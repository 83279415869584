<template>
<div>
<!-- <nav class="navbar navbar-expand navbar-light fixed-top nav-styl">
    <div class="container px-0">
        <ul class="navbar-nav mx-auto">
            <li>
                <img class="name-logo" src="@/assets/images/NameLogo01.png" alt="namelogo">
            </li>
        </ul>
    </div>
</nav> -->
<!-- <nav class="navbar navbar-expand navbar-light fixed-top nav-styl"></nav> -->
<div class="row spacer-row"></div>

        <!-- <div class="card main-card-login">
            <div class="row">
                <div class="col">
                    <span>the Investor's network</span>
                </div>
            </div>
        </div> -->


    <!-- <div class="row">
        <div class="col d-flex justify-content-center">
            <img id="img" src="@/assets/images/welcome996.png" class="custom-img-login2">
        </div>
    </div> -->



    <!-- <div class="row">
        <div class="col d-flex justify-content-center">
            <img id="img" src="@/assets/images/Inv_Modal.png" class="custom-img-login">
        </div>
    </div> -->

<div class="row spacer-row2"></div>

<nav class="navbar navbar-expand navbar-light fixed-bottom nav-styl2">
    <div class="container px-0">
        <ul class="navbar-nav mx-auto">
            <li>
                <a href="" @click.prevent="refreshPage" role="button">
                <img class="name-logo2" src="@/assets/images/NameLogo01.png" alt="namelogo">
                </a>
            </li>
        </ul>
    </div>
</nav>
</div>
</template>

<script>
import router from '@/router'
export default {
    name: 'LogoBar',
    methods: {
        refreshPage() {
            return router.go(0)
        }
    }
}
</script>

<style scoped>
/* .nav-styl {
    background-color: #272522;
    height: 80px;
}

.name-logo {
    height: 21px;
    margin-top: 40px;
    outline: none !important;
    border: none !important;
} */
/* @media only screen and (min-width: 2001px) {
    .spacer-row {
        height: 1px;
        margin-bottom: 100px;
    }
    .spacer-row2 {
        height: 1px;
        margin-bottom: 100px;
    }
} */
@media only screen and (min-width: 1601px) {  /* Large Desktop*/
    .spacer-row {
        height: 1px;
        margin-bottom: 100px;  /* with logo was 60 */
        margin-bottom: 4%;
    }
    .spacer-row2 {
        height: 1px;
        margin-bottom: 100px;  /* with logo was 60 */
        margin-bottom: 7%;
    }
}

@media only screen and (min-width: 1370px) and (max-width: 1600px) {  /* Small Desktop*/

    .spacer-row {
        height: 1px;
        margin-bottom: 70px;
        margin-bottom: 4%;
    }
    .spacer-row2 {
        height: 1px;
        margin-bottom: 70px;
        margin-bottom: 7%;
    }
}
@media only screen and (min-width: 1008px) and (max-width: 1369px) {  /* Laptop*/

    .spacer-row {
        height: 1px;
        margin-bottom: 55px;
        margin-bottom: 4%;
    }
    .spacer-row2 {
        height: 1px;
        margin-bottom: 65px;
        margin-bottom: 7%;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1007px) {  /* Tablet */

    .spacer-row {
        height: 1px;
        margin-bottom: 75px; /* with logo was 70 */
        margin-bottom: 4%;
    }
    .spacer-row2 {
        height: 1px;
        margin-bottom: 75px; /* with logo was 30 */
        margin-bottom: 7%;
    }
}


@media only screen and (min-width: 768px) {  /* Tablet and above*/

    .spacer-row {
        height: 1px;
        margin-bottom: 4%;
    }
    .spacer-row2 {
        height: 1px;
        margin-bottom: 6%;
    }
}


@media only screen and (min-width: 381px) and (max-width: 767px) {  /* Large phone*/

    .spacer-row {
        height: 1px;
        margin-bottom: 58px; /* with logo was 70   88*/
        margin-bottom: 13.5%;
    }
    .spacer-row2 {
        height: 1px;
        margin-bottom: 138px; /* with logo was 30   88*/
        margin-bottom: 22%;
    }
}
@media only screen and (max-width: 380px) {  /* Small phones*/
    .spacer-row {
        height: 1px;
        margin-bottom: 47px; /* with logo was -80  then 47*/
        margin-bottom: 12%;
    }
    .spacer-row2 {
        height: 1px;
        margin-bottom: 87px;  /* with logo was 160  then 47 */
        margin-bottom: 20%;
    }
}
@media only screen and (max-width: 325px) {  /* Small phones*/
    .spacer-row {
        height: 1px;
        margin-bottom: 12%;
    }
    .spacer-row2 {
        height: 1px;
        margin-bottom: 15%;
    }
}


/* .spacer-row {
    height: 1px;
    margin-bottom: 12%;
}
.spacer-row2 {
    height: 1px;
    margin-bottom: 25%;
} */

.custom-img-login {
    max-width: 125px;
    margin-top: 20px;
    margin-bottom: 0px;
}
/* .spacer-row {
    height: 45px;
} */

.nav-styl2 {
    background-color: #272522;
    height: 60px;
}

.name-logo2 {
    height: 21px;
    margin-bottom: 25px;
    outline: none !important;
    border: none !important;
}

.custom-img-login2 {
    display: block;
    max-width: 238px;
    width: auto;
    height: auto;
    /* margin-left: 1px; */
    margin-top: 10px; /* was 5   -65  -80*/
    margin-bottom: -30px; /* was -3   97   65*/
    z-index: 10;
}

.main-card-login {
    background-color: #272522;
    border: none;
    padding-left: 0px;
    text-align: center;
    font-weight: bold;
    font-size: 19px;
    /* color: #e2e2e9; */
    /* color: #cbc7c4; */
    color: #cbc7c4;
    margin-top: -20px;
    margin-bottom: -10px;
    font-family: "Segoe Print";
    word-spacing: 3px;
    text-shadow: 0px 4px #000;
    z-index: 10;
}

</style>
