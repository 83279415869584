<template>
<div>
  <input
    type="checkbox"
    role="switch"
    checked disabled
    @change="$emit('update:modelValue', $event.target.checked)"
    :class="modelValue ? 'form-check-input blue-switch' : 'form-check-input'"
  />
  <label v-if="label" :class="modelValue ? 'form-check-label solid-label' : ' form-check-label transp-label'">{{ label }}</label>
</div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: Boolean,
      default: false
    }
  },
}
</script>
<style>

.transp-label {
  color: #777777;
  font-size: 0.9rem;
}

.solid-label {
  color: #fff;
  font-size: 0.9rem;  
}
.blue-switch {
  background-color: #2F5C85 !important;
}
</style>

