<template>
<div class="container-flex msg-cont">
    <div class="msg-card">
        <span class="me-1">{{ resource }}.</span> <span>  Back to: <a href="" @click="$router.push('/')" class="retry-link">HOME</a></span>
    </div>
</div>
</template>

<script>
export default {
    props: {
        resource: {
            type: String,
            required: false,  // If it is not passed, will use the default
            default: 'Page not found'
        }
    },
    created() {
        localStorage.removeItem('paid') //new-0806
        console.log('removed paid from localStorage on NotFound-created')
    }
}
</script>

<style scoped>
.msg-cont {
    /* margin-top: 100px; */
    background-color: #272522;
    position: absolute;
    top: 180px;
    width: 100%;
}

.msg-card {
    width: 95%;
    background-color: #413e3c !important;
    color: #dbd7d4;
    height: 60px;
    margin: 3px auto;
    padding-top: 15px;
    text-align: center;
    font-size: 1.1rem;
    font-weight: bold;
}

.retry-link {
    /* color: #7FA650 !important; */
    color: #3F7CB3 !important;
    /* text-decoration: none !important; */
    text-decoration: underline;
    font-weight: bold;
    font-size: 1.1rem;
    margin-left: 10px;
}

/* .msg-card {
    width: 95%;
    background-color: #d7d7d7;
    color: #000;
    height: 50px;
    margin: 3px auto;
    padding-top: 13px;
    text-align: center;
    font-size: 1.1rem;
    font-weight: bold;
}

.retry-link {
    text-decoration: underline;
    font-weight: bold;
    font-size: 1.1rem;
} */
</style>
