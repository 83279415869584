<template>
<div>
    <div class="modal fade" id="removeModal" tabindex="-1" aria-labelledby="removeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content modal-area">
                <div class="modal-header">
                    <h5 class="modal-title modal-title2" id="removeModalLabel">Remove asset from watchlist </h5>
                    <button type="button" class="btn close-butt" data-bs-dismiss="modal" aria-label="Remove">
                        <div class="btn-tex">
                            x
                        </div>
                    </button>
                </div>

                <div class="modal-body modal-body2">
                    <div class="row top-row-modal">
                        <div class="col">
                            <!-- <p>Sure you want to remove <b>{{ modalRemoveEntry.assetname.slice(modalRemoveEntry.assetname.lastIndexOf(" - ") + 3) }} ({{modalRemoveEntry.symbol}})</b> from your watchlist?</p> -->
                            <p>Sure you want to remove <b>{{ modalRemoveEntry.shortname }} ({{modalRemoveEntry.symbol}})</b> from your watchlist?</p>
                        </div>
                    </div>
                </div>

                <div class="modal-footer modal-body2 px-0">
                    <div class="row modal-row">
                        <div class="col-8 px-0">
                            <button type="button" @click.prevent="removeAsset(this.modalRemoveEntry, this.modalRemoveIndex)" class="btn btn-primary modal-btn">Remove asset</button>
                        </div>
                        <div class="col-4 px-0">
                            <button type="button" class="btn btn-secondary btn-secondary-modal" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div v-if="this.titleRow == true" class="row title-row">
        <div class="col d-flex justify-content-center">
            <h2>Watchlist</h2>
        </div>
    </div>


    <!-- <div v-if="this.showEmpty" class="card entry-card top-card"> -->
    <div v-if="this.showEmpty" class="card empty-card">
        <div class="row">
            <!-- <div class="col-12 cell-empty"> -->
            <div class="col-12">
                {{ this.emptyMsgWtc }}
            </div>
        </div>
    </div>

    <div v-else-if="this.updatedEntries.length > 0">

        <div class="card card-headers">
            <div class="row">
                <div class="col-3 cell-headers head-1">
                    asset
                </div>
                <div class="col-1 cell-headers head-2">
                    l/s
                </div>
                <div class="col-3 cell-headers head-3">
                    start
                </div>
                <div class="col-2 cell-headers">
                    chg
                </div>
                <div class="col-3 cell-headers">
                    last
                </div>
            </div>
        </div>

        <div v-for="entry, index in this.updatedEntries" :key="entry.id">
            <div class="card entry-card" :id="'wtccard-'+index">
                <div class="row">
                    <div class="col-3 cell-tick cell-left">
                        <a class="cell-neu" href="" @click.prevent="toggleSubcard(index)">{{ entry.assetname.split(' ')[0].slice(0, 7) }}</a>
                    </div>
                    <div class="col-1 cell-pri cell-mid1">
                        {{ (entry.longshort == 'long') ? 'L' : 'S' }}
                    </div>
                    <div v-if="entry.startprice < 0.001" class="col-3 cell-pri cell-mid2">
                        {{ (entry.startprice*1000000).toFixed(4) }}E-5
                    </div>
                    <div v-else class="col-3 cell-pri cell-mid2">
                        <!-- {{ (Math.round(entry.startprice*100)/100).toLocaleString('en-US') }} -->
                        {{ (Math.round(entry.startprice*100)/100).toLocaleString('en-US') }}
                    </div>
                    <div class="col-2 cell-pri cell-right">
                        <span :class="(entry.currprice/entry.startprice) >= 1 ? 'cell-pos' : 'cell-neg'">
                            <!-- {{ (Math.round((entry.currprice / entry.startprice -1) * 10000) / 100).toFixed(2) }} -->
                            {{ entry.priChg }}

                        </span>
                    </div>
                    <div v-if="entry.currprice < 0.001" class="col-3 cell-pri cell-mid3">
                        <!-- {{ (Math.round(Number(entry.currprice)*100)/100).toLocaleString('en-US') }} -->
                        {{ (entry.currprice*1000000).toFixed(4) }}E-5
                    </div>
                    <div v-else class="col-3 cell-pri cell-mid3">
                        <!-- {{ (Math.round(Number(entry.currprice)*100)/100).toLocaleString('en-US') }} -->
                        {{ (Math.round(entry.currprice*100)/100).toLocaleString('en-US') }}
                    </div>
                </div>

                <div class="row">
                    <div class="col-3 cell-qty cell-left">

                    </div>
                    <div class="col-1 cell-curr cell-mid1">

                    </div>
                    <div class="col-3 cell-curr cell-mid2">
                        {{ entry.currency.toLowerCase() }}
                    </div>
                    <div class="col-2 cell-curr cell-mid3">
                        %
                    </div>
                    <div class="col-3 cell-curr cell-right">
                        {{ entry.currency.toLowerCase() }}
                    </div>
                </div>

            </div>

            <div v-show="showCard[index]" class="card entry-subcard entry-subcard-wtc" :id="'wtcsubcard-'+index">

                <div class="row">
                    <div class="col-1 cell-subc cell-subc-left">

                    </div>
                    <div class="col-3 cell-subc subc-head cell-subc-mid1">

                    </div>
                    <div class="col-3 cell-subc subc-head cell-subc-mid2 mid2-wtc">
                        start
                    </div>
                    <div class="col-2 cell-subc subc-head cell-subc-mid3 mid3-wtc">
                        chg%
                    </div>
                    <div class="col-3 cell-subc subc-head cell-subc-right">
                        last
                    </div>
                </div>

                <div class="row">
                    <div class="col-4 cell-subc cell-titl cell-subc-left">
                        <!-- fx {{ entry.currency.toLowerCase() }}/{{ baseCurrency.toLowerCase() }} -->
                        <!-- fx {{ entry.currency.toLowerCase() }}/{{ entry.baseCurrency.toLowerCase() }} -->
                        <div v-if="entry.currencysymbol">
                            fx {{ entry.currency.toLowerCase() }}/{{ entry.currencysymbol.slice(-5, -2).toLowerCase() }}
                        </div>
                        <div v-else>
                            fx {{ entry.currency.toLowerCase() }}/{{ entry.currency.toLowerCase() }}
                        </div>
                    </div>
                    <!-- <div class="col-3 cell-subc cell-subc-mid1">
                        {{ entry.currency.toLowerCase() }}/{{ baseCurrency.toLowerCase() }}
                    </div> -->
                    <div class="col-3 cell-subc cell-subc-mid2 mid2-wtc">
                        {{ (Math.round(entry.startfx * 10000) / 10000).toFixed(4) }}
                    </div>
                    <div class="col-2 cell-subc cell-subc-mid3 mid3-wtc">
                        <span :class="(entry.currfx / entry.startfx) >= 1 ? 'cell-pos' : 'cell-neg2'">
                            <!-- {{ (Math.round((entry.currfx / entry.startfx -1) * 10000) / 100).toFixed(2)}} -->
                            {{ entry.fxChg }}
                        </span>
                    </div>
                    <div class="col-3 cell-subc cell-subc-right">
                        {{ (Math.round(entry.currfx * 10000) / 10000).toFixed(4) }}
                    </div>
                </div>

                <div class="row last-row-subc-wtc">
                    <div class="col-4 cell-subc comments-cell cell-subc-low1">
                        <a v-if="this.commentsVisible" class="cell-light" href="" @click.prevent="toggleComments(entry, index)">comments</a>
                        <span v-else class="cell-disabled">comments</span>
                        <!-- <a class="cell-neu" href="" @click.prevent="toggleComments(entry, index)">comments</a> -->
                    </div>

                    <div class="col-6 cell-subc closepos-cell cell-subc-low2 low2-wtc" v-if="this.showCloseLink">
                        <!-- <a class="cell-neu" href="" @click.prevent="toggleClose(index)">remove from list</a> -->
                        <a class="cell-light" href="" @click.prevent="showRemoveModal(entry, index)">remove from list</a>
                    </div>
                    <div class="col-2 cell-subc closepos-cell cell-subc-low3 px-0" v-if="this.showCloseLink">
                        <a class="cell-light" href="" @click.prevent="toggleAlert(entry, index)">alert</a>
                    </div>

                </div>
            </div>

            <div v-show="showComment[index]" class="container-fluid px-0">
                <div class="card comments-card">
                    <div class="row">
                        <div class="col line-break-and-tab" :id="'wtccomment-'+index">
                            <p> {{ this.updatedcomment[index] }} </p>
                            <!-- <CommentList :htmlString="this.updatedcomment[index]" /> -->
                        </div>
                    </div>
                </div>

                <div v-show="!this.textArea[index] && this.commentsAllowed" class="row">
                    <div class="col">
                        <button role="button" @click.prevent="this.addComment(index)" class="btn addcomm-btn btn3d btn-primar">Add a comment</button>
                    </div>
                </div>
                <div v-show="this.textArea[index]" class="row">
                    <div class="col-sm-12">
                        <div class="form-floating">
                            <textarea class="form-control comment-textarea" maxlength="3000" :id="'wtcfloatingTextarea-' + index" v-model="this.newcomment[index]" @drop.prevent="false" @paste.prevent="false"></textarea>
                        </div>
                    </div>
                </div>
                <div v-show="this.textArea[index]" class="row">
                    <div class="col-10">
                        <button role="button" @click.prevent="enterComment(entry, index)" class="btn entercomm-btn btn3d btn-primar">Enter comment</button>
                        <!-- </div>
                    <div class="col-3"> -->
                        <button role="button" @click.prevent="this.newcomment[index] = ''; this.textArea[index] = false;" class="btn btn-secondary btn-secondary-comment shadow rounded ">Cancel</button>
                    </div>
                </div>

            </div>

            <div v-show="showAlert[index]" class="container-fluid px-0">
                <div class="card comments-card pt-2">
                    <div class="row" :id="'wtcalert-'+index">
                        <div class="col">
                            <p>Get notification when price closes:</p>
                        </div>
                    </div>
                    <div v-if="modifyAbove[index]" class="row alert-row-1-open g-0">
                        <div class="col-2">
                            Above:
                        </div>
                        <div class="col-3 col-prialert col-alertbox">
                            <!-- <BaseInputEnter v-model="alertAbove[index]" type="number" step=0.0001 @drop.prevent="false" @paste.prevent="false" @blur="validAlert($event.target.value)" class="alert-box" :id="'abovebox-'+index"/> -->
                            <input v-model="alertAbove[index]" type="number" step=0.0001 @drop.prevent="false" @paste.prevent="false" class="alert-box" :id="'wtcabovebox-'+index" />
                        </div>
                        <div class="col-2">
                            <b>{{entry.currency.toLowerCase()}}</b>
                        </div>
                        <div class="col-2 col-alert-enter">
                            <button role="button" @click.prevent="enterAlert(entry, index, 'above', true)" class="btn btn-primar btn-alert-enter btn3d">Enter</button>
                        </div>
                        <div class="col-2 col-alert-cancel">
                            <button role="button" @click.prevent="cancelAlert(entry, index, 'above')" class="btn btn-primar btn-alert-cancel">Cancel</button>
                        </div>
                    </div>

                    <div v-else class="row alert-row-1 g-0">
                        <div class="col-2">
                            Above:
                        </div>
                        <div class="col-3 col-prialert">
                            <b>{{alertAbove[index]}}</b>
                        </div>
                        <div class="col-2">
                            <b>{{entry.currency.toLowerCase()}}</b>
                        </div>
                        <div v-if="entry.alertabove!=0" class="col-2 col-alert-enter">
                            <button role="button" @click.prevent="modifyAlert(index, 'above')" class="btn btn-primar btn-alert-modify btn3d">Modify</button>
                        </div>
                        <div v-if="entry.alertabove!=0" class="col-2 col-alert-cancel">
                            <button role="button" @click.prevent="enterAlert(entry, index, 'above', false)" class="btn btn-primar btn-alert-remove">Remove</button>
                        </div>
                        <div v-if="entry.alertabove==0" class="col-4 col-alert-set">
                            <button role="button" @click.prevent="modifyAlert(index, 'above')" class="btn btn-primar btn-alert-set btn3d">Create alert</button>
                        </div>
                    </div>

                    <div v-if="modifyBelow[index]" class="row alert-row-2-open g-0">
                        <div class="col-2">
                            Below:
                        </div>
                        <div class="col-3 col-prialert col-alertbox">
                            <!-- <BaseInputEnter v-model="alertBelow[index]" type="number" step=0.0001 @drop.prevent="false" @paste.prevent="false" @blur="validAlert($event.target.value)" class="alert-box" :id="'belowbox-'+index"/> -->
                            <input v-model="alertBelow[index]" type="number" step=0.0001 @drop.prevent="false" @paste.prevent="false" class="alert-box" :id="'wtcbelowbox-'+index" />
                        </div>
                        <div class="col-2">
                            <b>{{entry.currency.toLowerCase()}}</b>
                        </div>
                        <div class="col-2 col-alert-enter">
                            <button role="button" @click.prevent="enterAlert(entry, index, 'below', true)" class="btn btn-primar btn-alert-enter btn3d">Enter</button>
                        </div>
                        <div class="col-2 col-alert-cancel">
                            <button role="button" @click.prevent="cancelAlert(entry, index, 'below')" class="btn btn-primar btn-alert-cancel">Cancel</button>
                        </div>
                    </div>
                    <div v-else class="row alert-row-2 g-0">
                        <div class="col-2">
                            Below:
                        </div>
                        <div class="col-3 col-prialert">
                            <b>{{alertBelow[index]}}</b>
                        </div>
                        <div class="col-2">
                            <b>{{entry.currency.toLowerCase()}}</b>
                        </div>
                        <div v-if="entry.alertbelow!=0" class="col-2 col-alert-enter">
                            <button role="button" @click.prevent="modifyAlert(index, 'below')" class="btn btn-primar btn-alert-modify btn3d">Modify</button>
                        </div>
                        <div v-if="entry.alertbelow!=0" class="col-2 col-alert-cancel">
                            <button role="button" @click.prevent="enterAlert(entry, index, 'below', false)" class="btn btn-primar btn-alert-remove">Remove</button>
                        </div>
                        <div v-if="entry.alertbelow==0" class="col-4 col-alert-set">
                            <button role="button" @click.prevent="modifyAlert(index, 'below')" class="btn btn-primar btn-alert-set btn3d">Create alert</button>
                        </div>
                    </div>
                </div>
            </div>





        </div>

    </div>
</div>
</template>

<script>
// import CommentList from '@/components/CommentList.vue'
import apiCalls from '@/services/apiCalls.js'
// import yfapiCalls from '@/services/yfapiCalls.js'
import store from '@/store'
import {
    mapActions
} from 'vuex'
import {
    Modal
} from 'bootstrap'
// import router from '@/router'
export default {
    name: 'TableWatchlist',
    // components: {
    //     CommentList
    // },
    data() {
        return {
            width: 0,
            showEmpty: false,
            emptyMsgWtc: '',
            particId: null,
            // particIcon: null,
            showCloseLink: false,
            entries: [],
            updatedEntries: [],
            currencies: [],
            showCard: [],
            showComment: [],
            textArea: [],
            // showClose: [],
            newcomment: [],
            updatedcomment: [],
            openwtc: 0,
            // takenassets: '',
            // takenassetsObj: {},
            modalRemoveEntry: {},
            modalRemoveIndex: null,
            baseCurrency: null,
            hideComments: false,
            stopComments: false,
            commentsVisible: false,
            commentsAllowed: false,

            showAlert: [],
            modifyAbove: [],
            modifyBelow: [],
            alertAbove: [],
            alertBelow: [],

        }
    },
    props: [
        'category'
    ],
    computed: {
        titleRow() {
            if (store.state.page == '/watchlist' && store.state.particId && store.state.particId != store.state.myPid) {
                if (this.width >= 500){
                    return false
                } else if(this.width >= 400 && store.state.particAlias.length <= 14) {
                    return false
                } else if(this.width >= 400) {
                    return true
                } else if(this.width >= 360 && store.state.particAlias.length <= 12) {
                    return false
                } else {
                    return true
                }
            } else {
                return false
            }
        },
        // baseCurrency() {
        //     return store.state.myCur
        // },
    },
    methods: {
        ...mapActions(['setParticIcon', 'setToinvPage']),
        async getAssets() {
            try {
                // this.endPoint = '/' + this.category + '/'
                console.log('in gestAssets watchlist')
                let endPoint = '/watchassets/'
                let params = {}
                if (this.particId && this.particId != store.state.myPid) {
                    // console.log('this.particId: ', this.particId)
                    // console.log('this.particIcon: ', this.particIcon)
                    params = {
                        'participant': this.particId,
                        'closed': 0
                    }
                    this.emptyMsgWtc = 'Watchlist currently empty'
                } else {
                    params = {
                        'participant': store.state.myPid,
                        'closed': 0
                    }
                    this.emptyMsgWtc = 'Watchlist currently empty'
                }
                const Entries = await apiCalls.getEntries(endPoint, params)
                if (Entries && Entries.data.length > 0) {
                    this.entries = Entries.data
                    console.log('this.entriesItems2Table: ', this.entries)
                    // this.updatedcomment = Entries.data.map(a => a.comment)
                    // console.log('this.updatedcomment: ', this.updatedcomment)
                    await this.getAssetsData()
                } else {
                    await this.setToinvPage(false) // Making sure that it is triggered in case that watchlist is empty. HERE BECAUSE watchlist appears to be the last ASYNC EVENT WHEN BUILDING INVESTOR PAGE. SO at the end of it IT CAN BE RENDERED
                    // await store.dispatch('setToinvPage', false)
                    console.log('setting ToinvPage to FALSE IN WATCHLIST')
                    return this.showEmpty = true
                }
            } catch (error) {
                return console.log('error status: ', error)
            }
        },
        async getAssetsData() {
            try {
                this.updatedEntries = this.entries
                // this.currencies = []
                this.updatedEntries.forEach(asset => {
                    asset.currprice = Number(asset.lastprice)
                    asset.priChg = new Intl.NumberFormat("en-US", {
                        signDisplay: "exceptZero"
                    }).format(Math.round((asset.currprice / asset.startprice - 1) * 10000) / 100)
                })

                this.currencies = []
                const Currencies = await apiCalls.getEntries('/currencies/', {})
                this.currencies = Currencies.data
                console.log('currencies: ', this.currencies)

                let fxRates = {}
                this.currencies.forEach(currObj => {
                    fxRates[currObj.symbol] = Number(currObj.lastprice)
                })
                this.updatedEntries.forEach(uE => {
                    if (uE.currencysymbol) {
                        uE.currfx = fxRates[uE.currencysymbol]
                        // uE.entryBasecurrency = uE.currencysymbol.slice(3, 6) // added because if participant basecurrency is changed in the meantime, the one of the start time should be used
                    } else if (!uE.currencysymbol) {
                        console.log('it is in my base currency... of the time when it was entered in watchlist')
                        uE.currfx = 1.0000
                    }
                    uE.fxChg = new Intl.NumberFormat("en-US", {
                        signDisplay: "exceptZero"
                    }).format(Math.round((uE.currfx / uE.startfx - 1) * 10000) / 100)
                })
                console.log('this.updatedEntries2: ', this.updatedEntries)
                await this.setToinvPage(false) // IT IS HERE BECAUSE THIS APPEARS TO BE THE LAST ASYNC EVENT WHEN BUILDING INVESTOR PAGE. SO AFTER THIS IT CAN BE RENDERED
                // await store.dispatch('setToinvPage', false)
                console.log('setting ToinvPage to FALSE IN WATCHLIST')
            } catch (error) {
                return console.log('error: ', error)
            }
        },
        // async getAssetsDataFromApi() {
        //     try {
        //         // let endPoint = '/v6/finance/quote'
        //         let symbols = ''
        //         this.entries.forEach(obj => {
        //             obj.symbol = obj.assetname.split(' ')[0]
        //             // console.log('storedasset: ', obj)
        //             symbols = symbols + obj.symbol + ','
        //         })
        //         symbols = symbols.slice(0, -1) // eliminates the final comma
        //         let params = {
        //             // lang: 'en',
        //             symbols: symbols,
        //         }
        //         // console.log('params: ', params)
        //         let assetsData = []
        //         if (store.state.apiUrl == 'yf') {
        //             assetsData = await yfapiCalls.getYF(params)
        //         } else {
        //             assetsData = await yfapiCalls.getRapid(params)
        //         }
        //         // console.log('assetsData: ', assetsData.data.quoteResponse.result)
        //         this.updatedEntries = []
        //         this.currencies = []
        //         this.entries.forEach(asset => {
        //             asset = {
        //                 ...asset,
        //                 ...assetsData.data.quoteResponse.result.find(e => e.symbol == asset.symbol)
        //             }
        //             switch (asset.longshort) {
        //                 case 'long':
        //                     asset.currprice = asset.bid
        //                     break
        //                 case 'short':
        //                     asset.currprice = asset.ask
        //                     break
        //             }
        //             if (asset.ask == 0 || asset.bid == 0) {
        //                 asset.currprice = asset.regularMarketPrice
        //             }
        //             // console.log('updated asset: ', asset)
        //             this.updatedEntries.push(asset)
        //             if (!this.currencies.includes(asset.currency)) {
        //                 this.currencies.push(asset.currency)
        //             }
        //         })
        //         // console.log('this.updatedEntries1: ', this.updatedEntries)
        //         // console.log('currencies: ', this.currencies)
        //         params['symbols'] = ''
        //         this.currencies.forEach(item => {
        //             params['symbols'] = params['symbols'] + item + store.state.myCur + '=X' + ','
        //         })
        //         params['symbols'] = params['symbols'].slice(0, -1) // eliminates the final comma
        //         // params['symbols']= 'USDCHF=X'
        //         // console.log(params)
        //         let fxData = []
        //         if (store.state.apiUrl == 'yf') {
        //             fxData = await yfapiCalls.getYF(params)
        //         } else {
        //             fxData = await yfapiCalls.getRapid(params)
        //         }
        //         // console.log(fxData)
        //         // this.entry.startfx = Math.round((fx + Number.EPSILON) * 10000) / 10000
        //         let fxRates = {}
        //         fxData.data.quoteResponse.result.forEach(currObj => {
        //             fxRates[currObj.symbol.slice(0, 3)] = (currObj.ask + currObj.bid) / 2
        //         })
        //         // console.log('fxRates: ', fxRates)
        //         this.updatedEntries.forEach(uE => {
        //             uE.currfx = fxRates[uE.currency]
        //             // uE.fxeffect = uE.amount - (uE.amount * uE.startfx / uE.currfx)
        //         })
        //         console.log('this.updatedEntries2: ', this.updatedEntries)
        //     } catch (error) {
        //         return console.log('error: ', error)
        //     }
        // },
        async removeAsset(entry, index) {
            // console.log(entry) // Has all the data from the entry, whether shown or not
            try {
                let entryId = entry.id
                let updatedComment = null
                let cutPos = entry.comment.indexOf("INITIAL COMMENT:")

                let tradeend = entry.comment.substr(0, cutPos).lastIndexOf('\n\n')
                let tradesstr = entry.comment.substr(0, tradeend)
                let commentstr = entry.comment.substr(tradeend)

                // let tradesstr = entry.comment.substr(0, cutPos - 9)
                // let commentstr = entry.comment.substr(cutPos - 9)
                // let tradesstr = entry.comment.substr(0, entry.comment.indexOf("\n\nINITIAL COMMENT:"))
                // let commentstr = entry.comment.substr(entry.comment.indexOf("\n\nINITIAL COMMENT:"))
                let myDate = new Date()
                updatedComment = `${tradesstr}${'\n'}Removed on ${myDate.getFullYear()}-${('0'+(myDate.getMonth()+1)).slice(-2)}-${('0'+myDate.getDate()).slice(-2)}${commentstr}`

                let params = {
                    'closed': true,
                    'timeclosed': new Date().toISOString().slice(0, 16).replace('T', ' @'),
                    'endprice': (Math.round(entry.currprice * 10000000000) / 10000000000),
                    'endfx': (Math.round(entry.currfx * 10000) / 10000),
                    'comment': updatedComment,
                }
                await apiCalls.patchEntry('/watchassets/' + entryId + '/', params)
                // console.log(Resp) // No particular info in the Response from delete

                let participant = {}
                participant['openwtc'] = this.openwtc - 1
                this.openwtc -= 1
                await apiCalls.patchEntry('/participants/' + store.state.myPid + '/', participant)

                this.showCard[index] = false
                this.showComment[index] = false
                this.updatedEntries.splice(index, 1)

                // Delete from taken Assets in Club *********************
                // const deleteAssetname = entry.assetname.split(' ')[0]
                // await this.getTakenassets()
                // console.log('takenassetsObj before delete', this.takenassetsObj)
                // delete this.takenassetsObj[deleteAssetname]
                // console.log('takenassestObj after delete', this.takenassetsObj)
                // const takenassetsString = JSON.stringify(this.takenassetsObj)
                // const params2 = {
                //     'takenassets': takenassetsString
                // }
                // console.log('params', params2)
                // await apiCalls.patchEntry('/clubs/' + store.state.myClub + '/', params2)
                // ************ END ************************

                this.hideRemoveModal()

            } catch (error) {
                return console.log('error status: ', error)
            }
        },
        async getParticipant() {
            try {
                if (store.state.particId && store.state.particId != store.state.myPid) { // Looking into a Participant other than myself
                    this.particId = store.state.particId
                    this.showCloseLink = false
                } else {
                    this.particId = store.state.myPid
                    this.showCloseLink = true
                }
                let participantData = {}
                if (store.state.page != '/investor' || store.state.investorData == null) {
                    console.log('AAAATTTT investorData not used in watchlist getParticipant: ', store.state.investorData)
                    const Entries = await apiCalls.getEntries('/participants/' + this.particId + '/', {})
                    if (Entries) {
                        participantData = Entries.data
                    }
                } else if (store.state.page == '/investor' && store.state.investorData != null) {
                    participantData = store.state.investorData
                    console.log('AAAATTTT investorData used in watchlist getParticipant: ', store.state.investorData)
                }
                if (participantData) {
                    if (!localStorage.getItem('paic')  || store.state.particIcon == null) { // this is the case when it is routing from View buttom in a notification and misses the icon of the notifier
                        localStorage.setItem('paic', participantData['usericon'])
                        await this.setParticIcon(participantData['usericon'])
                    }
                    this.openwtc = Number(participantData['openwtc'])
                    this.baseCurrency = participantData['basecurrency']
                    // this.hideComments = participantData['stopcomments']   // DELETE
                    this.hideComments = participantData['hidecomments'] //RESTORE                    
                    this.stopComments = participantData['stopcomments']
                } else {
                    console.log('Did not get Participant data in TableWatchlist/getParticipant')
                }
            } catch (error) {
                return console.log('error in TableWatchlist/getParticipant: ', error)
            }
        },
        addComment(index) {
            this.textArea[index] = true
            setTimeout(() => { // NEW 20230308  // Seems that in reactive languages like React, Vue etc focus command doesn't work if you don't place inside setTimeout
                document.getElementById('wtcfloatingTextarea-' + index).focus()
                // document.getElementById('wtccomment-' + index).scrollIntoView({
                document.getElementById('wtcfloatingTextarea-' + index).scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                })
            }, 0)
        },
        async enterComment(entry, index) {
            if (!this.newcomment[index]) {
                return
            }
            // if (!entry.extracomments) { // NOT NEEDED - SEE IN TABLEITEMS1
            //     this.updatedcomment[index] =  `${entry.comment}${'\n'}`
            // }
            console.log('comment entry: ', entry)
            let newComment = {
                // 'club': store.state.myClub,
                'participant': store.state.myPid,
                // 'usericon': store.state.myIcon,
                'useralias': store.state.myAlias,
                'watchasset': entry.id,
                'comment': this.newcomment[index]
            }
            // console.log('new comment', newComment)
            // console.log('entry.id', entry.id)
            try {
                const resp = await apiCalls.postEntry('/wtccomments/', newComment)
                if (entry.extracomments == false) {
                    let params = {
                        'extracomments': true
                    }
                    await apiCalls.patchEntry('/watchassets/' + entry.id + '/', params)
                    entry.extracomments = true //will tell me not to patch the watchasset if I add a second one immediately
                    localStorage.setItem('cnu', '1')
                    console.log('localStorage.getItem(cnu): ', localStorage.getItem('cnu'))
                } else {
                    let commentsNum = Number(localStorage.getItem('cnu')) + 1
                    localStorage.setItem('cnu', commentsNum)
                    console.log('localStorage.getItem(cnu): ', localStorage.getItem('cnu'))
                }
                if (store.state.particId && store.state.particId != store.state.myPid) { // I am looking at someone else's watchlist, cannot remove or report comments
                    this.updatedcomment[index] = (
                        `${this.updatedcomment[index]}${'\n'}<b><a href="/${resp.data.participant}" style="color: #fff;">${resp.data.useralias}</a></b> - ${resp.data.created.slice(0, 10)}: ${'\n'}${resp.data.comment}${'\n'}`
                    )
                } else { // I'm in my own watchlist, so can remove this comment
                    this.updatedcomment[index] = (
                        `${this.updatedcomment[index]}${'\n'}<b><a href="/${resp.data.participant}" style="color: #fff;">${resp.data.useralias}</a></b> - ${resp.data.created.slice(0, 10)}:   ( <i><u><a href="/remove?a=w&c=${resp.data.id}&p=${entry.id}" style="color: #fff;">remove</a></i></u> )${'\n'}${resp.data.comment}${'\n'}`
                    )
                }
                entry.comment = `${'\n'}${this.updatedcomment[index]}`
                entry.comment = entry.comment.trim() // RECENT

                // console.log('this.updatedcomment[index]: ', this.updatedcomment[index])
                document.getElementById('wtccomment-' + index).innerHTML = this.updatedcomment[index]
                this.textArea[index] = false
                return this.newcomment[index] = ''
                // return this.showComment[index] = false
            } catch (error) {
                return console.log('error while entering new comment: ', error)
            }
        },
        // async getTakenassets() {
        //     try {
        //         let endPoint = '/clubs/' + store.state.myClub + '/'
        //         const Entries = await apiCalls.getEntries(endPoint, {})
        //         this.takenassets = Entries.data['takenassets']
        //         console.log('takenassets: ', this.takenassets)
        //         if(this.takenassets) {
        //             console.log('there is a taken asset list')
        //             this.takenassetsObj = JSON.parse(this.takenassets)
        //         }
        //     } catch (error) {
        //         return console.log('error status: ', error)
        //     }
        // },
        toggleSubcard(index) {
            if (this.showComment[index]) {
                this.showComment[index] = false
            }
            if (this.textArea[index]) {
                this.textArea[index] = false
            }
            if (this.showAlert[index]) {
                this.showAlert[index] = false
                this.modifyAbove[index] = false
                this.modifyBelow[index] = false
            }
            if (!this.showCard[index]) {
                this.showCard[index] = !this.showCard[index]
                setTimeout(() => { // NEW 20230308 
                    return document.getElementById('wtccard-' + index).scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    })
                }, 0)

            } else {
                return this.showCard[index] = !this.showCard[index]
            }
        },
        async toggleComments(entry, index) {
            // if (!this.showComment[index] && entry.extracomments) {

            // if (!this.showComment[index] && !this.updatedcomment[index]) {
            if (!this.showComment[index]) {
                if (this.showAlert[index]) {
                    this.showAlert[index] = false
                    this.modifyAbove[index] = false
                    this.modifyBelow[index] = false
                }
                let cutPos = entry.comment.indexOf("INITIAL COMMENT:")

                let aliasstart = entry.comment.substr(0, cutPos).lastIndexOf('\n\n') + 2
                let aliasstr = entry.comment.slice(aliasstart, cutPos - 3)
                let tradesstr = entry.comment.substr(0, cutPos - 3 - aliasstr.length)

                // let tradesstr = entry.comment.substr(0, cutPos -7)
                // let iconstr = entry.comment.substr(cutPos -7, 4)
                let commentstr = entry.comment.substr(cutPos + 16)
                // this.updatedcomment[index] =`${tradesstr}<i><b><a href="/${iconstr.slice(-2) + entry.participant}" style="color: #fff;">${iconstr}</a></b> - INITIAL COMMENT:</i>${commentstr}${'\n'}`
                this.updatedcomment[index] = `${tradesstr}<b><a href="/${entry.participant}" style="color: #fff;">${aliasstr}</a></b> - INITIAL COMMENT:${commentstr}${'\n'}`

                // this.updatedcomment[index] =  `${entry.comment}${'\n'}`
                let params = {
                    'watchasset': entry.id,
                }
                if (entry.extracomments) {
                    try {
                        localStorage.setItem('cnu', '0')
                        const extracomms = await apiCalls.getEntries('/wtccomments/', params)
                        // console.log('extracomms: ', extracomms)
                        if (extracomms && extracomms.data.length > 0) {
                            localStorage.setItem('cnu', extracomms.data.length)
                            console.log('localStorage.getItem(cnu): ', localStorage.getItem('cnu'))
                        }
                        console.log('extracomms: ', extracomms)
                        if (store.state.particId && store.state.particId != store.state.myPid) { // I am looking at someone else's watchlist, cannot remove comments
                            extracomms.data.forEach(obj => {
                                this.updatedcomment[index] = (
                                    `${this.updatedcomment[index]}${'\n'}<b><a href="/${obj.participant}" style="color: #fff;">${obj.useralias}</a></b> - ${obj.created.slice(0, 10)}:${'\n'}${obj.comment}${'\n'}`
                                )
                            })

                        } else { // Means it is my own watchlist
                            extracomms.data.forEach(obj => {
                                this.updatedcomment[index] = (
                                    `${this.updatedcomment[index]}${'\n'}<b><a href="/${obj.participant}" style="color: #fff;">${obj.useralias}</a></b> - ${obj.created.slice(0, 10)}:   ( <i><u><a href="/remove?a=w&c=${obj.id}&p=${entry.id}" style="color: #fff;">remove</a></i></u> )${'\n'}${obj.comment}${'\n'}`
                                )
                            })
                        }
                        // document.getElementById('comment-' + index).innerHTML = this.updatedcomment[index]
                        // return this.showComment[index] = !this.showComment[index]
                    } catch (err) {
                        console.log(err)
                    }
                }
                // console.log('this.updatedcomment[index] after: ', this.updatedcomment[index])
                document.getElementById('wtccomment-' + index).innerHTML = this.updatedcomment[index]
                this.showComment[index] = !this.showComment[index]
                setTimeout(() => { // NEW 20230308 
                    return document.getElementById('wtcsubcard-' + index).scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    })
                }, 0)
            } else {
                return this.showComment[index] = !this.showComment[index]
            }
        },
        // htmlString(index) {
        //     return this.updatedcomment[index],
        //     }
        // },
        // toggleClose(index) {
        //     this.showClose[index] = !this.showClose[index]
        // },
        showRemoveModal(entry, index) {
            console.log('entry for modal: ', entry)
            this.modalRemoveEntry = {
                ...entry,
                'shortname': entry.assetname.slice(entry.assetname.lastIndexOf(" - ") + 3)
            }
            this.modalRemoveIndex = index
            this.bsRemoveModal.show()
        },
        hideRemoveModal() {
            this.bsRemoveModal.hide()
        },
        async getAllowComments() {
            try {
                this.commentsVisible = false
                this.commentsAllowed = false
                let particClubsList = []
                let myClubsList = []
                let commonMemberships = false
                if (this.particId == store.state.myPid) {
                    this.commentsVisible = true
                    this.commentsAllowed = true
                    return
                }
                const blockedMe = await apiCalls.getEntries('/blocks/', {
                    'blocked': store.state.myPid,
                    'blocker': this.particId,
                })
                if (blockedMe && blockedMe.data.length > 0) {
                    this.commentsVisible = false
                    this.commentsAllowed = false
                    return
                }
                if (!this.hideComments && !this.stopComments) {
                    this.commentsVisible = true
                    this.commentsAllowed = true
                    return
                }
                // If at least one of the 2 restrictions is true check if there are commonMemberships
                const particMemberships = await apiCalls.getEntries('/memberships/', {
                    'participant': this.particId,
                    'status': 'active',
                })
                if (particMemberships && particMemberships.data.length > 0) {
                    console.log('particMemberships: ', particMemberships.data)
                    particClubsList = particMemberships.data.map(a => a.club)
                    console.log('particClubsList: ', particClubsList)
                    const myMemberships = await apiCalls.getEntries('/memberships/', {
                        'participant': store.state.myPid,
                        'status': 'active',
                    })
                    if (myMemberships && myMemberships.data.length > 0) {
                        console.log('myMembershipsInPortfolio: ', myMemberships.data)
                        myClubsList = myMemberships.data.map(a => a.club)
                        console.log('myClubsList: ', myClubsList)
                        commonMemberships = particClubsList.some(item => myClubsList.includes(item))
                    } else {
                        commonMemberships = false
                    }
                } else {
                    commonMemberships = false
                }
                if (commonMemberships == true) {
                    this.commentsVisible = true
                    this.commentsAllowed = true
                    return
                }
                // if there are no common memberships, then the restrictions apply
                if (this.hideComments) {
                    this.commentsVisible = false
                    this.commentsAllowed = false
                    return
                } else if (this.stopComments) {
                    this.commentsVisible = true
                    this.commentsAllowed = false
                    return
                }
            } catch (err) {
                console.log('error in getAllowComments: ', err)
                this.commentsVisible = false
                this.commentsAllowed = false
                return
            }
            // OLD VERSION BELOW!!!  REMOVE WHEN READY
            //     if (this.particId == store.state.myPid) {
            //         return this.commentsAllowed = true
            //     }
            //     if (!this.privatePartic) {
            //         return this.commentsAllowed = true
            //     }
            //     const particMemberships = await apiCalls.getEntries('/memberships/', {
            //         'participant': this.particId,
            //         'status': 'active',
            //     })
            //     if (particMemberships && particMemberships.data.length > 0) {
            //         console.log('particMemberships: ', particMemberships.data)
            //         particClubsList = particMemberships.data.map(a => a.club)
            //         console.log('particClubsList: ', particClubsList)
            //     } else {
            //         return this.commentsAllowed = false
            //     }
            //     const myMemberships = await apiCalls.getEntries('/memberships/', {
            //         'participant': store.state.myPid,
            //         'status': 'active',
            //     })
            //     if (myMemberships && myMemberships.data.length > 0) {
            //         console.log('myMembershipsInPortfolio: ', myMemberships.data)
            //         myClubsList = myMemberships.data.map(a => a.club)
            //         console.log('myClubsList: ', myClubsList)
            //     } else {
            //         return this.commentsAllowed = false
            //     }
            //     this.commentsAllowed = particClubsList.some(item => myClubsList.includes(item))
            // } catch (err) {
            //     console.log('error in commonMemberships: ', err)
            //     return this.commentsAllowed = false
            // }
        },

        toggleAlert(entry, index) {

            if (this.showAlert[index]) {
                this.showAlert[index] = false
                this.modifyAbove[index] = false
                this.modifyBelow[index] = false

            } else if (!this.showAlert[index]) {
                if (Number(entry.alertabove) > 0) {
                    console.log('entry.alertabove: ', entry.alertabove)
                    this.alertAbove[index] = Number(entry.alertabove)
                    this.alertAbove[index] = Number(this.alertAbove[index].toFixed(4))
                    console.log('this.alertAbove[index]: ', this.alertAbove[index])
                    console.log('this.modifyAbove[index]: ', this.modifyAbove[index])
                    console.log('this.modifyBelow[index]: ', this.modifyBelow[index])

                } else {
                    this.alertAbove[index] = ''
                }
                if (Number(entry.alertbelow) > 0) {
                    console.log('entry.alertbelow: ', entry.alertbelow)
                    this.alertBelow[index] = Number(entry.alertbelow)
                    this.alertBelow[index] = Number(this.alertBelow[index].toFixed(4))
                    console.log('this.alertAbove[index]: ', this.alertAbove[index])
                } else {
                    this.alertBelow[index] = ''
                }
                // if (this.showAlert[index]) {
                //     this.showAlert[index] = false
                // } else if (!this.showAlert[index]) {
                this.showComment[index] = false
                this.showAlert[index] = true
                setTimeout(() => { // NEW 20230308 
                    return document.getElementById('wtcalert-' + index).scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    })
                }, 0)
                // }
            }
        },
        validAlert(input) {
            console.log('input: ', input)
            if (typeof input == 'number' && input > 0 && input < 1000000) {
                if (Math.round(input * 10000) / 10000 != input) {
                    input = Math.round(input * 10000) / 10000
                }
                return true
            } else {
                console.log('Input not valid')
                console.log('typeof input: ', typeof input)
                return false
            }
        },
        modifyAlert(index, typ) {
            if (typ == 'above') {
                this.modifyAbove[index] = true
                this.alertAbove[index] = ''
                setTimeout(() => { // NEW 20230308
                    document.getElementById('wtcabovebox-' + index).focus()
                }, 0)
            } else if (typ == 'below') {
                console.log('index2: ', index)
                this.modifyBelow[index] = true
                this.alertBelow[index] = ''
                setTimeout(() => { // NEW 20230308
                    document.getElementById('wtcbelowbox-' + index).focus()
                }, 0)
            } else {
                return
            }
        },
        async enterAlert(entry, index, typ, enter) {
            try {
                console.log('entry: ', entry)
                if (typ == 'above') {
                    if (this.alertAbove[index] <= 0 || this.alertAbove[index] == '') {
                        return this.cancelAlert(entry, index, typ)
                    }
                    // if (this.alertAbove[index] == '') {
                    //     if (Number(entry.alertabove) > 0) {
                    //         this.alertAbove[index] = Number(entry.alertabove)
                    //         this.alertAbove[index] = Number(this.alertAbove[index].toFixed(4))
                    //         if (this.alertAbove[index] == 0) {
                    //             this.alertAbove[index] = ''
                    //         }
                    //     }
                    //     return
                    // }
                    if (enter == false) {
                        this.alertAbove[index] = 0
                    }
                    // this.alertBelow[index] = Number(this.alertBelow[index])
                    console.log('this.alertBelow[index]: ', typeof this.alertAbove[index])
                    this.alertAbove[index] = Number(this.alertAbove[index])
                    this.alertAbove[index] = Number(this.alertAbove[index].toFixed(4))
                    console.log('this.alertAbove[index] 2: ', typeof this.alertAbove[index])
                    if (this.alertAbove[index] == Number(entry.alertabove)) {
                        console.log('Was the same')
                        this.modifyAbove[index] = false
                    }
                    if (enter == false || this.validAlert(this.alertAbove[index])) {
                        console.log('Patching: ', this.alertAbove[index])
                        const patchedpos = await apiCalls.patchEntry('/watchassets/' + entry.id + '/', {
                            'alertabove': this.alertAbove[index]
                        })
                        if (patchedpos) {
                            console.log('patchedwtc: ', patchedpos.data)
                            entry.alertabove = this.alertAbove[index]
                            if (entry.alertabove == 0) {
                                this.alertAbove[index] = ''
                            }
                            this.modifyAbove[index] = false
                            setTimeout(() => {
                                return document.getElementById('wtcalert-' + index).scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'center'
                                })
                            }, 0)
                        } else {
                            console.log('watchasset not patched')
                        }
                    } else {
                        console.log('Input not entered')
                        console.log("this.alertBelow[index] != '': ", this.alertAbove[index] != '')
                        console.log("this.alertBelow[index] != Number(entry.alertabove): ", this.alertAbove[index] != Number(entry.alertabove))
                        return
                    }
                } else if (typ == 'below') {
                    if (this.alertBelow[index] <= 0 || this.alertBelow[index] == '') {
                        return this.cancelAlert(entry, index, typ)
                    }
                    // if (this.alertBelow[index] == '') {
                    //     if (Number(entry.alertbelow) > 0) {
                    //         this.alertBelow[index] = Number(entry.alertbelow)
                    //         this.alertBelow[index] = Number(this.alertBelow[index].toFixed(4))
                    //         if (this.alertBelow[index] == 0) {
                    //             this.alertBelow[index] = ''
                    //         }
                    //     }
                    //     return
                    // }
                    if (enter == false) {
                        this.alertBelow[index] = 0
                    }
                    // this.alertBelow[index] = Number(this.alertBelow[index])
                    console.log('this.alertBelow[index]: ', typeof this.alertBelow[index])
                    this.alertBelow[index] = Number(this.alertBelow[index])
                    this.alertBelow[index] = Number(this.alertBelow[index].toFixed(4))
                    console.log('this.alertBelow[index] 2: ', typeof this.alertBelow[index])
                    // if (Math.round(this.alertBelow[index] * 10000)/10000 != this.alertBelow[index]) {
                    //     // this.alertBelow[index] = Math.round(this.alertBelow[index] * 10000)/10000
                    //     Number(this.alertBelow[index].toFixed(4))
                    // }
                    if (this.alertBelow[index] == Number(entry.alertbelow)) {
                        console.log('Was the same')
                        this.modifyBelow[index] = false
                    }
                    if (enter == false || this.validAlert(this.alertBelow[index])) {
                        const patchedpos = await apiCalls.patchEntry('/watchassets/' + entry.id + '/', {
                            'alertbelow': this.alertBelow[index]
                        })
                        if (patchedpos) {
                            console.log('patchedwtc: ', patchedpos.data)
                            entry.alertbelow = this.alertBelow[index]
                            if (entry.alertbelow == 0) {
                                this.alertBelow[index] = ''
                            }
                            this.modifyBelow[index] = false
                            setTimeout(() => {
                                return document.getElementById('wtcalert-' + index).scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'center'
                                })
                            }, 0)
                        } else {
                            console.log('wacthasset not patched')
                        }
                    } else {
                        console.log('Input not entered')
                        console.log("this.alertBelow[index] != '': ", this.alertBelow[index] != '')
                        console.log("this.alertBelow[index] != Number(entry.alertbelow): ", this.alertBelow[index] != Number(entry.alertbelow))
                        return
                    }
                } else {
                    return
                }
            } catch (err) {
                console.log(err)
            }
        },
        cancelAlert(entry, index, typ) {
            if (typ == 'above') {
                if (entry.alertabove == 0) {
                    this.alertAbove[index] = ''
                    return this.modifyAbove[index] = false
                }
                this.alertAbove[index] = Number(entry.alertabove)
                this.alertAbove[index] = Number(this.alertAbove[index].toFixed(4))
                return this.modifyAbove[index] = false
            } else if (typ == 'below') {
                if (entry.alertbelow == 0) {
                    this.alertBelow[index] = ''
                    return this.modifyBelow[index] = false
                }
                this.alertBelow[index] = Number(entry.alertbelow)
                this.alertBelow[index] = Number(this.alertBelow[index].toFixed(4))
                return this.modifyBelow[index] = false
            } else {
                return
            }
        },


        onResize() {
            this.width = window.innerWidth
        },
        //     async getCommonMembership() {
        //         try {
        //             this.commentsAllowed = false
        //             let partID = ''
        //             let particClubsList = []
        //             let myClubsList = []
        //             if (store.state.particId && store.state.particId != store.state.myPid) {
        //                 partID = store.state.particId
        //                 console.log('partID: ', partID)
        //             } else if (store.state.particId && store.state.particId == store.state.myPid) {
        //                 console.log('store.state.myPid: ', store.state.myPid)
        //                 console.log('store.state.particId: ', store.state.particId)
        //                 return this.commentsAllowed = true
        //             } else if (!store.state.particId) {
        //                 console.log('No particId in store')
        //                 return this.commentsAllowed = true
        //             } else {
        //                 console.log('store.state.myPid: ', store.state.myPid)
        //                 console.log('store.state.particId: ', store.state.particId)
        //                 console.log('somewhat unexpected')
        //                 return
        //             }
        //             const particMemberships = await apiCalls.getEntries('/memberships/', {
        //                 'participant': partID,
        //                 'status': 'active',
        //             })
        //             if (particMemberships && particMemberships.data.length > 0) {
        //                 console.log('particMemberships: ', particMemberships.data)
        //                 particClubsList = particMemberships.data.map(a => a.club)
        //                 console.log('particClubsList: ', particClubsList)
        //             } else {
        //                 return this.commentsAllowed = false
        //             }
        //             const myMemberships = await apiCalls.getEntries('/memberships/', {
        //                 'participant': store.state.myPid,
        //                 'status': 'active',
        //             })
        //             if (myMemberships && myMemberships.data.length > 0) {
        //                 console.log('myMembershipsInWatchlist: ', myMemberships.data)
        //                 myClubsList = myMemberships.data.map(a => a.club)
        //                 console.log('myClubsList: ', myClubsList)
        //             } else {
        //                 return this.commentsAllowed = false
        //             }
        //             this.commentsAllowed = particClubsList.some(item => myClubsList.includes(item))
        //         } catch (err) {
        //             console.log('error in commonMemberships: ', err)
        //             return this.commentsAllowed = false
        //         }
        //     }

    },
    async created() {
        try {
            console.log('creating watchlist 1')
            this.baseCurrency = store.state.myCur // temporarily here to avoid error in rendering until it gets it through getParticipant
            await this.getParticipant()
            if (store.state.page != '/investor' || (store.state.commentsVisible == null || store.state.commentsAllowed == null)) {
                await this.getAllowComments()
                console.log('AAAATTTT comments settings not used not used in watchlist created: ', store.state.page)
            } else {
                this.commentsVisible = store.state.commentsVisible
                this.commentsAllowed = store.state.commentsAllowed
                console.log('AAAATTTT used store.state.commentsVisible and store.state.commentsAllowed in watchlist created: ', store.state.page)
            }
            await this.getAssets()
            console.log('creating watchlist 2')
        } catch (err) {
            console.log(err)
        }
    },
    mounted() {
        window.addEventListener('resize', this.onResize)
        this.width = window.innerWidth
        this.myRemoveModal = document.getElementById('removeModal')
        this.bsRemoveModal = new Modal(this.myRemoveModal)
    },
    unmounted() {
        window.removeEventListener('resize', this.onResize)
    }
}
</script>

<style scoped>
.entry-card {
    background-color: #413e3c !important;
    height: 60px;
    width: 100%;
    margin: 3px auto;
    padding-top: 5px;
    text-align: center;
    font-size: 0.8rem;
}

.empty-card {
    background-color: #413e3c;
    height: 40px;
    margin-top: 70px;
    padding-top: 6px;
    text-align: center;
}

/* .top-card {
    margin-top: 70px;
    height: 40px;
}

.cell-empty {
    font-size: 0.9rem;
} */

.card-headers {
    height: 30px;
    background-color: #272522 !important;
    /* color: #d97b2d !important;
    color: #f48043 !important; */
    margin-bottom: -5px;
    margin-top: 55px;
    /* recent was 55 */
    text-align: center;
    border: none;
    font-size: 0.8rem;
}

.cell-headers {
    color: #6c6c6c !important;
    font-size: 1rem;
    /* text-decoration: underline; */
    margin-left: -3px;
    padding-left: 0px;
    padding-right: 0px;
}

.head-1 {
    margin-left: 9px;
    margin-right: -9px;
}

.head-2 {
    margin-left: 5px;
    margin-right: -12px;
    padding-left: 0px;
    padding-right: 0px;
}

.head-3 {
    margin-left: 3px;
    margin-right: 2px;
}

.cell-tick {
    /* left text*/
    position: relative;
    color: #dbd7d4 !important;
    text-decoration: underline;
    font-size: 1rem;
    text-align: center;
    /* left: 4px !important; */

    margin-left: -3px;
    /* font-weight: bold; */
}

/* .cell-targ {
    color: #dbd7d4 !important;
} */
.cell-pri {
    /* right text*/
    color: #dbd7d4 !important;
}

.cell-neg {
    /* color: #b33430; */
    /* color: #ff8a65; */
    /* color: #fc7f59; */
    color: #e25651;
}

.cell-neg2 {
    /* color: #b33430; */
    /* color: #8f0f0a; */
    /* color: #a50c07; */
    /* color: #ffab91; */
    color: #3d0402;
    filter: brightness(1.3);
}

.cell-pos {
    /* color: #7fa650; */
    color: #64dd17;
}

.cell-qty {
    font-size: 0.9rem;
    text-align: center;
    text-align: start;
    position: relative;
    left: 8px !important;
}

.cell-curr {
    /* right text*/
    color: #babab9 !important;
}

.cell-left {
    padding-left: 10px;
    padding-right: 0px;
    margin-left: 2px;
    margin-right: -3px;
}

.cell-mid1 {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 5px;
    margin-right: -10px;
}

.cell-mid2 {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
}

.cell-mid3 {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
}

.cell-right {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
}

.entry-subcard {
    /* left-right sides of card */
    /* background-color: #7f7D7A !important; */
    background-color: #62605E !important;

    height: 140px;
    width: 100%;
    margin: 3px auto;
    padding-top: 10px;
    text-align: center;
    font-size: 0.8rem;
    color: #000 !important;
    padding-top: 5px;
    /* for the text inside the card */
    /* border-radius: 5px; */
    /* color: #babab9 !important; */
}

.entry-subcard-wtc {
    /* **********Additional for wtc************ */
    height: 95px;
}

.cell-subc {
    color: #fff;
    font-size: 0.8rem;
    text-align: center;
}

.cell-subc-left {
    padding-left: 8px;
    padding-right: 0px;
    margin-left: 2px;
    margin-right: -2px;
    margin-top: -2px;
}

.cell-subc-mid1 {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 12px;
    margin-right: -10px;
}

.cell-subc-mid2 {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
}

.mid2-wtc {
    /* **********Additional for wtc************ */
    margin-left: -6px;
    margin-right: 6px;
}

.cell-subc-mid3 {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: -3px;
    margin-right: 3px;
}

.mid3-wtc {
    /* **********Additional for wtc************ */
    margin-left: -7px;
    margin-right: 7px;
}

.cell-subc-right {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: -10px;
    margin-right: 0px;
}

.subc-head {
    /* text-decoration: underline; */
    text-decoration: none;
    margin-bottom: 4px;
}

.cell-titl {
    font-size: 0.9rem;
    text-align: start;
    position: relative;
    left: 8px !important;
}

.last-row-subc-wtc {
    /* ************Additional for wtc*********** */
    margin-top: -4px;
}

.cell-subc-low1 {
    margin-top: 15px;
    margin-left: 0px;
}

.cell-subc-low2 {
    margin-top: 15px;
    margin-left: -5px;
}

.cell-subc-low3 {
    margin-top: 15px;
    margin-left: -2px;
}

.cell-subc-low-wtc {
    /* ************Additional for wtc*********** */
    padding-top: 17px;
}

.low2-wtc {
    /* ************Additional for wtc*********** */
    margin-left: -15px;
}

.comments-cell {
    font-size: 1rem;
    text-align: start;
    position: relative;
    left: 4px !important;
}

.cell-neu {
    color: #fff;
}

.cell-light {
    text-decoration: underline;
    color: #e2e2e2;
}

.cell-disabled {
    padding-right: 0px;
    text-decoration: none;
    color: #b2b2b9 !important;
    overflow-wrap: normal;
}

.closepos-cell {
    font-size: 1rem;
    text-align: center;
    text-decoration: underline;
    color: #dbd7d4 !important;
    /* position: relative; */
    /* margin-right: 10px; */
}

/* .close-btn {
    margin-top: -10px;
    margin-left: -5px;
} */

.close-butt {
    height: 25px !important;
    width: 10px !important;
    text-align: start !important;
    background-color: #b33430;
    color: white;
    margin-top: 10px !important;
}

.btn-tex {
    position: relative;
    top: -8px;
    left: -4px;
}

/* ***** MODAL ****************************************************** */
.modal-area {
    background-color: #dbd7d4;
    background-color: #e4e4e4;
}

.modal-title2 {
    color: #4d4d4d;
}

.modal-body2 {
    color: #4d4d4d;
    /* justify-content: flex-start; */
}

.top-row-modal {
    margin-top: 0px;
}

.modal-row {
    text-align: center;
}

.btn-secondary-modal {
    margin-left: 20px !important;
    height: 38px;
    margin-top: -3px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.modal-footer {
    display: flex;
    justify-content: center;
}

.modal-btn {
    /* position: relative; */
    /* left: 0px; */
    /* width: 140px; */
    padding-left: 20px;
    padding-right: 20px;
    height: 38px;
    padding-top: 5px;
    padding-bottom: 5px;
    /* margin-right: 10px !important; */
    /* margin-left: -80px !important; */
    margin-top: -3px !important;
    background-color: #2F5C85 !important;
    color: white !important;
    border: none !important;
}

/*no*/
/* .mid-row-modal {
    margin-top: 20px;
}
.col-bold { 
    font-weight: bold;
}

.last-row-modal1 {
    text-align: start;
    padding: 0 0;
    font-weight: bold;
    margin-top: 3px;
} */

/* ********* COMMENTS ************* */
/* .comments-card {
    margin: 5px 0;
    color: #000;
    background-color: #dbd7d4;
} */
.comments-card {
    margin: 3px 0;
    /* color: #dbd7d4; */
    color: #fff;
    /* background-color: #62605E;
    background-color: #7f7D7A; */
    background-color: #7C7B78;
    border: 1px;
    padding: 5px 5px;
    font-size: 0.9rem;
}

.line-break-and-tab {
    white-space: pre-wrap;
}

/* #just-line-break {
    white-space: pre-line;
} */
.comment-textarea {
    height: 150px !important;
    /* background-color: #d1d1d1; */
    background-color: #dbd7d4;
    color: #000;
    margin-top: 0px;
    margin-bottom: 5px;
    padding-top: 10px !important;
    font-size: 1rem;
}

.addcomm-btn {
    background-color: #2F5C85 !important;
    /* background-color: #6a6867 !important; */
    color: #fff !important;
    margin-bottom: 10px;
}

.entercomm-btn {
    background-color: #2F5C85 !important;
    color: #fff !important;
    margin-bottom: 10px;
    margin-right: 20px;
}

.btn-secondary-comment {
    /* margin-left: 20px !important; */
    height: 38px;
    margin-bottom: 10px;
    margin-left: 0px;
    /* margin-top: -3px; */
    /* padding-top: 2px; */
}

/* ********* END COMMENTS ********** */



.btn-alert-set {
    background-color: #2F5C85 !important;
    /* margin-right: 20px; */
    width: 100%;
    padding-top: 0px;
    padding-bottom: 4px;
    padding-left: 10px;
    padding-right: 10px;
    height: 25px;
    /* font-weight: bold; */
    color: #fff !important;
    margin-left: 0px;
    margin-right: 0px;
}

.btn-alert-modify {
    background-color: #2F5C85 !important;
    width: 70px;
    padding-top: 0px;
    padding-bottom: 4px;
    padding-left: 0px;
    padding-right: 0px;
    height: 25px;
    /* font-weight: bold; */
    color: #fff !important;
    margin-left: 5px;
    margin-right: 0px;
}

.btn-alert-enter {
    background-color: #2F5C85 !important;
    /* margin-right: 20px; */
    width: 70px;
    padding-top: 0px;
    padding-bottom: 4px;
    padding-left: 10px;
    padding-right: 10px;
    height: 25px;
    /* font-weight: bold; */
    color: #fff !important;
    margin-left: 5px;
    margin-right: 0px;
}

.btn-alert-cancel {
    background-color: #525259 !important;
    width: 65px;
    margin-top: -1px;
    padding-top: 0px !important;
    padding-bottom: 5px !important;
    padding-left: 0px;
    padding-right: 0px;
    height: 28px;
    /* font-weight: bold; */
    color: #fff !important;
    margin-left: 50px;
    margin-right: 0px;
}

.btn-alert-remove {
    background-color: rgba(214, 55, 55, 0.897) !important;
    width: 65px;
    margin-top: -1px;
    padding-top: 0px !important;
    padding-bottom: 5px !important;
    padding-left: 0px;
    padding-right: 0px;
    height: 28px;
    /* font-weight: bold; */
    color: #fff !important;
    margin-left: 50px;
    margin-right: 0px;
}


/* ******************* BTN 3D ******************* */
.btn3d {
    border: 0;
    transition: all 40ms linear;
}

.btn3d.btn-primar {
    box-shadow: 0 0 0 1px #2f5b85a1 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

.btn3d.btn-primar:hover {
    box-shadow: 0 0 0 1px #4a95db34 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

/* ******************* END BTN 3D ******************* */

.title-row {
    padding-top: 10px;
    margin-bottom: -50px;
}


.alert-row-1 {
    margin-top: 5px;
}

.alert-row-1-open {
    margin-top: 5px;
    margin-bottom: -2px;
}

.alert-row-2 {
    margin-top: 30px;
    margin-bottom: 10px;
}

.alert-row-2-open {
    margin-top: 30px;
    margin-bottom: 8px;
}

.col-prialert {
    background-color: #dad2d2;
    color: #000;
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: -5px;
    margin-right: 8px;
    padding-top: 2px;
    padding-bottom: 0px;
}

.col-alertbox {
    background-color: #fff;
    padding-bottom: 4px !important;
    margin-bottom: 0px !important;
}

.col-alert-enter {
    color: #fff;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: -18px;
    margin-right: -18px;
}

.col-alert-cancel {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    /* margin-right: -20px; */
}

.col-alert-set {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
}

.alert-box {
    height: 24px;
    width: 100% !important;
    text-align: center;
    padding-left: 0px !important;
    padding-right: 0px !important;
    background-color: #fff !important;
    color: #000 !important;
    font-weight: bold !important;
    font-size: 0.9rem !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    border: none !important;
}

.alert-box:focus {
    background-color: #fff;
    outline: none;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}
</style>
