<template>
<div>
    <div class="row top-row-notifications"></div>
    <div class="main-cont" ref="mainCont" id="main-cont">

        <div class="container messages-cont" id="messages-cont" ref="containerMessages" :style="{'padding-bottom': paddingBottom + 'px', 'padding-top': paddingTop + 'px'}">
            <div class="row top-row-messages" ref="topRowMessages"></div>
            <div v-for="entry, index in this.notifications" :key="entry.id">
                <div v-if="entry.notifier == this.myPid" class="card card-sent">
                    <div class="row">
                        <div class="col line-break-and-tab" :id="'msgsent-' + index">
                            <p> {{ entry.timeSent }} </p>
                        </div>
                        <div class="col-2">
                            <img v-if="entry.msgStatus != 'undefined'" class="msgsent-icon" :src="require('@/assets/images/'+ entry.msgStatus + '.png')" path="@/assets/images/">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col line-break-and-tab" :id="'notification-' + index">
                            <p> {{ entry.description }} </p>
                        </div>
                    </div>
                </div>

                <div v-else class="card card-received">
                    <div class="row">
                        <div class="col line-break-and-tab" :id="'msgsent-' + index">
                            <p> {{ entry.timeSent }} </p>
                        </div>
                        <div class="col-2">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col line-break-and-tab" :id="'notification-' + index">
                            <p> {{ entry.description }} </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container input-cont">
            <form>
                <!-- <div class="row justify-content-center gx-3 gy-0 pt-2" style="background-color: blue"> -->
                <div class="row input-row">
                    <div class="col-10 col-sm-11 input-col p-0">
                        <!-- <BaseInputEnter v-model="this.newmessage" @drop.prevent="false" @paste.prevent="false" id="input-msg"/></div> -->
                        <textarea id="input-area" class="form-control" placeholder="Message" v-model="newmessage" ref="textarea" @input="onInput" rows="1" maxlength="250" @drop.prevent="false" @paste.prevent="false"></textarea>
                        <!-- class="absolute rounded border border-gray-300 px-3 py-2 resize-none w-full bottom-0 outline-none focus:border-blue-500 hover:border-blue-500" -->

                    </div>
                    <div class="col-2 col-sm-1 send-col">
                        <img v-if="this.newmessage==''" class="send-icon" src="@/assets/images/send4.png">
                        <img v-else class="send-icon" src="@/assets/images/send3.png" role="button" @click.prevent="sendMessage(this.newmessage)">
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import apiCalls from '@/services/apiCalls.js'
import store from '@/store'
// import router from '@/router'
// import {
//     Modal
// } from 'bootstrap'
import {
    mapActions
} from 'vuex'
export default {
    name: 'TableConversation',
    data() {
        return {
            notifications: [],
            // time: [],
            // text: [],
            newmessage: '',
            messagecount: 0,
            paddingBottom: 40,
            paddingTop: 0,
            textareaHeight: 36,
            windowHeight: 0,
            firstHeight: 0,
            cardHeights: 0,
            myMessage: '',
            myTimeSent: '',
            myMsgStatus: '',
        }
    },
    props: [
        'category',
    ],
    computed: {
        myPid() {
            return store.state.myPid
        },
    },
    methods: {
        ...mapActions(['setAlertMsg', 'setRfrsh', 'setParticIcon', 'setIsLoading', 'setUnreadM']),
        async getMessages() {
            this.notifications = []
            this.cardHeights = 0
            this.messagecount = 0
            let contHeight = 0
            // this.time = []
            // this.text = []
            try {
                console.log('in getMessages, sender:', store.state.particId)
                let params = {}
                //     'notified': store.state.myPid,
                //     'topic': 'conversation',
                // }
                if (localStorage.getItem('cik') && localStorage.getItem('cna') && localStorage.getItem('cid')) {
                    params = {
                        'topic': 'conversation',
                        'club': localStorage.getItem('cid'),
                    }
                } else {
                    params = {
                        'topic': 'conversation',
                        'notified': store.state.myPid,
                        'notifier': store.state.particId,
                    }
                }
                const notifications = await apiCalls.getEntries('/notifications/', params)
                // const notifications2 = await apiCalls.getEntries('/notifications/', {
                //     'notifier': store.state.myPid,
                //     'notified': this.sender,
                //     'topic': 'message',
                // })

                // if ((notifications && notifications.data.length > 0) || (notifications2 && notifications2.data.length > 0)) {
                if (notifications && notifications.data.length > 0) {
                    this.notifications = notifications.data
                    // if (notifications2 && notifications2.data.length > 0) {
                    //     this.notifications2 = notifications2.data
                    //     this.notifications = this.notifications.concat(this.notifications2)
                    // }
                    console.log('notifications: ', this.notifications)
                    this.notifications.forEach(obj => {

                        if (obj.invitemessage) {
                            // obj.description = `${obj.invitemessage}

                            const messageWithLineBreaks = obj.invitemessage.replace(/\n/g, '<br>')

                            // this.time[this.messagecount] = obj.created.slice(0, 10) + '   ' +  obj.created.slice(11, 16)
                            // this.text[this.messagecount] = obj.invitemessage
                            // obj.description = `${obj.created.slice(0, 10)}&nbsp;&nbsp;&nbsp;${obj.created.slice(11, 16)}<br><b>${messageWithLineBreaks}</b><br>`
                            if (!obj.club || obj.notifier == store.state.myPid) {
                                obj.timeSent = `${obj.created.slice(0, 10)}&nbsp;&nbsp;&nbsp;${obj.created.slice(11, 16)}`
                            } else {
                                obj.timeSent = `(<b>${obj.notifieralias}</b>)&nbsp;&nbsp;&nbsp;${obj.created.slice(0, 10)}&nbsp;&nbsp;&nbsp;${obj.created.slice(11, 16)}`
                            }

                            if (obj.club) {
                                // obj.msgStatus = "undefined"
                                obj.msgStatus = "msgsent"
                            } else if (obj.status == 'unread') {
                                obj.msgStatus = "msgsent"
                            } else if (obj.status == 'dlvd') {
                                obj.msgStatus = "msgdlvd"
                            } else if (obj.status == 'read') {
                                obj.msgStatus = "msgread"
                            }
                            obj.description = `<b>${messageWithLineBreaks}</b>`

                        } else {
                            // obj.description = `Empty message.`
                            obj.timeSent = `-`
                            obj.msgStatus = `-`
                            obj.description = `-`
                        }

                        // document.getElementById('notification-' + msgIndex).innerHTML = obj.description
                        console.log('this.messagecount: ', this.messagecount)
                        let name = 'notification-' + this.messagecount
                        let timeCol = 'msgsent-' + this.messagecount

                        // console.log('name: ', name)
                        setTimeout(() => { // Seems that in reactive languages, like React, Vue etc focus command doesn't work if you don't place inside setTimeout. BUt unclear why here is needed!
                            if (document.getElementById(name)) {
                                document.getElementById(name).innerHTML = obj.description

                                if (document.getElementById(timeCol)) {
                                    document.getElementById(timeCol).innerHTML = obj.timeSent
                                }

                                if (this.$refs.topRowMessages.scrollHeight > (this.cardHeights + document.getElementById(name).scrollHeight + 27 + 38)) {
                                    this.cardHeights = this.cardHeights + document.getElementById(name).scrollHeight + 27
                                } else {
                                    this.cardHeights = this.$refs.topRowMessages.scrollHeight - 38
                                }
                                // console.log('cardHeights: ', this.cardHeights)
                                // console.log('mainCont height 1: ', this.$refs.mainCont.scrollHeight)
                                // console.log('containerMessages height 1: ', this.$refs.containerMessages.scrollHeight)
                                // console.log('topRowMessages height 1: ', this.$refs.topRowMessages.scrollHeight)

                            }

                        }, 0)
                        this.messagecount = this.messagecount += 1

                    })
                    // this.cardHeights = cardHeight
                    // console.log('this.cardHeights: ', this.cardHeights)
                    if (this.$refs.topRowMessages) {
                        contHeight = this.$refs.topRowMessages.scrollHeight
                        console.log('topRowMessages before: ', contHeight)

                        setTimeout(() => {
                            if (contHeight > this.cardHeights) {
                                this.$refs.topRowMessages.style.height = `${contHeight - this.cardHeights}px`
                            }
                            console.log('reduced topRowMessages from ' + contHeight + ' by ' + (this.cardHeights))
                            console.log('mainCont at the end: ', this.$refs.mainCont.scrollHeight)
                            console.log('containerMessages at the end: ', this.$refs.containerMessages.scrollHeight)
                            console.log('topRowMessages at the end: ', this.$refs.topRowMessages.scrollHeight)
                        }, 50)
                    }
                } else {
                    console.log('mainCont height 2: ', this.$refs.mainCont.scrollHeight)
                    console.log('containerMessages height 2: ', this.$refs.containerMessages.scrollHeight)
                    console.log('topRowMessages height 2: ', this.$refs.topRowMessages.scrollHeight)

                    // this.showEmptyNotifications = true
                }
            } catch (error) {
                return console.log('error in getMessages: ', error)
            }
        },
        async getParticipant() {
            try {
                if (store.state.particId != null) {
                    this.particId = store.state.particId
                    console.log('particId: ', this.particId)
                } else {
                    console.log('Missing store.state.particId in Conversation getParticipant - forcing logout')
                    await this.setRfrsh('/login')
                    return await this.setAlertMsg(`Network error. Please login again.${'\n\n'}Should error persist, please inform the administrators. Thank you.`)
                }
                const Participant = await apiCalls.getEntries('/participants/' + this.particId + '/', {})
                if (Participant && Participant.data != null) {
                    console.log('participant data: ', Participant.data)
                    localStorage.setItem('paic', Participant.data['usericon'])
                    await this.setParticIcon(Participant.data['usericon'])
                } else {
                    console.log('could not getParticipant in Conversation')
                    await this.setRfrsh('/login')
                    return await this.setAlertMsg(`Network error. Please login again.${'\n\n'}Should an error persist, please inform the administrators.`)
                }
            } catch (err) {
                console.log('error in Conversation/getParticipant: ', err)
            }
        },
        async sendMessage(newmessage) {
            try {
                this.newmessage = ''
                if (newmessage.length < 1) {
                    setTimeout(() => { // Seems that in reactive languages like React, Vue etc focus command doesn't work if you don't place inside setTimeout
                        // document.getElementById('floatingTextarea').focus()
                        if (document.getElementById('messages-cont')) {
                            document.getElementById('messages-cont').scrollIntoView({
                                behavior: 'smooth',
                                block: 'end'
                            })
                        }
                    }, 0)
                    return
                }
                let notification = null
                if (!localStorage.getItem('cna') || !localStorage.getItem('cik')) {
                    notification = await apiCalls.postEntry('/notifications/', {
                        'notifier': store.state.myPid,
                        'notifieralias': store.state.myAlias,
                        'notified': store.state.particId,
                        'topic': 'message',
                        'status': 'unread',
                        'invitemessage': newmessage,
                    })
                } else if (localStorage.getItem('cid')) {
                    notification = await apiCalls.postEntry('/notifications/', {
                        'notifier': store.state.myPid,
                        'notifieralias': store.state.myAlias,
                        'club': localStorage.getItem('cid'),
                        'topic': 'message',
                        'status': 'unread',
                        'invitemessage': newmessage,
                    })
                }

                if (notification.data) {
                    // router.go(0)
                    // this.messagecount +=1
                    let myDate = new Date().toISOString().slice(0, 16).replace('T', '  ')

                    this.myTimeSent = `${myDate}`
                    this.myMessage = `<b>${newmessage}</b>`
                    this.myMessage = this.myMessage.replace(/\n/g, '<br>')
                    this.myMsgStatus = require('@/assets/images/msgsent.png') // With require get the absolute path to the image, needed here.

                    // await this.setIsLoading(true)

                    // create a new card element and set its innerHTML to the new message
                    let newCard = document.createElement('div')
                    // newCard.classList.add("card", "card-sent"); // add "card" and "card-sent" class to new card element. Thsi did not work!
                    newCard.innerHTML = `
                        <div class="card card-sent" 
                            style="    
                            background-color: #52504E;
                            font-size: 0.9rem;
                            color: #d2d2d9;
                            width: 80%;
                            margin-left: 18%;
                            margin-right: 2%;
                            margin-bottom: 15px;
                            padding-top: 5px;
                            padding-bottom: 5px;
                            padding-left: 10px;
                            padding-right: 10px;
                            border-radius: 9px;
                            ">
                            <div class="row">
                                <div class="col line-break-and-tab" :id="'msgsent-' + ${this.messagecount}">
                                    ${this.myTimeSent}
                                </div>
                                <div class="col-2">
                                    <img style="    
                                        height: 12px;
                                        margin-top: 0px;
                                        margin-bottom: 4px;
                                        margin-left: 0px;
                                        margin-right: 0px;
                                        "
                                        src="${this.myMsgStatus}">
                                </div>
                            </div>  
                            <div class="row">
                                <div class="col line-break-and-tab" :id="'notification-' + index">
                                    ${this.myMessage}
                                </div>
                            </div>
                        `
                    const messagesContainer = document.getElementById('messages-cont')
                    messagesContainer.appendChild(newCard)

                    // clear user input
                    const textArea = document.getElementById("input-area")
                    textArea.value = ""
                    textArea.setAttribute('style', '')
                    messagesContainer.style.paddingBottom = "40px"

                    // messagesContainer.scrollTop = messagesContainer.scrollHeight
                    setTimeout(() => {
                        window.scrollTo(0, document.body.scrollHeight)
                        // this.setIsLoading(false)

                    }, 0)

                    // setTimeout(() => {
                    //     this.setIsLoading(false)
                    // }, 10)

                    // console.log('Existing participant found and Notification sent: ', notification.data)
                    // this.messagecount = this.messagecount += 1
                    // this.time[this.messagecount] = new Date().toISOString().slice(0, 16).replace('T', ' @')
                    // this.text[this.messagecount] = this.newmessage
                    // let msgText = `${this.time[this.messagecount]}<br><b>${this.text[this.messagecount]}</b><br><br>`
                    // this.newmessage = ''

                    // let name = 'notification-' + this.messagecount
                    // // console.log('name: ', name)
                    // setTimeout(function () { // Seems that in reactive languages, like React, Vue etc focus command doesn't work if you don't place inside setTimeout. BUt unclear why here is needed!
                    //     document.getElementById(name).innerHTML = msgText
                    // }, 0)
                    // return await this.setAlertMsg(`Your message has been sent to ${store.state.particAlias}.`)

                } else {
                    return await this.setAlertMsg(`Sorry, it appears that the message could not be sent.${'\n\n'}Please try again or inform the site administrators.`)
                }
            } catch (err) {
                console.log('Error in sendMessage: ', err)
            }
        },
        onResize() {
            console.log('RESIZED')
            // this.width = window.innerWidth
            // setTimeout(() => {
            //     if (document.getElementById('input-msg')) {
            //         document.getElementById('input-msg').scrollIntoView({
            //             behavior: 'smooth',
            //             block: 'start'
            //         })
            //     }
            // }, 0)
        },
        // onFocus() {
        //     alert('FOCUSSED')
        //     setTimeout(function () { // Seems that in reactive languages like React, Vue etc focus command doesn't work if you don't place inside setTimeout
        //         console.log('HERE3')
        //         document.getElementById('notification' + this.messagecount).scrollIntoView({
        //             behavior: 'smooth',
        //             block: 'center'
        //         })
        //     }, 0)
        //     // this.width = window.innerWidth

        // },

        async onInput() {
            // Set the textarea's height to auto to get its new height
            this.$refs.textarea.style.height = 'auto'
            const height = this.$refs.textarea.scrollHeight

            // Set the new height of the textarea
            this.$refs.textarea.style.height = `${height}px`

            if (height != this.textareaHeight) {
                if (height > this.textareaHeight) {
                    // Update the padding of the message-cards container
                    this.paddingBottom += 24
                    this.paddingTop -= 24
                } else if (height < this.textareaHeight) {
                    // Update the padding of the message-cards container
                    this.paddingBottom -= 24
                    this.paddingTop += 24
                }
                this.textareaHeight = height
                setTimeout(() => {
                    console.log('triggered scroll: ', this.textareaHeight)
                    window.scrollTo(0, document.body.scrollHeight)
                    // document.getElementById('messages-cont').scrollIntoView({
                    //     behavior: 'smooth',
                    //     block: 'end'
                    // })
                }, 0)
            }
        },
        async updateMessageBar() {
            try {
                let params = {}
                params = {
                    'notified': store.state.myPid,
                    'status': 'messagebar',
                }
                const notifications = await apiCalls.getEntries('/notifications/', params)
                if (notifications && notifications.data.length > 0) {
                    console.log('notificationsIn updateMessageBar: ', notifications.data)
                    const messageCount = notifications.data.length
                    console.log('messageCount: ', messageCount)
                    // const myParticNotifiers= notifications.data.filter(obj => { return obj.topic == 'message' }).map(a => a.notifier)
                    // const myClubNotifiers= notifications.data.filter(obj => { return (obj.topic == 'message' && obj.club) }).map(a => a.club)
                    // const myMessagesId= myMessageNotifications.map(a => a.id)
                    // console.log('Notifiers list: ', myParticNotifiers)
                    // console.log('myMessagesId: ', myMessagesId)
                    // console.log('messageCount > 0: ', messageCount > 0)

                    let unreadMessageCount = 0
                    let readList = []
                    // console.log('myMessageNotifications: ', myMessageNotifications)
                    notifications.data.forEach(msg => {
                        // console.log('msg: ', msg)
                        // console.log('msg.club: ', msg.club)
                        if (msg.status == 'unread' || msg.status == 'dlvd') {
                            unreadMessageCount += 1
                        } else if (msg.club) {
                            readList = msg.status.split(',')
                            // console.log('typeof store.state.myPid: ', typeof store.state.myPid)
                            // console.log('typeof readList[0]: ', typeof store.state.myPid)
                            // console.log('readList in if messagecount: ', readList)
                            // console.log('store.state.myPid: ', store.state.myPid)
                            // console.log('store.state.myPid == readList[0]: ', store.state.myPid == readList[0])
                            // console.log('readList.indexOf(store.state.myPid): ', readList.indexOf(store.state.myPid))
                            if (readList.indexOf(store.state.myPid.toString()) == -1) {
                                unreadMessageCount += 1
                            }
                        }
                    })
                    await this.setUnreadM(unreadMessageCount)
                    localStorage.setItem('unrm', unreadMessageCount)
                } else {
                    await this.setUnreadM(0)
                    localStorage.setItem('unrm', 0)
                }
            } catch (error) {
                return console.log('error in Conversation/updateMessagebar: ', error)
            }
        },
    },
    async created() {
        try {
            console.log('router(-1): ', window.history.state.back)
            if (!localStorage.getItem('cik') && !localStorage.getItem('cna')) {
                // this.firstHeight = document.getElementById('main-cont').scrollHeight
                // console.log('this.firstHeight: ', this.firstHeight)
                await this.getParticipant()
                // this.firstHeight = document.getElementById('messages-cont').scrollHeight
                // console.log('this.firstHeight: ', this.firstHeight)
            }
            await this.getMessages()
            // scrolled = document.getElementById('messages-cont').scrollHeight
            // let scrolled = 0
            // setTimeout(function () {
            //     scrolled = document.getElementById('messages-cont').scrollHeight
            //     console.log('scrolled: ', scrolled)
            // }, 50)
            // setTimeout(function () {

            // const contHeight = this.$refs.topRowMessages.scrollHeight
            // console.log('contHeight: ', contHeight)
            // // }, 100)

            // // setTimeout(function () {
            // this.$refs.topRowMessages.style.height = `${contHeight-160}px`
            // // }, 100)
            if (store.state.unreadm > 0 && window.history.state.back != '/messages') { // only update unread messages in Navbar if not coming from /messages (in that case will update automatically)
                await this.updateMessageBar() // to update the navbar messages, in case there were unread messages in the chat being opened
            }

            if (this.$refs.mainCont) {

                this.windowHeight = window.innerHeight
                console.log('innerHeight in created: ', this.windowHeight)
                // this.$refs.mainCont.style.height = `${this.windowHeight}px`

            }
            setTimeout(() => { // Seems that in reactive languages like React, Vue etc focus command doesn't work if you don't place inside setTimeout
                // document.getElementById('floatingTextarea').focus()
                window.scrollTo(0, document.body.scrollHeight)
                // document.getElementById('messages-cont').scrollIntoView({
                //     behavior: 'smooth',
                //     block: 'end'
                // })
            }, 0)

        } catch (err) {
            console.log(err)
        }
    },
    async mounted() {
        console.log('INSIDE mounted: ')
        window.addEventListener('resize', this.onResize)
        this.windowHeight = window.innerHeight
        console.log('innerHeight in mounted: ', this.windowHeight)

        this.$refs.topRowMessages.style.height = `${this.windowHeight -120}px`

    },

    unmounted() {
        window.removeEventListener('resize', this.onResize)
    },

}
</script>

<style scoped>
#main-cont {
    margin-top: -24px;
}

.top-row-notifications {
    margin-top: 0px;
}

.messages-cont {
    /* min-height: 500px; */
    /* height: 100%; */
    width: 100%;
    padding-top: 0px;
    padding-bottom: 15px;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
    background-color: #312e2b;
    /* display: flex; */
    flex-wrap: wrap;
    justify-content: space-between;
    overflow-y: auto;
    overflow-x: hidden;
    /* trying to prevent horizontal movements in iphone */
}

.top-row-messages {
    height: 550px;
}

.card-received {
    background-color: #7C7B78;
    /* background-color: #62605E; */
    /* background-color: #413e3c; */
    font-size: 0.9rem;
    color: #fff;
    width: 82%;
    margin-left: 2%;
    margin-right: 16%;
    margin-bottom: 15px;
    padding: 5px 8px 5px;
    color: #fff;
    border-radius: 9px;
}

.card-sent {

    /* background-color: #413e3c; */
    background-color: #52504E;
    font-size: 0.9rem;
    color: #d2d2d9;
    width: 80%;
    margin-left: 18%;
    margin-right: 2%;
    margin-bottom: 15px;
    padding: 5px 10px 5px;
    border-radius: 9px;
    /* text-align: center; */
}

.input-cont {
    /* left: 0; */
    /* width: 100%; */
    position: fixed;
    bottom: 40px;
    padding-bottom: 0px;
    padding-top: 0px;
    min-height: 50px;
    /* background-color: yellow; */
}

.input-row {
    background-color: #312e2b;
    /* min-height: 45px; */
    width: 100% !important;
    padding: 0;
    min-height: 50px;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 2%;
    ;
    /* background-color: blue; */
}

.input-col {
    min-height: 40px;
    /* width: 85%; */
    /* background-color: green; */
}

.send-col {
    /* background-color: red; */
    padding-top: 0px;
    padding-right: 0px;
    /* margin-right: -10px;
    margin-left: 0px; */
}

.textarea {
    /* background-color: #dbd7d4; */
    margin-top: 0px;
    margin-bottom: 0px;
    min-height: 40px;
    border: 1px solid #ccc;
    resize: none;
    font-size: 1rem;
    padding: 7px;
    box-sizing: border-box;
    /* border-radius: 10px !important; */
}

#input-area {
    width: 100%;
}

.send-icon {
    height: 43px;
    width: 45px;
    margin-top: 0px;
    margin-bottom: 4px;
    margin-left: 0px;
    margin-right: -12px;
    position: fixed;
    bottom: 40px;
}

.msgsent-icon {
    height: 12px;
    /* width: 12px; */
    margin-top: 0px;
    margin-bottom: 4px;
    margin-left: 0px;
    margin-right: 0px;
}
</style><style>
/* #input-msg {
    background-color: #f2f2f9 !important;
    height: 43px !important;
    border-radius: 9px !important;
    margin-left: 2%;
} */
</style>
