import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// ************** ABOVE ARE DEFAULTS **************

import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import "bootstrap/dist/css/bootstrap.min.css"
// import "bootstrap/dist/js/bootstrap.js"
import "bootstrap"

console.log('in MAIN...')


///////////////// BADGE *************************************************
// if (navigator.setAppBadge) {
//   console.log("1. The App Badging API is supported!")
//   alert("Badge supported")
// }
// if( "setAppBadge" in navigator && "clearAppBadge" in navigator){
//   console.log("2. The App Badging API is supported!");
// } else {
//   alert ('Not supported') // Android supports, IOS not!
// }
// if (!("Notification" in window)) { // Android supports, IOS not!
//   // Check if the browser supports notifications
//   alert("This browser does not support desktop notification")
// } else if (Notification.permission === "granted") {
//   console.log('Notifications are already granted')
//   alert('Notifications already granted')
// } else if (Notification.permission !== "denied") {
//   // We need to ask the user for permission
//   Notification.requestPermission().then((permission) => {
//     // If the user accepts, let's create a notification
//     if (permission === "granted") {
//       console.log('Notifications have been granted')
//       alert('Notifications granted')
//     }
//   })
// }
// **************************************************************************


const requireComponent = require.context(
    './components',
    false,
    /Base[A-Z]\w+\.(vue|js)$/
  )

const app = createApp(App)

requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName)
    const componentName = upperFirst(
        camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, '$1'))
      )
    app.component(componentName, componentConfig.default || componentConfig)
})

app.use(store)
app.use(router)
app.mount('#app')
