<template>
<div class="container">
    <transition name="fade-up-in" appear>
        <div v-if="this.shown" class="pwa-prompt" :class="[{ shown }]">
            Add to home screen?
            <button class="btn custom-btn btn3d btn-primar" @click="installPWA">
                Install
            </button>
            <button class="btn close-butt" @click="dismissPrompt">
                Dismiss
            </button>
        </div>
    </transition>
</div>
</template>

<script>
export default {
    data() {
        return {
            installEvent: undefined,
            shown: false,
        }

    },
    beforeMount() {
        // var w = document.documentElement.clientWidth || window.innerWidth
        var w = window.innerWidth
        //console.log('document.documentElement.clientWidth: ', document.documentElement.clientWidth)
        //console.log('window.innerWidth: ', window.innerWidth)
        //console.log('w: ', w)

        if (w <= 480) {
            window.addEventListener('beforeinstallprompt', (e) => {
                e.preventDefault()
                this.installEvent = e
                this.shown = true
            })
        }
    },
    methods: {
        installPWA() {
            this.installEvent.prompt()
            this.installEvent.userChoice.then((choice) => {
                this.dismissPrompt() // Hide the banner once the user's clicked
                if (choice.outcome === 'accepted') {
                    // Do something additional if the user chose to install
                } else {
                    // Do something additional if the user declined
                }
            })
        },
        dismissPrompt() {
            this.shown = false
        }
    }
}
</script>

<style scoped>
.pwa-prompt {
    position: fixed;
    left: 0px;
    top: 12%;
    background: var(--dark);
    color: var(--background);
    transform: translateY(0);
    /* margin-top: 50px; */
    /* margin-bottom: -110px; */
    font-size: 1rem;
    z-index: 20;
    background-color: #413e3c;
    height: 80px;
    width: 100%;
    padding-left: 3px;
    padding-right: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: solid 1px #727279;
}

.custom-btn {
    height: 36px;
    width: 64px;
    margin-top: -1px;
    margin-left: 7px;
    background-color: #2F5C85;
    color: white;
    padding-left: 10px;
    padding-top: 5px;
}

.close-butt {
    height: 36px;
    width: 74px;
    margin-top: -2px;
    margin-left: 10px;
    background-color: #6c6c6c;
    color: white;
    padding-left: 8px;
    padding-top: 5px;
    margin-right: 0px;
}

/* .sr {
    position: absolute;
    width: 1px;
    height: 1px;
    left: -100vw;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
} */

.fade-up-in-enter-active,
.fade-up-in-leave-active {
    transition: opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1), transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: translateY(0);
}

.fade-up-in-enter,
.fade-up-in-leave-to {
    opacity: 0;
    transform: translateY(4rem);
}

/* ******************* BTN 3D ******************* */
.btn3d {
    border: 0;
    transition: all 40ms linear;
}

.btn3d.btn-primar {
    box-shadow: 0 0 0 1px #2f5b85a1 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

.btn3d.btn-primar:hover {
    box-shadow: 0 0 0 1px #4a95db34 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

/* ******************* END BTN 3D ******************* */
</style>
