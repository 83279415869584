<template>
<div class="container my-5">
    <div class="row spacer-row"></div>
    <div class="row d-flex justify-content-center">
        <div class="card px-5 py-0 ">

            <div class="row">
                <div class="col-6 col-last-rad">
                    <!-- <span class="icon-rad"> -->
                    <!-- <BaseRadio v-model="participant.usericon" :value="11" label="" name="icons" id="I-11" /></span> -->
                    <!-- <img class="preview-icon" :src="image" alt=""> -->
                </div>
                <div class="col-3 col-browse">
                    <div class="mb-1 btn-pos">
                        <input style="display: none" type="file" @change="onFileSelected" ref="fileInput" accept="image/png, image/jpeg">
                        <button @click.prevent="$refs.fileInput.click()" class="btn browse-btn">Browse</button>
                    </div>
                </div>
                <div class="col-3">
                    <div v-if="this.image" class="preview-icon ms-3 me-0" :style="{ 'background-image': `url(${this.image})` }"></div>
                    <div v-else><img class="empty-image2" src="@/assets/images/I-11.png" alt="Account"></div>
                </div>
            </div>

            <div class="row">
                <div class="col-7">
                    <div class="mb-3 btn-pos"> <button @click.prevent="pickIcon" class="btn custom-btn btn3d btn-primar">Confirm change</button> </div>
                </div>
                <div class="col-5">
                    <div class="mb-3 "> <button @click.prevent="closeIcon" class="btn close-btn">Cancel</button> </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
// import store from '@/store'
import {
    mapActions
} from 'vuex'
import router from '@/router'
import apiCalls from '@/services/apiCalls.js'
export default {
    name: 'ClubIcon',
    props: [
        'clubId',
    ],
    data() {
        return {
            selectedFile: null,
            image: null,
        }
    },
    methods: {
        ...mapActions(['setAlertMsg', 'setRfrsh']),
        randomString(len) {
            let output = ''
            for (let i = 0; i < len; ++i) {
                output += (Math.floor(Math.random() * 16)).toString(16).toUpperCase()
            }
            console.log('output: ', output)
            return output
        },
        async pickIcon() {
            try {
                if (!this.selectedFile.name) {
                    return false
                }
                let filename = 'I-' + this.randomString(16)
                let extension = this.selectedFile.name.split(".").pop().toLowerCase()
                if (extension != 'png' && extension != 'jpg' && extension != 'jpeg') {
                    return await this.setAlertMsg(`Only .png, .jpg or .jpeg image files allowed.${'\n\n'}Please select an image in one of those formats and try again.`)
                }
                let full_filename = `${filename}.${extension}`
                let clubicon = filename
                await this.uploadImage(full_filename)
                // If just removed icon
                // const deleted = await apiCalls.postEntry('/delimage/', { //TODO find way in backend to check authentication when getting images
                //     club: this.clubId,
                // })
                // if (deleted != null) {
                //     console.log('deleted: ', deleted)
                // }
                await apiCalls.patchEntry('/clubs/' + this.clubId + '/', {
                    clubicon: clubicon
                })
                return router.push('/clubsadmin')
            } catch (error) {
                console.log('error in pickIcon: ', error)
            }
        },
        closeIcon() {
            return router.push('/clubsadmin')
        },
        async onFileSelected(event) {
            console.log(event)
            if (event.target.files[0].type.indexOf("image") == -1) {
                return await this.setAlertMsg(`File type not supported.${'\n\n'}Please select another image and try again.`)
            }
            if (event.target.files[0].size > 3200000) {
                let size = Math.round(Number(event.target.files[0].size) / 10000) / 100
                return await this.setAlertMsg(`Image too big (${size} MB)  -  max size 3.2 MB.${'\n\n'}Please select another image and try again.`)
            }
            this.selectedFile = event.target.files[0]
            this.image = URL.createObjectURL(this.selectedFile)
            console.log('this.image2: ', this.image)
        },
        async uploadImage(filename) {
            try {
                console.log('this.selectedFile: ', this.selectedFile)
                console.log('this.selectedFile.name: ', this.selectedFile.name)
                const fd = new FormData()
                // fd.append('use', 'c')
                fd.append('club', this.clubId)
                fd.append('image', this.selectedFile, filename)
                console.log('fd: ', fd)
                const uploaded = await apiCalls.postEntry('/postimage/', fd)
                // onUploadProgress: uploadEvent => {
                //     console.log('Upload Progress: ', +Math.round(uploadEvent.loaded / uploadEvent.total * 100) + '%')
                // }
                console.log('uploaded: ', uploaded)
            } catch (error) {
                console.log('error in uploadImage: ', error)
            }
        },
    },
}
</script>

<style scoped>
.spacer-row {
    height: 10px;
}

.card {
    margin-top: -15px;
    border-radius: 5px;
    border: none;
    width: 330px;
    height: 200px;
    background-color: #413e3c;
}

.custom-btn {
    position: relative;
    height: 37px;
    width: 135px;
    margin-top: 23px;
    margin-bottom: 0px;
    margin-left: -5px;
    background-color: #2F5C85;
    color: white;
    padding: 0 0;
}

.close-btn {
    position: relative;
    height: 37px;
    width: 70px;
    margin-top: 23px;
    margin-bottom: 0px;
    padding: 0 0;
    margin-left: 5px;
    background-color: #6c6c6c;
    color: white;
}

/* ******************* BTN 3D ******************* */
.btn3d {
    border: 0;
    transition: all 40ms linear;
}

.btn3d.btn-primar {
    box-shadow: 0 0 0 1px #2f5b85a1 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

.btn3d.btn-primar:hover {
    box-shadow: 0 0 0 1px #4a95db34 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

/* ******************* END BTN 3D ******************* */

/* .btn-pos {
    position: relative;
    left: -12px;
} */

.browse-btn {
    position: relative;
    height: 34px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 3px !important;
    padding-bottom: 5px !important;
    margin-top: 26px;
    margin-bottom: 0px;
    margin-left: -5px;
    background-color: #828289;
    color: white;
}

.preview-icon {
    height: 35px;
    width: 35px;
    margin-top: 24px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    object-fit: cover;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
}

.empty-image2 {
    height: 35px;
    width: 35px;
    margin-top: 24px;
    margin-bottom: 0px;
    margin-left: 15px;
    margin-right: 0px;
    border-radius: 50%;
    background-color: #e2e2e9;
    border: solid 1px #00b3dba6 !important;
}

.col-last-rad {
    padding-top: 30px;
    /* margin-right: -50px !important; */
}

.col-browse {
    margin-left: -52px !important;
}
</style>
