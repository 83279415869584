<template>
<div>
    <!-- <div class="main-cont-notifications"> -->

    <BaseModalPicture v-if="picUrl" :picUrl="picUrl" :topPos="topPos" :leftPos="leftPos" element='investor' @clicked="onModalClosed" />

    <div class="modal fade" id="removeModal" tabindex="-1" aria-labelledby="removeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content modal-area">
                <div class="modal-header">
                    <h5 class="modal-title modal-title2" id="removeModalLabel">Delete conversation</h5>
                    <button type="button" class="btn close-butt" data-bs-dismiss="modal" aria-label="Remove">
                        <div class="btn-tex">
                            x
                        </div>
                    </button>
                </div>

                <div class="modal-body modal-body2">
                    <div class="row top-row-modal">
                        <div class="col">
                            <p v-if="deleteStringUser!=''">Sure you want to delete this conversation with <b>{{deleteStringUser}}</b>?</p>
                            <p v-else-if="deleteStringClub!=''">Sure you want to delete this conversation with club <b>{{deleteStringClub}}</b>?</p>
                        </div>
                    </div>
                </div>

                <div class="modal-footer modal-body2 px-0">
                    <div class="row modal-row">
                        <div class="col">
                            <button type="button" @click.prevent="hideMessages()" class="btn btn-primary modal-btn">Delete conversation</button>
                            <button type="button" class="btn btn-secondary btn-secondary-modal" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="modal fade" id="blockModal" tabindex="-1" aria-labelledby="blockModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content modal-area">
                <div class="modal-header">
                    <h5 class="modal-title modal-title2" id="blockModalLabel">Block <b>{{ blockModalEntry.notifieralias }}</b></h5>
                    <button type="button" class="btn close-butt" data-bs-dismiss="modal" aria-label="Block">
                        <div class="btn-tex">
                            x
                        </div>
                    </button>
                </div>

                <div class="modal-body modal-body2">
                    <div class="row top-row-modal">
                        <div class="col">
                            <p>Sure you want to block <b>{{ blockModalEntry.notifieralias }}</b>?</p>
                            <p>If blocked, {{ blockModalEntry.notifieralias }} will not be able to send you messages or invitations, nor to view comments in your portfolio or watchlist</p>
                        </div>
                    </div>

                    <!-- <div class="row mt-3 mb-2">
                        <div class="col pt-0 ps-4">
                            <div class="form-check">
                                <BaseInputCheckbox label="Report message as inappropriate" v-model="this.reportComment" />
                            </div>
                        </div>
                    </div> -->

                </div>

                <div class="modal-footer modal-body2 px-0">
                    <div class="row modal-row">
                        <div class="col">
                            <button type="button" @click.prevent="blockInvestor(blockModalEntry)" class="btn btn-primary modal-btn">Block {{ blockModalEntry.notifieralias }}</button>
                            <button type="button" class="btn btn-secondary btn-secondary-modal" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="row top-row-notifications"></div>

    <div v-if="this.showEmptyNotifications" class="card entry-card-empty">
        <div class="row">
            <!-- <div class="col-12 cell-empty"> -->
            <div class="col-12">
                {{ this.emptyMsgNotifications }}
            </div>
        </div>
    </div>

    <!-- <div v-else-if="this.notifications.length > 0">
    <div class="card card-select">
        <div class="row">
            <div class="col-7 cell-headers head-1">

            </div>
            <div class="col-5 cell-headers head-2 delete-selected">
                <a href="" class="links-delete" @click.prevent="showRemoveModal();">
                    Delete selected
                </a>
            </div>
            <div class="col-2 select-box-col">
                <a href="" class="links-delete" @click.prevent="deleteNotifications();">
                    delete
                </a>
            </div>
        </div>
    </div>

    <div class="card card-headers">
        <div class="row">
            <div class="col-9 cell-headers head-1">

            </div>
            <div class="col-2 cell-headers head-2">

            </div>
            <div class="col-1 cell-headers head-3 d-flex justify-content-center select-all-box">
                <div class="form-check">
                    <BaseInputCheckbox v-model="this.allNotifications" @update:modelValue="toggleAllNotifications(this.allNotifications)" />
                </div>
            </div>
        </div>
    </div>
</div> -->

    <div v-for="entry, index in this.counterparts" :key="entry.id">
        <div class="card card-notification" :id="'notificationcard-'+index">
            <div class="row">
                <!-- <div class="col-3 cell-tick cell-col0 d-flex justify-content-center"> -->
                <div class="col-3 d-flex justify-content-center cell-clickable">
                    <span v-if="entry.usericon.length == 4"><img class="user-sticker-round" :src="require('@/assets/images/'+ entry.usericon + '.png')" path="@/assets/images/" @click.prevent="goConversation(entry)" alt="user1"></span>
                    <span v-else-if="entry.usericon.length > 4 && entry.src">
                        <div class="investor-image" :style="{ 'background-image': `url(${entry.src})` }" @click.prevent="enlargePic(entry.src, index)" :id="'investorPic-' + index"></div>
                    </span>
                    <span v-else><img class="user-sticker-round" :src="require('@/assets/images/'+ 'I-11' + '.png')" path="@/assets/images/" @click.prevent="goConversation(entry)" alt="user1"></span>

                    <!-- <span><img class="user-sticker-round" :src="require('@/assets/images/'+ 'I-11' + '.png')" path="@/assets/images/" @click.prevent="toggleSubcard(index)" alt="user1"></span> -->
                    <!-- <span><img class="user-sticker-round" :src="require('@/assets/images/'+ 'I-11' + '.png')" path="@/assets/images/" @click.prevent="goConversation(entry)" alt="user1"></span> -->

                </div>

                <div class="col-5 cell-member">
                    <!-- <a :class="(topicBold[index]==true) ? 'cell-neu cell-bold' : 'cell-neu'" href="" @click.prevent="toggleNotification(entry, index)">{{ entry.topic}}</a> -->
                    <a :class="(entry.topicBold==true) ? 'cell-faded cell-bold' : 'cell-faded'" href="" @click.prevent="goConversation(entry)">{{ entry.notifieralias}}</a>
                </div>
                <div v-if="entry.unreadMessages > 0" class="col-2 cell-newmsgs" @click.prevent="goConversation(entry)">
                    <!-- <a class="cell-neu" href="" @click.prevent="removeNotification(entry)">remove</a> -->
                    <span @click.prevent="goConversation(entry)"> {{entry.unreadMessages}} </span>
                    <!-- 1 -->
                </div>
                <div v-else class="col-2 cell-no-newmsgs">
                    <!-- <a class="cell-neu" href="" @click.prevent="removeNotification(entry)">remove</a> -->
                </div>
                <div class="col-2 d-flex justify-content-end checkbox-col">

                    <img class="three-dots" :src="require('@/assets/images/'+ 'dots3-1' + '.png')" path="@/assets/images/" @click.prevent="toggleNotification(entry, index)">

                    <!-- <button class="toggle-btn" role="button" @click="toggleNotification(entry, index)">
                    <span class="line"></span>
                    <span class="line"></span>
                    <span class="line"></span>
                </button> -->

                    <!-- <div class="form-check"> -->
                    <!-- <BaseInputCheckbox v-model="this.notifications[index]['delete']" /> -->
                    <!-- - -->
                    <!-- </div> -->

                    <!-- <button class="btn close-butt" role="button" @click.prevent="removeNotification(entry)">
                    <div class="btn-tex">
                        x
                    </div>
                </button> -->

                </div>
            </div>
        </div>

        <div v-show="this.showNotification[index]">
            <div class="card comments-card">

                <div class="row">
                    <div class="col line-break-and-tab" :id="'notification-'+ index">
                        <p> {{ entry.description }} </p>
                    </div>
                </div>

                <div class="row row-btn">
                    <div class="col d-flex justify-content-end">
                        <button v-if="!entry.club" type="button" class="btn-notif btn-ok btn3d" @click.prevent="this.showNotification[index] = false; showPage(entry)">Show User</button>
                        <button v-else type="button" class="btn-notif btn-ok btn3d" @click.prevent="this.showNotification[index] = false; showClub(entry)">Show Club</button>
                        <button v-if="!entry.club" type="button" class="btn-notif btn-report btn3d" @click.prevent="this.showNotification[index] = false; showBlockModal(entry)">Block user</button>
                        <!-- <button type="button" class="btn-notif btn-cancel1 btn3d" @click.prevent="this.showNotification[index] = false;">Cancel</button> -->
                        <button type="button" class="btn-notif btn-decline btn3d" @click.prevent="this.showNotification[index] = false; showRemoveModal(entry)">Delete</button>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="card entry-card-oldMsg">
        <div class="row">
            <!-- <div class="col-12 cell-empty"> -->
            <div class="col-12">
                {{ this.oldMessages }}
            </div>
        </div>
    </div>

    <!-- <div v-for="entry, index in this.notifications" :key="entry.id">
    <div class="card card-notification" :id="'notificationcard-'+index">
        <div class="row">
            <div class="col-3 d-flex justify-content-center">
            <span><img class="user-sticker-round" :src="require('@/assets/images/'+ 'I-11' + '.png')" path="@/assets/images/" @click.prevent="toggleSubcard(index)" alt="user1"></span>
            </div>
            <div class="col-6 cell-member">
                <a :class="(topicBold[index]==true) ? 'cell-faded cell-bold' : 'cell-faded'" href="" @click.prevent="toggleNotification(entry, index)">{{ entry.notifieralias}}</a>
            </div>
            <div class="col-2 subject-col cell-faded">
            </div>
            <div class="col-1 d-flex justify-content-center checkbox-col">
                <div class="form-check">
                    <BaseInputCheckbox v-model="this.notifications[index]['delete']" />
                </div>
            </div>
        </div>
    </div>
    <div v-show="this.showNotification[index]">
        <div class="card comments-card">
            <div class="row">
                <div class="col line-break-and-tab" :id="'notification-'+ index">
                    <p> {{ entry.description }} </p>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <button v-if="entry.topic=='message'" role="button" @click.prevent="goConversation(entry)" class="btn-notif btn-conv btn3d">View</button>
                    <button v-if="entry.topic=='application'" role="button" @click.prevent="acceptMember(entry)" class="btn-notif btn-ok btn3d">Accept</button>
                    <button v-else-if="entry.topic=='invitation'" role="button" @click.prevent="joinClub(entry)" class="btn-notif btn-ok btn3d">Join</button>
                    <button v-else-if="entry.topic=='message'" type="button" class="btn-notif btn-decline btn3d" @click.prevent="this.showNotification[index] = false; removeNotification(entry)">Delete</button>
                    <button v-else-if="entry.activity" role="button" @click.prevent="goVisit(entry)" class="btn-notif btn-ok btn3d">View</button>
                    <button v-if="entry.topic=='message'" type="button" class="btn-notif btn-cancel1 btn3d" @click.prevent="this.showNotification[index] = false;">Close</button>
                    <button v-else type="button" class="btn-notif btn-cancel2 btn3d" @click.prevent="this.showNotification[index] = false;">Close</button>
                    <button v-if="entry.topic=='application' || entry.topic=='invitation'" type="button" class="btn-notif btn-decline btn3d" @click.prevent="this.showNotification[index] = false; removeNotification(entry)">Decline</button>
                    <button v-else-if="entry.topic=='message' && (!entry.clubname)" type="button" class="btn-notif btn-report btn3d" @click.prevent="this.showNotification[index] = false; showBlockModal(entry)">Block/report</button>
                    <button v-else-if="entry.topic=='message' && entry.clubname=='blocked'" type="button" class="btn-notif btn-report " disabled>Blocked</button>
                    <button v-else-if="entry.topic=='message' && entry.clubname=='reported'" type="button" class="btn-notif btn-report " disabled>Blocked/reported</button>
                    <button v-else-if="entry.activity" type="button" class="btn-notif btn-decline btn3d" @click.prevent="this.showNotification[index] = false; removeNotification(entry)">Delete</button>
                </div>
            </div>
        </div>
    </div>
</div> -->

    <!-- </div> -->
</div>
</template>

<script>
import apiCalls from '@/services/apiCalls.js'
import store from '@/store'
import router from '@/router'
import {
    Modal
} from 'bootstrap'
import {
    mapActions
} from 'vuex'
// import router from '@/router'
export default {
    name: 'TableMessages',
    data() {
        return {
            notifications: [],
            showNotification: [],
            showEmptyNotifications: false,
            emptyMsgNotifications: 'No messages',
            oldMessages: 'All messages are deleted after 30 days',
            topicBold: [],
            // delNot: [],
            // allNotifications: false,
            blockModalEntry: {},
            modalReduceEntry: {},
            deleteStringUser: '',
            deleteStringClub: '',
            reportComment: false,
            counterparts: [],
            // unreadMessages: [],
            picUrl: false,
            topPos: null,
            leftPos: null,
        }
    },
    props: [
        'category',
    ],
    computed: {},
    methods: {
        ...mapActions(['setCid', 'setCin', 'setUnreadM', 'setAlertMsg', 'setRfrsh', 'setParticId', 'setParticAlias', 'setParticIcon', 'setToinvPage', 'setFrominvPage']),
        async getNotifications() {
            this.notifications = []
            this.counterparts = []
            let readList = []
            try {
                console.log('in getNotifications')
                let params = {
                    'notified': store.state.myPid,
                    'topic': 'messages',
                }
                const notifications = await apiCalls.getEntries('/notifications/', params)
                if (notifications && notifications.data && notifications.data.length > 0) {
                    // this.notifications = notifications.data.filter(obj => { return obj.notifier != store.state.myPid })
                    this.notifications = notifications.data
                    console.log('notifications: ', notifications.data)
                    let alias = ''
                    let aliasIsPresent = false
                    this.notifications.forEach(obj => {
                        // let hideList=null
                        // if (obj.hidelist && obj.hidelist != '') {
                        //     hideList = obj.hidelist.split(',')
                        // }
                        // if (hideList==null || hideList.indexOf(store.state.myPid.toString()) == -1) { // If myPid is in the Hide liest, skip the message end go directly to the next one
                        if (obj.club) {
                            readList = obj.status.split(',')
                        }
                        alias = obj.notifieralias
                        // For each message, check if the couunterpart is already present in ther counterparts list (which started empty and is getting built)
                        aliasIsPresent = this.counterparts.find((o) => o.notifieralias == alias)

                        if (obj['usericon'].length && obj['usericon'].length > 4) {
                            obj.src = store.state.serverUrl + '/getimage/' + obj['usericon'] + '/'
                        }
                        if (!aliasIsPresent && obj.notified && obj.notified == store.state.myPid) { // counterpart not yet in the list, personal message sent to me
                            console.log('new notifier: ', obj.notifier)
                            if (obj.status == 'unread' || obj.status == 'dlvd') { //message still unread
                                this.counterparts.push({
                                    'id': obj.notifier,
                                    'notifieralias': obj.notifieralias,
                                    'usericon': obj.usericon,
                                    'unreadMessages': 1,
                                    'topicBold': true,
                                    'src': obj.src,
                                })
                                console.log('counterparts1: ', this.counterparts)
                            } else { //message already read
                                this.counterparts.push({
                                    'id': obj.notifier,
                                    'notifieralias': obj.notifieralias,
                                    'usericon': obj.usericon,
                                    'unreadMessages': 0,
                                    'topicBold': false,
                                    'src': obj.src,
                                })
                                console.log('counterparts2: ', this.counterparts)
                            }
                        } else if (!aliasIsPresent && obj.club) { // counterpart not yet in the list, club message
                            if (obj.notifier != store.state.myPid && (obj.status == 'unread' || readList.indexOf(store.state.myPid.toString()) == -1)) { //message still unread by me
                                this.counterparts.push({
                                    'id': obj.notifier,
                                    'notifieralias': obj.notifieralias,
                                    'usericon': obj.usericon,
                                    'unreadMessages': 1,
                                    'topicBold': true,
                                    'src': obj.src,
                                    'club': obj.club,
                                })
                                console.log('counterparts12: ', this.counterparts)
                            } else { //message already read by me
                                this.counterparts.push({
                                    'id': obj.notifier,
                                    'notifieralias': obj.notifieralias,
                                    'usericon': obj.usericon,
                                    'unreadMessages': 0,
                                    'topicBold': false,
                                    'src': obj.src,
                                    'club': obj.club,
                                })
                                console.log('counterparts22: ', this.counterparts)
                            }
                        } else if (aliasIsPresent && obj.notified && obj.notified == store.state.myPid && (obj.status == 'unread' || obj.status == 'dlvd')) { // counterpart already in the list, personal message to me still unread
                            console.log('existing notifier: ', obj.notifier)
                            let object = this.counterparts.find(f => f.notifieralias == obj.notifieralias)
                            if (object) {
                                object.unreadMessages += 1
                                object.topicBold = true
                            }
                            console.log('counterparts3: ', this.counterparts)
                        } else if (aliasIsPresent && obj.club && obj.notifier != store.state.myPid && (obj.status == 'unread' || readList.indexOf(store.state.myPid.toString()) == -1)) { // counterpart already in the list, club message still unread by me
                            let object = this.counterparts.find(f => f.notifieralias == obj.notifieralias)
                            if (object) {
                                object.unreadMessages += 1
                                object.topicBold = true
                            }
                            console.log('counterparts3: ', this.counterparts)
                        } else if (!aliasIsPresent && obj.notifier == store.state.myPid) { // counterpart not yet in the list, personal or group message sent by me (If there is obj.notified, it means that it is not a message to a club)
                            console.log('new notified: ', obj.notified)
                            if (obj.club) {
                                this.counterparts.push({
                                    'id': obj.notified,
                                    'notifieralias': obj.notifieralias,
                                    'usericon': obj.usericon,
                                    'unreadMessages': 0,
                                    'topicBold': false,
                                    'src': obj.src,
                                    'club': obj.club,
                                })
                            } else {
                                this.counterparts.push({
                                    'id': obj.notified,
                                    'notifieralias': obj.notifieralias,
                                    'usericon': obj.usericon,
                                    'unreadMessages': 0,
                                    'topicBold': false,
                                    'src': obj.src,
                                })
                            }
                            console.log('counterparts4: ', this.counterparts)
                        }
                        // } // was the closing in case of checking hide list - filter moved to backend
                    })
                    console.log('counterparts: ', this.counterparts)
                    // this.counterparts = counterparts
                    // if (this.counterparts.indexOf(obj.notifier) == -1 && obj.notifier != store.state.myPid) {
                    //     this.counterparts.push(obj.notifier)
                    //     if (obj.status == 'unread') {
                    //         this.unreadMessages[counterpartCounter] = 1
                    //         this.topicBold[counterpartCounter] = true
                    //     } else {
                    //         this.unreadMessages[counterpartCounter] = 0
                    //         this.topicBold[counterpartCounter] = false
                    //     }
                    //     counterpartCounter += 1
                    // } else if (this.counterparts.indexOf(obj.notifier) != -1 && obj.status == 'unread') {
                    //     this.unreadMessages[this.counterparts.indexOf(obj.notifier)] += 1
                    //     this.topicBold[this.counterparts.indexOf(obj.notifier)] = true
                    // }

                    // if (this.counterparts.indexOf(obj.notified) == -1 && obj.notified != store.state.myPid) {
                    //     this.counterparts.push(obj.notified)
                    //     this.unreadMessages[this.counterparts.indexOf(obj.notified)] = 0
                    //     this.topicBold[this.counterparts.indexOf(obj.notified)] = false
                    // }

                    // if (obj.status == 'unread') {
                    //     this.topicBold[boldIndex] = true
                    // }
                    // boldIndex = boldIndex += 1

                    // console.log('unreadmessages: ', this.unreadMessages)

                    // let notificationsList = NotificationsData.data.map(a => a.topic)
                    // let params2 = {
                    //     'memberslist': memberslist.join() //creates a comma separated list of club members (participant ID) from the array
                    // }
                    // const membersData = await apiCalls.getEntries('/participants/', params2)
                    // this.membersData = membersData.data
                } else {
                    this.showEmptyNotifications = true
                }
            } catch (error) {
                return console.log('error in getNotifications: ', error)
            }
        },
        // async updateUnread(entry) {
        //     try {
        //         if (entry['topicBold'] == true) {
        //             console.log('entry.id is.....: ', entry.id)
        //             const read = await apiCalls.getEntries('/notifications/', { // this sets all messages with this counterpart as read, before going to TableConversation
        //                 'topic': 'message',
        //                 'notifier': entry.id,
        //                 'notified': store.state.myPid,
        //                 'status': 'dlvd'
        //             })
        //             if (read) {
        //                 entry['topicBold'] = false
        //                 let currUnread = store.state.unreadm
        //                 await this.setUnreadM(currUnread - entry.unreadMessages)
        //                 localStorage.setItem('unrm', currUnread - entry.unreadMessages)
        //                 console.log('Messages marked as read: ', read.data)
        //             }
        //         }

        //     } catch (err) {
        //         console.log(err)
        //     }
        // },

        // async updateUnread(entry) {
        //     try {
        //         if (entry['topicBold'] == true) {
        //             console.log('entry.id is.....: ', entry.id)
        //             // const read = await apiCalls.getEntries('/notifications/', { // this sets all messages with this counterpart as read, before going to TableConversation
        //             //     'topic': 'message',
        //             //     'notifier': entry.id,
        //             //     'notified': store.state.myPid,
        //             //     'status': 'dlvd'
        //             // })
        //             // if (read) {
        //                 entry['topicBold'] = false
        //                 let currUnread = store.state.unreadm
        //                 await this.setUnreadM(currUnread - entry.unreadMessages)
        //                 localStorage.setItem('unrm', currUnread - entry.unreadMessages)
        //                 // console.log('Messages marked as read: ', read.data)
        //             // }
        //         }

        //     } catch (err) {
        //         console.log(err)
        //     }
        // },
        async toggleNotification(entry, index) {
            try {
                if (!this.showNotification[index]) {
                    // document.getElementById('notification-' + index).innerHTML = this.notifications[index].description
                    // document.getElementById('notification-' + index).innerHTML = entry.description
                    this.showNotification[index] = !this.showNotification[index]
                    setTimeout(() => { // NEW 20230308  
                        return document.getElementById('notificationcard-' + index).scrollIntoView({
                            behavior: 'smooth',
                            block: 'center'
                        })
                    }, 100)
                } else {
                    return this.showNotification[index] = !this.showNotification[index]
                }

            } catch (err) {
                console.log(err)
            }
        },
        async goConversation(entry) {
            try {
                // await this.updateUnread(entry)  // Replaced by below and function updateUnread eliminated

                if (entry['topicBold'] == true) {
                    entry['topicBold'] = false
                    let currUnread = store.state.unreadm
                    await this.setUnreadM(currUnread - entry.unreadMessages)
                    localStorage.setItem('unrm', currUnread - entry.unreadMessages)
                }

                if (entry.club) {
                    localStorage.setItem('cik', entry.usericon)
                    localStorage.setItem('cna', entry.notifieralias)
                    localStorage.setItem('cid', entry.club)

                } else {
                    if (entry.id != store.state.myPid) {
                        localStorage.setItem('paid', entry.id)
                        localStorage.setItem('paal', entry.notifieralias)
                        localStorage.removeItem('paic')
                        await this.setParticId(entry.id)
                        await this.setParticAlias(entry.notifieralias)
                        await this.setParticIcon(null)
                    }
                }

                return router.push({
                    name: 'itemdata',
                    params: {
                        category: 'conversation',
                    }
                })

            } catch (error) {
                return console.log('error status: ', error)
            }
            // router.push('/' + entry.notifier) // this was the only line of the function when sending to the entire investor page
        },
        // async removeNotification(entry) {
        //     try {
        //         const removed = await apiCalls.deleteEntry('/notifications/' + entry.id + '/')
        //         if (removed) {
        //             console.log('removed notification: ', removed.data)
        //             await this.getNotifications()
        //         }
        //         return
        //         // router.go(0)
        //     } catch (err) {
        //         console.log(err)
        //     }
        // },
        // async deleteNotifications() {
        //     try {
        //         let deleteList = []
        //         let deleteUnread = 0
        //         let currUnread = store.state.unreadm
        //         deleteList = this.notifications.map(a => {
        //             if (a.delete == true) {
        //                 if (a.status == 'unread') {
        //                     deleteUnread +=1
        //                 }
        //                 return a.id
        //             } else {
        //                 return null
        //             }
        //         }).filter(Boolean).join(',')
        //         console.log('deleteList: ', deleteList)

        //         // const removed = await apiCalls.deleteEntry('/notifications/' + deleteList + '/')
        //         const removed = await apiCalls.deleteEntry('/delnotifications/', {
        //             'deletelist': deleteList
        //         })

        //         if (removed) {
        //             console.log('removed notification: ', removed)
        //             this.hideRemoveModal()
        //             this.allNotifications = false
        //             if (deleteUnread > 0) {
        //                 await this.setUnreadM(currUnread - deleteUnread)
        //                 localStorage.setItem('unrm', currUnread - deleteUnread)
        //             }
        //             await this.getNotifications()
        //         }
        //         // return router.go(0)
        //         return
        //     } catch (err) {
        //         console.log(err)
        //     }
        // },
        // async reportMessage(entry) {
        //     try {
        //         let reportType = 'message'
        //         let description = ''
        //         // let endpoint = '/' + reportType + 's/' + this.comment + '/'
        //         // const commentDetails = await apiCalls.getEntries(endpoint, {})
        //         // if (commentDetails) {

        //         description = `Message from ${entry.notifieralias} to ${store.state.myAlias} of ${entry.created}: ${entry.invitemessage}`

        //         const reported = await apiCalls.postEntry('/reports/', {
        //             'type': reportType,
        //             'reporter': store.state.myPid,
        //             'reported': entry.notifier,
        //             'description': description,
        //         })
        //         if (reported) {
        //             console.log('reported: ', reported)
        //             const patched = await apiCalls.patchEntry('/notifications/' + entry.id + '/', {
        //                 // 'notified': null,  // may add an administrator participant and book it to her id
        //                 'clubname': 'reported',
        //             })
        //             if (patched) {
        //                 console.log('patched notification: ', patched.data)
        //                 // await this.getNotifications()
        //             }
        //             await this.setRfrsh(true)
        //             await this.setAlertMsg(`The message has been reported to the administrators.`)
        //         } else {
        //             console.log('some error in reporting comment')
        //             await this.setRfrsh(true)
        //             await this.setAlertMsg(`Sorry, we have not been able to report the message correctly.${'\n\n'}Please try again or report the problem to the site administrators.`)
        //         }
        //     } catch (err) {
        //         console.log('error in reporting comment: ', err)
        //         await this.setRfrsh(true)
        //         await this.setAlertMsg(`Sorry, we have not been able to report the message correctly.${'\n\n'}Please try again or report the problem to the site administrators.`)
        //     }
        // },
        // toggleAllNotifications(value) {
        //     this.notifications.forEach(notification => {
        //         notification['delete'] = value
        //     })
        // },
        // showUser(entry) {
        //     return router.push('/' + entry.id)
        // },
        async showPage(entry) {
            console.log('entry in showPage: ', entry)

            //Below conditional statement eliminated as it seems not to be used
            // if (this.currentPage == '/clubs') { // Prevents the rendering changing before the eyes when navigating from My Clubs to Investor Page
            //     this.destPage = 'investorPage'
            // }
            // if (entry.id != store.state.particId) { // if they are the same, it will stay on the same page and just scroll to the top, othewise does the following in order to prevent scrolling to the top before refreshing the page
            //     await this.setToinvPage(true)
            //     await this.setFrominvPage(true) // Used only here so that it the above does not prevent triggering refresh in routerBeforeEach, but still prevents screen from scrolling in front of user before routing, when calling Investor Page from inside Investor Page
            // }
            await this.setParticId(entry.id)
            await this.setParticIcon(entry.usericon)
            await this.setParticAlias(entry.notifieralias)
            localStorage.setItem('paid', entry.id)
            localStorage.setItem('paic', entry.usericon)
            localStorage.setItem('paal', entry.notifieralias)
            // } else {
            // console.log('to my own page')
            // await store.dispatch('setParticId', null)
            // await store.dispatch('setParticIcon', null)
            // await store.dispatch('setParticAlias', null)
            // localStorage.removeItem('paid')
            // localStorage.removeItem('paic')
            // localStorage.removeItem('paal')
            // }
            console.log('ENDING setting partic in Investors/showPage - now will route to Investor page: ', store.state.particId + ' ' + store.state.particAlias + ' ' + store.state.particIcon)
            let investor = entry.id
            console.log('page: ', store.state.page)
            return router.push({ // if comes from somewhere else, then it can go gently through the router
                name: 'displayinvestor',
                params: {
                    investor: investor
                }
            })

        },

        async showClub(entry) {
            console.log('entry in showClub: ', entry)
            await this.setCid(entry.club) // This is effectively the prop that gets passed to MyClubs to select the club from the dropdown list
            localStorage.setItem('cid', entry.club)
            if (store.state.cin) {
                await this.setCin(false) // This is effectively the prop that gets passed to MyClubs to select the club from the dropdown list
                localStorage.removeItem('cid')
            }

            return router.push({
                name: 'itemdata',
                params: {
                    category: 'clubs',
                }
            })
        },
        async blockInvestor(entry) {
            try {
                console.log('entry to block: ', entry)
                console.log('store.state.myPid: ', store.state.myPid)
                console.log('this.reportComment: ', this.reportComment)
                this.hideBlockModal()
                const blocked = await apiCalls.postEntry('/blocks/', {
                    'blocker': store.state.myPid,
                    'blocked': entry.id,
                    'blockedalias': entry.notifieralias,
                })
                if (!blocked) {
                    console.log('some error in reporting comment')
                    await this.setRfrsh(true)
                    await this.setAlertMsg(`Sorry, we have not been able to block the investor correctly.${'\n\n'}Please try again or report the problem to the site administrators.`)
                }
                if (this.reportComment == true) {
                    let reportType = 'message'
                    let description = `Message from ${entry.notifieralias} to ${store.state.myAlias} of ${entry.created}: ${entry.invitemessage}`

                    const reported = await apiCalls.postEntry('/reports/', {
                        'type': reportType,
                        'reporter': store.state.myPid,
                        'reported': entry.notifier,
                        'description': description,
                    })
                    if (reported) {
                        console.log('reported: ', reported)
                        const patched1 = await apiCalls.patchEntry('/notifications/' + entry.id + '/', {
                            // 'notified': null,  // may add an administrator participant and book it to her id
                            'clubname': 'reported',
                        })
                        if (patched1) {
                            console.log('patched notification: ', patched1.data)
                            // await this.getNotifications()
                        }
                        await this.setRfrsh(true)
                        await this.setAlertMsg(`The investor has been blocked and the message reported to the administrators.`)
                    } else {
                        console.log('some error in reporting comment')
                        await this.setRfrsh(true)
                        await this.setAlertMsg(`Sorry, the investor has been blocked but we have not been able to report the message correctly.${'\n\n'}Please try again or report the problem to the site administrators.`)
                    }
                } else {
                    // const patched2 = await apiCalls.patchEntry('/notifications/' + entry.id + '/', {
                    //     // 'notified': null,  // may add an administrator participant and book it to her id
                    //     'clubname': 'blocked',
                    // })
                    // if (patched2) {
                    //     console.log('patched notification: ', patched2.data)
                    //     // await this.getNotifications()
                    // }
                    await this.setRfrsh(true)
                    await this.setAlertMsg(`The investor has been blocked successfully.`)
                }
            } catch (error) {
                console.log('Error in blockInvestor: ', error)
                await this.setRfrsh(true)
                await this.setAlertMsg(`Sorry, we have not been able to block the investor correctly.${'\n\n'}Please try again or report the problem to the site administrators.`)
            }
        },
        enlargePic(src, index) {
            if (this.picUrl) {
                this.picUrl = false
            } else {
                let elem = document.getElementById('investorPic-' + index)
                let rect = elem.getBoundingClientRect()
                // this.topPos = document.querySelector('pic-' + index).getBoundingClientRect().top
                // this.leftPos = document.querySelector('pic-' + index).getBoundingClientRect().left
                this.topPos = rect.top - 20
                this.leftPos = rect.left
                this.picUrl = src
                // this.showModalPic = true
            }
        },
        onModalClosed(value) {
            console.log('onmodalClosed: ', value)
            // alert('onmodalClosed')
            this.picUrl = false
            // this.showModalPic = false
        },

        async hideMessages() {
            try {

                console.log('entry in hideMessages: ', this.modalReduceEntry)
                let hideUnread = Number(this.modalReduceEntry.unreadMessages)
                let currUnread = store.state.unreadm
                let params = {}
                if (!this.modalReduceEntry.club) {
                    params = {
                        'notifier': this.modalReduceEntry.id
                    }
                } else if (this.modalReduceEntry.club) {
                    params = {
                        'club': this.modalReduceEntry.club
                    }
                }

                const hidden = await apiCalls.patchEntry('/delmessages/', params)

                if (hidden && hidden.data) {

                    console.log('hidden messages: ', hidden)
                    this.hideRemoveModal()

                    if (hideUnread > 0) {
                        await this.setUnreadM(currUnread - hideUnread)
                        localStorage.setItem('unrm', currUnread - hideUnread)
                    }
                    await this.getNotifications()
                } else {
                    this.hideRemoveModal()
                }
                return
            } catch (err) {
                console.log(err)
            }
        },

        showRemoveModal(entry) {
            this.deleteStringUser = ''
            this.deleteStringClub = ''
            this.modalReduceEntry = {}
            console.log('entry in showRemoveModal: ', entry)
            this.modalReduceEntry = entry
            if (entry.club) {
                this.deleteStringClub = ` ${entry.notifieralias}`
            } else if (!entry.club) {
                this.deleteStringUser = ` ${entry.notifieralias}`
            }
            return this.bsRemoveModal.show()
        },
        hideRemoveModal() {
            this.bsRemoveModal.hide()
        },
        showBlockModal(entry) {
            this.reportComment = false
            this.blockModalEntry = {} // RECENT 0822
            this.blockModalEntry = {
                ...entry
            }
            return this.bsBlockModal.show()
        },
        hideBlockModal() {
            this.bsBlockModal.hide()
        },

        // async toggleNotification(index) {
        //     try {
        //         console.log('selected: ', index)

        //         await this.setCid(this.clubentries[index].id)
        //         localStorage.setItem('cid', this.clubentries[index].id)
        //         await this.setCin(index)
        //         localStorage.setItem('cin', index)
        //         this.showClub = []
        //         return this.showClub[index] = true
        //     } catch (err) {
        //         console.log(err)
        //     }
        // },
    },
    async created() {
        try {
            await this.getNotifications()
        } catch (err) {
            console.log(err)
        }
    },
    mounted() {
        this.myRemoveModal = document.getElementById('removeModal')
        this.bsRemoveModal = new Modal(this.myRemoveModal)
        this.myBlockModal = document.getElementById('blockModal')
        this.bsBlockModal = new Modal(this.myBlockModal)
    },

}
</script>

<style scoped>
/* .main-cont-notifications {
    margin-bottom: 50px;
} */

/* .cell-tick {
    position: relative;
    color: #babab9 !important;
    text-decoration-line: underline;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    left: 8px !important;
    margin-top: 0px;
    margin-left: -18px;
    margin-right: 5px;
    padding-top: 5px;
} */
/* .cell-col0 {
    margin-left: -8px;
    margin-right: -4px;
    margin-top: 0px;
} */

.entry-card-empty {
    background-color: #413e3c;
    height: 40px;
    margin: 3px auto;
    padding-top: 6px;
    text-align: center;
}

.entry-card-oldMsg {
    /* background-color: #413e3c; */
    background-color: #34312f;
    color: #878380;
    width: 100%;
    font-size: 0.9rem;
    margin: 30px auto 3px;
    padding: 8px 3px;
    text-align: center;
    border-radius: 0px;
}

.card-notification {
    background-color: #413e3c !important;
    width: 100%;
    margin: 8px auto;
    padding-top: 14px;
    text-align: center;
    /* font-size: 0.8rem; */
    height: 68px;
    border-radius: 7px;
}

.cell-member {
    position: relative;
    text-decoration-line: none;
    font-size: 1rem;
    text-align: start;
    left: 8px !important;
    margin-right: -10px;
    margin-top: 4px;
}

.cell-remove {
    text-align: center;
}

/* .cell-empty {
    font-size: 0.9rem;

} */

.checkbox-col {
    padding-top: 0px;
    margin-top: 2px;
    font-size: 1.05rem;
    margin-left: 25px;
    /* was 1 */
    font-weight: bold;
}

.cell-faded {
    /* color: #dbd7d4; */
    /* color: #aba7a4; */
    color: #c2c4c7;
}

.cell-no-newmsgs {
    /* color: #dbd7d4; */
    background-color: #413e3c;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin-left: 15px;
    margin-right: -10px;
    margin-top: 7px;
}

.cell-newmsgs {
    /* color: #dbd7d4; */
    color: #000;
    color: #fff;
    /* border: solid 1px; */
    /* border-color: #272522; */
    /* border-color: #aba7a4; */
    /* border-color: #929299; */
    /* background-color: #2F5C85; */
    /* background-color: #43cae9a6; */
    background-color: #17dd70ec;
    background-color: #3F7CB3;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    font-weight: bold;
    font-size: 0.7rem;
    text-align: center;
    padding-bottom: 0px;
    padding-right: 0px;
    padding-left: 1px;
    padding-top: 4px;
    margin-top: 7px;
    margin-left: 15px;
    margin-right: -10px;
    cursor: pointer;
    z-index: 2;
    /* was overlapping with previous cell, so with this it becomes entirely clickable */

}

.three-dots {
    width: 35px;
    height: 26px;
    margin-top: 4px;
    margin-left: 28px;
    margin-bottom: 0px;
    /* border-radius: 50%; */
    padding-bottom: 0px;
}

.user-sticker-round {
    width: 40px;
    margin-top: -1px;
    margin-left: -5px;
    margin-bottom: 0px;
    border-radius: 50%;
    padding-bottom: 0px;
}

.cell-clickable {
    cursor: pointer;
}

.investor-image {
    height: 40px;
    width: 40px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding-bottom: 0px;
    object-fit: cover;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
}

.top-row-notifications {
    margin-top: 60px;
}

.comments-card {
    margin: 3px 0;
    margin-bottom: 7px;
    color: #fff;
    background-color: #7C7B78;
    padding-left: 0px;
    padding-right: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 0.9rem;
    /* height: 60px !important; */
}

.cell-bold {
    font-weight: bold;
    font-size: 1.1rem;
    color: #fbf7f4;
}

.row-btn {
    margin-top: -10px;
}

.btn-notif {
    padding-left: 9px;
    padding-right: 9px;
    min-width: 60px;
    /* padding-left: 0px;
    padding-right: 0px; */
    color: #fff;
    font-weight: bold;
    height: 30px !important;
}

.btn-ok {
    background-color: #2F5C85 !important;
    margin-right: 20px;
    margin-left: -5px !important;
    width: 120px;
}

.btn-report {
    background-color: rgba(214, 55, 55, 0.897) !important;
    padding-left: 4px;
    padding-right: 4px;
    margin-left: 0px;
    margin-right: 20px;
    width: 85px;
    /* color: #fff; */
    /* height: 30px !important; */
    /* width: 100px !important; */
}

.btn-cancel1 {
    background-color: #6c6c6c !important;
    margin-right: 0px;
    margin-left: 0px;
    /* width: 55px !important; */
}

.btn-decline {
    background-color: rgba(214, 55, 55, 0.897) !important;
    margin-right: 0px;
    margin-left: 0px;
}

/* ******************* BTN 3D ******************* */
.btn3d {
    border: 0;
    border-right: 1px solid #272522;
    border-bottom: 1px solid #272522;
    transition: all 40ms linear;
}

.btn3d.btn-primar {
    box-shadow: 0 0 0 1px #2f5b85a1 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

.btn3d.btn-primar:hover {
    box-shadow: 0 0 0 1px #00b3dba6 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

/* ******************* END BTN 3D ******************* */

.close-butt {
    height: 25px !important;
    width: 10px !important;
    text-align: start !important;
    background-color: #b33430;
    color: white;
    margin-top: 10px !important;
}

.btn-tex {
    position: relative;
    top: -8px;
    left: -4px;
}

/* .close-butt {
    height: 20px !important;
    width: 20px !important;
    text-align: center !important;
    background-color: #b33430;
    color: white;
    margin-top: 0px !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    font-weight: bold !important;
    padding: 0px !important;

}

.btn-tex {
    font-size: 1.1rem;
    margin-top: -6px;
} */

.card-headers {
    height: 30px;
    background-color: #272522 !important;
    margin-bottom: 8px;
    margin-top: 15px;
    text-align: center;
    border: none;
    font-size: 0.8rem;
}

.cell-headers {
    /* color: #6c6c6c !important; */
    color: #9c9c9c !important;
    font-size: 1rem;
    padding-left: 0px;
    padding-right: 0px;
}

.head-1 {
    text-align: start;
    position: relative;
    left: 32px !important;
    margin-right: -10px;
    font-size: 1.1rem;
}

.head-2 {
    text-align: center;
    font-size: 1.1rem;
}

.head-3 {
    text-align: center;
    /* margin-left: 0px; */
}

.card-select {
    position: sticky;
    top: 30;
    height: 30px;
    background-color: #272522 !important;
    margin-bottom: 10px;
    margin-top: -7px;
    text-align: center;
    border: none;
    font-size: 0.8rem;
}

.delete-selected {
    text-align: end;
    margin-left: -4%;
    margin-right: 0px;
    font-size: 1.1rem;
    /* color: #9c9c9c !important; */
}

.select-all-box {
    padding-top: 3px;
    font-size: 1.05rem;

}

.links-delete {
    /* color: #6c6c6c; */
    color: #9c9c9c !important;
    text-decoration: underline;
}

/* ***** MODAL ****************************************************** */
.modal-area {
    /* background-color: #dbd7d4; */
    background-color: #e4e4e4;
}

.modal-title2 {
    color: #4d4d4d;
}

.modal-body2 {
    color: #4d4d4d;
}

.top-row-modal {
    margin-top: 0px;
}

.modal-row {
    text-align: center;
}

.btn-secondary {
    margin-left: 20px !important;
    margin-top: -3px !important;
}

.btn-secondary-modal {
    height: 38px;
    margin-top: -3px !important;
}

.modal-footer {
    display: flex;
    justify-content: center;
}

.modal-btn {
    /* width: 180px; */
    padding-left: 20px;
    padding-right: 20px;
    height: 38px;
    margin-right: 10px !important;
    /* margin-left: -60px !important; */
    margin-top: -3px !important;
    background-color: #2F5C85 !important;
    color: white !important;
    border: none !important;
}

/* ********************************************* */
</style>
