<template>
<div>
    <BaseModalPicture v-if="picUrl" :picUrl="picUrl" :topPos="topPos" :leftPos="leftPos" element='club' @clicked="onModalClosed" />

    <div>
        <div v-if="this.category == 'topclubs'">
            <div class="row mt-4 pt-3">
                <div class="col-3 ps-2 pe-0 inactive-ranking">
                    <router-link :to="{ name: 'itemdata', params: { category: 'topinvestors' }}" class="links">
                        Investors
                    </router-link>
                </div>
                <div class="col-3 col-clubs ps-1 pe-0 active-ranking">
                    Clubs
                </div>
                <div class="col-3 col-inv ps-0 pe-0 inactive-ranking">
                    <router-link :to="{ name: 'itemdata', params: { category: 'topinvestments' }}" class="links">
                        Investments
                    </router-link>
                </div>
                <div class="col-3 ps-0 pe-0 inactive-ranking">
                    <router-link :to="{ name: 'itemdata', params: { category: 'topfollowed' }}" class="links">
                        Followed
                    </router-link>
                </div>
            </div>
        </div>
        <div v-else-if="this.category == 'searchclubs'">
            <div class="row mt-4 pt-3">
                <div class="col-4 ps-3 inactive-ranking">
                    <router-link :to="{ name: 'itemdata', params: { category: 'searchinvestors' }}" class="links">
                        Investors
                    </router-link>
                </div>
                <div class="col-4 ps-3 active-ranking">
                    Clubs
                </div>
                <div class="col-4 pe-2 inactive-ranking">
                    <router-link :to="{ name: 'itemdata', params: { category: 'searchinvestments' }}" class="links">
                        Investments
                    </router-link>
                </div>
            </div>
            <div class="src-wrapper">
                <div class="row justify-content-start gx-3 gy-0 pt-3 mb-0">
                    <form>
                        <div class="row gx-3">
                            <div class="col col-sm-10">
                                <BaseInputEnter label="Search Club" v-model="this.clubSearch" id="club-src" />
                            </div>
                            <div class="col-3 col-sm-2">
                                <button type="submit" @click.prevent="getClubs()" class="btn src-btn btn3d btn-primar">Search</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div v-if="this.showEmptyClubs" class="card empty-card">
            <div class="row">
                <div class="col-12 cell-empty">
                    {{ this.emptyMsgClubs }}
                </div>
            </div>
        </div>

        <div v-if="this.clubentries.length > 0" class="card card-headers">
            <div class="row">
                <div class="col-2 cell-headers ">

                </div>
                <div class="col-7 cell-headers head-1">
                    club name
                </div>
                <div class="col-3 cell-headers head-2">
                    usd capital
                </div>
            </div>
        </div>

        <div v-for="entry, index in this.clubentries" :key="entry.id">
            <!-- <div :class="this.showClub[index] ? 'card entry-card thick-card' : 'card entry-card'"> -->
            <div class="card club-card" :id="'clubcard-'+index">
                <div class="row">
                    <!-- <div class="row justify-content-start ps-0 pt-3 mb-0">     -->
                    <div class="col-2 d-flex justify-content-center">
                        <span v-if="entry.clubicon && entry.clubicon != 'I-11'">
                            <div class="club-image" :style="{ 'background-image': `url(${iconUrl(entry.clubicon)})` }" @click.prevent="enlargePic(iconUrl(entry.clubicon), index)" :id="'clubPic-' + index"></div>
                        </span>
                        <span v-else><img class="club-sticker-round" :src="require('@/assets/images/'+ 'I-11' + '.png')" path="@/assets/images/" alt="user1"></span>
                    </div>
                    <!-- </div> -->

                    <div class="col-7 cell-tick">
                        <!-- <a class="cell-neu" href="" @click.prevent="this.toggleClub(index)">{{ entry.clubname.slice(0, 21) }}</a> -->
                        <a class="cell-neu" href="" @click.prevent="this.toggleClub(index)">{{ entry.clubname }}</a>
                        <!-- <a class="cell-neu" href="" @click.prevent="this.toggleClub(index)">ABCDEFGHIJKLMNOPQRSTUV</a> -->
                    </div>

                    <div class="col-3 cell-pri">
                        <!-- {{ (Math.round((entry.usdcapital) * 100) / 100).toLocaleString('en-US')}} -->
                        {{ Math.round(entry.usdcapital).toLocaleString('en-US')}}
                        <!-- 100,000,000 -->
                    </div>
                </div>

                <div class="row">
                    <div class="col-9 ">
                    </div>
                    <div class="col-3 cell-qty cell-below-tick" v-if="showClub[index]">
                        <span v-if="entry.isMyClub == false || entry.isMyClub == 'invited' || entry.isMyClub == 'left'"><a class="cell-neu" href="" @click.prevent="this.applyToClub(entry, index)">Ask to join?</a></span>
                        <span v-else class="cell-status">{{ entry.isMyClub }}</span>
                    </div>
                    <div class="col-3 cell-qty cell-below-tick" v-else-if="!this.showClub[index]">
                        <span v-if="entry.isMyClub == 'member'" class="cell-status">member</span>
                        <span v-else-if="entry.isMyClub == 'applied'" class="cell-status">applied</span>
                        <!-- <span v-else-if="entry.isMyClub == 'left'" class="cell-status">left</span> -->
                    </div>
                </div>
            </div>

            <div v-if="showClub[index]" class="club-wrapper">
                <!-- <TableClubUsers category='clubinvestors' /> -->
                <TableInvestors category='clubinvestors' />
                <div class="row club-spacer"></div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import apiCalls from '@/services/apiCalls.js'
// import TableClubUsers from '@/components/TableClubUsers.vue'
import TableInvestors from '@/components/TableInvestors.vue'
import store from '@/store'
import {
    mapActions
} from 'vuex'
// import router from '@/router'
export default {
    name: 'TableClubs',
    components: {
        // TableClubUsers,
        TableInvestors,

    },
    data() {
        return {
            // particId: null,
            clubentries: [],
            showClub: [],
            showEmptyClubs: false,
            emptyMsgClubs: '',
            clubSearch: '',
            picUrl: false,
            topPos: null,
            leftPos: null,
            // showModalPic: false,
        }
    },
    props: [
        'category',
    ],
    methods: {
        ...mapActions(['setCid', 'setCin', 'setMyMem', 'setAlertMsg', 'setRfrsh']),
        enlargePic(src, index) {
            let elem = document.getElementById('clubPic-' + index)
            let rect = elem.getBoundingClientRect()
            // this.topPos = document.querySelector('pic-' + index).getBoundingClientRect().top
            // this.leftPos = document.querySelector('pic-' + index).getBoundingClientRect().left
            this.topPos = rect.top - 20
            this.leftPos = rect.left
            if (this.picUrl) {
                this.picUrl = false
            } else {
                this.picUrl = src
                // this.showModalPic = true
            }
        },
        onModalClosed(value) {
            console.log('onmodalClosed: ', value)
            // alert('onmodalClosed')
            this.picUrl = false
            // this.showModalPic = false
        },
        async topClubsData() {
            try {
                const ClubEntries = await apiCalls.getEntries('/topclubs/', {})
                if (ClubEntries && ClubEntries.data.length > 0) {
                    console.log('ClubEntries.data: ', ClubEntries.data)
                    return this.clubentries = ClubEntries.data
                } else {
                    return this.emptyMsgClubs = 'No club to show'
                }
            } catch (err) {
                console.log('error in topClubsData: ', err)
            }
        },
        async searchClubsData() {
            try {
                const clubsFound = await apiCalls.getEntries('/clubs/', {
                    'search': this.clubSearch,
                })
                if (clubsFound && clubsFound.data.length > 0) {
                    console.log('clubsFound.data: ', clubsFound.data)
                    return this.clubentries = clubsFound.data
                } else {
                    return this.emptyMsgClubs = 'No close match found'
                }
            } catch (err) {
                console.log('error in searchClubsData: ', err)
            }
        },
        async investorClubsData() {
            try {
                const MembershipEntries = await apiCalls.getEntries('/memberships/', {
                    'participant': store.state.particId,
                    'status': 'active'
                })
                if (MembershipEntries && MembershipEntries.data.length > 0) {
                    console.log('ParticipantMemberships: ', MembershipEntries.data)
                    let clubslist = MembershipEntries.data.map(a => a.club)
                    const ClubEntries = await apiCalls.getEntries('/clubs/', {
                        'clubslist': clubslist.join()
                    })
                    if (ClubEntries && ClubEntries.data.length > 0) {
                        console.log('ClubEntries.data: ', ClubEntries.data)
                        this.clubentries = ClubEntries.data
                    }
                } else {
                    return this.emptyMsgClubs = 'No club membership'
                }
            } catch (err) {
                console.log('error in investorClubsData: ', err)
            }
        },
        async getClubs() {
            try {
                console.log('ingetClubs')
                this.clubentries = []
                this.showEmptyClubs = false

                if (this.category == 'topclubs') {
                    await this.topClubsData()
                    console.log('this.clubentries top: ', this.clubentries)
                } else if (this.category == 'searchclubs') {
                    await this.searchClubsData()
                    console.log('this.clubentries search: ', this.clubentries)
                } else if (this.category == 'investorclubs') {
                    await this.investorClubsData()
                    console.log('this.clubentries investor: ', this.clubentries)
                }
                if (this.clubentries.length == 0) {
                    return this.showEmptyClubs = true
                }

                const myMemberships = await apiCalls.getEntries('/memberships/', {
                    'participant': store.state.myPid,
                })
                if (myMemberships && myMemberships.data.length > 0) {
                    console.log('myMembershipsInClubs: ', myMemberships.data)
                    const myActiveMemberships = myMemberships.data.filter(obj => obj.status == 'active' || obj.status == 'removed' || obj.status == 'applied' || obj.status == 'invited' || obj.status == 'left')
                    if (myActiveMemberships && myActiveMemberships.length > 0) {
                        console.log('myActiveMemberships: ', myActiveMemberships)
                        const myClubsList = myActiveMemberships.map(a => a.club)
                        console.log('myClubsList: ', myClubsList)
                        this.clubentries.forEach(topClub => {
                            if (myClubsList.includes(topClub.id)) {
                                console.log('found: ', topClub.id)
                                let matchedMembership = myMemberships.data.find(obj => obj.club == topClub.id)
                                console.log('matchedMembership: ', matchedMembership)
                                topClub['isMyClub'] = matchedMembership['status']
                                if (topClub['isMyClub'] == 'active') {
                                    topClub['isMyClub'] = 'member'
                                }
                            } else {
                                topClub['isMyClub'] = false
                            }
                        })
                    }
                } else { // Otherwise isMyClub remains undefined (it is not false, it is undefined, lik null)
                    this.clubentries.forEach(topClub => {
                        topClub['isMyClub'] = false
                    })
                }
            } catch (error) {
                return console.log('error in getClubs: ', error)
            }
        },
        async applyToClub(club, index) { // Here not receiving teh index like in SearchClubs. It returns a refresh (could be changed if needed and return closing the subcard)
            try {
                if (store.state.mymem > (store.state.maxMemberships - 1)) { // maxMemberships
                    // this.showClub[index] = false
                    // await this.setRfrsh(true)
                    return await this.setAlertMsg(`Sorry, you have already reached your limit of 10 active club memberships.${'\n\n'}Please leave one club before applying to one more.`)
                }
                const membersCount = await apiCalls.getEntries('/memberships/', {
                    'club': club.id,
                    'status': 'active'
                })
                if (membersCount && membersCount.data.length > (store.state.maxMembers - 1)) { // maxMembers
                    return await this.setAlertMsg(`Sorry, this club already reached its limit of 10 active members.`)
                }
                const membership = await apiCalls.getEntries('/memberships/', {
                    'participant': store.state.myPid,
                    'club': club.id,
                })
                let membershipId = ''
                if (membership && membership.data.length > 0) {
                    membershipId = membership.data[0].id
                    console.log('membership.data[0]: ', membership.data[0])
                    let status = membership.data[0].status
                    console.log('status applyCLub: ', status)
                    if (status == 'invited') {
                        //To be sure, recheck my membership in case that have maliciously edited in localStorage
                        const applicant = await apiCalls.getEntries('/participants/' + store.state.myPid + '/', {})
                        if (applicant) {
                            if (applicant.data.memberships > (store.state.maxMemberships - 1)) { // maxMemberships
                                localStorage.setItem('mem', applicant.data.memberships)
                                await this.setMyMem(applicant.data.memberships)
                                // await this.setRfrsh(true)

                                return await this.setAlertMsg(`Sorry, you have already reached your limit of 10 active club memberships.${'\n\n'}Please leave at least one other clubs before asking to join a new one.`)
                                // return router.go(0)
                            }
                        } else {
                            if (this.category != 'searchclubs') {
                                await this.setRfrsh(true)
                            }
                            return await this.setAlertMsg(`Looks like there has been an error.${'\n\n'}Please try again or inform of the problem the site administrators.`)
                            // return router.go(0)
                        }
                        const activatedMembership = await apiCalls.patchEntry('/memberships/' + membershipId + '/', {
                            'status': 'active',
                        })
                        if (activatedMembership) {
                            console.log('activatedMembership: ', activatedMembership)
                            let memCount = Number(store.state.mymem) + 1
                            await this.setMyMem(memCount)
                            localStorage.setItem('mem', memCount)
                            const memUpdated = await apiCalls.patchEntry('/participants/' + store.state.myPid + '/', {
                                'memberships': memCount
                            })
                            if (memUpdated) {
                                console.log('membershipscount updated: ', memUpdated.data)
                                localStorage.setItem('mem', memCount)
                                await this.setMyMem(memCount)
                                this.clubentries[index]['isMyClub'] = 'member'
                                // await this.setRfrsh(true)
                                return await this.setAlertMsg(`You had already been invited to join ${club.clubname}.${'\n\n'}You have now become member of that club.`)
                                // return router.go(0)
                            } else {
                                if (this.category != 'searchclubs') {
                                    await this.setRfrsh(true)
                                }
                                return await this.setAlertMsg(`Sorry, your request could not be sent.${'\n\n'}Please try again or inform the site administrators.`)
                                // return router.go(0)
                            }
                        } else {
                            if (this.category != 'searchclubs') {
                                await this.setRfrsh(true)
                            }
                            return await this.setAlertMsg(`Sorry, your request could not be sent.${'\n\n'}Please try again or inform the site administrators.`)
                            // return router.go(0)
                        }
                    } else if (status == 'left') { // means it was 'left'
                        const patchedMembership = await apiCalls.patchEntry('/memberships/' + membershipId + '/', {
                            'status': 'applied',
                        })
                        if (patchedMembership) {
                            console.log('patchedMembership: ', patchedMembership)
                            membershipId = patchedMembership.data.id
                            this.clubentries[index]['isMyClub'] = 'applied'
                            const notification = await apiCalls.postEntry('/notifications/', {
                                'notifier': store.state.myPid,
                                'notifieralias': store.state.myAlias,
                                'notified': club.admin,
                                'club': club.id,
                                'clubname': club.clubname,
                                'topic': 'application',
                                'status': 'unread',
                                'membership': membershipId,
                            })
                            if (notification) {
                                console.log('notification created: ', notification)
                                return await this.setAlertMsg(`Your request has been notified to the club administrator of ${club.clubname}.`)
                            } else {
                                console.log('Clould not create notification')
                                if (this.category != 'searchclubs') {
                                    await this.setRfrsh(true)
                                }
                                return await this.setAlertMsg(`Sorry, it appears that your application could not be created.${'\n\n'}Please try again or inform the site administrators.`)
                            }
                        }
                    }
                } else {
                    const newMembership = await apiCalls.postEntry('/memberships/', {
                        'participant': store.state.myPid,
                        'club': club.id,
                        'status': 'applied',
                    })
                    if (newMembership) {
                        console.log('newMembership: ', newMembership)
                        membershipId = newMembership.data.id
                        this.clubentries[index]['isMyClub'] = 'applied'

                        const notification = await apiCalls.postEntry('/notifications/', {
                            'notifier': store.state.myPid,
                            'notifieralias': store.state.myAlias,
                            'notified': club.admin,
                            'club': club.id,
                            'clubname': club.clubname,
                            'topic': 'application',
                            'status': 'unread',
                            'membership': membershipId,
                        })

                        if (notification) {
                            console.log('notification created: ', notification)
                            // this.clubentries[index]['isMyClub'] = 'applied'
                            // await this.setRfrsh(true)
                            return await this.setAlertMsg(`Your request has been notified to the club administrator of ${club.clubname}.`)
                            // return router.go(0)
                        } else {
                            console.log('Clould not create notification')
                            if (this.category != 'searchclubs') {
                                await this.setRfrsh(true)
                            }
                            return await this.setAlertMsg(`Sorry, it appears that your application could not be created.${'\n\n'}Please try again or inform the site administrators.`)
                            // return router.go(0)
                        }
                    } else {
                        if (this.category != 'searchclubs') {
                            await this.setRfrsh(true)
                        }
                        return await this.setAlertMsg(`Sorry, it appears that your application could not be created.${'\n\n'}Please try again or inform the site administrators.`)
                        // return router.go(0)
                    }
                }
                // return this.showClub[index] = false
            } catch (err) {
                console.log('Error in applyToClub: ', err)
            }
        },
        iconUrl(icon) {
            return store.state.serverUrl + '/getimage/' + icon + '/'
        },
        async toggleClub(index) {
            try {
                // let shiftScr = Math.round(window.screen.height * 0.4)
                // console.log('shiftScr: ', shiftScr)
                if (!this.showClub[index]) {
                    console.log('selected index: ', index)
                    console.log('selected club: ', this.clubentries[index])
                    await this.setCid(this.clubentries[index].id)
                    localStorage.setItem('cid', this.clubentries[index].id)
                    await this.setCin(index)
                    localStorage.setItem('cin', index)
                    this.showClub = []
                    // return this.showClub[index] = true
                    this.showClub[index] = true

                    setTimeout(() => { // NEW 20230308 
                        document.getElementById('clubcard-' + index).scrollIntoView({
                            behavior: 'smooth',
                            block: 'center'
                        })
                        // window.scrollBy(0, shiftScr)
                    }, 1000)
                } else {
                    return this.showClub[index] = false
                }
            } catch (err) {
                console.log(err)
            }
        }
    },
    async created() {
        try {
            console.log('creating Clubs Table1')
            if (this.category != 'searchclubs') {
                await this.getClubs()
            }
            console.log('creating Clubs Table2')
        } catch (err) {
            console.log(err)
        }
    }
}
</script>

<style scoped>
.club-card {
    background-color: #413e3c !important;
    /* height: 40px; */
    /* height: 60px; */
    padding: 6px 0px 10px;

    width: 100%;
    margin: 3px auto;
    /* padding-top: 5px; */
    text-align: center;
    font-size: 0.8rem;
    /* margin-bottom: 8px; */
    /* margin-bottom: 7px; */

}

.empty-card {
    background-color: #413e3c;
    height: 40px;
    margin-top: 30px;
    padding-top: 6px;
    text-align: center;
    /* width: 100%; */
    /* margin: 3px auto; */
    /* font-size: 1rem; */
    /* font-size: 0.9rem; */

}

/* .cell-empty {
    font-size: 0.9rem;
} */

.card-headers {
    height: 30px;
    background-color: #272522 !important;
    margin-bottom: -5px;
    margin-top: 12px;
    text-align: center;
    border: none;
}

.cell-headers {
    color: #6c6c6c !important;
    font-size: 1rem;
}

.head-1 {
    text-align: start;
    margin-left: -4px;
    margin-right: 4px;
    /* padding-left: 50px; */
}

.head-2 {
    margin-left: -16px;
}

.cell-tick {
    text-align: start !important;
    color: #dbd7d4 !important;
    text-decoration: underline;
    font-size: 1rem;
    margin-left: 0px;
    margin-right: px;
    padding-left: 10px;
    padding-right: 0px;
    font-weight: bold;
    margin-top: 10px;
}

.cell-below-tick {
    margin-top: -20px;

}

.cell-pri {
    color: #dbd7d4 !important;
    padding-left: 0px;
    margin-left: -8px;
}

.cell-neu {
    color: #dbd7d4;
}

.cell-qty {
    font-size: 0.9rem;
    text-align: center;
    padding-top: 2px;
    margin-left: -10px;
}

.top-row-clubs {
    margin-top: 40px;
}

.active-ranking {
    color: #dddddd;
    text-decoration: none;
    text-align: center;
    padding: 0 3px;
    font-weight: bold;
}

.inactive-ranking {
    text-decoration: underline;
    text-align: center;
    padding: 0 3px;
}

.links {
    color: #777777
}

/*below wrappers were supposed to amends the one of the imported component - but that element does not exist anymore */
/* This >>> allows to style into the component elements. Alternative would be to wrap this into  an additional <style> (not scoped)  */
/* .club-wrapper .main-cont-users {
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 30px;
}
.club-wrapper :deep(.head-card-users) {
    padding-top: 0px;
    margin-top: 0px;
    height: 30px;
} */

.club-spacer {
    height: 28px;
}

/* .thick-card {
    height: 60px;
} */

.cell-status {
    margin-left: -5px;
}

/* FOR searchclubs ************** */
.src-btn {
    background-color: #2F5C85 !important;
    margin-top: 23px;
    width: 100%;
    height: 37px;
    font-size: 1rem;
    padding-left: 5px;
    padding-right: 4px;
}

.src-wrapper :deep(.form-label) {
    color: #c2c2c9 !important;
}

.src-wrapper :deep(#club-src) {
    background-color: #413e3c !important;
    color: #d9d9d9 !important;
    /* font-weight: bold; */
    font-size: 1rem;
}

/* END FOR searchclubs ************** */

/* FOR topclubs ************** */
.col-clubs {
    margin-left: -10px;
    margin-right: 0px;
}

.col-inv {
    margin-left: 0px;
    margin-right: 5px;
}

/* END FOR topclubs ************** */

/* ******************* BTN 3D ******************* */
.btn3d {
    border: 0;
    transition: all 40ms linear;
}

.btn3d.btn-primar {
    box-shadow: 0 0 0 1px #2f5b85a1 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

.btn3d.btn-primar:hover {
    box-shadow: 0 0 0 1px #4a95db34 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

/* ******************* END BTN 3D ******************* */

.club-sticker-round {
    width: 36px;
    margin-top: 7px;
    margin-bottom: 0px;
    margin-left: 10px;
    border-radius: 50%;
}

.club-image {
    height: 36px;
    width: 36px;
    margin-top: 7px;
    margin-bottom: 0px;
    margin-left: 10px;
    margin-right: 0px;
    object-fit: cover;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
}
</style>
