<template>
<div class="container my-5">

    <div class="row mt-5">
        <div class="col d-flex justify-content-center">
            <div class="card pt-4">
                <form>
                    <div class="forms-inputs inner-addon  mt-3 mb-2 ">
                        <img class="input-icon" src="@/assets/icons/at.svg" alt="Email">
                        <input autocomplete="off" placeholder="Email" type="email" v-model="email" @focus="this.emailError='';" @blur="validEmail()" @drop.prevent="false" @paste.prevent="false">
                        <div v-if="this.emailError" class="warn">
                            {{ this.emailError }}
                        </div>
                    </div>

                    <div class="mb-3 btn-pos"> <button @click.prevent="regUserAdmin" class="btn custom-btn btn3d btn-primar">Register</button> </div>
                </form>
                <div class="row">
                    <div class="col d-flex justify-content-center">
                        <div class="login-message">
                            <span class="message-intro">Already have an account? </span>
                            <span>
                                <router-link to="/login" class="message-link">&nbsp;<u>Login</u></router-link>
                            </span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- </div> -->
    <div class="row" v-if="this.showMessage">
        <div class="col d-flex justify-content-center">
            <div class="card-msg">
                <p><b> Email sent to:<br>{{ email }}</b></p>
                <p><b>Please follow the link you have received to verify your email address and complete the registration.</b></p>
            </div>
        </div>
    </div>
    <!-- </div> -->
</div>
<!-- </div> -->
</template>

<script>
// import store from '@/store'
// import router from '@/router'
import authCalls from '@/services/authCalls.js'
export default {
    name: 'UserRegister',
    data() {
        return {
            email: '',
            password: '',
            emailError: '',
            valid: false,
            showMessage: null,
        }
    },
    methods: {
        async regUserAdmin() {
            this.validate()
            // this.password = Math.random().toString(36).slice(2, 10)
            if (this.valid) {
                const credentials = {
                    // username: this.email,
                    // password: this.password,
                    // username: 'z' + Math.random().toString(36).slice(2, 17),
                    // password: Math.random().toString(36).slice(2, 18),
                    email: this.email
                }
                try {
                    await authCalls.registerUser(credentials)
                    this.showMessage = true
                } catch (err) {
                    console.log('error status: ', err)
                }
            }
        },
        validate() {
            if (this.validEmail()) {
                this.valid = true;
            }
        },
        validEmail() {
            // const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i   // start and end may not be necessary. A-Z0-9 may be replaced by \w. A-Z may be replaced by a-zA-Z and then non need for i at the end 
            const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
            if (!this.email || this.email.length < 1) {
                this.emailError = 'Email is required.'
                return false
            } else if (!regex.test(this.email)) {
                this.emailError = 'Invalid email.'
                return false
            }
            this.email = this.email.toLowerCase()
            return true
        },
        onResize() {
            window.scrollTo(0, document.body.scrollHeight)
        }
    },
    mounted() {
        window.addEventListener('resize', this.onResize)
    },
    unmounted() {
        window.removeEventListener('resize', this.onResize)
    }
}
</script>

<style scoped>
/* .verify-message {
    margin-top: 50px;
    font-size: 1.1rem;
} */

/* .spacer-row {
    height: 10px;
} */

/* .cont-login {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
} */

/* #bg-img {
    height: 700px;
    background-image: url("@/assets/images/IC-black33.png");
    background-repeat: no-repeat;
    background-size: 530px;
    background-position-y: -10px;
    background-position-x: 52%;
} */

.registration-feedback {
    background-color: #272522 !important;
    color: #FFF !important;
    height: 68px;
    text-align: center;
    /* width: 100%; */
    margin: 0 auto;
    margin-top: 30px;
    /* padding-top: 10px; */
    margin-bottom: 0px;
    text-align: start;
    font-size: 1rem;
    font-weight: bold;
    /* padding-left: 50px; */
    /* padding-right: 50px; */

}

.card {
    margin-top: -5px;
    border: none;
    /* width: 330px; */
    height: 220px;
    background-color: #272522;
    background-color: rgba(39, 37, 34, 0);
}

.card-msg {
    width: 270px;
    /* height: 180px; */
    padding-left: 0px;
    padding-top: 10px;
    padding-bottom: 0px;
    border: solid 1px;
    border-color: #d2d2d9;
    text-align: center;
}

/* .forms-inputs {
    left: -13px;
} */

.forms-inputs input {
    /* These are the actual input boxes. */
    height: 43px;
    width: 270px;
    /* border: 2px solid #eee; */
    border: none;
    padding-left: 0px;
    border-radius: 7px;
    padding-left: 53px;
}

.forms-inputs input:focus {
    /* what changes when you start entering input in the box */
    box-shadow: none;
    outline: none;
    border: 3px solid #d9d9d9;
    padding-left: 50px;
}

.custom-btn {
    position: relative;
    height: 40px;
    width: 270px;
    margin-top: 23px;
    margin-left: -1px !important;
    background-color: #2F5C85;
    color: white;
    border-radius: 7px;
}

/* ******************* BTN 3D ******************* */
.btn3d {
    border: 0;
    transition: all 40ms linear;
}

.btn3d.btn-primar {
    box-shadow: 0 0 0 1px #2f5b85a1 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

.btn3d.btn-primar:hover {
    box-shadow: 0 0 0 1px #4a95db34 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

/* ******************* END BTN 3D ******************* */
/* .btn-pos {
    position: relative;
    left: -12px;
} */

.warn {
    /* The warning messages which appear in case of error */
    /* color: red; */
    /* color: #d2d2d9; */
    color: #faca64e5;
    font-size: 14px;
    margin-left: 5px;
    padding: 0px;
    width: 260px;
    margin-bottom: -21px;
}

.inner-addon {
    position: relative;
}

.input-icon {
    /* style icon */
    position: absolute;
    height: 43px;
    margin-top: 0px;
    padding: 10px;
    pointer-events: none;
    background-color: #d9d9d9;
    top: 0px;
    left: 0px;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
}

.login-message {
    /* padding-left: 10px; */
    margin-left: 1px;
    margin-top: 0px;
    margin-bottom: 5px;
}

.message-intro {
    /* color: #413e3c; */
    margin-right: 3px;
    color: #d2d2d9;
}

.message-link {
    text-decoration: none !important;
    color: #709fca;
    font-weight: bold;
}

.custom-img-reset {
    /* max-width: 140px; */
    max-width: 125px;
    margin-top: 20px;
    margin-bottom: 0px;
    /* margin: auto 0; */
}
</style>
