<template>
<!-- <div> -->
<div>
    <BaseModalPicture v-if="picUrl" :picUrl="picUrl" :topPos="topPos" :leftPos="leftPos" element='investor' @clicked="onModalClosed" />

    <div v-if="this.showEmptyClubs">
        <!-- <div v-if="this.showEmptyClubs" class="card empty-card"> -->
        <div class="card empty-card">
            <div class="row">
                <!-- <div class="col-12 cell-empty"> -->
                <div class="col-12">
                    {{ this.emptyMsgClubs }}
                </div>
            </div>
        </div>

        <!-- <div v-if="this.showEmptyClubs"> -->
        <div class="wrapper">
            <TableInvestors category='loneinvestor' />
        </div>

    </div>
    <!-- </div> -->
    <div v-if="showClub">
        <div class="row justify-content-start ps-0 pt-3 mb-0" v-if="!this.showEmptyClubs">
            <div class="col-3 col-md-3 d-flex justify-content-center club-icon">
                <span v-if="this.clubentry.clubicon && this.clubentry.clubicon != 'I-11'">
                    <div class="preview-icon" :style="{ 'background-image': `url(${this.clubentry.clubicon})` } " @click.prevent="enlargePic(this.clubentry.clubicon)" id="clubPicture"></div>
                </span>
                <span v-else><img class="user-sticker-round" :src="require('@/assets/images/'+ 'I-11' + '.png')" path="@/assets/images/" alt="user1"></span>
            </div>
            <div class="col-7 col-md-7 club-wrapper d-flex justify-content-start club-name">
                <!-- <BaseInputSelect label="Select club" v-model="clubentry.clubname" @change="toggleClub($event.target.value)" :options="this.clubnames" /> -->
                <BaseInputSelect label="" v-model="this.clubentry.clubname" @change="toggleClub($event.target.selectedIndex)" :options="this.clubnames" id="select-club" />
                <!-- <span style="margin-left: -20px; margin-top: 5px;">ABCDEFGHIJLMNOPQRSTUVZ</span> -->
            </div>
            <div class="col-2 col-md-2 d-flex justify-content-end message-col">
                <span class="span-message" @click.prevent="this.goConversation(this.clubentry)">message</span>
            </div>
        </div>

        <div v-for="entry, index in this.clubentries" :key="entry.id">
            <div v-if="showClub[index]" class="wrapper">
                <!-- <div class="wrapper"> -->
                <!-- <TableClubUsers category='clubusers' /> -->
                <TableInvestors category='myclubinvestors' />
            </div>
        </div>

    </div>
</div>
</template>

<script>
import apiCalls from '@/services/apiCalls.js'
// import TableClubUsers from '@/components/TableClubUsers.vue'
import TableInvestors from '@/components/TableInvestors.vue'
import store from '@/store'
import router from '@/router'
import {
    mapActions
} from 'vuex'
export default {
    name: 'ClubsTable',
    components: {
        // TableClubUsers,
        TableInvestors
    },
    data() {
        return {
            // particId: null,
            // particIcon: null,
            clubentries: [],
            clubnames: [],
            showEmptyClubs: false,
            emptyMsgClubs: 'No club membership',
            showClub: [],
            topClubId: null,
            clubentry: {
                clubname: null,
                clubicon: null,
                src: null,
            },
            picUrl: false,
            topPos: null,
            leftPos: null,
        }
    },
    props: [
        'category',
    ],
    computed: {
        baseCurrency() {
            return store.state.myCur
        },
    },
    methods: {
        ...mapActions(['setCad', 'setCid', 'setCin']),
        async getClubs() {
            try {
                let params = {
                    'participant': store.state.myPid,
                    'status': 'active'
                }
                const MembershipEntries = await apiCalls.getEntries('/memberships/', params)
                if (MembershipEntries && MembershipEntries.data.length > 0) {
                    console.log('MembershipEntries.data: ', MembershipEntries.data)
                    let clubslist = MembershipEntries.data.map(a => a.club)
                    let params2 = {
                        'clubslist': clubslist.join()
                    }
                    const ClubEntries = await apiCalls.getEntries('/clubs/', params2)
                    this.clubentries = ClubEntries.data
                    this.clubnames = this.clubentries.map(a => a.clubname)

                    return console.log('this.clubentries: ', this.clubentries)
                    // if (store.state.cin && store.state.cid && store.state.cin <= (this.clubentries.length - 1)) {  // This should trigger only in case of a refresh
                    //     console.log('store.state.cin etc present in getClubs: ', store.state.cin + '-' + store.state.cid + '-' + this.clubentries.length)
                    //     this.clubentry.clubname = this.clubnames[store.state.cin]
                    //     // this.showClub[store.state.cin] = true
                    // } else {
                    //     console.log('store.state.cin and .cid still absent in getClubs: ', store.state.cin + '-' + store.state.cid)
                    //     await this.setCid(this.clubentries[0].id)
                    //     localStorage.setItem('cid', this.clubentries[0].id)
                    //     this.clubentry.clubname = this.clubnames[0]
                    // }

                } else {
                    return console.log('no clubs for this investor')
                    // this.showEmptyClubs = true
                }
            } catch (error) {
                console.log('error in getClubs: ', error)
            }
        },
        async toggleClub(index) {
            try {
                console.log('selected: ', index)
                await this.setCid(this.clubentries[index].id)
                localStorage.setItem('cid', this.clubentries[index].id)
                console.log('set cid: ', store.state.cid)
                await this.setCin(index)
                localStorage.setItem('cin', index)
                console.log('set cin: ', store.state.cin)
                if (this.clubentries[index].clubicon && this.clubentries[index].clubicon != 'I-11') {
                    this.clubentry.clubicon = store.state.serverUrl + '/getimage/' + this.clubentries[index].clubicon + '/'
                } else if (this.clubentries[index].clubicon && this.clubentries[index].clubicon == 'I-11') {
                    this.clubentry.clubicon = 'I-11'
                }
                // this.clubentry.clubicon = store.state.serverUrl + '/getimage/' + this.clubentries[index].clubicon + '/'
                this.showClub = []
                return this.showClub[index] = true

            } catch (err) {
                console.log('err in toggleClub: ', err)
            }
        },
        enlargePic(src) {
            if (this.picUrl) {
                this.picUrl = false
            } else {
                let elem = document.getElementById('clubPicture')
                let rect = elem.getBoundingClientRect()
                // this.topPos = document.querySelector('pic-' + index).getBoundingClientRect().top
                // this.leftPos = document.querySelector('pic-' + index).getBoundingClientRect().left
                this.topPos = rect.top - 20
                this.leftPos = rect.left
                this.picUrl = src
                // this.showModalPic = true
            }
        },
        onModalClosed(value) {
            console.log('onmodalClosed: ', value)
            this.picUrl = false
            // this.showModalPic = false
        },
        async goConversation(entry) {
            localStorage.setItem('cik', entry.clubicon)
            localStorage.setItem('cna', entry.clubname)

            return router.push({
                name: 'itemdata',
                params: {
                    category: 'conversation',
                }
            })
        },
        // getParticipant() {
        //     this.particId = store.state.myPid
        //     this.particIcon = store.state.myIcon
        //     this.particAlias = store.state.myAlias
        // },
    },
    async created() {
        // console.log('start of experiment')
        // let xyz = false
        // let abc = null
        // let def = ''
        // let ghi
        // // all below trigger the ! condition. For all of them, !variable returns true
        // if(xyz) {
        //     console.log('xyz')
        // }
        // if(!xyz) {
        //     console.log('!xyz')
        //     console.log(xyz) // returns false
        //     console.log(!xyz)
        // }
        // if(abc) {
        //     console.log('abc')
        // }
        // if(!abc) {
        //     console.log('!abc')
        //     console.log(abc) // returns null
        //     console.log(!abc)
        // }
        // if(def) {
        //     console.log('def')
        // }
        // if(!def) {
        //     console.log('!def')
        //     console.log(def) // returns empty space
        //     console.log(!def)
        // }
        // if(ghi) {
        //     console.log('ghi')
        // }
        // if(!def) {
        //     console.log('!ghi')
        //     console.log(ghi) // returns undefined
        //     console.log(!ghi)
        // }
        // console.log('end of experiment')
        try {
            console.log('creating MyClubs')
            // console.log('store.state.myClub: ', store.state.myClub)  //TOPCLUB-RELATED LINES
            console.log("localStorage.getItem('mcl'): ", localStorage.getItem('mcl'))
            console.log('store.state.cid: ', store.state.cid)
            console.log("localStorage.getItem('cid'): ", localStorage.getItem('cid'))
            console.log('store.state.cin: ', store.state.cin)
            console.log("localStorage.getItem('cin'): ", localStorage.getItem('cin'))
            // this.getParticipant()
            await this.getClubs()
            // if (this.clubnames.length > 0) {
            //     this.clubentries.clubname = this.clubnames[0]
            // }
            if (this.clubentries && this.clubentries.length > 0) {

                console.log('this.clubentries present in created: ', this.clubentries)
                if (store.state.cin && store.state.cid) {
                    // alert('Both present')
                    console.log('store.state.cin and .cid already present: ', store.state.cin + '-' + store.state.cid)
                    console.log('this.clubentries.length: ', this.clubentries.length)
                    if (this.clubentries[store.state.cin] && this.clubentries[store.state.cin].id == store.state.cid) {
                        this.clubentry.clubname = this.clubentries[store.state.cin].clubname
                        if (this.clubentries[store.state.cin].clubicon && this.clubentries[store.state.cin].clubicon != 'I-11') {
                            this.clubentry.clubicon = store.state.serverUrl + '/getimage/' + this.clubentries[store.state.cin].clubicon + '/'
                        } else if (this.clubentries[store.state.cin].clubicon && this.clubentries[store.state.cin].clubicon == 'I-11') {
                            this.clubentry.clubicon = 'I-11'
                        }
                        return this.showClub[store.state.cin] = true
                    } else { // means that in the meantime have entered or left some clubs, therefore it may give error. remove from local storage and do a refresh
                        localStorage.removeItem('cin')
                        localStorage.removeItem('cid')
                        return router.go(0)
                    }

                } else {
                    // alert('else')
                    console.log('store.state.cin and .cid not both present in created: ', store.state.cin + '-' + store.state.cid)
                    let myIndex = 0

                    // if (store.state.myClub) { // If I have a top club, then is same but with myClub instead of cid    //TOPCLUB-RELATED LINES
                    if (store.state.cid) { // means it's coming from Messages page and wants to see a specific club
                        // alert('cid')
                        console.log('unsorted clubs: ', this.clubentries)
                        console.log('store.state.cid: ', store.state.cid)
                        // find the club object with id = store.state.cid
                        // discover its index and set cin and cad accordingly
                        // at the end, show it
                        let ind = 0
                        this.clubentries.forEach(clb => {
                            if (clb.id == store.state.cid) {
                                myIndex = ind
                            }
                            ind += 1
                        })
                        // Below lines seem to be redundant
                        // let matchedClub = this.clubentries.find(clb => clb.id == store.state.cid)
                        // console.log('matchedClub: ', matchedClub)
                        // matchedClub['sortIndex'] = 0
                        // this.clubentries.sort(function (a, b) {
                        //     return a.sortIndex - b.sortIndex
                        // })
                        // console.log('sorted clubs: ', this.clubentries)
                    }

                    // this.clubnames = this.clubentries.map(a => a.clubname)
                    this.clubentry.clubname = this.clubentries[myIndex].clubname
                    if (this.clubentries[myIndex].clubicon && this.clubentries[myIndex].clubicon != 'I-11') {
                        this.clubentry.clubicon = store.state.serverUrl + '/getimage/' + this.clubentries[myIndex].clubicon + '/'
                    } else if (this.clubentries[myIndex].clubicon && this.clubentries[myIndex].clubicon == 'I-11') {
                        this.clubentry.clubicon = 'I-11'
                    }
                    // this.clubentry.clubicon = store.state.serverUrl + '/getimage/' + this.clubentries[myIndex].clubicon + '/'
                    if (!store.state.cid || store.state.cid == null) {
                        await this.setCid(this.clubentries[myIndex].id) // This is effectively the prop that gets passed to TableInvestors to show the club members
                        localStorage.setItem('cid', this.clubentries[myIndex].id)
                    }
                    await this.setCin(myIndex)
                    localStorage.setItem('cin', myIndex)
                    console.log('store.state.cin and .cid entered in created: ', store.state.cin + '-' + store.state.cid)
                    // await this.toggleClub(0)
                    return this.showClub[myIndex] = true
                }
            } else {
                this.showEmptyClubs = true
            }

        } catch (err) {
            console.log('err in MyClubs created: ', err)
        }
    }
}
</script>

<style scoped>
.empty-card {
    background-color: #413e3c;
    height: 40px;
    margin-top: 80px;
    padding-top: 6px;
    text-align: center;
}

/* .empty-card {
    background-color: #413e3c !important;
    height: 40px;
    width: 100%;
    margin: 3px auto;
    padding-top: 5px;
    text-align: center;
    font-size: 0.8rem;
    margin-top: 80px;
} */

/* .cell-empty {
    font-size: 0.9rem;

} */

.card-headers {
    height: 30px;
    background-color: #272522 !important;
    margin-bottom: -6px;
    margin-top: 0px;
    text-align: center;
    border: none;
}

.cell-headers {
    color: #6c6c6c !important;
    font-size: 1rem;
    margin-left: -3px;
    padding-left: 0px;
    padding-right: 0px;
}

.head-1 {
    text-align: center;
    margin-left: -4px;
    margin-right: -1px;
    padding-left: 15px;
}

.head-2 {
    text-align: end;
    margin-left: -4px;
    margin-right: -3px;
}

.head-3 {
    margin-left: 5px;
    margin-right: 0px;
}

.cell-tick-clubs {
    /* text-align: center !important; */
    color: #dbd7d4 !important;
    text-decoration: underline;
    font-size: 1rem;
    text-align: start;
    padding-left: 15px;
    margin-left: 10px;
}

.cell-pri {
    /* right text*/
    /* color: #babab9 !important; */
    color: #dbd7d4 !important;
}

.cell-neg {
    /* color: #b33430; */
    color: #e25651;
}

.cell-neg2 {
    /* color: #b33430; */
    color: #8f0f0a;
    color: #e25651;

}

.cell-neg2 {
    /* color: #b33430; */
    color: #8f0f0a;
    color: #a50c07;
}

.cell-pos {
    color: #7fa650;
}

.cell-qty {
    font-size: 0.9rem;
    text-align: center;
    position: relative;
    left: 8px !important;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: -1px;
    margin-right: 3px;
}

.cell-curr {
    /* color: #c19556 !important; */
    color: #babab9 !important;
    margin-left: -3px;
}

/* .cell-left {
    padding-left: 10px;
    padding-right: 0px;
    margin-left: 2px;
    margin-right: -3px;
} */

/* .cell-mid1 {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: -5px;
} */

.cell-mid2 {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
}

.cell-mid3 {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
}

.cell-right {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: -5px;
    margin-right: 0px;
}

.cell-neu {
    color: #dbd7d4;
}

/*below wrappers were supposed to amends the one of the imported component - but that element does not exist anymore */
/* This >>> allows to style into the component elements. Alternative would be to wrap this into  an additional <style> (not scoped)  */
/* .club-wrapper .main-cont-users {
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 30px;
}
.club-wrapper :deep(.head-card-users) {
    padding-top: 0px;
    margin-top: 0px;
    height: 30px;
} */

/* .club-wrapper>>>.bg-medium { */
.club-wrapper :deep(.bg-medium) {
    background-color: #272522 !important;
    color: #c3c3c3 !important;
    font-weight: bold;
    font-size: 1.2rem;
    padding-top: 0px;
    text-decoration: underline;
    box-shadow: none !important;
    margin-top: -1px;
    margin-left: -30px;
    /* font-style: italic; */
}

.club-icon {
    margin-left: -10px;
    margin-right: 10px;
    z-index: 1;
}

.src-btn {
    /* margin-left: 5px; */
    /* margin-right: 5px; */
    margin-top: 23px;
    /* width: 75px; */
    width: 100%;
    height: 38x;
    font-size: 1rem;
}

.src-wrapper :deep(#club-src) {
    background-color: #413e3c !important;
    color: #d9d9d9 !important;
    /* font-weight: bold; */
    font-size: 1rem;
}

/* .my-clubs-select {
    margin-bottom: -18px;
} */

.preview-icon {
    height: 40px;
    width: 40px;
    margin-top: -1px;
    margin-bottom: 0px;
    margin-left: 8px;
    /* was 7, but with justify-center */
    margin-right: 0px;
    object-fit: cover;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
}

.user-sticker-round {
    width: 40px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 6px;
    /* was 5 */
    margin-right: 0px;
    /* margin-top: -23px; */
    /* margin-bottom: 2px; */
    border-radius: 50%;
}

.club-name {
    margin-left: -15px;
    margin-top: 2px;
}

.message-col {
    margin-left: 0px;
    padding: 0;
}

.span-message {
    margin-left: -15px;
    margin-top: 5px;
    /* color: #828489; */
    color: #6c6c6c;
    text-decoration: underline;
}

/* #select-club {
    margin-left: -25px !important;
} */
</style>
