<template>
<div class="container my-5">
    <div v-show="!showConditions">
        <!-- <div class="row spacer-row"></div> -->
        <!-- <div class="row">
            <div class="col d-flex justify-content-center">
                <img id="img" src="@/assets/images/Inv_Modal.png" class="custom-img-reset">
            </div>
        </div> -->

        <form>
            <!-- <div class="row d-flex justify-content-center"> -->
            <div class="row">
                <div class="col d-flex justify-content-center">
                    <div class="card py-4 card-long" id="form-card">

                        <div v-if="showUsernameRules">
                            <p class="username-rules"><span class="rules-title"><b><u>Username rules</u></b></span><br>Use latin letters, numbers, underscore (_) and dash (-), starting with a letter. No spaces. Length: {{minUsername}} to {{maxUsername}} characters.</p>
                        </div>
                        <div v-if="showPasswordRules">
                            <p class="password-rules"><span class="rules-title"><b><u>Password rules</u></b></span><br>Latin letters, numbers and underscore (_), starting with a letter. At least one upper case, one lower case and one number. No spaces. Length: 8 to 16 characters.</p>
                        </div>
                        <div class="forms-inputs inner-addon  mt-3 mb-2 ">
                            <img class="input-icon" src="@/assets/icons/account.svg" alt="Account">
                            <input autocomplete="off" placeholder="Choose your username" type="text" v-model="entry.username" @focus="usernameError=''; showUsernameRules=true;" @blur="showUsernameRules=false; checkUsername();" @drop.prevent="false" @paste.prevent="false" id="username-box">
                            <div v-if="this.usernameError">
                                <p class="warn">{{ this.usernameError }}</p>
                            </div>
                        </div>
                        <div class="forms-inputs inner-addon  mt-3 mb-2 ">
                            <img class="input-icon" src="@/assets/icons/lock.svg" alt="Lock">
                            <input autocomplete="off" :type="showPassword ? 'text' : 'password'" placeholder="Choose password" v-model="entry.password" @focus="this.pwdError=''; showPasswordRules=true;" @drop.prevent="false" @paste.prevent="false" @blur="showPasswordRules=false; validPassword();">
                            <img v-if="showPassword" class="pwd-icon right-icon" src="@/assets/icons/eye.svg" @click="showPassword = !showPassword" alt="Lock">
                            <img v-else class="pwd-icon" src="@/assets/icons/eye-off.svg" @click="showPassword = !showPassword" alt="Lock">
                            <div v-if="this.pwdError">
                                <p class="warn">{{ this.pwdError }}</p>
                            </div>
                        </div>
                        <div class="forms-inputs inner-addon  mt-3 mb-2 ">
                            <img class="input-icon" src="@/assets/icons/lock.svg" alt="Lock">
                            <input autocomplete="off" :type="showPassword2 ? 'text' : 'password'" placeholder="Re-type password" v-model="password2" @focus="this.pwd2Error=''" @blur="validPassword2()" @drop.prevent="false" @paste.prevent="false">
                            <img v-if="showPassword2" class="pwd-icon right-icon" src="@/assets/icons/eye.svg" @click="showPassword2 = !showPassword2" alt="Lock">
                            <img v-else class="pwd-icon" src="@/assets/icons/eye-off.svg" @click="showPassword2 = !showPassword2" alt="Lock">
                            <div v-if="this.pwd2Error">
                                <p class="warn">{{ this.pwd2Error }}</p>
                            </div>
                        </div>

                        <!-- <div v-if="!this.haveCurr" class=" input-box inner-addon"> -->
                        <div class="select-box inner-addon">
                            <img class="input-icon curr-icon" src="@/assets/icons/currency.svg" alt="Currency">
                            <!-- <label class="form-label ms-0 mb-0">Base currency</label> -->
                            <select class="form-select curr-select" v-model="participant.basecurrency" @focus="this.currError='' ">
                                <!-- <option selected>Select base currency</option> -->
                                <option v-for="option in currencies" :value="option" :key="option">{{ option }}</option>
                            </select>
                            <div v-if="this.currError">
                                <p class="warn">{{ this.currError }}</p>
                            </div>
                        </div>
                        <div class="mb-3 btn-pos"> <button type="submit" @click.prevent="regUser" class="btn custom-btn btn3d btn-primar">Register</button> </div>

                    </div>
                </div>
            </div>
        </form>
    </div>
    <div v-show="showConditions">

        <div class="row pt-0">
            <div :class="this.privacyStatement || this.termsStatement ? 'col d-flex justify-content-center title-row-lifted lifted-row px-4' : 'col d-flex justify-content-center title-row px-4'">
                <h2>Confirm Acceptance</h2>

            </div>
        </div>
        <!-- <div v-if="this.privacyStatement" class="row mx-0 mt-2 mb-0">
            <h1>Privacy Policy</h1>
        </div> -->
        <div v-if="this.privacyStatement" class="card scroll-card mx-2">
            <TextPrivacy v-if="this.privacyStatement" />
        </div>
        <!-- <div v-if="this.termsStatement" class="row mx-0 mt-2 mb-0">
            <h3>Terms and Conditions</h3>
        </div> -->
        <div v-if="this.termsStatement" class="card scroll-card mx-2">
            <TextTerms v-if="this.termsStatement" />
        </div>

        <div class="row mt-5 justify-content-center">
            <!-- <div class="col-sm-auto ps-4 pe-1 d-flex justify-content-end"> -->
            <div class="form-check col-2 ps-4 pe-1 d-flex justify-content-end box-size">
                <BaseInputCheckbox label="" v-model="this.termsAccepted" @update:modelValue="this.termsError=''" />
            </div>
            <!-- </div> -->
            <div class="col col-md-auto ps-2 pe-4">
                I have read and accept InvestClub <a href="" @click.prevent="this.privacyStatement=true;this.termsStatement=false;" class="message-link">Privacy Policy</a>
                and <a href="" @click.prevent="this.privacyStatement=false;this.termsStatement=true;" class="message-link">Terms and Conditions</a>
            </div>
        </div>

        <div v-if="this.termsError" class="row my-3 justify-content-center">
            <div class="col d-flex justify-content-center warn2">
                <!-- <p class="warn">{{ this.termsError }}</p> -->
                {{ this.termsError }}
            </div>
        </div>

        <div class="row mt-5">
            <div class="col d-flex justify-content-center">
                <button type="button" @click.prevent="regUser2()" class="btn terms-btn btn3d btn-primar">Accept and proceed</button>
                <button type="button" class="btn btn-secondary" @click.prevent="logOut()">Decline</button>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import store from '@/store'
import {
    mapActions
} from 'vuex'
import router from '@/router'
import apiCalls from '@/services/apiCalls.js'
import TextPrivacy from '@/components/TextPrivacy.vue'
import TextTerms from '@/components/TextTerms.vue'
export default {
    name: 'UserCreate',
    components: {
        TextPrivacy,
        TextTerms,
    },
    data() {
        return {
            password2: '',
            showPassword: false,
            showPassword2: false,
            usernameError: '',
            pwdError: '',
            pwd2Error: '',
            currError: '',
            valid: false,
            clubFull: false,
            entry: {
                username: '',
                password: '',
                email: '',
            },
            participant: {
                usermail: '',
                active: true,
                basecurrency: 'Select base currency',
                usercash: 500000,
            },
            currencies: [
                'Select base currency',
                'USD',
                'EUR',
                'GBP',
                'CHF',
                'AUD',
                'CAD',
            ],
            showConditions: false,
            termsAccepted: false,
            termsError: '',
            privacyStatement: false,
            termsStatement: false,
            showUsernameRules: false,
            showPasswordRules: false,
        }
    },
    computed: {
        haveCurr() {
            return (store.state.myCur)
        },
        maxUsername() {
            return store.state.maxUsername
        },
        minUsername() {
            return store.state.minUsername
        }
    },
    methods: {
        ...mapActions(['setMyPid', 'setMyCur', 'setMyAlias', 'setMyIcon', 'setMyIconM', 'setMyMem', 'setAlertMsg', 'setRfrsh']),
        regUser() {
            this.entry['email'] = localStorage.getItem('rmail')
            this.validate()
            if (this.valid) {
                return this.showConditions = true
            }
        },
        async logOut() {
            await this.setRfrsh('/login')
            await this.setAlertMsg(`We are not able to create your profile unless the Terms of Service are accepted.${'\n\n'}In case that you decide to join later, please obtain a new invitation or register at: www.investclub.net. ${'\n\n'}We hope to see you back soon.`)
            // return router.push('/login')
        },
        async regUser2() {
            if (this.termsAccepted == false) {
                this.termsError = 'Please click the checkbox above to confirm having read and accepted the Terms of Service.'
                return false
            }
            this.entry['email'] = localStorage.getItem('rmail')
            // this.participant.usermail = localStorage.getItem('rmail')  // Not needed anymore in Participant - new-eliminated 0810
            this.validate()
            if (this.valid) {
                try {
                    let usericon = 'I-0' + Math.floor(Math.random() * 11).toString()
                    if (usericon == 'I-010') {
                        usericon = 'I-10'
                    }
                    let fxRate = 1
                    if (this.participant.basecurrency != 'USD') {
                        let params = {
                            'symbol': this.participant.basecurrency + 'USD' + '=X'
                        }
                        console.log(this.participant.basecurrency, ' ', params.symbol)
                        const getFx = await apiCalls.getEntries('/currencies/', params)
                        // let fxRate = 1
                        if (getFx && getFx.data.length > 0) {
                            console.log('fx: ', getFx.data[0])
                            fxRate = getFx.data[0]['lastprice']
                        }
                        console.log('fxRate: ', fxRate)
                    }
                    // localStorage.removeItem('rmail')
                    const patchedUser = await apiCalls.patchEntry('/user_register/' + store.state.myUid + '/', this.entry)
                    if (!patchedUser) {
                        console.log('Could not patch user!!!!!')
                        return false
                    }
                    let newParticipant = {
                        ...this.participant,
                        ...{
                            // 'usermail': this.participant.usermail,  // Not needed anymore in Participant - new-eliminated 0810
                            'user': store.state.myUid,
                            'useralias': this.entry.username,
                            // 'usericon': usericon,
                            'basecurrency': this.participant.basecurrency, // comes from the form
                            'usercash': Math.round((500000 / Number(fxRate)) * 100) / 100,
                            'startcash': Math.round((500000 / Number(fxRate)) * 100) / 100,
                        }
                    }
                    console.log('newParticipant.usercash: ', newParticipant.usercash)
                    localStorage.setItem('cur', this.participant.basecurrency)
                    await this.setMyCur(this.participant.basecurrency)
                    if (store.state.cid) { // Means she was invited by admin
                        newParticipant = {
                            ...newParticipant,
                            ...{
                                'memberships': 1
                            }
                        }
                    }
                    // const getClubName = await apiCalls.getEntries('/clubs/', {
                    //     'id': this.clubId
                    // })
                    // let clubname = getClubName.data['clubname']
                    const createdParticipant = await apiCalls.postEntry('/participants/', newParticipant)
                    if (createdParticipant) {
                        console.log('createdParticipant: ', createdParticipant.data)
                        localStorage.setItem('pid', createdParticipant.data.id)
                        localStorage.setItem('pal', createdParticipant.data.useralias)
                        localStorage.setItem('pik', createdParticipant.data.usericon)
                        localStorage.setItem('pikm', createdParticipant.data.menuicon)
                        await this.setMyPid(createdParticipant.data.id)
                        await this.setMyAlias(createdParticipant.data.useralias)
                        await this.setMyIcon(createdParticipant.data.usericon)
                        await this.setMyIconM(createdParticipant.data.menuicon)
                        if (store.state.cid) { // Only if she was invited, else no club yet!!!

                            const membersCount = await apiCalls.getEntries('/memberships/', {
                                'club': store.state.cid,
                                'status': 'active'
                            })
                            if (membersCount && membersCount.data.length > (store.state.maxMembers - 1)) { // maxMembers
                                this.clubFull = true
                            } else {
                                let newMembership = {
                                    'participant': createdParticipant.data.id,
                                    'club': store.state.cid,
                                    'status': 'active',
                                }
                                const membershipCreated = await apiCalls.postEntry('/memberships/', newMembership)
                                if (membershipCreated) {
                                    localStorage.setItem('mem', '1')
                                    await this.setMyMem('1')
                                }
                            }
                        }
                        localStorage.removeItem('rmail')
                        if (this.clubFull == true) {
                            await this.setAlertMsg(`YOU'RE ALL SET${'\n\n'}However, the club to which you have been invited had already reached its limit of 10 members, therefore you have not become a member of that club.${'\n\n'}To open the Menu, swipe right or push the icon at the bottom (mobile devices) or at the top (desktops).${'\n'}All underlined words are clickable buttons.${'\n\n'}Enjoy InvestClub the and good luck!`)
                        } else {
                            await this.setAlertMsg(`YOU'RE ALL SET${'\n\n'}To open the Menu, swipe right or click the icon at the bottom (mobile devices) or at the top (desktops).${'\n'}All underlined words are clickable buttons. ${'\n\n'}Enjoy InvestClub the and good luck!`)
                        }
                        console.log('participant created successfully: ', store.state.myPid)
                    } else {
                        console.log('TODO: handles if something goes wrong in participant and/or membership creation')
                    }
                    return router.push('/')
                    // return router.push('/profile')
                    // return router.push({
                    //     name: 'icons',
                    //     params: { fromPage: 'create' },
                    // })
                } catch (error) {
                    console.log('error in regUser: ', error)
                }
            }
        },
        // async createClub() {
        //     try {
        //         let endpoint1 = '/clubs/'
        //         let params = {
        //             'basecurrency': this.participant.basecurrency
        //         }
        //         const clubres = await apiCalls.postEntry(endpoint1, params)
        //         this.clubId = clubres.data.id
        //         // await this.setMyClub(clubres.data.id)
        //     } catch (error) {
        //         console.log('error status: ', error.response.status)
        //     }
        // },
        validate() {
            if (this.validUsername() && this.validEmail() && this.validCurrency() && this.validPassword() && this.validPassword2() && this.passwordsMatch()) {
                this.valid = true;
            }
            console.log(this.validUsername(), this.validEmail(), this.validCurrency(), this.validPassword(), this.validPassword2(), this.passwordsMatch())
        },
        async checkUsername() {
            if (!this.validUsername()) {
                return
            }
            console.log('Checking if username unique')
            const usernameExists = await apiCalls.getEntries('/participants/', {
                'useralias': this.entry.username
            })
            if (usernameExists && usernameExists.data.length > 0) {
                console.log('Username already exists: ', usernameExists)
                return await this.setAlertMsg(`The username ${this.entry.username} is already in use, please choose a different one.`)
                //document.getElementById("username-box").focus() // This was getting into an infinite loop!!!
            } else {
                return console.log('Username does not exist: ', usernameExists)
            }
        },
        validUsername() {
            // console.log('here')
            // const regex = /^.{3,5}$/ // 3 to 5 characters long
            // const regex = /^[a-zA-Z]\w{2,4}]$/ // 3 to 5, starting with a letter
            const regex1 = /^[a-zA-Z0-9_-]+$/ // only letters numbers, underscore - minimum 1 character (otherwise * instead of +)
            const regex2 = /^[a-zA-Z]/ // must start with a letter
            // const regex = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/  // At least 1 lower, one upper and one number
            if (!this.entry.username || this.entry.username.length < 1) {
                this.usernameError = 'Username is required.'
                // this.usernameError = 'Invalid username'
                return false
            } else if (this.entry.username.length < store.state.minUsername) {
                this.usernameError = `Minimum ${store.state.minUsername} characters.`
                // this.usernameError = 'Invalid username'
                return false
            } else if (this.entry.username.length > store.state.maxUsername) {
                this.usernameError = `Maximum ${store.state.maxUsername} characters.`
                // this.usernameError = 'Invalid username'
                return false
            } else if (!regex1.test(this.entry.username)) {
                this.usernameError = 'Only latin letters, numbers, underscore, dash.'
                // this.usernameError = 'Invalid username'
                return false
            } else if (!regex2.test(this.entry.username)) {
                this.usernameError = 'Must start with a letter.'
                // this.usernameError = 'Invalid username'
                return false
            }
            return true
        },
        validEmail() {
            // const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i   // start and end may not be necessary. A-Z0-9 may be replaced by \w. A-Z may be replaced by a-zA-Z and then non need for i at the end 
            const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
            if (!this.entry['email'] || this.entry['email'].length < 1) {
                // this.emailError = 'Email is required.'
                return false
            } else if (!regex.test(this.entry['email'])) {
                // this.emailError = 'Invalid email.'
                return false
            }
            // this.email = this.email.toLowerCase()
            return true
        },
        // validEmail() {  || THIS ONE WAS UNTIL 20230415 - replaced by above
        //     if (this.entry['email'].length < 1) {
        //         // this.emailError = 'Invalid email'
        //         return false
        //     }
        //     return true
        // },
        validCurrency() {
            if (this.participant.basecurrency == 'Select base currency' || this.participant.basecurrency.length != 3) {
                this.currError = 'Please select your base currency'
                return false
            }
            return true
        },
        validPassword() {
            const regex1 = /^[a-zA-Z0-9_]+$/ // only letters numbers, underscore - minimum 1 character (otherwise * instead of +)
            const regex2 = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/ // At least 1 lower, one upper and one number
            const regex3 = /^[a-zA-Z]/ // must start with a letter
            if (!this.entry.password || this.entry.password.length < 1) {
                this.pwdError = 'Password is required.'
                // this.pwdError = 'Invalid password'
                return false
            } else if (this.entry.password.length < 8) {
                this.pwdError = 'Minimum 8 characters.'
                // this.pwdError = 'Invalid password'
                return false
            } else if (this.entry.password.length > 16) {
                this.pwdError = 'Maximum 16 characters.'
                // this.pwdError = 'Invalid password'
                return false
            } else if (!regex1.test(this.entry.password)) {
                this.pwdError = 'Only latin letters, numbers and underscore.'
                // this.pwdError = 'Invalid password'
                return false
            } else if (!regex2.test(this.entry.password)) {
                this.pwdError = 'At least one lower case, one upper case and one number.'
                // this.pwdError = 'Invalid password'
                return false
            } else if (!regex3.test(this.entry.password)) {
                this.pwdError = 'Must start with a letter.'
                // this.pwdError = 'Invalid password'
                return false
            }
            return true
        },
        validPassword2() {
            const regex1 = /^[a-zA-Z0-9_]+$/ // only letters numbers, underscore - minimum 1 character (otherwise * instead of +)
            const regex2 = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/ // At least 1 lower, one upper and one number
            const regex3 = /^[a-zA-Z]/ // must start with a letter
            if (!this.password2 || this.password2.length < 1) {
                this.pwd2Error = 'Password is required.'
                // this.pwd2Error = 'Invalid password'
                return false
            } else if (this.password2.length < 8) {
                this.pwd2Error = 'Minimum 8 characters.'
                // this.pwd2Error = 'Invalid password'
                return false
            } else if (this.password2.length > 16) {
                this.pwd2Error = 'Maximum 16 characters.'
                // this.pwd2Error = 'Invalid password'
                return false
            } else if (!regex1.test(this.password2)) {
                this.pwd2Error = 'Only latin letters, numbers and underscore.'
                // this.pwd2Error = 'Invalid password'
                return false
            } else if (!regex2.test(this.password2)) {
                this.pwd2Error = 'At least one lower case, one upper case and one number.'
                // this.pwd2Error = 'Invalid password'
                return false
            } else if (!regex3.test(this.password2)) {
                this.pwd2Error = 'Must start with a letter.'
                // this.pwdError = 'Invalid password'
                return false
            }
            return true
        },
        passwordsMatch() {
            if (this.entry.password != this.password2) {
                this.pwd2Error = 'The passwords don\'t match, please re-enter them.'
                this.password = ''
                this.password2 = ''
                return false
            }
            return true
        },
        onResize() {
            // window.scrollTo(0, document.body.scrollHeight)
            setTimeout(() => { // NEW 20230308  
                document.getElementById('form-card').scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                })
            }, 0)
        }
    },
    mounted() {
        window.addEventListener('resize', this.onResize)
    },
    unmounted() {
        window.removeEventListener('resize', this.onResize)
    }
}
</script>

<style scoped>
/* .spacer-row {
    height: 145px;
} */

.card-long {
    margin-top: 0px;
    /* border-radius: 5px; */
    border: none;
    /* width: 330px; */
    height: 395px;
    /* background-color: #272522; */
    background-color: rgba(39, 37, 34, 0);
}

/* .conditions {
    border: none;
    width: 100%;
    background-color: #272522;
} */

/* .forms-inputs span { */
/* These are the spans where the icon boxes and icons live */
/* position: absolute; */
/* top: -22px; */
/* from the vertical center... */
/* left: -22px; */
/* from the left border of the input box */
/* background-color: #fff; */
/* padding: 5px 10px; */
/* the first one seems to be vertical */
/* font-size: 15px; */
/* irrelevant... */
/* } */

/* .forms-inputs {
    left: -13px;
} */

.forms-inputs input {
    /* These are the actual input boxes */
    height: 43px;
    width: 258px;
    /* border: 2px solid #eee; */
    border: none;
    padding-left: 0px;
    border-radius: 7px;
    padding-left: 50px;
}

.forms-inputs input:focus {
    /* what changes when you start entering input in the box */
    box-shadow: none;
    outline: none;
    border: 3px solid #d9d9d9;
    padding-left: 47px;
}

/* .select-box {
    left: -13px;
} */

.select-box input {
    /* formats what's written inside the input box */
    padding-left: 50px;
}

.custom-btn {
    /* position: relative; */
    height: 39px;
    width: 258px;
    margin-top: 26px;
    margin-left: -1px !important;
    background-color: #2F5C85;
    color: white;
    border-radius: 7px;
}

/* ******************* BTN 3D ******************* */
.btn3d {
    border: 0;
    transition: all 40ms linear;
}

.btn3d.btn-primar {
    box-shadow: 0 0 0 1px #2f5b85a1 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

.btn3d.btn-primar:hover {
    box-shadow: 0 0 0 1px #4a95db34 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

/* ******************* END BTN 3D ******************* */
.btn-pos {
    position: relative;
    /* left: -12px; */
}

.warn {
    /* The warning messages which appear in case of error */
    /* color: red; */
    /* color: #d2d2d9; */
    color: #faca64e5;
    font-size: 14px;
    margin-left: 5px;
    padding: 0px;
    width: 260px;
    margin-bottom: -21px;
}

.username-rules {
    /* color: #d2d2d9; */
    color: #faca64e5;
    font-size: 14px;
    margin-left: -10px;
    padding: 0px;
    width: 260px;
    margin-top: -84px;
    margin-bottom: -10px;
}

.password-rules {
    /* color: #d2d2d9; */
    color: #faca64e5;
    font-size: 14px;
    margin-left: -10px;
    padding: 0px;
    width: 260px;
    margin-top: -100px;
    margin-bottom: -5px;
}

.rules-title {
    color: #d2d2d9;
}

.inner-addon {
    /* enable absolute positioning */
    position: relative;
}

.input-icon {
    /* style icon */
    height: 43px;
    position: absolute;
    margin-top: 0px;
    padding: 10px;
    pointer-events: none;
    background-color: #d9d9d9;
    top: 0px;
    left: 0px;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
}

.pwd-icon {
    position: absolute;
    margin-top: 0px;
    /* margin-left: 20px; */
    padding: 10px;
    cursor: pointer;
    /* background-color: #d9d9d9; */
    top: 1px;
    left: 205px;
    /* Moves the icon in the box */
}

.form-select {
    /* This is the Select box */
    height: 43px;
    width: 258px;
    /* border: 2px solid #eee; */
    border: none;
    padding-left: 50px;
    color: #6c6c6c !important;
    border-radius: 7px;
    font-size: 16px;
    margin-top: 15px !important;

}

.curr-icon {
    /* position: absolute;
    margin-left: 0px;
    margin-top: 17px;
    height: 43px; */
    /* style icon */
    height: 43px;
    position: absolute;
    margin-top: 0px;
    padding: 10px;
    pointer-events: none;
    background-color: #d9d9d9;
    top: 15px;
    left: 0px;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
}

.curr-select:focus {
    box-shadow: none;
    outline: none !important;
    border: 2px solid #d2d2d9
        /* border-color: red; */
}

.custom-img-reset {
    /* max-width: 140px; */
    max-width: 125px;
    margin-top: 20px;
    margin-bottom: 0px;
    /* margin: auto 0; */
}

.terms-btn {
    width: 190px;
    /* margin-right: 10px !important;
    margin-left: -60px !important;
    margin-top: -3px !important; */
    background-color: #2F5C85;
    color: white !important;
    border: none !important;
    height: 40px;
    margin-bottom: 40px;
}

.btn-secondary {
    width: 80px;
    background-color: #8C8B88;
    color: white !important;
    border: none !important;
    margin-left: 25px;
    height: 40px;
}

.warn2 {
    /* The warning messages which appear in case of error */
    /* color: red; */
    /* color: #e79696f1; */
    color: #faca64e5;
    /* font-size: 14px; */
    /* margin-left: 5px; */
    margin-left: 20px;
    margin-right: 20px;

    /* width: 260px; */
    /* margin-bottom: -21px; */
}

.title-row {
    /* font-weight: bold; */
    /* font-size: 1.7rem; */
    text-decoration: underline;
    /* text-decoration: none; */
}

.title-row-lifted {
    text-decoration: underline;
    margin-top: -70px;
}

.message-link {
    text-decoration: underline !important;
    color: #709fca;
}

.scroll-card {
    background-color: #413e3c;
    /* max-height: 300px; */
    max-height: 450px;
    overflow-y: auto;
    margin-bottom: -20px;
}

.box-size {
    margin-top: -3px;
    margin-right: 2px;
    font-size: 1.3rem;
}

/* ******************* BTN 3D ******************* */
.btn3d {
    border: 0;
    transition: all 40ms linear;
}

.btn3d.btn-primar {
    box-shadow: 0 0 0 1px #2f5b85a1 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

.btn3d.btn-primar:hover {
    box-shadow: 0 0 0 1px #4a95db34 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

/* ******************* END BTN 3D ******************* */
</style>
