<template>
<!-- <div class="container main-cont-investorpage" v-show="!this.toInvestorPage"> -->
<div class="container my-5 pt-3" v-show="!this.toInvestorPage">

    <div class="card social-card border-0">
        <div v-if="platforms!=[]">
            <div v-for="(platform, index) in platforms" :key="index">
                <div class="row social-row-inv g-1 mb-2 justify-content-center">
                    <div class="col col-social-img-inv">
                        <img :src="require('@/assets/images/'+ platform.icon + '.png')" path="@/assets/images/" :alt="platform.name" class="social-img-inv">
                    </div>
                    <div class="col-auto col-profile-inv">
                        {{platform.profileUrl}}
                    </div>
                    <div class="col col-social-img-inv">
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="row social-row g-1 mt-0">
                <div class="col-2 col-sm-3 col-social-img2">
                    <img :src="require('@/assets/'+ 'logo.png')" path="@/assets/" class="social-img">
                </div>
            <div v-if="particAlias" class="col-10 col-sm-9 col-profile">
                investclub.net/{{particAlias.toLowerCase()}}
            </div>
        </div>
        <div class="row g-1 social-row row-joined g-1 mb-2">
                <div class="col-2 col-sm-3">
                </div>
            <div class="col-10 col-sm-9 col-profile mt-0">
                Joined: {{ joinedDate }}
            </div>
        </div> -->

    </div>

    <div class="card joined-card">
        <div class="row">
            <div class="col-12">
                Member since {{ joinedDate }}
            </div>
        </div>
    </div>

    <div class="row title-row-pos">
        <div class="col d-flex justify-content-center">
            <h2>Portfolio</h2>
        </div>
    </div>
    <div>
        <TablePortfolio category="portfolio" />
    </div>

    <div class="row title-row-wtc">
        <div class="col d-flex justify-content-center">
            <h2>Watchlist</h2>
        </div>
    </div>
    <div>
        <TableWatchlist category="watchlist" />
    </div>

    <div class="row title-row-history"></div>
    <div>
        <TableHistory category="history" />
    </div>

    <div class="row title-row-clubs"></div>
    <div class="col d-flex justify-content-center col-clubs">
        <h2>Clubs</h2>
    </div>
    <div>
        <TableClubs category='investorclubs' />
    </div>
</div>
</template>

<script>
import apiCalls from '@/services/apiCalls.js'
import TablePortfolio from '@/components/TablePortfolio.vue'
import TableWatchlist from '@/components/TableWatchlist.vue'
import TableHistory from '@/components/TableHistory.vue'
import TableClubs from '@/components/TableClubs.vue'
import store from '@/store'
export default {
    name: 'DisplayInvestor',
    components: {
        TablePortfolio,
        TableWatchlist,
        TableHistory,
        TableClubs,
    },
    props: [
        'investor',
    ],
    // data() {
    //     return {
    //         joinedDate: null,
    //     }
    // },
    computed: {
        toInvestorPage() {
            return store.state.toInvestorPage
        },
        joinedDate() {
            return store.state.joinedDate
        },
        platforms() {
            if (store.state.profilesList != []) {
                return store.state.profilesList
            } else {
                return []
            }
        },
        particAlias() {
            return store.state.particAlias
        }
    },
    methods: {
        // async getAllowComments() {
        //     try {
        //         this.particId = store.state.particId
        //         this.commentsVisible = false
        //         this.commentsAllowed = false
        //         let particClubsList = []
        //         let myClubsList = []
        //         let commonMemberships = false
        //         if (this.particId == store.state.myPid) {
        //             this.commentsVisible = true
        //             this.commentsAllowed = true
        //             return
        //         }
        //         const blockedMe = await apiCalls.getEntries('/blocks/', {
        //             'blocked': store.state.myPid,
        //             'blocker': this.particId,
        //         })
        //         if (blockedMe && blockedMe.data.length > 0) {
        //             this.commentsVisible = false
        //             this.commentsAllowed = false
        //             return
        //         }
        //         if (!this.hideComments && !this.stopComments) {
        //             this.commentsVisible = true
        //             this.commentsAllowed = true
        //             return
        //         }
        //         // If at least one of the 2 restrictions is true check if there are commonMemberships
        //         const particMemberships = await apiCalls.getEntries('/memberships/', {
        //             'participant': this.particId,
        //             'status': 'active',
        //         })
        //         if (particMemberships && particMemberships.data.length > 0) {
        //             console.log('particMemberships: ', particMemberships.data)
        //             particClubsList = particMemberships.data.map(a => a.club)
        //             console.log('particClubsList: ', particClubsList)
        //             const myMemberships = await apiCalls.getEntries('/memberships/', {
        //                 'participant': store.state.myPid,
        //                 'status': 'active',
        //             })
        //             if (myMemberships && myMemberships.data.length > 0) {
        //                 console.log('myMembershipsInPortfolio: ', myMemberships.data)
        //                 myClubsList = myMemberships.data.map(a => a.club)
        //                 console.log('myClubsList: ', myClubsList)
        //                 commonMemberships = particClubsList.some(item => myClubsList.includes(item))
        //             } else {
        //                 commonMemberships = false
        //             }
        //         } else {
        //             commonMemberships = false
        //         }
        //         if (commonMemberships == true) {
        //             this.commentsVisible = true
        //             this.commentsAllowed = true
        //             return
        //         }
        //         // if there are no common memberships, then the restrictions apply
        //         if (this.hideComment) {
        //             this.commentsVisible = false
        //             this.commentsAllowed = false
        //             return
        //         } else if (this.stopComment) {
        //             this.commentsVisible = true
        //             this.commentsAllowed = false
        //             return
        //         }
        //     } catch (err) {
        //         console.log('error in getAllowComments: ', err)
        //         this.commentsVisible = false
        //         this.commentsAllowed = false
        //         return
        //     }
        // },
    },
    beforeRouteEnter: async function (to, from) {
        try {
            console.log('STARTING beforeRouteEnter in DisplayInvestor')
            console.log('store.state.page: ', store.state.page)
            console.log('store.state.particId: ', store.state.particId)
            console.log('store.state.toInvestorPage:', store.state.toInvestorPage)
            console.log('**************to in DisplayInvestor beforeRouteEnter: ', to)
            console.log('**************to.params.investor in DisplayInvestor beforeRouteEnter: ', to.params.investor)
            console.log('******************from in DisplayInvestor beforeRouteEnter: ', from)
            console.log('******************from.path in DisplayInvestor beforeRouteEnter: ', from.path)
            if (from.path == '/' && store.state.page != '/investor') { // means that it comes from the hyperlinks in comments or in notifications, not through the router, therefore need to do the cleaning of paid/paic/paal here

                // await store.dispatch('setParticId', null)
                // await store.dispatch('setParticIcon', null)
                // await store.dispatch('setParticAlias', null)
                // localStorage.removeItem('paid')
                // localStorage.removeItem('paic')
                // localStorage.removeItem('paal')
                console.log('*******NOT DONE: WOULD HAVE REMOVED paid/paic/paal in localstorage and in store********')
            }

            // let particIcon = null
            // let particAlias = null

            if (localStorage.getItem('paid') && localStorage.getItem('paic') && localStorage.getItem('paal')) { // if they are present, then it's a refresh or it comes from Portfolio/Watchlist through the router, so no need for apiCall
                console.log('it\'s a refresh or comes from Portfolio/Watchlist/Investors through the router')
                console.log('ATT store.state.particId present in investor page1: ', store.state.particId)

                // ADDED THE BELOW - BASICALLY YOU ALWAYS HAVE TO DO THE APICALL TO GET THE INVESTOR
                // const Investor1 = await apiCalls.getEntries('/participants/' + store.state.particId + '/', {})
                // if (Investor1) {
                //     console.log('Investor.data: ', Investor1.data)
                //     await store.dispatch('setInvestorData', Investor1.data) // ADDED in order not to run getParticipant in History, Portfolio and Watchlist!
                //     particIcon = Investor1.data['usericon']
                //     particAlias = Investor1.data['useralias']
                //     // await store.dispatch('setParticId', Investor.data['id']) // NEW
                //     // let joinedDate = Investor.data['created']
                //     await store.dispatch('setParticIcon', particIcon)
                //     await store.dispatch('setParticAlias', particAlias)
                //     localStorage.setItem('paic', particIcon)
                //     localStorage.setItem('paal', particAlias)
                // } else {
                console.log('route1 in DisplayInvestor/beforeRouteEnter')
                // }

                /////////////// END ADDED /////////////////////////////////////////////////////

                // } else if (to.params.investor) {
            } else if (localStorage.getItem('paid') && !localStorage.getItem('paic') && !localStorage.getItem('paal')) { //Means it comes from hyperlink and only localStorage('paid') has been filled in 
                console.log('comes via from.path: ', from.path)
                console.log('ATT store.state.particId present in investor page2: ', store.state.particId)
                // let particId = to.params.investor
                // localStorage.setItem('paid', particId)
                // await store.dispatch('setParticId', particId)

                // const Investor2 = await apiCalls.getEntries('/participants/' + store.state.particId + '/', {})
                // if (Investor2) {
                //     console.log('Investor.data: ', Investor2.data)
                //     await store.dispatch('setInvestorData', Investor2.data) // ADDED in order not to run getParticipant in History, Portfolio and Watchlist!
                //     particIcon = Investor2.data['usericon']
                //     particAlias = Investor2.data['useralias']
                //     // await store.dispatch('setParticId', Investor.data['id']) // NEW
                //     // let joinedDate = Investor.data['created']
                //     await store.dispatch('setParticIcon', particIcon)
                //     await store.dispatch('setParticAlias', particAlias)
                //     localStorage.setItem('paic', particIcon)
                //     localStorage.setItem('paal', particAlias)
                // } else {
                console.log('route 2 in DisplayInvestor/beforeRouteEnter')
                // }
            }

            const Investor = await apiCalls.getEntries('/participants/' + store.state.particId + '/', {})
            if (Investor) {
                console.log('Investor.data: ', Investor.data)
                await store.dispatch('setInvestorData', Investor.data) // ADDED in order not to run getParticipant in History, Portfolio and Watchlist!
                let particIcon = Investor.data['usericon']
                let particAlias = Investor.data['useralias']
                // await store.dispatch('setParticId', Investor.data['id']) // NEW
                let joinedDate = Investor.data['created']
                let profiles = Investor.data['profiles']
                if (profiles) {
                    console.log('profilse in Investor page: ', profiles)
                    let platforms = [{
                            name: 'Twitter',
                            icon: 'twitter',
                            genericUrl: 'twitter.com/',
                            profileUrl: '',
                        },
                        {
                            name: 'LinkedIn',
                            icon: 'linkedin',
                            genericUrl: 'linkedin.com/',
                            profileUrl: '',
                        },
                        {
                            name: 'Facebook',
                            icon: 'facebook',
                            genericUrl: 'facebook.com/',
                            profileUrl: '',
                        },
                        {
                            name: 'Instagram',
                            icon: 'instagram',
                            genericUrl: 'instagram/',
                            profileUrl: '',
                        },
                        {
                            name: 'YouTube',
                            icon: 'youtube',
                            genericUrl: 'youtube.com/',
                            profileUrl: '',
                        },
                        {
                            name: 'TikTok',
                            icon: 'tiktok',
                            genericUrl: 'tiktok.com/',
                            profileUrl: '',
                        },
                        {
                            name: 'Reddit',
                            icon: 'reddit',
                            genericUrl: 'reddit.com/',
                            profileUrl: '',
                        }
                    ]
                    let profilesList = profiles.split(',')
                    let index = 0
                    var profilesListStore = []
                    profilesList.forEach(profile => {
                        index = parseInt(profile.charAt(0))
                        platforms[index].profileUrl = platforms[index].genericUrl + profile.substring(2)
                        console.log('profile: ', index, platforms[index].profileUrl)
                        profilesListStore.push(platforms[index])
                    })
                    console.log('profilesListStore: ', profilesListStore)
                }

                joinedDate = joinedDate.slice(0, 10)
                await store.dispatch('setJoinedDate', joinedDate)
                await store.dispatch('setProfilesList', profilesListStore)
                console.log('profilesListStore2: ', store.state.profilesList)
                await store.dispatch('setParticIcon', particIcon)
                await store.dispatch('setParticAlias', particAlias)
                localStorage.setItem('paic', particIcon)
                localStorage.setItem('paal', particAlias)
            } else {
                console.log('Could not find participant in DisplayInvestor/beforeRouteEnter')
            }

            if (store.state.fromInvestorPage == true) {
                await store.dispatch('setFrominvPage', false)
            }
            // this is getAllowedComments, so it doesn't execute it in Portfolio, Watchlist, History
            let particClubsList = []
            let myClubsList = []
            let commonMemberships = false
            if (store.state.particId == store.state.myPid) {
                await store.dispatch('setCommentsVisible', true)
                await store.dispatch('setCommentsAllowed', true)
                console.log('ATTT: finished setting Visible and Allowed in DisplayInvestor true+true 1!!!!!')
                console.log('ENDING beforeRouteEnter in DisplayInvestor')
                return
            }
            const blockedMe = await apiCalls.getEntries('/blocks/', {
                'blocked': store.state.myPid,
                'blocker': store.state.particId,
            })
            if (blockedMe && blockedMe.data.length > 0) {
                await store.dispatch('setCommentsVisible', false)
                await store.dispatch('setCommentsAllowed', false)
                return
            }
            // console.log('AAATT store.state.investorData[stopcomments]: ', store.state.investorData['stopcomments'])
            console.log('AAATT store.state.investorData: ', store.state.investorData)

            // if (store.state.investorData['stopcomments'] == false) { // DELETE
            if (store.state.investorData['hidecomments'] == false && store.state.investorData['stopcomments'] == false) { // RESTORE
                await store.dispatch('setCommentsVisible', true)
                await store.dispatch('setCommentsAllowed', true)
                console.log('ATTT: finished setting Visible and Allowed in DisplayInvestor true+true 2!!!!!')
                console.log('ENDING beforeRouteEnter in DisplayInvestor')
                return
            }
            // If at least one of the 2 restrictions is true check if there are commonMemberships
            const particMemberships = await apiCalls.getEntries('/memberships/', {
                'participant': store.state.particId,
                'status': 'active',
            })
            if (particMemberships && particMemberships.data.length > 0) {
                console.log('particMemberships: ', particMemberships.data)
                particClubsList = particMemberships.data.map(a => a.club)
                console.log('particClubsList: ', particClubsList)
                const myMemberships = await apiCalls.getEntries('/memberships/', {
                    'participant': store.state.myPid,
                    'status': 'active',
                })
                if (myMemberships && myMemberships.data.length > 0) {
                    console.log('myMembershipsInPortfolio: ', myMemberships.data)
                    myClubsList = myMemberships.data.map(a => a.club)
                    console.log('myClubsList: ', myClubsList)
                    commonMemberships = particClubsList.some(item => myClubsList.includes(item))
                } else {
                    commonMemberships = false
                }
            } else {
                commonMemberships = false
            }
            if (commonMemberships == true) {
                await store.dispatch('setCommentsVisible', true)
                await store.dispatch('setCommentsAllowed', true)
                console.log('ATTT: finished setting Visible and Allowed in DisplayInvestor true+true 3!!!!!')
                console.log('ENDING beforeRouteEnter in DisplayInvestor')
                return
            }
            // if there are no common memberships, then the restrictions apply
            // DELETE BELOW!!!
            // if (store.state.investorData['stopcomments'] == true) {
            //     await store.dispatch('setCommentsVisible', false)
            //     await store.dispatch('setCommentsAllowed', false)
            //     console.log('ATTT: finished setting Visible and Allowed in DisplayInvestor false+false!!!!!')
            //     console.log('ENDING beforeRouteEnter in DisplayInvestor')
            //     return
            // } else {
            //     await store.dispatch('setCommentsVisible', true)
            //     await store.dispatch('setCommentsAllowed', true)
            //     console.log('ATTT: finished setting Visible and Allowed in DisplayInvestor true+true 4!!!!!')
            //     console.log('ENDING beforeRouteEnter in DisplayInvestor')
            //     return
            // }

            // RESTORE BELOW!!!
            if (store.state.investorData['hidecomments'] == true) {
                await store.dispatch('setCommentsVisible', false)
                await store.dispatch('setCommentsAllowed', false)
                console.log('ATTT: finished setting Visible and Allowed in DisplayInvestor false+false!!!!!')
                console.log('ENDING beforeRouteEnter in DisplayInvestor')
                return
            } else if (store.state.investorData['stopcomments'] == true) {
                await store.dispatch('setCommentsVisible', true)
                await store.dispatch('setCommentsAllowed', false)
                console.log('ATTT: finished setting Visible and Allowed in DisplayInvestor true+false!!!!!')
                console.log('ENDING beforeRouteEnter in DisplayInvestor')
                return
            }

        } catch (err) {
            console.log('error inside DisplayInvestor/beforeRouteEnter: ', err)
            await store.dispatch('setCommentsVisible', false)
            await store.dispatch('setCommentsAllowed', false)
            console.log('ATTT - ERROR: finished setting Visible and Allowed in DisplayInvestor!!!!!')
            return
        }
    },
}
</script>

<style>
/* .main-cont-investorpage {
    margin-top: 70px;
    margin-bottom: 60px;
} */

.title-row-pos {
    /* margin-top: 30px !important; */
    margin-bottom: -45px;
}

.title-row-wtc {
    margin-bottom: -45px;
    margin-top: 35px !important;
}

.title-row-history {
    margin-bottom: -60px;
    margin-top: 70px !important;
}

.title-row-clubs {
    /* margin-bottom: -60px !important; */
    margin-top: -10px !important;
    /* height: 30px; */
}

.col-clubs {
    margin-bottom: -8px;
}

.social-card {
    background-color: #272522 !important;
    /* background-color: #413e3c !important; */
    /* height: 40px; */
    margin-top: -5px;
    /* was -16*/
    margin-bottom: 50px;
    /*was 24*/
    padding-top: 0px;
    /*was6*/
    padding-bottom: 0px;
    text-align: center;
}

.social-row-inv {
    /* margin-left: -15px; */
    margin-top: 3px;
    margin-bottom: 0px;
    padding-left: 0px;
}

.col-social-img-inv {
    text-align: end;
    padding-right: 0px !important;
    margin-right: -20px;
    margin-left: 20px;
}

.col-social-img2 {
    text-align: end;
    padding-right: 10px !important;
    margin-top: 20px;
    margin-bottom: -20px;
    padding-top: 9px;
}

.social-img-inv {
    height: 28px;
    width: 28px;
    border-radius: 50%;
}

.col-profile-inv {
    text-align: start;
    padding-left: 35px !important;
    padding-top: 2px;
    padding-bottom: 0px;
    /* margin-left: 5px; */
    padding-right: 0px;
    color: #d2d2d9;
}

.row-joined {
    margin-top: -6px !important;
}

.joined-card {
    background-color: #413e3c !important;
    height: 40px;
    margin-top: -30px;
    margin-bottom: 30px;
    padding-top: 6px;
    text-align: center;
}
</style>
