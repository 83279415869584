<template>
<div class="container my-5 cont-login">
    <!-- <div class="row spacer-row"></div>
    <div class="row">
        <div class="col d-flex justify-content-center">
            <img id="img" src="@/assets/images/Inv_Modal.png" class="custom-img-login">
        </div>
    </div> -->

    <!-- <div id="bg-img"> -->
    <div v-if="moveUp" class="row spacing-row"></div>

    <div class="row">
        <div class="col d-flex justify-content-center">
            <div class="card px-5 pt-3 pb-0 card-lgn">
                <div class="row">
                    <div class="col d-flex justify-content-center">
                        <div class="forms-inputs inner-addon  mt-3 mb-2 ">
                            <img class="input-icon" src="@/assets/icons/at.svg" alt="Email">
                            <input autocomplete="off" placeholder="Enter your email address" type="email" v-model="entry.usermail" @focus="this.emailError='';" @blur="validEmail(); moveUp=false;" @drop.prevent="false" @paste.prevent="false">
                            <div v-if="this.emailError" class="warn">
                                {{ this.emailError }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col d-flex justify-content-center">

                        <div class="mb-3 btn-pos"> <button @click="resetPwd" class="btn custom-btn btn3d btn-primar">Reset Password</button> </div>
                    </div>
                </div>

                <div v-if="this.pwdMessage" class="updatedMessage">
                    {{ this.pwdMessage }}
                </div>
                <div class="row">
                    <div class="col d-flex justify-content-center">
                        <div class="mb-3 btn-pos"> <button @click="resetUser" class="btn custom-btn btn3d btn-primar">Reset Username and Password</button> </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col d-flex justify-content-center">

                        <div class="login-message">
                            <span class="message-intro">Back to</span>
                            <span>
                                <router-link @click="moveUp=false" to="/login" class="message-link">&nbsp;<u>Login</u> </router-link>
                            </span>
                            <span class="message-intro"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col d-flex justify-content-center">
            <!-- <div class="row d-flex justify-content-center mt-0 pt-0"> -->
            <div class="card-msg px-3 mt-0 my-2" v-if="this.profileMessage">
                <!-- <p><b> An email has been sent to your email address. Please follow the link included in that email to reset your Username and Password and create new ones</b></p> -->
                <b>{{ this.profileMessage }} </b>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import authCalls from '@/services/authCalls.js'
export default {
    name: 'UserResetRequest',
    data() {
        return {
            // f: '',
            emailError: '',
            profileMessage: '',
            pwdMessage: '',
            entry: {
                usermail: null,
                username: null,
                password: null,
            },
            moveUp: false,
        }
    },
    methods: {
        async resetUser() {
            if (!this.entry.usermail) {
                return
            }
            if (!this.validEmail()) {
                return
            }
            try {
                let edituser = {
                    'email': this.entry.usermail.toLowerCase(), //lowercase here should be redundant, i.e. already done, but added just to be sure
                    'lid': 'l' // l stands for LoginID, while p stands for password
                }
                console.log('edituser: ', edituser)
                // let endpoint1 = '/user_reset/'
                const resp = await authCalls.userResetRequest(edituser)
                console.log('resp: ', resp)
                if (resp.request.status < 200 || resp.request.status > 299) {
                    var failed = true
                }
                if (failed) {
                    return this.profileMessage = "Could not reset Username"
                } else {
                    this.moveUp = true
                    return this.profileMessage = "If this address exists in our database, an email has been sent to it. Please follow the link you have received in order to reset Username and Password and create new ones."
                }
            } catch (err) {
                console.log('error status: ', err)
            }
        },
        async resetPwd() {
            if (!this.entry.usermail) {
                return
            }
            if (!this.validEmail()) {
                return
            }
            try {
                let edituser = {
                    'email': this.entry.usermail.toLowerCase(), //lowercase here should be redundant, i.e. already done, but added just to be sure,
                    'lid': 'p' // l stands for LoginID, while p stands for password
                }
                console.log('edituser: ', edituser)
                const resp = await authCalls.userResetRequest(edituser)
                console.log('resp: ', resp)
                if (resp.request.status < 200 || resp.request.status > 299) {
                    var failed = true
                }
                if (failed) {
                    return this.profileMessage = "Could not reset Username"
                } else {
                    this.moveUp = true
                    return this.profileMessage = "If this address exists in our database, an email has been sent to it. Please follow the link you have received in order to reset the Password and create a new one."
                }
            } catch (err) {
                console.log('error status: ', err)
            }
        },
        validEmail() {
            // const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i   // start and end may not be necessary. A-Z0-9 may be replaced by \w. A-Z may be replaced by a-zA-Z and then non need for i at the end 
            const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
            if (!this.entry.usermail || this.entry.usermail.length < 1) {
                this.emailError = 'Email is required.'
                return false
            } else if (!regex.test(this.entry.usermail)) {
                this.emailError = 'Invalid email.'
                return false
            }
            this.entry.usermail = this.entry.usermail.toLowerCase()
            return true
        },
        onResize() {
            window.scrollTo(0, document.body.scrollHeight)
        }
    },
    mounted() {
        window.addEventListener('resize', this.onResize)
    },
    unmounted() {
        window.removeEventListener('resize', this.onResize)
    }
}
</script>

<style scoped>
/* .spacer-row {
    height: 10px;
} */

/* .cont-login {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
} */

/* #bg-img {
    height: 700px;
    background-image: url("@/assets/images/IC-black33.png");
    background-repeat: no-repeat;
    background-size: 530px;
    background-position-y: -10px;
    background-position-x: 52%;
} */

.card {
    margin-top: -5px;
    border-radius: 7px;
    border: none;
    width: 330px;
    height: 290px;
    background-color: #272522;
    background-color: rgba(39, 37, 34, 0);
    padding-top: 25px !important;
}

.card-msg {
    background-color: #272522;
    width: 270px;
    margin-top: 0px;
    /* height: 150px; */
    padding-left: 0px;
    padding-top: 10px;
    padding-bottom: 8px;
    border: solid 1px;
    border-color: #d2d2d9;
}

/* .forms-inputs {

    left: -13px;    
} */

.forms-inputs input {
    /* These are the actual input boxes */
    height: 43px;
    width: 270px;
    /* border: 2px solid #eee; */
    border: none;
    padding-left: 0px;
    border-radius: 7px;
    padding-left: 53px;
}

.forms-inputs input:focus {
    /* what changes when you start entering input in the box */
    box-shadow: none;
    outline: none;
    border: 3px solid #d9d9d9;
    padding-left: 50px;
}

.custom-btn {
    position: relative;
    height: 40px;
    width: 270px;
    margin-top: 19px;
    margin-bottom: -5px;
    margin-left: -1px !important;
    background-color: #2F5C85;
    color: white;
    border-radius: 7px;
    padding-left: 0px;
    padding-right: 0px;
}

/* ******************* BTN 3D ******************* */
.btn3d {
    border: 0;
    transition: all 40ms linear;
}

.btn3d.btn-primar {
    box-shadow: 0 0 0 1px #2f5b85a1 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

.btn3d.btn-primar:hover {
    box-shadow: 0 0 0 1px #4a95db34 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

/* ******************* END BTN 3D ******************* */
/* .btn-pos {
    position: relative;
} */

.warn {
    /* The warning messages which appear in case of error */
    /* color: red; */
    /* color: #d2d2d9; */
    color: #faca64e5;
    font-size: 14px;
    /* margin-left: 5px; */
    padding: 0px;
    width: 260px;
    margin-bottom: -21px;
}

.inner-addon {
    /* enable absolute positioning */
    position: relative;
}

.input-icon {
    /* style icon */
    position: absolute;
    height: 43px;
    margin-top: 0px;
    padding: 10px;
    pointer-events: none;
    background-color: #d9d9d9;
    top: 0px;
    left: 0px;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
}

.login-message {
    /* padding-left: 10px; */
    /* margin-left: 9px; */
    margin-top: 10px;
    text-align: center;
    /* margin-bottom: -20px !important; */
    /* margin-bottom: 0px; */

}

.message-intro {
    color: #fff;
    margin-right: 3px;

}

.message-link {
    text-decoration: none !important;
    color: #709fca;
    font-weight: bold;
}

.custom-img-login {
    max-width: 125px;
    margin-top: 20px;
    margin-bottom: 0px;
}

.spacing-row {
    height: 2px;
    margin-top: -30px;
    margin-bottom: -20px;

}

.card-lgn {
    margin-bottom: -15px;
}
</style>
