<template>
<div>
<!-- <h1>How it works</h1><br> -->
<br><p><b><a class="links-manual" href="" @click.prevent="closeAll(); navigation=!navigation">A) Basic Navigation</a></b></p>
<div v-show="navigation"><p>
1) Swipe-right or push the bottom-right icon (top-right icon for desktops) to unhide the Menu bar.
<br><br>2) Every underlined text in the app is a clickable link to initiate an activity, open a window or a navigate to a page.
<br><br></p></div>
<p><b><a class="links-manual" href="" @click.prevent="closeAll(); rules=!rules">B) Top 10 Features/Rules</a></b></p>
<div v-show="rules"><p>
1) Upon joining, you receive an amount of "simulated cash" equivalent to 500,000 USD converted to your preferred currency at the exchange rate of the time of joining.
<br><br>2) To add assets to your portfolio, in the Menu navigate to "Add asset". Search the asset by entering for example part of the company name for a stock, or directly the ticker symbol of the asset and hit Search. Select the asset from the dropdown menu. If the market for the asset is open, then enter an integer amount to represent for example the number of stocks to purchase (or sell short). Add (whenever you can) a comment to share the background of your simulated investment with other users and hit "Add to Portfolio".
<br><br>3) If the market is closed, assets cannot be added to the portfolio (nor positions can be exited or reduced). However, also during market-close hours new assets can be added to the watchlist and comments can be entered to watchlist or to existing assets in the portfolio or watchlist.
<br><br>4) A user can enter a Long or a Short position for an asset. In both cases, the User's cash is reduced by the price (ask or bid price) of the asset at the time of entering the position, times the quantity, adjusted according to the exchange rate if the asset is denominated in a currency different than the user's base currency. For Long positions, assets remain in the portfolio until the user closes the position. However, for short position, the position is automatically closed when the loss is equivalent to the initial amount deducted from the user's cash when entering the position (which is therefore equivalent to the "margin" for the trade, and when the margin is lost it triggers a stop loss, the position is closed and no simulated cash is returned to the user).
<br><br>5) Simulated Fees: when entering a position in the poprtfolio (both for Long and Short positions), in addition to the value of the assets, an additional 1% of the total amount is deducted from the user's cash, as if a 1% transaction fee was being paid. This is done only when entering a position (or adding to an existing position), while the "simulated fee" when exiting (or reducing) a position is zero. This fee rate is set high by design in order to encourage medium-long term investments rather than a large number of short-term trades, because the platform is more geared towards medium-term investments driven by some analysis (possibly to be shared with other members) rather than towards a large number of short-term trades with no particular background other than short-term price momentum. 
<br><br>6) Mark-to-market: All assets are marked-to-market overnight at the last available price of the day (always bid or ask to simulate liquidation value, depending on the Long or Short direction of the investment). During the day, the values or the price of the assets in the portfolio are not updated in real time - again, this is only done once a day overnight. However, when entering a new position (or operating on an existing one), the prices are by design the real-time ones (by design in the sense that the platform does not guarantee the 100% accuracy, but does strive to provide prices very close to real-time when operating on a position or adding a new one). 
<br><br>7) In order to create clubs, go to the "Clubs admin" page in the Menu. When you create a club by giving it a unique name, you are automatically the administrator and can invite users to it (by email) in the lower part of the page. Alternatively, if you would like to invite existing users of the platform, you do not need their email address. Just search for them in the "Search" page, click their alias in order to expand their card and select "Invite". In the window which appears you can select the club to which you want to invite them and Invite. As a reminder, you can only invite new members to a club if you are the administrator of that club. If you are not the administrator, you can still send a message to the club administrator (the user that originally invited you to the club or to whom you asked to join it), and request to invite the new member to that club.
<br><br>8) You can also join clubs by finding them in the "Search" page, expanding the club card to see more info, and click on "Ask to join". 
<br><br>9) Notice that for the time being the platform does not adjust stock prices after stock splits, nor does it add "dividends" to the simulated cash holdings of users. However, both these features are planned to be added soon (please bear with us).
<br><br></p></div>
<p><b><a class="links-manual" href="" @click.prevent="closeAll(); limits=!limits">C) Limits</a></b></p>
<div v-show="limits"><p> 
- A user may have a maximum of 10 assets in the Portfolio and maximum 10 assets in the Watchlist at any given time. When that limit is reached, the user needs to close or remove one of the assets/positions from the Portfolio or from the Watchlist in order to be able to add a new asset.
<br><br>- A user may belong to a maximum of 10 clubs at any given time, whether she is the administrator or just a club member. If you want to join a new club and you already belong to 10 clubs, please leave one first.
<br><br>- Any club may have a maximum of 10 members. Mind that, if a member is removed from a club or leaves it, she remains a member of the platform and may continue to use it normally, but is simply no longer a member of that particular club.
<br><br>- A user may Follow a maximum of 30 other users (in order to be notified when they opertate on their portfolio or watchlist).
<br><br></p></div>
<p><b><a class="links-manual" href="" @click.prevent="closeAll(); profile=!profile">D) Profile handling</a></b></p>
<div v-show="profile"><p> 
- To change your username, password, email address, profile thumbnail, please navigate in the Menu to the "Profile" page.
<br><br>- In the Profile page you can also find different settings that restrict other users from sending you messages, invite you to clubs or read the comments to your own portfolio or watchlist positions. However, you may not restrict members of one of your clubs from doing any of that, i.e. you cannot apply any of those restrictions to club co-members.
<br><br>- In the Profile page you may also Delete your Profile permanently. In order to do that, it is required that you enter your password. When you delete your Profile, anything related to you is permanently deleted from the platform.
<br><br></p></div>
<p><b><a class="links-manual" href="" @click.prevent="closeAll(); notifications=!notifications">E) Notifications</a></b></p>
<div v-show="notifications"><p> 
- The platformn does not send any notification via email, nor does it show a warning on your device screen when there is a Notification for a User. In order to see the Notifications which you may have received, you have to visit the "Notifications" page from the Menu. However, you do see a warning in the Menu next to the Notifications link, with the number of unread Notificatiosn which you have in that page.
<br><br>- A Notification informs you whenever a co-member of any of your clubs, or a user which you Follow, operated in their Portfolio or Watchlist (adding, removing or - in the case of the portfolio - reducing the amount of an asset). It also informs when you have received an invitation to join a club, or a private message from another user.
<br><br>- Notice that Notifications get deleted automatically after 3 days (in the case of notifications of portfolio/watchlist transactions) or after 14 days (for invitations and private messages)
<br><br></p></div>
<p><b><a class="links-manual" href="" @click.prevent="closeAll(); behavior=!behavior">F) Behavior standards</a></b></p>
<div v-show="behavior"><p> 
- This platform is not meant by the developers to be a "forum" in the traditional sense, but more a brainstorming tool and a way to share analysis and demonstrate strategies among grups investors. However, the platform does support informal and free communication among users. On other other side, inappropriate messages (whether sent in private or left as comments to positions in Portfolios or Watchlists) are not supported. Users which receive these kind of messages may Delete them, Block the users that have sent or posted them and also Report the inappropriate comments/messages to the Administrators, who may decide to take measures accordingly.
<br><br>- There is the chance for the users to upload small thumbnails for their profiles (which become visible to all other users) or for their Menu icons. The platform does not support any content of such thumbnails (or of the text of user's aliases) which may be offensive, significantly provocative or of course infringe laws of any country where free-speech is generally protected. 
<br><br>- The Administrators may, at their sole discretion, decide to discontinue Profiles which do not follow these simple behavioral rules of respect for the other users and that risk to compromise the integrity and the overall spirit of the platform, or in extreme cases that may violate laws.
<br><br></p></div>
<br>

</div>
</template>

<script>

export default {
    data() {
        return {
            navigation: false,
            rules: false,
            limits: false,
            profile: false,
            notifications: false,
            behavior: false,
        }
    },
    methods: {
        closeAll() {
            // this.navigation=false
            // this.rules = false
            // this.limits = false
            // this.profile =false
            // this.notifications = false
            // this.behavior = false
            return
        }
    }

}
</script>

<style scoped>
.links-manual {
    color: #dbd7d4;
    text-decoration: underline;
    font-size: 17px;
    text-decoration-thickness: 1px;
}
</style>
