import axios from 'axios'
import store from '@/store'
// import router from '@/router'

const mbClient = axios.create({
    baseURL: 'https://mboum-finance.p.rapidapi.com',
    timeout:5000,
    // timeout:1, // Here in case that I want to test generating error with API
    // params: {modules: 'defaultKeyStatistics,assetProfile'},
    withCredentials: false,
    headers: {
        'X-RapidAPI-Host': 'mboum-finance.p.rapidapi.com',
        'X-RapidAPI-Key': 'd8f0881e4emsh31377c7cf897a2dp1ea90fjsn2fe8c8b5b827',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }
})



mbClient.interceptors.request.use(
    async function(config) {
        await store.dispatch('setIsLoading', true)
        return config
    }
)

mbClient.interceptors.response.use(
    async function (response) {    // Any status code that lie within the range of 2xx cause this function to trigger. Do something with response data (here just returning the response unchanged)
        await store.dispatch('setIsLoading', false)
        return response
    },
    async function (error) {    // Any status codes that falls outside the range of 2xx cause this function to trigger    // Do something with response error
        try {
            await store.dispatch('setIsLoading', false)
            console.log('error in mbClient1: ',error)
            return 
        } catch (error) {
            console.log('error in mbClient2: ', error)
        }
    }
)

export default {
    getBalanceSheet(params) {
        let endpoint = '/qu/quote/balance-sheet/'
        return mbClient.get(endpoint, {
            params: params,
        })
    },
    getIncomeStatement(params) {
        let endpoint = '/qu/quote/income-statement/'
        return mbClient.get(endpoint, {
            params: params,
        })
    },
    getCashFlow(params) {
        let endpoint = '/qu/quote/cashflow-statement/'
        return mbClient.get(endpoint, {
            params: params,
        })
    },
    getKeyStatistics(params) {
        let endpoint = '/qu/quote/default-key-statistics/'
        return mbClient.get(endpoint, {
            params: params,
        })
    },
    getMarketQuotes(params) {
        let endpoint = '/qu/quote/'
        return mbClient.get(endpoint, {
            params: params,
        })
    },
    getStockData(params) {
        let endpoint = '/mo/module/'
        return mbClient.get(endpoint, {
            params: params,
        })
    }
}