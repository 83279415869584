<template>
<div class="container my-5">
    <div class="card main-card-form">
        <div v-if="!showSearch">
            <form>
                <div class="row justify-content-center gx-3 gy-0 pt-2">
                    <div class="col col-sm-8">
                        <BaseInputEnter label="Enter Ticker (e.g. AAPL or aapl)" v-model="tickerName" @focus="emptyAll()" />
                    </div>
                    <div class="col-3 col-sm-2">
                        <button type="submit" @click.prevent="getCryptoPrice(tickerName)" class="btn custom-btn-form get-btn btn3d btn-primar">Get Price</button>
                    </div>
                </div>
                <div v-if="searchWarn" class="row justify-content-center">
                    <div class="col col-sm-10 search-warn">
                        {{searchWarn}}
                    </div>
                </div>
            </form>
            <div class="row justify-content-center search-toggle">
                <div class="col col-sm-10">
                    Not sure about the ticker? <span @click.prevent="showSearch=true; showEnterTicker=false; emptyAll()">&nbsp;&nbsp;<u><b>Search</b></u></span>
                </div>
            </div>
            <div v-if="entry.shortName" class="row justify-content-center gx-3 gy-0 pt-2 mt-3">
                <!-- <div class="col-3 px-0"> -->
                <div class="col col-sm-10 ps-1">
                    &nbsp; Name: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>{{ entry.shortName }}</b>
                </div>
            </div>
            <div v-if="entry.shortName" class="row justify-content-center gx-3 gy-0 pt-2">
                <div class="col col-sm-10 ps-1">
                    &nbsp; Exchange: &nbsp; <b>{{ entry.exchange }}</b>
                </div>
            </div>
        </div>
        <div v-else>
            <form>
                <div class="row justify-content-center gx-3 gy-0 pt-2">
                    <div class="col col-sm-8">
                        <BaseInputEnter label="Search crypto asset" v-model="nameSearch" @focus="emptyAll()" />
                    </div>
                    <div class="col-3 col-sm-2">
                        <button type="submit" @click.prevent="searchCryptoApi()" class="btn custom-btn-form src-btn btn3d btn-primar">Search</button>
                    </div>
                </div>
                
                <div v-if="searchWarn" class="row justify-content-center">
                    <div class="col col-sm-10 search-warn">
                        {{searchWarn}}
                    </div>
                </div>

            </form>
            <div class="row justify-content-center gx-2 gy-0 mt-3">
                <div class="col-xs-10 col-sm-10">
                    <BaseInputSelect label="Select crypto asset" v-model="assetName" @change="getCryptoPrice($event.target.value)" :options="tickers" />
                </div>
            </div>
            <!-- <div v-if="showEnterTicker" class="row justify-content-center search-toggle"> -->
            <div class="row justify-content-center search-toggle">
                <div class="col col-sm-10">
                    <!-- Asset not in the list? <span @click.prevent="showSearch=false; showEnterTicker=false; emptyAll() ">&nbsp;&nbsp;<u><b>Enter ticker</b></u></span><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<router-link :to="{ name: 'newassetdata' }" class="links" @click="clearPartic">Asset</router-link></span> -->
                    <span>&nbsp;<router-link :to="{ name: 'newassetdata' }" class="link-asset">Back to &nbsp;NON-CRYPTO ASSETS</router-link></span>

                </div>
            </div>
        </div>
        <div class="row ">
            <div class="divider sep-line"></div>
        </div>

        <div class="row justify-content-center gx-4 gy-2 mt-3 ">
            <div class="col-xs-6 col-sm-5">
                <div class="row">
                    <div class="col">
                        <BaseInputSelect label="Long/Short" v-model="entry.longshort" @change="adjustPrice($event.target.value)" :options="longshorts" />
                    </div>
                    <div class="col">
                        <button type="submit" @click.prevent="addWatchlist" class="btn custom-btn-form wtc-btn btn3d btn-primar">Add to watchlist</button>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-5">
                <div class="row">
                    <div class="col">
                        <BaseInputEnter label="Quantity" v-model="entryPos.quantity" type="number" step=1 @drop.prevent="false" @paste.prevent="false" @blur="validQty()" />
                    </div>
                    <div class="col">
                        <button type="submit" @click.prevent="addPosition" class="btn custom-btn-form pos-btn btn3d btn-primar">Add to portfolio</button>
                    </div>
                    <div v-if="showMsg"> {{ this.msg }}</div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center gx-4 gy-2 mt-1">
            <div class="col-xs-6 col-sm-5">
                <div class="row">
                    <div class="col">
                        <!-- <BaseInputEnter :label="this.entry.longshort == 'long' ? 'Ask price' : 'Bid price'" v-model="entry.startprice" type="text" disabled /> -->
                        <BaseInputEnter :label="this.pricelabel" v-model="entry.startprice" type="text" disabled />
                    </div>
                    <div class="col">
                        <BaseInputEnter label="Currency" v-model="entry.currency" type="text" disabled />
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-5">
                <div class="row">
                    <div class="col">
                        <BaseInputEnter :label="this.fxlabel" v-model="entry.startfx" type="text" step=0.01 disabled />
                    </div>
                    <div class="col">
                        <!-- <BaseInputEnter :label="this.amountLabel" v-model="this.amount" type="number" step=0.01 disabled /> -->
                        <BaseInputEnter :label="this.amountLabel" v-model="this.showAmount" type="text" disabled />
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center pt-2 comment-missing" v-show="this.showWarning">
            <div class="col col-sm-9 line-break-and-tab pt-2" id="warn-msg">
                {{ this.warningMsg }}
            </div>

            <div class="col-1 col-sm-1 d-flex justify-content-end pe-3 pt-2">
                <button class="btn close-butt" role="button" @click.prevent="this.close()">
                    <div class="btn-tex">
                        x
                    </div>
                </button>
            </div>
        </div>

        <div class="row ">
            <div class="divider sep-line"></div>
        </div>

        <div class="row justify-content-center gy-4 mt-0">
            <!-- <span id="charMsg" style="color: Red; display: none">Following special characters not allowed: "%\&\{\}"</span> -->
            <span v-if="this.charMsg" id="characMsg">Special characters not allowed: "%\&//{}'[]^</span>
            <div class="col-sm-10 pb-1 ps-3">
                <label class="comment-label">Comment</label>
            </div>
            <div class="col-sm-10">
                <div class="form-floating">
                    <!-- <textarea class="form-control comment-box" maxlength="3000" id="floatingTextarea" v-model="entry.comment" @keydown="IsSpecialKeys($event)" @drop.prevent="false" @paste.prevent="false"></textarea> -->
                    <textarea class="form-control comment-box" maxlength="3000" id="floatingTextarea" v-model="entry.comment" @drop.prevent="false" @paste.prevent="false"></textarea>

                </div>
            </div>
        </div>
    </div>
</div>

<!-- <input type="text" value="U" @keyup="IsSpecialKeys($event)"> -->
<!-- <input type="text" value="D" @keydown="IsSpecialKeys($event)"> -->
<!-- <input type="text" value="P" @keypress="IsSpecialKeys($event)"> -->
<!-- <input type="text" value="I" @input="IsSpecialKeys($event)"> -->
</template>

<script>

// import mbapiCalls from '@/services/mbapiCalls.js'

import apiCalls from '@/services/apiCalls.js'
import yfapiCalls from '@/services/yfapiCalls.js'
import cgapiCalls from '@/services/cgapiCalls.js'
import store from '@/store'
import router from '@/router'
export default {
    name: 'FormCrypto',
    data() {
        return {
            showEnterTicker: false,
            showSearch: true,
            searchWarn: '',
            tickerName: '',
            assetName: '',
            showWarning: false,
            commentWarning: 'Please share a comment with your club members before entering a new asset',
            warningMsg: '',
            tickers: [],
            nameSearch: '',
            region: 'any',
            regions: [
                'any',
                'US',
                'GB',
                'DE',
                'FR',
                'IT',
                'ES',
                'AU',
                'CA',
                'HK',
                'IN',
            ],
            longshorts: [
                'long',
                'short',
            ],
            entry: {
                assetname: '',
                symbol: '',
                comment: '',
                longshort: 'long',
                currency: 'USD',
                startprice: '',
                lastprice: '',
                currencysymbol: '',
                startfx: '',
                exchange: '',
                shortName: '',
            },
            entryPos: {
                quantity: '',
                startvalue: 0,
                participant: null,
            },
            askprice: null,
            bidprice: null,
            marketopen: false,
            pricelabel: 'Price',
            fxlabel: 'FX',
            showMsg: false,
            msg: '',
            usercash: null,
            userportfolio: null,
            openpos: 0,
            openwtc: 0,
            charMsg: false,
            // takenassets: '',
            // takenassetsObj: {},
        }
    },
    computed: {
        amount() { // Number.EPSILON is the smallest number generated as rounding when checking equalities between floats (also decimals?). So equalities between floats or decimals should be checked by checking that their difference is smaller than Number.EPSILON 
            // Here amount is what you are going to pay/commit to buy the asset (long or short)
            return Math.round(((Number(this.entry.startprice) * this.entryPos.quantity * Number(this.entry.startfx)) + Number.EPSILON) * 100) / 100
        },
        showAmount() {
            if (this.entry.startprice > 0) {
                return (Math.round(((Number(this.entry.startprice) * this.entryPos.quantity * Number(this.entry.startfx)) + Number.EPSILON) * 100) / 100).toLocaleString('en-US')
            } else {
                return ''
            }
},
        amountLabel() {
            return "Amount/" + store.state.myCur.toUpperCase()
        }
    },
    methods: {
        // IsSpecialKeys(e) {
        //     this.charMsg = false
        //     console.log('event: ', e)
        //     alert(JSON.stringify({
        //         'key': e.key,
        //         'keycode': e.keyCode
        //     }, null, 4))
        //     let specChar = ['*', '&', '^', '%', '#', '@', '<', '>', '/', '{', '}', '[', ']']
        //     // specChar.push('\\')
        //     if (specChar.indexOf(e.key) != -1) {
        //         alert(specChar.indexOf(e.key))
        //         e.preventDefault()
        //         this.charMsg = true
        //         return false
        //     }
        //     return true
        // }, // ATTENTION: the dollar sign ($) directly followed by single quote (') generates a mess in Vue Format!!!!!!!!!!!! Taken it out of specChar

        close() {
            this.showWarning = false
        },
        emptyAll() {
            this.tickerName=this.assetName=this.nameSearch=this.msg=this.warningMsg=this.searchWarn = ''
            this.showWarning = this.marketopen = this.showMsg = this.charMsg = false
            this.askprice = this.bidprice = null
            this.openpos = this.openwtc = 0
            this.tickers = []
            this.entry = {
                assetname: '',
                symbol: '',
                comment: '',
                longshort: 'long',
                currency: 'USD',
                startprice: '',
                lastprice: '',
                currencysymbol: '',
                startfx: '',
                exchange: '',
                shortName: '',
            }
            this.entryPos = {
                quantity: '',
                startvalue: 0,
                participant: null,
            }
        },
        async searchCryptoApi() {
            this.showEnterTicker = true
            this.close() // Hides the warning message if there is one open
            if (!this.nameSearch) {
                // this.warningMsg = 'Please enter an asset to search.'
                // return this.showWarning = true
                this.tickers = []
                return this.searchWarn = 'Please enter an asset to search.'
            }
            // console.log('this.nameSearch: ', this.nameSearch)
            try {
                let params = {
                    query: this.nameSearch,
                }
                let resp = null

                let dropdownTickers = await cgapiCalls.searchCG(params)
                console.log('dropdownTickers CG: ', dropdownTickers)

                if (dropdownTickers && dropdownTickers.data.coins && dropdownTickers.data.coins.length > 0) {
                    resp = dropdownTickers.data.coins
                } else {
                    this.assetName = ''
                    this.tickerName = ''
                    this.nameSearch = ''
                    this.tickers = []
                    // this.warningMsg = 'No asset found matching your search'
                    // return this.showWarning = true
                    return this.searchWarn = 'No asset found matching your search!'
                }

                // resp.sort(function (a, b) {
                //     return a.sortIndex - b.sortIndex
                // })
                // console.log('sorted resp: ', resp)
                // let tickerIds = []
                // let tickerSymbols = []
                if (dropdownTickers.data.coins) {
                    // this.tickers = resp.map(a => a.symbol + ' (' + a.id + ')')
                    this.tickers = resp.map(a => a.symbol + ' - CRYPTO - ' + a.id)
                    // tickerIds = resp.map(a => a.id)
                    // tickerSymbols = resp.map(a => a.symbol)
                }

                this.assetName = this.tickers[0]
                await this.getCryptoPrice(this.assetName)
            } catch (error) {
                return console.log('error in Search Crypto asset: ', error)
            }
        },
        async getCryptoPrice(asset) {
            console.log('TRIGGERED get')
            console.log('this.warningMsg get: ', this.warningMsg)
            let id = ''
            let symbol = ''
            if (!asset&& this.assetName == '') {
                // this.warningMsg = 'Please enter an asset to search.'
                // return this.showWarning = true
                this.tickers = []
                return this.searchWarn = 'Please enter an asset to search.'
            } else {
                symbol = asset.substring(0, asset.indexOf(' - CRYPTO - '))
                id = asset.slice(asset.indexOf(' - CRYPTO - ') + 12)
            }

            console.log('asset: ', symbol + id)
            console.log('this.assetName11: ', this.assetName)

            // if (this.assetName) {
            //     console.log('asset_old: ', this.assetName.split(' ')[0])
            // }
            this.close()
            // if (this.assetAlreadyTaken(asset)) {
            //     return this.showWarning = true
            // }
            try {

                // alert(asset)
                let params = {
                    ids: id,
                    vs_currencies: 'usd',
                    precision: 10,
                }
                let tickerData = []
                tickerData = await cgapiCalls.getCryptoPrice(params)
                console.log('tickerData CG: ', tickerData)
                console.log('tickerData CG1: ', tickerData.data)
                console.log('tickerData CG3: ', tickerData.data[id])

                if (tickerData && tickerData.data && tickerData.data[id] && tickerData.data[id]['usd']) {
                    console.log('Selected asset data: ', tickerData.data[id])
                    console.log('tickerData: ', tickerData)
                // } else if(){
                //     console.log
                //     this.assetName = ''
                //     this.tickerName = ''
                //     this.nameSearch = ''
                //     // this.warningMsg = 'No asset found matching your search'
                //     // return this.showWarning = true
                //     return this.searchWarn = 'No asset found matching your search'
                } else {
                    console.log
                    this.assetName = ''
                    this.tickerName = ''
                    this.nameSearch = ''
                    this.tickers = []
                    // this.warningMsg = 'No asset found matching your search'
                    // return this.showWarning = true
                    return this.searchWarn = 'No asset found matching your search!'
                }
                // if (!this.assetName) { //means it's coming directly from entering ticker, rather than from dropdown
                // this.entry.assetname = param.symbols + ' - ' + tickerData.data.quoteResponse.result[0]['fullExchangeName'] + ' - ' + tickerData.data.quoteResponse.result[0]['shortName']
                // console.log('this.entry.assetname: ', this.entry.assetname)
                // }

                // this.entry.shortName = tickerData.data.quoteResponse.result[0]['shortName']
                // this.entry.exchange = tickerData.data.quoteResponse.result[0]['fullExchangeName']
                // this.entry.currency = tickerData.data.quoteResponse.result[0]['currency']
                // if (this.entry.currency) {
                //     this.entry.currency = this.entry.currency.toUpperCase() // GBp vs GBP
                // }
                this.askprice = tickerData.data[id]['usd']
                this.bidprice = tickerData.data[id]['usd']
                if (this.askprice != null) {
                    this.askprice = Math.round(this.askprice*10000000000)/10000000000
                }
                if (this.bidprice != null) {
                    this.bidprice = Math.round(this.bidprice*10000000000)/10000000000
                }

                if (symbol) {
                    // this.entry.symbol = 'CRYPTO:' + symbol
                    this.entry.symbol = symbol
                }
                this.entry.assetname = asset

                // if (tickerData.data.quoteResponse.result[0]['marketState'] == 'REGULAR') {
                    this.marketopen = true
                    console.log('Market is open')
                // } else {
                //     this.marketopen = false
                //     console.log('Market is closed')
                // }
                if (this.marketopen == false) {
                    this.pricelabel = 'Price (closed)'

                    if (tickerData && tickerData.data[id] && tickerData.data[id]['usd']) {
                        this.entry.startprice = tickerData && tickerData.data[id] && tickerData.data[id]['usd']
                    } else {
                        this.entry.startprice = null
                    }
                    if (this.entry.startprice != null) {
                        this.entry.startprice = Math.round(this.entry.startprice*10000000000)/10000000000
                    }
                    this.warningMsg = `The market for this asset is closed.${'\n'}It may only be added to your watchlist.`
                    document.getElementById('warn-msg').innerHTML = this.warningMsg
                    this.showWarning = true
                } else {
                    if (this.entry.longshort == 'long') {
                        this.entry.startprice = this.askprice
                        // this.entry.lastprice = this.bidprice // Was done to show the current value of position, but no need, better be at the beginning lastprice = startprice. At night gets anyway overwritten by closing price
                        this.pricelabel = 'Ask price (open)'
                    } else if (this.entry.longshort == 'short') {
                        this.entry.startprice = this.bidprice
                        // this.entry.lastprice = this.askprice
                        this.pricelabel = 'Bid price (open)'
                    }
                }

                // if (tickerData.data.quoteResponse.result[0]['currency'] == 'GBp') {
                //     this.entry.startprice = this.entry.startprice/100
                // }

                // if (!this.askprice || this.askprice == 0 || !this.bidprice || this.bidprice == 0) {
                //     this.marketopen = false
                // } else {
                //     this.marketopen = true
                // }
                console.log('Ask price: ', this.askprice)
                if (this.entry.longshort == 'long' && this.marketopen && (this.askprice == null || !this.askprice || this.askprice <= 0)) {
                    this.pricelabel = 'Price (closed)'
                    // this.entry.startprice = tickerData.data.quoteResponse.result[0]['regularMarketPrice']
                    if (this.entry.startprice != null) {
                        this.entry.startprice = Math.round(this.entry.startprice*10000000000)/10000000000
                    }
                    this.warningMsg = `No ask price is available.${'\n'}It may only be added to your watchlist.`
                    document.getElementById('warn-msg').innerHTML = this.warningMsg
                    this.showWarning = true

                    // this.warningMsg = 'Market seems open but no ask price is available. Please try again later.'
                    // return this.showWarning = true
                    // this.askprice = tickerData.data.quoteResponse.result[0]['regularMarketPrice']
                } else if (this.entry.longshort == 'short' && this.marketopen && (this.bidprice == null || !this.bidprice || this.bidprice <= 0)) {
                    this.pricelabel = 'Price (closed)'
                    // this.entry.startprice = tickerData.data.quoteResponse.result[0]['regularMarketPrice']
                    if (this.entry.startprice != null) {
                        this.entry.startprice = Math.round(this.entry.startprice*10000000000)/10000000000
                    }
                    this.warningMsg = `No bid price is available.${'\n'}It may only be added to your watchlist.`
                    document.getElementById('warn-msg').innerHTML = this.warningMsg
                    this.showWarning = true

                    // this.warningMsg = 'Market seems open but no bid price is available. Please try again later.'
                    // return this.showWarning = true
                    // this.bidprice = tickerData.data.quoteResponse.result[0]['regularMarketPrice']
                }

                let fxData = []
                let fx = null
                if (!this.entry.currency) {
                    return
                }
                if (this.entry.currency != store.state.myCur) {
                    if (this.entry.currency == 'USD') {
                        params['symbols'] = store.state.myCur + '=X'
                    } else {
                        params['symbols'] = this.entry.currency + store.state.myCur + '=X'
                    }
                    if (store.state.apiUrl == 'yf') {
                        fxData = await yfapiCalls.getYF(params)
                    } else {
                        fxData = await yfapiCalls.getRapid(params)
                    }
                    let fxAsk = ''
                    let fxBid = ''
                    if (fxData.data.quoteResponse.result[0]['ask'] >= fxData.data.quoteResponse.result[0]['bid']) {
                        fxAsk = fxData.data.quoteResponse.result[0]['ask']
                        fxBid = fxData.data.quoteResponse.result[0]['bid']
                        console.log('fxAsk: ', fxAsk)
                        // console.log('typeof fxData.data.quoteResponse.result[0][ask]: ', fxData.data.quoteResponse.result[0]['ask'])
                        console.log('fxBid: ', fxBid)
                    } else {
                        fxAsk = fxData.data.quoteResponse.result[0]['bid']
                        fxBid = fxData.data.quoteResponse.result[0]['ask']
                        console.log('fxAsk: ', fxAsk)
                        console.log('typeof fxData.data.quoteResponse.result[0][bid]: ', fxData.data.quoteResponse.result[0]['bid'])
                        console.log('fxBid: ', fxBid)
                    }

                    //Below has been changed - the start fx rate is always the ask, also for shorts
                    // if (this.entry.longshort == 'long') {
                    // fx = fxData.data.quoteResponse.result[0]['ask']  // this line had been already commmented out
                    fx = fxAsk
                    this.fxlabel = `Ask ${this.entry.currency.toUpperCase()}/${store.state.myCur.toUpperCase()}`
                    // } else if (this.entry.longshort == 'short') {
                    // fx = fxData.data.quoteResponse.result[0]['bid']  // this line had been already commmented out
                    // fx = fxBid
                    // this.fxlabel = `Bid ${this.entry.currency.toUpperCase()}/${store.state.myCur.toUpperCase()}`
                    // }

                    this.entry.startfx = Math.round((fx + Number.EPSILON) * 10000) / 10000
                    console.log('Selected currency data: ', fxData.data.quoteResponse.result[0])
                } else if (this.entry.currency == store.state.myCur) {
                    this.entry.startfx = 1.0000
                    this.fxlabel = 'FX'
                    console.log('Asset currency is same as base currency')
                }
                console.log('this.entry.startfx: ', this.entry.startfx)
            } catch (error) {
                return console.log('error in populating dropdowns: ', error)
            }
        },
        async addPosition() {
            if (!this.entry.assetname) {
                if (this.showSearch) {
                    this.warningMsg = 'Please first select an asset.'
                } else {
                    this.warningMsg = 'Please first enter a ticker and Get Price.'
                }
                return this.showWarning = true
            }
            if (!this.entry.currency) {
                this.warningMsg = 'Sorry, no currency is indicated for this asset, please select a different one.'
                return this.showWarning = true
            }
            // if (this.assetAlreadyTaken()) {
            //     return this.showWarning = true
            // }
            if (this.openpos >= store.state.maxPositions) { // maxPositions
                this.warningMsg = 'You already have 20 open trades. Please close one before opening one more.'
                return this.showWarning = true
            }
            if (this.marketopen == false) {
                this.warningMsg = 'The market for this asset is closed. Pls try again when the market is open.'
                return this.showWarning = true // COMMENT OUT THIS LINE (and the other one, 2 in total) TO OPERATE ON MARKET CLOSED   0917 RESTORE
            }
            if (this.pricelabel == 'Price (closed)') {
                this.warningMsg = 'Live price not available. Can only be added to watchlist.'
                return this.showWarning = true // COMMENT OUT THIS LINE (and the other one, 2 in total) TO OPERATE ON MARKET CLOSED   0917 RESTORE
            }
            if (!this.entry.startprice || this.entry.startprice <= 0) {
                this.warningMsg = 'Price for this asset is not available. Cannot be added to portfolio'
                return this.showWarning = true
            }
            if (this.entryPos.quantity < 0.01 || Math.round(this.entryPos.quantity * 100)/100 != this.entryPos.quantity) {
                this.warningMsg = 'Please enter a number with max 2 decimal digits in the Quantity field.'
                return this.showWarning = true
            }
            // if (!this.entry.comment) {  // COMMENT OUT THIS PARAGRAPH TO ENTER POSITIONS WITHOUT COMMENT  0917 RESTORE
            //     this.warningMsg = this.commentWarning
            //     document.getElementById('warn-msg').innerHTML = this.warningMsg
            //     this.showWarning = true
            //     document.getElementById('floatingTextarea').focus()
            //     return document.getElementById('floatingTextarea').scrollIntoView({ behavior: 'smooth'}) 
            // } //////////////////////////////

            if (this.amount * (1 + store.state.tradingFee) > this.usercash) { // Trading fee = 1.01
                this.msg = 'Maximum buying power: ' + (Math.round(this.usercash * 100 / (1 + store.state.tradingFee)) / 100).toLocaleString('en-US') + ' ' + store.state.myCur // Trading fee = 1.01
                return this.showMsg = true
            }
            try {
                // await this.getTakenassets()
                // if (this.assetAlreadyTaken()) {
                //     return this.showWarning = true
                // }

                // ALL Below was already commented out!!!
                // const newAssetPartTable = store.state.myIcon +'-pos'
                // const newObj = {
                //     [newAssetname]: newAssetPartTable
                // }
                // this.takenassetsObj = {
                //     ...this.takenassetsObj,
                //     ...newObj
                // }
                // const takenassetsString = JSON.stringify(this.takenassetsObj)
                // const params = {
                //     'takenassets': takenassetsString
                // }
                // await apiCalls.patchEntry('/clubs/' + store.state.myClub + '/', params)


                // if (this.entry.assetname) {
                //     this.entry['symbol'] = this.entry.assetname.split(' ')[0]
                // } else {
                //     this.entry['symbol'] = this.tickerName
                // }
                // this.entry.assetname = this.entry['symbol'] + ' - ' + this.entry.exchange + ' - ' + this.entry.shortName

                if (this.entry['currency'] != store.state.myCur) {
                    this.entry['currencysymbol'] = this.entry['currency'] + store.state.myCur + '=X' //left this line in just for the subsequent test
                    if (!this.entry['currencysymbol'] || !/[a-zA-Z]{6}=[xX]/.test(this.entry['currencysymbol'])) { // left this in just as an additional test (may be redundant but ok)
                        this.warningMsg = 'The currency for this asset is not clear. Please try again or choose a different asset.'
                        return this.showWarning = true
                    }
                    if (this.entry['currency'] == 'USD') {
                        this.entry['currencysymbol'] = store.state.myCur + '=X'
                    } else {
                        this.entry['currencysymbol'] = this.entry['currency'] + store.state.myCur + '=X'
                    }
                }
                this.entry.startprice = Math.round(this.entry.startprice * 10000000000) / 10000000000 //RECENT1605
                this.entry['lastprice'] = this.entry.startprice
                this.entryPos.participant = store.state.myPid
                this.entryPos.startvalue = this.amount // This is what you pay/commit to buy the asset (long or short)                
                // if (this.amount <= this.usercash) {

                this.entry['amount'] = this.amount
                this.entry['feerate'] = store.state.tradingFee
                this.entry['poschg'] = 1

                const entrie = {
                    ...this.entry,
                    ...this.entryPos
                }
                console.log('entrie: ', entrie)
                let updatedComment = null
                let myDate = new Date()
                // let myIcon = store.state.myIcon
                let myAlias = store.state.myAlias
                if (this.entry.longshort == 'long') {
                    updatedComment = `B: ${entrie.quantity}; pri ${entrie.startprice}; fx ${entrie.startfx}; ${myDate.getFullYear()}-${('0'+(myDate.getMonth()+1)).slice(-2)}-${('0'+myDate.getDate()).slice(-2)}`
                } else if (this.entry.longshort == 'short') {
                    updatedComment = `S: ${entrie.quantity}; pri ${entrie.startprice}; fx ${entrie.startfx}; ${myDate.getFullYear()}-${('0'+(myDate.getMonth()+1)).slice(-2)}-${('0'+myDate.getDate()).slice(-2)}`
                }
                entrie['comment'] = `${entrie.assetname}${'\n'}${updatedComment}${'\n\n'}${myAlias} - INITIAL COMMENT:${'\n'}${this.entry['comment']}` // Done directly on the object to post to avoid reactive update of Comment text Area

                const addResponse = await apiCalls.postEntry('/positions/', entrie)
                if (addResponse.data['error'] && addResponse.data['error'] == 'Asset already exists') {
                    console.log('addResponse.data: ', addResponse.data)
                    this.warningMsg = 'This asset is already present in your portfolio. Pls add to the existing position instead.'
                    return this.showWarning = true
                }

                // ///////////////// BELOW NOW HANDLED AT BACKEND //////////////////////////////////////////////
                //TODO: SHOULD ONLY PASS this.Amount, tradingFee and openpos = 0/+1 and let the back end handle (subtract this.Amount*(1+tradingFee) from cash, add this.Amount to userportfolio, add fees, adjust openPos)
                // let participant = {}
                // participant['usercash'] = (Math.round((this.usercash - (this.amount * (1 + store.state.tradingFee))) * 100) / 100) // Trading fee = 1.01
                // participant['userportfolio'] = (Math.round((this.userportfolio + this.amount) * 100) / 100)
                // participant['openpos'] = this.openpos + 1
                // await apiCalls.patchEntry('/participants/' + store.state.myPid + '/', participant)
                // /////////////////////////////////////////////////////////////////////////////////////
                this.openpos += 1

                // const newAssetname = this.entry.assetname.split(' ')[0]
                // const newAssetPartTable = store.state.myIcon.slice(-2) + 'p' //RECENT1605
                // const newObj = {
                //     [newAssetname]: newAssetPartTable
                // }
                // console.log('this.takenassetsObj1: ', this.takenassetsObj)
                // this.takenassetsObj = {
                //     ...this.takenassetsObj,
                //     ...newObj
                // }
                // const takenassetsString = JSON.stringify(this.takenassetsObj)
                // const params = {
                //     'takenassets': takenassetsString
                // }
                // await apiCalls.patchEntry('/clubs/' + store.state.myClub + '/', params)

                return router.push({
                    name: 'itemdata',
                    params: {
                        category: 'portfolio'
                    }
                })

                //BELOW WAS ALREADY COMMENTED OUT!!! 1905
                // } else { // This if/else is a repetition but if taken away creates duplications of several names from outside the if statement
                // this.msg = 'Maximum buying power: ' + this.usercash.toString() + ' ' + store.state.myCur
                // return this.showMsg = true
                // return
                // }

            } catch (error) {
                return console.log('error in AddPosition: ', error)
            }
        },
        async addWatchlist() {
            if (!this.entry.assetname) {
                if (this.showSearch) {
                    this.warningMsg = 'Please first select an asset.'
                } else {
                    this.warningMsg = 'Please first enter a ticker and Get Price.'
                }
                return this.showWarning = true
            }
            if (!this.entry.currency) {
                this.warningMsg = 'Sorry, no currency is indicated for this asset, please select a different one.'
                return this.showWarning = true
            }
            // if (this.assetAlreadyTaken()) {
            //     return this.showWarning = true
            // }
            if (this.openwtc >= store.state.maxWatchassets) { // maxWatchassets
                this.warningMsg = 'You already have 10 assets in your watchlist. Please remove one before adding one more.'
                return this.showWarning = true
            }
            if (!this.entry.startprice || this.entry.startprice <= 0) {
                this.warningMsg = 'Price for this asset is not available. It cannot be added to watchlist'
                return this.showWarning = true
            }
            // if (!this.entry.comment) {   // COMMENT OUT THIS PARAGRAPH TO ENTER WATCHASSETS WITHOUT COMMENT  0917 RESTORE
            //     this.warningMsg = this.commentWarning
            //     document.getElementById('warn-msg').innerHTML = this.warningMsg
            //     this.showWarning = true
            //     document.getElementById('floatingTextarea').focus()
            //     return document.getElementById('floatingTextarea').scrollIntoView({ behavior: 'smooth'})
            // } /////////////////////////////////////////////////////////

            try {
                // await this.getTakenassets()
                // if (this.assetAlreadyTaken()) {
                //     return this.showWarning = true
                // }

                // if (this.entry.assetname) {
                //     this.entry['symbol'] = this.entry.assetname.split(' ')[0]
                // } else {
                //     this.entry['symbol'] = this.tickerName
                // }
                // this.entry.assetname = this.entry['symbol'] + ' - ' + this.entry.exchange + ' - ' + this.entry.shortName

                if (this.entry['currency'] != store.state.myCur) {
                    this.entry['currencysymbol'] = this.entry['currency'] + store.state.myCur + '=X'
                    if (!this.entry['currencysymbol'] || !/[a-zA-Z]{6}=[xX]/.test(this.entry['currencysymbol'])) {
                        this.warningMsg = 'The currency for this asset is not clear. Please try again or choose a different asset.'
                        return this.showWarning = true
                    }
                    if (this.entry['currency'] == 'USD') {
                        this.entry['currencysymbol'] = store.state.myCur + '=X'
                    } else {
                        this.entry['currencysymbol'] = this.entry['currency'] + store.state.myCur + '=X'
                    }
                }
                this.entry.startprice = Math.round(this.entry.startprice * 10000000000) / 10000000000 //RECENT1605
                this.entry['lastprice'] = this.entry.startprice
                this.entry['participant'] = store.state.myPid
                console.log('new watchlist entry', this.entry)
                // this.entry['comment'] = `${this.entry.assetname}:${'\n'}${this.entry['comment']}`
                const entryObj = { // Replaces the above to avoid that the comment text Area updates reactively in front of the user
                    ...this.entry
                }
                let myDate = new Date()
                // let myIcon = store.state.myIcon
                let myAlias = store.state.myAlias
                let initialWtc = `Entered in watchlist on ${myDate.getFullYear()}-${('0'+(myDate.getMonth()+1)).slice(-2)}-${('0'+myDate.getDate()).slice(-2)}`
                entryObj['comment'] = `${this.entry.assetname}${'\n'}${initialWtc}${'\n\n'}${myAlias} - INITIAL COMMENT:${'\n'}${this.entry['comment']}` // Done directly on the object to post to avoid reactive update of Comment text Area
                const addResponse = await apiCalls.postEntry('/watchassets/', entryObj)
                if (addResponse.data['error'] && addResponse.data['error'] == 'Asset already exists') {
                    this.warningMsg = 'This asset is already present in your watchlist.'
                    return this.showWarning = true
                }
                let participant = {}
                participant['openwtc'] = this.openwtc + 1
                this.openwtc += 1
                await apiCalls.patchEntry('/participants/' + store.state.myPid + '/', participant)

                // const newAssetname = this.entry.assetname.split(' ')[0]
                // const newAssetPartTable = store.state.myIcon.slice(-2) + 'w'
                // const newObj = {
                //     [newAssetname]: newAssetPartTable
                // }
                // this.takenassetsObj = {
                //     ...this.takenassetsObj,
                //     ...newObj
                // }
                // const takenassetsString = JSON.stringify(this.takenassetsObj)
                // const params = {
                //     'takenassets': takenassetsString
                // }
                // await apiCalls.patchEntry('/clubs/' + store.state.myClub + '/', params)

                return router.push({
                    name: 'itemdata',
                    params: {
                        category: 'watchlist'
                    }
                })
            } catch (error) {
                return console.log('error in addWatchlist: ', error)
            }
        },
        adjustPrice(longshort) {
            if (this.marketopen == false) { // leaves everything as is, i.e. the entry.startprice defined in getCryptoPrice function
                return
            }
            if (longshort == 'long') {
                this.entry.startprice = this.askprice
                this.pricelabel = 'Ask price (open)'
                // } else if (this.entry.longshort == 'long') {
            } else if (longshort == 'short') {
                this.entry.startprice = this.bidprice
                this.pricelabel = 'Bid price (open)'
            }
        },
        async getParticipant() {
            try {
                let endPoint = '/participants/' + store.state.myPid + '/'
                const Entries = await apiCalls.getEntries(endPoint, {})
                // let participant = Entries.data
                this.usercash = Number(Entries.data['usercash'])
                this.userportfolio = Number(Entries.data['userportfolio'])
                console.log('participant: ', Entries.data)
                this.openpos = Number(Entries.data['openpos'])
                this.openwtc = Number(Entries.data['openwtc'])
                if (this.openpos >= store.state.maxPositions && this.openwtc >= store.state.maxWatchassets) { // maxPositions maxWatchassets
                    this.warningMsg = 'Warning: you already have 20 open trades and 20 assets in your watchlist. Please close or remove one before adding one more.'
                    return this.showWarning = true
                }
                // if (this.openpos > 9) {
                //     this.warningMsg = 'Warning: you already have 10 open trades. Please close one before opening one more.'
                //     return this.showWarning = true
                // }
                // if (this.openwtc > 9) {
                //     this.warningMsg = 'warning: you already have 10 assets in your watchlist. Please remove one before adding one more.'
                //     return this.showWarning = true
                // }
            } catch (error) {
                return console.log('error in getParticipant: ', error)
            }
        },
        validQty() {
            if (this.entryPos.quantity < 0.01 || Math.round(this.entryPos.quantity * 100)/100 != this.entryPos.quantity) {
                this.warningMsg = 'Please enter a number with max 2 decimal digits in the Quantity field.'
                return this.showWarning = true
            }
        },
        // async getTakenassets() {
        //     try {
        //         let endPoint = '/clubs/' + store.state.myClub + '/'
        //         const Entries = await apiCalls.getEntries(endPoint, {})
        //         this.takenassets = Entries.data['takenassets']
        //         console.log('takenassets: ', this.takenassets)
        //         if (this.takenassets) {
        //             console.log('there is a taken asset list')
        //             this.takenassetsObj = JSON.parse(this.takenassets)
        //         } else {
        //             console.log('no taken asset list yet')
        //         }
        //     } catch (error) {
        //         return console.log('error in getTakenassets: ', error)
        //     }
        // },
    },
    async created() {
        await this.getParticipant()
        // await this.getTakenassets()
    }

}
</script>

<style scoped>
.custom-btn-form {
    background-color: #2F5C85 !important;
    color: white !important;
}

/* ******************* BTN 3D ******************* */
.btn3d {
    border: 0;
    transition: all 40ms linear;
}

.btn3d.btn-primar {
    box-shadow: 0 0 0 1px #2f5b85a1 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

.btn3d.btn-primar:hover {
    box-shadow: 0 0 0 1px #4a95db34 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

/* ******************* END BTN 3D ******************* */
.main-card-form {
    background-color: #413e3c;
    padding: 0 7px;
}

.src-btn {
    width: 100%;
    height: 37px;
    font-size: 1rem;
    margin-top: 23px;
    /* margin-left: -7px; */
    padding-left: 0px;
    padding-right: 0px;
}

.get-btn {
    /* width: 70px !important; */
    width: 100%;
    height: 37px;
    font-size: 1rem;
    margin-left: -5px !important;
    /* margin-right: -5px !important; */
    margin-top: 23px;
    padding-left: 1px;
    padding-right: 1px;
}

.wtc-btn {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 23px;
    width: 100%;
    height: 37px;
    font-size: 1rem;
    padding-left: 0px;
    padding-right: 0px;
}

.pos-btn {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 23px;
    width: 100%;
    height: 37px;
    font-size: 1rem;
    padding-left: 0px;
    padding-right: 0px;
}

.comment-box {
    height: 200px !important;
    /* RECENT was 240 */
    background-color: #dbd7d4;
    color: #000;
    margin-top: -24px;
    padding-top: 10px !important;
    margin-bottom: 25px !important;
    font-size: 1rem;
}

.sep-line {
    background-color: #272522 !important;
    padding-top: 6px !important;
    margin-top: 18px;
    margin-bottom: -15px;
    width: 100%;
    margin-left: 0px;
    /* margin-right: 20px; */
    padding-left: 0px;
    padding-right: 0px;

}

.comment-missing {
    /* font-weight: bold; */   
    margin-bottom: -5px;
    /* color: #e6dddc; */
    color: #faca64e5;
    padding-right: 3px;
}

.close-butt {
    height: 25px !important;
    width: 10px !important;
    text-align: start !important;
    color: white;
    background-color: #b33430;
    margin-top: 5px;
}
.btn-tex {
    position: relative;
    top: -8px;
    left: -4px;
}

#characMsg {
    color: #d2d2d9;
    margin-bottom: -20px;
}

.search-toggle {
    margin-top: 10px;
    margin-bottom: -8px;
    color: #b2b2b9;
}
.link-asset {
    margin-top: 10px;
    margin-bottom: -8px;
    color: #faca64e5;
    /* color: #b2b2b9; */
    font-weight: bold;
    text-decoration: underline;
}

.search-warn {
    margin-top: 7px;
    /* color: #faca64; */
    color: #faca64e5;
}
</style>
