<template>
<!-- <div class="container main-cont-itemdata"> -->
<div class="container my-5" v-show="!this.isLoading">
    <div v-if="this.category == 'portfolio'">
        <TablePortfolio :category="category" />
    </div>
    <div v-else-if="this.category == 'watchlist'">
        <TableWatchlist :category="category" />
    </div>
    <div v-else-if="this.category == 'history'">
        <TableHistory :category="category" />
    </div>

    <div v-else-if="this.category == 'clubs'">
        <TableMyClubs :category="category" />
    </div>
    <div v-else-if="this.category == 'searchclubs'">
        <TableClubs :category="category" />
    </div>
    <div v-else-if="this.category == 'topclubs'">
        <TableClubs :category="category" />
    </div>

    <!-- better enter the below separated, else it does not refresh the compnent when switching from one to the other! -->
    <div v-else-if="this.category == 'searchinvestors'">
        <TableInvestors :category="category" />
    </div>
    <div v-else-if="this.category == 'topinvestors'">
        <TableInvestors :category="category" />
    </div>
    <div v-else-if="this.category == 'topfollowed'">
        <TableInvestors :category="category" />
    </div>
    <div v-else-if="this.category == 'followed'">
        <TableInvestors :category="category" />
    </div>

    <div v-else-if="this.category == 'searchinvestments'">
        <TableInvestments :category="category" />
    </div>
    <div v-else-if="this.category == 'topinvestments'">
        <TableInvestments :category="category" />
    </div>
    <div v-else-if="this.category == 'notifications'">
        <TableNotifications :category="category" />
    </div>
    <div v-else-if="this.category == 'messages'">
        <TableMessages :category="category" />
    </div>
    <div v-else-if="this.category == 'conversation'">
        <TableConversation :category="category" />
    </div>

</div>
</template>

<script>
import TablePortfolio from '@/components/TablePortfolio.vue'
import TableWatchlist from '@/components/TableWatchlist.vue'
import TableHistory from '@/components/TableHistory.vue'
import TableMyClubs from '@/components/TableMyClubs.vue'
import TableClubs from '@/components/TableClubs.vue'
import TableInvestors from '@/components/TableInvestors.vue'
import TableInvestments from '@/components/TableInvestments.vue'
import TableNotifications from '@/components/TableNotifications.vue'
import TableMessages from '@/components/TableMessages.vue'
import TableConversation from '@/components/TableConversation.vue'
import apiCalls from '@/services/apiCalls.js'
import router from '@/router'
import store from '@/store'
import {
    mapActions,
} from 'vuex'
export default {
    components: {
        TablePortfolio,
        TableWatchlist,
        TableHistory,
        TableMyClubs,
        TableClubs,
        TableInvestors,
        TableInvestments,
        TableNotifications,
        TableMessages,
        TableConversation,
    },
    props: [
        'category',
    ],
    computed: {
        isLoading() {
            return store.state.isLoading
        },
    },
    methods: {
        ...mapActions(['setParticId']),

        validUsername(possibleUsername) {
            const regex1 = /^[a-zA-Z0-9_-]+$/ // only letters numbers, underscore - minimum 1 character (otherwise * instead of +)
            const regex2 = /^[a-zA-Z]/ // must start with a letter
            if (!possibleUsername || possibleUsername.length < 1) {
                return false
            } else if (possibleUsername.length < store.state.minUsername) {
                return false
            } else if (possibleUsername.length > store.state.maxUsername) {
                return false
            } else if (!regex1.test(possibleUsername)) {
                return false
            } else if (!regex2.test(possibleUsername)) {
                return false
            }
            return true
        },
    },
    async created() {
        try {
            let categoryList = ['portfolio', 'watchlist', 'history', 'clubs', 'searchclubs', 'topclubs', 'searchinvestors', 'topinvestors',
                'topfollowed', 'followed', 'searchinvestments', 'topinvestments', 'notifications', 'messages', 'conversation'
            ]
            if (categoryList.indexOf(this.category) == -1) {

                let possibleParticipant = this.category
                console.log('this.category in DisplayItemData created: ', this.category)
                if (this.validUsername(possibleParticipant)) {
                    console.log('In DisplayItemData created - valid username: ', possibleParticipant)
                    const participantExists = await apiCalls.getEntries('/participants/', {
                        'useralias': possibleParticipant
                    })
                    if (participantExists && participantExists.data && participantExists.data.length > 0) {
                        console.log('participantExists: ', participantExists)
                        let investor2 = participantExists.data[0].id
                        if (investor2 != localStorage.getItem('paid') || !localStorage.getItem('paid')) {
                            localStorage.setItem('paid', investor2)
                            await this.setParticId(investor2)
                            router.push('/' + investor2)
                        }
                        console.log('refreshing investor page: ', investor2)
                    } else {
                        console.log('participant does not exist')
                        return router.push('not-found')
                    }
                } else {

                    console.log('in displayItemData: ', this.category)
                    return router.push('not-found')
                }
            }
        } catch (e) {
            console.log(e)
        }
    }
}
</script>

<style>
/* .main-cont-itemdata {
    margin-bottom: 50px;
} */
</style>
