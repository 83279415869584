<template>
<div>
  <input
    type="checkbox"
    role="switch"
    :checked="modelValue"
    @change="$emit('update:modelValue', $event.target.checked)"
    class="form-check-input shadow rounded"
    />
    <label v-if="label" class="form-check-label ms-0 mb-0">{{ label }}</label>
</div>
</template>

<script>
// role="switch"
export default {
    props: {
        label: {
            type: String,
            default: ''
        },
        modelValue: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>
.form-check-input {
    background-color: #e2e2e9;
}

.form-check-input:checked {
    background-color: #2F5C85 !important;
}
</style>
