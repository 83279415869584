import axios from 'axios'
import store from '@/store'
import router from '@/router'

const serveUrl = store.state.serverUrl
const apiClient = axios.create({
    baseURL: serveUrl,
    timeout:5000,
    withCredentials: false,
    headers: {
        'Accept': 'application/json',  // This is what you accept back from the server. At times 'Accept' it is not in quotes, but seems to works either way....
        'Content-Type': 'application/json',  // This is what you send to the server
    }
})
// const apiClient2 = axios.create({
//     baseURL: serveUrl,
//     timeout:25000,
//     withCredentials: false,
//     headers: {
//         'Accept': 'application/json',  // This is what you accept back from the server. At times 'Accept' it is not in quotes, but seems to works either way....
//         'Content-Type': 'application/json',  // This is what you send to the server
//     }
// })

apiClient.interceptors.request.use(
    async function(config) {
        // console.log('RRRRRRRRRRRRRRRRRRapiCalls spinner start')
        await store.dispatch('setIsLoading', true)
        return config
    }
)

apiClient.interceptors.response.use(
    async function (response) {    // Any status code that lie within the range of 2xx cause this function to trigger. Do something with response data (here just returning the response unchanged)
        // console.log('RRRRRRRRRRRRRRRRRRapiCallsResponse spinner stop')
        await store.dispatch('setIsLoading', false)
        return response
    }, 
    async function (error) {    // Any status codes that falls outside the range of 2xx cause this function to trigger    // Do something with response error
        try {
            // console.log('RRRRRRRRRRRRRRRRRRapiCallsError spinner stop')
            await store.dispatch('setIsLoading', false)
            console.log('errorAPC1: ', error.response)
            console.log('errorAPC1: ', error)
            // if (store.state.page == '/clubsadmin' && error.response && error.response.data.username && error.response.data.username[0].indexOf('must be unique') >= 0) {
            //     // Seems unnecessary in clubsadmin - no username is chosen there. Changed to club names instead!
            //     console.log('USERNAME ALREADY EXISTS!!!')
            //     await store.dispatch('messages/setShowLocalMsg', 'This username already exists.')
            //     return setTimeout(() => {
            //         store.dispatch('messages/setShowLocalMsg', '')
            //     }, 3000)
            // }
            if (store.state.page == '/clubsadmin' && error.response && error.response.data && error.response.data.error && error.response.data.error.indexOf('must be unique') >= 0) {
                console.log('CLUBNAME ALREADY EXISTS!!!: ', JSON. parse(error.response.config.data)['clubname'])
                let clubName = JSON. parse(error.response.config.data)['clubname']
                return await store.dispatch('setAlertMsg', `Club "${clubName}" already exists.${'\n\n'}Please choose a different name and try again.`)
            }
                // TODO: Then when he clicks the link to accept the invitation check if he has already too many memberships and tell him to leave another club before trying again
                // get the participant id for the existing user
            if (store.state.page == '/clubsadmin' && error.response && error.response.data && error.response.data.error && error.response.data.error.indexOf('Email already in use. Membership request created') >= 0) {
                console.log('EMAIL ALREADY EXISTS - MEMBERSHIP CREATED!!!')
                // Use this to handle when user chooses username which already exists (is it really needed????)
                // console.log('error: ', JSON.parse(error.response.config.data)['username'])
                let address = JSON.parse(error.response.config.data)['email']
                return await store.dispatch('setAlertMsg', `If ${address} is not yet a user, your invitation has been sent to that address.${'\n\n'}Otherwise your invitation has been notified to the corresponding user.`)
                // await store.dispatch('messages/setShowLocalMsg', `If the address ${address} is already in use, the user has been notified your invitation. Otherwise an invitation has been sent to that email address.`)
                // return
                // return setTimeout(() => {
                //     store.dispatch('messages/setShowLocalMsg', '')
                // }, 3000)            
            }
            if (store.state.page == '/create' && error.response && error.response.data && error.response.data.email && error.response.data.email[0].indexOf('must be unique') >= 0) {
                console.log('EMAIL ALREADY EXISTS!!!')
                // Use this to handle when user chooses username which already exists (is it really needed????)
                await store.dispatch('setAlertMsg', `This email address may be already in use. Please try to login with the username and password corresponding to this email address.${'\n\n'}If you do not remember username or password (or both) please ask to reset them and you will receive a reset link to your email address.`)
                // await store.dispatch('messages/setShowLocalMsg', 'Email address already in use. Please login with the username and password corresponding to your email address. If you do not remember username or password (or both) please ask to reset them and you will receive a reset link to your email address.')
                // return router.push('/login')
            }
            if (store.state.page == '/create' && error.response && error.response.data && error.response.data.password && error.response.data.password[0].indexOf('too common') >= 0) {
                console.log('This password is too common!!!')
                await store.dispatch('setRfrsh', true)
                return await store.dispatch('setAlertMsg', `The password you have chosen is too common. We recommend to change it for a stronger one in your Profile page.`)
            } else if (store.state.page == '/create' && error.response && error.response.data && error.response.data.password) {
                await store.dispatch('setRfrsh', true)
                return await store.dispatch('setAlertMsg', `${error.response.data.password[0]}.${'\n\n'}Please change your password.`)
            }
            if (store.state.page == '/profile' && error.response && error.response.data && error.response.data.password && error.response.data.password[0].indexOf('too common') >= 0) {
                console.log('This password is too common!!!')
                return await store.dispatch('setAlertMsg', `Sorry, the password you have chosen is too common. Please try again with a different password.`)
            } else if (store.state.page == '/profile' && error.response && error.response.data && error.response.data.password) {
                return await store.dispatch('setAlertMsg', `${error.response.data.password[0]}.${'\n\n'}Please try again.`)
            }
            if (store.state.page == '/profile' && error.response && error.response.data && error.response.data.username && error.response.data.username[0].indexOf('must be unique') >= 0) {
                console.log('USERNAME ALREADY EXISTS!!!')
                await store.dispatch('setRfrsh', true)
                return await store.dispatch('setAlertMsg', `This username is already in use.${'\n\n'}Please enter a different username and try again.`)
            }
            if (store.state.page == '/profile' && error.response && error.response.data && error.response.data.email && error.response.data.email[0].indexOf('must be unique') >= 0) {
                console.log('EMAIL ALREADY EXISTS!!!')
                // Use this to handle when user chooses username which already exists (is it really needed????)
                // await store.dispatch('setRfrsh', '/profile')
                await store.dispatch('setRfrsh', true)
                return await store.dispatch('setAlertMsg', `We are experiencing an error. Please make sure that this email is not already in use.${'\n\n'}If this email belongs to you and you do not remember username or password (or both) please logout and then from the Login page ask for a reset.`)
                // await store.dispatch('messages/setShowLocalMsg', 'Email address already in use. Please login with the username and password corresponding to your email address. If you do not remember username or password (or both) please ask to reset them and you will receive a reset link to your email address.')
                // return router.push('/profile')
            }
            if(error.response && error.response.status && error.response.status == 404) {  // If the seeked element doesn't exist, then load 404
                return router.push({
                    name: 'not-found',
                    params: { resource: 'Not found' }  // If a specific resource is not found I can pass it as parameter (e.g. "Participant not found.")
                })
            }
            // if (store.state.page == '/profile' && error.response && error.response.data.old_password && error.response.data.old_password[0].indexOf('not correct') >= 0) {
            if (store.state.page == '/profile' && error.response && error.response.data && error.response.data.old_password) {
                console.log('OLD PASSWORD NOT CORRECT-1!!!')
                await store.dispatch('setRfrsh', true) 
                return await store.dispatch('setAlertMsg', `The old password you have provided is not correct.${'\n\n'}If you don't remember your password please logout and ask to reset it following the link on the Login page.`)
                // await store.dispatch('messages/setShowLocalMsg', 'Email address already in use. Please login with the username and password corresponding to your email address. If you do not remember username or password (or both) please ask to reset them and you will receive a reset link to your email address.')
                // return router.go(0)
            }

            if (error.response && error.response.data && error.response.data && error.response.data.detail && error.response.data.detail.indexOf('token not valid') >= 0) {        
            
                console.log('Token not valid - will try to refresh token)')

                const originalRequest = error.config //error.config has all the elements of the original request, it basically is the original request
                if (originalRequest.url !== '/api/token/' && error.response) { // Access Token was expired
                    // if (error.response.status === 401 && !originalRequest._trying) {           
                    if (error.response && error.response.status === 401 && !store.state.authentications.tryingRefresh) { 
                        // originalRequest._trying = true

                        await store.dispatch('authentications/setTryingRefresh', true)
                        console.log('tryingRefresh set to "true"')
                        console.log('rtk1: ', localStorage.getItem('rtk'))
                        let refreshToken = localStorage.getItem('rtk')
                        console.log('rtk2: ', refreshToken)
                        if (!refreshToken) {
                            console.log('REFRESH TOKEN NOT CAUGHT - TRYING WITH STORE')
                            refreshToken = store.state.rtk
                            console.log('rtk3: ', refreshToken)
                        }
                        const response = await apiClient.post('/api/token/refresh/', {
                            refresh: refreshToken,
                        })
                        console.log(response)
                        const accessToken = response.data.access
                        const new_refreshToken = response.data.refresh
                        console.log('rtk4: ', new_refreshToken)
                        // console.log('response refresh: ', response.data.refresh)
                        localStorage.setItem('atk', accessToken)
                        localStorage.setItem('rtk', new_refreshToken)
                        await store.dispatch('setAtk', accessToken)
                        await store.dispatch('setRtk', new_refreshToken)
                        if (refreshToken != new_refreshToken) {
                            console.log('old and new refresh are different')
                        } else {
                            console.log('old and new refresh are the same')
                        }
                        console.log('New accessToken received and installed')
                        // await this.$store.dispatch('authentications/setTryingRefresh', false)
                        // originalRequest._trying = false
                        const resendOriginalRequest = new Promise(resolve => {
                            originalRequest.headers['Authorization'] = `Bearer ${ accessToken }`
                            resolve (axios(originalRequest))
                        })
                        await store.dispatch('authentications/setTryingRefresh', false)    
                        console.log('tryingRefresh set to "false". Now resending original request which gave error: ', originalRequest)                
                        return await resendOriginalRequest
                    }
                }
            }
            if (error.code == 'ERR_NETWORK') {
                console.log('Network error caught')
                return router.push('/network-error')
            }
            return router.push('/network-error')  // If nothing else, assume it's a network error
        } catch (error2) { // Below is handling the same errors at the top, in case that they happen when re-submitting after a token refresh!!!
            if (store.state.page == '/clubsadmin' && error2.response && error2.response.data && error2.response.data.error && error2.response.data.error.indexOf('must be unique') >= 0) {
                console.log('CLUBNAME ALREADY EXISTS!!!: ', JSON. parse(error2.response.config.data)['clubname'])
                let clubName = JSON. parse(error2.response.config.data)['clubname']
                return await store.dispatch('setAlertMsg', `Club name "${clubName}" already exists. Please choose a different one.`)
            }
                // TODO: Then when he clicks the link to accept the invitation check if he has already too many memberships and tell him to leave another club before trying again
                // get the participant id for the existing user
            if (store.state.page == '/clubsadmin' && error2.response && error2.response.data && error2.response.data.error && error2.response.data.error.indexOf('Email already in use. Membership request created') >= 0) {
                console.log('EMAIL ALREADY EXISTS - MEMBERSHIP CREATED!!!')
                // Use this to handle when user chooses username which already exists (is it really needed????)
                // console.log('error: ', JSON.parse(error.response.config.data)['username'])
                let address = JSON.parse(error2.response.config.data)['email']
                return await store.dispatch('setAlertMsg', `If the address ${address} is already in use, the user has been notified your invitation.${'\n\n'}Otherwise an invitation has been sent to that email address.`)
                // await store.dispatch('messages/setShowLocalMsg', `If the address ${address} is already in use, the user has been notified your invitation. Otherwise an invitation has been sent to that email address.`)
                // return
                // return setTimeout(() => {
                //     store.dispatch('messages/setShowLocalMsg', '')
                // }, 3000)            
            }
            if (store.state.page == '/create' && error2.response && error2.response.data && error2.response.data.email && error2.response.data.email[0].indexOf('must be unique') >= 0) {
                console.log('EMAIL ALREADY EXISTS!!!')
                // Use this to handle when user chooses username which already exists (is it really needed????)
                await store.dispatch('setAlertMsg', `This email address may be already in use. Please tra to login with the username and password corresponding to this email address.${'\n\n'}If you do not remember username or password (or both) please ask to reset them and you will receive a reset link to your email address.`)
                // await store.dispatch('messages/setShowLocalMsg', 'Email address already in use. Please login with the username and password corresponding to your email address. If you do not remember username or password (or both) please ask to reset them and you will receive a reset link to your email address.')
                await store.dispatch('setRfrsh', '/login') //New 221231
                return router.push('/login')
            }
            if (store.state.page == '/create' && error2.response && error2.response.data && error2.response.data.password && error2.response.data.password[0].indexOf('too common') >= 0) {
                console.log('This password is too common!!!')
                return await store.dispatch('setAlertMsg', `The password you have chosen is too common. We recommend to change it for a stronger one in your Profile page.`)
            } else if (store.state.page == '/create' && error2.response && error2.response.data.password) {
                return await store.dispatch('setAlertMsg', `${error2.response.data.password[0]}.${'\n\n'}Please change your password.`)
            }
            if (store.state.page == '/profile' && error2.response && error2.response.data && error2.response.data.password && error2.response.data.password[0].indexOf('too common') >= 0) {
                console.log('This password is too common!!!')
                return await store.dispatch('setAlertMsg', `Sorry, the password you have chosen is too common.${'\n\n'}Please try again with a different password.`)
            } else if (store.state.page == '/profile' && error2.response && error2.response.data.password) {
                return await store.dispatch('setAlertMsg', `${error2.response.data.password[0]}.${'\n\n'}Please try again.`)
            }
            if(error2.response && error2.response.status && error2.response.status == 404) {  // If the seeked element doesn't exist, then load 404
                return router.push({
                    name: 'not-found',
                    params: { resource: 'Not found' }  // If a specific resource is not found I can pass it as parameter (e.g. "Participant not found.")
                })
            }
            if (store.state.page == '/profile' && error2.response && error2.response.data && error2.response.data.username && error2.response.data.username[0].indexOf('must be unique') >= 0) {
                console.log('USERNAME ALREADY EXISTS2!!!')
                await store.dispatch('setRfrsh', true)
                return await store.dispatch('setAlertMsg', `This username is already in use.${'\n\n'}Please enter a different username and try again.`)
            }
            if (store.state.page == '/profile' && error2.response && error2.response.data && error2.response.data.email && error2.response.data.email[0].indexOf('must be unique') >= 0) {
                console.log('EMAIL ALREADY EXISTS2!!!')
                // Use this to handle when user chooses username which already exists (is it really needed????)
                // await store.dispatch('setRfrsh', '/profile')
                await store.dispatch('setRfrsh', true)
                return await store.dispatch('setAlertMsg', `We are experiencing an error. Please make sure that this email is not already in use.${'\n\n'}If this email belongs to you and you do not remember username or password (or both) please logout and then from the Login page ask for a reset.`)
                // await store.dispatch('messages/setShowLocalMsg', 'Email address already in use. Please login with the username and password corresponding to your email address. If you do not remember username or password (or both) please ask to reset them and you will receive a reset link to your email address.')
                // return router.push('/profile')
            }
            // if (store.state.page == '/profile' && error.response && error.response.data.old_password && error.response.data.old_password[0].indexOf('not correct') >= 0) {
            if (store.state.page == '/profile' && error2.response && error2.response.data && error2.response.data.old_password) {
                console.log('OLD PASSWORD NOT CORRECT-2!!!')
                await store.dispatch('setRfrsh', true) 
                return await store.dispatch('setAlertMsg', `The old password you have provided is not correct.${'\n\n'}If you don't remember your password please logout and then from the Login page ask for a reset.`)
                // await store.dispatch('messages/setShowLocalMsg', 'Email address already in use. Please login with the username and password corresponding to your email address. If you do not remember username or password (or both) please ask to reset them and you will receive a reset link to your email address.')
                // return router.go(0)
            }

            if (store.state.alertMessage == 1) {
                alert(error2)
            } else {
                console.log('errorAPC2: ', error2)
            }
            await store.dispatch('setSessionExpired', true)
            await store.dispatch('setRfrsh', '/login') //New 221231
            return router.push('/login')
            // return Promise.reject(_error) // Something else had gone wrong
        }
    }
)
export default {
    getEntries(endpoint, params) {
        apiClient.defaults.headers.common['Authorization'] = `Bearer ${ localStorage.getItem('atk') }`        
        return apiClient.get(endpoint, { // The apiCalls need to be returned!
            params: params,
        })
    },
    postEntry(endpoint, entry) {
        apiClient.defaults.headers.common['Authorization'] = `Bearer ${ localStorage.getItem('atk') }` 
        return apiClient.post(endpoint, entry)
    },
    // postImage(endpoint, entry) {
    //     apiClient.defaults.headers.common['Authorization'] = `Bearer ${ localStorage.getItem('atk') }`
    //     apiClient.defaults.headers['Content-Type'] = `multipart/form-data`
    //     return apiClient.post(endpoint, entry)
    // },
    deleteEntry(endpoint, entryId) {
        // let baseURL = store.state.serverUrl
        let baseURL = store.state.serverUrl  
        // let baseURL = 'https://marqsin.pythonanywhere.com'
        let endPoint = baseURL + endpoint
        return axios.delete(endPoint, {
            headers: {
                'Authorization': `Bearer ${ localStorage.getItem('atk') }`
            },
            data: {
                id: entryId
            }
        })
    },
    // deleteImage(imageName) {
    //     // let baseURL = store.state.serverUrl
    //     let baseURL = store.state.serverUrl  
    //     // let baseURL = 'https://marqsin.pythonanywhere.com'
    //     let endPoint = baseURL + '/delimage/'
    //     return axios.delete(endPoint, {
    //         headers: {
    //             'Authorization': `Bearer ${ localStorage.getItem('atk') }`
    //         },
    //         data: {
    //             imagename: imageName,
    //             participant: store.state.myPid
    //         }
    //     })
    // },
    patchEntry(endpoint, params) {
        apiClient.defaults.headers.common['Authorization'] = `Bearer ${ localStorage.getItem('atk') }`        
        return apiClient.patch(endpoint, params)
    },
    getYhFinancials(endpoint) {
        apiClient.defaults.headers.common['Authorization'] = `Bearer ${ localStorage.getItem('atk') }`        
        return apiClient.get(endpoint)
    },
    // getYhQuotes(endpoint) {
    //     apiClient.defaults.headers.common['Authorization'] = `Bearer ${ localStorage.getItem('atk') }`        
    //     return apiClient.get(endpoint)
    // },
    // getYhSearch(endpoint) {
    //     apiClient.defaults.headers.common['Authorization'] = `Bearer ${ localStorage.getItem('atk') }`        
    //     return apiClient.get(endpoint)
    // },
    // getYhStats(endpoint) {
    //     apiClient2.defaults.headers.common['Authorization'] = `Bearer ${ localStorage.getItem('atk') }`        
    //     return apiClient2.get(endpoint)
    // }
}

// *************************  BELOW ARE THE API CALLS WITH BASIC AUTHENTICATION - NO JWT ********************************************
    // getEntries(endpoint, params, user) {  // With Basic Authentication you always had user as third parameter, this same structure in all Api calls, including post
    //     // console.log('params getEntries: ', params)
    //     return apiClient.get(endpoint, {
    //         params: params,
    //         auth: {
    //             username: user.username,
    //             password: user.password
    //         },
    //     })
    // },
    // getEntry(endpoint, id){  // Example of passing ID parameter to the API through the url full path
    //     apiClient.defaults.headers.common['Authorization'] = `Bearer ${ localStorage.getItem('atk') }` 
    //     return apiClient.get(endpoint + id + '/')
    // },
// ***************************************************************************************************************************









