export default {
    namespaced: true,
    state: {
        loggedIn: false,
        incorrectAuth: false,
        tryingRefresh: false,
        // isAdminUser: false,
        // userId: null,
    },
    mutations: {
        SET_INCORRECT_AUTH (state, bool) {
            state.incorrectAuth = bool
        },
        SET_LOGGEDIN (state, bool) {
            state.loggedIn = bool
        }, 
        SET_TRYING_REFRESH (state, bool) {
            state.tryingRefresh = bool
        },
        // SET_ADMIN_USER (state, bool) {
        //     state.isAdminUser = bool
        // },
        // SET_USER_ID (state, userId) {
        //     state.userId = userId
        // },
    },
    actions: {
        setLoggedIn({ commit }, bool) {
            commit('SET_LOGGEDIN', bool)
        },
        setIncorrectAuth({ commit }, bool) {
            commit('SET_INCORRECT_AUTH', bool)
        },
        setTryingRefresh({ commit }, bool) {
            commit('SET_TRYING_REFRESH', bool)
        },
        // setAdminUser({ commit }, bool) {
        //     commit('SET_ADMIN_USER', bool)
        // },
        // setUserId({ commit }, userId) {
        //     commit('SET_USER_ID', userId)
        // },
    }
}
