<template>
<div>
<label v-if="label" class="form-label ms-1 mb-0">{{ label }}</label>
  <input
    class="form-control shadow bg-body rounded bg-medium"
    v-bind="$attrs"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
    @drop.prevent="false" 
    @paste.prevent="false"
  >
    <!-- :placeholder="label"    #add it inside in case of need-->
  <p
  v-if="error"
  class="errorMessage"
  >
  {{ error }}
  </p>
<div v-if="(label==='Quantity' || label==='Price') && (modelValue < 0 || modelValue > 1000000000)"><p class="warn">Quantity must be between 0 and 1,000,000,000</p></div>
<div v-else-if="label==='Quantity' && ((modelValue * 1000) != Math.floor(modelValue * 1000))"><p class="warn">Quantity must have maximum 3 decimals</p></div>
<div v-else-if="label==='Price' && ((modelValue * 100) != Math.floor(modelValue * 100))"><p class="warn">Price must have maximum 2 decimals</p></div>
</div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    error: {
      type: String,
      default: ''
    }
  },
  // emits: ['update:modelValue']
}
</script>
<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.bg-medium {
    background-color: #d4d4d4 !important;
    border: none;
}
/* .bg-medium:focus {
    background-color: #2F5C85 !important;
    border: solid 2px !important;
    border-color: #12db00a6 !important;
} */

/* Firefox */

/* input[type=number] {  ATTENTION: commented out because suddenly was giving error!!!!!
  -moz-appearance: textfield;
} */
input[type="text"]:disabled{
  background-color: #9a9a9a !important;
  border: none;
}
input[type="number"]:disabled{
  background-color: #9a9a9a !important;
  border: none;
}

.warn {
    /* color: red; */
    color: #faca64e5;
    font-size: 14px;
    margin-left: 12px;
    padding: 0px;
    width: 355px;
}
</style>