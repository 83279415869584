<template>
<div>
  <input
      type="radio"
      :checked="modelValue === value"
      :value="value"
      v-bind="$attrs"
      @change="$emit('update:modelValue', value)"
    />
  <label v-if="label">{{ label }}</label>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    value: {
      type: [String, Number],
      required: true
    }
  }
}
</script>
<style>
/* .radio[disabled] {
    background-color: #413e3c !important;
} */
input[type="radio"]:disabled {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #413e3c !important;
}


</style>