<template>
<!-- <img id="myImg" src="@/assets/images/I-11.png" @click="enlargePic()" alt="Snow" style="width:100%;max-width:100px"> -->
<!-- The Modal -->
<!-- <div id="myModal" :class="this.showPic ? 'modal' : 'modal-none'" v-show="this.showPic" > -->
<div id="myModal" class="modal" v-show="this.showPic">

    <!-- <a v-if="this.width>1007" id="caption" href="" role="button" @click.prevent="closeModal()">x</a> -->

    <img v-if="this.showPic" class="modal-content" :src="this.picUrl" id="img01">

    <!-- <span class="close" @click.prevent="closeMod()" id="caption">&times;</span> -->
    <!-- <a id="caption" href="" @click.prevent="closeMod()">&times;</a> -->
    <!-- <a v-if="this.width>1007" id="caption" href="" role="button" @click.prevent="closeModal()">x</a> -->
    <!-- <button type="button" id="caption" class="btn btn-tex" @click.prevent="closeMod()">x</button> -->

    <!-- <span><button type="button" id="caption" class="btn close-butt" @click.prevent="closeMod()">
                    <div class="btn-tex">
                        x
                    </div>
                </button></span> -->

    <!-- <div id="caption"></div> -->
</div>
</template>

<script>
// import store from '@/store'
export default {
    name: 'BaseModalPicture',
    props: [
        'picUrl',
        'topPos',
        'leftPos',
        'element',
    ],
    data() {
        return {
            showPic: false,
            width: null,
            height: null,
        }
    },
    methods: {
        closeModal() {
            // alert('clicked')
            console.log('clicked')
            this.$emit('clicked', true) // just passing any value. This function could also receive the event if needed
            // this.showPic = false
        },
        onTouch() {
            if (this.showPic) {
                console.log('triggered touch')
                this.closeModal()
                // this.showPic = false
            }
        },
        onPopstate() {
            if (this.showPic) {
                console.log('pressed back')
                this.closeModal()
                // this.showPic = false
            }
        }
        // enlargePic() {
        //                 // Get the modal
        //     var modal = document.getElementById("myModal");
        //     // Get the image and insert it inside the modal - use its "alt" text as a caption
        //     // var img = document.getElementById("myImg");
        //     var modalImg = document.getElementById("img01");
        //     // var captionText = document.getElementById("caption");

        //     modal.style.display = "block";
        //     modalImg.src = this.picSrc;
        //     // captionText.innerHTML = this.alt;

        //     // Get the <span> element that closes the modal
        //     var span = document.getElementsByClassName("close")[0];

        //     // When the user clicks on <span> (x), close the modal
        //     span.onclick = function() { 
        //     modal.style.display = "none";
        //     }
        // }
    },
    // created() {
    // this.picSrc = this.picUrl
    // this.showPic = true
    // alert('creating modal')
    // var modal = document.getElementById("myModal");
    // Get the image and insert it inside the modal - use its "alt" text as a caption
    // var img = document.getElementById("myImg");
    // var modalImg = document.getElementById("img01");
    // var captionText = document.getElementById("caption");

    // modal.style.display = "block";
    // modalImg.src = this.picSrc;
    // captionText.innerHTML = this.alt;

    // Get the <span> element that closes the modal
    // var span = document.getElementsByClassName("close")[0];

    // When the user clicks on <span> (x), close the modal
    // span.onclick = function() { 
    //     modal.style.display = "none";
    // }
    // },
    created() {
        this.showPic = true
    },
    mounted() {
        document.addEventListener('touchstart', event => {
            let touchstartX = event.changedTouches[0].clientX
            let touchstartY = event.changedTouches[0].clientY
            let horizontalDifference = touchstartX - this.leftPos
            let verticalDifference = 0
            // if (store.state.page == '/investor' || store.state.page == '/portfolio' || store.state.page == '/watchlist') {
            if (this.element == 'bar') {
                verticalDifference = touchstartY - (this.topPos - 50)
                horizontalDifference = touchstartX - (this.leftPos + 20)
            } else {
                verticalDifference = touchstartY - (this.topPos + 20)
            }
            // alert(this.topPos)
            // alert(touchstartY)
            // alert(verticalDifference)
            if (Math.abs(horizontalDifference) < 35 && Math.abs(verticalDifference) < 35) {
                console.log(event)
            } else {
                console.log(event)
                this.onTouch()
            }
        }, {
            once: true
        })
        window.addEventListener('popstate', this.onPopstate)

        document.addEventListener('keydown', event => {
            if (event.key == 'Escape' && this.showPic) {
                console.log('pressed esc')
                this.closeModal()
                // this.showPic = false
            }
        }, {
            once: true
        })

        this.width = window.innerWidth
        this.height = window.innerHeight
        let topPos = `${Math.round((this.topPos - 15) / this.height * 100)}%`
        let leftPos = `${Math.round((this.leftPos + 60) / this.width * 100)}%`
        document.getElementById('myModal').style.top = topPos
        document.getElementById('myModal').style.left = leftPos
    },
    unmounted() {
        // alert('unmounting')
        document.removeEventListener('touchstart', this.onTouch)
        window.removeEventListener('popstate', this.onPopstate)
        // window.removeEventListener('keydown', this.handler)
    }

}
</script>

<style scoped>
/* body {font-family: Arial, Helvetica, sans-serif;} */

#myImg {
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
}

#myImg:hover {
    opacity: 0.7;
}

/* The Modal (background) */
.modal {
    display: block;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 30;
    /* Sit on top */
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 5px;
    /* margin-left: 30%; */
    /* margin-right: 40% */
    /* left: auto; */
    /* top: 25%; */
    /* left: 25%; */
    width: 120px;
    /* Full width */
    height: 120px;
    /* Full height */
    /* overflow: auto;  */
    /* Enable scroll if needed */
    overflow: hidden;
    /* background-color: rgb(0,0,0);  */
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0);
    /* Black w/ opacity Modal transparent*/
}

/* Modal Content (image) */
.modal-content {
    margin: auto;
    display: block;
    width: 100%;
    max-width: 700px;
}

#caption {
    position: absolute;
    text-decoration: none;
    top: -6px;
    right: 5px;
    color: #000;
    font-size: 20px;
    font-weight: bold;
    transition: 0.3s;
    z-index: 20;
}

#caption:hover,
#caption:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

/* Caption of Modal Image */
/* #caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
} */

/* Add Animation */
.modal-content {
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.4s;
    animation-name: zoom;
    animation-duration: 0.4s;
}

#caption {
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 1.6s;
    animation-name: zoom;
    animation-duration: 1.6s;
}

@-webkit-keyframes zoom {
    from {
        -webkit-transform: scale(0)
    }

    to {
        -webkit-transform: scale(1)
    }
}

@keyframes zoom {
    from {
        transform: scale(0)
    }

    to {
        transform: scale(1)
    }
}

/* The Close Button */
/* .close {
  position: absolute;
  top: -6px;
  right: 5px;
  color: #000;
  font-size: 20px;
  font-weight: bold;
  transition: 0.3s;
  z-index: 20;
} */

/* .close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
} */

/* .close {  
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
} */

/* 100% Image Width on Smaller Screens */
/* @media only screen and (max-width: 700px){
  .modal-content {
    width: 100%;
  }
} */

/* .close-butt {
    height: 25px !important;
    width: 10px !important;
    text-align: start !important;
    background-color: #b33430;
    color: white;
    margin-top: -25px !important;
    margin-right: 20px !important;
    margin-left: 0px !important;

}
.btn-tex {
    position: relative;
    top: -8px;
    left: -4px;
} */
</style>
