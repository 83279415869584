<template>
<div>
    <div v-if="this.category == 'asset'">
        <FormItems />
    </div>
    <div v-else-if="this.category == 'crypto'">
        <FormCrypto />
    </div>
</div>
</template>

<script>
import FormItems from '@/components/FormItems.vue'
import FormCrypto from '@/components/FormCrypto.vue'
export default {
    components: {
        FormItems,
        FormCrypto,
    },
    props: [
        'category',
    ],
}
</script>

<style>
/* .custom-btn {
    background-color: #2F5C85 !important;
    color: white !important;
} */
/* .custom-switch {
    background-color: #2F5C85 !important;
    color: #2F5C85 !important;
} */
/* .warn {
    color: red;
    font-size: 14px;
    margin-left: 12px;
    padding: 0px;
    width: 355px;
} */
</style>
