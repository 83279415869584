<template>
<div id="app">

    <BaseModalAlert v-if="this.alertMsg" :alertMsg="alertMsg" />
    
    <!-- <NavBars v-if="this.loggedIn && (this.myPid || this.isAdminUser)" /> -->
    <NavBars v-if="this.loggedIn && this.myPid" />

    <LogoBar v-else />

    <!-- <TopBar v-if="this.loggedIn" /> -->

    <PWAPrompt />

    <LoadingSpinner v-if="this.isLoading"/>
    
    <!-- <button v-if="updateExists" @click="refreshApp">
        New version available! Click to update
    </button> -->

    <router-view v-slot="{ Component }">
        <!-- <transition name="fade" mode="out-in"> -->
        <!-- <transition :name="pageTo == '/clubsadmin' ? 'fade' : ''" mode="out-in"> -->
        <component :is="Component" />
        <!-- </transition> -->
    </router-view>

</div>
</template>

<script>
// import TopBar from '@/components/TopBar.vue'
// import apiCalls from '@/services/apiCalls.js'
import NavBars from '@/components/NavBars.vue'
import LogoBar from '@/components/LogoBar.vue'
import PWAPrompt from '@/components/PWAPrompt.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
// import NewModal from '@/components/NewModal.vue'
import {
    mapActions,
} from 'vuex'
import store from '@/store'
import router from '@/router'
// import axios from 'axios' // was needed for the interceptor
export default {
    components: {
        NavBars,
        LogoBar,
        PWAPrompt,
        LoadingSpinner,
        // NewModal,
    },
    data() {
        return {
            // refreshing: false,
            // registration: null,
            // updateExists: false,
        }
    },
    computed: { // Notice: the store is reactive (forces computing), localStorage is not. In case that links to property that is not reactive, call a method instead of computed
        loggedIn() {
            return store.state.authentications.loggedIn
        },
        myPid() {
            return store.state.myPid
        },
        // isAdminUser() {
        //     return store.state.authentications.isAdminUser
        // },
        alertMsg() {
            return store.state.alertMsg
        },
        isLoading() {
            return store.state.isLoading
        },
        // pageTo() {
        //     return store.state.page
        // },
    },
    methods: {
        ...mapActions('authentications', ['setLoggedIn']),  //removed setAdminUser
        ...mapActions(['setMyPid', 'setMyIcon', 'setMyIconM', 'setMyAlias', 'setPage', 'setParticIcon', 'setParticAlias', 'setParticId', 'setApiUrl', 'setMyCur', 'setMyClub', 'setMyUid', 'setCin', 'setCid', 'setCain', 'setCaid', 'setCad', 'setUnreadN', 'setUnreadM', 'setMyMem', 'setAtk', 'setRtk']),
        // showRefreshUI (e) {
        //     // Display a button inviting the user to refresh/reload the app due to an app update being available.
        //     // The new service worker is installed, but not yet active.
        //     // Store the ServiceWorkerRegistration instance for later use.
        //     this.registration = e.detail
        //     this.updateExists = true
        // },
        // refreshApp () {
        //     // Handle a user tap on the update app button.
        //     this.updateExists = false
        //     // Protect against missing registration.waiting.
        //     if (!this.registration || !this.registration.waiting) { 
        //         return 
        //     }
        //     this.registration.waiting.postMessage('skipWaiting')
        // },
    },
    async created() { // Restores the store states in case of a refresh  new-0806 !!!
        console.log('STARTING APP created')
        try {
            console.log('IN APP window.location: ', window.location)
            console.log('IN APP window.location.href: ', window.location.href)
            if (/\/investor/.test(window.location)) { //new-0806 !!!
                let investor = localStorage.getItem('paid')
                console.log('refreshing investor page: ', investor)
                router.push('/' + investor)
                // return router.push({ 
                //     name: 'DisplayInvestor', 
                //     params: {investor: investor } 
                // })
            }
            if (/\/\d+$/.test(window.location.href)) {
                console.log('!!!!!!!COMING FROM AN HYPERLINK TO /INVESTORS: ', window.location.href.slice(window.location.href.lastIndexOf('/') + 1))
                let investor2 = window.location.href.slice(window.location.href.lastIndexOf('/') + 1)
                if (investor2 != localStorage.getItem('paid') || !localStorage.getItem('paid')) {
                    localStorage.setItem('paid', investor2)
                    await this.setParticId(investor2)
                    router.push('/' + investor2)
                }
                // console.log('refreshing investor page: ', investor)
            }
            const loggedIn = localStorage.getItem('atk') // localStorage only stores as string, but here is ok, if it were an object then needed to JSON.parse(theObject)
            if (loggedIn) { // checks if there is a token in localStorage (it gets emptied only with logOut)
                await this.setLoggedIn(true) // needed in the store because the store is reactive, unlike the localStorage
            }
            const page = localStorage.getItem('pag')
            await this.setPage(page)
            // const userProfile = localStorage.getItem('userProfile')
            // if (userProfile && userProfile == 'admin') {
            //     await this.setAdminUser(true) // needed in the store because the store is reactive, unlike the localStorage
            // } else {
            const atk = localStorage.getItem('atk')
            const rtk = localStorage.getItem('rtk')
            const myicon = localStorage.getItem('pik')
            const myiconM = localStorage.getItem('pikm')
            const myalias = localStorage.getItem('pal')
            const paid = localStorage.getItem('paid')
            console.log('in App refresh- localStorage.getItem(paid): ', localStorage.getItem('paid'))
            console.log('paid: ', paid)
            const paic = localStorage.getItem('paic')
            const paal = localStorage.getItem('paal')
            const unreadn = localStorage.getItem('unrn')
            const unreadm = localStorage.getItem('unrm')
            await this.setAtk(atk)
            await this.setRtk(rtk)
            await this.setMyIcon(myicon)
            await this.setMyIconM(myiconM)
            await this.setMyAlias(myalias)
            await this.setParticId(paid)
            console.log('store.state.ParticId set in App refresh: ', store.state.ParticId)
            await this.setParticIcon(paic)
            await this.setParticAlias(paal)

            const mypid = localStorage.getItem('pid')
            const myuid = localStorage.getItem('uid')
            const cad = localStorage.getItem('cad')
            const cin = localStorage.getItem('cin')
            const cid = localStorage.getItem('cid')
            const cain = localStorage.getItem('cain')
            const caid = localStorage.getItem('caid')
            const apiUrl = localStorage.getItem('aur')
            const mycur = localStorage.getItem('cur')
            const mymem = localStorage.getItem('mem')
            const myclub = localStorage.getItem('mcl')
            await this.setMyPid(mypid)

            await this.setMyUid(myuid)
            await this.setCad(cad)
            await this.setCin(cin)
            await this.setCid(cid)
            await this.setCain(cain)
            await this.setCaid(caid)
            await this.setUnreadN(unreadn)
            await this.setUnreadM(unreadm)
            await this.setMyCur(mycur)
            await this.setMyMem(mymem)
            await this.setMyClub(myclub)
            if (apiUrl == 'rp') {
                await this.setApiUrl(apiUrl)
            } else {
                await this.setApiUrl('yf')
            }
            // }
            // if (/\/investor/.test(window.location)) {
            //     console.log('refreshing investor page')
            //     let investor = localStorage.getItem('paid')
            //     console.log('refreshing investor page: ', investor)
            //     router.push('/' + investor)
            //     // return router.push({ 
            //     //     name: 'DisplayInvestor', 
            //     //     params: {investor: investor } 
            //     // })
            // }

            // console.log('window.location: ', window.location)
            // console.log('regex: ', /\/investor/.test(window.location))
            // if(/\/investor/.test(window.location)) {
            //     router.push({ 
            //         name: 'DisplayInvestor', 
            //         params: {investor: investor } 
            //     })
            // }

            // Listen for swUpdated event and display refresh snackbar as required.
            // document.addEventListener(
            //     'swUpdated', this.showRefreshUI, { once: true }
            // )
            // // Refresh all open app tabs when a new service worker is installed.
            // if (navigator.serviceWorker) {  
            //     navigator.serviceWorker.addEventListener(
            //     'controllerchange', () => {
            //         if (this.refreshing) return;
            //         this.refreshing = true;
            //         window.location.reload()
            //     }
            //     )
            // }

            console.log('ENDING APP created')
        } catch (err) {
            console.log('error statusApp: ', err)
        }
        // axios.interceptors.response.use( // Safety measure, forces logout in case that someone maliciously would enter a fake user in the state through the console/Vue window 
        //     response => response, // simply return the response. Any status code of the kind 2XX triggers this function!!!
        //     error => {  // Any status code other than 2XX triggers this function!!!
        //         if(error.response.status === 401) { // if we catch a 401 error
        //             this.logout() // force a log out  .... logout no longer there, now would be done with: setLoggedIn(false)
        //         }
        //         return Promise.reject(error) // reject the Promise, with the error as the reason
        //         }
        // )
    },
}
</script>

<style>
.btn {
    color: #e2e2e9 !important;
    /* background-color: #2F5C85 !important; */
    /* border: solid 2px !important; */
    /* border: none; */
    /* border-color: #357ebd; */
}

.btn:hover {
    border-color: #00b3dba6 !important;
    /* border: solid 2px !important; */
}

.btn:focus {
    /* After been clicked remains in focus */
    /* background-color: #2F5C85 !important; */
    border: none !important;
}

/* ******************* BTN 3D ******************* */

.btn3d {
    position: relative;
    top: -1px;
    border: 0;
    transition: all 40ms linear;
}

.btn3d:active:focus,
.btn3d:focus:hover,
.btn3d:focus {
    outline: medium none;
}

.btn3d:active,
.btn3d.active {
    top: 2px;
}

.btn3d.btn-primar {
    box-shadow: 0 0 0 1px #2f5b85a1 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 5px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

.btn3d.btn-primar:active,
.btn3d.btn-primar.active {
    box-shadow: 0 0 0 1px #2f5b85a1 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset;
    background-color: #2F5C85;
}

/* #00b3dba6 inset  this color has been replaced in all pages by this one:  #4a95db34 inset */

/* ******************* END BTN 3D ******************* */

body {
    margin-top: 0;
    margin-right: auto;
    margin-bottom: 0;
    margin-left: auto;
    padding: 0;
    color: #ddd !important;    
    background-color: #272522 !important;
    /* background-color: #43d317 !important; */
    /* background-image: linear-gradient(rgba(0, 0, 255, 0.5), rgba(255, 255, 0, 0.5)),
                  url("@/assets/images/I-11.png"); */
        
    /* background-image: url("@/assets/images/IC-black24.png");
    background-repeat: no-repeat;
    background-size: 660px;
    background-position-y: 120px;
    background-position-x: 50%; */
    /* background-size: cover; */
    /* background-size: 176%; */
    /* background-position-y: -16.5%; */
    /* background-position-y: 155px; */
    /* background-position-y: 50px; */
    /* overflow-x: hidden; */
    /* width: 100% */

    /* LOGO club.net COLOR: #3f7cb3; */
}

/* html {
    overflow-x: hidden;
    width: 100%;
} */
/* prevents horizontal scrolling*/
body,
html {
    overflow-x: hidden;
    padding-right: 0px !important; /* RECENT-BODY- prevents body from shifting when vertical scrollbar is visible!!! */
}

/* body, html {
  height: 100%;
} */
/* .bg {
  background-image: url("@/assets/images/IC-black1.png");
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
} */

/* ###################### TRANSITIONS ###################    */
/* .fade-enter-from {
    opacity: 0;
}
.fade-enter-active {
    transition: opacity 0.2s ease-out;
}
.fade-leave-to {
    opacity: 0;
}
.fade-leave-active {
    transition: opacity 0.1s ease-out;
}
.fade2-enter-from {
    opacity: 0;
}
.fade2-enter-active {
    transition: opacity 0.1s ease-out;
} */
/* ###################### END TRANSITIONS ###################    */
</style>
