<template>
<!-- <div class="container main-cont-remove-comment"> -->
<div class="container my-5">

    <div class="modal fade" id="removeModal" tabindex="-1" aria-labelledby="removeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content modal-area">
                <div class="modal-header">
                    <h5 class="modal-title modal-title2" id="removeModalLabel">Remove comment</h5>
                    <button type="button" @click.prevent="closeRemove()" class="btn close-butt" data-bs-dismiss="modal" aria-label="Remove">
                        <div class="btn-tex">
                            x
                        </div>
                    </button>
                </div>

                <div class="modal-body modal-body2">
                    <div class="row top-row-modal">
                        <div class="col">
                            <p>Sure you want to remove this comment from {{ commentAuthor}}?</p>
                            <p>"{{ commentText }}"</p>

                        </div>
                    </div>

                    <div v-if="this.showBlock" class="row mt-3">
                        <!-- <div class="col pt-0 ps-4 checkbox-col"> -->
                        <div class="col pt-0 ps-4">
                            <div class="form-check">
                                <BaseInputCheckbox :label="this.labelText" v-model="this.blockInvestor" />
                            </div>
                        </div>
                    </div>

                    <div v-if="this.showReport" class="row mt-3 mb-2">
                        <!-- <div class="col pt-0 ps-4 checkbox-col"> -->
                        <div class="col pt-0 ps-4">
                            <div class="form-check">
                                <BaseInputCheckbox label="Report comment as inappropriate" v-model="this.reportComment" />
                            </div>
                        </div>
                    </div>

                </div>

                <div class="modal-footer modal-body2 px-0">
                    <div class="row modal-row">
                        <div class="col">
                            <button type="button" @click.prevent="removeComment()" class="btn btn-primary modal-btn">Remove comment</button>
                            <button type="button" @click.prevent="closeRemove()" class="btn btn-secondary  btn-secondary-modal" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
</template>

<script>
import apiCalls from '@/services/apiCalls.js'
import store from '@/store'
import router from '@/router'
import {
    mapActions
} from 'vuex'
import {
    Modal
} from 'bootstrap'
export default {
    name: 'RemoveComment',
    props: [
        'comment',
        'position',
        'category',
    ],
    data() {
        return {
            reportComment: false,
            blockInvestor: false,
            showReport: true,
            showBlock: true,
            authorId: null,
            authorAlias: null,
            previousPage: '',
            commentDetails: {},
            commentAuthor: '',
            commentText: '',
        }
    },
    computed: {
        labelText() {
            return `Block ${this.authorAlias}. If blocked, the user will not be able to send you messages or invitations, nor to view comments in your portfolio or watchlist`
        }
    },
    methods: {
        ...mapActions(['setAlertMsg', 'setRfrsh', 'setPage']),
        async removeComment() {
            try {
                if (this.blockInvestor) {
                    const blocked = await apiCalls.postEntry('/blocks/', {
                        'blocker': store.state.myPid,
                        'blocked': this.authorId,
                        'blockedalias': this.authorAlias,
                    })
                    if (!blocked) {
                        console.log('error in blocking investor')
                        // TODO = handle in case of error                        
                    }
                }
                if (this.reportComment) {
                    let reportType = ''
                    let description = ''
                    if (this.category == 'p') {
                        reportType = 'poscomment'
                    } else if (this.category == 'w') {
                        reportType = 'wtccomment'
                    }
                    // let endpoint = '/' + reportType + 's/' + this.comment + '/'
                    // const commentDetails = await apiCalls.getEntries(endpoint, {})
                    // if (commentDetails) {
                    if (this.category == 'p') {
                        description = `Comment to Position ${this.commentDetails['position']} of ${this.commentDetails['created']}: ${this.commentDetails['comment']}`
                    } else if (this.category == 'w') {
                        description = `Comment to Watchasset ${this.commentDetails['watchasset']} of ${this.commentDetails['created']}: ${this.commentDetails['comment']}`
                    }
                    const reported = await apiCalls.postEntry('/reports/', {
                        'type': reportType,
                        'reporter': store.state.myPid,
                        'reported': this.authorId,
                        'description': description,
                    })
                    if (!reported) {
                        console.log('error in reporting comment')
                    }
                }
                let removed = ''
                let positionPatched = ''
                if (this.category == 'p') {
                    removed = await apiCalls.deleteEntry('/poscomments/' + this.comment + '/')
                } else if (this.category == 'w') {
                    removed = await apiCalls.deleteEntry('/wtccomments/' + this.comment + '/')
                }
                if (removed) {
                    console.log('comment removed')
                    // console.log('store.state.commNum: ', store.state.commNum)
                    // console.log('typeof store.state.commNum: ', typeof store.state.commNum)
                    let commenum = localStorage.getItem('cnu')
                    if ((Number(commenum) == 1)) {
                        if (this.category == 'p') {
                            positionPatched = await apiCalls.patchEntry('/positions/' + this.position + '/', {
                                'extracomments': 0,
                            })
                        } else if (this.category == 'w') {
                            positionPatched = await apiCalls.patchEntry('/watchassets/' + this.position + '/', {
                                'extracomments': 0,
                            })
                        }
                        if (positionPatched) {
                            console.log('position amended: ', positionPatched)
                            this.hideRemoveModal()
                            return router.push({
                                name: 'itemdata',
                                params: {
                                    category: this.previousPage,
                                }
                            })
                        } else {
                            this.hideRemoveModal()
                            await this.setRfrsh(true)
                            await this.setAlertMsg(`Sorry, it appears that the comment could not be properly removed.${'\n\n'}Please try again or inform the site administrators.`)
                            // return router.go(0)
                        }
                    } else {
                        this.hideRemoveModal()
                        return router.push({
                            name: 'itemdata',
                            params: {
                                category: this.previousPage,
                            }
                        })
                    }
                } else {
                    this.hideRemoveModal()
                    await this.setRfrsh(true)
                    await this.setAlertMsg(`Sorry, it appears that the comment could not be properly removed.${'\n\n'}Please try again or inform the site administrators.`)
                }
            } catch (error) {
                console.log('error in removeComment: ', error)
                this.hideRemoveModal()
                await this.setRfrsh(true)
                await this.setAlertMsg(`Sorry, it appears that the comment could not be properly removed.${'\n\n'}Please try again or inform the site administrators.`)
                // return router.go(0)
            }
        },
        closeRemove() {
            this.hideRemoveModal()
            return router.go(-1)
        },
        hideRemoveModal() {
            this.bsRemoveModal.hide()
        },
    },
    async created() {
        if (!this.category || !this.comment || !this.position) {
            return router.go(-1)
        }
        console.log('category: ', this.category)
        console.log('comment: ', this.comment)
        console.log('position: ', this.position)
        try {
            let endpoint = ''
            if (this.category == 'p') {
                endpoint = '/poscomments/' + this.comment + '/'
            } else if (this.category == 'w') {
                endpoint = '/wtccomments/' + this.comment + '/'
            }
            const comment = await apiCalls.getEntries(endpoint, {})
            if (comment) {
                console.log('comment: ', comment.data)
                this.commentAuthor = comment.data['useralias']
                this.commentText = comment.data['comment']
            } else {
                console.log('comment not found')
            }

        } catch (err) {
            console.log(err)
        }
    },
    async mounted() {
        this.myRemoveModal = document.getElementById('removeModal')
        this.bsRemoveModal = new Modal(this.myRemoveModal)
        // console.log('store.state.particId: ', store.state.particId)
        try {
            let endpoint = ''
            if (this.category == 'p') {
                await this.setPage('/removep')
                endpoint = '/poscomments/' + this.comment + '/'
                this.previousPage = 'portfolio'
            } else if (this.category == 'w') {
                await this.setPage('/removew')
                endpoint = '/wtccomments/' + this.comment + '/'
                this.previousPage = 'watchlist'
            }
            const commentDetails = await apiCalls.getEntries(endpoint, {})
            if (commentDetails) {
                this.commentDetails = commentDetails.data
                this.authorId = commentDetails.data.participant
                this.authorAlias = commentDetails.data.useralias
            }

            if (this.authorId == store.state.myPid) {
                this.showReport = false
                this.showBlock = false
            } else {
                const blockedInvestor = await apiCalls.getEntries('/blocks/', {
                    'blocker': store.state.myPid,
                    'blocked': this.authorId,
                })
                if (blockedInvestor && blockedInvestor.data.length > 0) {
                    console.log('blockedInvestor.data: ', blockedInvestor.data)
                    this.showBlock = false
                }
            }
            return this.bsRemoveModal.show()

        } catch (err) {
            console.log('error in showRemoveModal: ', err)
        }
    },
}
</script>

<style scoped>
/* .main-cont-remove-comment {
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 40px;
    margin-bottom: 40px;
} */

/* ***** MODAL ****************************************************** */
.modal-area {
    /* background-color: #dbd7d4; */
    background-color: #e4e4e4;
}

.modal-title2 {
    color: #4d4d4d;
}

.modal-body2 {
    color: #4d4d4d;
}

.top-row-modal {
    margin-top: 0px;
    font-weight: bold;
}

.modal-row {
    text-align: center;
}

.btn-secondary {
    margin-left: 20px !important;
}

.btn-secondary-modal {
    height: 38px;
    margin-top: -3px !important;
}

.modal-footer {
    display: flex;
    justify-content: center;
}

.modal-btn {
    /* width: 180px; */
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 10px !important;
    height: 38px;
    /* margin-left: -60px !important; */
    margin-top: -3px !important;
    background-color: #2F5C85 !important;
    color: white !important;
    border: none !important;
}

/* ********************************************* */

.close-butt {
    height: 25px !important;
    width: 10px !important;
    text-align: start !important;
    background-color: #b33430;
    color: white;
    margin-top: 10px !important;
}

.btn-tex {
    position: relative;
    top: -8px;
    left: -4px;
}

/* .close-butt {
    height: 21px !important;
    width: 21px !important;
    text-align: center !important;
    background-color: #b33430;
    color: #dddddd;
    font-size: 0.9rem !important;
    margin-top: 0px !important;
    margin-left: 0px !important;
    font-weight: bold !important;
    padding: 0px !important;
}
.btn-tex {
    position: relative;
    top: -6px;
    left: -4px;
    padding: 0px;
    font-size: 1.1rem;
    margin-top: 0px;
    margin-left: 7px;
} */
</style>
