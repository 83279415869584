<template>
<div>
  <label v-if="label" class="form-label ms-1 mb-0">{{ label }}</label>
    <select
    class="form-select shadow bg-body rounded bg-medium"
    v-bind="$attrs"
    :value="modelValue"
    @change="$emit('update:modelValue', $event.target.value)"
    >
    <option
      v-for="option in options"
      :value="option"
      :key="option"
      :selected="option === modelValue"
    >{{ option }}</option>
  </select>
  </div>
</template>


<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number, Boolean],
      default: ''
    },
    options: {
      type: Array,
      required: true
    }
  }
}
</script>
<style>
.bg-medium {
    background-color: #d4d4d4 !important;
    border: none;
    /* background-color: #babab9 !important; */
}
</style>