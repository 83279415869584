<template>
<div class="container my-5">
    <div class="row mt-5 pt-1">
        <div class="col px-4">

            <TextAbout v-if="category=='about'" />
            <TextManual v-if="category=='manual'" />
            <TextPrivacy v-if="category=='privacy'" />
            <TextTerms v-if="category=='terms'" />

            <!-- <div class="row spacer-row-help"></div> -->
            <!-- <div v-if="this.loggedIn" class="public-privacy"> -->
                <div v-if="category!='about'" class="my-4">
                    <router-link :to="{ name: 'about' }" class="links">About InvestClub</router-link>
                </div>
                <div v-if="category!='manual'" class="my-4">
                    <router-link :to="{ name: 'manual' }" class="links">How it works</router-link>
                </div>
                <div v-if="category!='privacy'" class="my-4">
                    <router-link :to="{ name: 'privacy' }" class="links">Privacy Policy</router-link>
                </div>
                <div v-if="category!='terms'" class="my-4">
                    <router-link :to="{ name: 'terms' }" class="links">Terms and Conditions</router-link>
                </div>
            <!-- </div> -->

        </div>
    </div>
</div>
</template>

<script>
import TextAbout from '@/components/TextAbout.vue'
import TextManual from '@/components/TextManual.vue'
import TextPrivacy from '@/components/TextPrivacy.vue'
import TextTerms from '@/components/TextTerms.vue'
// import store from '@/store'
export default {
    props: [
        'category',
    ],
    components: {
        TextAbout,
        TextManual,
        TextPrivacy,
        TextTerms,
    },
    // computed: {
    //     loggedIn() {
    //         return store.state.authentications.loggedIn
    //     },
    // },
}
</script>

<style scoped>
.links {
    color: #dbd7d4;
    text-decoration: underline;
    font-size: 19px;
    font-weight: bold;
    text-decoration-thickness: 2px;
}

.spacer-row-help {
    height: 2px;
    /* margin-top: 0px;
    margin-bottom: 0px; */
}
</style>
