import axios from 'axios'
import store from '@/store'
// import router from '@/router'

const cgClient = axios.create({
    baseURL: 'https://api.coingecko.com',
    timeout:5000,
    // timeout:1, // Here in case that I want to test generating error with API
    // params: {modules: 'defaultKeyStatistics,assetProfile'},
    withCredentials: false,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }
})



cgClient.interceptors.request.use(
    async function(config) {
        await store.dispatch('setIsLoading', true)
        return config
    }
)

cgClient.interceptors.response.use(
    async function (response) {    // Any status code that lie within the range of 2xx cause this function to trigger. Do something with response data (here just returning the response unchanged)
        await store.dispatch('setIsLoading', false)
        return response
    },
    async function (error) {    // Any status codes that falls outside the range of 2xx cause this function to trigger    // Do something with response error
        try {
            await store.dispatch('setIsLoading', false)
            console.log('error in cgClient1: ',error)
            return 
        } catch (error) {
            console.log('error in cgClient2: ', error)
        }
    }
)

export default {
    searchCG(params) {
        let endpoint = '/api/v3/search/'
        return cgClient.get(endpoint, {
            params: params,
        })
    },
    getCryptoPrice(params) {
        let endpoint = '/api/v3/simple/price/'
        return cgClient.get(endpoint, {
            params: params,
        })
    },
}