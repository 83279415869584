/* eslint-disable no-console */

import { register } from 'register-service-worker'
// import getNotificationsInBackground from "@/components/BackgroundSync.vue"

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    async ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )


      ///////////////// BADGE *************************************************
    //   if ('serviceWorker' in navigator) {
    //     const registration = await navigator.serviceWorker.ready
    //     // Check if periodicSync is supported
    //     if ('permissions' in navigator) {
    //       console.log('Permissions are supported')
    //     } else {
    //       console.log('Permissions are not supported')
    //     }
    //     if ('periodicSync' in registration) {  // or: if (registration.periodicSync) {
    //         console.log('Periodic Background Sync is supported.')
    //         // Request permission
    //         const status = await navigator.permissions.query({
    //           name: 'periodic-background-sync',
    //         })
    //         console.log('Permission state: ', status.state)
    //         if (status.state === 'granted') {
    //           console.log('Periodic background sync can be used.')

    //           // const permissionStatus = await navigator.permissions.request({name: 'periodic-background-sync', userVisibleOnly: 'true'})
    //           // console.log('push permission state is ', permissionStatus.state)

    //           // Register new background sync with tag "get-notification"
    //           try {
    //               // await registration.periodicSync.register('get-notifications', { 
    //               //   minInterval: 4 * 60 * 60 * 1000 // every 4 hours
    //               // })
    //               await registration.periodicSync.register('get-notifications', { 
    //               minInterval: 2 * 60 * 1000 // every 2 minutes
    //               })
    //               console.log('Periodic background sync is registered.')
    //               alert('Periodic background sync is registered.')
    //               navigator.setAppBadge(3)
    //               alert('Done')

    //           } catch(e) {
    //               console.error(`Periodic background sync failed:\nx${e}`)
    //           }

    //         } else {
    //           console.log('Permission not granted - periodic background sync cannot be used.')
    //         }
    //         const tags = await registration.periodicSync.getTags();
    //         if (!tags.includes('get-notifications')) {
    //             console.log("Only update content if sync isn't set up (?).")
    //         // getNotificationsOnPageLoad()
    //         }
    //     } else {
    //         console.log('Periodic Background Sync is not supported.')
    //         console.log("If periodic background sync isn't supported, update at least on load (?).")
    //         // getNotificationsOnPageLoad()
    //     }
    //     self.addEventListener('periodicsync', (event) => {
    //       if (event.tag === 'get-notifications') {
    //       event.waitUntil(getNotificationsInBackground())
    //       }
    //     })
    //   }
      // ***********************************************************************************************


    },
    // registered (registration) {
    registered () {  
      console.log('Service worker has been registered.')
      // setInterval(() => {
      //   registration.update();
      // }, 1000 * 60 * 60 * 24 * 7); // e.g. weekly checks
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    // updated (registration) {
    updated () {  
      console.log('New content is available; please refresh.')
      // document.dispatchEvent(
      //   new CustomEvent('swUpdated', { detail: registration })
      // )
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })

}