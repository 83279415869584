<template>
<div class="container main-cont-admin-club" v-show="!this.isLoading && !this.clubLoading">

<BaseModalPicture v-if="picUrl" :picUrl="picUrl" :topPos="topPos" :leftPos="leftPos" element='investor' @clicked="onModalClosed"/>

    <div class="modal fade" id="removeModal" tabindex="-1" aria-labelledby="removeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content modal-area">
                <div class="modal-header">
                    <h5 class="modal-title modal-title2" id="removeModalLabel">Remove member from Club</h5>
                    <button type="button" class="btn close-butt" data-bs-dismiss="modal" aria-label="Remove">
                        <div class="btn-tex">
                            x
                        </div>
                    </button>
                </div>

                <div class="modal-body modal-body2">
                    <div class="row top-row-modal">
                        <div class="col">
                            <p>Sure you want to remove <b>{{ this.modalRemoveEntry.useralias }}</b> from your Club?</p>
                        </div>
                    </div>
                </div>

                <div class="modal-footer modal-body2 px-0">
                    <div class="row modal-row">
                        <div class="col px-0">
                            <button type="button" @click.prevent="removeMember(this.modalRemoveEntry)" class="btn btn-primary modal-btn">Remove member</button>
                            <button type="button" class="btn btn-secondary btn-secondary-modal" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="modal fade" id="leaveModal" tabindex="-1" aria-labelledby="leaveModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content modal-area">

                <div class="modal-header">
                    <h5 class="modal-title modal-title2" id="leaveModalLabel">Leave club</h5>
                    <button type="button" class="btn close-butt" data-bs-dismiss="modal" aria-label="Leave">
                        <div class="btn-tex">
                            x
                        </div>
                    </button>
                </div>

                <div class="modal-body modal-body2">
                    <div class="row top-row-modal">
                        <div class="col">
                            <p>Sure you want to leave <b>{{ this.modalLeaveClub.clubname }}</b> club?</p>
                        </div>
                    </div>
                </div>

                <div class="modal-footer modal-body2 px-0">
                    <div class="row modal-row">
                        <div class="col px-0">
                            <button type="button" @click.prevent="leaveClub(this.modalLeaveMembership, this.modalLeaveClub)" class="btn btn-primary modal-btn">Leave club</button>
                            <button type="button" class="btn btn-secondary btn-secondary-modal" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <!-- <div class="row mt-3">
        <div class="col pt-2 ps-4">
            <div class="form-check">
                <BaseInputCheckbox label="my primary club" v-model="this.topClub" @update:modelValue="updateTopClub()"/>
            </div>
        </div>
    </div> -->

    <div v-if="this.admin == this.myPid && !this.clubLoading" class="users-list">
        <div v-for="entry, index in this.membersData" :key="entry.id" class="card entry-card">
            <div class="row">

                <div class="col-2 cell-col0 d-flex justify-content-center">
                    <span v-if="entry.usericon.length == 4"><img class="user-sticker-round" :src="require('@/assets/images/'+ entry.usericon + '.png')" path="@/assets/images/" alt="user"></span>
                    <span v-else-if="entry.usericon.length > 4 && entry.src"><div class="investor-image" :style="{ 'background-image': `url(${entry.src})` }" @click.prevent="enlargePic(entry.src, index)" :id="'investorPic-' + index"></div></span>
                    <!-- <span v-else-if="entry.usericon.length > 4 && entry.src"><img class="user-sticker-round" :src="entry.src" alt="user1"></span> -->
                    <span v-else><img class="user-sticker-round" :src="require('@/assets/images/'+ 'I-11' + '.png')" path="@/assets/images/" alt="user1"></span>
                </div>


                <div class="col-7 cell-member cell-neu">
                    <!-- {{ entry.usermail}}-{{ this.admin }}-{{entry.id }}-{{this.myPid}} -->
                    <!-- {{ entry.usermail}} -->
                    {{ entry.useralias}}
                </div>

                <div v-if="entry.id == this.admin" class="col-3 cell-remove cell-neu">
                    admin
                </div>
                <div v-else-if="this.admin == this.myPid" class="col-3 cell-remove">
                    <a class="cell-neu" href="" @click.prevent="showRemoveModal(entry)">remove</a>
                </div>

            </div>
        </div>

        <div>
            <form>
                <div class="row justify-content-start gx-3 gy-0 pt-1 mt-3">
                    <div class="col col-sm-10">
                        <BaseInputEnter label="Invite new user by Email:" type="text" v-model="entry.email" @focus="this.emailError=''" @blur="validEmail()" id="emailBox" />
                    </div>
                    <div class="col-3 col-sm-2">
                        <button type="submit" class="btn custom-btn invite-btn btn3d btn-primar" @click.prevent="addComment()" id="add-comment-btn">Invite</button>
                    </div>
                    <div v-if="this.emailError">
                        <p class="warn">{{ this.emailError }}</p>
                    </div>
                </div>
            </form>

            <!-- <div v-if="this.textArea"> -->
            <div v-if="this.textArea || this.inviteSubmitted" class="invite-warning">
                Below message will be attached to the email invitation:<br>"<b>Invitation sent by a user with email address:&nbsp; {{myEmail}}</b>"
            </div>
            <div v-if="this.inviteSubmitted && this.newcomment" class="invite-warning">
                <br>"<b>{{this.newcomment}}</b>"
            </div>
            <div v-if="this.textArea && !this.inviteSubmitted" class="invite-warning2">
                Additional message (optional, 250 characters):<br>
            </div>
            <!-- </div> -->
            <div v-show="this.textArea && !this.inviteSubmitted" class="row">
                <div class="col-sm-12">
                    <div class="form-floating">
                        <textarea class="form-control comment-textarea" maxlength="250" id="floatingTextarea" v-model="this.newcomment" @drop.prevent="false" @paste.prevent="false"></textarea>
                    </div>
                </div>
            </div>
            <div v-show="this.textArea && !this.inviteSubmitted" class="row">
                <!-- <button v-show="this.textArea[index]" role="button" @click.prevent="enterComment(entry, index)" class="btn shadow rounded entercomm-btn">Enter comment</button> -->
                <!-- <button v-show="this.textArea[index]" role="button" @click.prevent="this.newcomment[index] = ''; toggleComments(entry, index);" class="btn shadow rounded entercomm-btn">Cancel</button> -->
                <div class="col-10">
                    <button role="button" @click.prevent="inviteNewUser()" class="btn entercomm-btn btn3d btn-primar">Invite new user</button>
                    <!-- </div>
                <div class="col-3"> -->
                    <button role="button" @click.prevent="cancelInvite()" class="btn btn-secondary btn-secondary-comment shadow rounded">Cancel</button>
                </div>

            </div>

            <!-- <div v-show="this.showUserInvitedMsg" class="card comment-missing px-2 py-2 mt-3">
                <p>{{ userInvitedMsg }}</p>
                <button class="btn close-butt close-butt-invite" role="button" @click="this.closeUserInvitedMsg()">
                    <div class="btn-tex">
                        x
                    </div>
                </button>
            </div> -->

        </div>
        <!-- <div class="row">
            <div class="col py-3 px-0">
            <div class="form-check form-switch">
                <BaseInputSwitch label="Each asset available exclusively to one club member (first come first serve)" v-model="this.exlusiveAssets" />
            </div>
            </div>
        </div> -->
    </div>

    <div v-else>
        <div class="leave-club">
            Not the administrator. <a href="" @click.prevent="showLeaveModal()" class="leave-link">Leave</a> this club?
        </div>
    </div>
</div>
</template>

<script>
import apiCalls from '@/services/apiCalls.js'
// import LocalMsg from '@/components/LocalMsg.vue'
import store from '@/store'
import {
    mapActions
} from 'vuex'
import {
    Modal
} from 'bootstrap'

// import router from '@/router'
export default {
    name: 'ClubAdmin',
    // components: {
    //     LocalMsg,
    // },
    data() {
        return {
            // clubData: null,
            entry: {
                // username: '',
                // password: '',
                email: '',
                clubId: '', // Necessary if the user already exists, then this info is entered in Memberships as 'invited'
                clubname: '', // This is necessary when creating the user in order to send her the mail with the link
                inviteId: '', // also needed for the link to send to the invited
                invitemessage: '',
            },
            invite: {
                invitemail: '',
                inviteclub: '',
                invitecurrency: '',
                inviteduser: '',
                invitedby: null,
                invitemessage: '',
            },
            userInvitedMsg: '',
            // showUserInvitedMsg: false,
            membersData: [],
            modalRemoveEntry: {},
            modalLeaveClub: {},
            modalLeaveMembership: {},
            // exlusiveAssets: true,
            admin: null,
            investorSearch: null,
            emailError: '',
            topClub: false,
            textArea: false,
            newcomment: '',
            myEmail: '',
            inviteSubmitted: false,
            clubLoading: false,
            picUrl: false,
            topPos: null,
            leftPos: null,
        }
    },
    computed: {
        // showLocalMsg() {
        //     return store.state.messages.showLocalMsg
        // },
        myPid() {
            return store.state.myPid
        },
        isLoading() {
            return store.state.isLoading
        },
    },
    methods: {
        ...mapActions(['setCad', 'setMyMem', 'setAlertMsg', 'setRfrsh', 'setMyClub']),
        // async updateTopClub() {  //TOPCLUB-RELATED LINES
        //     console.log('store.state.caid in updateTopClub: ', store.state.caid)
        //     console.log('this.topClub: ', this.topClub)
        //     let topClubId = null
        //     try {
        //         if (this.topClub) {
        //             console.log('this.topClub is true')
        //             topClubId = store.state.caid
        //         }
        //         // } else {
        //         //     console.log('this.topClub is false')
        //         //     topClubId = null
        //         // }
        //         const updatedProfile = await apiCalls.patchEntry('/participants/' + store.state.myPid + '/', {
        //             'topclubid': topClubId
        //         })
        //         if (updatedProfile) {
        //             console.log('profile updated: ', updatedProfile)
        //             console.log('this.topClub before updating caid and mcl: ', this.topClub)
        //             if (this.topClub) {
        //                 localStorage.setItem('mcl', topClubId)
        //             } else {
        //                 localStorage.removeItem('mcl')
        //             }
        //             await this.setMyClub(topClubId)
        //         }
        //     } catch (err) {
        //         console.log('error in updateTopClub: ', err)
        //     }
        // },
        async getClubMembersData() {
            try {
                // let clubId = store.state.caid
                // let params1 = {
                //     'club': store.state.caid, // caid is the 'prop' to the component although comes through the store
                //     'status': 'active',
                // }
                const clubMemberships = await apiCalls.getEntries('/memberships/', {
                    'club': store.state.caid, // caid is the 'prop' to the component although comes through the store
                    'status': 'active',
                })
                if (clubMemberships && clubMemberships.data.length > 0) {
                    let memberslist = clubMemberships.data.map(a => a.participant)
                    let params2 = {
                        'memberslist': memberslist.join() //creates a comma separated list of club members (participant ID) from the array
                    }
                    const membersData = await apiCalls.getEntries('/participants/', params2)
                    this.membersData = membersData.data
                    this.membersData.forEach(obj => {
                        if (obj['usericon'].length > 4) {
                            obj.src = store.state.serverUrl + '/getimage/' + obj['usericon'] + '/'
                        }
                    })
                }
                this.clubLoading = false
            } catch (error) {
                this.clubLoading = false
                return console.log('error in getClubMembersData: ', error)
            }
        },
        async addComment() {
            try {
                if (!this.entry.email) {
                    return
                }
                if (!this.validEmail()) {
                    return
                }
                this.myEmail = ''
                // document.getElementById('add-comment-btn').setAttribute("disabled","disabled")
                document.getElementById('add-comment-btn').disabled = true

                // ***************************************************************************************                }
                // const myPart = await apiCalls.getEntries('/participants/' + store.state.myPid + '/', {})
                // if(myPart) {
                //     this.myEmail = myPart.data['usermail'] //USERMAIL-HIDE - it's my own, so could directly call my user. Alternatively, could simply add it in the message and inform the user that it will be added
                // ***************************************************************************************                       
                const myPart = await apiCalls.getEntries('/ltdu/', {
                    pid: store.state.myPid
                })
                if (myPart && myPart.data.length > 0) {
                    this.myEmail = myPart.data[0]['email']
                    // console.log('FFFFFOUNDmyPart.data[0]: ', myPart.data)
                    // console.log('FFFFFOUNDmyPart.data[0]["email"]: ', myPart.data[0]['email'])
                    this.textArea = true
                    setTimeout(() => { // NEW 20230308  // Seems that in reactive languages like React, Vue etc focus command doesn't work if you don't place inside setTimeout
                        document.getElementById('floatingTextarea').focus()
                        return document.getElementById('floatingTextarea').scrollIntoView({
                            behavior: 'smooth',
                            block: 'center'
                        })
                        // window.scrollTo(0, document.body.scrollHeight)
                    }, 0)
                } else {
                    return await this.setAlertMsg('Email not found. Connection error or you don\'t have authorization. Please try again')
                }
            } catch (err) {
                console.log(err)
            }
        },
        cancelInvite() {
            this.newcomment = ''
            this.textArea = false
            document.getElementById('add-comment-btn').disabled = false
        },
        async inviteNewUser() {
            if (!this.entry.email) {
                return
            }
            if (!this.validEmail()) {
                return
            }
            document.getElementById('add-comment-btn').disabled = false
            this.inviteSubmitted = true
            try {
                const membersCount = await apiCalls.getEntries('/memberships/', {
                    'club': store.state.caid,
                    'status': 'active'
                })
                if (membersCount && membersCount.data.length > (store.state.maxMembers - 1)) { // maxMembers
                    return await this.setAlertMsg(`Sorry, this club already has 10 members. You would have to remove one member before inviting one more to join.`)
                }
                this.userInvitedMsg = ''
                // this.showUserInvitedMsg = false
                // Get the club details
                let endPoint = '/clubs/' + store.state.caid + '/' // caid is the 'prop' to the component although comes through the store
                const clubData = await apiCalls.getEntries(endPoint, {})
                if (clubData) {
                    this.entry.clubname = clubData.data.clubname
                    this.entry.clubId = clubData.data.id // should be the same as store.state.caid
                }
                if (this.newcomment && this.newcomment.length > 0) {
                    this.entry.invitemessage = this.newcomment
                }
                // Check if the email is already in Participant table and if yes get the Participant and send invite notification
                // *****************************************************************
                // const existingParticipant = await apiCalls.getEntries('/participants/', { //USERMAIL-HIDE: replaced in the backend by an endpoint that returns for the participant with the given user email: stopinvite, id and memberships
                //     'usermail': this.entry.email
                // })
                // *****************************************************************
                const existingParticipant = await apiCalls.getEntries('/ltdp/', {
                    'usermail': this.entry.email
                })
                if (existingParticipant && existingParticipant.data.length > 0) { //the email belongs to an existing user
                    // console.log('found Participant from email: ', existingParticipant.data[0])
                    let invitedParticipant = existingParticipant.data[0]
                    // alert('pause')
                    invitedParticipant.id = atob(existingParticipant.data[0]['mi'])
                    invitedParticipant.memberships = atob(existingParticipant.data[0]['ms'])
                    invitedParticipant.stopinvite = existingParticipant.data[0]['si']
                    console.log('invitedParticipant: ', invitedParticipant)
                    console.log('decoded id: ', invitedParticipant.id)
                    console.log('decoded memberships: ', invitedParticipant.memberships)
                    console.log('invitedParticipant.stopinvite: ', invitedParticipant.stopinvite)
                    if (invitedParticipant.stopinvite == true) {
                        return await this.setAlertMsg('This Investor does not accept any more invitations.')
                    }
                    if (Number(invitedParticipant.memberships) < store.state.maxMemberships) {
                        await this.inviteExistingUser(invitedParticipant) // No more instructions in this function after the callback function
                    } else {
                        return await this.setAlertMsg('This Investor has already reached his limit of 10 active club memberships.')
                    }
                } else {
                    //Check if already 2 invitations from this participant to this email address
                    const invitedCount = await apiCalls.getEntries('/invitations/', {
                        'invitemail': this.entry.email,
                        'inviteused': 0, // stands for false, but false gives error at the backend
                    })
                    if (invitedCount && invitedCount.data.filter(obj => obj.invitedby == store.state.myPid && obj.inviteclub == store.state.caid).length > 1) {
                        return await this.setAlertMsg(`Sorry, you have already sent 2 identical invitations to ${this.entry.email} and they have not been used.${'\n\n'}We are not able to send more identical invitations to the same email address.`)
                    } else if (invitedCount && invitedCount.data.filter(obj => obj.invitedby == store.state.myPid).length > 1) {
                        return await this.setAlertMsg(`Sorry, you have already sent 2 invitations to ${this.entry.email} and they have not been used.${'\n\n'}We are not able to send more invitations to the same email address.`)
                    } else if (invitedCount && invitedCount.data.length > 2) {
                        return await this.setAlertMsg(`Sorry, already 3 invitations have been sent to ${this.entry.email} which have not been used.${'\n\n'}We are not able to send more invitations to the same email address.`)
                    }
                    const inviterCount = await apiCalls.getEntries('/invitations/', {
                        'invitedby': store.state.myPid,
                        'inviteused': 0, // stands for false, but false gives error at the backend
                    })
                    if (inviterCount && inviterCount.data.length > (store.state.maxInvitations - 1)) { // maxInvitations
                        return await this.setAlertMsg(`Sorry, you have already sent 20 invitations which have not been used yet.${'\n\n'}We recommend to wait until some of your invitations are used before sending more.`)
                    }
                    this.invite.invitemail = this.entry.email
                    this.invite.inviteclub = store.state.caid
                    this.invite.invitedby = store.state.myPid
                    this.invite.invitecurrency = store.state.myCur
                    this.invite.invitemessage = this.newcomment
                    // this.invite.inviteduser = createdUser.data.id // Not available yet
                    console.log('About to create invitation: ', this.invite)
                    const inviteCreated = await apiCalls.postEntry('/invitations/', this.invite)
                    if (inviteCreated) {
                        console.log('invite created: ', inviteCreated.data)
                        this.entry.inviteId = inviteCreated.data.id
                        const createdUser = await apiCalls.postEntry('/user_create/', this.entry)
                        if (createdUser) {
                            console.log('createdUser: ', createdUser.data)
                            // this.userInvitedMsg = `If ${this.entry.email} is not in use, your invitation has been sent to that address.${'\n\n'}Otherwise your invitation has been notified to the existing user.`
                            // return this.showUserInvitedMsg = true
                            this.inviteSubmitted = false
                            this.textArea = false
                            this.newcomment = ''
                            let invitedEmail = this.entry.email
                            this.entry.email = ''
                            return await this.setAlertMsg(`If ${invitedEmail} is not yet a user, your invitation has been sent to that address.${'\n\n'}Otherwise your invitation has been notified to the corresponding user (unless the user is already a member of this club).`)

                        } else {
                            return await this.setAlertMsg(`Sorry, it appears that your invitation could not be sent.${'\n\n'}Please try again or inform the site administrators.`)
                            // this.userInvitedMsg = `Sorry, it appears that your invitation could not be sent. Please try again or inform the site administrators.`
                            // return this.showUserInvitedMsg = true
                        }
                    } else {
                        return await this.setAlertMsg(`Sorry, it appears that your invitation could not be sent.${'\n\n'}Please try again or inform the site administrators.`)
                        // this.userInvitedMsg = `Sorry, it appears that your invitation could not be sent. Please try again or inform the site administrators.`
                        // return this.showUserInvitedMsg = true
                    }
                }
            } catch (error) {
                console.log('error in inviteNewUser: ', error)
                return await this.setAlertMsg(`Sorry, it appears that your invitation could not be sent.${'\n\n'}Please try again or inform the site administrators.`)
                // this.userInvitedMsg = `Sorry, it appears that your invitation could not be sent. Please try again or inform the site administrators.`
                // return this.showUserInvitedMsg = true
            }
        },
        async inviteExistingUser(invitedParticipant) {
            try {
                const membership = await apiCalls.getEntries('/memberships/', {
                    'participant': invitedParticipant.id,
                    'club': this.entry.clubId,
                })
                let membershipId = ''
                if (membership && membership.data.length > 0) {
                    let status = membership.data[0].status
                    if (status == 'left') {
                        return await this.setAlertMsg(`The Investor with email address ${this.entry.email} was already in this club but left.${'\n\n'}The Investor may apply to join again, but cannot be invited.`)
                        // this.userInvitedMsg = `Sorry, the Investor with email address ${this.entry.email} was already in this club but left. The Investor may apply to join again, but cannot be invited.`
                        // return this.showUserInvitedMsg = true
                    } else if (status == 'invited') {
                        return await this.setAlertMsg(`The Investor with email address ${this.entry.email} has already been invited to this club.${'\n\n'}The Investor may apply to join, but cannot be invited again.`)
                        // this.userInvitedMsg = `Sorry, the Investor with email address ${this.entry.email} was already invited to this club. The Investor may apply to join, but cannot be invited again.`
                        // return this.showUserInvitedMsg = true
                    } else if (status == 'active') {
                        return await this.setAlertMsg(`If ${this.entry.email} is not yet a user, your invitation has been sent to that address.${'\n\n'}Otherwise your invitation has been notified to the corresponding user (unless the user is already a member of this club).`)
                        // this.userInvitedMsg = `The Investor with email address ${this.entry.email} is already a member of this club.`
                        // return this.showUserInvitedMsg = true
                    } else if (status == 'applied') {
                        membershipId = membership.data[0].id
                        const confirmedMembership = await apiCalls.patchEntry('/memberships/' + membershipId + '/', {
                            'status': 'active',
                        })
                        if (confirmedMembership) {
                            console.log('confirmedMembership: ', confirmedMembership)
                            let newMems = Number(invitedParticipant.memberships) + 1
                            const amendedPartic = await apiCalls.patchEntry('/participants/' + invitedParticipant.id + '/', {
                                'memberships': newMems,
                            })
                            if (amendedPartic) { // THIS IS DONE TO PRESERVE THE PRIVACY ABOUT THE EMAILS OF USERS, EVEN THOUGH HE HAD ALREADY APPLIED
                                this.inviteSubmitted = false
                                this.textArea = false
                                this.newcomment = ''
                                let invitedEmail1 = this.entry.email
                                this.entry.email = ''
                                return await this.setAlertMsg(`If ${invitedEmail1} is not yet a user, your invitation has been sent to that address.${'\n\n'}Otherwise your invitation has been notified to the corresponding user (unless the user is already a member of this club).`)
                                // this.userInvitedMsg = `If the address ${this.entry.email} is already in use, your invitation has been notified to the user. Otherwise an invitation has been sent to ${this.entry.email}.`
                                // return this.showUserInvitedMsg = true
                            } else {
                                return await this.setAlertMsg(`Sorry, it appears that your invitation could not be sent.${'\n\n'}Please try again or inform the site administrators.`)
                                // this.userInvitedMsg = `Sorry, it appears that your invitation could not be sent. Please try again or inform the site administrators.`
                                // return this.showUserInvitedMsg = true
                            }
                        } else {
                            return await this.setAlertMsg(`Sorry, it appears that your invitation could not be sent.${'\n\n'}Please try again or inform the site administrators.`)
                            // this.userInvitedMsg = `Sorry, it appears that your invitation could not be sent. Please try again or inform the site administrators.`
                            // return this.showUserInvitedMsg = true
                        }
                    } else {
                        membershipId = membership.data[0].id
                        const patchedMembership = await apiCalls.patchEntry('/memberships/' + membershipId + '/', {
                            'status': 'invited',
                        })
                        if (patchedMembership) {
                            console.log('patchedMembership: ', patchedMembership)
                            // this.userInvitedMsg = `If ${this.entry.email} is not in use, your invitation has been sent to that address.${'\n\n'}Otherwise your invitation has been notified to the existing user.`
                            // return this.showUserInvitedMsg = true
                            this.inviteSubmitted = false
                            this.textArea = false
                            this.newcomment = ''
                            let invitedEmail2 = this.entry.email
                            this.entry.email = ''
                            return await this.setAlertMsg(`If ${invitedEmail2} is not yet a user, your invitation has been sent to that address.${'\n\n'}Otherwise your invitation has been notified to the corresponding user (unless the user is already a member of this club).`)

                        }
                    }
                } else {
                    const newMembership = await apiCalls.postEntry('/memberships/', {
                        'participant': invitedParticipant.id,
                        'club': this.entry.clubId,
                        'status': 'invited',
                    })
                    if (newMembership) {
                        console.log('newMembership: ', newMembership)
                        membershipId = newMembership.data.id
                        const notification = await apiCalls.postEntry('/notifications/', {
                            'notifier': store.state.myPid,
                            'notifieralias': store.state.myAlias,
                            'notified': invitedParticipant.id,
                            'club': this.entry.clubId,
                            'clubname': this.entry.clubname,
                            'topic': 'invitation',
                            'status': 'unread',
                            'membership': membershipId,
                            'invitemessage': this.newcomment,
                        })
                        if (notification.data) {
                            // this.userInvitedMsg = `If ${this.entry.email} is not already a user, your invitation has been sent to that email address.${'\n\n'}   Otherwise your invitation has been notified to the existing user.`
                            // return this.showUserInvitedMsg = true
                            this.inviteSubmitted = false
                            this.textArea = false
                            this.newcomment = ''
                            let invitedEmail3 = this.entry.email
                            this.entry.email = ''
                            return await this.setAlertMsg(`If ${invitedEmail3} is not yet a user, your invitation has been sent to that address.${'\n\n'}Otherwise your invitation has been notified to the corresponding user (unless the user is already a member of this club).`)
                        } else {
                            return await this.setAlertMsg(`Sorry, it appears that your invitation could not be sent.${'\n\n'}Please try again or inform the site administrators.`)
                            // this.userInvitedMsg = `Sorry, it appears that your invitation could not be sent. Please try again or inform the site administrators.`
                            // return this.showUserInvitedMsg = true
                        }
                    } else {
                        return await this.setAlertMsg(`Sorry, it appears that your invitation could not be sent.${'\n\n'}Please try again or inform the site administrators.`)
                        // this.userInvitedMsg = `Sorry, it appears that your invitation could not be sent. Please try again or inform the site administrators.`
                        // return this.showUserInvitedMsg = true
                    }
                }
            } catch (err) {
                console.log('Error in inviteExistingUser: ', err)
                return await this.setAlertMsg(`Sorry, it appears that your invitation could not be sent.${'\n\n'}Please try again or inform the site administrators.`)
                // this.userInvitedMsg = `Sorry, it appears that your invitation could not be sent. Please try again or inform the site administrators.`
                // return this.showUserInvitedMsg = true
            }
        },
        // async renameClub() {
        //     try {
        //         if (!this.clubentry.clubname) {
        //             return
        //         }
        //         let endpoint1 = '/clubs/' + store.state.myClub + '/'
        //         const clubres = await apiCalls.patchEntry(endpoint1, this.clubentry)
        //         localStorage.setItem('cad', '2')
        //         await this.setCad('2')
        //         let endpoint2 = '/participants/' + store.state.myPid + '/'
        //         await apiCalls.patchEntry(endpoint2, {
        //             'clubadmin': '2'
        //         })
        //         if (clubres.data.clubname == this.clubentry.clubname) {
        //             this.showMessage1 = true
        //         }
        //     } catch (error) {
        //         console.log('error status: ', error.response.status)
        //     }
        // },
        async removeMember(participant) {
            try {
                let endpoint = '/memberships/' + participant.membershipId + '/'
                let params = {
                    'club': store.state.caid,
                    'participant': participant.id,
                    'status': 'removed'
                }
                const remove = await apiCalls.patchEntry(endpoint, params)
                console.log('response from remove: ', remove.data)

                if (remove) {
                    console.log('response from remove: ', remove.data)
                    let newMems = Number(participant.memberships) - 1
                    const amendedPartic = await apiCalls.patchEntry('/participants/' + participant.id + '/', {
                        'memberships': newMems,
                    })
                    if (amendedPartic) {
                        this.hideRemoveModal()
                        await this.setRfrsh(true)
                        await this.setAlertMsg(`The Investor ${participant.useralias} has been removed from this club.`)
                        // return router.go(0)
                    } else {
                        this.hideRemoveModal()
                        await this.setRfrsh(true)
                        await this.setAlertMsg(`Sorry, it appears that the Investor could not be properly removed.${'\n\n'}Please try again or inform the site administrators.`)
                        // return router.go(0)
                    }
                } else {
                    this.hideRemoveModal()
                    await this.setRfrsh(true)
                    await this.setAlertMsg(`Sorry, it appears that the Investor could not be removed.${'\n\n'}Please try again or inform the site administrators.`)
                    // return router.go(0)
                }
            } catch (error) {
                console.log('error in removeMember: ', error)
                this.hideRemoveModal()
                await this.setRfrsh(true)
                await this.setAlertMsg(`Sorry, it appears that the Investor could not be removed.${'\n\n'}Please try again or inform the site administrators.`)
                // return router.go(0)
            }
        },
        async leaveClub(membershipEntry, clubEntry) {
            try {
                console.log('membership from leave modal: ', membershipEntry)
                const leftClub = await apiCalls.patchEntry('/memberships/' + membershipEntry.id + '/', {
                    'club': clubEntry.id,
                    'participant': store.state.myPid,
                    'status': 'left'
                })
                if (leftClub) {
                    // let myParticData = this.membersData.find(obj => obj.id == store.state.myPid)
                    let newMems = Number(store.state.mymem) - 1
                    console.log('newMems: ', newMems)
                    let amendedPartic = null
                    if (this.topClub == true) {
                        amendedPartic = await apiCalls.patchEntry('/participants/' + store.state.myPid + '/', {
                            'memberships': newMems,
                            'topclubid': null,
                        })
                    } else {
                        amendedPartic = await apiCalls.patchEntry('/participants/' + store.state.myPid + '/', {
                            'memberships': newMems
                        })
                    }
                    if (amendedPartic) {
                        localStorage.setItem('mem', newMems)
                        await this.setMyMem(newMems)
                        console.log('amendedPartic: ', amendedPartic)
                        this.hideLeaveModal()
                        await this.setRfrsh(true)
                        await this.setAlertMsg(`You have left the club ${clubEntry.clubname}.`)
                        // return router.go(0)
                    } else {
                        this.hideLeaveModal()
                        await this.setRfrsh(true)
                        await this.setAlertMsg(`Sorry, it appears that we were not able to properly remove you from the club ${clubEntry.clubname}.${'\n\n'}Please try again or inform the site administrators.`)
                        // return router.go(0)
                    }
                } else {
                    this.hideLeaveModal()
                    await this.setRfrsh(true)
                    await this.setAlertMsg(`Sorry, it appears that we were not able to remove you from the club ${clubEntry.clubname}.${'\n\n'}Please try again or inform the site administrators.`)
                    // return router.go(0)
                }
            } catch (error) {
                console.log('error in leaveClub: ', error)
                this.hideLeaveModal()
                await this.setRfrsh(true)
                await this.setAlertMsg(`Sorry, it appears that we were not able to remove you from the club ${clubEntry.clubname}.${'\n\n'}Please try again or inform the site administrators.`)
                // return router.go(0)
            }
        },
        validEmail() {
            // const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i   // start and end may not be necessary. A-Z0-9 may be replaced by \w. A-Z may be replaced by a-zA-Z and then non need for i at the end 
            const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
            if (!this.entry.email || this.entry.email.length < 1) {
                this.emailError = 'Email is required.'
                return false
            } else if (!regex.test(this.entry.email)) {
                this.emailError = 'Invalid email.'
                return false
            }
            this.entry.email = this.entry.email.toLowerCase()
            return true
        },
        // closeUserInvitedMsg() {  //Eliminate: no longer used - message box replaced by Alert Modal
        //     this.inviteSubmitted = false
        //     this.textArea = false
        //     this.showUserInvitedMsg = false
        //     return this.entry.email = ''
        // },
        async showRemoveModal(entry) {
            try {
                console.log('entry for modal: ', entry)
                // Get the Membership entry
                const membership = await apiCalls.getEntries('/memberships/', {
                    'participant': entry.id,
                    'club': store.state.caid,
                    'status': 'active',
                })
                if (membership && membership.data.length > 0) {
                    this.modalRemoveEntry = {
                        ...entry
                    }
                    this.modalRemoveEntry['membershipId'] = membership.data[0].id
                    return this.bsRemoveModal.show()
                } else {
                    return console.log('Membership not found')
                }
            } catch (err) {
                console.log('error in showRemoveModal: ', err)
            }
        },
        hideRemoveModal() {
            this.bsRemoveModal.hide()
        },
        async showLeaveModal() {
            this.modalLeaveMembership = {}
            this.modalLeaveClub = {}
            // this.modalLeaveMembership = {
            //     club: 7,
            //     id: 302,
            //     participant: 2,
            //     status: "active",

            // }
            // console.log('membership for modal: ', this.modalLeaveMembership)
            try{
                let endPoint = '/clubs/' + store.state.caid + '/' // caid is the 'prop' to the component although comes through the store
                const clubData = await apiCalls.getEntries(endPoint, {})
                if (clubData) {
                    console.log(clubData.data)
                    this.modalLeaveClub = {
                        ...clubData.data
                    }
                    console.log('club for modal: ', this.modalLeaveClub)
                    let params = {
                        'club': store.state.caid,
                        'participant': store.state.myPid,
                    }
                    const membershipData = await apiCalls.getEntries('/memberships/', params)
                    if (membershipData && membershipData.data.length == 1) {
                        this.modalLeaveMembership = {
                            ...membershipData.data[0]
                        }
                        console.log('membership for modal: ', this.modalLeaveMembership)
                        return this.bsLeaveModal.show()
                    }
                }
            }catch(err) {
                console.log('error in showLeaveModal: ', err)
            }
            // setTimeout(()=> { 
            //     console.log('something2')
            //     this.bsLeaveModal.show()
             // }, 3000)
        },
        hideLeaveModal() {
            this.bsLeaveModal.hide()
        },
        onResize() {
            console.log('document.activeElement in ClubAdmin: ', document.activeElement.id)
            if (document.activeElement.id == 'emailBox') {
                window.scrollTo(0, document.body.scrollHeight)
            }
        },
        enlargePic(src, index) {
            if (this.picUrl) {
                this.picUrl = false
            } else {
                let elem = document.getElementById('investorPic-' + index)
                let rect = elem.getBoundingClientRect()
                // this.topPos = document.querySelector('pic-' + index).getBoundingClientRect().top
                // this.leftPos = document.querySelector('pic-' + index).getBoundingClientRect().left
                this.topPos = rect.top - 20
                this.leftPos = rect.left
                this.picUrl = src
                // this.showModalPic = true
            }
        },
        onModalClosed(value) {
            console.log('onmodalClosed: ', value)
            this.picUrl = false
            // this.showModalPic = false
        },
    },
    async created() {
        try {
            this.clubLoading = true
            console.log('creating ClubAdmin')
            // console.log('store.state.myClub: ', store.state.myClub) //TOPCLUB-RELATED LINE
            console.log("localStorage.getItem('mcl'): ", localStorage.getItem('mcl'))
            console.log('store.state.cain: ', store.state.cain)
            console.log("localStorage.getItem('cain'): ", localStorage.getItem('cain'))
            console.log('this.topClub in created1: ', this.topClub)
            this.admin = store.state.cad
            // if (store.state.myClub && store.state.caid && store.state.caid == store.state.myClub) {  //TOPCLUB-RELATED LINES
            //     this.topClub = true
            // } else {
            //     this.topClub = false
            // }
            this.topClub = false //TOPCLUB-RELATED LINES
            console.log('this.topClub in created2: ', this.topClub)
            // await this.getClubData()
            await this.getClubMembersData()
        } catch (error) {
            this.clubLoading = false
            return console.log('error in ClubAdmin created: ', error)
        }
    },
    mounted() {
        this.myRemoveModal = document.getElementById('removeModal')
        this.bsRemoveModal = new Modal(this.myRemoveModal)
        this.myLeaveModal = document.getElementById('leaveModal')
        this.bsLeaveModal = new Modal(this.myLeaveModal)

        window.addEventListener('resize', this.onResize)
    },
    unmounted() {
        window.removeEventListener('resize', this.onResize)
    }
}
</script>

<style scoped>
.main-cont-admin-club {
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 20px;
    /* was 70 */
    margin-bottom: 40px;
    /* was 45 */
}

.custom-btn {
    background-color: #2F5C85 !important;
    color: white !important;
    margin-top: 0px !important;
    margin-bottom: 20px !important;
}

/* ******************* BTN 3D ******************* */
.btn3d {
    border: 0;
    transition: all 40ms linear;
}

.btn3d.btn-primar {
    box-shadow: 0 0 0 1px #2f5b85a1 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

.btn3d.btn-primar:hover {
    box-shadow: 0 0 0 1px #4a95db34 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

/* ******************* END BTN 3D ******************* */
.entry-card {
    background-color: #413e3c !important;
    height: 50px;
    width: 100%;
    margin: 3px auto;
    padding-top: 10px;
    text-align: center;
}

.cell-member {
    /* left text*/
    position: relative;
    /* color: #fff !important; */
    text-decoration-line: none;
    font-size: 1rem;
    text-align: start;

    left: 8px !important;
}

.comment-missing {
    background-color: #272522 !important;
    color: #FFF !important;
    height: 180px;
    /* width: 100%; */
    width: 270px;
    margin: 0 auto;
    margin-top: 0px;
    /* padding-top: 10px; */
    margin-bottom: 0px;
    text-align: start;
    font-size: 1rem;
    font-weight: bold;
    border: solid 1px;
    border-color: #d2d2d9;
}

.btn-tex {
    position: relative;
    top: -8px;
    left: -4px;
    /* padding: 0px;
    font-size: 1.1rem;
    margin-top: 0px;
    margin-left: 7px; */
}

.close-butt {
    height: 25px !important;
    width: 10px !important;
    text-align: start !important;
    color: white;
    /* height: 21px !important;
    width: 21px !important;
    text-align: center !important;
    color: #dddddd; */
    /* font-size: 0.9rem !important; */
    /* padding: 0px !important; */
    /* padding-left: 7px !important; */
    /* font-weight: bold !important;     */
    background-color: #b33430;
    margin-top: 0px !important;
    margin-left: 0px !important;
}

.cell-remove {
    font-size: 1rem;
    text-align: center;
    padding-left: 0px;
    margin-left: -10px;
}

.cell-neu {
    color: #dbd7d4;
}

.warn {
    /* The warning messages which appear in case of error */
    color: #d2d2d9;
    /* color: #faca64e5; */
    font-size: 14px;
    margin-left: 5px;
    padding: 0px;
    width: 260px;
    margin-top: -18px;
}

/* ***** MODAL ****************************************************** */
.modal-area {
    /* background-color: #dbd7d4; */
    background-color: #e4e4e4;
}

.modal-title2 {
    color: #4d4d4d;
}

.modal-body2 {
    color: #4d4d4d;
}

.top-row-modal {
    margin-top: 0px;
}

.modal-row {
    text-align: center;
}

.btn-secondary {
    margin-left: 20px !important;
}

.btn-secondary-modal {
    height: 38px;
    margin-top: -3px !important;
}

.modal-footer {
    display: flex;
    justify-content: center;
}

.modal-btn {
    /* width: 180px; */
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 10px !important;
    height: 38px;
    /* margin-left: -60px !important; */
    margin-top: -3px !important;
    background-color: #2F5C85 !important;
    color: white !important;
    border: none !important;
}

/* ********************************************* */

.leave-club {
    margin-left: 5px;
    margin-top: -24px;
}

.leave-link {
    color: #d9d9d9;
    text-decoration: underline;
}

.invite-btn {
    /* margin-left: 5px; */
    /* margin-right: 5px; */
    margin-top: 24px !important;
    width: 100%;
    height: 38x;
    font-size: 1rem;
}

.users-list {
    margin-top: 20px;
}

.comment-textarea {
    height: 80px !important;
    background-color: #dbd7d4;
    color: #000;
    margin-top: 0px;
    margin-bottom: 5px;
    padding-top: 10px !important;
    font-size: 1rem;
}

.entercomm-btn {
    background-color: #2F5C85 !important;
    height: 38px;
    color: #fff !important;
    margin-bottom: 10px;
    margin-right: 20px;
}

.btn-secondary-comment {
    height: 38px;
    margin-bottom: 10px;
    margin-left: 0px;
}

.invite-warning {
    color: #d2d2d9;
    /* color: #faca64e5; */
    /* font-size: 14px; */
    /* margin-left: 5px; */
    padding: 5px;
    /* width: 260px; */
    margin-top: -13px;
}

.invite-warning2 {
    color: #d2d2d9;
    /* color: #faca64e5; */
    /* font-size: 14px; */
    /* margin-left: 5px; */
    padding: 5px;
    /* width: 260px; */
    /* margin-top: -13px; */
}

.cell-col0 {
    margin-left: 5px;
    margin-right: -5px;
    margin-top: -3px;
}

.user-sticker-round {
    width: 35px;
    margin-top: 0px;
    margin-bottom: 0px;
    border-radius: 50%;
    padding-bottom: 0px;
}
.investor-image {
    height: 35px;
    width: 35px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding-bottom: 0px;
    object-fit: cover;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
}
</style>
