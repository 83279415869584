import axios from 'axios'
import store from '@/store'

const serveUrl = store.state.serverUrl

const apiClient = axios.create({
    baseURL: serveUrl,
    timeout:5000,
    withCredentials: false,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
})
// baseURL: 'https://marqsin.pythonanywhere.com',
// baseURL: 'http://localhost:8000',

apiClient.interceptors.request.use(
    async function(config) {
        await store.dispatch('setIsLoading', true)
        return config
    }
)
apiClient.interceptors.response.use(
    async function (response) {
        await store.dispatch('setIsLoading', false)
        return response
    },
    async function (error) {
        try {
            console.log('error in authCalls: ', error)
            await store.dispatch('setIsLoading', false)
            if (error.response && error.response.data && error.response.data.username && error.response.data.username[0].indexOf('must be unique') >= 0) {
                console.log('USERNAME ALREADY EXISTS!!!')
                await store.dispatch('setRfrsh', true)
                return await store.dispatch('setAlertMsg', `This username is already in use.${'\n\n'}Please enter a different username and try again.`)
            }
        } catch(error2) {
            console.log('error2 in authCalls: ', error2)
            await store.dispatch('setIsLoading', false)
            if (error2.response && error2.response.data && error2.response.data.username && error2.response.data.username[0].indexOf('must be unique') >= 0) {
                console.log('USERNAME ALREADY EXISTS!!!')
                await store.dispatch('setRfrsh', true)
                return await store.dispatch('setAlertMsg', `This username is already in use.${'\n\n'}Please enter a different username and try again.`)
            }
        }

    }
)

export default {
    loginUser(credentials) {
        return apiClient.post('/api/token/', credentials)
    },
    loginEmailUser(credentials) {
        return apiClient.post('/api/token3/', credentials)
    },
    loginNewUser(credentials) {
        return apiClient.post('/api/token2/', credentials)
    },
    registerUser(credentials) {
        return apiClient.post('/selfregister/', credentials)
    },
    // resetUser(edituser) {
    //     return apiClient.get('/user_reset/', edituser)
    // },
    userResetRequest(params) {
        console.log('params_here1: ', params)
        return apiClient.post('/user_reset_request/', {
            params: params   
        })
    },
    userResetCheck(endpoint) {
        console.log('endpoint_check: ', endpoint)
        return apiClient.get(endpoint, {
            params: {}
        })
    },
    userResetComplete(endpoint, params) {
        console.log('params_here3: ', params)
        return apiClient.patch(endpoint, params)
    }
}
// loginUser(user) {  // This was the Login with Basic Authentication
//     return apiClient.get('/login/', {
//         auth: {
//             username: user.username,
//             password: user.password
//         }
//     })
// }