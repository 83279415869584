<template>
<div class="container-flex msg-cont">
    <div class="msg-card">
        <span class="me-4">No Network</span> <span><a href="" @click="$router.push('/')" class="retry-link">TRY AGAIN</a></span>
    </div>
</div>
</template>

<style scoped>
.msg-cont {
    /* margin-top: 100px; */
    background-color: #272522;
    position: absolute;
    top: 180px;
    width: 100%;
}

/* .msg-card {
    width: 95%;
    background-color: #d7d7d7;

    color: #000;
    height: 50px;
    margin: 3px auto;
    padding-top: 13px;
    text-align: center;
    font-size: 1.1rem;
    font-weight: bold;
} */
.msg-card {
    width: 95%;
    background-color: #413e3c !important;
    color: #dbd7d4;
    height: 60px;
    margin: 3px auto;
    padding-top: 15px;
    text-align: center;
    font-size: 1.1rem;
    font-weight: bold;
}

.retry-link {
    /* color: #7FA650 !important; */
    color: #3F7CB3 !important;
    /* text-decoration: none !important; */
    text-decoration: underline;
    font-weight: bold;
    font-size: 1.1rem;
    margin-left: 20px;
}
</style>
