<template>
<div>
<p><b>InvestClub</b> is a platform that allows users to demonstrate their investing skills and to share their investing strategies without exposing any information about themselves or about their wealth.
<!-- <p><b>InvestClub</b> is the app that allows users to demonstrate their investing skills and to share their investment strategies without exposing any information about themselves or about your real wealth. -->

</p><p>Upon joining users receive an amount of "simulated cash" equivalent to $500,000 in their preferred currency. With it they can simulate buying, shorting and selling different assets at real-time prices (stocks, currencies, etc) in order to increase their simulated capital over time.
<!-- </p><p>Upon joining all users receive an equal amount of "simulated cash" equivalent to $500,000 in their preferred currency. With it they simulate buying, shorting and selling different assets at real-time prices (stocks, currencies, etc) in order to increase their simulated capital over time. -->

</p><p>Participants can create investment clubs, develop common strategies, compete and learn from each other, comment their own and other's investments, monitor portfolios without logging into their banking apps.

</p><p>All is needed to join is a valid email address (which remains invisible to others) and a unique username of choice. The platform does not collect any private information, nor does it request access to information stored in the user's device.
<!-- </p><p>New joiners only need to provide a valid email address (which remains invisible to others) and a unique username - we recommend using a fantasy one.  -->

</p><p><b>InvestClub</b> may also be used as a competition game between members of the same club or of the entire platform. However, its developers see it primarily as a brainstorming tool for groups of investors, to share ideas, comments and insights into their investment strategies, in order to keep learning from each other and to become better at investing over time.

</p><p>Have fun using <b>InvestClub</b> and good luck with your real investments!</p>

<p>For desktop, find us at <u>www.investclub.net</u></p>

<!-- FB: APP which allows users to demonstrate their investing skills and share their investing strategies publicly, without exposing any personal or financial information. -->


<!--The Logo
We see investing like planting a forest: a game of patience and dedication which pays back in the long run. 
It is a marathon, not a sprint. Investing wisely allows us to generate individual returns, but at the same 
to drive the allocation of resources towards the initiatives that create the highest utility over time.

Do all our homework as investors and have no fear to share our findings with others. It will help us all to 
improve our ability to allocate capital to the best initiatives.
-->
</div>
</template>

