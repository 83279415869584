<template>
<div class="container my-5">

    <div class="modal fade" id="leaveModal" tabindex="-1" aria-labelledby="leaveModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content modal-area">
                <div class="modal-header">
                    <h5 class="modal-title modal-title2" id="leaveModalLabel">Delete my account</h5>
                    <button type="button" class="btn close-butt" data-bs-dismiss="modal" aria-label="Leave">
                        <div class="btn-tex">
                            x
                        </div>
                    </button>
                </div>
                <div class="modal-body modal-body2">
                    <div class="row top-row-modal">
                        <div class="col">
                            <p>Sure you want to permanently delete your account? If yes, please enter your password and confirm.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col d-flex justify-content-center">
                            <div class="forms-inputs inner-addon modal-input ms-4 mt-3 mb-2 ">
                                <img class="input-icon" src="@/assets/icons/lock.svg" alt="Lock">
                                <input autocomplete="off" :type="showPasswordOld ? 'text' : 'password'" placeholder="Password" v-model="entry.passwordOld" @focus="this.pwdOldError=''; this.pwdMessage='';" @blur="validPasswordOld()" @drop.prevent="false">
                                <img v-if="showPasswordOld" class="pwd-icon right-icon" src="@/assets/icons/eye.svg" @click="showPasswordOld = !showPasswordOld" alt="Lock">
                                <img v-else class="pwd-icon" src="@/assets/icons/eye-off.svg" @click="showPasswordOld = !showPasswordOld" alt="Lock">
                                <div v-if="this.pwdOldError">
                                    <p class="warn warn-modal">{{ this.pwdOldError }}</p>
                                </div>
                                <div v-else-if="this.delprofileMessage">
                                    <p class="warn warn-modal">{{ this.delprofileMessage }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer modal-body2 px-0">
                    <div class="row modal-row">
                        <div class="col">
                            <button type="button" @click.prevent="deleteProfile()" class="btn btn-primary modal-btn">Delete my account</button>
                            <button type="button" class="btn btn-secondary btn-secondary-modal" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="currencyModal" tabindex="-1" aria-labelledby="currencyModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content modal-area">
                <div class="modal-header">
                    <h5 class="modal-title modal-title2" id="currencyModalLabel">Change base currency</h5>
                    <button type="button" class="btn close-butt" data-bs-dismiss="modal" aria-label="Currency">
                        <div class="btn-tex">
                            x
                        </div>
                    </button>
                </div>
                <div class="modal-body modal-body2">
                    <div class="row top-row-modal">
                        <div class="col">
                            <p>Changing base currency is possible. However, doing it may introduce inaccuracies in some historical values. In particular, if you have open investment positions at the time of changing your base currency, the initial FX rate of those investments is replaced by assessed values, which allow the correct evaluation of future p/l's for those investments. For investments already closed and p/l's already generated before the change, as well as the assets in the watchlist, continue to be reported in the old base currency. This has no effect on the value of the portfolio or on the cash balance which remain fully accurate.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col d-flex justify-content-center">

                            <div class="select-box inner-addon mb-3">
                                <img class="curr-icon" src="@/assets/icons/currency.svg" alt="Currency">
                                <label class="form-label ms-1 mb-0 py-0">Select new base currency</label>
                                <select class="form-select curr-select" v-model="entry.basecurrency" @focus="this.currError=''" @change="this.selecteddcurrency = $event.target.value">
                                    <option v-for="option in currencies" :value="option" :key="option">{{ option }}</option>
                                </select>
                                <div v-if="this.currError">
                                    <p class="warn">{{ this.currError }}</p>
                                </div>
                            </div>

                            <!-- <div class="forms-inputs inner-addon modal-input ms-4 mt-3 mb-2 ">
                                <img class="input-icon" src="@/assets/icons/lock.svg" alt="Lock">
                                <input autocomplete="off" :type="showPasswordOld ? 'text' : 'password'" placeholder="Password" v-model="entry.passwordOld" @focus="this.pwdOldError=''; this.pwdMessage='';" @blur="validPasswordOld()">
                                <img v-if="showPasswordOld" class="pwd-icon right-icon" src="@/assets/icons/eye.svg" @click="showPasswordOld = !showPasswordOld" alt="Lock">
                                <img v-else class="pwd-icon" src="@/assets/icons/eye-off.svg" @click="showPasswordOld = !showPasswordOld" alt="Lock">
                                <div v-if="this.pwdOldError">
                                    <p class="warn warn-modal">{{ this.pwdOldError }}</p>
                                </div>
                            </div> -->

                        </div>
                    </div>
                </div>
                <div class="modal-footer modal-body2 px-0">
                    <div class="row modal-row">
                        <div class="col">
                            <button type="button" @click.prevent="hideCurrencyModal(); showConvertModal();" class="btn btn-primary modal-btn">Change currency</button>
                            <button type="button" class="btn btn-secondary btn-secondary-modal" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="convertModal" tabindex="-1" aria-labelledby="convertModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content modal-area">
                <div class="modal-header py-3">
                    <h5 v-if="!this.showWarning" class="modal-title modal-title2" id="convertModalLabel">Change base currency to {{ modalConvertEntry.newcurrency }}</h5>
                    <h5 v-else class="modal-title modal-title2" id="convertModalLabel">Change base currency</h5>
                    <button type="button" class="btn close-butt" data-bs-dismiss="modal" aria-label="Convert" @click="entry.basecurrency = myCur">
                        <div class="btn-tex">
                            x
                        </div>
                    </button>
                </div>
                <div class="modal-body modal-body2 py-0">
                    <div v-if="!this.showWarning" class="row top-row-modal mb-4">
                        <div class="col">
                            In order to change base currency, the cash balance of this profile has to be converted from {{ modalConvertEntry.oldcurrency }} to {{ modalConvertEntry.newcurrency }} at the current FX rate.
                        </div>
                    </div>

                    <div v-if="this.showWarning" class="mod-warn">
                        <!-- <p>  djskdjsk djksjdks djskjdksj djskjdks djskjd jskjdsk djksjdks jdskjd</p> -->
                        <p> {{ this.warningMsg }} </p>
                    </div>

                    <div v-else>

                        <div class="row mid-row-modal modal-row mb-3">
                            <div class="col-6 title-cell">
                                current cash:
                            </div>
                            <div class="col-6 bold-cell">
                                {{ Number(modalConvertEntry.usercash).toLocaleString('en-US') }} {{ modalConvertEntry.oldcurrency }}
                                <!-- 100,000,000.00 {{ modalConvertEntry.oldcurrency }} -->
                            </div>
                        </div>
                        <div class="row mid-row-modal modal-row mb-3">
                            <div class="col-6 title-cell">
                                {{ modalConvertEntry.newcurrency }}/{{ modalConvertEntry.oldcurrency }} ask rate:
                            </div>
                            <div class="col-6 bold-cell">
                                {{ modalConvertEntry.fxrate }}
                            </div>
                        </div>
                        <div class="row mid-row-modal modal-row mb-3">
                            <div class="col-6 title-cell">
                                new cash amount:
                            </div>
                            <div class="col-6 bold-cell">
                                {{ Number(modalConvertEntry.newamount).toLocaleString('en-US') }} {{ modalConvertEntry.newcurrency }}
                                <!-- 100,000,000.00 {{ modalConvertEntry.newcurrency }} -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer modal-body2 px-0">

                    <div class="row modal-row">
                        <div v-if="!this.showWarning" class="col">
                            <button type="button" @click.prevent="convertCurrency()" class="btn btn-primary modal-btn">Switch to {{ modalConvertEntry.newcurrency }}</button>
                            <button type="button" class="btn btn-secondary btn-secondary-modal" data-bs-dismiss="modal" @click="entry.basecurrency = myCur">Cancel</button>
                        </div>
                        <div v-else class="col">
                            <button type="button" class="btn btn-secondary btn-secondary-modal" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>

                    <!-- <div v-if="!this.errorMsg" class="container-fluid d-flex justify-content-center add-mod-warn">
                        {{ this.errorMsg }}
                    </div> -->
                </div>

            </div>
        </div>
    </div>

    <div class="row spacer-row"></div>
    <div class="row justify-content-center">
        <div class="card px-5">
            <div class="row px-0">
                <div class="col-2 px-0">
                    <div>
                        <div v-if="this.myIcon.length == 4"><img class="user-sticker-round" :src="require('@/assets/images/'+ this.myIcon + '_.png')" path="@/assets/images/" alt="user1"></div>
                        <div v-else-if="this.myIcon.length > 4 && !this.mySrc"><img class="user-sticker-round" :src="require('@/assets/images/'+'I-11' + '_.png')" path="@/assets/images/" alt="user1"></div>
                        <div v-else class="preview-icon" :style="{ 'background-image': `url(${this.mySrc})` }"></div>
                        <!-- <div v-else><img class="user-sticker-round" :src="this.mySrc" alt="user1"></div> -->
                    </div>
                </div>
                <div class="col-5 px-0 change-link">
                    <!-- <router-link :to="{ name: 'icons', params: { fromPage: 'profile' } }" class="links"> -->
                    <!-- Change icon -->
                    <router-link :to="{ name: 'icons', params: { use: 'p', partImage: this.mySrc } }" class="links" v-if="this.mySrc">
                        Profile image
                    </router-link>
                    <router-link :to="{ name: 'icons', params: { use: 'p' } }" class="links" v-else>
                        Profile image
                    </router-link>
                </div>
                <div class="col-5 px-0 default-link">
                    <router-link :to="{ name: 'icons', params: { use: 'm' } }" class="links">
                        Menu icon
                    </router-link>
                    <!-- <a href="" @click.prevent="removeIcon()" class="links">Default icon</a> -->

                    <!-- <input style="display: none" type="file" @change="onFileSelected" ref="fileInput" accept="image/png, image/jpeg">
                    <a href="" @click.prevent="$refs.fileInput.click()" class="links">Upload image</a>
                    <button @click.prevent="uploadImage" class="btn upload-btn">Upload</button> -->
                </div>
            </div>
            <div class="divider sep-line mt-3 mb-2"></div>

            <form v-if="usernameForm">
                <div class="row">
                    <div class="col-12 px-2">
                        <div class="forms-inputs inner-addon mb-1">
                            <img class="input-icon" src="@/assets/icons/account.svg" alt="Account">
                            <input autocomplete="off" placeholder="Choose your Username" type="text" v-model="entry.username" @focus="this.usernameError=''; this.usernameMessage='';" @blur="checkUsername()" @drop.prevent="false" @paste.prevent="false">
                            <div v-if="this.usernameError" class="warn">
                                {{ this.usernameError }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3 buttons-row gx-4">
                    <div class="col-8 ps-0">
                        <!-- <div class="btn-pos"> -->
                        <button type="submit" @click.prevent="updateUser('username')" class="btn custom-btn-new btn3d btn-primar">Update username</button>
                        <!-- </div> -->
                    </div>
                    <div class="col pe-0 cancel-col">
                        <!-- <div class="btn-pos"> -->
                        <button type="button" class="btn btn-secondary cancel-btn-new" @click.prevent="usernameForm=false; usernameMessage='';">{{closeBt}}</button>
                        <!-- </div> -->
                    </div>
                </div>
            </form>
            <div v-else class="mb-3 btn-pos"> <button type="submit" @click.prevent="usernameMessage=''; emailMessage=''; pwdMessage=''; emailForm=false; usernameForm=true; passwordForm=false; closeBt='Cancel'" class="btn custom-btn btn3d btn-primar">Change username</button> </div>
            <div v-if="this.usernameMessage" class="updatedMessage">
                <p>{{ this.usernameMessage }}</p>

            </div>
            <div class="divider sep-line mb-2"></div>
            <form v-if="emailForm">
                <div class="row">
                    <div class="col-12 px-2">
                        <div class="forms-inputs inner-addon mb-1">
                            <img class="input-icon" src="@/assets/icons/at.svg" alt="Email">
                            <input autocomplete="off" placeholder="Email for notifications" type="email" v-model="entry.usermail" @focus="this.emailError=''; this.emailMessage='';" @blur="validEmail()" @drop.prevent="false" @paste.prevent="false">
                            <div v-if="this.emailError" class="warn">
                                {{ this.emailError }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3 buttons-row gx-4">
                    <div class="col-8 ps-0">
                        <button type="submit" @click.prevent="updateUser('email')" class="btn custom-btn-new btn3d btn-primar">Update email</button>
                    </div>
                    <div class="col pe-0 cancel-col">
                        <!-- <div class="btn-pos"> -->
                        <button type="button" class="btn btn-secondary cancel-btn-new" @click.prevent="emailForm=false; emailMessage='';">{{closeBt}}</button>
                        <!-- </div> -->
                    </div>
                </div>

            </form>
            <div v-else class="mb-3 btn-pos"> <button type="submit" @click.prevent="usernameMessage=''; emailMessage=''; pwdMessage=''; getUsermail();" class="btn custom-btn btn3d btn-primar">Change email address</button> </div>
            <div v-if="this.emailMessage" class="updatedMessage">
                <p>{{ this.emailMessage }}</p>

            </div>

            <div class="divider sep-line mb-2"></div>

            <!-- <div class="select-box inner-addon mb-3">
                <img class="curr-icon" src="@/assets/icons/currency.svg" alt="Currency">
                <label class="form-label ms-1 mb-0 py-0">Base currency</label>
                <select class="form-select curr-select" v-model="entry.basecurrency" @focus="this.currError=''" @change="showConvertModal($event.target.value)">
                    <option v-for="option in currencies" :value="option" :key="option">{{ option }}</option>
                </select>
                <div v-if="this.currError">
                    <p class="warn">{{ this.currError }}</p>
                </div>
            </div>
            <div class="divider sep-line"></div> -->

            <form v-if="passwordForm">
                <div class="row">
                    <div class="col-12 px-2">
                        <div class="forms-inputs inner-addon mb-1">
                            <img class="input-icon" src="@/assets/icons/lock.svg" alt="Lock">
                            <input autocomplete="off" :type="showPasswordOld ? 'text' : 'password'" placeholder="Old password" v-model="entry.passwordOld" @focus="this.pwdOldError=''; this.pwdMessage='';" @blur="validPasswordOld()" @drop.prevent="false">
                            <img v-if="showPasswordOld" class="pwd-icon right-icon" src="@/assets/icons/eye.svg" @click="showPasswordOld = !showPasswordOld" alt="Lock">
                            <img v-else class="pwd-icon" src="@/assets/icons/eye-off.svg" @click="showPasswordOld = !showPasswordOld" alt="Lock">
                            <div v-if="this.pwdOldError" class="warn">
                                {{ this.pwdOldError }}
                            </div>
                        </div>
                        <div class="forms-inputs inner-addon  mt-3 mb-2 ">
                            <img class="input-icon" src="@/assets/icons/lock.svg" alt="Lock">
                            <input autocomplete="off" :type="showPassword ? 'text' : 'password'" placeholder="New password" v-model="entry.password" @focus="this.pwdError=''; this.pwdMessage='';" @blur="validPassword()" @drop.prevent="false">
                            <img v-if="showPassword" class="pwd-icon right-icon" src="@/assets/icons/eye.svg" @click="showPassword = !showPassword" alt="Lock">
                            <img v-else class="pwd-icon" src="@/assets/icons/eye-off.svg" @click="showPassword = !showPassword" alt="Lock">
                            <div v-if="this.pwdError" class="warn">
                                {{ this.pwdError }}
                            </div>
                        </div>
                        <div class="forms-inputs inner-addon  mt-3 mb-2 ">
                            <img class="input-icon" src="@/assets/icons/lock.svg" alt="Lock">
                            <input autocomplete="off" :type="showPassword2 ? 'text' : 'password'" placeholder="Confirm new Password" v-model="password2" @focus="this.pwd2Error=''; this.pwdMessage='';" @blur="validPassword2()" @drop.prevent="false">
                            <img v-if="showPassword2" class="pwd-icon right-icon" src="@/assets/icons/eye.svg" @click="showPassword2 = !showPassword2" alt="Lock">
                            <img v-else class="pwd-icon" src="@/assets/icons/eye-off.svg" @click="showPassword2 = !showPassword2" alt="Lock">
                            <div v-if="this.pwd2Error" class="warn">
                                {{ this.pwd2Error }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3 buttons-row gx-4">
                    <div class="col-8 ps-0">
                        <!-- <div class="mb-3 btn-pos"> <button @click.stop.prevent="changePwd" class="btn custom-btn">Change password</button> </div> -->
                        <button type="submit" @click.prevent="changePwd" class="btn custom-btn-new btn3d btn-primar">Update password</button>
                    </div>
                    <div class="col pe-0 cancel-col">
                        <!-- <div class="btn-pos"> -->
                        <button type="button" class="btn btn-secondary cancel-btn-new" @click.prevent="passwordForm=false; pwdMessage='';">{{closeBt}}</button>
                        <!-- </div> -->
                    </div>
                </div>

            </form>
            <div v-else class="mb-3 btn-pos"> <button type="submit" @click.prevent="usernameMessage=''; emailMessage=''; pwdMessage=''; emailForm=false; usernameForm=false; entry.passwordOld=''; entry.password=''; password2=''; pwdMessage=''; passwordForm=true; closeBt='Cancel'" class="btn custom-btn btn3d btn-primar">Change password</button> </div>
            <div v-if="this.pwdMessage" class="updatedMessage">
                <p>{{ this.pwdMessage }}</p>
            </div>

            <div class="divider sep-line"></div>

            <div class="row">
                <div class="col pt-3 px-0 switch-row">
                    <div class="form-check form-switch">
                        <BaseInputSwitch label="Show comments only to clubs" v-model="this.hideComments" @update:modelValue="HideComments()" />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col pt-3 px-0 mt-1 switch-row">
                    <div class="form-check form-switch">
                        <BaseInputSwitch v-if="this.hideComments==false" label="Allow comments only from clubs" v-model="this.stopComments" @update:modelValue="StopComments()" />
                        <BaseInputSwitch2 v-else label="Allow comments only from clubs" v-model="this.stopComments" />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col pt-3 px-0 mt-1 switch-row">
                    <div class="form-check form-switch">
                        <!-- <BaseInputSwitch label="No new invitations to join clubs" @change="StopInvite($event.target.value)" v-model="this.stopInvite" /> -->
                        <BaseInputSwitch label="No new invitations to clubs" v-model="this.stopInvite" @update:modelValue="StopInvite()" />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col pt-3 px-0 mt-1 switch-row">
                    <div class="form-check form-switch">
                        <BaseInputSwitch label="No private messages" v-model="this.stopMessages" @update:modelValue="StopMessages()" />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col pt-3">
                    <span ref="targetArea" class="delete-link">Base currency: {{ this.entry.basecurrency }} </span> <span class="delete-link2">(<a href="" @click.prevent="showCurrencyModal()" class="delete-link3">change</a>)</span>
                </div>
            </div>

            <div class="row social-row g-1 mt-4">
                <div class="col-2">
                    <img :src="require('@/assets/'+ 'logo.png')" path="@/assets/" class="social-img">
                </div>
                <div v-if="username" class="col-10 col-profile">
                    <a href="" @click.prevent="visitSummary()" class="delete-link3">investclub.net/{{username.toLowerCase()}}</a>
                </div>
            </div>

            <div class="row" v-if="!showSocial">
                <div class="col pt-3 delete-link">
                    <a href="" @click.prevent="showSocialRows()" class="delete-link3">My social media profiles</a>
                </div>
            </div>
            <div class="row" v-else>
                <div class="col pt-3 delete-link">
                    <a href="" @click.prevent="showSocialRows(); showSocialButt = []" class="delete-link3">Hide social media</a>
                </div>
            </div>
            <div v-show="showSocial">
                <div class="row p-1">
                    <div class="col p-1 warn-social">
                        If entered, your social media profiles will be visible to all users at the top of your summary page

                    </div>
                </div>
            </div>

            <div v-for="(platform, index) in platforms" :key="index">
                <div class="row social-row g-1" v-show="showSocial">
                    <div class="col-2">
                        <img :src="require('@/assets/images/'+ platform.icon + '.png')" path="@/assets/images/" :alt="platform.name" class="social-img">
                    </div>
                    <!-- <div class="col-6 social-url">
                    https://instagram/
                </div> -->
                    <div class="col-10 col-social-input">
                        <input v-model="platform.profileUrl" autocomplete="off" :placeholder="platform.genericUrl" type="text" @focus="tempUrl=platform.profileUrl; placeCursor(index)" class="social-input" :id="'socialbox-'+index" />
                    </div>
                    <!-- <div class="col-2 col-alert-enter">
                    <button role="button" @click.prevent="enterAlert(entry, index, 'above', true)" class="btn btn-primar btn-alert-enter btn3d">Enter</button>
                </div>
                <div class="col-2 col-alert-cancel">
                    <button role="button" @click.prevent="cancelAlert(entry, index, 'above')" class="btn btn-primar btn-alert-cancel">Cancel</button>
                </div> -->
                </div>
                <div class="row social-buttons-row g-0" v-show="showSocialButt[index]">
                    <div class="col col-social-button1 col-auto">
                        <button role="button" @click.prevent="removeSocial(index)" class="btn btn-primar btn-social-remove ">Remove</button>
                    </div>
                    <div class="col col-social-button2 col-auto">
                        <button role="button" @click.prevent="cancelSocial(index)" class="btn btn-primar btn-social-cancel">Cancel</button>
                    </div>
                    <div class="col col-social-button3 col-auto">
                        <button role="button" @click.prevent="enterSocial(index)" class="btn btn-primar btn-social-enter btn3d">Enter</button>
                    </div>

                </div>
            </div>

            <div class="row mt-3">
                <div class="col pt-3">
                    <a href="" @click.prevent="showDeleteModal()" class="delete-link">Delete my InvestClub account</a>
                </div>
            </div>

            <div v-if="this.showBlocked">

                <div class="divider sep-line-low"></div>

                <!-- <div class="row d-flex justify-content-center"> -->
                <div class="container-fluid">

                    <div v-for="entry in this.blockedList" :key="entry.id" class="card entry-card my-1">
                        <div class="row">
                            <div class="col-8 cell-member cell-neu">
                                {{ entry.blockedalias}}
                            </div>

                            <div class="col-4 closepos-cell">
                                <a class="cell-neu" href="" @click.prevent="unBlock(entry)">Unblock</a>
                            </div>

                        </div>

                    </div>
                </div>

                <!-- </div> -->
            </div>

        </div>
    </div>
</div>
</template>

<script>
import store from '@/store'
import {
    mapActions
} from 'vuex'
import {
    Modal
} from 'bootstrap'
import router from '@/router'
import apiCalls from '@/services/apiCalls.js'
import yfapiCalls from '@/services/yfapiCalls.js'
export default {
    name: 'UserProfile',
    data() {
        return {
            password2: '',
            showPassword: false,
            showPassword2: false,
            showPasswordOld: false,
            usernameError: '',
            pwdError: '',
            pwd2Error: '',
            pwdOldError: '',
            emailError: '',
            currError: '',
            usernameMessage: '',
            emailMessage: '',
            pwdMessage: '',
            delprofileMessage: '',
            validProfile: false,
            validPwd: false,
            username: '',
            usermail: '',
            usericon: '',
            entry: {
                username: null,
                usermail: null,
                password: null,
                passwordOld: null,
                usercash: 0,
                startcash: 0,
                userportfolio: 0,
                basecurrency: '',
                accruedpl: 0,
                fees: 0,
                dividends: 0,
                // loading: false,
                // usericon: null,
            },
            currencies: [
                // 'Change base currency',
                'USD',
                'EUR',
                'GBP',
                'CHF',
                'AUD',
                'CAD',
            ],
            hideComments: false,
            stopComments: false,
            stopInvite: false,
            stopMessages: false,
            showBlocked: false,
            blockedList: [],

            modalConvertEntry: {},
            showWarning: false,
            warningMsg: '',
            errorMsg: '',
            actionFx: null,
            fxlabel: 'fx',
            selectedcurrency: '',
            selectedFile: null,
            image: null,
            // loading: true,
            mySrc: null,
            usernameForm: false,
            emailForm: false,
            passwordForm: false,
            cloaseBt: 'Cancel',
            platforms: [{
                    name: 'Twitter',
                    icon: 'twitter',
                    genericUrl: 'twitter.com/',
                    profileUrl: '',
                    profileUrl2: '',
                    profileAlias: '',
                },
                {
                    name: 'LinkedIn',
                    icon: 'linkedin',
                    genericUrl: 'linkedin.com/',
                    profileUrl: '',
                    profileUrl2: '',
                    profileAlias: '',
                },
                {
                    name: 'Facebook',
                    icon: 'facebook',
                    genericUrl: 'facebook.com/',
                    profileUrl: '',
                    profileUrl2: '',
                    profileAlias: '',
                },
                {
                    name: 'Instagram',
                    icon: 'instagram',
                    genericUrl: 'instagram/',
                    profileUrl: '',
                    profileUrl2: '',
                    profileAlias: '',
                },
                {
                    name: 'YouTube',
                    icon: 'youtube',
                    genericUrl: 'youtube.com/',
                    profileUrl: '',
                    profileUrl2: '',
                    profileAlias: '',
                },
                {
                    name: 'TikTok',
                    icon: 'tiktok',
                    genericUrl: 'tiktok.com/',
                    profileUrl: '',
                    profileUrl2: '',
                    profileAlias: '',
                },
                {
                    name: 'Reddit',
                    icon: 'reddit',
                    genericUrl: 'reddit.com/',
                    profileUrl: '',
                    profileUrl2: '',
                    profileAlias: '',
                }
            ],
            showSocial: false,
            showSocialButt: [],
            tempUrl: '',
        }
    },
    computed: {
        myIcon() {
            // if (store.state.myIcon.length > 4) {
            //     return 'I-11'
            // } else {
            //     return store.state.myIcon
            // }
            return store.state.myIcon
        },
        myCur() {
            return store.state.myCur
        }
    },
    methods: {
        ...mapActions(['setMyAlias', 'setAlertMsg', 'setRfrsh', 'setMyCur', 'setParticId', 'setParticIcon', 'setParticAlias']),
        async visitSummary() {
            try {
                console.log('routing to summary page')
                await this.setParticId(store.state.myPid)
                await this.setParticIcon(this.usericon)
                await this.setParticAlias(this.username)
                localStorage.setItem('paid', store.state.myPid)
                localStorage.setItem('paic', this.usericonn)
                localStorage.setItem('paal', this.username)

                return router.push({
                    name: 'displayinvestor',
                    params: {
                        investor: store.state.myPid
                    }
                })
            } catch (e) {
                console.log(e)
            }
        },
        showSocialRows() {
            this.showSocial = !this.showSocial
            setTimeout(() => {
                return document.getElementById('socialbox-4').scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                })
            }, 0)
        },
        placeCursor(index) {
            // console.log('this.tempUrl1: ', this.tempUrl)
            this.showSocialButt = []
            this.platforms[index].profileUrl = ''
            this.platforms[index].profileUrl = this.platforms[index].genericUrl
            const input = document.getElementById('socialbox-' + index)
            const end = this.platforms[index].genericUrl.length
            setTimeout(() => {
                input.setSelectionRange(end, end)
            }, 0)
            this.showSocialButt[index] = true
        },
        cancelSocial(index) {
            // console.log('this.platforms[index].profileUrl: ', this.platforms[index].profileUrl)
            console.log('this.tempUrl: ', this.tempUrl)
            if (this.platforms[index].profileUrl == this.platforms[index].genericUrl && this.tempUrl == '') {
                this.platforms[index].profileUrl = ''
            } else if (this.platforms[index].profileUrl == this.platforms[index].genericUrl && this.tempUrl != '') {
                this.platforms[index].profileUrl = this.tempUrl
            } else if (this.platforms[index].profileUrl != this.tempUrl) {
                // console.log('this.tempUrl: ', this.tempUrl)
                this.platforms[index].profileUrl = this.tempUrl
            }
            this.showSocialButt = []
        },
        async enterSocial(index) {
            try {
                console.log('this.platforms[index].profileUrl: ', this.platforms[index].profileUrl)
                console.log('this.platforms[index].genericUrl: ', this.platforms[index].genericUrl)
                if (this.platforms[index].profileUrl == this.platforms[index].genericUrl || this.platforms[index].profileUrl == '') {
                    this.platforms[index].profileUrl = ''
                    // } else {
                }
                console.log('evaluate this.platforms[index].profileUrl: ', this.platforms[index].profileUrl)
                let profileAlias = this.platforms[index].profileUrl.replace(this.platforms[index].genericUrl, '')
                if (this.validProfileUrl(this.platforms[index], index, profileAlias)) {
                    let profilesList = ''
                    for (let i = 0; i < 5; i++) {
                        if (this.platforms[i].profileUrl != '') {
                            this.platforms[i].profileAlias = this.platforms[i].profileUrl.replace(this.platforms[i].genericUrl, '')
                            if (profilesList == '') {
                                profilesList = profilesList + i + '-' + this.platforms[i].profileAlias
                            } else {
                                profilesList = profilesList + ',' + i + '-' + this.platforms[i].profileAlias
                            }
                        }
                    }
                    console.log('profilesList: ', profilesList)
                    if (profilesList != this.entry.profiles) {
                        const updatedSocial = await apiCalls.patchEntry('/participants/' + store.state.myPid + '/', {
                            'profiles': profilesList
                        })

                        if (updatedSocial) {
                            console.log('updatedSocial: ', updatedSocial)
                        } else {
                            console.lof('Could not update participant profiles')
                        }
                    }
                } else {
                    console.log('Profile not valid')
                }
                // }
                this.showSocialButt = []
            } catch (e) {
                console.log(e)
            }
        },
        async removeSocial(index) {
            try {
                this.platforms[index].profileUrl = ''
                let profileAlias = this.platforms[index].profileUrl.replace(this.platforms[index].genericUrl, '')
                if (this.validProfileUrl(this.platforms[index], index, profileAlias)) {
                    let profilesList = ''
                    for (let i = 0; i < 5; i++) {
                        if (this.platforms[i].profileUrl != '') {
                            this.platforms[i].profileAlias = this.platforms[i].profileUrl.replace(this.platforms[i].genericUrl, '')
                            if (profilesList == '') {
                                profilesList = profilesList + i + '-' + this.platforms[i].profileAlias
                            } else {
                                profilesList = profilesList + ',' + i + '-' + this.platforms[i].profileAlias
                            }
                        }
                    }
                    console.log('profilesList: ', profilesList)
                    if (profilesList != this.entry.profiles) {
                        const updatedSocial = await apiCalls.patchEntry('/participants/' + store.state.myPid + '/', {
                            'profiles': profilesList
                        })

                        if (updatedSocial) {
                            console.log('updatedSocial: ', updatedSocial)
                        } else {
                            console.lof('Could not update participant profiles')
                        }
                    }
                } else {
                    console.log('Profile not valid')
                }
                this.showSocialButt = []
            } catch (e) {
                console.log(e)
            }
        },
        validProfileUrl(platformData, index) {
            console.log(platformData, index)
            return true
        },
        generateHexString(len) {
            let output = ''
            for (let i = 0; i < len; ++i) {
                output += (Math.floor(Math.random() * 16)).toString(16).toUpperCase()
            }
            console.log('output: ', output)
        },
        async StopInvite() {
            console.log('this.stopInvite: ', this.stopInvite)
            try {
                let stopInvitations = ''
                if (this.stopInvite == 0) {
                    stopInvitations = 0
                } else {
                    stopInvitations = 1
                }
                console.log('stopInvitations: ', stopInvitations)
                const updatedProfile = await apiCalls.patchEntry('/participants/' + store.state.myPid + '/', {
                    'stopinvite': stopInvitations

                })
                if (updatedProfile) {
                    console.log('profile updated: ', updatedProfile)
                }
            } catch (err) {
                console.log('error in StopInvite: ', err)
            }
        },
        async StopMessages() {
            console.log('this.stopMessages ', this.stopMessages)
            try {
                let stopMessaging = ''
                if (this.stopMessages == 0) {
                    stopMessaging = 0
                } else {
                    stopMessaging = 1
                }
                console.log('stopMessaging: ', stopMessaging)
                const updatedProfile = await apiCalls.patchEntry('/participants/' + store.state.myPid + '/', {
                    'stopmessages': stopMessaging

                })
                if (updatedProfile) {
                    console.log('profile updated: ', updatedProfile)
                }
            } catch (err) {
                console.log('error in StopMessages: ', err)
            }
        },
        async StopComments() {
            console.log('this.stopComments: ', this.stopComments)
            try {
                let stopUnknownComments = ''
                if (this.stopComments == 0) {
                    stopUnknownComments = 0
                } else {
                    stopUnknownComments = 1
                }
                console.log('stopUnknownComments: ', stopUnknownComments)
                const updatedProfile = await apiCalls.patchEntry('/participants/' + store.state.myPid + '/', {
                    'stopcomments': stopUnknownComments
                })
                if (updatedProfile) {
                    console.log('profile updated: ', updatedProfile)
                } else {
                    console.log('could not update profile in StopComments!!!)')
                }
            } catch (err) {
                console.log('error in StopComments: ', err)
            }
        },
        async HideComments() {
            console.log('this.hideComments: ', this.hideComments)
            try {
                let hideUnknownComments = ''
                let stopUnknownComments = ''
                if (this.hideComments == 0) {
                    hideUnknownComments = 0
                    this.stopComments = false // OPTIONAL, depending on desired behavior when showing comments again
                    stopUnknownComments = 0 // OPTIONAL, depending on desired behavior when showing comments again
                } else {
                    this.stopComments = true
                    hideUnknownComments = 1
                    stopUnknownComments = 1
                }
                console.log('hideUnknownComments: ', hideUnknownComments)
                console.log('stopUnknownComments: ', stopUnknownComments)
                const updatedProfile = await apiCalls.patchEntry('/participants/' + store.state.myPid + '/', { //RESTORE
                    'hidecomments': hideUnknownComments, //RESTORE
                    'stopcomments': stopUnknownComments, //RESTORE
                }) //RESTORE
                if (updatedProfile) {
                    console.log('profile updated: ', updatedProfile)
                } else {
                    console.log('could not update profile in HideComments!!!)')
                }
            } catch (err) {
                console.log('error in HideComments: ', err)
            }
        },
        async getUsermail() {
            try {
                // Below was the old getUser()
                // let endPoint = '/users/' + localStorage.getItem('uid') + '/'

                // let endPoint = '/users/' + store.state.myUid + '/' // RECENT
                // const Entries = await apiCalls.getEntries(endPoint, {})
                // let user = Entries.data
                // this.entry.username = user['username']
                // this.username = user['username']
                // console.log('user: ', user)

                //***************************************************************
                const myLtdu = await apiCalls.getEntries('/ltdu/', {
                    pid: store.state.myPid
                })
                if (myLtdu && myLtdu.data.length > 0) {
                    console.log('myLtdu.data: ', myLtdu.data)
                    this.entry.usermail = myLtdu.data[0]['email']
                    this.usermail = myLtdu.data[0]['email']
                    this.emailForm = true
                    this.usernameForm = false
                    this.passwordForm = false
                    this.closeBt = 'Cancel'
                } else {
                    return await this.setAlertMsg('Email not found. Connection error or you don\'t have authorization. Please try again')
                }
                //****************************************************************************

            } catch (error) {
                return console.log('error in getUsermail: ', error)
            }
        },
        async getParticipant() {
            try {
                let endPoint = '/participants/' + localStorage.getItem('pid') + '/'
                const Participant = await apiCalls.getEntries(endPoint, {})
                if (Participant && Participant.data) {
                    let participant = Participant.data
                    //***************************************************************
                    const myLtdu = await apiCalls.getEntries('/ltdu/', {
                        pid: participant.id
                    })
                    if (myLtdu && myLtdu.data.length > 0) {
                        this.entry.usermail = myLtdu.data[0]['email']
                        this.usermail = myLtdu.data[0]['email']
                    } else {
                        return await this.setAlertMsg('Email not found. Connection error or you don\'t have authorization. Please try again')
                    }
                    //****************************************************************************
                    // this.entry.usermail = participant['usermail']  // Usermail not anymore in Participant, replaced with the above - new-eliminated 0810
                    this.entry.username = participant['useralias']
                    this.username = participant['useralias']
                    this.usericon = participant['usericon']

                    this.entry.basecurrency = participant['basecurrency']
                    this.entry.usercash = Number(participant['usercash'])
                    this.entry.startcash = Number(participant['startcash'])
                    this.entry.userportfolio = Number(participant['userportfolio'])
                    // this.usermail = myLtdu.data[0]['email']
                    // this.usermail = participant['usermail'] // Usermail not anymore in Participant, replaced with the above - new-eliminated 0810
                    this.stopInvite = participant['stopinvite']
                    this.hideComments = participant['hidecomments']
                    this.stopComments = participant['stopcomments']
                    this.stopMessages = participant['stopmessages']
                    this.entry.accruedpl = participant['accruedpl']
                    this.entry.fees = participant['fees']
                    this.entry.dividends = participant['dividends']
                    this.entry.profiles = participant['profiles']
                    if (this.entry.profiles) {
                        let profilesList = this.entry.profiles.split(',')
                        let index = 0
                        console.log('profilesList in getParticipant: ', profilesList)
                        profilesList.forEach(profile => {
                            index = parseInt(profile.charAt(0))
                            console.log('index: ', index)
                            this.platforms[index].profileUrl = this.platforms[index].genericUrl + profile.substring(2)
                            console.log('profile: ', index, this.platforms[index].profileUrl)
                        })
                    }
                    // if (participant['usericon'].length > 4 && store.state.serverUrl == 'http://localhost:8000') {
                    if (participant['usericon'].length > 4) {
                        let path = store.state.serverUrl + '/getimage/'
                        // this.mySrc = path + store.state.myPid + '/'
                        this.mySrc = path + participant['usericon'] + '/'
                        console.log('this.mySrc: ', this.mySrc)
                    }
                    console.log('participant: ', participant)
                } else {
                    return console.log('could not retrieve participant data')
                }
            } catch (error) {
                return console.log('error status: ', error)
            }
        },
        async updateUser(field) {
            this.validateProfile(field)
            if (this.validProfile) {
                try {
                    let edituser = {}
                    let editparticipant = {}
                    let failed1 = false
                    let failed2 = false
                    if (field == 'username') {
                        if (this.entry.username && this.entry.username != store.state.myAlias) {
                            edituser['username'] = this.entry.username
                            editparticipant['useralias'] = this.entry.username
                        } else {
                            return this.usernameMessage = "Username not updated" // INFORM THAT THE USERNAME IS MISSING OR HAS NOT BEEN CHANGED
                            // edituser['username'] = store.state.myAlias
                        }
                    } else if (field == 'email') {
                        if (this.entry.usermail && this.entry.usermail != this.usermail) {
                            edituser['email'] = this.entry.usermail
                            // editparticipant['usermail'] = this.entry.usermail  // Not needed anymore in Participant - new-eliminated 0810
                            // } else if (this.usermail) {
                            //     edituser['email'] = this.usermail
                        } else {
                            return this.emailMessage = "Email not updated" // INFORM THAT THE USERNAME IS MISSING OR HAS NOT BEEN CHANGED
                        }
                    } else {
                        return this.usernameMessage = "Profile not updated" // INFORM THAT THE USERNAME IS MISSING OR HAS NOT BEEN CHANGED
                    }
                    console.log('edituser: ', edituser)
                    console.log('editparticipant: ', editparticipant)
                    if ((field == 'email' && edituser) || (field == 'username' && edituser && editparticipant)) {
                        console.log('edituser: ', edituser)
                        // let endpoint1 = '/user_update/' + localStorage.getItem('uid') + '/'
                        let endpoint1 = ''
                        if (field == 'username') {
                            endpoint1 = '/username_update/' + store.state.myUid + '/'
                        } else if (field == 'email') {
                            endpoint1 = '/usermail_update/' + store.state.myUid + '/'

                        }
                        // let endpoint1 = '/user_update/' + store.state.myUid + '/'
                        console.log('endpoint1: ', endpoint1)
                        const userUpdated = await apiCalls.patchEntry(endpoint1, edituser)
                        console.log('userUpdated: ', userUpdated)
                        if (field == 'username' && !userUpdated) {
                            return this.usernameMessage = "Username not updated"
                        } else if (field == 'email' && !userUpdated) {
                            return this.emailMessage = "Email not updated"
                        }
                        if (userUpdated.request.status < 200 || userUpdated.request.status > 299) {
                            failed1 = true
                        } else {
                            // if (field == 'username' && this.entry.username && this.entry.username != store.state.myAlias) { // already checked earlier
                            if (field == 'username') {
                                this.username = this.entry.username
                                localStorage.setItem('pal', this.entry.username)
                                await this.setMyAlias(this.entry.username)

                                // } else if (field == 'email' && this.entry.usermail && this.entry.usermail != this.usermail) { // already checked earlier
                            } else if (field == 'email') {
                                this.closeBt = 'Close'
                                this.emailMessage = "Email updated successfully"
                                return this.usermail = this.entry.usermail
                            }
                        }

                        if (field == 'username' && userUpdated && editparticipant && failed1 == false) {
                            // let endpoint2 = '/participants/' + localStorage.getItem('pid') + '/'
                            // let editParticipant = {}
                            let endpoint2 = '/participants/' + store.state.myPid + '/'
                            // let editParticipant = {
                            //     ...{
                            //         'usermail': this.entry.usermail
                            //     }
                            // }
                            const participantUpdated = await apiCalls.patchEntry(endpoint2, editparticipant)
                            console.log('participantUpdated: ', participantUpdated)
                            if (!participantUpdated) {
                                failed2 = true
                            } else if (participantUpdated.request.status < 200 || participantUpdated.request.status > 299) {
                                failed2 = true
                            }
                        } else if (field == 'username') {
                            return this.usernameMessage = "Username not updated"
                        }
                    } else {
                        if (field == 'username') {
                            return this.usernameMessage = "Username not updated"
                        } else if (field == 'email') {
                            return this.emailMessage = "Email not updated"
                        }
                    }
                    console.log('failed: ', failed1, ' ', failed2)
                    if (failed1 || failed2) {
                        return this.usernameMessage = "Could not update profile"
                    } else {
                        this.closeBt = 'Close'
                        return this.usernameMessage = "Username updated successfully"
                    }
                    // return router.push('/')
                } catch (err) {
                    console.log('error in updateUser: ', err)
                }
            }
        },
        async changePwd() {
            this.validatePwd()
            if (this.validPwd) {
                try {
                    let editpwd = {}
                    editpwd['password'] = this.entry.password
                    editpwd['old_password'] = this.entry.passwordOld
                    console.log('editpwd: ', editpwd)
                    // let endpoint = '/user_changepwd/' + localStorage.getItem('uid') + '/'
                    let endpoint = '/user_changepwd/' + store.state.myUid + '/'
                    const resp = await apiCalls.patchEntry(endpoint, editpwd)
                    console.log('respPwd: ', resp)
                    if (resp && resp.request && resp.request.status > 199 && resp.request.status < 300) {
                        //     var successPwd = true
                        // }
                        // if (successPwd) {
                        this.entry.passwordOld = ''
                        this.entry.password = ''
                        this.password2 = ''
                        this.closeBt = 'Close'
                        return this.pwdMessage = "Password changed successfully"
                    } else {
                        return this.pwdMessage = "Could not change password"
                    }
                    // return router.push('/')
                } catch (err) {
                    console.log('error status: ', err)
                }
            }
        },
        validateProfile(field) {
            if (field == 'username' && this.validUsername()) {
                this.validProfile = true
            } else if (field == 'email' && this.validEmail()) {
                this.validProfile = true
            } else {
                this.validProfile = false
            }
        },
        validatePwd() {
            if (this.validPasswordOld() && this.validPassword() && this.validPassword2() && this.passwordsMatch()) {
                this.validPwd = true
            }
        },
        async checkUsername() {
            if (!this.validUsername()) {
                return
            }
            if (this.entry.username == store.state.myAlias) {
                return
            }
            console.log('Checking if username unique')
            const usernameExists = await apiCalls.getEntries('/participants/', {
                'useralias': this.entry.username
            })
            if (usernameExists && usernameExists.data.length > 0) {
                console.log(usernameExists)
                return this.usernameMessage = `The username ${this.entry.username} is already in use, please choose a different one.`
                // document.getElementById("username-box").focus()
            } else {
                return console.log(usernameExists)
            }
        },
        validUsername() {
            // const regex = /^.{3,5}$/ // 3 to 5 characters long
            // const regex = /^[a-zA-Z]\w{2,4}]$/ // 3 to 5, starting with a letter
            const regex1 = /^[a-zA-Z0-9_-]+$/ // only letters numbers, underscore - minimum 1 character (otherwise * instead of +)
            const regex2 = /^[a-zA-Z]/ // must start with a letter
            // const regex = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/  // At least 1 lower, one upper and one number
            if (!this.entry.username || this.entry.username.length < 1) {
                this.usernameError = 'Username is required.'
                // this.usernameError = 'Invalid username'
                return false
            } else if (this.entry.username.length < store.state.minUsername) {
                this.usernameError = `Minimum ${store.state.minUsername} characters.`
                // this.usernameError = 'Invalid username'
                return false
            } else if (this.entry.username.length > store.state.maxUsername) {
                this.usernameError = `Maximum ${store.state.maxUsername} characters.`
                // this.usernameError = 'Invalid username'
                return false
            } else if (!regex1.test(this.entry.username)) {
                this.usernameError = 'Only latin letters, numbers, underscore, dash.'
                // this.usernameError = 'Invalid username'
                return false
            } else if (!regex2.test(this.entry.username)) {
                this.usernameError = 'Must start with a letter.'
                // this.usernameError = 'Invalid username'
                return false
            }
            return true
        },
        validEmail() {
            // const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i   // start and end may not be necessary. A-Z0-9 may be replaced by \w. A-Z may be replaced by a-zA-Z and then non need for i at the end
            const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
            if (!this.entry.usermail || this.entry.usermail.length < 1) {
                this.emailError = 'Email is required.'
                return false
            } else if (!regex.test(this.entry.usermail)) {
                this.emailError = 'Invalid email.'
                return false
            }
            this.entry.usermail = this.entry.usermail.toLowerCase()
            return true
        },
        validCurrency() {
            if (this.entry.basecurrency == 'Select base currency' || this.entry.basecurrency.length != 3) {
                this.currError = 'Please enter your base currency'
                return false
            }
            return true
        },
        validPassword() {
            const regex1 = /^[a-zA-Z0-9_]+$/ // only letters numbers, underscore - minimum 1 character (otherwise * instead of +)
            const regex2 = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/ // At least 1 lower, one upper and one number
            const regex3 = /^[a-zA-Z]/ // must start with a letter
            if (!this.entry.password || this.entry.password.length < 1) {
                this.pwdError = 'Password is required.'
                // this.pwdError = 'Invalid password'
                return false
            } else if (this.entry.password.length < 8) {
                this.pwdError = 'Minimum 8 characters.'
                // this.pwdError = 'Invalid password'
                return false
            } else if (this.entry.password.length > 16) {
                this.pwdError = 'Maximum 16 characters.'
                // this.pwdError = 'Invalid password'
                return false
            } else if (!regex1.test(this.entry.password)) {
                this.pwdError = 'Only latin letters, numbers and underscore.'
                // this.pwdError = 'Invalid password'
                return false
            } else if (!regex2.test(this.entry.password)) {
                this.pwdError = 'At least one lower case, one upper case and one number.'
                // this.pwdError = 'Invalid password'
                return false
            } else if (!regex3.test(this.entry.password)) {
                this.pwdError = 'Must start with a letter.'
                // this.pwdError = 'Invalid password'
                return false
            }
            return true
        },
        validPassword2() {
            const regex1 = /^[a-zA-Z0-9_]+$/ // only letters numbers, underscore - minimum 1 character (otherwise * instead of +)
            const regex2 = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/ // At least 1 lower, one upper and one number
            const regex3 = /^[a-zA-Z]/ // must start with a letter
            if (!this.password2 || this.password2.length < 1) {
                this.pwd2Error = 'Password is required.'
                // this.pwd2Error = 'Invalid password'
                return false
            } else if (this.password2.length < 8) {
                this.pwd2Error = 'Minimum 8 characters.'
                // this.pwd2Error = 'Invalid password'
                return false
            } else if (this.password2.length > 16) {
                this.pwd2Error = 'Maximum 16 characters.'
                // this.pwd2Error = 'Invalid password'
                return false
            } else if (!regex1.test(this.password2)) {
                this.pwd2Error = 'Only latin letters, numbers and underscore.'
                // this.pwd2Error = 'Invalid password'
                return false
            } else if (!regex2.test(this.password2)) {
                this.pwd2Error = 'At least one lower case, one upper case and one number.'
                // this.pwd2Error = 'Invalid password'
                return false
            } else if (!regex3.test(this.password2)) {
                this.pwd2Error = 'Must start with a letter.'
                // this.pwdError = 'Invalid password'
                return false
            }
            return true
        },
        validPasswordOld() {
            const regex1 = /^[a-zA-Z0-9_]+$/ // only letters numbers, underscore - minimum 1 character (otherwise * instead of +)
            const regex2 = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/ // At least 1 lower, one upper and one number
            const regex3 = /^[a-zA-Z]/ // must start with a letter
            if (!this.entry.passwordOld || this.entry.passwordOld.length < 1) {
                this.pwdOldError = 'Password is required.'
                // this.pwdError = 'Invalid password'
                return false
            } else if (this.entry.passwordOld.length < 8) {
                this.pwdOldError = 'Minimum 8 characters.'
                // this.pwdError = 'Invalid password'
                return false
            } else if (this.entry.passwordOld.length > 16) {
                this.pwdOldError = 'Maximum 16 characters.'
                // this.pwdError = 'Invalid password'
                return false
            } else if (!regex1.test(this.entry.passwordOld)) {
                this.pwdOldError = 'Only latin letters, numbers and underscore.'
                // this.pwdError = 'Invalid password'
                return false
            } else if (!regex2.test(this.entry.passwordOld)) {
                this.pwdOldError = 'At least one lower case, one upper case and one number.'
                // this.pwdError = 'Invalid password'
                return false
            } else if (!regex3.test(this.entry.passwordOld)) {
                this.pwdOldError = 'Must start with a letter.'
                // this.pwdError = 'Invalid password'
                return false
            }
            return true
        },
        passwordsMatch() {
            if (this.entry.password && this.password2 && (this.entry.password != this.password2)) {
                this.pwd2Error = 'The passwords don\'t match, please re-enter them.'
                this.password = ''
                this.password2 = ''
                return false
            }
            return true
        },
        async deleteProfile() {
            try {
                if (!this.validPasswordOld()) {
                    return false
                }
                let editpwd = {}
                let newPwd = Math.random().toString(36).slice(2, 10)
                let rndStr = Math.random().toString(36).slice(2, 7)
                let myDate = new Date()
                let newUname = `z${myDate.getFullYear()}${('0'+(myDate.getMonth()+1)).slice(-2)}${('0'+myDate.getDate()).slice(-2)}${rndStr}`
                editpwd['password'] = newPwd
                editpwd['old_password'] = this.entry.passwordOld
                console.log('editpwd: ', editpwd)
                let endpoint = '/user_changepwd/' + store.state.myUid + '/'
                const resp = await apiCalls.patchEntry(endpoint, editpwd)
                console.log('respPwd: ', resp)
                if (resp && resp.request && (resp.request.status < 200 || resp.request.status > 299)) {
                    this.delprofileMessage = "Profile not deleted"
                    return console.log('COULD NOT CHANGE PASSWORD')
                }
                let edituser = {}
                let editparticipant = {}
                let failed1 = false
                let failed2 = false
                edituser['username'] = newUname
                editparticipant['useralias'] = newUname
                edituser['email'] = `${newPwd}@tic321.io`
                // editparticipant['usermail'] = `${newPwd}@tic321.io`   // Not needed anymore in Participant - new-eliminated 0810
                editparticipant['active'] = 0
                console.log('edituser: ', edituser)
                let endpoint1 = '/user_update/' + store.state.myUid + '/'
                const userUpdated = await apiCalls.patchEntry(endpoint1, edituser)
                console.log('userUpdated: ', userUpdated)
                if (userUpdated.request.status < 200 || userUpdated.request.status > 299) {
                    failed1 = true
                }
                if (userUpdated && editparticipant && failed1 == false) {
                    let endpoint2 = '/participants/' + store.state.myPid + '/'
                    const participantUpdated = await apiCalls.patchEntry(endpoint2, editparticipant)
                    console.log('participantUpdated: ', participantUpdated)
                    if (participantUpdated.request.status < 200 || participantUpdated.request.status > 299) {
                        failed2 = true
                    }
                } else {
                    return this.delprofileMessage = "Profile not deleted"
                }

                console.log('failed: ', failed1, ' ', failed2)
                if (failed1 || failed2) {
                    return this.delprofileMessage = "Could not delete profile"
                } else {
                    const archived = await apiCalls.postEntry('/archive/', {
                        'participant': store.state.myPid,
                        'user': store.state.myUid,
                        'alias': store.state.myAlias,
                        'email': this.usermail,
                    })
                    if (!archived) {
                        return this.delprofileMessage = "Profile not deleted"
                    }
                    const myMemberships = await apiCalls.getEntries('/memberships/', {
                        'participant': store.state.myPid,
                    })
                    if (myMemberships && myMemberships.data.length > 0) {
                        console.log('myMembershipsInProfile: ', myMemberships.data)
                        // const myActiveMemberships = myMemberships.data.filter(obj => obj.status == 'active' || obj.status == 'removed' || obj.status == 'applied')
                        // if (myActiveMemberships && myActiveMemberships.length > 0) {
                        //     console.log('myActiveMemberships: ', myActiveMemberships)
                        let patchedMmb = {}
                        myMemberships.data.forEach(async function (mmb) {
                            patchedMmb = await apiCalls.patchEntry('/memberships/' + mmb.id + '/', {
                                'status': 'left'
                            })
                            if (patchedMmb) {
                                console.log('Membership patched: ', patchedMmb)
                            }
                        })
                    }
                    this.bsLeaveModal.hide()
                    // this.hideDeleteModal()
                    await this.setRfrsh('/login')
                    await this.setAlertMsg(`Profile successfully deleted. We hope to see you back soon with a new profile.`)
                }
                // return router.push('/')
            } catch (err) {
                console.log('error status: ', err)
            }
        },
        showDeleteModal() {
            return this.bsLeaveModal.show()
        },
        showCurrencyModal() {
            return this.bsCurrencyModal.show()
        },
        // hideDeleteModal() {
        //     return this.bsLeaveModal.hide()
        // },
        // async showConvertModal(newBasecurrency) {
        async showConvertModal() {
            try {

                let newBasecurrency = this.entry.basecurrency
                console.log('newBasecurrency: ', newBasecurrency)
                console.log('this.entry.basecurrency: ', this.entry.basecurrency)
                console.log('store.state.myCur: ', store.state.myCur)
                if (newBasecurrency == store.state.myCur) {
                    console.log('they are the same')
                    return
                }
                this.warningMsg = ''
                this.errorMsg = ''
                this.showWarning = false
                let symbols = ''
                if (newBasecurrency == 'USD') {
                    symbols = store.state.myCur + '=X'
                } else {
                    symbols = newBasecurrency + store.state.myCur + '=X'
                }
                console.log(symbols)
                let params = {
                    symbols: symbols,
                }
                let tickerData = [] // It's a list because it includes the asset plus its currency
                if (store.state.apiUrl == 'yf') {
                    tickerData = await yfapiCalls.getYF(params)
                } else {
                    tickerData = await yfapiCalls.getRapid(params)
                }
                console.log('tickerData: ', tickerData.data.quoteResponse.result)
                if (!tickerData || !tickerData.data.quoteResponse.result[0]) {
                    this.warningMsg = 'Missing live market data - pls try again'
                    this.showWarning = true
                    return this.bsConvertModal.show()
                }

                if (tickerData.data.quoteResponse.result[0]['marketState'] == 'REGULAR') { // COMMENT OUT THIS IF-ELSE STATEMENT ENTIRELY TO OPERATE ON MARKET CLOSE DAYS   0917
                    console.log('Market is open')
                } else {
                    console.log('Market is closed')
                    this.warningMsg = 'The market is closed at the moment so no live FX rate is availble. Please try again later.'
                    this.showWarning = true
                    return this.bsConvertModal.show()
                }

                // this.fxlabel = 'FX rate'
                // let actionFx = Math.round((tickerData.data.quoteResponse.result[0]['bid'] + Number.EPSILON) * 10000) / 10000
                let fxRate = 0
                if (tickerData.data.quoteResponse.result[0]['ask'] >= tickerData.data.quoteResponse.result[0]['bid']) {
                    fxRate = Math.round((tickerData.data.quoteResponse.result[0]['ask'] + Number.EPSILON) * 10000) / 10000
                } else {
                    fxRate = Math.round((tickerData.data.quoteResponse.result[0]['bid'] + Number.EPSILON) * 10000) / 10000
                }
                if (!fxRate || fxRate <= 0) {
                    this.warningMsg = 'Market seems open but no FX rate is available. Please try later.'
                    this.showWarning = true
                    return this.bsConvertModal.show()
                }
                let newAmount = Math.round(this.entry.usercash / fxRate * 100) / 100
                console.log('this.entry.usercash: ', this.entry.usercash)
                console.log('fxRate: ', fxRate)

                console.log('newAmount: ', newAmount)
                this.modalConvertEntry = {
                    'usercash': this.entry.usercash,
                    'oldcurrency': store.state.myCur,
                    'newcurrency': newBasecurrency,
                    'fxrate': fxRate,
                    'newamount': newAmount,
                    'startcash': Math.round(this.entry.startcash / fxRate * 100) / 100,
                    'userportfolio': Math.round(this.entry.userportfolio / fxRate * 100) / 100,
                    'accruedpl': Math.round(this.entry.accruedpl / fxRate * 100) / 100,
                    'fees': Math.round(this.entry.fees / fxRate * 100) / 100,
                    'dividends': Math.round(this.entry.dividends / fxRate * 100) / 100,
                }
                console.log('this.modalConvertEntry1: ', this.modalConvertEntry)
                return this.bsConvertModal.show()
            } catch (error) {
                if (store.state.alertMessage == 1) {
                    return alert(error)
                } else {
                    return console.log('errorTI19: ', error)
                }
            }
        },
        async convertCurrency() {
            try {

                // TODO THE CONVERSION SHOULD HAPPEN ENTIRELY IN THE BACKEND ////////////////////////////////////////////////////
                console.log('this.modalConvertEntry2: ', this.modalConvertEntry)
                const changedParticipant = await apiCalls.patchEntry('/participantsbc/' + store.state.myPid + '/', { //participantsbc (=basecurrency) is endpoint to make the changes not only to Participant but also to her Positions
                    // 'oldcurrency': convertEntry.oldcurrency,
                    'basecurrency': this.modalConvertEntry.newcurrency,
                    'fxrate': this.modalConvertEntry.fxrate,
                    'usercash': this.modalConvertEntry.newamount,
                    'startcash': this.modalConvertEntry.startcash,
                    'userportfolio': this.modalConvertEntry.userportfolio,
                    'accruedpl': this.modalConvertEntry.accruedpl,
                    'fees': this.modalConvertEntry.fees,
                    'dividends': this.modalConvertEntry.dividends,
                })

                if (changedParticipant && changedParticipant.data['basecurrency'] == this.modalConvertEntry.newcurrency) {
                    this.entry.basecurrency = this.modalConvertEntry.newcurrency
                    this.entry.usercash = this.modalConvertEntry.newamount
                    this.entry.startcash = this.modalConvertEntry.startcash
                    this.entry.userportfolio = this.modalConvertEntry.userportfolio
                    this.entry.accruedpl = this.modalConvertEntry.accruedpl
                    this.entry.fees = this.modalConvertEntry.fees
                    this.entry.dividends = this.modalConvertEntry.dividends
                    console.log('changedParticipant: ', changedParticipant)
                    console.log('changedParticipant.data: ', changedParticipant.data)
                    await this.setMyCur(this.modalConvertEntry.newcurrency)
                    localStorage.setItem('cur', this.modalConvertEntry.newcurrency)

                    this.bsConvertModal.hide()
                    console.log('base currency changed to ', this.modalConvertEntry.newcurrency)
                    return await this.setAlertMsg(`Your base currency has been changed from ${this.modalConvertEntry.oldcurrency} to ${this.modalConvertEntry.newcurrency}.`)

                } else {
                    console.log('not managed to change currency')
                    return await this.setAlertMsg(`Your base currency could not be changed.`)
                }

            } catch (err) {
                console.log('not managed to change currency')
                console.log(err)
            }

        },
        hideCurrencyModal() {
            return this.bsCurrencyModal.hide()
        },
        // async removeIcon() {
        //     try {
        //         this.generateHexString(16)
        //         await apiCalls.patchEntry('/participants/' + store.state.myPid + '/', {
        //             'usericon': 'I-00',
        //         })
        //         localStorage.setItem('pik', 'I-00')
        //         await this.setMyIcon('I-00')
        //         // return router.push('/profile')
        //     } catch (error) {
        //         console.log('error in removeIcon: ', error)
        //     }
        // },
        async getBlocked() {
            try {
                this.blockedList = []
                const blockedInvestors = await apiCalls.getEntries('/blocks/', {
                    'blocker': store.state.myPid,
                })
                if (blockedInvestors && blockedInvestors.data.length > 0) {
                    console.log('blockedInvestors: ', blockedInvestors)
                    this.showBlocked = true
                    this.blockedList = blockedInvestors.data
                }
            } catch (err) {
                console.log(err)
            }
        },
        async unBlock(entry) {
            try {
                const removed = await apiCalls.deleteEntry('/blocks/' + entry.id + '/')
                if (removed) {
                    console.log('block removed')
                }
                return router.go(0)
            } catch (err) {
                console.log(err)
            }
        },
        onFileSelected(event) {
            console.log(event)
            this.selectedFile = event.target.files[0]

            // let reader = new FileReader()
            // reader.readAsDataURL(event.target.files[0])
            // reader.onload = (event) => {
            //     this.image = event.target.result
            //     console.log('this.image1: ', this.image)
            // }

            this.image = URL.createObjectURL(this.selectedFile)
            console.log('this.image2: ', this.image)
        },
        async uploadImage() {
            try {
                console.log('this.selectedFile: ', this.selectedFile)
                console.log('this.selectedFile.name: ', this.selectedFile.name)
                const fd = new FormData()
                fd.append('participant', store.state.myPid)
                fd.append('image', this.selectedFile, this.selectedFile.name)
                // fd.append('image', this.selectedFile)
                console.log('fd: ', fd)
                const uploaded = await apiCalls.postImage('/images/', fd)
                // ,{
                // onUploadProgress: uploadEvent => {
                //     console.log('Upload Progress: ', +Math.round(uploadEvent.loaded / uploadEvent.total * 100) + '%')
                // }
                // })
                console.log('uploaded: ', uploaded)
            } catch (error) {
                console.log('error in uploadImage: ', error)
            }
        },
        goUtil() {
            return router.push('/utility')
        }
    },
    // beforeRouteEnter(to) {
    //     if (to.path == '/icons' && store.state.myIcon.length > 4) {
    //         alert('triggered1')
    //         return
    //     }
    // },
    async created() {
        try {
            // await this.getUser()
            await this.getParticipant()
            await this.getBlocked()
        } catch (error) {
            return console.log('error status: ', error)
        }
    },
    mounted() {
        this.myLeaveModal = document.getElementById('leaveModal')
        this.bsLeaveModal = new Modal(this.myLeaveModal)
        this.myConvertModal = document.getElementById('convertModal')
        this.bsConvertModal = new Modal(this.myConvertModal)
        this.myCurrencyModal = document.getElementById('currencyModal')
        this.bsCurrencyModal = new Modal(this.myCurrencyModal)

        const targetArea = this.$refs.targetArea
        let clickCount = 0
        let lastClickTime = null
        targetArea.addEventListener('click', () => {
            const now = Date.now()

            if (lastClickTime && now - lastClickTime < 2000) { // within 2 seconds
                clickCount++
                if (clickCount === 8) {
                    this.goUtil()
                    clickCount = 0
                    lastClickTime = null
                }
            } else {
                clickCount = 1
                lastClickTime = now
            }
        })
    }
}
</script>

<style scoped>
.spacer-row {
    height: 0px;
}

.card {
    margin-top: 8px;
    border-radius: 5px;
    border: none;
    width: 330px;
    padding-bottom: 15px;
    padding-top: 12px;
    height: 100%px;
    background-color: #413e3c;
    background-color: #272522;
    background-color: rgba(39, 37, 34, 0);
    margin-bottom: 45px;
}

.forms-inputs {
    /* moves the input box position */
    left: -7px;
    margin-top: 7px;
    margin-bottom: 10px !important;
}

.forms-inputs input {
    /* These are the actual input boxes. Seems to be the same as input-box */
    height: 41px;
    width: 255px;
    /* border: 2px solid #eee; */
    border: none;
    padding-left: 0px;
    border-radius: 7px;
    padding-left: 50px;
    margin-bottom: 6px;
}

.forms-inputs input:focus {
    /* what changes when you start entering input in the box */
    box-shadow: none;
    outline: none;
    border: 3px solid #d9d9d9;
    padding-left: 47px;
}

.custom-btn-new {
    /* position: relative; */
    height: 38px;
    width: 170px;
    /* padding-left: 15px;
    padding-right: 15px; */
    margin-top: 4px;
    margin-bottom: 2px;
    /* margin-left: 2px; */
    /* margin-right: 15px; */
    background-color: #2F5C85;
    color: white;
    border-radius: 7px;
}

.cancel-btn-new {
    /* padding-left: 15px;
    padding-right: 15px; */
    margin-right: 0px !important;
    /* margin-left: 15px; */
    margin-top: 3px;
    height: 38px !important;
    border-radius: 7px;
    /* width: 75px; */
}

.buttons-row {
    display: flex;
    justify-content: stretch;
}

.cancel-col {
    display: flex;
    justify-content: flex-end;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: 2px;
}

.custom-btn {
    position: relative;
    height: 38px;
    width: 258px;
    margin-top: 7px;
    margin-bottom: 2px;
    margin-left: -1px !important;
    background-color: #2F5C85;
    color: white;
    border-radius: 7px;
}

/* ******************* BTN 3D ******************* */
.btn3d {
    border: 0;
    transition: all 40ms linear;
}

.btn3d.btn-primar {
    box-shadow: 0 0 0 1px #2f5b85a1 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

.btn3d.btn-primar:hover {
    box-shadow: 0 0 0 1px #4a95db34 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

/* ******************* END BTN 3D ******************* */
.btn-pos {
    position: relative;
    left: -12px;
}

.warn {
    /* The warning messages which appear in case of error */
    /* color: red; */
    /* color: #d2d2d9; */
    color: #faca64e5;
    font-size: 14px;
    margin-left: 5px;
    padding: 0px;
    /* padding-bottom: 0px; */
    width: 280px;
    margin-top: -7px;
    margin-bottom: -10px;
}

.warn-modal {
    color: #000;
    /* margin-left: 15px; */
    text-align: center;
}

.inner-addon {
    /* enable absolute positioning */
    position: relative;
}

.modal-input {
    width: 100%;
    left: 0px;
}

.input-icon {
    /* style icon */
    height: 41px;
    position: absolute;
    margin-top: 0px;
    padding: 10px;
    pointer-events: none;
    background-color: #d9d9d9;
    top: 0px;
    left: 0px;
    /* left: 23px; */
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
}

.pwd-icon {
    position: absolute;
    margin-top: 0px;
    padding: 10px;
    cursor: pointer;
    /* background-color: #d9d9d9; */
    top: 1px;
    left: 210px;
    /* Moves the icon in the box */
}

/* .user-sticker {
    width: 35px;
    margin-top: -2px;
    margin-bottom: 3px;
    margin-left: 0px;
    margin-right: 0px;
} */

.user-sticker-round {
    width: 37px;
    margin-top: -1px;
    margin-bottom: 0px;
    margin-left: 2px;
    margin-right: 0px;
    border-radius: 50%;
}

.preview-icon {
    height: 37px;
    width: 37px;
    margin-top: -1px;
    margin-bottom: 0px;
    margin-left: 2px;
    margin-right: 0px;
    object-fit: cover;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
}

.sep-line {
    background-color: #dddddd !important;
    padding-top: 1px !important;
    margin-bottom: 5px;
    width: 258px;
    margin-left: -15px;
}

.sep-line-low {
    background-color: #dddddd !important;
    padding-top: 1px !important;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 258px;
    margin-left: -15px;
}

.updatedMessage {
    margin-top: -15px;
    margin-bottom: -8px;
    text-align: center;
    color: #faca64e5;
}

.switch-row {
    margin-top: -8px;
    margin-bottom: -2px;
}

.links {
    color: #c2c2c9;
    /* font-weight: bold; */
    font-size: 1rem;
    padding-left: 0px;
    padding-right: 0px;
}

.change-link {
    padding-top: 8px;
    margin-left: 10px;
    margin-right: -1px;
    text-align: center;
}

.default-link {
    padding-top: 8px;
    margin-right: -10px;
    margin-left: 0px;
    text-align: center;
    /* overflow-wrap: normal; */
    /* margin-left: -5px; */
}

.delete-link {
    color: #828289;
    font-size: 1rem;
    margin-top: 5px;
    margin-left: -8px;
    /* pading-top: 5px; */
}

.delete-link2 {
    color: #828289;
    font-size: 1rem;
    margin-left: 3px;
}

.delete-link3 {
    color: #828289;
    font-size: 1rem;

}

/* ***** MODAL ****************************************************** */
.modal-area {
    background-color: #dbd7d4;
    background-color: #e4e4e4;
}

.modal-title2 {
    color: #4d4d4d;
}

.modal-body2 {
    color: #4d4d4d;
}

.top-row-modal {
    margin-top: 0px;
}

.modal-row {
    text-align: center;
}

/* .btn-secondary {
    margin-left: 20px !important;
} */

.btn-secondary-modal {
    height: 38px;
    margin-top: -3px !important;
    margin-left: 20px !important;
}

.modal-footer {
    display: flex;
    justify-content: center;
}

.modal-btn {
    /* width: 180px; */
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 10px !important;
    height: 38px;
    /* margin-left: -60px !important; */
    margin-top: -3px !important;
    background-color: #2F5C85 !important;
    color: white !important;
    border: none !important;
}

.title-cell {
    text-align: start;
    font-weight: bold;
    margin-right: 0px;
    padding-right: 0px;
}

.bold-cell {
    text-align: start;
    font-weight: bold;
    margin-right: 0px;
    padding-right: 0px;
    margin-left: 0px;
    padding-left: 0px;
}

.close-butt {
    height: 25px !important;
    width: 10px !important;
    /* margin-top: 10px; */
    /* margin-left: 0px; */
    text-align: start !important;
    background-color: #b33430;
    color: white;
    margin-top: 3px !important;
    /* padding-top: -5px !important; */
}

.btn-tex {
    position: relative;
    top: -8px;
    left: -4px;
}

.mod-warn {
    background-color: #e4e4e4;
    color: rgb(240, 76, 76);
    font-weight: bold;
    border: none;
    padding-left: 10px;
    padding-right: 10px;
}

/* ********************************************* */

.entry-card {
    /* background-color: #413e3c !important; */
    background-color: #272522 !important;
    height: 37px;
    width: 258px;
    padding-left: 0px;
    padding-top: 0px;
    text-align: center;
    margin-left: -40px;
}

.cell-member {
    position: relative;
    text-decoration-line: none;
    font-size: 1rem;
    text-align: start;
    left: 8px !important;
}

.closepos-cell {
    font-size: 1rem;
    text-align: center;
    padding-left: 0px;
}

.cell-neu {
    color: #dbd7d4;
}

.select-box {
    left: -13px;
    /* Moves the box together with the icon */
    /* padding-left: 0px; */
    /* height: 50px;  */
    /* Determines how close the boxes get vertically somehow... */
}

.select-box input {
    /* formats what's written inside the input box */
    padding-left: 50px;
}

.form-select {
    /* This is the Select box */
    height: 43px;
    width: 258px;
    border: none;
    padding-left: 50px;
    color: #6c6c6c !important;
    border-radius: 7px;
    font-size: 16px;
    margin-top: 6px !important;

}

.curr-icon {
    /* style icon */
    height: 43px;
    position: absolute;
    margin-top: 30px;
    padding: 10px;
    pointer-events: none;
    background-color: #d9d9d9;
    top: 0px;
    left: 0px;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
}

.curr-select:focus {
    box-shadow: none;
    outline: none !important;
    border: 2px solid #d2d2d9
        /* border-color: red; */
}

.social-row {
    margin-left: -15px;
    margin-top: 10px;
    margin-bottom: 0px;
    padding-left: 0px;
}

.social-img {
    height: 28px;
    width: 28px;
    border-radius: 50%;
}

.col-social-input {
    /* background-color: #dad2d2; */
    color: #000;
    text-align: start;
    padding-left: 10px;
    padding-right: 0px;
}

.social-input {
    background-color: #dad2d2;
    height: 24px;
    width: 108%;
    text-align: start;
    padding-left: 4px;
    padding-right: 2px;
    color: #000;
    /* font-weight: bold ; */
    font-size: 1rem;
    margin-top: 2px;
    padding-top: 0px;
    border: none;
    margin-left: -10px;
}

.social-input:focus {
    background-color: #fff;
    outline: none;
}

.social-url {
    color: #828289;
    font-size: 1rem;
    margin-top: 24px;
    /* padding-top: 6px; */
    /* margin-left: -8px; */
    font-size: 0.9rem;
    padding: 0px;
    /* font-weight: bold; */
}

.social-buttons-row {
    /* text-align: start; */
    /* margin-left: -15px; */
    margin-top: 15px;
    margin-bottom: 5px;
    margin-left: 10px;
    padding-left: 10px;
    padding-right: 0px;
    margin-right: -10px;
    /* padding-left: 22px; */
}

.col-social-button1 {
    padding-left: 0px;
    margin-left: 10px !important;
    margin-right: 0px !important;
}

.col-social-button2 {
    padding-left: 0px;
    margin-left: 10px !important;
    margin-right: 0px !important;
}

.col-social-button3 {
    padding-left: 0px;
    margin-left: 10px !important;
    margin-right: 0px !important;

}

.btn-social-enter {
    background-color: #2F5C85 !important;
    /* width: 60px; */
    padding-top: 0px;
    padding-bottom: 4px;
    padding-left: 12px;
    padding-right: 12px;
    height: 24px;
    /* font-weight: bold; */
    color: #fff !important;
    margin-left: 0px;
    margin-right: 0px !important;
}

.btn-social-cancel {
    background-color: #525259 !important;
    /* width: 65px; */
    margin-top: -1px;
    padding-top: 0px !important;
    padding-bottom: 5px !important;
    padding-left: 5px;
    padding-right: 5px;
    height: 25px;
    /* font-weight: bold; */
    color: #fff !important;
    margin-left: 0px;
    margin-right: 0px !important;
}

.btn-social-remove {
    background-color: #525259 !important;
    /* width: 65px; */
    margin-top: -1px;
    padding-top: 0px !important;
    padding-bottom: 5px !important;
    padding-left: 5px;
    padding-right: 5px;
    height: 25px;
    /* font-weight: bold; */
    color: #fff !important;
    margin-left: 0px;
    margin-right: 0px !important;
    background-color: rgba(214, 55, 55, 0.897) !important;
}

.col-profile {
    color: #828289;
    text-align: start;
    padding-left: 14px;
    padding-top: 2px;
    padding-bottom: 0px;
    margin-left: -15px;
    padding-right: 0px;
    /* color: #d2d2d9; */
}

.warn-social {
    color: #faca64e5;
}
</style>
