<template>
<div class="container my-5">
    <div class="row spacer-row"></div>
</div>
</template>

<script>
import {
    mapActions
} from 'vuex'
import router from '@/router'
// import store from '@/store'
import authCalls from '@/services/authCalls.js'
import apiCalls from '@/services/apiCalls.js'
export default {
    name: 'UserAccept',
    props: [
        // 'rmail',
        'rkey',
        'rus',
        'rid',
        'token',
        'ui',
    ],
    methods: {
        ...mapActions('authentications', ['setLoggedIn', 'setIncorrectAuth']),
        ...mapActions(['setCad', 'setApiUrl', 'setMyCur', 'setMyUid', 'setCid', 'setAlertMsg', 'setRfrsh']),

    },
    async created() { // NOTICE: YOu arrive here through the endpoint /accept
        const credentials = {
            // username: this.rmail,
            username: this.rus,
            password: this.rkey
        }
        try {
            let endpoint = `/user_reset_check/${this.ui}/${this.token}/`
            const resp1 = await authCalls.userResetCheck(endpoint)
            console.log('resp1: ', resp1) // TODO: Handle here in case that the token was already used

            const res = await authCalls.loginNewUser(credentials) // Goes to /api/token. If the credentials provided are correct it simply returns valid access+refresh tokens
            localStorage.clear()
            // localStorage.clear()
            localStorage.setItem('atk', res.data.access)
            localStorage.setItem('rtk', res.data.refresh)
            // let uid = res.data.uid
            localStorage.setItem('uid', res.data.uid) // it receives the user ID with the invitation. UserID was stored by back-end in the invitation link when the user was created.
            // localStorage.setItem('rmail', this.rmail)
            await this.setMyUid(res.data.uid)

            // CHANGE BACK: Disable the 3 lines below
            // localStorage.setItem('aur', 'rp')
            // localStorage.setItem('aut', `${new Date().getMonth()+1}${new Date().getDate()}`)
            // await this.setApiUrl('rp')

            // Check if I have an invitation and if it is still valid. Check to which club am I invited
            // Sends a request to the invitation endpoint. If there is an invitation, the response will include the invitation (with its data) else will be empty
            // const invitationData = await apiCalls.getEntries('/invitation/login/', {}) // The invitation is in the name of the user (which is already created), therefore the view takes care to search the user
            ////////// Replaced by line below ///////////
            const invitationData = await apiCalls.getEntries('/invitations/' + this.rid + '/', {})
            console.log('invitationData: ', invitationData)

            if (invitationData && invitationData.data.inviteused == false) {

                //Check if email exists already
                console.log('Checking if email exists')
                // ***********************************************************************************                
                // const emailExists = await apiCalls.getEntries('/participants/', { // USERMAIL-HIDE: not needed, only needs to know that it exists. replace with endpoint that checks if a user email exist
                //     'usermail': invitationData.data.invitemail.toLowerCase()
                // })
                // **************************************************************************************
                const emailExists = await apiCalls.getEntries('/ltdp/', { // USERMAIL-HIDE: not needed, only needs to know that it exists. replace with endpoint that checks if a user email exist
                    'usermail': invitationData.data.invitemail.toLowerCase(),
                })
                if (emailExists && emailExists.data.length > 0) {
                    console.log('OH NO, emailExists: ', emailExists)
                    // await store.dispatch('messages/setShowGeneralMsg', `The email address "${invitationData.data.invitemail}" is already in use. Please login with the username and password corresponding to that email address. If you don't remember username or password (or both) please click on "Forgot password or username?" to reset them. `)
                    // alert(`The email address "${invitationData.data.invitemail}" is already in use. Please login with the username and password corresponding to that email address. If you don't remember username or password (or both) please click on "Forgot password or username?" to reset them.`)
                    // router.push('/login')
                    await this.setRfrsh('/login')
                    return await this.setAlertMsg(`The email address "${invitationData.data.invitemail}" is already in use. Please login with the username and password corresponding to that email address.${'\n\n'}If you don't remember username or password (or both) please click on "Forgot password or username?" to reset them.`)
                    // return router.push('/login')
                }
                localStorage.setItem('rmail', invitationData.data.invitemail.toLowerCase())
                // if (invitationData && invitationData.data.length > 0) { // If you had an invitation in the db it means you didn't self-register and there is a club ID in the invitation 
                if (invitationData.data.inviteclub) {
                    // await this.setMyClub(invited.data[0].inviteclub)
                    await this.setCid(invitationData.data.inviteclub)
                    localStorage.setItem('cid', invitationData.data.inviteclub)
                    await this.setMyCur(invitationData.data.invitecurrency)
                    localStorage.setItem('cur', invitationData.data.invitecurrency)
                }
                const invitePatched = await apiCalls.patchEntry('/invitations/' + this.rid + '/', {
                    'inviteused': true,
                    'inviteduser': res.data.uid
                })
                if (invitePatched) {
                    console.log('invitePatched: ', invitePatched.data)
                }
            } else {
                // await this.setAlertMsg('Invitation invalid or already used, please get a new one or register at: www.theinvestmentclub.io')
                // alert('Invitation invalid or already used, please get a new one or register at: www.theinvestmentclub.io')
                await this.setRfrsh('/login')
                return await this.setAlertMsg(`Invitation invalid or already used.${'\n\n'}Please get a new one or register at: www.investclub.net`)
                // return router.push('/')
            }
            await this.setLoggedIn(true)
            await this.setIncorrectAuth(false)
            return router.push('/create') //goes to UserCreateProfile
        } catch (error) {
            // if(err.response.status === 401) {
            console.log('error in UserAcceptInvitation: ', error)
            // await this.setIncorrectAuth(true)
            if (error.response.data.error == 'Token not valid, please request a new one') {
                // await this.setAlertMsg('Invitation invalid or already used. Please get a new one or register at: www.theinvestmentclub.io')
                // alert('Invitation invalid or already used. Please get a new one or register at: www.theinvestmentclub.io')
                await this.setRfrsh('/login')
                return await this.setAlertMsg(`Invitation invalid or already used.${'\n\n'}Please get a new one or register at: www.investclub.net`)
            }
            return await this.setIncorrectAuth(true)
        }
    }
}
</script>

<style scoped>
.spacer-row {
    height: 50px;
}
</style>
