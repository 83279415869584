import { createRouter, createWebHistory } from 'vue-router'
import store from "@/store"

import UserRegister from "@/views/UserRegister.vue"
import UserAccept from "@/views/UserAccept.vue"
import UserCreate from "@/views/UserCreate.vue"
import UserIcon from "@/views/UserIcon.vue"
import ClubIcon from "@/views/ClubIcon.vue"
import UserProfile from "@/views/UserProfile.vue"
import UserResetRequest from "@/views/UserResetRequest.vue"
import UserResetComplete from "@/views/UserResetComplete.vue"
import UserLogin from "@/views/UserLogin.vue"

import ClubsAdmin from "@/views/ClubsAdmin.vue"
import NewItemdata from "@/views/NewItemdata.vue"
import DisplayItemdata from "@/views/DisplayItemdata.vue"
import DisplayInvestor from "@/views/DisplayInvestor.vue"
import RemoveComment from "@/views/RemoveComment.vue"

import NetworkError from "@/views/NetworkError.vue"
import NotFound from "@/views/NotFound.vue"
import UserHelp from '@/views/UserHelp.vue'
import FairValue from '@/views/FairValue.vue'
import UtilityPage from '@/views/UtilityPage.vue'

const routes = [
  {
    path: "/register",
    name: "register",
    component: UserRegister,
    meta: { requiresAuth: false },
  },
  {
    path: "/accept",
    name: "accept",
    component: UserAccept,
    props: route => {
      return {
        rkey: route.query.rkey,
        rid: route.query.rid,
        rus: route.query.rus,
        token: route.query.token,
        ui: route.query.ui,
      }
    },
    meta: { requiresAuth: false },  // MUST BE ACCESSIBLE WITHOUT AUTH TO FUNCTION; SO PAY ATTENTION TO HOW MITIGATE RISK!
  },
  {
    path: "/create",
    name: "create",
    component: UserCreate,
    meta: { requiresAuth: true },
  },
  {
    path: "/icons",
    name: "icons",
    component: UserIcon,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/clubicons",
    name: "clubicons",
    component: ClubIcon,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/profile",
    name: "profile",
    component: UserProfile,
    meta: { requiresAuth: true },
  },
  {
    path: "/resetrequest",
    name: "resetrequest",
    component: UserResetRequest,
    meta: { requiresAuth: false },
  },
  {
    // path: "/resetcomplete/:uidb64/:token",
    path: "/resetcomplete",
    name: "resetcomplete",
    component: UserResetComplete,
    props: route => {
      return {
        lid: route.query.lid,
        uidb64: route.query.uidb64,
        token: route.query.token,
      }
    },
    meta: { requiresAuth: false },  // MUST BE ACCESSIBLE WITHOUT AUTH TO FUNCTION; SO PAY ATTENTION TO HOW MITIGATE RISK!
  },
  {
    path: "/login",
    name: "login",
    component: UserLogin,
    meta: { requiresAuth: false },
  },
  {
    path: '/',
    name: 'homepage',
    component: DisplayItemdata,
    props: {
      category: 'portfolio'
    },
    meta: { requiresAuth: true },    
  },
  {
    path: "/clubsadmin",
    name: "clubsadmin",
    component: ClubsAdmin,
    meta: { requiresAuth: true },
  },
  {
    path: '/newasset',
    name: 'newassetdata',
    component: NewItemdata,
    // props: true,
    props: {
      category: 'asset'
    },
    meta: { requiresAuth: true },    
  },
  {
    path: '/newcrypto',
    name: 'newcryptodata',
    component: NewItemdata,
    // props: true,
    props: {
      category: 'crypto'
    },
    meta: { requiresAuth: true },    
  },
  {
    path: '/:category',  // Serves also as catchAll route, when url doesn't mach one of the categories
    name: 'itemdata',
    component: DisplayItemdata,
    props: true,
    meta: { requiresAuth: true },    
    // props: route => { return {
    //   category: route.params.category,
    //   participantid: route.params.participantid,
    // } }
  },
  {
    path: '/:investor(\\d+)',  //this will trigger if the path is just /1015 or any other number!
    meta: { requiresAuth: true },
    // props: true,  
    redirect: to => {
      return { name: 'displayinvestor', params: {investor: to.params.investor} }
    }
  },
  {
    path: '/investor',  //this will trigger if the path is just /1015 or any other number (called by the one above)
    name: 'displayinvestor',
    component: DisplayInvestor,
    props: true,    
    meta: { requiresAuth: true },
  },
  {
    path: '/remove',
    redirect: to => {  //could also use route instead of to, is the same
      return { name: 'removecomment', query: '', params: {category: to.query.a, comment: to.query.c, position: to.query.p} }
    },
    meta: { requiresAuth: true },
  },
  {
    path: '/removecomment',
    name: 'removecomment',
    component: RemoveComment,
    props: true,    
    meta: { requiresAuth: true },
  },
  {
    path: '/not-found',
    name: 'not-found',
    component: NotFound,
    props: true,  // if you know what has not been found, then can pass the resource in parameter as a prop, e.g. resource:'Participant', for example if the API call for an event id fails with 404
    meta: { requiresAuth: true },
  },
 {
    path: '/network-error',
    name: 'network-error',
    component: NetworkError,
    meta: { requiresAuth: true },
  },
  {
    path: '/help',
    name: 'help',
    component: UserHelp,
    props: {
      category: 'help',
    },
    meta: { requiresAuth: true },
  },
  {
    path: '/about',
    name: 'about',
    component: UserHelp,
    props: {
      category: 'about',
    },
    meta: { requiresAuth: true },
  },
  {
    path: '/manual',
    name: 'manual',
    component: UserHelp,
    props: {
      category: 'manual',
    },
    meta: { requiresAuth: true },
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: UserHelp,
    props: {
      category: 'privacy',
    },
    meta: { requiresAuth: false },
  },
  {
    path: '/terms',
    name: 'terms',
    component: UserHelp,
    props: {
      category: 'terms',
    },
    meta: { requiresAuth: true },
  },
  {
    path: '/valuation',
    name: 'valuation',
    component: FairValue,
    props: true,
    meta: { requiresAuth: true },    
  },
  {
    path: '/utility',
    name: 'utility',
    component: UtilityPage,
    props: true,
    meta: { requiresAuth: true },    
  },
 ]
// ####################  UTILITIES - NOT IN USE #####################################
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: About,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // }
  // {

// ################################################################################

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // I Prefer that it always goes back to top position
  scrollBehavior() {
    return { top: 0 }
  }
  // scrollBehavior(savedPosition) { // Makes sure that upon navigation, vue moves the page to the top (unless you are going back, in which case you want to go back to the savedPosition)
    // if (savedPosition) {
    //   return savedPosition
    // } else {
    //   return { top: 0 }
    // }
  // }
})

router.beforeEach(async function(to, from) {
  // console.log('STARTING router.beforeEach')
  // await store.dispatch('setIsLoading', true)
  try {
    await store.dispatch('setShowPic', false) // this is the enlarged pic of the Topbar

    if(from.path == '/clubs' && to.path != '/conversation') {
      if(localStorage.getItem('cid')) {
        localStorage.removeItem('cid')
      }
      if(localStorage.getItem('cna')) {
        localStorage.removeItem('cna')
      }
      if(localStorage.getItem('cik')) {
        localStorage.removeItem('cik')
      }
      if(localStorage.getItem('cin')) {
        localStorage.removeItem('cin')
      }

    }

    if (from.path == '/conversation' && to.path != '/conversation' && (localStorage.getItem('cna') || localStorage.getItem('cik') || localStorage.getItem('cid') || localStorage.getItem('cin'))) {
      if(localStorage.getItem('cid')) {
        localStorage.removeItem('cid')
      }
      if(localStorage.getItem('cna')) {
        localStorage.removeItem('cna')
      }
      if(localStorage.getItem('cik')) {
        localStorage.removeItem('cik')
      }
      if(localStorage.getItem('cin')) {
        localStorage.removeItem('cin')
      }
    }

    if (to.name == 'displayinvestor') {
      // console.log('to in beforeEach: ', to.name)
      // console.log('from in beforeEach: ', from)
      // console.log('toInvestorPage in beforeEach: ', store.state.toInvestorPage)
      // console.log('to.params.investor: ', to.params.investor)
      // if (localStorage.getItem('paid') && to.params.investor != localStorage.getItem('paid')) { //means it's coming through one of the hyperlinks
      if (to.params.investor && !localStorage.getItem('paid')) { //means it's coming through one of the hyperlinks
        // console.log('coming from a hyperlink therefore cleaning the old localStorage before routing')
        await store.dispatch('setParticId', to.params.investor)
        await store.dispatch('setParticIcon', null)
        await store.dispatch('setParticAlias', null)
        localStorage.setItem('paid', to.params.investor)
        localStorage.removeItem('paic')
        localStorage.removeItem('paal')
        return router.go(0)
      }
      // if (to.params.investor) {
      //   localStorage.setItem('paid', to.params.investor)
      //   await store.dispatch('setParticId', to.params.investor)
      // }
      // console.log('store.state.particId2: ', store.state.particId)
      // console.log('setting ToinvPage to TRUE - to:', to)
      // console.log('setting ToinvPage to TRUE - from:', from.path)
      // console.log('localStorage.getItem(paid): ', localStorage.getItem('paid'))
      // console.log('store.state.particId: ', store.state.particId)
      // console.log('store.state.toInvestorPage:', store.state.toInvestorPage)
      if(from.path == '/investor' && (store.state.toInvestorPage == false || store.state.fromInvestorPage == true)) { //if fromInvestorPage is true, then it comes from inside Investor Page and toInvestorePage has already been set to true in order to immediately hide the screen updating of the same page
        // console.log('FORCING A REFRESH OTHERWISE THE PAGE COMPONENTS WONT UPDATE')
        return router.go(0)
      }
      if (store.state.toInvestorPage == false) {
        await store.dispatch('setToinvPage', true)
      }
    }
    const loggedIn = localStorage.getItem('atk') // Checks if there is a token in localStorage, indicating if user is loggedIn
    if (to.path == '/network-error') {
      return true
    }
    await store.dispatch('setPage', to.path)
    localStorage.setItem('pag', to.path)

    if (from.path == '/portfolio' || from.path == '/watchlist' || from.path == '/history') {
      await store.dispatch('setParticId', null)
      await store.dispatch('setParticIcon', null)
      await store.dispatch('setParticAlias', null)
      localStorage.removeItem('paid')
      localStorage.removeItem('paic')
      localStorage.removeItem('paal')
      // console.log('*******REMOVED Router: paid/paic/paal in localstorage and in store********')
    }

    // if (from.path == '/clubs') {
    //   await store.dispatch('setCin', false)
    //   localStorage.removeItem('cin')
    // }
    if (from.path == '/clubsadmin') {
      await store.dispatch('setCaid', false)
      await store.dispatch('setCain', false)
      localStorage.removeItem('caid')
      localStorage.removeItem('cain')
    }
    if (store.state.apiUrl == 'rp') {
      let myDate = new Date()
      let dt = `${('0'+(myDate.getMonth()+1)).slice(-2)}${('0'+myDate.getDate()).slice(-2)}`
      if (dt != localStorage.getItem('aut')) {
        await store.dispatch('setApiUrl', 'yf')
        localStorage.removeItem('aur')
        localStorage.removeItem('aut')
      }
    }
    if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) { //Notice: to.matched gives an array of the records that match the "to" route. The "some" method iterates over the collection of routes to see if any of them has the meta requiresAuth property on it
      //   await store.dispatch('setAlertMsg', 'Sorry, you are not authorized to view this Page')
      await store.dispatch('setRfrsh', '/login') //New 221231
      return { path: '/login'}  // This redirects to the root route
    }
    // console.log('ENDING router.beforeEach')
    return true // This allows to continue navigation to the "to" route
  } catch(error) {
    console.log('error inside router beforeEach: ',error)
  }
})

router.afterEach(async function(to) {
  // console.log('STARTING router.afterEach')

  try {
    // await store.dispatch('setIsLoading', false)
    // console.log('to in afterEach: ', to.name)
    // console.log('from in afterEach: ', from)
    // console.log('toInvestorPage in afterEach: ', store.state.toInvestorPage)

    if (to.name != 'displayinvestor' && store.state.toInvestorPage == true) { // just in case that it had remained to true
      await store.dispatch('setToinvPage', false)
      // console.log('setting ToinvPage to FALSE IN router.afterEach')
    }
    
    // console.log('ENDING router.afterEach')
  } catch(error) {
    console.log('error inside router afterEach: ',error)
  }


  // if (to.name == 'DisplayInvestor') {
  // setTimeout(() => {
  //     store.dispatch('setToinvPage', false)
  // }, 700)
  // }
})

export default router
