<template>
<div class="container my-5 pt-2">
    <!-- <div class="row spacer-row"></div> -->

    <!-- <div class="row">
        <div class="col d-flex justify-content-center">
        <img id="img" src="@/assets/images/Inv_Modal.png" class="custom-img-login">
        </div>
    </div> -->

    <div v-if="pwdChangedMsg" class="row spacing-row"></div>

    <div class="row mt-5 pt-2">
        <div class="col d-flex justify-content-center">
            <div v-if="showUsernameRules">
                <p class="username-rules"><span class="rules-title"><b><u>Username rules</u></b></span><br>Use latin letters, numbers, underscore (_) and dash (-), starting with a letter. No spaces. Length: {{minUsername}} to {{maxUsername}} characters.</p>
            </div>
            <div v-if="showPasswordRules">
                <p class="password-rules"><span class="rules-title"><b><u>Password rules</u></b></span><br>Use latin letters, numbers and underscore (_), starting with a letter. At least one upper case, one lower case and one number. No spaces. Length: 8 to 16 characters.</p>
            </div>
        </div>
    </div>

    <div class="row mb-0">
        <div class="col d-flex justify-content-center">
            <div :class="this.lid == 'p' ? 'card px-5 py-0' :  'card card-long px-5 py-0'">
                <!-- <div class="row">
                <div class="col d-flex justify-content-center">
                    <div v-if="showUsernameRules">
                        <p class="username-rules"><span class="rules-title"><b><u>Username rules</u></b></span><br>Use latin letters, numbers, underscore (_) and dash (-), starting with a letter. No spaces. Length: {{minUsername}} to {{maxUsername}} characters.</p>
                    </div>
                    <div v-if="showPasswordRules">
                        <p class="password-rules"><span class="rules-title"><b><u>Password rules</u></b></span><br>Use latin letters, numbers and underscore (_), starting with a letter. At least one upper case, one lower case and one number. No spaces. Length: 8 to 16 characters.</p>
                    </div>
                </div>
                </div> -->
                <div class="row" v-if="this.lid == 'l'">
                    <div class="col d-flex justify-content-center">
                        <div class="forms-inputs inner-addon  mt-3 mb-2 ">
                            <img class="input-icon" src="@/assets/icons/account.svg" alt="Account">
                            <input autocomplete="off" placeholder="New username" type="text" v-model="entry.username" @focus="this.usernameError=''; this.pwdMessage=''; showUsernameRules=true; pwdChangedMsg=false;" @blur="showUsernameRules=false; validUsername()" @drop.prevent="false" @paste.prevent="false">
                            <div v-if="this.usernameError">
                                <p class="warn">{{ this.usernameError }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col d-flex justify-content-center">

                        <div class="forms-inputs inner-addon  mt-3 mb-2 ">
                            <img class="input-icon" src="@/assets/icons/lock.svg" alt="Lock">
                            <input autocomplete="off" :type="showPassword ? 'text' : 'password'" placeholder="New password" v-model="entry.password" @focus="this.pwdError=''; showPasswordRules=true; pwdChangedMsg=false;" @blur="showPasswordRules=false; validPassword()" @drop.prevent="false" @paste.prevent="false">
                            <img v-if="showPassword" class="pwd-icon right-icon" src="@/assets/icons/eye.svg" @click="showPassword = !showPassword" alt="Lock">
                            <img v-else class="pwd-icon" src="@/assets/icons/eye-off.svg" @click="showPassword = !showPassword" alt="Lock">
                            <div v-if="this.pwdError">
                                <p class="warn">{{ this.pwdError }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col d-flex justify-content-center">
                        <div class="forms-inputs inner-addon  mt-3 mb-2 ">
                            <img class="input-icon" src="@/assets/icons/lock.svg" alt="Lock">
                            <input autocomplete="off" :type="showPassword2 ? 'text' : 'password'" placeholder="Re-type new password" v-model="password2" @focus="this.pwd2Error=''; pwdChangedMsg=false;" @blur="validPassword2(); passwordsMatch();" @drop.prevent="false" @paste.prevent="false">
                            <img v-if="showPassword2" class="pwd-icon right-icon" src="@/assets/icons/eye.svg" @click="showPassword2 = !showPassword2" alt="Lock">
                            <img v-else class="pwd-icon" src="@/assets/icons/eye-off.svg" @click="showPassword2 = !showPassword2" alt="Lock">
                            <div v-if="this.pwd2Error">
                                <p class="warn">{{ this.pwd2Error }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col d-flex justify-content-center">

                        <div class="mb-3 btn-pos">
                            <button v-if="this.lid == 'l'" @click="changeNamePwd()" class="btn custom-btn btn3d btn-primar">Change Username and Password</button>
                            <button v-else-if="this.lid == 'p'" @click="changePwd()" class="btn custom-btn btn3d btn-primar">Change Password</button>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col d-flex justify-content-center">

                        <!-- <div v-if="this.loginLink" class="login-message"> -->
                        <div class="login-message">
                            <span class="message-intro">Would you like to </span>
                            <span>
                                <router-link @click="pwdChangedMsg=false" to="/login" class="message-link">Login</router-link>
                            </span>
                            <span class="message-intro">?</span>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row row-msg">
        <div class="col d-flex justify-content-center">
            <div class="card-msg px-2 pt-2" v-if="this.pwdMessage">
                <!-- <div v-if="!this.pwdMessage" class="updatedMessage"> -->
                <!-- Your password has been changed successfully. Please login using your new password and your email address as Username -->
                <!-- Your password has been changed successfully. -->
                {{ this.pwdMessage }}
            </div>
            <!-- </div> -->
        </div>
    </div>
    <!-- </div> -->
    <!-- </div> -->
</div>
</template>

<script>
import store from '@/store'
// import {
//     mapActions
// } from 'vuex'
import router from '@/router'
// import apiCalls from '@/services/apiCalls.js'
import authCalls from '@/services/authCalls.js'
export default {
    name: 'UserResetComplete',
    data() {
        return {
            password2: '',
            showPassword: false,
            showPassword2: false,
            usernameError: '',
            pwdError: '',
            pwd2Error: '',
            profileMessage: '',
            pwdMessage: '',
            pwdChangedMsg: false,
            validProfile: false,
            validPwd: false,
            // username: '',
            loginLink: false,
            entry: {
                username: null,
                password: null,
            },
            showUsernameRules: false,
            showPasswordRules: false,
        }
    },
    props: [
        'lid',
        'uidb64',
        'token',
    ],
    computed: {
        maxUsername() {
            return store.state.maxUsername
        },
        minUsername() {
            return store.state.minUsername
        }
    },
    methods: {
        async changePwd() {
            this.validatePwd()
            if (this.validPwd) {
                try {
                    let editpwd = {}
                    editpwd['password'] = this.entry.password
                    editpwd['uidb64'] = this.uidb64
                    editpwd['token'] = this.token
                    editpwd['lid'] = this.lid
                    console.log('editpwd: ', editpwd)
                    // let endpoint = `/user_reset_complete/${this.uidb64}/${this.token}/`
                    let endpoint = '/user_reset_complete/'
                    const resp = await authCalls.userResetComplete(endpoint, editpwd)
                    console.log('respPwd: ', resp)
                    if (resp.request.status > 199 && resp.request.status < 300) {
                        var successPwd = true
                    }
                    if (successPwd) {
                        if (this.lid == 'p') {
                            this.pwdMessage = "Your password has been changed successfully."
                        } else if (this.lid == 'l') {
                            this.pwdMessage = "Your password has been changed successfully. Please login using your new password and your email address as Username"
                        }
                        return this.loginLink = true

                        // return router.push('/')
                    } else {
                        return this.pwdMessage = "Could not change password"
                    }
                    // return router.push('/')
                } catch (err) {
                    console.log('error in change pwd: ', err)
                }
            } else {
                return this.pwdMessage = "Could not change password"
            }
        },
        async changeNamePwd() {
            this.pwdChangedMsg = false
            this.validatePwd()
            this.validateProfile()
            if (this.validPwd && this.validProfile) {
                try {
                    let editprof = {}

                    editprof['password'] = this.entry.password
                    editprof['username'] = this.entry.username
                    editprof['uidb64'] = this.uidb64
                    editprof['token'] = this.token
                    editprof['lid'] = this.lid

                    console.log('editprof: ', editprof)
                    // let endpoint = `/user_reset_complete/${this.uidb64}/${this.token}/`
                    let endpoint = '/user_reset_complete/'
                    const resp = await authCalls.userResetComplete(endpoint, editprof)
                    console.log('respProfile: ', resp)
                    if (resp.request.status > 199 && resp.request.status < 300) {
                        var successProf = true
                    }
                    if (successProf) {
                        if (this.lid == 'p') {
                            this.pwdMessage = "Your password has been changed successfully."
                        } else if (this.lid == 'l') {
                            // this.pwdMessage = "Your password has been changed successfully. Please login using your new password and your email address as Username"
                            this.pwdChangedMsg = true
                            this.pwdMessage = "Your username and password have been changed successfully."
                        }
                        return this.loginLink = true
                        // return router.push('/')
                    } else {
                        return this.pwdMessage = "Could not change username and password"
                    }
                    // return router.push('/')
                } catch (error) {
                    if (error.response && error.response.data.username && error.response.data.username[0].indexOf('must be unique') >= 0) {
                        return this.pwdMessage = " The Username must be unique, please try again."
                    } else if (error.response && error.response.data.detail && error.response.data.detail.indexOf('link not valid') >= 0) {
                        this.pwdMessage = "Reset link no longer valid. Please request a new one and try again"
                        return setTimeout(() => {
                            router.push('/')
                        }, 3000)
                    } else {
                        console.log('error in changeNamePwd: ', error)
                    }

                }
            } else {
                return this.pwdMessage = "Could not change Username and Password"
            }
        },
        validateProfile() {
            if (this.validUsername()) {
                this.validProfile = true;
            }
        },
        validatePwd() {
            if (this.validPassword() && this.validPassword2() && this.passwordsMatch()) {
                this.validPwd = true;
            }
        },
        // async checkUsername() {  // CANNOT BE USED BECAUSE THE USER IS NOT AUTHENTICATED
        //     if(!this.validUsername()) {
        //         return
        //     }
        //     console.log('Checking if username unique')
        //     const usernameExists = await apiCalls.getEntries('/participants/', {
        //         'useralias': this.entry.username
        //     })
        //     if (usernameExists && usernameExists.data.length > 0) {
        //         console.log(usernameExists)
        //         return this.pwdMessage = `The username ${this.entry.username} is already in use, please choose a different one.`
        //         // document.getElementById("username-box").focus()
        //     } else {
        //         return console.log(usernameExists)
        //     }
        // },
        validUsername() {
            // const regex = /^.{3,5}$/ // 3 to 5 characters long
            // const regex = /^[a-zA-Z]\w{2,4}]$/ // 3 to 5, starting with a letter
            const regex1 = /^[a-zA-Z0-9_-]+$/ // only letters numbers, underscore - minimum 1 character (otherwise * instead of +)
            const regex2 = /^[a-zA-Z]/ // must start with a letter
            // const regex = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/  // At least 1 lower, one upper and one number
            if (!this.entry.username || this.entry.username.length < 1) {
                this.usernameError = 'Username is required.'
                // this.usernameError = 'Invalid username'
                return false
            } else if (this.entry.username.length < store.state.minUsername) {
                this.usernameError = `Minimum ${store.state.minUsername} characters.`
                // this.usernameError = 'Invalid username'
                return false
            } else if (this.entry.username.length > store.state.maxUsername) {
                this.usernameError = `Maximum ${store.state.maxUsername} characters.`
                // this.usernameError = 'Invalid username'
                return false
            } else if (!regex1.test(this.entry.username)) {
                this.usernameError = 'Only latin letters, numbers, underscore, dash.'
                // this.usernameError = 'Invalid username'
                return false
            } else if (!regex2.test(this.entry.username)) {
                this.usernameError = 'Must start with a letter.'
                // this.usernameError = 'Invalid username'
                return false
            }
            return true
        },
        validPassword() {
            const regex1 = /^[a-zA-Z0-9_]+$/ // only letters numbers, underscore - minimum 1 character (otherwise * instead of +)
            const regex2 = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/ // At least 1 lower, one upper and one number
            const regex3 = /^[a-zA-Z]/ // must start with a letter
            if (!this.entry.password || this.entry.password.length < 1) {
                this.pwdError = 'Password is required.'
                // this.pwdError = 'Invalid password'
                return false
            } else if (this.entry.password.length < 8) {
                this.pwdError = 'Minimum 8 characters.'
                // this.pwdError = 'Invalid password'
                return false
            } else if (this.entry.password.length > 16) {
                this.pwdError = 'Maximum 16 characters.'
                // this.pwdError = 'Invalid password'
                return false
            } else if (!regex1.test(this.entry.password)) {
                this.pwdError = 'Only latin letters, numbers and underscore.'
                // this.pwdError = 'Invalid password'
                return false
            } else if (!regex2.test(this.entry.password)) {
                this.pwdError = 'At least one lower case, one upper case and one number.'
                // this.pwdError = 'Invalid password'
                return false
            } else if (!regex3.test(this.entry.password)) {
                this.pwdError = 'Must start with a letter.'
                // this.pwdError = 'Invalid password'
                return false
            }
            return true
        },
        validPassword2() {
            const regex1 = /^[a-zA-Z0-9_]+$/ // only letters numbers, underscore - minimum 1 character (otherwise * instead of +)
            const regex2 = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/ // At least 1 lower, one upper and one number
            const regex3 = /^[a-zA-Z]/ // must start with a letter
            if (!this.password2 || this.password2.length < 1) {
                this.pwd2Error = 'Password is required.'
                // this.pwd2Error = 'Invalid password'
                return false
            } else if (this.password2.length < 8) {
                this.pwd2Error = 'Minimum 8 characters.'
                // this.pwd2Error = 'Invalid password'
                return false
            } else if (this.password2.length > 16) {
                this.pwd2Error = 'Maximum 16 characters.'
                // this.pwd2Error = 'Invalid password'
                return false
            } else if (!regex1.test(this.password2)) {
                this.pwd2Error = 'Only latin letters, numbers and underscore.'
                // this.pwd2Error = 'Invalid password'
                return false
            } else if (!regex2.test(this.password2)) {
                this.pwd2Error = 'At least one lower case, one upper case and one number.'
                // this.pwd2Error = 'Invalid password'
                return false
            } else if (!regex3.test(this.password2)) {
                this.pwd2Error = 'Must start with a letter.'
                // this.pwdError = 'Invalid password'
                return false
            }
            return true
        },
        passwordsMatch() {
            if (this.entry.password && this.password2 && (this.entry.password != this.password2)) {
                this.pwd2Error = 'The passwords don\'t match, please re-enter them.'
                this.password = ''
                this.password2 = ''
                return false
            }
            return true
        },
        onResize() {
            window.scrollTo(0, document.body.scrollHeight)
        }
    },
    async created() {
        console.log('uidb64: ', this.uidb64)
        console.log('token: ', this.token)
        console.log('lid: ', this.lid)
        // let params = {
        //     'uidb64': this.uidb64,
        //     'token': this.token
        // }
        let endpoint = `/user_reset_check/${this.uidb64}/${this.token}/`
        try {
            const resp1 = await authCalls.userResetCheck(endpoint)
            console.log('resp1: ', resp1)

        } catch (error) {
            if (error.response && error.response.data.error.indexOf('Token not valid') >= 0) {
                this.pwdMessage = "Reset link no longer valid. Please request a new one and try again"
                return setTimeout(() => {
                    router.push('/')
                }, 3000)

            } else {
                console.log(error)
            }

        }
    },
    mounted() {
        window.addEventListener('resize', this.onResize)
    },
    unmounted() {
        window.removeEventListener('resize', this.onResize)
    }

}
</script>

<style scoped>
/* .spacer-row {
    height: 10px;
} */

.card {
    margin-top: 0px;
    /* border-radius: 7px; */
    border: none;
    width: 330px;
    height: 280px;
    background-color: #272522;
    background-color: rgba(39, 37, 34, 0);
}

.card-long {
    height: 350px;
}

/* .forms-inputs {
    left: -13px;
} */

.forms-inputs input {
    /* These are the actual input boxes. */
    height: 43px;
    width: 258px;
    /* border: 2px solid #eee; */
    border: none;
    padding-left: 0px;
    border-radius: 7px;
    padding-left: 50px;
}

.forms-inputs input:focus {
    /* what changes when you start entering input in the box */
    box-shadow: none;
    outline: none;
    border: 3px solid #d9d9d9;
    padding-left: 47px;
}

.custom-btn {
    /* position: relative; */
    height: 39px;
    width: 258px;
    margin-top: 26px;
    /* margin-left: -1px !important; */
    background-color: #2F5C85;
    color: white;
    border-radius: 7px;
    padding-left: 0px;
    padding-right: 0px;
}

/* ******************* BTN 3D ******************* */
.btn3d {
    border: 0;
    transition: all 40ms linear;
}

.btn3d.btn-primar {
    box-shadow: 0 0 0 1px #2f5b85a1 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

.btn3d.btn-primar:hover {
    box-shadow: 0 0 0 1px #4a95db34 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

/* ******************* END BTN 3D ******************* */
.btn-pos {
    /* position: relative;
    left: -12px; */
    margin-top: -7px;
}

.warn {
    /* The warning messages which appear in case of error */
    /* color: red; */
    /* color: #d2d2d9; */
    color: #faca64e5;
    font-size: 14px;
    /* margin-left: 3px; */
    padding: 0px;
    width: 260px;
    margin-bottom: -21px;
}

.username-rules {
    /* color: #d2d2d9; */
    color: #faca64e5;
    font-size: 14px;
    margin-left: -10px;
    padding: 0px;
    width: 260px;
    margin-top: -84px;
    margin-bottom: -10px;
}

.password-rules {
    /* color: #d2d2d9; */
    color: #faca64e5;
    font-size: 14px;
    margin-left: -10px;
    padding: 0px;
    width: 260px;
    margin-top: -117px;
    margin-bottom: -10px;
}

.rules-title {
    color: #d2d2d9;
}

.inner-addon {
    /* enable absolute positioning */
    position: relative;
}

.input-icon {
    /* style icon */
    height: 43px;
    position: absolute;
    margin-top: 0px;
    padding: 10px;
    pointer-events: none;
    background-color: #d9d9d9;
    top: 0px;
    left: 0px;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
}

.pwd-icon {
    position: absolute;
    margin-top: 0px;
    /* margin-left: 20px; */
    padding: 10px;
    cursor: pointer;
    /* background-color: #d9d9d9; */
    top: 1px;
    left: 210px;
    /* Moves the icon in the box */
}

.login-message {
    /* padding-left: 10px; */
    /* margin-left: 25px; */
    margin-top: 0px;
    margin-bottom: 10px;
}

.message-intro {
    color: #d2d2d9;
    margin-right: 3px;
}

.message-link {
    color: #709fca;
    text-decoration: underline !important;
    font-weight: bold;
}

.row-msg {
    margin-top: -30px;
}

.card-msg {
    background-color: #272522;
    width: 270px;
    /* margin-top: -50px; */

    /* height: 100px; */
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: solid 1px;
    border-color: #d2d2d9;
    color: #faca64e5;
    text-align: center;
}

.custom-img-login {
    max-width: 125px;
    margin-top: 20px;
    margin-bottom: 0px;
}

/* .row-rules {
    min-height: 80px;
} */
.spacing-row {
    height: 2px;
    margin-top: -60px;
    margin-bottom: -20px;

}
</style>
