<template>
<div>
    <div v-if="category == 'topinvestments'">
        <div class="row mt-4 pt-3">
            <div class="col-3 ps-2 pe-0 inactive-ranking">
                <router-link :to="{ name: 'itemdata', params: { category: 'topinvestors' }}" class="links">
                    Investors
                </router-link>
            </div>
            <div class="col-3 col-clubs ps-1 pe-0 inactive-ranking">
                <router-link :to="{ name: 'itemdata', params: { category: 'topclubs' }}" class="links">
                    Clubs
                </router-link>
            </div>
            <div class="col-3 col-inv ps-0 pe-0 active-ranking">
                Investments
            </div>
            <div class="col-3 ps-0 pe-0 inactive-ranking">
                <router-link :to="{ name: 'itemdata', params: { category: 'topfollowed' }}" class="links">
                    Followed
                </router-link>
            </div>
        </div>
    </div>
    <div v-else-if="category == 'searchinvestments'">
        <div class="row mt-4 pt-3">
            <div class="col-4 ps-3 inactive-ranking">
                <router-link :to="{ name: 'itemdata', params: { category: 'searchinvestors' }}" class="links">
                    Investors
                </router-link>
            </div>
            <div class="col-4 ps-3 inactive-ranking">
                <router-link :to="{ name: 'itemdata', params: { category: 'searchclubs' }}" class="links">
                    Clubs
                </router-link>
            </div>
            <div class="col-4 pe-2 active-ranking">
                Investments
            </div>
        </div>

        <div class="src-wrapper">
            <form>
                <div class="row justify-content-start gx-2 gy-0 pt-3 mb-0">
                    <div class="col-7 col-sm-8">
                        <BaseInputEnter label="Asset symbol (e.g. AAPL)" v-model="this.tradeSearch" id="club-src" />
                    </div>
                    <div class="col-3 col-sm-2">
                        <button type="submit" @click.prevent="getInvestments()" class="btn src-btn btn3d btn-primar">Search</button>
                    </div>
                    <div class="col-2 col-sm-2 px-0">
                        <div class="openclose-wrapper">
                            <BaseInputSelect label="" v-model="this.openClosed" @change="OpenClosed($event.target.value)" :options="this.openCloseds" id="openclose" />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <!-- <div v-if="this.showEmptyPos" class="card entry-card empty-card"> -->
    <div v-if="this.showEmptyPos" class="card empty-card">
        <div class="row">
            <!-- <div class="col-12 cell-empty"> -->
            <div class="col-12">
                {{ this.emptyMsgPos }}
            </div>
        </div>
    </div>
    <div v-if="this.posentries.length > 0" class="card card-headers">
        <div class="row">
            <div class="col-3 cell-headers head-1">
                asset
            </div>
            <div class="col-1 cell-headers head-2">
                l/s
            </div>
            <div class="col-2 cell-headers head-3">
                p/l
            </div>
            <div class="col-3 cell-headers">
                end price
            </div>
            <div class="col-3 cell-headers head-5">
                p/l
            </div>
        </div>
    </div>

    <div v-for="entry, index in this.posentries" :key="entry.id">
        <div class="card entry-card">
            <div class="row">
                <div class="col-3 cell-tick cell-left">
                    <a class="cell-neu" href="" @click.prevent="toggleSubcardPos(index)">{{ entry.assetname.split(' ')[0].slice(0, 7) }}</a>
                    <!-- <a class="cell-neu" href="" @click.prevent="toggleSubcardPos(index)">{{ ('ABCDEFGHILMNO').slice(0, 7) }}</a> -->
                </div>
                <div class="col-1 cell-pri cell-mid1">
                    {{ (entry.longshort) == 'long' ? 'L' : 'S' }}
                </div>
                <div class="col-2 cell-pri cell-mid2">
                    <!-- <span :class="(entry.endprice / entry.startprice) > 1 ? 'cell-pos' : 'cell-neg'">
                        {{ (Math.round((entry.endprice / entry.startprice -1) * 10000) / 100).toFixed(2) }} -->
                    <span :class="(entry.endvalue / Number(entry.startvalue)) >= 1 ? 'cell-pos' : 'cell-neg'">
                        <!-- {{ (Math.round((entry.endvalue / Number(entry.startvalue) -1) * 10000) / 100).toFixed(2)}} -->
                        {{ entry.amountChg }}
                        <!-- 1,234.56 -->
                    </span>
                </div>
                <div class="col-3 cell-pri cell-mid3">
                    {{ Number(entry.endprice).toLocaleString('en-US') }}
                    <!-- 1,234,567.89 -->
                </div>
                <div class="col-3 cell-pri cell-right">
                    {{ (Math.round((entry.endpl) * 100) / 100).toFixed(0).toLocaleString('en-US')}}
                    <!-- {{ (Math.round((entry.usdpl) * 100) / 100).toFixed(0)}} -->
                    <!-- 10,234,567.89 -->
                </div>
            </div>

            <div class="row">
                <div class="col-3 cell-qty">
                    {{ Number(entry.quantity).toLocaleString('en-US') }}
                    <!-- 10,234,567 -->
                </div>
                <div class="col-1 cell-curr">

                </div>
                <div class="col-2 cell-curr">
                    %
                </div>
                <div class="col-3 cell-curr">
                    {{ entry.currency.toLowerCase() }}
                    <!-- {{ entry.currency }} -->

                </div>
                <div v-if="entry.baseCurrency" class="col-3 cell-curr">
                    <!-- {{ baseCurrency.toLowerCase() }} -->
                    <!-- usd -->
                    {{ entry.baseCurrency.toLowerCase() }}
                    <!-- {{ entry.baseCurrency }} -->

                </div>
            </div>

        </div>

        <div v-show="showCardPos[index]" class="card entry-subcard">
            <div class="row">
                <div class="col-1 cell-subc cell-subc-left">

                </div>
                <div class="col-3 cell-subc subc-head cell-subc-mid1">
                    start
                </div>
                <div class="col-2 cell-subc subc-head cell-subc-mid2">
                    chg%
                </div>
                <div class="col-3 cell-subc subc-head cell-subc-mid3">
                    end
                </div>
                <div class="col-3 cell-subc subc-head cell-subc-right">
                    p/l
                </div>
            </div>

            <div class="row">
                <div class="col-1 cell-subc cell-titl cell-subc-left">
                    pri
                </div>
                <div class="col-3 cell-subc cell-subc-mid1">
                    {{ Number(entry.startprice).toLocaleString('en-US') }}
                    <!-- 1,234,567.89 -->
                </div>
                <div class="col-2 cell-subc cell-subc-mid2">
                    <span :class="(entry.endprice / entry.startprice) >= 1 ? 'cell-pos' : 'cell-neg2'">
                        <!-- {{ (Math.round((entry.endprice / entry.startprice -1) * 10000) / 100).toFixed(2)}} -->
                        {{ entry.priChg }}
                        <!-- 1,234.56 -->
                    </span>

                </div>
                <div class="col-3 cell-subc cell-subc-mid3">
                    {{ Number(entry.endprice).toLocaleString('en-US') }}
                    <!-- 1,234,567.89 -->
                </div>
                <div class="col-3 cell-subc cell-subc-right">

                </div>
            </div>

            <div class="row">
                <div class="col-1 cell-subc cell-titl cell-subc-left">
                    fx
                </div>
                <div class="col-3 cell-subc cell-subc-mid1">
                    {{ (Math.round(entry.startfx * 10000) / 10000).toFixed(4) }}
                    <!-- 10.0001 -->
                </div>
                <div class="col-2 cell-subc cell-subc-mid2">
                    <span :class="(entry.endfx / entry.startfx) >= 1 ? 'cell-pos' : 'cell-neg2'">
                        <!-- {{ (Math.round((entry.endfx / entry.startfx -1) * 10000) / 100).toFixed(2)}} -->
                        {{ entry.fxChg }}
                        <!-- 1,234.56 -->
                    </span>

                </div>
                <div class="col-3 cell-subc cell-subc-mid3">
                    {{ (Math.round(entry.endfx * 10000) / 10000).toFixed(4) }}
                    <!-- 10.0001 -->
                </div>
                <div class="col-3 cell-subc cell-subc-right">
                    <!-- {{ entry.fxeffect.toLocaleString('en-US') }} -->
                    {{ (Math.round(entry.fxeffect *100)/100).toLocaleString('en-US') }}
                    <!-- 10,234,567.89 -->
                </div>
            </div>

            <div class="row">
                <div v-if="entry.baseCurrency" class="col-1 cell-subc cell-titl cell-subc-left">
                    <!-- {{ baseCurrency.toLowerCase() }} -->
                    {{ entry.baseCurrency.toLowerCase() }}
                    <!-- {{ entry.baseCurrency }} -->
                </div>
                <div class="col-3 cell-subc cell-subc-mid1">
                    {{ Number(entry.startvalue).toLocaleString('en-US') }}
                    <!-- 10,234,567.89 -->
                </div>
                <div class="col-2 cell-subc cell-subc-mid2">
                    <span :class="(entry.endvalue / Number(entry.startvalue)) >= 1 ? 'cell-pos' : 'cell-neg2'">
                        <!-- {{ (Math.round((entry.endvalue / Number(entry.startvalue) -1) * 10000) / 100).toFixed(2)}} -->
                        {{ entry.amountChg }}

                        <!-- 1,234.56 -->
                    </span>

                </div>
                <div class="col-3 cell-subc cell-subc-mid3">
                    {{ (Math.round((Number(entry.startvalue) + Number(entry.endpl))*100)/100).toLocaleString('en-US') }}
                    <!-- 10,234,567.89 -->
                </div>
                <div class="col-3 cell-subc cell-subc-right">
                    {{ Number(entry.endpl).toLocaleString('en-US')}}
                    <!-- 10,234,567.89 -->
                </div>
            </div>

            <div class="row">
                <div class="col-4 cell-subc comments-cell cell-subc-low1">
                    <a class="cell-neu cell-light" href="" @click.prevent="toggleCommentsPos(entry, index)">comments</a>
                </div>

            </div>
        </div>

        <div v-show="showCommentPos[index]" class="container-fluid px-0">
            <div class="card comments-card">
                <div class="row">
                    <div class="col line-break-and-tab" :id="'poscomment-' + index">
                        <p> {{ this.updatedcommentPos[index] }} </p>
                    </div>
                </div>
            </div>

        </div>

    </div>

</div>
</template>

<script>
import apiCalls from '@/services/apiCalls.js'
// import store from '@/store'
// import {
//     mapActions
// } from 'vuex'
// import router from '@/router'
export default {
    name: 'TableInvestments',
    data() {
        return {
            particId: null,
            posentries: [],
            showEmptyPos: false,
            emptyMsgPos: '',
            showCardPos: [],
            showCommentPos: [],
            updatedcommentPos: [],

            openCloseds: [
                'opened',
                'closed',
            ],
            openClosed: 'closed',
            tradeSearch: '',
            closedTrades: 1,
        }
    },
    props: [
        'category',
    ],
    methods: {
        async topInvestmentsData() {
            try {
                const PosEntries = await apiCalls.getEntries('/topinvestments/', {})
                if (PosEntries && PosEntries.data.length > 0) {
                    console.log('PosEntries.data: ', PosEntries.data)
                    return this.posentries = PosEntries.data
                } else {
                    return this.emptyMsgPos = 'No investment to show'
                }
            } catch (err) {
                console.log('error in topInvestmentsData: ', err)
            }
        },
        async searchInvestmentsData() {
            try {
                const PosEntries = await apiCalls.getEntries('/positions/', {
                    'search': this.tradeSearch,
                    'closed': this.closedTrades
                })
                if (PosEntries && PosEntries.data.length > 0) {
                    console.log('PosEntries.data: ', PosEntries.data)
                    return this.posentries = PosEntries.data
                } else {
                    return this.emptyMsgPos = 'No close match found'
                }
            } catch (err) {
                console.log('error in topInvestmentsData: ', err)
            }
        },
        async getInvestments() {
            try {
                this.posentries = []
                this.showEmptyPos = false
                if (this.category == 'searchinvestments') {
                    await this.searchInvestmentsData()
                    console.log('this.posentries search: ', this.posentries)
                }
                if (this.category == 'topinvestments') {
                    await this.topInvestmentsData()
                    console.log('this.posentries top: ', this.posentries)
                }
                console.log('this.posentries.length: ', this.posentries.length)
                if (this.posentries.length == 0) {
                    return this.showEmptyPos = true
                }
                // this.updatedcommentPos = PosEntries.data.map(a => a.comment)
                this.updatedcommentPos = this.posentries.map(a => a.comment)
                this.posentries.forEach(uE => {
                    uE.endvalue = Number(uE.startvalue) + Number(uE.endpl)
                    uE.fxeffect = uE.endvalue - (uE.endvalue * Number(uE.startfx) / Number(uE.endfx))
                    if (uE.currencysymbol == '') {
                        uE.baseCurrency = uE.currency
                        console.log('currency1: ', uE.baseCurrency)
                    } else {
                        uE.baseCurrency = uE.currencysymbol.slice(-5, -2)
                        console.log('currency2: ', uE.baseCurrency)
                    }
                    uE.priChg = new Intl.NumberFormat("en-US", {
                        signDisplay: "exceptZero"
                    }).format(Math.round((uE.endprice / uE.startprice - 1) * 10000) / 100)
                    uE.fxChg = new Intl.NumberFormat("en-US", {
                        signDisplay: "exceptZero"
                    }).format(Math.round((uE.endfx / uE.startfx - 1) * 10000) / 100)
                    uE.amountChg = new Intl.NumberFormat("en-US", {
                        signDisplay: "exceptZero"
                    }).format(Math.round((uE.endvalue / Number(uE.startvalue) - 1) * 10000) / 100)
                })
            } catch (error) {
                return console.log('error in getInvestments: ', error)
            }
        },
        toggleSubcardPos(index) {
            if (this.showCommentPos[index]) {
                this.showCommentPos[index] = false
            }
            this.showCardPos[index] = !this.showCardPos[index]
        },
        async toggleCommentsPos(entry, index) {
            // if (!this.showCommentPos[index] && entry.extracomments) {
            if (!this.showCommentPos[index]) {

                let cutPos = entry.comment.indexOf("INITIAL COMMENT:")

                let aliasstart = entry.comment.substr(0, cutPos).lastIndexOf('\n\n') + 2
                let aliasstr = entry.comment.slice(aliasstart, cutPos - 3)
                let tradesstr = entry.comment.substr(0, cutPos - 3 - aliasstr.length)

                let commentstr = entry.comment.substr(cutPos + 16)
                this.updatedcommentPos[index] = `${tradesstr}<b><a href="/${entry.participant}" style="color: #fff;">${aliasstr}</a></b> - INITIAL COMMENT:${commentstr}${'\n'}`
                let params = {
                    'position': entry.id,
                }
                if (entry.extracomments) {
                    try {
                        const extracomms = await apiCalls.getEntries('/poscomments/', params)
                        console.log('extracomms: ', extracomms)
                        // let updatedComment = entry.comment
                        extracomms.data.forEach(obj => {
                            this.updatedcommentPos[index] = (
                                `${this.updatedcommentPos[index]}${'\n'}<b><a href="/${obj.participant}" style="color: #fff;">${obj.useralias}</a></b> - ${obj.created.slice(0, 10)}:${'\n'}${obj.comment}${'\n'}`
                            )
                        })
                        // entry.comment = updatedComment
                        // document.getElementById('poscomment-' + index).innerHTML = this.updatedcommentPos[index]
                        // console.log('this.updatedcomment[index]: ', this.updatedcomment[index])
                        // return this.showCommentPos[index] = !this.showCommentPos[index]
                    } catch (err) {
                        console.log(err)
                    }
                }
                console.log('this.updatedcommentPos[index] after: ', this.updatedcommentPos[index])
                document.getElementById('poscomment-' + index).innerHTML = this.updatedcommentPos[index]
            }
            return this.showCommentPos[index] = !this.showCommentPos[index]
        },
        OpenClosed(value) {
            // console.log('openclosed: ', value)
            if (value == 'closed') {
                this.closedTrades = 1
            } else {
                this.closedTrades = 0
            }
            console.log('this.closedTrades: ', this.closedTrades)
            this.getInvestments()

        }
    },
    async created() {
        try {
            this.posentries = []
            this.showEmptyPos = false
            console.log('in TopInvestments/created: ', this.category)
            if (this.category != 'searchinvestments') {
                await this.getInvestments()
            }
        } catch (err) {
            console.log(err)
        }
    }
}
</script>

<style scoped>
.entry-card {
    background-color: #413e3c !important;
    height: 60px;
    width: 100%;
    margin: 3px auto;
    padding-top: 5px;
    text-align: center;
    font-size: 0.8rem;
}

.empty-card {
    background-color: #413e3c;
    height: 40px;
    margin-top: 30px;
    padding-top: 6px;
    text-align: center;
}

/* .empty-card {
    height: 40px;
    margin-top: 30px;
    padding-top: 5px;
} */

/* .cell-empty {
    font-size: 0.9rem;
} */

.card-headers {
    height: 30px;
    background-color: #272522 !important;
    margin-bottom: -6px;
    margin-top: 12px;
    text-align: center;
    border: none;
}

.cell-headers {
    color: #6c6c6c !important;
    font-size: 1rem;
    /* text-decoration: underline; */
    /* margin-left: -3px; */
    padding-left: 0px;
    padding-right: 0px;
}

.head-1 {
    margin-left: 9px;
    margin-right: -9px;
}

.head-2 {
    /* text-align: end; */
    /* margin-left: -3px; */
    /* margin-right: -7x; */
    padding-left: 0px;
    padding-right: 0px;
}

.head-3 {
    margin-left: -5px;
    /* margin-right: 0px; */
}

.head-5 {
    margin-left: -7px;
    /* margin-right: 0px; */
}

/* .head-1 {
    margin-left: 8px;
    margin-right: -10px;
}

.head-2 {
    text-align: end;
    margin-left: -7px;
    margin-right: -2px;
}

.head-3 {
    margin-left: 5px;
    margin-right: 0px;
} */

.cell-tick {
    text-align: center;
    color: #dbd7d4 !important;
    text-decoration: underline;
    font-size: 1rem;
    margin-left: -3px;
    /* position: relative; */
    /* left: 4px !important; */
    /* margin-right: 10px; */
    /* font-weight: bold; */
}

.cell-pri {
    /* right text*/
    /* color: #babab9 !important; */
    color: #dbd7d4 !important;
}

.cell-neg {
    /* color: #b33430; */
    /* color: #fc7f59; */
    color: #e25651;
}

.cell-neg2 {
    /* color: #b33430; */
    /* color: #8f0f0a; */
    /* color: #a50c07; */
    color: #3d0402;
    filter: brightness(1.3);
}

.cell-pos {
    /* color: #7fa650; */
    color: #64dd17;
}

.cell-qty {
    font-size: 0.9rem;
    text-align: center;
    position: relative;
    left: 8px !important;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: -1px;
    margin-right: 3px;
}

.cell-curr {
    /* color: #c19556 !important; */
    color: #babab9 !important;
    margin-left: -3px;
}

.cell-left {
    padding-left: 10px;
    padding-right: 0px;
    margin-left: 2px;
    margin-right: -3px;
}

.cell-mid1 {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: -5px;
}

.cell-mid2 {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
}

.cell-mid3 {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
}

.cell-right {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: -5px;
    margin-right: 0px;
}

.entry-subcard {
    /* left-right sides of card */
    /* background-color: #7f7D7A !important; */
    background-color: #62605E !important;
    height: 140px;
    width: 100%;
    margin: 3px auto;
    padding-top: 10px;
    text-align: center;
    font-size: 0.8rem;
    color: #000 !important;
    padding-top: 5px;
    /* for the text inside the card */
    /* border-radius: 5px; */
    /* color: #babab9 !important; */
}

.cell-subc {
    color: #fff;
    font-size: 0.8rem;
    text-align: center;
}

.cell-subc-left {
    padding-left: 3px;
    padding-right: 0px;
    margin-left: 5px;
    margin-right: -5px;
}

.cell-subc-mid1 {
    padding-left: 0px;
    padding-right: 0px;
    /* margin-left: 3px; */
    margin-left: 0px;
    margin-right: -5px;
}

.cell-subc-mid2 {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
}

.cell-subc-mid3 {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
    /* margin-left: -3px;
    margin-right: 3px; */
}

.cell-subc-right {
    padding-left: 0px;
    padding-right: 0px;
    /* margin-left: -10px; */
    margin-left: -5px;
    margin-right: 0px;
}

.subc-head {
    /* text-decoration: underline; */
    text-decoration: none;
}

.cell-titl {
    font-size: 0.9rem;
    text-align: start;
    position: relative;
    left: 8px !important;
}

.cell-subc-low1 {
    margin-top: 15px;
    margin-left: 0px;
}

.comments-cell {
    text-decoration: underline;
    font-size: 1rem;
    text-align: start;
    position: relative;
    left: 4px !important;
}

.cell-neu {
    color: #dbd7d4;
}

.cell-light {
    color: #e2e2e2;
}

/* ********* COMMENTS ************* */
.comments-card {
    margin: 3px 0;
    color: #dbd7d4;
    color: #fff;
    background-color: #7C7B78;
    border: 1px;
    padding: 5px 5px;
    font-size: 0.9rem;
}

.line-break-and-tab {
    white-space: pre-wrap;
}

/* ********* END COMMENTS ********** */
.active-ranking {
    color: #dddddd;
    text-decoration: none;
    text-align: center;
    padding: 0 3px;
    font-weight: bold;
}

.inactive-ranking {
    text-decoration: underline;
    text-align: center;
    padding: 0 3px;
}

.links {
    color: #777777
}

.col-clubs {
    margin-left: -10px;
    margin-right: 0px;
}

.col-inv {
    margin-left: 0px;
    margin-right: 5px;
}

/* ********* FOR SEARCHTRADES ********** */

.src-btn {
    /* margin-left: 5px; */
    /* margin-right: 0px !important; */
    /* width: 75px; */
    background-color: #2F5C85 !important;
    margin-top: 23px;
    height: 37px;
    padding-top: 5px;
    width: 90%;
    font-size: 1rem;
    /* margin-top: 24px;
    height: 36px;
    padding-top: 5px; */

}

.src-wrapper :deep(#club-src) {
    background-color: #413e3c !important;
    color: #d9d9d9 !important;
    /* font-weight: bold; */
    font-size: 1rem;
}

.src-wrapper :deep(.form-label) {
    color: #c2c2c9 !important;
}

.openclose-wrapper :deep(#openclose) {
    background-color: #272522 !important;
    color: #babab9 !important;
    font-size: 1rem;
    margin-top: 23px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 4px;
    padding-top: 0px;
    text-decoration: underline;
    width: 68px !important;
    height: 38px;
    text-align: start;
    margin-right: 0px !important;
    margin-left: -6px !important;
    box-shadow: none !important;
    /* font-weight: bold; */
    /* border: solid !important; */
}

/* ********* END FOR SEARCHTRADES ********** */

/* ******************* BTN 3D ******************* */
.btn3d {
    border: 0;
    transition: all 40ms linear;
}

.btn3d.btn-primar {
    box-shadow: 0 0 0 1px #2f5b85a1 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

.btn3d.btn-primar:hover {
    box-shadow: 0 0 0 1px #4a95db34 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

/* ******************* END BTN 3D ******************* */
</style>
