<template>

<div class="modal fade" id="alertModal" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="alertModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content modal-area">
            <div class="modal-header mt-4 ms-3">
                <h5 class="modal-title modal-title2" id="alertModalLabel">Upload club image</h5>
                <!-- <div class="row"><img id="img-modal" src="@/assets/images/Inv_Modal.png" class="custom-img-modal"></div> -->
                <button type="button" class="btn close-butt" data-bs-dismiss="modal" @click.prevent="onClickButton" aria-label="alert">
                    <div class="btn-tex">
                        x
                    </div>
                </button>

            </div>
            <div class="divider sep-line "></div>
            <div class="modal-footer modal-body2 d-flex justify-content-center">
                <div class="row modal-row mt-3 mb-4 ">
                    <!-- <div class="row d-flex justify-content-center"> -->
                    <!-- <div class="card px-5 py-0 "> -->

                    <!-- <div class="row"> -->
                    <!-- <div class="col-3 col-last-rad"> -->
                    <!-- <span class="icon-rad"> -->
                    <!-- <BaseRadio v-model="participant.usericon" :value="11" label="" name="icons" id="I-11" /></span> -->
                    <!-- <img class="preview-icon" :src="image" alt=""> -->
                    <!-- </div> -->
                    <div class="col-7 d-flex justify-content-end">
                        <!-- <div class="mb-1"> -->
                        <input style="display: none" type="file" @change="onFileSelected" ref="fileInput" accept="image/png, image/jpeg">
                        <button @click.prevent="$refs.fileInput.click(); warningMsg=false;" class="btn browse-btn">Browse</button>
                        <!-- </div> -->
                    </div>
                    <div class="col-5">
                        <!-- {{this.image}} -->
                        <span v-if="this.image && this.image != 'I-11'">
                            <div class="preview-icon" :style="{ 'background-image': `url(${this.image})` }"></div>
                        </span>
                        <span v-else><img class="empty-image2" src="@/assets/images/I-11.png" alt="Account"></span>
                    </div>

                    <div v-show="warningMsg" class="row warn py-0">
                        <div class="col">
                            <!-- <p>jdskjdksj djskjdks jdksjd </p> -->
                            <p>{{ warningMsg }}</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col d-flex justify-content-start">
                            <a v-show="this.image && this.image != 'I-11' && !this.isLoading" href="" class="links" @click.prevent="this.image='I-11'; this.selectedFile=false;">Remove image</a>
                            <span class="warn ps-4" v-show="this.isLoading"><p>Loading, please wait...</p></span>
                        </div>
                    </div>

                </div>

                <!-- </div> -->

                <!-- <div class="row">
                            <div class="col-7">
                                <div class="mb-3 btn-pos"> <button @click.prevent="pickIcon" class="btn custom-btn btn3d btn-primar">Confirm change</button> </div>
                            </div>
                            <div class="col-5">
                                <div class="mb-3 "> <button @click.prevent="closeIcon" class="btn close-btn">Cancel</button> </div>
                            </div>
                        </div> -->
                <!-- </div> -->
                <!-- </div> -->

                <!-- <div class="col">
                        <p>{{ this.clubId }}</p>
                    </div>
                </div> -->

                <!-- <div class="row modal-row">
                    <div class="col">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click.prevent="hideAlertModal()">Close</button>
                    </div>
                </div> -->
            </div>
            <div class="divider sep-line "></div>
            <div class="modal-footer modal-body2 d-flex justify-content-center">
                <div class="row modal-row mt-5 mb-4">

                    <!-- <div class="row"> -->
                    <div class="col-7 d-flex justify-content-end">
                        <button @click.prevent="pickIcon" class="btn custom-btn btn3d btn-primar">Confirm change</button>
                    </div>
                    <div class="col-5">
                        <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click.prevent="onClickButton(event)">Cancel</button> -->
                        <button type="button" class="btn btn-secondary close-btn" @click.prevent="onClickButton">Cancel</button>
                    </div>
                    <!-- </div> -->

                </div>
            </div>

        </div>
    </div>
</div>
</template>

<script>
import {
    Modal
} from 'bootstrap'
// import {
//     mapActions
// } from 'vuex'
import store from '@/store'
import router from '@/router'
import apiCalls from '@/services/apiCalls.js'
export default {
    name: 'ClubIconModal',
    props: [
        'clubId',
        'clubImage',
    ],
    data() {
        return {
            selectedFile: false,
            image: false,
            warningMsg: false,
        }
    },
    computed: {
        isLoading() {
            return store.state.isLoading
        },
    },
    methods: {
        // ...mapActions(['setAlertMsg', 'setRfrsh']),
        hideAlertModal() {
            console.log('triggered hide modal')
            this.bsAlertModal.hide()
            return router.push('/clubsadmin')
        },
        onClickButton(event) {
            console.log(event)
            this.$emit('clicked', true) // just passing any value. This function could also receive the event if needed
            this.hideAlertModal()
        },
        randomString(len) {
            let output = ''
            for (let i = 0; i < len; ++i) {
                output += (Math.floor(Math.random() * 16)).toString(16).toUpperCase()
            }
            console.log('output: ', output)
            return output
        },
        async pickIcon() {
            try {
                if (this.warningMsg) {
                    return
                }
                // if (!this.selectedFile.name) {
                //     return false
                // }

// If the previous icon name (of this.image) was long and the new one is I-11, do a delete in the backend and images. Then set the icon of the club to I-11
                if (this.image == 'I-11' && this.clubImage.length > 4) {
                    const deleted = await apiCalls.postEntry('/delimage/', { //TODO find way in backend to check authentication when getting images
                        club: this.clubId,
                    })
                    if (deleted != null) {
                        console.log('deleted: ', deleted)
                    } else {
                        return this.warningMsg = 'Sorry, could not update club image'
                    }
                    const updatedClub = await apiCalls.patchEntry('/clubs/' + this.clubId + '/', {
                        clubicon: 'I-11'
                    })
                    if (updatedClub != null) {
                        console.log('updatedClub: ', updatedClub)
                        this.hideAlertModal()
                        return router.go()
                    } else {
                        return this.warningMsg = 'Sorry, could not update club picture'
                    }
                }
                if (!this.selectedFile) {
                    return this.warningMsg = 'Please select a file to upload or press cancel to close this window'
                }
                if (!this.selectedFile.name) {
                    return this.warningMsg = 'Not able to read this file name, please try a different one'
                }
                let filename = 'I-' + this.randomString(16)
                let extension = this.selectedFile.name.split(".").pop().toLowerCase()
                if (extension != 'png' && extension != 'jpg' && extension != 'jpeg') {
                    return this.warningMsg = `Only .png, .jpg or .jpeg image files allowed.${'\n\n'}Please select an image in one of those formats and try again.`
                    // return await this.setAlertMsg(`Only .png, .jpg or .jpeg image files allowed.${'\n\n'}Please select an image in one of those formats and try again.`)
                }
                let full_filename = `${filename}.${extension}`
                let clubicon = filename
                await this.uploadImage(full_filename)
                // TO DO If just selected the neutral icon and the previous icon was an image, implement to delete the image.
                // if (this.use == 'p' && store.state.myIcon && store.state.myIcon.length > 4 && params.usericon.length == 4)
                // const deleted = await apiCalls.postEntry('/delimage/', { //TODO find way in backend to check authentication when getting images
                //     club: this.clubId,
                // })
                // if (deleted != null) {
                //     console.log('deleted: ', deleted)
                // }
                await apiCalls.patchEntry('/clubs/' + this.clubId + '/', {
                    clubicon: clubicon
                })
                this.hideAlertModal()
                router.go()
                // this.$emit('clicked', true)
                // return router.push('/clubsadmin')
            } catch (error) {
                console.log('error in pickIcon: ', error)
            }
        },
        async onFileSelected(event) {
            console.log(event)
            if (event.target.files[0].type.indexOf("image") == -1) {
                return this.warningMsg = `File type not supported. Try a different one`
                // return await this.setAlertMsg(`File type not supported.${'\n\n'}Please select another image and try again.`)
            }
            if (event.target.files[0].size > 3200000) {
                let size = Math.round(Number(event.target.files[0].size) / 10000) / 100
                return this.warningMsg = `Image too big (${size} MB)  -  max size 3.2 MB.`
                //return await this.setAlertMsg(`Image too big (${size} MB)  -  max size 2 MB.${'\n\n'}Please select another image and try again.`)
            }
            this.selectedFile = event.target.files[0]
            this.image = URL.createObjectURL(this.selectedFile)
            console.log('this.image2: ', this.image)
        },
        async uploadImage(filename) {
            try {
                console.log('this.selectedFile: ', this.selectedFile)
                console.log('this.selectedFile.name: ', this.selectedFile.name)
                const fd = new FormData()
                // fd.append('use', 'c')
                fd.append('club', this.clubId)
                fd.append('image', this.selectedFile, filename)
                console.log('fd: ', fd)
                const uploaded = await apiCalls.postEntry('/postimage/', fd)
                // onUploadProgress: uploadEvent => {
                //     console.log('Upload Progress: ', +Math.round(uploadEvent.loaded / uploadEvent.total * 100) + '%')
                // }
                console.log('uploaded: ', uploaded)
            } catch (error) {
                console.log('error in uploadImage: ', error)
            }
        },
    },
    created() {
        this.image = this.clubImage
        console.log('SSSSSH this.image: ', this.image)
    },
    async mounted() {
        this.myAlertModal = document.getElementById('alertModal')
        this.bsAlertModal = new Modal(this.myAlertModal)
        this.bsAlertModal.show()

        // document.addEventListener('touchstart', async () => {
        //     if (this.bsAlertModal._isShown == true) {
        //         console.log('triggered touch')
        //         await this.hideAlertModal()
        //     }
        // })

        // document.addEventListener('keydown', async event => {
        //     if (event.key == 'Escape') {
        //         console.log('pressed esc')
        //         await this.setAlertMsg('')
        //         await this.setRfrsh(false)
        //     }
        // })

        // window.addEventListener('popstate', async event => {
        //     if (this.bsAlertModal._isShown == true) {
        //         console.log('pressed back', event)
        //         document.getElementById("butt-dism").click()
        //     }
        // })
    },
}
</script>

<style scoped>
/* ***** MODAL ****************************************************** */

.modal.fade .modal-dialog {
    /* height: 600px !important; */
    transform: translate3d(0, 100vh, 0);
}

.modal.show .modal-dialog {
    transform: translate3d(0, 0, 0);
    transition: transform 0.3s ease-out;
}

.modal-area {
    /* background-color: #dbd7d4; */
    background-color: #272522;
    border: solid;
    border-color: #d4d4d9;
    border-width: 1px;
    border-radius: 15px;
    /* background-color: #413e3c; */
}

.modal-header {
    border: none;
    padding: 0 0;
    margin-bottom: 0px;
}

/* .modal-title2 {
    color: #4d4d4d;
} */

.modal-body2 {
    /* color: #4d4d4d; */
    color: #e2e2e2;
    border: none;
}

.top-row-modal {
    margin-top: 25px;
    margin-bottom: -10px;
}

.modal-row {
    text-align: center;
}

.custom-img-modal {
    max-width: 100px;
    margin-top: 5px;
    margin-bottom: 10px;
    margin-left: 5px;
}

.close-butt {
    height: 25px !important;
    width: 10px !important;
    text-align: start !important;
    background-color: #b33430;
    color: white;
    margin-top: 0px !important;
    margin-right: 20px !important;
    margin-left: 0px !important;

}

.btn-tex {
    position: relative;
    top: -8px;
    left: -4px;
}

/* .line-break-and-tab {
    white-space: pre-wrap;
} */

/* .spacer-row {
    height: 10px;
} */

/* .card {
    margin-top: -15px;
    border-radius: 5px;
    border: none;
    width: 330px;
    height: 200px;
    background-color: #413e3c;
} */

.custom-btn {
    /* position: relative; */
    height: 37px;
    width: 165px;
    margin-top: 1px;
    margin-bottom: 0px;
    margin-left: -5px;
    background-color: #2F5C85;
    color: white;
    padding: 0 0;
}

.close-btn {
    /* position: relative; */
    height: 37px;
    width: 80px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0 0;
    margin-left: 0px;
    background-color: #6c6c6c;
    color: white;
}

/* ******************* BTN 3D ******************* */
.btn3d {
    border: 0;
    transition: all 40ms linear;
}

.btn3d.btn-primar {
    box-shadow: 0 0 0 1px #2f5b85a1 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

.btn3d.btn-primar:hover {
    box-shadow: 0 0 0 1px #4a95db34 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

/* ******************* END BTN 3D ******************* */

.browse-btn {
    /* position: relative; */
    /* left: -7%; */
    height: 38px;
    /* width: 200px; */
    padding-left: 45px;
    padding-right: 45px;
    padding-top: 3px !important;
    padding-bottom: 5px !important;
    margin-top: 26px;
    margin-bottom: 0px;
    margin-left: 0px;
    background-color: #828289;
    color: white;
    outline: none !important;
}

/* .browse-btn:checked {
    border-color: white !important;
    outline: none !important;
    border: solid 3px #000;
}

.browse-btn:focus {
    border-color: rgb(216, 18, 18) !important;
    outline: none !important;
    border: solid 3px #000;
}

.browse-btn:hover {
    border-color: rgb(222, 236, 26) !important;
    outline: none !important;
    border: solid 3px #000;
}

.browse-btn:active {
    border-color: rgb(94, 209, 109) !important;
    outline: none !important;
} */

/* .image-col {
    display: flex;
    justify-content: center;
} */

.preview-icon {
    height: 45px;
    width: 45px;
    margin-top: 24px;
    margin-bottom: 0px;
    margin-left: 30px;
    margin-right: 0px;
    object-fit: cover;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
}

.empty-image2 {
    height: 45px;
    width: 45px;
    margin-top: 24px;
    margin-bottom: 0px;
    margin-left: 30px;
    margin-right: 0px;
    border-radius: 50%;
    background-color: #e2e2e9;
    border: solid 1px #00b3dba6 !important;
}

.col-last-rad {
    padding-top: 30px;
    /* margin-right: -50px !important; */
}

.sep-line {
    background-color: #626269 !important;
    padding-top: 2px !important;
    margin-top: 30px;
    margin-bottom: -20px;
    width: 90%;
    margin-left: 5%;
    /* margin-right: 10% */
}

.warn {
    width: 100%;
    margin-left: 0px;;
    margin-right: 5%;
    margin-top: 20px;
    margin-bottom: -50px !important;
    font-weight: bold;
    text-align: start;
}

.links {
    color: #e2e2e9;
    font-size: 1rem;
    margin-top: 20px;
    padding-left: 30px;
    padding-right: 0px;
    margin-bottom: -40px;
}
</style>
