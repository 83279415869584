<template>
<div class="pt-2 pb-5">
    <div class="row top-row-history-pos">
        <div class="col d-flex justify-content-center">
            <h2>Past Investments</h2>
        </div>
    </div>

    <!-- <div v-if="this.showEmptyPos" class="card entry-card top-card-history"> -->
    <div v-if="this.showEmptyPos" class="card empty-card">
        <div class="row">
            <!-- <div class="col-12 cell-empty"> -->
            <div class="col-12">
                {{ this.emptyMsgPos }}
            </div>
        </div>
    </div>

    <div v-else-if="this.posentries.length > 0">

        <div class="card card-headers card-headers-history">
            <div class="row">
                <div class="col-3 cell-headers poshead-1">
                    asset
                </div>
                <div class="col-1 cell-headers poshead-2">
                    l/s
                </div>
                <div class="col-2 cell-headers poshead-3">
                    p/l
                </div>
                <div class="col-3 cell-headers poshead-4">
                    end price
                </div>
                <div class="col-3 cell-headers">
                    p/l
                </div>
            </div>
        </div>

        <div v-for="entry, index in this.posentries" :key="entry.id">
            <div class="card entry-card" :id="'hisposcard-'+index">
                <div class="row">
                    <div class="col-3 cell-tick cell-left">
                        <a class="cell-neu" href="" @click.prevent="toggleSubcardPos(index)">{{ entry.assetname.split(' ')[0].slice(0, 7) }}</a>
                        <!-- <a class="cell-neu" href="" @click.prevent="toggleSubcardPos(index)">{{ ('ABCDEFGHILMNO').slice(0, 7) }}</a> -->
                    </div>
                    <div class="col-1 cell-pri cell-mid1">
                        {{ (entry.longshort) == 'long' ? 'L' : 'S' }}
                    </div>
                    <div class="col-2 cell-pri cell-mid2">
                        <!-- <span :class="(entry.endprice / entry.startprice) > 1 ? 'cell-pos' : 'cell-neg'">
                            {{ (Math.round((entry.endprice / entry.startprice -1) * 10000) / 100).toFixed(2) }} -->
                        <span :class="(entry.endvalue / Number(entry.startvalue)) >= 1 ? 'cell-pos' : 'cell-neg'">
                            <!-- {{ (Math.round((entry.endvalue / Number(entry.startvalue) -1) * 10000) / 100).toFixed(2)}} -->
                            {{ entry.amountChg }}

                            <!-- 1,234.56 -->
                        </span>
                    </div>
                    <div v-if="Number(entry.endprice)<0.001" class="col-3 cell-pri cell-mid3">
                        {{ (Number(entry.endprice)* 1000000).toFixed(4) }}E-5
                        <!-- 1,234,567.89 -->
                    </div>
                    <div v-else class="col-3 cell-pri cell-mid3">
                        {{ (Math.round(Number(entry.endprice)* 100) / 100).toLocaleString('en-US') }}
                        <!-- 1,234,567.89 -->
                    </div>
                    <div class="col-3 cell-pri cell-right">
                        {{ (Math.round((entry.endpl) * 100) / 100).toFixed(0).toLocaleString('en-US')}}
                        <!-- 10,234,567.89 -->
                    </div>
                </div>

                <div class="row">
                    <div class="col-3 cell-qty">
                        {{ Number(entry.quantity).toLocaleString('en-US') }}
                        <!-- 10,234,567 -->
                    </div>
                    <div class="col-1 cell-curr">

                    </div>
                    <div class="col-2 cell-curr">
                        %
                    </div>
                    <div class="col-3 cell-curr">
                        {{ entry.currency.toLowerCase() }}
                    </div>
                    <div class="col-3 cell-curr">
                        {{ baseCurrency.toLowerCase() }}
                    </div>
                </div>

            </div>

            <div v-show="showCardPos[index]" class="card entry-subcard" :id="'hispossubcard-'+index">
                <div class="row">
                    <div class="col-1 cell-subc cell-subc-left">

                    </div>
                    <div class="col-3 cell-subc subc-head cell-subc-mid1">
                        start
                    </div>
                    <div class="col-2 cell-subc subc-head cell-subc-mid2">
                        chg%
                    </div>
                    <div class="col-3 cell-subc subc-head cell-subc-mid3">
                        end
                    </div>
                    <div class="col-3 cell-subc subc-head cell-subc-right">
                        p/l
                    </div>
                </div>

                <div class="row">
                    <div class="col-1 cell-subc cell-titl cell-subc-left">
                        pri
                    </div>
                    <div v-if="Number(entry.startprice)<0.001" class="col-3 cell-subc cell-subc-mid1">
                        {{ (Number(entry.startprice)*100000).toFixed(4) }}E-5
                        <!-- 1,234,567.89 -->
                    </div>
                    <div v-else class="col-3 cell-subc cell-subc-mid1">
                        {{ (Math.round(Number(entry.startprice)*100)/100).toLocaleString('en-US') }}
                        <!-- 1,234,567.89 -->
                    </div>
                    <div class="col-2 cell-subc cell-subc-mid2">
                        <span :class="(entry.endprice / entry.startprice) >= 1 ? 'cell-pos' : 'cell-neg2'">
                            <!-- {{ (Math.round((entry.endprice / entry.startprice -1) * 10000) / 100).toFixed(2)}} -->
                            {{ entry.priChg }}
                            <!-- 1,234.56 -->
                        </span>

                    </div>
                    <div v-if="Number(entry.endprice)<0.001" class="col-3 cell-subc cell-subc-mid3">
                        {{ (Number(entry.endprice)*100000).toFixed(4) }}E-5
                        <!-- 1,234,567.89 -->
                    </div>
                    <div v-else class="col-3 cell-subc cell-subc-mid3">
                        {{ (Math.round(Number(entry.endprice)*100)/100).toLocaleString('en-US') }}
                        <!-- 1,234,567.89 -->
                    </div>
                    <div class="col-3 cell-subc cell-subc-right">
                        {{ (Math.round(entry.prieffect *100)/100).toLocaleString('en-US') }}
                    </div>
                </div>

                <div class="row">
                    <div class="col-1 cell-subc cell-titl cell-subc-left">
                        fx
                    </div>
                    <div class="col-3 cell-subc cell-subc-mid1">
                        {{ (Math.round(entry.startfx * 10000) / 10000).toFixed(4) }}
                        <!-- 10.0001 -->
                    </div>
                    <div class="col-2 cell-subc cell-subc-mid2">
                        <span :class="(entry.endfx / entry.startfx) >= 1 ? 'cell-pos' : 'cell-neg2'">
                            <!-- {{ (Math.round((entry.endfx / entry.startfx -1) * 10000) / 100).toFixed(2)}} -->
                            {{ entry.fxChg }}
                            <!-- 1,234.56 -->
                        </span>

                    </div>
                    <div class="col-3 cell-subc cell-subc-mid3">
                        {{ (Math.round(entry.endfx * 10000) / 10000).toFixed(4) }}
                        <!-- 10.0001 -->
                    </div>
                    <div class="col-3 cell-subc cell-subc-right">
                        <!-- {{ entry.fxeffect.toLocaleString('en-US') }} -->
                        {{ (Math.round(entry.fxeffect *100)/100).toLocaleString('en-US') }}
                        <!-- 10,234,567.89 -->
                    </div>
                </div>

                <div class="row">
                    <div class="col-1 cell-subc cell-titl cell-subc-left">
                        {{ baseCurrency.toLowerCase() }}
                    </div>
                    <div class="col-3 cell-subc cell-subc-mid1">
                        {{ Number(entry.startvalue).toLocaleString('en-US') }}
                        <!-- 10,234,567.89 -->
                    </div>
                    <div class="col-2 cell-subc cell-subc-mid2">
                        <span :class="(entry.endvalue / Number(entry.startvalue)) >= 1 ? 'cell-pos' : 'cell-neg2'">
                            <!-- {{ (Math.round((entry.endvalue / Number(entry.startvalue) -1) * 10000) / 100).toFixed(2)}} -->
                            {{ entry.amountChg }}
                            <!-- 1,234.56 -->
                        </span>

                    </div>
                    <div class="col-3 cell-subc cell-subc-mid3">
                        {{ (Math.round((Number(entry.startvalue) + Number(entry.endpl))*100)/100).toLocaleString('en-US') }}
                        <!-- 10,234,567.89 -->
                    </div>
                    <div class="col-3 cell-subc cell-subc-right">
                        {{ Number(entry.endpl).toLocaleString('en-US')}}
                        <!-- 10,234,567.89 -->
                    </div>
                </div>

                <div class="row">
                    <div class="col-4 cell-subc comments-cell cell-subc-low1">
                        <a v-if="this.commentsVisible" class="cell-light" href="" @click.prevent="toggleCommentsPos(entry, index)">comments</a>
                        <span v-else class="cell-disabled">comments</span>
                        <!-- <a class="cell-neu cell-light" href="" @click.prevent="toggleCommentsPos(entry, index)">comments</a> -->
                    </div>
                </div>
            </div>

            <div v-show="showCommentPos[index]" class="container-fluid px-0">
                <div class="card comments-card">
                    <div class="row">
                        <div class="col line-break-and-tab" :id="'hisposcomment-' + index">
                            <p> {{ this.updatedcommentPos[index] }} </p>
                        </div>
                    </div>
                </div>

                <div v-show="!this.posTextArea[index] && this.commentsAllowed" class="row">
                    <div class="col">
                        <button role="button" @click.prevent="this.addPosComment(index)" class="btn addcomm-btn btn3d btn-primar">Add a comment</button>
                    </div>
                </div>
                <div v-show="this.posTextArea[index]" class="row">
                    <div class="col-sm-12">
                        <div class="form-floating">
                            <textarea class="form-control comment-textarea" maxlength="3000" :id="'hisposfloatingTextarea-' + index" v-model="this.newcomment[index]" @drop.prevent="false" @paste.prevent="false"></textarea>
                        </div>
                    </div>
                </div>
                <div v-show="this.posTextArea[index]" class="row">
                    <!-- <button v-show="this.textArea[index]" role="button" @click.prevent="enterComment(entry, index)" class="btn shadow rounded entercomm-btn">Enter comment</button> -->
                    <!-- <button v-show="this.textArea[index]" role="button" @click.prevent="this.newcomment[index] = ''; toggleComments(entry, index);" class="btn shadow rounded entercomm-btn">Cancel</button> -->
                    <div class="col-10">
                        <button role="button" @click.prevent="enterPosComment(entry, index)" class="btn entercomm-btn btn3d btn-primar">Enter comment</button>
                        <!-- </div>
                    <div class="col-3"> -->
                        <button role="button" @click.prevent="this.newcomment[index] = ''; this.posTextArea[index] = false;" class="btn btn-secondary btn-secondary-comment shadow rounded ">Cancel</button>
                    </div>

                </div>

            </div>

        </div>

    </div>
    <div class="row top-row-history-wtc">
        <div class="col d-flex justify-content-center">
            <h2>Previously in watchlist</h2>
        </div>
    </div>

    <!-- <div v-if="this.showEmptyWtc" class="card entry-card top-card-history"> -->
    <div v-if="this.showEmptyWtc" class="card empty-card">
        <div class="row">
            <!-- <div class="col-12 cell-empty"> -->
            <div class="col-12">
                {{ this.emptyMsgWtc }}
            </div>
        </div>
    </div>

    <div v-else-if="this.wtcentries.length > 0">

        <div class="card card-headers card-headers-history">
            <div class="row">
                <div class="col-3 cell-headers wtchead-1">
                    asset
                </div>
                <div class="col-1 cell-headers wtchead-2">
                    l/s
                </div>
                <div class="col-3 cell-headers wtchead-3">
                    start
                </div>
                <div class="col-2 cell-headers wtchead-4">
                    chg
                </div>
                <div class="col-3 cell-headers">
                    end
                </div>
            </div>
        </div>

        <div v-for="entry, index in this.wtcentries" :key="entry.id">
            <div class="card entry-card" :id="'hiswtccard-'+index">
                <div class="row">
                    <div class="col-3 cell-tick cell-left">
                        <a class="cell-neu" href="" @click.prevent="toggleSubcardWtc(index)">{{ entry.assetname.split(' ')[0].slice(0, 6) }}</a>
                    </div>
                    <div class="col-1 cell-pri cell-mid1">
                        {{ (entry.longshort == 'long') ? 'L' : 'S' }}
                    </div>
                    <div v-if="Number(entry.startprice)<0.01" class="col-3 cell-pri cell-mid2">
                        {{ (Number(entry.startprice)*100000).toFixed(4) }}E-5
                    </div>
                    <div v-else class="col-3 cell-pri cell-mid2">
                        {{ (Math.round(Number(entry.startprice)*100)/100).toLocaleString('en-US') }}
                    </div>
                    <div class="col-2 cell-pri">
                        <span :class="(entry.endprice/entry.startprice) >= 1 ? 'cell-pos' : 'cell-neg'">
                            <!-- {{ (Math.round((entry.endprice / entry.startprice -1) * 10000) / 100).toFixed(2) }} -->
                            {{ entry.priChg }}
                        </span>
                    </div>
                    <div v-if="Number(entry.endprice)<0.001" class="col-3 cell-pri cell-mid3-wtc">
                        {{ (Number(entry.endprice)*100000).toFixed(4) }}E-5
                    </div>
                    <div v-else class="col-3 cell-pri cell-mid3-wtc">
                        {{ (Math.round(Number(entry.endprice)*100)/100).toLocaleString('en-US') }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-3 cell-qty cell-left">

                    </div>
                    <div class="col-1 cell-curr cell-mid1">

                    </div>
                    <div class="col-3 cell-curr cell-mid2">
                        {{ entry.currency.toLowerCase() }}
                    </div>
                    <div class="col-2 cell-curr cell-mid3">
                        %
                    </div>
                    <div class="col-3 cell-curr cell-right">
                        {{ entry.currency.toLowerCase() }}
                    </div>
                </div>

            </div>

            <div v-show="showCardWtc[index]" class="card entry-subcard entry-subcard-wtc" :id="'hiswtcsubcard-'+index">

                <div class="row">
                    <div class="col-1 cell-subc cell-subc-left">

                    </div>
                    <div class="col-3 cell-subc subc-head cell-subc-mid1">

                    </div>
                    <div class="col-3 cell-subc subc-head cell-subc-mid2 mid2-wtc">
                        start
                    </div>
                    <div class="col-2 cell-subc subc-head cell-subc-mid3 mid3-wtc">
                        chg%
                    </div>
                    <div class="col-3 cell-subc subc-head cell-subc-right">
                        end
                    </div>
                </div>

                <div class="row">
                    <div class="col-4 cell-subc cell-titl cell-subc-left">
                        <!-- fx {{ entry.currency.toLowerCase() }}/{{ baseCurrency.toLowerCase() }} -->
                        fx {{ entry.currency.toLowerCase() }}/{{ entry.currencysymbol.slice(-5, -2).toLowerCase() }}
                    </div>
                    <div class="col-3 cell-subc cell-subc-mid2 mid2-wtc mid2-wtc-num">
                        {{ (Math.round(entry.startfx * 10000) / 10000).toFixed(4) }}
                    </div>
                    <div class="col-2 cell-subc cell-subc-mid3 mid3-wtc">
                        <span :class="(entry.endfx / entry.startfx) >= 1 ? 'cell-pos' : 'cell-neg2'">
                            <!-- {{ (Math.round((entry.endfx / entry.startfx -1) * 10000) / 100).toFixed(2)}} -->
                            {{ entry.fxChg }}
                        </span>
                    </div>
                    <div class="col-3 cell-subc cell-subc-right">
                        {{ (Math.round(entry.endfx * 10000) / 10000).toFixed(4) }}
                    </div>
                </div>

                <div class="row last-row-subc-wtc">
                    <div class="col-4 cell-subc comments-cell cell-subc-low1">
                        <a v-if="this.commentsVisible" class="cell-light" href="" @click.prevent="toggleCommentsWtc(entry, index)">comments</a>
                        <span v-else class="cell-disabled">comments</span>
                        <!-- <a class="cell-neu cell-light" href="" @click.prevent="toggleCommentsWtc(entry, index)">comments</a> -->
                    </div>
                </div>
            </div>

            <div v-show="showCommentWtc[index]" class="container-fluid px-0">
                <div class="card comments-card">
                    <div class="row">
                        <div class="col line-break-and-tab" :id="'hiswtccomment-' + index">
                            <p> {{ this.updatedcommentWtc[index] }} </p>
                        </div>
                    </div>
                </div>
                <div v-show="!this.wtcTextArea[index] && this.commentsAllowed" class="row">
                    <div class="col">
                        <button role="button" @click.prevent="this.addWtcComment(index)" class="btn addcomm-btn btn3d btn-primar">Add a comment</button>
                    </div>
                </div>
                <div v-show="this.wtcTextArea[index]" class="row">
                    <div class="col-sm-12">
                        <div class="form-floating">
                            <textarea class="form-control comment-textarea" maxlength="3000" :id="'hiswtcfloatingTextarea-' + index" v-model="this.newcomment[index]" @drop.prevent="false" @paste.prevent="false"></textarea>
                        </div>
                    </div>
                </div>
                <div v-show="this.wtcTextArea[index]" class="row">
                    <div class="col-10">
                        <button role="button" @click.prevent="enterWtcComment(entry, index)" class="btn entercomm-btn btn3d btn-primar">Enter comment</button>
                        <!-- </div>
                    <div class="col-3"> -->
                        <button role="button" @click.prevent="this.newcomment[index] = ''; this.wtcTextArea[index] = false;" class="btn btn-secondary btn-secondary-comment shadow rounded ">Cancel</button>
                    </div>
                </div>
            </div>

        </div>

    </div>

</div>
</template>

<script>
import apiCalls from '@/services/apiCalls.js'
import store from '@/store'
import {
    mapActions
} from 'vuex'
// import router from '@/router'
export default {
    name: 'HistoryTable',
    data() {
        return {
            width: 0,
            particId: null,
            // particIcon: null,
            posentries: [],
            wtcentries: [],
            showEmptyPos: false,
            showEmptyWtc: false,
            emptyMsgPos: '',
            emptyMsgWtc: '',
            showCardPos: [],
            showCardWtc: [],
            showCommentPos: [],
            showCommentWtc: [],
            updatedcommentPos: [],
            updatedcommentWtc: [],
            hideComments: false,
            stopComments: false,
            commentsVisible: false,
            commentsAllowed: false,

            posTextArea: [],
            wtcTextArea: [],
            newcomment: [],
            // updatedcomment: [],

        }
    },
    props: [
        'category',
    ],
    // computed: {
    // baseCurrency() {
    //     return store.state.myCur
    // },
    // },
    methods: {
        ...mapActions(['setParticId', 'setCommentsVisible', 'setCommentsAllowed', 'setParticIcon']),
        // showSmtg() {
        //     console.log('showCard: ', this.showCard)
        //     console.log('showComment: ', this.showComment)
        // },
        async getAssets() {
            try {
                // this.endPoint = '/' + category + '/'
                let params = {}
                if (this.particId && this.particId != store.state.myPid) {
                    // console.log('this.particId: ', this.particId)
                    params = {
                        'participant': this.particId,
                        'closed': 1
                    }
                    this.emptyMsgPos = 'No past investments for'
                    this.emptyMsgWtc = 'No asset previously in watchlist'
                } else {
                    params = {
                        'participant': store.state.myPid,
                        'closed': 1
                    }
                    this.emptyMsgPos = 'No past investments'
                    this.emptyMsgWtc = 'No asset previously in watchlist'
                }
                const PosEntries = await apiCalls.getEntries('/positions/', params)
                if (PosEntries && PosEntries.data.length > 0) {
                    this.posentries = PosEntries.data
                    this.posentries.forEach(asset => { // RECENT 231222
                        asset.quantity = Number(asset.quantity)
                    })
                    // console.log('this.posentries: ', this.posentries)
                    this.updatedcommentPos = PosEntries.data.map(a => a.comment)

                    this.posentries.forEach(uE => {
                        // console.log('uE.startvalue: ', typeof uE.startvalue)
                        // console.log('uE.endpl: ', typeof uE.endpl)
                        // console.log('uE.startfx: ', typeof uE.startfx)
                        // console.log('uE.endfx: ', typeof uE.endfx)
                        // console.log('uE.startvalue: ', uE.startvalue)
                        // console.log('uE.endpl: ', uE.endpl)
                        // console.log('uE.startfx: ', uE.startfx)
                        // console.log('uE.endfx: ', uE.endfx)

                        uE.endvalue = Number(uE.startvalue) + Number(uE.endpl)
                        switch (uE.longshort) {
                            case 'long':
                                uE.fxeffect = uE.endvalue * (1 - Number(uE.startfx) / Number(uE.endfx))
                                uE.prieffect = uE.endpl - uE.fxeffect
                                break
                            case 'short':
                                uE.fxeffect = uE.endpl * (1 - Number(uE.startfx) / Number(uE.endfx))
                                uE.prieffect = uE.endpl - uE.fxeffect
                                break
                        }
                        // console.log(uE.closed)
                        // console.log('uE.endvalue: ', typeof uE.endvalue)
                        // console.log('uE.fxeffect: ', typeof uE.fxeffect)
                        // console.log('uE.endvalue: ', uE.endvalue)
                        // console.log('uE.fxeffect: ', uE.fxeffect)

                        uE.priChg = new Intl.NumberFormat("en-US", {
                            signDisplay: "exceptZero"
                        }).format(Math.round((uE.endprice / uE.startprice - 1) * 10000) / 100)
                        uE.fxChg = new Intl.NumberFormat("en-US", {
                            signDisplay: "exceptZero"
                        }).format(Math.round((uE.endfx / uE.startfx - 1) * 10000) / 100)
                        uE.amountChg = new Intl.NumberFormat("en-US", {
                            signDisplay: "exceptZero"
                        }).format(Math.round((uE.endvalue / Number(uE.startvalue) - 1) * 10000) / 100)

                    })

                } else {
                    this.showEmptyPos = true
                }
                const WtcEntries = await apiCalls.getEntries('/watchassets/', params)
                if (WtcEntries && WtcEntries.data.length > 0) {
                    this.wtcentries = WtcEntries.data
                    console.log('this.wtcentriesHistoryTable: ', this.wtcentries)
                    this.updatedcommentWtc = WtcEntries.data.map(a => a.comment)
                    // console.log('this.updatedcomment: ', this.updatedcomment)
                    this.wtcentries.forEach(uE => {
                        uE.priChg = new Intl.NumberFormat("en-US", {
                            signDisplay: "exceptZero"
                        }).format(Math.round((uE.endprice / uE.startprice - 1) * 10000) / 100)
                        uE.fxChg = new Intl.NumberFormat("en-US", {
                            signDisplay: "exceptZero"
                        }).format(Math.round((uE.endfx / uE.startfx - 1) * 10000) / 100)
                    })
                } else {
                    this.showEmptyWtc = true
                }

            } catch (error) {
                return console.log('error status: ', error)
            }
        },
        async getParticipant() {
            try {
                console.log('baseCurrency in History 1: ', this.baseCurrency)
                if (store.state.particId != null && store.state.particId != store.state.myPid) { // Looking into a Participant other than myself
                    this.particId = store.state.particId
                } else if (store.state.myPid != null) { // Before here it would just exit because it didn't need stopcomments! Now is it needed? Maybe to know if go ahead with getAllowed comments. Anyway should not disturb
                    this.particId = store.state.myPid
                } else {
                    console.log('Missing both store.state.particId and store.state.myPid in TablePortfolio - forcing logout')
                    await this.setRfrsh('/login')
                    return await this.setAlertMsg(`Network error. Please login again.${'\n\n'}Should the error persist, please inform the administrators. Thank you.`)
                }
                let participantData = {}
                if (store.state.page != '/investor' || store.state.investorData == null) {
                    console.log('AAAATTTT investorData not used in history getParticipant: ', store.state.investorData)
                    let Entries = null
                    if (this.particId != null) {
                        Entries = await apiCalls.getEntries('/participants/' + this.particId + '/', {})
                    } else {
                        console.log('this.particId is null - forcing logout')
                        await this.setRfrsh('/login')
                        return await this.setAlertMsg(`Network error. Please login again.${'\n\n'}Should the error persist, please inform the administrators. Thanks.`)
                    }
                    if (Entries != null) {
                        participantData = Entries.data
                    }
                } else if (store.state.page == '/investor' && store.state.investorData != null) {
                    participantData = store.state.investorData
                    console.log('AAAATTTT investorData used in history getParticipant: ', store.state.investorData)
                }
                if (Object.keys(participantData).length != 0) { // means participantData is not empty
                    if (!localStorage.getItem('paic') || store.state.particIcon == null) { // this is the case when it is routing from View buttom in a notification and misses the icon of the notifier
                        localStorage.setItem('paic', participantData['usericon'])
                        await this.setParticIcon(participantData['usericon'])
                    }

                    this.baseCurrency = participantData['basecurrency'] // participantDeatail returns an object, not a list! (so would give error with part.data[0])
                    // this.hideComments = participantData['stopcomments'] // DELETE
                    this.hideComments = participantData['hidecomments'] //RESTORE
                    this.stopComments = participantData['stopcomments'] // ADDED to allow commenting in history

                    // console.log('store.state.page in History getParticipant: ', store.state.page)
                    // if (store.state.page == '/investor') {
                    //     this.stopComments = part.data['stopcomments']
                    // }  
                } else {
                    console.log('could not getParticipant in TableHistory')
                    await this.setRfrsh('/login')
                    return await this.setAlertMsg(`Network error. Please login again.${'\n\n'}Should the error persists, please inform the administrators.`)
                }
                // console.log('baseCurrency in History 2: ', this.baseCurrency)
            } catch (err) {
                console.log('error in History/getParticipant: ', err)
            }

        },
        async getAllowCommentsHistory() {
            try {
                this.commentsVisible = false
                this.commentsAllowed = false
                let particClubsList = []
                let myClubsList = []
                let commonMemberships = false
                if (this.particId == store.state.myPid) {
                    this.commentsVisible = true
                    this.commentsAllowed = true
                    return
                }
                const blockedMe = await apiCalls.getEntries('/blocks/', {
                    'blocked': store.state.myPid,
                    'blocker': this.particId,
                })
                if (blockedMe && blockedMe.data.length > 0) {
                    this.commentsVisible = false
                    this.commentsAllowed = false
                    return
                }
                if (!this.hideComments && !this.stopComments) { //&& !this.stopComments
                    this.commentsVisible = true
                    this.commentsAllowed = true
                    return
                }
                // If at least one of the 2 restrictions is true check if there are commonMemberships
                const particMemberships = await apiCalls.getEntries('/memberships/', {
                    'participant': this.particId,
                    'status': 'active',
                })
                if (particMemberships && particMemberships.data.length > 0) {
                    console.log('particMemberships: ', particMemberships.data)
                    particClubsList = particMemberships.data.map(a => a.club)
                    console.log('particClubsList: ', particClubsList)
                    const myMemberships = await apiCalls.getEntries('/memberships/', {
                        'participant': store.state.myPid,
                        'status': 'active',
                    })
                    if (myMemberships && myMemberships.data.length > 0) {
                        console.log('myMembershipsInPortfolio: ', myMemberships.data)
                        myClubsList = myMemberships.data.map(a => a.club)
                        console.log('myClubsList: ', myClubsList)
                        commonMemberships = particClubsList.some(item => myClubsList.includes(item))
                    } else {
                        commonMemberships = false
                    }
                } else {
                    commonMemberships = false
                }
                if (commonMemberships == true) {
                    this.commentsVisible = true
                    this.commentsAllowed = true
                    return
                }
                // if there are no common memberships, then the restrictions apply
                if (this.hideComment) {
                    this.commentsVisible = false
                    this.commentsAllowed = false
                    return
                } else if (this.stopComment) {
                    this.commentsVisible = true
                    this.commentsAllowed = false
                    return
                }
            } catch (err) {
                console.log('error in getAllowComments: ', err)
                this.commentsVisible = false
                this.commentsAllowed = false
                return
            }
        },
        toggleSubcardPos(index) {
            if (this.showCommentPos[index]) {
                this.showCommentPos[index] = false
            }
            if (!this.showCardPos[index]) {
                this.showCardPos[index] = !this.showCardPos[index]
                setTimeout(() => { // NEW 20230308 
                    return document.getElementById('hisposcard-' + index).scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    })
                }, 0)

            } else {
                return this.showCardPos[index] = !this.showCardPos[index]
            }
        },
        async toggleCommentsPos(entry, index) {
            // if (!this.showCommentPos[index] && entry.extracomments) {
            if (!this.showCommentPos[index]) {

                let cutPos = entry.comment.indexOf("INITIAL COMMENT:")
                let aliasstart = entry.comment.substr(0, cutPos).lastIndexOf('\n\n') + 2
                let aliasstr = entry.comment.slice(aliasstart, cutPos - 3)
                let tradesstr = entry.comment.substr(0, cutPos - 3 - aliasstr.length)
                // let tradesstr = entry.comment.substr(0, cutPos -7)
                // let iconstr = entry.comment.substr(cutPos -7, 4)
                let commentstr = entry.comment.substr(cutPos + 16)
                this.updatedcommentPos[index] = `${tradesstr}<b><a href="/${entry.participant}" style="color: #fff;">${aliasstr}</a></b> - INITIAL COMMENT:${commentstr}${'\n'}`
                console.log('this.updatedcomment[index]: ', this.updatedcommentPos[index])
                // let tradesstr = entry.comment.substr(0, entry.comment.indexOf("INITIAL COMMENT:")-9)
                // let commentstr = entry.comment.substr(entry.comment.indexOf("INITIAL COMMENT:")-9)
                // this.updatedcommentPos[index] =`${tradesstr}${commentstr}${'\n'}`

                // this.updatedcommentPos[index] = `${entry.comment}${'\n'}`
                let params = {
                    'position': entry.id,
                }
                if (entry.extracomments) {
                    try {
                        const extracomms = await apiCalls.getEntries('/poscomments/', params)
                        console.log('extracomms: ', extracomms)
                        // let updatedComment = entry.comment
                        extracomms.data.forEach(obj => {
                            // console.log('obj: ', obj)
                            this.updatedcommentPos[index] = (
                                `${this.updatedcommentPos[index]}${'\n'}<b><a href="/${obj.participant}" style="color: #fff;">${obj.useralias}</a></b> - ${obj.created.slice(0, 10)}:${'\n'}${obj.comment}${'\n'}`
                                // `${this.updatedcommentPos[index]}${'\n'}<i><b><a href="/partics/${obj.usericon.slice(-2) + obj.participant}" style="color: #fff;">${obj.usericon}</a></b> - ${obj.created.slice(0, 10)}:</i> ${'\n'}${obj.comment}${'\n'}`
                            )
                            // this.updatedcomment[index] = `${this.updatedcomment[index]}${'\n'}<b><u>${obj.usericon}</u></b> <i>${obj.created.slice(0, 17).replace('T', ' @')}</i> ${'\n'}${obj.comment}${'\n'}`
                        })
                        // entry.comment = updatedComment
                        // document.getElementById('poscomment-' + index).innerHTML = this.updatedcommentPos[index]
                        // console.log('this.updatedcomment[index]: ', this.updatedcomment[index])
                        // return this.showCommentPos[index] = !this.showCommentPos[index]
                        // console.log('this.updatedcomment[index] with extracomments: ', this.updatedcomment[index])
                    } catch (err) {
                        console.log(err)
                    }
                }
                // console.log('this.updatedcommentPos[index] after: ', this.updatedcommentPos[index])
                document.getElementById('hisposcomment-' + index).innerHTML = this.updatedcommentPos[index]

                this.showCommentPos[index] = !this.showCommentPos[index]
                setTimeout(() => { // NEW 20230308 
                    return document.getElementById('hispossubcard-' + index).scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    })
                }, 0)
            } else {
                return this.showCommentPos[index] = !this.showCommentPos[index]
            }
        },
        addPosComment(index) {
            this.posTextArea[index] = true
            setTimeout(() => { // NEW 20230308 // Seems that in reactive languages like React, Vue etc focus command doesn't work if you don't place inside setTimeout
                document.getElementById('hisposfloatingTextarea-' + index).focus()
                document.getElementById('hisposfloatingTextarea-' + index).scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                })
            }, 0)
        },
        async enterPosComment(entry, index) { // TODO: validate the comment!!! 
            if (!this.newcomment[index]) {
                return
            }
            // if (!entry.extracomments) {   // NO NEED BECAUSE WHEN TOGGLING HAVE ALREADY DEFINED THIS.UPDATEDCOMMENT[INDEX]
            //     this.updatedcomment[index] = `${entry.comment}${'\n'}`
            // }
            let newComment = {
                'participant': store.state.myPid,
                // 'usericon': store.state.myIcon,
                'useralias': store.state.myAlias,
                'position': entry.id,
                'comment': this.newcomment[index]
            }
            try {
                const resp = await apiCalls.postEntry('/poscomments/', newComment)
                if (entry.extracomments == false) {
                    let params = {
                        'extracomments': true
                    }
                    await apiCalls.patchEntry('/positions/' + entry.id + '/', params)
                    entry.extracomments = true //will tell me not to patch the position if I add a second one immediately
                    localStorage.setItem('cnu', '1')
                    console.log('localStorage.getItem(cnu) in enterComment: ', localStorage.getItem('cnu'))
                    // await this.setCommNum('1')
                    // console.log('store.state.commNum: ', store.state.commNum)
                    // console.log('typeof store.state.commNum: ', typeof store.state.commNum)
                } else {
                    let commentsNum = Number(localStorage.getItem('cnu')) + 1
                    localStorage.setItem('cnu', commentsNum)
                    // console.log('localStorage.getItem(cnu) in enterComment 2: ', localStorage.getItem('cnu'))
                    // let commentsNum = Number(store.state.commNum) + 1
                    // await this.setCommNum(commentsNum)
                    // console.log('store.state.commNum: ', store.state.commNum)
                    // console.log('typeof store.state.commNum: ', typeof store.state.commNum)
                }
                if (store.state.particId && store.state.particId != store.state.myPid) { // I am looking at someone else's portfolio, cannot remove or report comments
                    this.updatedcommentPos[index] = (
                        `${this.updatedcommentPos[index]}${'\n'}<b><a href="/${resp.data.participant}" style="color: #fff;">${resp.data.useralias}</a></b> - ${resp.data.created.slice(0, 10)}: ${'\n'}${resp.data.comment}${'\n'}`
                    )
                    // console.log('this.updatedcomment[index]3: ',this.updatedcomment[index])
                } else { // I'm in my own portfolio, so can remove this comment
                    // if (entry.commentsNum > 1) {
                    this.updatedcommentPos[index] = (
                        `${this.updatedcommentPos[index]}${'\n'}<b><a href="/${resp.data.participant}" style="color: #fff;">${resp.data.useralias}</a></b> - ${resp.data.created.slice(0, 10)}:   ( <i><u><a href="/remove?a=p&c=${resp.data.id}&p=${entry.id}" style="color: #fff;">remove</a></i></u> )${'\n'}${resp.data.comment}${'\n'}`
                    )
                    // console.log('this.updatedcomment[index]4: ',this.updatedcomment[index])
                    // } else if(entry.commentsNum == 1) {

                    // }
                }
                entry.comment = `${'\n'}${this.updatedcommentPos[index]}`
                entry.comment = entry.comment.trim() // RECENT //Trim drops the first and last line breaks
                document.getElementById('hisposcomment-' + index).innerHTML = this.updatedcommentPos[index]
                this.posTextArea[index] = false
                return this.newcomment[index] = ''
            } catch (error) {
                return console.log('errorTI15: ', error)
            }
        },

        toggleSubcardWtc(index) {
            if (this.showCommentWtc[index]) {
                this.showCommentWtc[index] = false
            }
            if (!this.showCardWtc[index]) {
                this.showCardWtc[index] = !this.showCardWtc[index]
                setTimeout(() => { // NEW 20230308 
                    return document.getElementById('hiswtccard-' + index).scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    })
                }, 0)

            } else {
                return this.showCardWtc[index] = !this.showCardWtc[index]
            }
        },
        async toggleCommentsWtc(entry, index) {
            // if (!this.showCommentWtc[index] && entry.extracomments) {
            if (!this.showCommentWtc[index]) {
                let cutPos = entry.comment.indexOf("INITIAL COMMENT:")
                let aliasstart = entry.comment.substr(0, cutPos).lastIndexOf('\n\n') + 2
                let aliasstr = entry.comment.slice(aliasstart, cutPos - 3)
                let tradesstr = entry.comment.substr(0, cutPos - 3 - aliasstr.length)

                // let tradesstr = entry.comment.substr(0, cutPos -7)
                // let iconstr = entry.comment.substr(cutPos -7, 4)
                let commentstr = entry.comment.substr(cutPos + 16)
                this.updatedcommentWtc[index] = `${tradesstr}<b><a href="/${entry.participant}" style="color: #fff;">${aliasstr}</a></b> - INITIAL COMMENT:${commentstr}${'\n'}`

                // let tradesstr = entry.comment.substr(0, entry.comment.indexOf("INITIAL COMMENT:")-9)
                // let commentstr = entry.comment.substr(entry.comment.indexOf("INITIAL COMMENT:")-9)
                // this.updatedcommentWtc[index] =`${tradesstr}${commentstr}${'\n'}`

                // this.updatedcommentWtc[index] = `${entry.comment}${'\n'}`
                let params = {
                    'watchasset': entry.id,
                }
                if (entry.extracomments) {
                    try {
                        const extracomms = await apiCalls.getEntries('/wtccomments/', params)
                        console.log('extracomms: ', extracomms)
                        // let updatedComment = entry.comment
                        extracomms.data.forEach(obj => {
                            // console.log('obj: ', obj)
                            this.updatedcommentWtc[index] = (
                                `${this.updatedcommentWtc[index]}${'\n'}<b><a href="/${obj.participant}" style="color: #fff;">${obj.useralias}</a></b> - ${obj.created.slice(0, 10)}:${'\n'}${obj.comment}${'\n'}`
                                // `${this.updatedcommentWtc[index]}${'\n'}<i><b><a href="/partics/${obj.usericon.slice(-2) + obj.participant}" style="color: #fff;">${obj.usericon}</a></b> - ${obj.created.slice(0, 10)}:</i> ${'\n'}${obj.comment}${'\n'}`
                            )
                        })
                        // entry.comment = updatedComment
                        // document.getElementById('wtccomment-' + index).innerHTML = this.updatedcommentWtc[index]
                        // console.log('this.updatedcommentWtc[index]: ', this.updatedcommentWtc[index])
                        // return this.showCommentWtc[index] = !this.showCommentWtc[index]
                    } catch (err) {
                        console.log(err)
                    }
                }
                // console.log('this.updatedcommentWtc[index] after: ', this.updatedcommentWtc[index])
                document.getElementById('hiswtccomment-' + index).innerHTML = this.updatedcommentWtc[index]

                this.showCommentWtc[index] = !this.showCommentWtc[index]
                setTimeout(() => { // NEW 20230308 
                    return document.getElementById('hiswtcsubcard-' + index).scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    })
                }, 0)
            } else {
                return this.showCommentWtc[index] = !this.showCommentWtc[index]
            }
        },
        addWtcComment(index) {
            this.wtcTextArea[index] = true
            setTimeout(() => { // NEW 20230308 // Seems that in reactive languages like React, Vue etc focus command doesn't work if you don't place inside setTimeout
                document.getElementById('hiswtcfloatingTextarea-' + index).focus()
                document.getElementById('hiswtcfloatingTextarea-' + index).scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                })
            }, 0)
        },
        async enterWtcComment(entry, index) {
            if (!this.newcomment[index]) {
                return
            }
            // if (!entry.extracomments) { // NOT NEEDED - SEE IN TABLEITEMS1
            //     this.updatedcomment[index] =  `${entry.comment}${'\n'}`
            // }
            console.log('comment entry: ', entry)
            let newComment = {
                // 'club': store.state.myClub,
                'participant': store.state.myPid,
                // 'usericon': store.state.myIcon,
                'useralias': store.state.myAlias,
                'watchasset': entry.id,
                'comment': this.newcomment[index]
            }
            // console.log('new comment', newComment)
            // console.log('entry.id', entry.id)
            try {
                const resp = await apiCalls.postEntry('/wtccomments/', newComment)
                if (entry.extracomments == false) {
                    let params = {
                        'extracomments': true
                    }
                    await apiCalls.patchEntry('/watchassets/' + entry.id + '/', params)
                    entry.extracomments = true //will tell me not to patch the watchasset if I add a second one immediately
                    localStorage.setItem('cnu', '1')
                    console.log('localStorage.getItem(cnu): ', localStorage.getItem('cnu'))
                } else {
                    let commentsNum = Number(localStorage.getItem('cnu')) + 1
                    localStorage.setItem('cnu', commentsNum)
                    console.log('localStorage.getItem(cnu): ', localStorage.getItem('cnu'))
                }
                if (store.state.particId && store.state.particId != store.state.myPid) { // I am looking at someone else's watchlist, cannot remove or report comments
                    this.updatedcommentWtc[index] = (
                        `${this.updatedcommentWtc[index]}${'\n'}<b><a href="/${resp.data.participant}" style="color: #fff;">${resp.data.useralias}</a></b> - ${resp.data.created.slice(0, 10)}: ${'\n'}${resp.data.comment}${'\n'}`
                    )
                } else { // I'm in my own watchlist, so can remove this comment
                    this.updatedcommentWtc[index] = (
                        `${this.updatedcommentWtc[index]}${'\n'}<b><a href="/${resp.data.participant}" style="color: #fff;">${resp.data.useralias}</a></b> - ${resp.data.created.slice(0, 10)}:   ( <i><u><a href="/remove?a=w&c=${resp.data.id}&p=${entry.id}" style="color: #fff;">remove</a></i></u> )${'\n'}${resp.data.comment}${'\n'}`
                    )
                }
                entry.comment = `${'\n'}${this.updatedcommentWtc[index]}`
                entry.comment = entry.comment.trim() // RECENT

                // console.log('this.updatedcomment[index]: ', this.updatedcomment[index])
                document.getElementById('hiswtccomment-' + index).innerHTML = this.updatedcommentWtc[index]
                this.wtcTextArea[index] = false
                return this.newcomment[index] = ''
                // return this.showComment[index] = false
            } catch (error) {
                return console.log('error while entering new comment: ', error)
            }
        },

        onResize() {
            this.width = window.innerWidth
        },
        // toggleClosePos(index) {
        //     this.showClosePos[index] = !this.showClosePos[index]
        // }
    },
    async created() {
        try {
            console.log('creating history 1')
            this.baseCurrency = store.state.myCur // temporarily here to avoid error in rendering until it gets it through getParticipant
            await this.getParticipant()
            // await this.getAllowComments()
            console.log('AAAATTTT store.state.page in History created: ', store.state.page)
            console.log('AAAATTTT store.state.commentsVisible in History created: ', store.state.commentsVisible)
            console.log('AAAATTTT store.state.commentsAllowed in History created: ', store.state.commentsAllowed)
            // if (store.state.page != '/investor' || (store.state.commentsVisible == null || store.state.commentsAllowed == null)) {
            if (store.state.commentsVisible == null || store.state.commentsAllowed == null) {
                await this.getAllowCommentsHistory()
                console.log('AAAATTTT comments settings not used not used in history created: ', store.state.page)
            } else {
                this.commentsVisible = store.state.commentsVisible
                this.commentsAllowed = store.state.commentsAllowed
                console.log('AAAATTTT used store.state.commentsVisible and store.state.commentsAllowed in history created: ', store.state.page)
            }
            // if (store.state.page == '/investor') {
            //     await this.getAllowComments()
            //     await this.setCommentsVisible(this.commentsVisible)
            //     await this.setCommentsAllowed(this.commentsAllowed)
            //     console.log('AAAATTTT commentsVisible and commentsAllowed generated in History created: ', store.state.page)
            // } else {
            //     await this.getAllowCommentsHistory() // This is for History alone, no need to define both commentsVisible and commentsAllowed
            //     console.log('AAAATTTT commentsAllowed not generated in History created: ', store.state.page)
            // }
            await this.getAssets()
            console.log('creating history 2')
        } catch (err) {
            return console.log('error in history created', err)
        }
    },
    mounted() {
        window.addEventListener('resize', this.onResize)
        this.width = window.innerWidth
    },
    unmounted() {
        window.removeEventListener('resize', this.onResize)
    }
}
</script>

<style scoped>
.entry-card {
    background-color: #413e3c !important;
    height: 60px;
    width: 100%;
    margin: 3px auto;
    padding-top: 5px;
    text-align: center;
    font-size: 0.8rem;
}

/* .cell-empty {
    font-size: 0.9rem;
} */

.card-headers {
    height: 30px;
    background-color: #272522 !important;
    /* color: #d97b2d !important;
    color: #f48043 !important; */
    margin-bottom: -5px;
    margin-top: 45px;
    text-align: center;
    border: none;
    font-size: 0.8rem;
}

.cell-headers {
    color: #6c6c6c !important;
    font-size: 1rem;
    /* text-decoration: underline; */
    /* margin-left: -3px; */
    padding-left: 0px;
    padding-right: 0px;
}

.poshead-1 {
    margin-left: 9px;
    margin-right: -9px;
}

.poshead-2 {
    padding-left: 0px;
    padding-right: 0px;
}

.poshead-3 {
    margin-left: -5px;
    /* margin-right: 0px; */
}

.poshead-4 {
    margin-right: -6px;
    /* margin-right: 0px; */
}

.wtchead-1 {
    margin-left: 9px;
    margin-right: -9px;
}

.wtchead-2 {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.wtchead-3 {
    margin-left: -5px;
    /* margin-right: 0px; */
}

.wtchead-4 {
    margin-right: -6px;
}

.cell-tick {
    text-align: center;
    color: #dbd7d4 !important;
    text-decoration: underline;
    font-size: 1rem;
    margin-left: -3px;
    /* position: relative; */
    /* left: 4px !important; */
    /* margin-right: 10px; */
    /* font-weight: bold; */
}

/* .cell-tick {
    text-align: center !important;
    position: relative;
    color: #dbd7d4 !important;
    text-decoration: underline;
    font-size: 1rem;
    text-align: start;
    left: 4px !important;

} */

/* .cell-targ {
    color: #dbd7d4 !important;
} */
.cell-pri {
    /* right text*/
    /* color: #babab9 !important; */
    color: #dbd7d4 !important;
}

.cell-neg {
    /* color: #b33430; */
    /* color: #ff8a65; */
    /* color: #fc7f59; */
    color: #e25651;
}

/* .cell-neg2 {
    color: #8f0f0a;
    color: #e25651;
} */

.cell-neg2 {
    /* color: #b33430; */
    /* color: #8f0f0a; */
    /* color: #a50c07; */
    /* color: #ffab91; */
    color: #3d0402;
    filter: brightness(1.3);
}

.cell-pos {
    /* color: #7fa650; */
    color: #64dd17;
}

.cell-qty {
    font-size: 0.9rem;
    text-align: center;
    position: relative;
    left: 8px !important;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: -1px;
    margin-right: 3px;
}

/* .cell-qty {
    font-size: 0.9rem;
    text-align: center;
    position: relative;
    left: 8px !important;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 2px;
    margin-right: -2px;
} */

.cell-curr {
    /* color: #c19556 !important; */
    color: #babab9 !important;
    margin-left: -3px;
}

.cell-left {
    padding-left: 10px;
    padding-right: 0px;
    margin-left: 2px;
    margin-right: -3px;
}

.cell-mid1 {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: -5px;
}

.cell-mid2 {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
}

.cell-mid3 {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
}

.cell-mid3-wtc {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: -3px;
    margin-right: 0px;
}

.cell-right {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: -5px;
    margin-right: 0px;
}

.entry-subcard {
    /* left-right sides of card */
    /* background-color: #7f7D7A !important; */
    background-color: #62605E !important;
    height: 140px;
    width: 100%;
    margin: 3px auto;
    padding-top: 10px;
    text-align: center;
    font-size: 0.8rem;
    color: #000 !important;
    padding-top: 5px;
    /* for the text inside the card */
    /* bborder-radius: 5px; */
    /* color: #babab9 !important; */
}

.cell-subc {
    color: #fff;
    font-size: 0.8rem;
    text-align: center;
}

.cell-subc-left {
    padding-left: 3px;
    padding-right: 0px;
    margin-left: 5px;
    margin-right: -5px;
    margin-top: -2px;
    overflow-wrap: normal;
}

.cell-subc-mid1 {
    padding-left: 0px;
    padding-right: 0px;
    /* margin-left: 3px; */
    margin-left: 0px;
    margin-right: -5px;
    z-index: 1;
}

.cell-subc-mid2 {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
}

.cell-subc-mid3 {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
    /* margin-left: -3px;
    margin-right: 3px; */
}

.cell-subc-right {
    padding-left: 0px;
    padding-right: 0px;
    /* margin-left: -10px; */
    margin-left: -5px;
    margin-right: 0px;
}

/* .cell-subc-left {
    padding-left: 3px;
    padding-right: 0px;
    margin-left: 5px;
    margin-right: -5px;
}

.cell-subc-mid1 {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 12px;
    margin-right: -10px;
}

.cell-subc-mid2 {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
} */

.mid2-wtc {
    /* **********Additional for wtc************ */
    margin-left: -6px;
    margin-right: 6px;
}

/* .cell-subc-mid3 {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: -3px;
    margin-right: 3px;
} */

.mid3-wtc {
    /* **********Additional for wtc************ */
    margin-left: -7px;
    margin-right: 7px;
}

/* .cell-subc-right {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: -10px;
    margin-right: 0px;
} */

.subc-head {
    /* text-decoration: underline; */
    text-decoration: none;
    margin-bottom: 4px;
}

.cell-titl {
    font-size: 0.9rem;
    text-align: start;
    position: relative;
    left: 8px !important;
}

.cell-subc-low1 {
    margin-top: 15px;
    margin-left: 0px;
}

.comments-cell {
    /* text-decoration: underline; */
    font-size: 1rem;
    text-align: start;
    position: relative;
    left: 4px !important;
}

.cell-neu {
    color: #dbd7d4;
}

.cell-disabled {
    padding-right: 0px;
    text-decoration: none;
    color: #b2b2b9 !important;
    overflow-wrap: normal;
}

.cell-light {
    text-decoration: underline;
    color: #e2e2e2;
}

.closepos-cell {
    font-size: 1rem;
    text-align: center;
    text-decoration: underline;
    color: #dbd7d4 !important;
    /* position: relative; */
    /* margin-right: 10px; */
}

.close-btn {
    margin-top: -10px;
    margin-left: -5px;
}

.close-butt {
    height: 25px !important;
    width: 10px !important;
    /* margin-top: 10px; */
    /* margin-left: 0px; */
    text-align: start !important;
    background-color: #b33430;
    color: white;
    margin-top: 10px !important;
    /* padding-top: -5px !important; */
}

.btn-tex {
    position: relative;
    top: -7px;
    left: -4px;
}

/* ********* COMMENTS ************* */
.comments-card {
    margin: 3px 0;
    color: #dbd7d4;
    color: #fff;
    background-color: #7C7B78;
    border: 1px;
    padding: 5px 5px;
    font-size: 0.9rem;
}

.line-break-and-tab {
    white-space: pre-wrap;
}

/* ********* END COMMENTS ********** */

.entry-subcard-wtc {
    /* **********Additional for wtc************ */
    height: 95px;
}

.last-row-subc-wtc {
    /* ************Additional for wtc*********** */
    margin-top: -4px;
}

.top-row-history-pos {
    /* ************Additional for history *********** */
    margin-top: 10px;
    margin-bottom: -10px;
}

.top-row-history-wtc {
    /* ************Additional for history *********** */
    margin-top: 50px;
    margin-bottom: -10px;
}

.card-headers-history {
    /* ************Additional for history *********** */
    margin-top: 10px;
}

.empty-card {
    background-color: #413e3c;
    height: 40px;
    margin-top: 20px;
    padding-top: 6px;
    text-align: center;
}

/* .top-card-history {
    margin-top: 20px;
    height: 40px;
} */
.mid2-wtc-num {
    /* **********Additional for wtc************ */
    margin-left: -12px;

}

/* ********* ADD COMMENTS ************* */

.comment-textarea {
    height: 150px !important;
    /* background-color: #d1d1d1; */
    background-color: #dbd7d4;
    color: #000;
    margin-top: 0px;
    margin-bottom: 5px;
    padding-top: 10px !important;
    font-size: 1rem;
}

.addcomm-btn {
    background-color: #2F5C85 !important;
    /* background-color: #6a6867 !important; */
    color: #fff !important;
    margin-bottom: 10px;
}

.entercomm-btn {
    background-color: #2F5C85 !important;
    color: #fff !important;
    margin-bottom: 10px;
    margin-right: 20px;
}

.btn-secondary-comment {
    height: 38px;
    margin-bottom: 10px;
    margin-left: 0px;
}

/********** END COMMENTS ***********/

/* ******************* BTN 3D ******************* */
.btn3d {
    border: 0;
    transition: all 40ms linear;
}

.btn3d.btn-primar {
    box-shadow: 0 0 0 1px #2f5b85a1 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

.btn3d.btn-primar:hover {
    box-shadow: 0 0 0 1px #4a95db34 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

/* ******************* END BTN 3D ******************* */
</style>
