<template>
<div class="container my-5">

    <div class="row">
        <div class="col d-flex justify-content-center">
            <div class="card mx-4 my-4 card-short">
                <div class="session-expired" v-if="this.sessionExpired">Session expired. Please login</div>
                <form>
                    <div class="row">
                        <div class="col d-flex justify-content-center">
                            <div class="forms-inputs inner-addon mt-3 mb-4 ">
                                <img class="input-icon" src="@/assets/icons/account.svg" alt="Account">
                                <!-- <input autocomplete="off" placeholder="Username" type="text" v-model="username" @focus="this.usernameError=''; this.pwdError='';" @blur="validUsername()"> -->
                                <input autocomplete="off" placeholder="Username or Email" type="text" v-model="username" @focus="usernameError=''; pwdError=''" @blur="validUsername()" @drop.prevent="false" @paste.prevent="false">
                                <div class="row">
                                    <div class="col d-flex justify-content-center">
                                        <div v-if="this.usernameError" class="warn">
                                            {{ usernameError }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col d-flex justify-content-center">
                            <div class="forms-inputs inner-addon mb-4 ">
                                <img class="input-icon" src="@/assets/icons/lock.svg" alt="Lock">
                                <!-- <input autocomplete="off" :type="showPassword ? 'text' : 'password'" placeholder="Password" v-model="password" @focus="this.pwdError=''; this.pwdError2='';" @blur="validPassword()"> -->
                                <input autocomplete="off" :type="showPassword ? 'text' : 'password'" placeholder="Password" v-model="password" @focus="this.pwdError=''; this.pwdError2='';" @blur="validPassword()" @drop.prevent="false">
                                <img v-if="showPassword" class="pwd-icon" src="@/assets/icons/eye.svg" @click="showPassword = !showPassword" alt="Lock">
                                <img v-else class="pwd-icon" src="@/assets/icons/eye-off.svg" @click="showPassword = !showPassword" alt="Lock">
                                <div class="row">
                                    <div class="col d-flex justify-content-center">

                                        <div v-if="this.pwdError && !this.incorrectAuth" class="warn">
                                            {{ this.pwdError }}
                                        </div>
                                        <div v-else-if="this.pwdError2" class="warn">
                                            {{ this.pwdError2 }}
                                            <!-- <p class="warn">sjdsdjk kdslkdls dkslkdlsk dkslklkd sklkls dklkdslk kdlskdl kdlsk dksdsdosk dklskdlk kdlskdl  dlk</p> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col d-flex justify-content-center">
                            <div class="mb-3"> <button type="submit" @click.prevent="logIn" class="btn custom-btn btn3d btn-primar">Login</button> </div>
                        </div>
                    </div>
                </form>
                <div class="row">
                    <div class="col d-flex justify-content-center">
                        <div class="login-message">
                            <span class="message-intro">Not a user yet? </span>
                            <span>
                                <router-link to="/register" class="message-link">&nbsp;<u>Register</u></router-link>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col d-flex justify-content-center">
                        <div class="reset-message">
                            <span>
                                <router-link :to="{ name: 'resetrequest'}" class="message-link"><u>Forgot</u></router-link>
                            </span>
                            <span class="message-intro">&nbsp; password or username?</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

</div>
</template>

<script>
import {
    mapActions
} from 'vuex'
import store from '@/store'
import router from '@/router'
import authCalls from '@/services/authCalls.js'
import apiCalls from '@/services/apiCalls.js'
export default {
    name: 'UserLogin',
    data() {
        return {
            username: '',
            password: '',
            showPassword: false,
            usernameError: '',
            pwdError: '',
            pwdError2: '',
            valid: false,
            // loggingIn: false,
        }
    },
    computed: {
        incorrectAuth() {
            return store.state.authentications.incorrectAuth
        },
        sessionExpired() {
            return store.state.sessionExpired
        }
    },
    methods: {
        ...mapActions('authentications', ['setLoggedIn', 'setIncorrectAuth', 'setTryingRefresh']), // removed setAdminUser
        ...mapActions(['setMyPid', 'setCad', 'setCin', 'setCid', 'setCain', 'setCaid', 'setMyIcon', 'setMyIconM', 'setMyAlias', 'setMyClub', 'setParticIcon', 'setParticAlias', 'setParticId', 'setPage', 'setApiUrl', 'setMyCur', 'setMyUid', 'setSessionExpired', 'setUnreadN', 'setUnreadM', 'setMyMem', 'setAtk', 'setRtk', 'setRfrsh']),
        async logIn() {
            if ((store.state.rfrsh == false || store.state.rfrsh != '/login') && localStorage.getItem('pid')) {
                return router.replace('/')
                // return router.push('/')
            }
            try {
                // async this.clearSession()
                localStorage.clear()
                await this.setLoggedIn(false)
                await this.setIncorrectAuth(false)
                await this.setRfrsh(false)
                await this.setAtk('')
                await this.setRtk('')
                // await this.setAdminUser(false)
                await this.setTryingRefresh(false)
                await this.setMyPid(null)
                await this.setMyUid(null) // RECENT
                await this.setCad(false)
                await this.setCin(false)
                await this.setCid(false)
                await this.setCain(false)
                await this.setCaid(false)
                await this.setMyCur(null)
                await this.setMyIcon(null)
                await this.setMyIconM(null)
                await this.setMyAlias(null)
                await this.setParticIcon(null)
                await this.setParticAlias(null)
                await this.setMyMem(null)
                await this.setMyClub(null)
                await this.setParticId(null)
                await this.setPage(null)
                await this.setUnreadN(0)
                await this.setUnreadM(0)
                await this.setApiUrl('yf') // CHANGE BACK: enable this line and disable the 3 lines below
                // localStorage.setItem('aur', 'rp')
                // localStorage.setItem('aut', `${new Date().getMonth()+1}${new Date().getDate()}`)
                // await this.setApiUrl('rp')
                // this.LoggingIn = true

                // if (this.username.indexOf('@') != -1) {
                // console.log('Trying to login with email')
                //     let emailCredentials = {
                //         username: this.username,
                //         password: this.password,
                //     }
                //     const emailRes = await authCalls.loginEmailUser(emailCredentials)
                //     if (emailRes) {
                //         console.log('emailRes: ', emailRes)
                //         return
                //     } else {
                //         console.log('Email Login aborted')
                //         return
                //     }

                // }

                this.validate()
                if (this.valid) {
                    // this.loggingIn = false // was a check to differentiate reactivity between Login and blur - e.g. not push to fill-in missimg input
                    let res = null
                    const credentials = {
                        username: this.username,
                        password: this.password,
                    }

                    if (this.username.indexOf('@') != -1) {
                        console.log('Trying to login with email')
                        res = await authCalls.loginEmailUser(credentials)
                    } else {
                        console.log('Trying to login with username')
                        res = await authCalls.loginUser(credentials) // Goes to /api/token endpoint. If the credentials provided are correct it simply returns valid access+refresh tokens
                    }
                    console.log('response to login: ', res.data)
                    localStorage.setItem('atk', res.data.access)
                    localStorage.setItem('rtk', res.data.refresh)
                    await this.setAtk(res.data.access)
                    await this.setRtk(res.data.refresh)
                    await this.setLoggedIn(true)
                    await this.setIncorrectAuth(false)
                    // if (this.username == 'admin') {
                    //     localStorage.setItem('userProfile', 'admin')
                    //     await this.setAdminUser(true)
                    //     return router.push('/admin')
                    // }
                    let endpoint = '/participant/login/' // Does the same as with Invitation/login in UserAcceptInvitation: searches Participant for the user that submits the request and, if there is, returns the Participant in the response
                    let params = {}
                    const myParticData = await apiCalls.getEntries(endpoint, params)

                    if (myParticData && myParticData.data.length > 0) { // If your user has a Participant in the db the response includes it and you get loggedin as participant
                        console.log('myParticData ', myParticData.data[0])
                        localStorage.setItem('pik', myParticData.data[0].usericon)
                        await this.setMyIcon(myParticData.data[0].usericon)
                        localStorage.setItem('pikm', myParticData.data[0].menuicon)
                        await this.setMyIconM(myParticData.data[0].menuicon)
                        localStorage.setItem('pal', myParticData.data[0].useralias)
                        await this.setMyAlias(myParticData.data[0].useralias)
                        localStorage.setItem('pid', myParticData.data[0].id)
                        // await this.setMyPid(myParticData.data[0].id.toString())  // ATT: The reason for this is that if myPid is stored as number, then it is not recognized in indexOf in navbars/getNotifications. Notice that all localStorage is strings, so after a refresh, also all store is of type string.
                        await this.setMyPid(myParticData.data[0].id)
                        localStorage.setItem('mem', myParticData.data[0].memberships)
                        await this.setMyMem(myParticData.data[0].memberships)
                        localStorage.setItem('mcl', myParticData.data[0].topclubid)
                        await this.setMyClub(myParticData.data[0].topclubid)
                        // localStorage.setItem('mcid', partResp.data[0].club)
                        localStorage.setItem('uid', myParticData.data[0].user)
                        await this.setMyUid(myParticData.data[0].user)
                        // await this.setMyClub(partResp.data[0].club)
                        // localStorage.setItem('cad', partResp.data[0].clubadmin)
                        // await this.setCad(partResp.data[0].clubadmin)
                        localStorage.setItem('cur', myParticData.data[0].basecurrency)
                        await this.setMyCur(myParticData.data[0].basecurrency)
                        await this.setSessionExpired(false)
                        let myDate = new Date()
                        // let currentCheck = `${('0' + myDate.getDate()).slice(-2)}${('0' + myDate.getHours()).slice(-2)}`
                        let currentCheck = Number(`${(myDate.getFullYear())}${('0' + (myDate.getMonth() + 1)).slice(-2)}${('0' + myDate.getDate()).slice(-2)}${('0' + myDate.getHours()).slice(-2)}`) - 710
                        localStorage.setItem('nlc', currentCheck)

                        return router.push('/')
                    } else { // There is no Participant for your user, it means that you are logging in with the credentials sent with the invitation mail. So you should now go to /accept. Your email is currently your username 
                        // const res2 = await authCalls.loginNewUser(credentials) // Goes to /api/token endpoint. If the credentials provided are correct it simply returns valid access+refresh tokens
                        // console.log('response to login2: ', res2.data)
                        // localStorage.setItem('atk', res2.data.access)
                        // localStorage.setItem('rtk', res2.data.refresh)
                        // await this.setLoggedIn(true)
                        // await this.setIncorrectAuth(false)
                        // return router.push(`/accept/?&rid=${res2.data.uid}&email=${this.username}&rkey=${this.password}`)
                        return router.push(`/accept/?email=${this.username}&rkey=${this.password}`)
                        // return router.push({
                        //     name: 'invitation',
                        //     params: {
                        //         'rmail': this.username,
                        //         'rkey': this.password,
                        //         'uid': res2.data.uid,
                        //     }
                        // })
                    }
                }
            } catch (err) {
                console.log('error status: ', err)
                await this.setIncorrectAuth(true)
                return this.showPwdError2()
            }
        },
        showPwdError2() {
            if (this.incorrectAuth) {
                if (this.username.indexOf('@') == -1) {
                    this.pwdError2 = 'Incorrect username or password. Please try again'
                } else {
                    this.pwdError2 = 'Incorrect email or password. Please try again'
                }
            }
        },
        validate() {
            if (this.validUsername() && this.validPassword()) {
                this.valid = true
            } else {
                this.valid = false
            }
        },
        validUsername() {
            if (this.username.indexOf('@') == -1) {
                console.log('Validating with username')

                // const regex = /^.{3,5}$/ // 3 to 5 characters long
                // const regex = /^[a-zA-Z]\w{2,4}]$/ // 3 to 5, starting with a letter
                const regex1 = /^[a-zA-Z0-9_-]+$/ // only letters numbers, underscore - minimum 1 character (otherwise * instead of +)
                const regex2 = /^[a-zA-Z]/ // must start with a letter
                // const regex = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/  // At least 1 lower, one upper and one number
                if (!this.username || this.username.length < 1) {
                    this.usernameError = 'Username is required.'
                    // this.usernameError = 'Invalid username'
                    return false
                    // } else if (this.username == 'admin') {
                    //     return true
                    // } else if (this.loggingIn && this.username.length < store.state.minUsername) {
                    //     this.usernameError = 'Invalid username'
                    //     return false
                } else if (this.username.length < store.state.minUsername) {
                    // this.usernameError = 'Minimum 6 characters.'
                    this.usernameError = 'Invalid username'
                    return false
                } else if (this.username.length > store.state.maxUsername) {
                    // this.usernameError = 'Maximum 12 characters.'
                    this.usernameError = 'Invalid username'
                    return false
                } else if (!regex1.test(this.username)) {
                    // this.usernameError = 'Only letters, numbers, underscore, dash.'
                    this.usernameError = 'Invalid username'
                    return false
                } else if (!regex2.test(this.username)) {
                    // this.usernameError = 'Must start with a letter.'
                    this.usernameError = 'Invalid username'
                    return false
                }
                return true
            } else if (this.username.indexOf('@') != -1) {
                console.log('Validating with email')

                // const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i   // start and end may not be necessary. A-Z0-9 may be replaced by \w. A-Z may be replaced by a-zA-Z and then non need for i at the end
                const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
                if (!this.username || this.username.length < 1) {
                    // this.emailError = 'Email is required.'
                    return false
                } else if (!regex.test(this.username)) {
                    this.usernameError = 'Invalid email.'
                    return false
                }
                // this.username = this.username.toLowerCase()
                return true
            }
        },
        validPassword() {
            const regex1 = /^[a-zA-Z0-9_]+$/ // only letters numbers, underscore - minimum 1 character (otherwise * instead of +)
            const regex2 = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/ // At least 1 lower, one upper and one number
            const regex3 = /^[a-zA-Z]/ // must start with a letter
            if (!this.password || this.password.length < 1) {
                this.pwdError = 'Password is required.'
                // this.pwdError = 'Invalid password'
                return false
                // } else if (this.username == 'admin') {
                //     return true
            } else if (this.password.length < 8) {
                // this.pwdError = 'Minimum 8 characters.'
                this.pwdError = 'Invalid password'
                return false
            } else if (this.password.length > 16) {
                // this.pwdError = 'Maximum 16 characters.'
                this.pwdError = 'Invalid password'
                return false
            } else if (!regex1.test(this.password)) {
                // this.pwdError = 'Only letters, numbers and underscore.'
                this.pwdError = 'Invalid password'
                return false
            } else if (!regex2.test(this.password)) {
                // this.pwdError = 'At least one lower case, one upper case and one number.'
                this.pwdError = 'Invalid password'
                return false
            } else if (!regex3.test(this.password)) {
                // this.pwdError = 'Must start with a letter.'
                this.pwdError = 'Invalid password'
                return false
            }
            return true
        },
        onResize() {
            window.scrollTo(0, document.body.scrollHeight)
        }
    },
    async created() {

        if ((store.state.rfrsh == false || store.state.rfrsh != '/login') && localStorage.getItem('pid')) { // New 221231
            return router.replace('/')
            // return
        }
        try {
            localStorage.clear()
            await this.setLoggedIn(false)
            await this.setIncorrectAuth(false)
            await this.setRfrsh(false)
            await this.setAtk('')
            await this.setRtk('')
            // await this.setAdminUser(false)
            await this.setTryingRefresh(false)
            // await this.setMyClub(null)
            await this.setMyPid(null)
            await this.setMyUid(null) // RECENT
            await this.setCad(false)
            await this.setCin(false)
            await this.setMyCur(null)
            await this.setMyIcon(null)
            await this.setMyIconM(null)
            await this.setMyAlias(null)
            await this.setParticIcon(null)
            await this.setParticAlias(null)
            await this.setParticId(null)
            await this.setPage(null)
            await this.setUnreadN(0)
            await this.setUnreadM(0)
            await this.setMyMem(null)
            await this.setMyClub(null)
            // await this.setSessionExpired(false)
            await this.setApiUrl('yf') // CHANGE BACK: enable this line and disable the 3 lines below
            // localStorage.setItem('aur', 'rp')
            // localStorage.setItem('aut', `${new Date().getMonth()+1}${new Date().getDate()}`)
            // await this.setApiUrl('rp')
        } catch (err) {
            console.log('error in login/created: ', err)
        }
    },
    mounted() {
        window.addEventListener('resize', this.onResize)
        // window.addEventListener('resize', () =>{
        //     window.scrollTo(0, document.body.scrollHeight)
        // })
    },
    unmounted() {
        // Unregister the event listener before destroying this Vue instance
        window.removeEventListener('resize', this.onResize)
    }
}
</script>

<style scoped>
/* .spacer-row {
    height: 10px;
} */

/* .cont-login {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
} */

/* #bg-img {
    height: 700px;
    background-image: url("@/assets/images/IC-black32.png");
    background-repeat: no-repeat;
    background-size: 530px;
    background-position-y: -10px;
    background-position-x: 52%;
} */

.card-short {
    margin-top: -5px;
    /* border-radius: 7px; */
    border: none;
    width: 335px;
    height: 300px;
    /* background-color: #272522; */
    background-color: rgba(39, 37, 34, 0);
}

.session-expired {
    text-align: center;
    margin-bottom: -7px;
}

/* .card-small {
    margin: 0 auto;
    margin-top: 0px;
    border: none;
    width: 335px;
    height: 35px;
    color: #eee;
    background-color: #272522;
    text-align: center;
} */

.forms-inputs {
    /* Moves the boxes */
    left: 0px;
}

.forms-inputs input {
    /* These are the actual input boxes */
    height: 43px;
    width: 270px;
    /* border: 2px solid #eee; */
    border: none;
    border-radius: 7px;
    padding-left: 53px;
    /* left: 20px; */
}

.forms-inputs input:focus {
    /* what changes when you start entering input in the box */
    box-shadow: none;
    outline: none;
    border: 3px solid #d9d9d9;
    padding-left: 50px;
}

.custom-btn {
    height: 41px;
    width: 270px;
    /* margin-top: 12px; */
    margin-top: 5px;
    margin-bottom: 0px;
    background-color: #2F5C85;
    color: white;
    border-radius: 7px;
}

.warn {
    /* The warning messages which appear in case of error */
    /* color: red; */
    /* color: #d2d2d9; */
    color: #faca64e5;
    font-size: 14px;
    /* margin-left: 12px; */
    padding: 0px;
    width: 270px;
    margin-bottom: -21px;
}

.inner-addon {
    /* enable absolute positioning */
    position: relative;
}

.input-icon {
    /* style icon */
    height: 43px;
    position: absolute;
    margin-top: 0px;
    padding: 10px;
    pointer-events: none;
    background-color: #d9d9d9;
    top: 0px;
    left: 0px;
    /* height: 43px !important; */
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
}

.pwd-icon {
    /* the eye icon */
    position: absolute;
    margin-top: 0px;
    padding: 10px;
    cursor: pointer;
    /* background-color: #d9d9d9; */
    top: 1px;
    left: 225px;
    /* Moves the icon in the box */
}

.login-message {
    /* padding-left: 10px; */
    margin-left: 10px;
    margin-top: 7px;
    margin-bottom: 5px;
}

.reset-message {
    margin-left: 7px;
    margin-top: 2px;
    /* padding: 0px 0px; */
}

.message-intro {
    /* color: #413e3c; */
    /* margin-right: 2px; */
    color: #d2d2d9;
    /* padding-top: 12px !important; */
}

.message-link {
    text-decoration: none !important;
    font-weight: bold;
    /* color: #709fca;  original one*/
    /* color: #3F7CB3; logo one*/
    /* color: #2F5C85; button one*/
    /* color: #3F7CB3; */
    /* color: #5087b8; */
    /* color: #547ba0; */
    /* color: #5d84a8; */
    /* color: #638cb3; */
    color: #709fca;
    /* padding-top: 10px!important; */
    /* padding: 0px 0px; */
}

/* ******************* BTN 3D ******************* */
.btn3d {
    border: 0;
    transition: all 40ms linear;
}

.btn3d.btn-primar {
    box-shadow: 0 0 0 1px #2f5b85a1 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

.btn3d.btn-primar:hover {
    box-shadow: 0 0 0 1px #4a95db34 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

/* ******************* END BTN 3D ******************* */

.main-card-login {
    background-color: #272522;
    border: none;
    padding-left: 0px;
    text-align: center;
    font-weight: bold;
    font-size: 19px;
    /* color: #e2e2e9; */
    /* color: #cbc7c4; */
    color: #cbc7c4;
    margin-top: -20px;
    margin-bottom: -10px;
    font-family: "Segoe Print";
    word-spacing: 3px;
    text-shadow: 0px 4px #000;
    z-index: 10;
}

/* #525259 */
/* #413e3c */
/* font-style: italic; */
/* letter-spacing: 1px; */

/* .exclam-mark {
    font-family: "Segoe Script";
    padding-left: 3px;
} */

/* .custom-img-login {
    max-width: 125px;
    margin-top: 20px;
    margin-bottom: 0px;
} */
.custom-img-login2 {
    display: block;
    max-width: 238px;
    width: auto;
    height: auto;
    margin-left: 1px;
    margin-top: -35px;
    /* was 5   -65  -80*/
    margin-bottom: 17px;
    /* was -3   97   65*/
    z-index: 10;
}
</style>
