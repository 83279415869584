<template>
<div class="modal fade" id="alertModal" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="alertModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content modal-area">
            <div class="modal-header">
                <!-- <h5 class="modal-title modal-title2" id="alertModalLabel">Alert</h5> -->
                <div class="row"><img id="img-modal" src="@/assets/images/Inv_Modal.png" class="custom-img-modal"></div>
                <button type="button" class="btn close-butt" data-bs-dismiss="modal" @click.prevent="hideAlertModal()" aria-label="alert">
                    <div class="btn-tex">
                        x
                    </div>
                </button>
            </div>

            <div class="modal-body modal-body2">
                <div class="row top-row-modal">
                    <div class="col line-break-and-tab" id="theMsg">
                        <p>{{ this.alertMsg }}</p>
                    </div>
                </div>
                <!-- <div class="row modal-row">
                    <div class="col">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click.prevent="hideAlertModal()">Close</button>
                    </div>
                </div> -->
            </div>

            <div class="modal-footer modal-body2">
                <div class="row modal-row">
                    <div class="col">
                        <button type="button" id="butt-dism" class="btn btn-secondary" data-bs-dismiss="modal" @click.prevent="hideAlertModal()">Close</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
</template>

<script>
import {
    Modal
} from 'bootstrap'
import {
    mapActions
} from 'vuex'
import store from '@/store'
import router from '@/router'
export default {
    name: 'BaseModalAlert',
    props: [
        'alertMsg',
    ],
    data() {
        return {

        }
    },
    computed: {

    },
    methods: {
        ...mapActions(['setAlertMsg', 'setRfrsh']),
        async hideAlertModal() {
            console.log('triggered hide modal')
            await this.setAlertMsg('')
            this.bsAlertModal.hide()
            if (store.state.rfrsh && store.state.rfrsh == '/login') {
                return router.push('/login')
            }
            if (store.state.rfrsh) {
                return router.go(0)
            }
        },
        async onTouch() {
            if (this.bsAlertModal._isShown == true) {
                console.log('triggered touch')
                await this.hideAlertModal()
            }
        },
        onPopstate() {
            if (this.bsAlertModal._isShown == true) {
                console.log('pressed back')
                document.getElementById("butt-dism").click()
            }
        }
    },
    async mounted() {
        this.myAlertModal = document.getElementById('alertModal')
        this.bsAlertModal = new Modal(this.myAlertModal)
        document.getElementById('theMsg').innerHTML = this.alertMsg
        this.bsAlertModal.show()

        document.addEventListener('touchstart', this.onTouch)

        // document.addEventListener('touchstart', async () => {
        //     if (this.bsAlertModal._isShown == true) {
        //         console.log('triggered touch')
        //         await this.hideAlertModal()
        //     }
        // })

        document.addEventListener('keydown', async event => {
            if (event.key == 'Escape') {
                console.log('pressed esc')
                await this.setAlertMsg('')
                await this.setRfrsh(false)
            }
        }, {
            once: true
        })

        window.addEventListener('popstate', this.onPopstate)
        // window.addEventListener('popstate', async event => {
        //     if (this.bsAlertModal._isShown == true) {
        //         console.log('pressed back', event)
        //         document.getElementById("butt-dism").click()
        //     }
        // })
    },
    unmounted() {
        // alert('unmounting')
        document.removeEventListener('touchstart', this.onTouch)
        window.removeEventListener('popstate', this.onPopstate)
        // window.removeEventListener('keydown', this.handler)
    }

}
</script>

<style scoped>
/* ***** MODAL ****************************************************** */

.modal.fade .modal-dialog {
    /* height: 600px !important; */
    transform: translate3d(0, 100vh, 0);
}

.modal.show .modal-dialog {
    transform: translate3d(0, 0, 0);
    transition: transform 0.3s ease-out;
}

.modal-area {
    /* background-color: #dbd7d4; */
    background-color: #272522;
    border: solid;
    border-color: #d4d4d9;
    border-width: 1px;
    border-radius: 15px;
    /* background-color: #413e3c; */
}

.modal-header {
    border: none;
    padding: 0 0;
    margin-bottom: 0px;
}

/* .modal-title2 {
    color: #4d4d4d;
} */

.modal-body2 {
    /* color: #4d4d4d; */
    color: #e2e2e2;
    border: none;
}

.top-row-modal {
    margin-top: -10px;
}

.modal-row {
    text-align: center;
}

.btn-secondary {
    margin-left: 20px !important;
}

.custom-img-modal {
    max-width: 100px;
    margin-top: 5px;
    margin-bottom: 10px;
    margin-left: 5px;
}

.close-butt {
    height: 25px !important;
    width: 10px !important;
    text-align: start !important;
    background-color: #b33430;
    color: white;
    margin-top: -25px !important;
    margin-right: 20px !important;
    margin-left: 0px !important;

}

.btn-tex {
    position: relative;
    top: -8px;
    left: -4px;
}

.line-break-and-tab {
    white-space: pre-wrap;
}
</style>
