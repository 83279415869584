<template>
<div>
    <BaseModalPicture v-if="picUrl" :picUrl="picUrl" :topPos="topPos" :leftPos="leftPos" element='investor' @clicked="onModalClosed" />

    <div class="modal fade" id="inviteModal" tabindex="-1" aria-labelledby="inviteModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content modal-area">
                <div class="modal-header">
                    <h5 v-if="this.action == 'invite'" class="modal-title modal-title2" id="inviteModalLabel">Invite <b>{{ modalInviteEntry.useralias }}</b> to join a Club</h5>
                    <h5 v-else-if="this.action == 'message'" class="modal-title modal-title2" id="inviteModalLabel">Send message to <b>{{ modalInviteEntry.useralias }}</b></h5>
                    <button type="button" class="btn close-butt" data-bs-dismiss="modal" aria-label="Invite">
                        <div class="btn-tex">
                            x
                        </div>
                    </button>
                </div>

                <div class="modal-body modal-body2">
                    <div v-if="this.action == 'invite'" class="row top-row-modal">
                        <div class="col">
                            <p>Please select to which club you would like to invite <b>{{ modalInviteEntry.useralias }}</b> and send the invitation.</p>
                            <div class="club-wrapper">
                                <div class="row justify-content-start gx-2 gy-0 mt-1">
                                    <div class="col-xs-10 col-sm-10">
                                        <!-- <BaseInputSelect label="" v-model="this.clubIndex" @change="this.clubname = $event.target.selectedIndex" :options="this.clubnames" /> -->
                                        <BaseInputSelect label="" @change="this.clubIndex=$event.target.selectedIndex" :options="this.clubnames" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row top-row-modal">
                        <div class="col">
                            <p class="mt-4 mb-1" v-if="this.action == 'invite'">If you want, please add a message to <b>{{ modalInviteEntry.useralias }}</b> (max 250 characters):</p>
                            <p class=" mb-2" v-else-if="this.action == 'message'">Please write your message to <b>{{ modalInviteEntry.useralias }}</b> (max 250 characters):</p>
                            <div class="row">
                                <div class="col px-1">
                                    <div class="form-floating">
                                        <textarea class="form-control comment-textarea" maxlength="250" id="floatingTextarea" v-model="this.newmessage" @drop.prevent="false" @paste.prevent="false"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer modal-body2 px-0">
                    <div v-if="this.action == 'invite'" class="row modal-row">
                        <div class="col">
                            <button type="button" @click.prevent="inviteInvestor(this.clubIndex, this.newmessage)" class="btn btn-primary modal-btn">Send invitation</button>
                            <button type="button" class="btn btn-secondary btn-secondary-modal" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                    <div v-else-if="this.action == 'message'" class="row modal-row">
                        <div class="col">
                            <button type="button" @click.prevent="sendMessage(this.newmessage)" class="btn btn-primary modal-btn">Send message</button>
                            <button type="button" class="btn btn-secondary btn-secondary-modal" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div>
        <div v-if="this.category == 'searchinvestors'">
            <div class="row mt-4 pt-3">
                <div class="col-4 ps-3 active-ranking">
                    Investors
                </div>
                <div class="col-4 ps-3 inactive-ranking">
                    <router-link :to="{ name: 'itemdata', params: { category: 'searchclubs' }}" class="links">
                        Clubs
                    </router-link>
                </div>
                <div class="col-4 pe-2 inactive-ranking">
                    <router-link :to="{ name: 'itemdata', params: { category: 'searchinvestments' }}" class="links">
                        Investments
                    </router-link>
                </div>
            </div>

            <div class="wrapper">
                <form>
                    <div class="row justify-content-start gx-3 gy-0 pt-3 mb-0">
                        <div class="col col-sm-10">
                            <BaseInputEnter label="Search Username" v-model="this.investorSearch" id="input-src" />
                        </div>
                        <div class="col-3 col-sm-2">
                            <button type="submit" @click.prevent="this.getInvestors()" class="btn src-btn btn3d btn-primar">Search</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div v-else-if="this.category == 'topinvestors'">

            <div class="row mt-4 pt-3">
                <div class="col-3 ps-2 pe-0 active-ranking">
                    Investors
                </div>
                <div class="col-3 col-clubs ps-1 pe-0 inactive-ranking">
                    <router-link :to="{ name: 'itemdata', params: { category: 'topclubs' }}" class="links">
                        Clubs
                    </router-link>
                </div>
                <div class="col-3 col-inv ps-0 pe-0 inactive-ranking">
                    <router-link :to="{ name: 'itemdata', params: { category: 'topinvestments' }}" class="links">
                        Investments
                    </router-link>
                </div>
                <div class="col-3 ps-0 pe-0 inactive-ranking">
                    <router-link :to="{ name: 'itemdata', params: { category: 'topfollowed' }}" class="links">
                        Followed
                    </router-link>
                </div>
            </div>

        </div>

        <div v-else-if="this.category == 'topfollowed'">
            <div class="row mt-4 pt-3">
                <div class="col-3 ps-2 pe-0 active-ranking">
                    <router-link :to="{ name: 'itemdata', params: { category: 'topinvestors' }}" class="links">
                        Investors
                    </router-link>
                </div>
                <div class="col-3 col-clubs ps-1 pe-0 inactive-ranking">
                    <router-link :to="{ name: 'itemdata', params: { category: 'topclubs' }}" class="links">
                        Clubs
                    </router-link>
                </div>
                <div class="col-3 col-inv ps-0 pe-0 inactive-ranking">
                    <router-link :to="{ name: 'itemdata', params: { category: 'topinvestments' }}" class="links">
                        Investments
                    </router-link>
                </div>
                <div class="col-3 ps-0 pe-0 inactive-ranking">
                    Followed
                </div>
            </div>
        </div>

        <!-- <div v-if="this.showEmpty" :class="this.category=='searchinvestors' ? 'card entry-card empty-card-search' : 'card entry-card empty-card-followed'"> -->
        <div v-if="this.showEmpty" :class="this.category=='searchinvestors' ? 'card empty-card' : 'card empty-card empty-card-followed'">
            <div class="row">
                <div class="col-12">
                    {{ this.emptyMsg }}
                </div>
            </div>
        </div>

        <div v-if="this.entries.length > 0" :class="this.category == 'followed' ? 'card head-card-followed' : 'card head-card'">
            <div class="row">
                <div class="col-3 cell-head head0">
                    <a v-if="this.category=='topinvestors' && this.criteria=='amount'" href="" class="links links-criteria" @click.prevent="this.criteria='rate'; this.changeCriteria();">
                        amount
                    </a>
                    <a v-else-if="this.category=='topinvestors' && this.criteria=='rate'" href="" class="links links-criteria" @click.prevent="this.criteria='amount'; this.changeCriteria();">
                        p/l rate
                    </a>

                </div>
                <div class="col-3 cell-head head1">
                    last
                </div>
                <div class="col-3 cell-head head2">
                    p/l
                </div>
                <div class="col-3 cell-head head3">
                    p/l
                </div>
            </div>
        </div>

        <div v-for="entry, index in entries" :key="entry.id">
            <div class="card entry-card" :id="'investorcard-'+index">
                <div class="row entry-top-row">
                    <div class="col-3 cell-tick cell-col0">
                    </div>
                    <div class="col-3 cell-col1 cell-num">
                        {{ Math.round(Number(entry.userportfolio) + Number(entry.usercash)).toLocaleString('en-US') }}
                        <!-- 10,234,567.89 -->
                    </div>
                    <div class="col-3 cell-col2 cell-num">
                        <span :class="((Number(entry.userportfolio) + Number(entry.usercash))/Number(entry.startcash)) >= 1 ? 'cell-pos' : 'cell-neg'">
                            <!-- {{ (Math.round(((Number(entry.userportfolio) + Number(entry.usercash))/Number(entry.startcash) -1) * 10000) / 100).toFixed(2)}} -->
                            {{ entry.capitalChg }}

                            <!-- 1,234.56 -->
                        </span>

                    </div>
                    <div class="col-3 cell-col3 cell-num">
                        {{ Math.round((Number(entry.userportfolio) + Number(entry.usercash)) - Number(entry.startcash)).toLocaleString('en-US') }}
                        <!-- 10,234,567.89 -->
                    </div>
                </div>
                <div class="row entry-bottom-row">
                    <div class="col-3 cell-tick cell-col0 d-flex justify-content-center">
                        <span v-if="entry.usericon.length == 4"><img class="user-sticker-round" :src="require('@/assets/images/'+ entry.usericon + '.png')" path="@/assets/images/" @click.prevent="toggleSubcard(index)" alt="user1"></span>
                        <span v-else-if="entry.usericon.length > 4 && entry.src">
                            <div class="investor-image" :style="{ 'background-image': `url(${entry.src})` }" @click.prevent="enlargePic(entry.src, index)" :id="'investorPic-' + index"></div>
                        </span>
                        <!-- <span v-else-if="entry.usericon.length > 4 && entry.src"><img class="user-sticker-round" :src="entry.src" alt="user1"></span> -->
                        <span v-else><img class="user-sticker-round" :src="require('@/assets/images/'+ 'I-11' + '.png')" path="@/assets/images/" @click.prevent="toggleSubcard(index)" alt="user1"></span>
                    </div>
                    <div class="col-3 cell-col1 cell-dim">
                        {{ entry.basecurrency.toLowerCase() }}
                    </div>
                    <div class="col-3 cell-col2 cell-dim">
                        %
                    </div>
                    <div class="col-3 cell-col3 cell-dim">
                        {{ entry.basecurrency.toLowerCase() }}
                    </div>
                </div>
                <div class="row">
                    <div v-if="this.width>=992" class="col-md-3 cell-alias-center">
                        <a class="cell-neu" href="" @click.prevent="toggleSubcard(index)">{{ entry.useralias}}</a>
                        <!-- <a class="cell-neu" href="" @click.prevent="toggleSubcard(index)">{{userAlias}}</a> -->
                    </div>
                    <div v-else-if="this.width<350" class="col-3 cell-alias-start">
                        <a class="cell-neu" href="" @click.prevent="toggleSubcard(index)">{{ entry.useralias}}</a>
                        <!-- <a class="cell-neu" href="" @click.prevent="toggleSubcard(index)">{{userAlias}}</a> -->
                    </div>
                    <div v-else-if="this.width<380 && entry.useralias.length<7" class="col-3 cell-alias-center">
                        <a class="cell-neu" href="" @click.prevent="toggleSubcard(index)">{{ entry.useralias}}</a>
                        <!-- <a class="cell-neu" href="" @click.prevent="toggleSubcard(index)">{{userAlias}}</a> -->
                    </div>
                    <div v-else-if="this.width<380 && entry.useralias.length>=7" class="col-3 cell-alias-start">
                        <a class="cell-neu" href="" @click.prevent="toggleSubcard(index)">{{ entry.useralias}}</a>
                        <!-- <a class="cell-neu" href="" @click.prevent="toggleSubcard(index)">{{userAlias}}</a> -->
                    </div>
                    <div v-else-if="this.width<460 && entry.useralias.length<9" class="col-3 cell-alias-center">
                        <a class="cell-neu" href="" @click.prevent="toggleSubcard(index)">{{ entry.useralias}}</a>
                        <!-- <a class="cell-neu" href="" @click.prevent="toggleSubcard(index)">{{userAlias}}</a> -->
                    </div>
                    <div v-else-if="this.width<460 && entry.useralias.length>=9" class="col-3 cell-alias-start">
                        <a class="cell-neu" href="" @click.prevent="toggleSubcard(index)">{{ entry.useralias}}</a>
                        <!-- <a class="cell-neu" href="" @click.prevent="toggleSubcard(index)">{{userAlias}}</a> -->
                    </div>
                    <div v-else-if="this.width<768 && entry.useralias.length<12" class="col-3 cell-alias-center">
                        <a class="cell-neu" href="" @click.prevent="toggleSubcard(index)">{{ entry.useralias}}</a>
                        <!-- <a class="cell-neu" href="" @click.prevent="toggleSubcard(index)">{{userAlias}}</a> -->
                    </div>
                    <div v-else-if="this.width<768 && entry.useralias.length>=12" class="col-3 cell-alias-start">
                        <a class="cell-neu" href="" @click.prevent="toggleSubcard(index)">{{ entry.useralias}}</a>
                        <!-- <a class="cell-neu" href="" @click.prevent="toggleSubcard(index)">{{userAlias}}</a> -->
                    </div>
                    <div v-else-if="this.width<992 && entry.useralias.length<18" class="col-3 cell-alias-center">
                        <a class="cell-neu" href="" @click.prevent="toggleSubcard(index)">{{ entry.useralias}}</a>
                        <!-- <a class="cell-neu" href="" @click.prevent="toggleSubcard(index)">{{userAlias}}</a> -->
                    </div>
                    <div v-else-if="this.width<992 && entry.useralias.length>=18" class="col-3 cell-alias-start">
                        <a class="cell-neu" href="" @click.prevent="toggleSubcard(index)">{{ entry.useralias}}</a>
                        <!-- <a class="cell-neu" href="" @click.prevent="toggleSubcard(index)">{{userAlias}}</a> -->
                    </div>

                    <!-- <div v-if="this.width<768" class="col-3"></div> -->
                    <div class="col-3 col-md-3"></div>
                    <div class="col-3 col-md-3 cell-followers">
                        <span v-if="this.category =='topfollowed'">followers:</span>
                    </div>
                    <div class="col-3 col-md-3 cell-followers2">
                        <span v-if="this.category =='topfollowed'"> {{Math.round(Number(entry.followers)).toLocaleString('en-US')}}</span>
                        <!-- <span v-if="this.category =='topfollowed'"> 1,000,000</span> -->
                    </div>
                </div>

            </div>

            <!-- <div v-show="showCard[index]" :class="this.category != 'myclubinvestors' ? 'card entry-subcard' : 'card entry-subcard my-club'"> -->
            <div v-show="showCard[index]" class="card entry-subcard">
                <div class="row">
                    <div class="col-3 subc-top1">
                        <!-- {{ entry.created.slice(0,10) }} -->
                    </div>
                    <div class="col-3 subc-top2">
                        cash
                    </div>
                    <div class="col-3 subc-top3">
                        <a href="" class="cell-neu" @click.prevent="showPortfolio(entry)">portfolio</a>
                    </div>
                    <div class="col-3 subc-top4">
                        <a href="" class="cell-neu" @click.prevent="showWatchlist(entry)">watchlist</a>
                    </div>
                </div>

                <div class="row">
                    <div class="col-3 subc-mid1">
                        {{ entry.basecurrency.toUpperCase() }}
                    </div>
                    <div class="col-3 subc-mid2">
                        {{ Math.round(Number(entry.usercash)).toLocaleString('en-US')}}
                        <!-- 100,234,567 -->
                    </div>
                    <div class="col-3 subc-mid3">
                        {{ Math.round(Number(entry.userportfolio)).toLocaleString('en-US')}}
                        <!-- 100,234,567 -->
                    </div>
                    <div class="col-3 subc-mid4">
                        <a href="" class="cell-neu" @click.prevent="showHistory(entry)">history</a>
                    </div>
                </div>

                <div class="row">
                    <div class="col-3 subc-low1">
                        <!-- <div v-if="this.currentPage != '/clubs' && this.destPage !='investorPage'"> -->
                        <a v-if="entry.id != this.myPid && !entry.followingId && this.category != 'myclubinvestors'" class="cell-neu" href="" @click.prevent="this.followInvestor(entry)">follow</a>
                        <a v-else-if="entry.id != this.myPid && entry.followingId" class="cell-neu" href="" @click.prevent="this.unfollowInvestor(entry)">unfollow</a>
                        <span v-else-if="entry.id != this.myPid && this.category == 'myclubinvestors'" class="cell-disabled">follow</span>
                        <!-- <a class="cell-neu" href="" @click.prevent="this.unfollowInvestor(entry)">unfollow</a> -->
                        <!-- </div> -->
                    </div>
                    <div class="col-3 subc-low2">

                        <!-- {{ entry.created.slice(0,10) }} -->
                        <!-- <a v-if="this.myPid != entry.id && entry.stopmessages == false && (this.blockersList.length < 1 || this.blockersList.indexOf(entry.id) == -1)" class="cell-neu" href="" @click.prevent="this.showInviteModal(entry, 'message')">message</a> -->
                        <a v-if="entry.id != null && this.myPid != entry.id && entry.stopmessages == false && (this.blockersList != null && this.blockersList.length < 1 || this.blockersList.indexOf(entry.id.toString()) == -1)" class="cell-neu" href="" @click.prevent="this.goConversation(entry)">message</a>
                        <span v-else-if="entry.id != null && this.myPid != entry.id && entry.stopmessages == true || (this.blockersList != null && this.blockersList.length > 0 && this.blockersList.indexOf(entry.id.toString()) != -1)" class="cell-disabled">message</span>
                        <!-- <span v-else class="cell-disabled">message</span> -->

                        <!-- <div v-if="this.currentPage != '/clubs' && this.destPage !='investorPage'"> -->

                        <!-- <a v-if="this.myPid != entry.id && entry.stopinvite == false && (this.blockersList.length < 1 || this.blockersList.indexOf(entry.id) == -1)" class="cell-neu" href="" @click.prevent="this.showInviteModal(entry, 'invite')">invite</a>
                    <span v-else-if="this.myPid != entry.id && entry.stopinvite == true || (this.blockersList.length > 0 && this.blockersList.indexOf(entry.id) != -1)" class="cell-disabled">invite</span> -->

                        <!-- <span v-else class="cell-disabled">invite</span> -->
                        <!-- <span  class="cell-neu">no invite</span> -->
                        <!-- </div> -->

                    </div>
                    <div class="col-3 subc-low3">

                        <!-- {{ entry.created.slice(0,10) }} -->
                        <!-- <a v-if="this.myPid != entry.id && entry.stopmessages == false && (this.blockersList.length < 1 || this.blockersList.indexOf(entry.id) == -1)" class="cell-neu" href="" @click.prevent="this.showInviteModal(entry, 'message')">message</a> -->

                        <!-- <a v-if="this.myPid != entry.id && entry.stopmessages == false && (this.blockersList.length < 1 || this.blockersList.indexOf(entry.id) == -1)" class="cell-neu" href="" @click.prevent="this.goConversation(entry)">message</a>
                    <span v-else-if="this.myPid != entry.id && entry.stopmessages == true || (this.blockersList.length > 0 && this.blockersList.indexOf(entry.id) != -1)" class="cell-disabled">message</span> -->

                        <!-- <span v-else class="cell-disabled">message</span> -->

                        <!-- <div v-if="this.currentPage != '/clubs' && this.destPage !='investorPage'"> -->
                        <a v-if="entry.id != null &&  this.myPid != entry.id && entry.stopinvite == false && (this.blockersList != null && this.blockersList.length < 1 || this.blockersList.indexOf(entry.id.toString()) == -1)" class="cell-neu" href="" @click.prevent="this.showInviteModal(entry, 'invite')">invite</a>
                        <span v-else-if="entry.id != null && this.myPid != entry.id && entry.stopinvite == true || (this.blockersList != null && this.blockersList.length > 0 && this.blockersList.indexOf(entry.id.toString()) != -1)" class="cell-disabled">invite</span>
                        <!-- <span v-else class="cell-disabled">invite</span> -->
                        <!-- <span  class="cell-neu">no invite</span> -->
                        <!-- </div> -->

                    </div>
                    <div class="col-3 subc-low4">

                        <a href="" class="cell-neu" @click.prevent="showPage(entry)">summary</a>

                        <!-- {{ entry.created.slice(0,10) }} -->
                        <!-- <a v-if="this.myPid != entry.id && entry.stopmessages == false && (this.blockersList.length < 1 || this.blockersList.indexOf(entry.id) == -1)" class="cell-neu" href="" @click.prevent="this.showInviteModal(entry, 'message')">message</a> -->

                        <!-- <a v-if="this.myPid != entry.id && entry.stopmessages == false && (this.blockersList.length < 1 || this.blockersList.indexOf(entry.id) == -1)" class="cell-neu" href="" @click.prevent="this.goConversation(entry)">message</a>
                    <span v-else-if="this.myPid != entry.id && entry.stopmessages == true || (this.blockersList.length > 0 && this.blockersList.indexOf(entry.id) != -1)" class="cell-disabled">message</span> -->

                        <!-- <span v-else class="cell-disabled">message</span> -->

                    </div>
                </div>

            </div>
        </div>

    </div>
</div>
</template>

<script>
import apiCalls from '@/services/apiCalls.js'
import store from '@/store'
import router from '@/router'
import {
    mapActions
} from 'vuex'
import {
    Modal
} from 'bootstrap'
export default {
    name: 'TableInvestors',
    data() {
        return {
            entries: [],
            showCard: [],
            modalInviteEntry: {},
            clubnames: [],
            clubIndex: null,

            myFollowingsCount: 0,
            myFollowingsList: [],
            showEmpty: false,
            emptyMsg: '',
            investorSearch: '',
            // destPage: '',
            criteria: 'amount',
            action: '',
            newmessage: '',
            width: 0,
            blockersList: [],
            picUrl: false,
            topPos: null,
            leftPos: null,
            // showModalPic: false,
            // userAlias: 'ABCDEFGHIJKLMNOPQRST',
            // userAlias: 'ABCDEF',
        }
    },
    props: [
        'category'
    ],
    computed: {
        myPid() {
            return store.state.myPid
        },
        currentPage() {
            return store.state.page
        },
        // imagePath() {
        //     return store.state.serverUrl + '/getimage/'
        // }
    },
    methods: {
        ...mapActions(['setParticId', 'setParticIcon', 'setParticAlias', 'setAlertMsg', 'setToinvPage', 'setFrominvPage']),
        enlargePic(src, index) {
            if (this.picUrl) {
                this.picUrl = false
            } else {
                let elem = document.getElementById('investorPic-' + index)
                let rect = elem.getBoundingClientRect()
                // this.topPos = document.querySelector('pic-' + index).getBoundingClientRect().top
                // this.leftPos = document.querySelector('pic-' + index).getBoundingClientRect().left
                this.topPos = rect.top - 20
                this.leftPos = rect.left
                this.picUrl = src
                // this.showModalPic = true
            }
        },
        onModalClosed(value) {
            console.log('onmodalClosed: ', value)
            // alert('onmodalClosed')
            this.picUrl = false
            // this.showModalPic = false
        },
        async searchInvestorsData() {
            try {
                const foundInvestors = await apiCalls.getEntries('/participants/', {
                    'search': this.investorSearch,
                })
                if (foundInvestors && foundInvestors.data.length > 0) {
                    console.log('foundInvestors.data: ', foundInvestors.data)
                    return this.entries = foundInvestors.data
                } else {
                    return this.emptyMsg = 'No close match found'
                }
            } catch (err) {
                console.log('error in searchInvestorsData: ', err)
            }
        },
        async topInvestorsData() {
            try {
                const topInvestors = await apiCalls.getEntries('/topinvestors/', {
                    'criteria': this.criteria,
                })
                if (topInvestors && topInvestors.data.length > 0) {
                    console.log('topInvestors.data: ', topInvestors.data)
                    return this.entries = topInvestors.data
                } else {
                    return this.emptyMsg = 'No Investor to show'
                }
            } catch (err) {
                console.log('error in topInvestorsData: ', err)
            }
        },
        async topFollowedData() {
            try {
                const topFollowed = await apiCalls.getEntries('/topfollowed/', {})
                if (topFollowed && topFollowed.data.length > 0) {
                    console.log('topFollowed.data: ', topFollowed.data)
                    return this.entries = topFollowed.data
                } else {
                    return this.emptyMsg = 'No Investor to show'
                }
            } catch (err) {
                console.log('error in topFollowedData: ', err)
            }
        },
        async followedInvestorsData() {
            try {
                const followedInvestors = await apiCalls.getEntries('/participants/', {
                    'followedlist': this.myFollowingsList.join()
                })
                if (followedInvestors && followedInvestors.data.length > 0) {
                    console.log('followedInvestors.data: ', followedInvestors.data)
                    return this.entries = followedInvestors.data
                } else {
                    return this.emptyMsg = 'Not following any investor'
                }
            } catch (err) {
                console.log('error in followedInvestorsData: ', err)
            }
        },
        async clubInvestorsData() {
            try {
                console.log('store.state.cid: ', store.state.cid)
                const clubMemberships = await apiCalls.getEntries('/memberships/', {
                    'club': store.state.cid,
                    'status': 'active',
                })
                if (clubMemberships && clubMemberships.data.length > 0) {
                    console.log('clubMemberships: ', clubMemberships.data)
                    let memberslist = clubMemberships.data.map(a => a.participant)
                    console.log('memberslist: ', memberslist)
                    console.log('list: ', memberslist.join())

                    const clubInvestors = await apiCalls.getEntries('/participants/', {
                        'memberslist': memberslist.join()
                    })
                    if (clubInvestors && clubInvestors.data.length > 0) {
                        console.log('clubInvestors.data: ', clubInvestors.data)
                        return this.entries = clubInvestors.data
                    } else {
                        return this.emptyMsg = 'No investor in this club'
                    }
                } else {
                    return this.emptyMsg = 'No investor in this club'
                }
            } catch (error) {
                return console.log('error in getMembers: ', error)
            }
        },
        async loneInvestorData() {
            try {
                const clubInvestor = await apiCalls.getEntries('/participants/' + store.state.myPid + '/', {})
                if (clubInvestor && clubInvestor.data) {
                    console.log('clubInvestor.data: ', clubInvestor.data)
                    return this.entries = [
                        clubInvestor.data,
                    ]
                } else {
                    return console.log('could not extract loneInvestor data)')
                }
            } catch (error) {
                return console.log('error in loneInvestorData: ', error)
            }
        },
        async getInvestors() {
            try {
                this.entries = []
                this.showEmpty = false
                this.myFollowingsList = []
                if (this.category == 'searchinvestors') {
                    await this.searchInvestorsData()
                    console.log('this.entries search: ', this.entries)
                    if (this.entries.length == 0) {
                        return this.showEmpty = true
                    }
                } else if (this.category == 'topinvestors') {
                    await this.topInvestorsData()
                    console.log('this.entries top: ', this.entries)
                    if (this.entries.length == 0) {
                        return this.showEmpty = true
                    }
                } else if (this.category == 'topfollowed') {
                    await this.topFollowedData()
                    console.log('this.entries topfollowed: ', this.entries)
                    if (this.entries.length == 0) {
                        return this.showEmpty = true
                    }
                    // this.entries.forEach(invstr => {
                    //     invstr.useralias = invstr.useralias + ' (' + invstr.followers + ')'
                    // })
                } else if (this.category == 'clubinvestors' || this.category == 'myclubinvestors') {
                    await this.clubInvestorsData()
                    console.log('this.entries club: ', this.entries)
                    if (this.entries.length == 0) {
                        return
                    }
                } else if (this.category == 'loneinvestor') {
                    await this.loneInvestorData()
                    console.log('BBBBBBBBBBBBBBBBBthis.imagePath: ', store.state.serverUrl + '/getimage/')
                    console.log('this.entries club: ', this.entries)
                    if (this.entries.length == 0) {
                        return
                    }
                }
                this.entries.forEach(async function (uE) {
                    uE.capitalChg = new Intl.NumberFormat("en-US", {
                        signDisplay: "exceptZero"
                    }).format(Math.round(((Number(uE.userportfolio) + Number(uE.usercash)) / Number(uE.startcash) - 1) * 10000) / 100)
                    if (uE['usericon'].length > 4) {
                        // uE.src = this.imagePath + uE.id + '/'
                        uE.src = store.state.serverUrl + '/getimage/' + uE['usericon'] + '/'
                        console.log('uE.src: ', uE.src)
                    }
                })
                // if (this.entries.length == 0) {  // Cannot place it here because if category is 'followed' this entries gets populated further down so execution would stop
                //     return this.showEmpty = true
                // }
                // let myDate = ''
                // this.entries.forEach(Investor => {
                //     myDate = Investor.created
                //     console.log('myDate: ', myDate)
                //     Investor['joinedDate'] = `${myDate.getFullYear()}-${('0'+(myDate.getMonth()+1)).slice(-2)}-${('0'+myDate.getDate()).slice(-2)}`
                // })

                // if (this.category != 'myclubinvestors') { // Below not needed for myclubinvestors, since will not show the links to follow or invite - CONDITION ELIMINATED SINCE IT DOES SHOW THEM on 230103
                const myFollowings = await apiCalls.getEntries('/followings/', {
                    'follower': store.state.myPid,
                })
                if (myFollowings && myFollowings.data.length > 0) {
                    this.myFollowingsCount = myFollowings.data.length //Not needed for Followed but not harming
                    console.log('this.myFollowingsCount: ', this.myFollowingsCount) //Not needed for Followed but but not harming
                    console.log('myFollowings: ', myFollowings.data)
                    this.myFollowingsList = myFollowings.data.map(a => a.followee)
                    console.log('myFollowingsList: ', this.myFollowingsList)

                    if (this.category == 'followed') {
                        await this.followedInvestorsData()
                        console.log('this.entries followed: ', this.entries)
                        if (this.entries.length == 0) {
                            return this.showEmpty = true
                        }
                        this.entries.forEach(uE => {
                            uE.capitalChg = new Intl.NumberFormat("en-US", {
                                signDisplay: "exceptZero"
                            }).format(Math.round(((Number(uE.userportfolio) + Number(uE.usercash)) / Number(uE.startcash) - 1) * 10000) / 100)
                            if (uE['usericon'].length > 4) {
                                // uE.src = this.imagePath + uE.id + '/'
                                uE.src = store.state.serverUrl + '/getimage/' + uE['usericon'] + '/'
                                console.log('uE.src: ', uE.src)
                            }
                        })
                    }
                    // let myDate = ''
                    this.entries.forEach(Investor => {
                        if (this.myFollowingsList.includes(Investor.id)) {
                            console.log('found: ', Investor.id)
                            let matchedFollowing = myFollowings.data.find(obj => obj.followee == Investor.id)
                            console.log('matchedFollowing: ', matchedFollowing)
                            Investor['followingId'] = matchedFollowing.id
                        } else {
                            Investor['followingId'] = ''
                        }
                        // myDate = Investor.created
                        // console.log('myDate: ', myDate)
                        // Investor['joinedDate'] = `${myDate.getFullYear()}-${('0'+(myDate.getMonth()+1)).slice(-2)}-${('0'+myDate.getDate()).slice(-2)}`
                    })
                } else {
                    if (this.category != 'followed') {
                        this.entries.forEach(Investor => {
                            Investor['followingId'] = ''
                        })
                    } else if (this.category == 'followed') {
                        this.emptyMsg = 'Not following any investor'
                        return this.showEmpty = true
                    }
                }
                // } //WAS closing the condition to skip if category is 'myclubinvestors'
            } catch (err) {
                console.log('error in getInvestor: ', err)
            }
        },
        async changeCriteria() {
            try {
                this.entries = []
                this.showEmpty = false
                this.myFollowingsList = []
                console.log('in table investors: ', this.category)
                await this.getInvestors()
            } catch (err) {
                console.log(err)
            }
        },
        toggleSubcard(index) {
            if (!this.showCard[index]) {
                this.showCard[index] = !this.showCard[index]
                setTimeout(() => { // NEW 20230308  
                    return document.getElementById('investorcard-' + index).scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    })
                }, 0)

            } else {
                return this.showCard[index] = !this.showCard[index]
            }
        },
        async showPortfolio(entry) {
            console.log('SearchUsers entry: ', entry)
            try {
                if (entry.id != store.state.myPid) {
                    await this.setParticId(entry.id)
                    await this.setParticIcon(entry.usericon)
                    await this.setParticAlias(entry.useralias)
                    localStorage.setItem('paid', entry.id)
                    localStorage.setItem('paic', entry.usericon)
                    localStorage.setItem('paal', entry.useralias)
                }
                return router.push('/portfolio')
            } catch (error) {
                return console.log('error status: ', error)
            }
        },
        async showWatchlist(entry) {
            try {
                if (entry.id != store.state.myPid) {
                    await this.setParticId(entry.id)
                    await this.setParticIcon(entry.usericon)
                    await this.setParticAlias(entry.useralias)
                    localStorage.setItem('paid', entry.id)
                    localStorage.setItem('paic', entry.usericon)
                    localStorage.setItem('paal', entry.useralias)
                }
                return router.push('/watchlist')
            } catch (error) {
                return console.log('error status: ', error)
            }
        },
        async showHistory(entry) {
            try {
                if (entry.id != store.state.myPid) {
                    await this.setParticId(entry.id)
                    await this.setParticIcon(entry.usericon)
                    await this.setParticAlias(entry.useralias)
                    localStorage.setItem('paid', entry.id)
                    localStorage.setItem('paic', entry.usericon)
                    localStorage.setItem('paal', entry.useralias)
                }
                return router.push('/history')
            } catch (error) {
                return console.log('error status: ', error)
            }
        },
        async showPage(entry) {
            console.log('entry in showPage: ', entry)

            //Below conditional statement eliminated as it seems not to be used
            // if (this.currentPage == '/clubs') { // Prevents the rendering changing before the eyes when navigating from My Clubs to Investor Page
            //     this.destPage = 'investorPage'
            // }

            if (entry.id != store.state.particId) { // if they are the same, it will stay on the same page and just scroll to the top, othewise does the following in order to prevent scrolling to the top before refreshing the page
                await this.setToinvPage(true)
                await this.setFrominvPage(true) // Used only here so that it the above does not prevent triggering refresh in routerBeforeEach, but still prevents screen from scrolling in front of user before routing, when calling Investor Page from inside Investor Page
            }
            await this.setParticId(entry.id)
            await this.setParticIcon(entry.usericon)
            await this.setParticAlias(entry.useralias)
            localStorage.setItem('paid', entry.id)
            localStorage.setItem('paic', entry.usericon)
            localStorage.setItem('paal', entry.useralias)
            // } else {
            // console.log('to my own page')
            // await store.dispatch('setParticId', null)
            // await store.dispatch('setParticIcon', null)
            // await store.dispatch('setParticAlias', null)
            // localStorage.removeItem('paid')
            // localStorage.removeItem('paic')
            // localStorage.removeItem('paal')
            // }
            console.log('ENDING setting partic in Investors/showPage - now will route to Investor page: ', store.state.particId + ' ' + store.state.particAlias + ' ' + store.state.particIcon)
            let investor = entry.id
            console.log('page: ', store.state.page)
            if (store.state.page == '/investor') {
                return router.push({
                    path: '/' + entry.id,
                }) // this will force a refresh, else the components in the investor page won't update 
            } else {
                return router.push({ // if comes from somewhere else, then it can go gently through the router
                    name: 'displayinvestor',
                    params: {
                        investor: investor
                    }
                })
            }
        },
        async followInvestor(entry) {
            try {
                if (this.myFollowingsCount > 29) { // maxFollowed
                    // this.toggleSubcard(index)
                    return await this.setAlertMsg(`Sorry, you have already reached your limit of 30 Investors followed.${'\n\n'}Please unfollow at least one Investor before following more.`)
                }
                const followed = await apiCalls.postEntry('/followings/', {
                    'follower': store.state.myPid,
                    'followee': entry.id,
                })
                if (followed) {
                    console.log('Added to following: ', followed.data)
                    this.myFollowingsCount = this.myFollowingsCount + 1
                    entry['followingId'] = followed.data.id
                    return
                    // return this.toggleSubcard(index)
                }
            } catch (err) {
                console.log(err)
            }

        },
        async unfollowInvestor(entry) {
            try {
                const unfollowed = await apiCalls.deleteEntry('/followings/' + entry.followingId + '/')
                if (unfollowed) {
                    console.log('Unfollowed investor: ', unfollowed)
                    // if (this.category == 'searchinvestors' || this.category == 'topinvestors') {
                    if (this.category != 'followed') {
                        this.myFollowingsCount = this.myFollowingsCount - 1
                        return entry['followingId'] = ''
                    } else if (this.category == 'followed') {
                        return router.go(0)
                    }
                }
            } catch (err) {
                console.log('error in Unfollow: ', err)
            }
        },
        async inviteInvestor(clubIndex, newmessage) {
            try {
                if (this.modalInviteEntry['memberships'] > (store.state.maxMemberships - 1)) { // maxMemberships
                    this.hideInviteModal()
                    return await this.setAlertMsg('Sorry, this Investor has already reached his limit of 10 active club memberships.')
                }
                let clubId = this.clubs[clubIndex].id
                const membersCount = await apiCalls.getEntries('/memberships/', {
                    'club': clubId,
                    'status': 'active'
                })
                if (membersCount && membersCount.data.length > (store.state.maxMembers - 1)) { // maxMembers
                    return await this.setAlertMsg(`Sorry, this club already has 10 members. You would have to remove one member before inviting one more to join.`)
                }
                // let clubId = this.clubs[clubIndex].id
                let clubName = this.clubs[clubIndex].clubname
                const membership = await apiCalls.getEntries('/memberships/', {
                    'participant': this.modalInviteEntry['id'],
                    'club': clubId,
                })
                let membershipId = ''
                if (membership && membership.data.length > 0) {
                    let status = membership.data[0].status

                    if (status == 'left') {
                        this.hideInviteModal()
                        return await this.setAlertMsg(`${this.modalInviteEntry['useralias']} was already in this club but left.${'\n\n'}The Investor may apply to join again, but cannot be invited.`)
                    } else if (status == 'invited') {
                        this.hideInviteModal()
                        return await this.setAlertMsg(`${this.modalInviteEntry['useralias']} has already been invited to this club.${'\n\n'}The Investor may apply to join, but cannot be invited again.`)
                    } else if (status == 'active') {
                        this.hideInviteModal()
                        // return await this.setAlertMsg(`The Investor with username ${this.modalInviteEntry['useralias']} is already a member of this club.`)
                        return await this.setAlertMsg(`${this.modalInviteEntry['useralias']} is already a member of this club.`)
                    } else if (status == 'applied') {
                        membershipId = membership.data[0].id
                        const confirmedMembership = await apiCalls.patchEntry('/memberships/' + membershipId + '/', {
                            'status': 'active',
                        })
                        if (confirmedMembership) {
                            console.log('confirmedMembership: ', confirmedMembership)
                            let newMems = Number(this.modalInviteEntry['memberships']) + 1
                            const amendedPartic = await apiCalls.patchEntry('/participants/' + this.modalInviteEntry['id'] + '/', {
                                'memberships': newMems,
                            })
                            if (amendedPartic) {
                                this.hideInviteModal()
                                return await this.setAlertMsg(`${this.modalInviteEntry['useralias']} had already requested to join this club.${'\n\n'}The investor is now a club member.`)
                            } else {
                                this.hideInviteModal()
                                return await this.setAlertMsg(`Sorry, it appears that the invitations could not be created.${'\n\n'}Please try again or inform the site administrators.`)
                            }
                        }
                    } else {
                        membershipId = membership.data[0].id
                        const patchedMembership = await apiCalls.patchEntry('/memberships/' + membershipId + '/', {
                            'status': 'invited',
                        })
                        if (patchedMembership) {
                            console.log('patchedMembership: ', patchedMembership)
                            this.hideInviteModal()
                            return await this.setAlertMsg(`Your invitation has been notified to ${this.modalInviteEntry['useralias']}.`)
                        }
                    }
                } else {
                    const newMembership = await apiCalls.postEntry('/memberships/', {
                        'participant': this.modalInviteEntry['id'],
                        'club': clubId,
                        'status': 'invited',
                    })
                    if (newMembership) {
                        console.log('newMembership: ', newMembership)
                        membershipId = newMembership.data.id
                        const notification = await apiCalls.postEntry('/notifications/', {
                            'notifier': store.state.myPid,
                            'notifieralias': store.state.myAlias,
                            'notified': this.modalInviteEntry['id'],
                            'club': clubId,
                            'clubname': clubName,
                            'topic': 'invitation',
                            'status': 'unread',
                            'membership': membershipId,
                            'invitemessage': newmessage,
                        })
                        if (notification.data) {
                            console.log('Existing participant found and Notification sent: ', notification.data)
                            this.hideInviteModal()
                            return await this.setAlertMsg(`Your invitation has been notified to ${this.modalInviteEntry['useralias']}.`)
                        } else {

                            this.hideInviteModal()
                            return await this.setAlertMsg(`Sorry, it appears that the invitations could not be created.${'\n\n'}Please try again or inform the site administrators.`)
                        }
                    } else {
                        this.hideInviteModal()
                        return await this.setAlertMsg(`Sorry, it appears that the invitations could not be created.${'\n\n'}Please try again or inform the site administrators.`)
                    }
                }

            } catch (err) {
                console.log('Error in inviteInvestor: ', err)
            }
        },
        async goConversation(entry) {
            try {
                if (entry.id != store.state.myPid) {
                    localStorage.setItem('paid', entry.id)
                    localStorage.setItem('paal', entry.useralias)
                    localStorage.removeItem('paic')
                    await this.setParticId(entry.id)
                    await this.setParticAlias(entry.useralias)
                    await this.setParticIcon(null)
                }

                return router.push({
                    name: 'itemdata',
                    params: {
                        category: 'conversation',
                    }
                })

            } catch (error) {
                return console.log('error status: ', error)
            }
            // router.push('/' + entry.notifier) // this was the only line of the function when sending to the entire investor page
        },
        async showInviteModal(investor, action) {
            this.newmessage = ''
            this.clubIndex = null
            try {
                if (action == 'invite') {
                    this.action = 'invite'
                    const clubs = await apiCalls.getEntries('/clubs/', {
                        'admin': store.state.myPid,
                    })
                    if (clubs && clubs.data.length > 0) {
                        this.clubs = clubs.data
                        this.clubnames = clubs.data.map(a => a.clubname)
                        this.modalInviteEntry = investor
                        console.log('clubs for modal: ', this.clubnames)
                        this.clubname = this.clubnames[0]
                        return this.bsInviteModal.show()
                    } else {
                        return await this.setAlertMsg(`You need to be administrator of at least one club in order to invite other investors to join.${'\n\n'}If you wish to administer a club, please create a new one in My Club Admin page.`)
                    }
                } else if (action == 'message') {
                    this.action = 'message'
                    this.newmessage = ''
                    this.modalInviteEntry = investor
                    this.bsInviteModal.show()
                    setTimeout(() => { // NEW 20230308 
                        return document.getElementById('floatingTextarea').focus()
                        // return document.getElementById('floatingTextarea').scrollIntoView({ behavior: 'smooth', block: 'center' })
                    }, 500)
                }
            } catch (err) {
                console.log(err)
            }

        },
        hideInviteModal() {
            this.bsInviteModal.hide()
        },
        async sendMessage(newmessage) {
            try {
                this.hideInviteModal()
                const notification = await apiCalls.postEntry('/notifications/', {
                    'notifier': store.state.myPid,
                    'notifieralias': store.state.myAlias,
                    'notified': this.modalInviteEntry['id'],
                    'topic': 'message',
                    'status': 'unread',
                    'invitemessage': newmessage,
                })
                if (notification.data) {
                    console.log('Existing participant found and Notification sent: ', notification.data)
                    return await this.setAlertMsg(`Your message has been sent to ${this.modalInviteEntry['useralias']}.`)
                } else {
                    return await this.setAlertMsg(`Sorry, it appears that the message could not be sent.${'\n\n'}Please try again or inform the site administrators.`)
                }
            } catch (err) {
                console.log('Error in sendMessage: ', err)
            }
        },
        onResize() {
            this.width = window.innerWidth
        },
        async checkBlocked() {
            // console.log('ZZZZZZZZZZZZZZZZZZZZZ')
            try {
                this.blockersList = []

                const blockedMe = await apiCalls.getEntries('/blocks/', {
                    'blocked': store.state.myPid,
                    // 'blocker': this.particId,
                })
                if (blockedMe && blockedMe.data.length > 0) {
                    this.blockersList = blockedMe.data.map(a => a.blocker)
                    // console.log('this.blockersList1: ', this.blockersList)
                }
                // console.log('this.blockersList2: ', this.blockersList)
                // console.log('this.entries[0]: ', this.entries[1])
                // console.log('typeof this.blockersList[0]: ', typeof this.blockersList[0])
                // console.log('typeof this.entries[0].id: ', typeof this.entries[1].id)
                // console.log('this.blockersList[0]: ', this.blockersList[0])
                // console.log('this.entries[0].id: ', this.entries[1].id)
                // console.log('this.blockersList.indexOf(this.entries[0].id): ', this.blockersList.indexOf(this.entries[1].id))
            } catch (err) {
                console.log(err)
            }

        }
    },
    async created() {
        try {
            this.entries = []
            this.showEmpty = false
            this.myFollowingsList = []
            console.log('in table investors: ', this.category)
            // if (this.category == 'followed'  || this.category == 'topinvestors' || this.category == 'clubinvestors') {
            if (this.category != 'searchinvestors') {
                // console.log('in followed or topinvestors')
                await this.getInvestors()
            }
            await this.checkBlocked()
        } catch (err) {
            console.log(err)
        }
    },
    mounted() {
        window.addEventListener('resize', this.onResize)
        this.width = window.innerWidth
        this.myInviteModal = document.getElementById('inviteModal')
        this.bsInviteModal = new Modal(this.myInviteModal)
    },
    unmounted() {
        window.removeEventListener('resize', this.onResize)
    }
}
</script>

<style scoped>
.entry-card {
    background-color: #413e3c !important;
    height: 83px;
    /* was 80 */
    width: 100%;
    margin: 3px auto;
    padding-top: 0px;
    text-align: center;
    padding: 0px 0px;
}

.empty-card {
    background-color: #413e3c;
    height: 40px;
    margin-top: 30px;
    padding-top: 6px;
    text-align: center;
}

/* ONLY FOR FOLLOWED */
.empty-card-followed {
    /* height: 40px; */
    margin-top: 90px;
    /* padding-top: 6px; */
}

/* ONLY FOR SEARCH */
/* .empty-card-search {
    height: 35px;
    margin-top: 30px;
    padding-top: 5px;
} */

.cell-head {
    color: #6c6c6c !important;
    font-size: 1rem;
}

.head-card {
    /* FOR SEARCH AND TOP */
    height: 30px;
    background-color: #272522 !important;
    margin-top: 10px;
    margin-bottom: -6px;
    text-align: center;
    border: none;
    padding-top: 0px;
    padding-right: 0px;
}

.head-card-followed {
    /* FOR FOLLOWED */
    height: 30px;
    background-color: #272522 !important;
    /* margin-top: 25px; */
    margin-bottom: 0px;
    text-align: center;
    border: none;
    padding-top: 5px;
    padding-right: 0px;
}

.cell-tick {
    position: relative;
    color: #babab9 !important;
    text-decoration-line: underline;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    left: 8px !important;
    margin-top: -10px;
    margin-left: -18px;
    margin-right: 5px;
    padding-top: 5px;
}

.cell-alias-center {
    color: #babab9 !important;
    text-decoration-line: underline;
    font-size: 0.8rem;
    font-weight: bold;
    text-align: center;
    margin-top: -5px;
    /* margin-left: 8px; */
    /* margin-right: -5px; */
    padding-top: 2px;
    padding-left: 0px;
    padding-right: 0px;
    overflow-wrap: normal;
    z-index: 1;
}

.cell-alias-start {
    color: #babab9 !important;
    text-decoration-line: underline;
    font-size: 0.8rem;
    font-weight: bold;
    text-align: start;
    margin-top: -5px;
    margin-left: 17px;
    margin-right: -17px;
    padding-top: 2px;
    padding-left: 0px;
    padding-right: 0px;
    overflow-wrap: normal;
    z-index: 1;
}

.head0 {
    margin-top: -1px;
}

.head1 {
    margin-left: -18px;
}

.head2 {
    margin-left: 0px;
}

.head3 {
    margin-left: -3px;
}

.cell-col0 {
    margin-left: -8px;
    margin-right: -4px;
    margin-top: 0px;
}

.cell-col1 {
    margin-left: -5px;
    margin-right: 10px;
}

.cell-col2 {
    margin-left: -10px;
    margin-right: -2px;
}

.cell-col3 {
    margin-left: 0px;
    margin-right: 0px;
}

.cell-num {
    font-size: 0.9rem;
    padding: 0px 0px;
    color: #cccccc;
    margin-top: 6px;
    margin-bottom: -6px;
}

.cell-dim {
    font-size: 0.9rem;
    padding: 0px 0px;
    color: #babab9;
    margin-top: 7px;
    margin-bottom: -7px;
}

.entry-top-row {
    padding-top: 5px;
    /* was 8 */
}

.entry-bottom-row {
    padding-top: 0px;
}

.close-butt {
    height: 25px !important;
    width: 10px !important;
    text-align: start !important;
    background-color: #b33430;
    color: white;
    /* margin-top: 5px !important; */
    /* padding-top: -5px !important; */
}

.btn-tex {
    position: relative;
    top: -8px;
    left: -4px;
}

/* .user-sticker {
    width: 40px;
    margin-top: -23px;
    margin-bottom: 2px;
} */
.user-sticker-round {
    width: 35px;
    margin-top: -30px;
    margin-bottom: 7px;
    border-radius: 50%;
    padding-bottom: 0px;
}

.investor-image {
    height: 35px;
    width: 35px;
    margin-top: -22px;
    margin-bottom: 12px;
    margin-left: 0px;
    margin-right: 0px;
    padding-bottom: 0px;
    object-fit: cover;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
}

.cell-neg {
    /* color: #e30813; */
    /* color: #fc7f59; */
    color: #e25651;
}

.cell-pos {
    /* color: #7fa650; */
    color: #64dd17;
}

.entry-subcard {
    background-color: #6a6867 !important;
    height: 104px;
    width: 100%;
    margin: 3px auto;
    text-align: center;
    font-size: 1rem;
    color: #e2e2e2;
    padding-top: 4px;
    padding-bottom: 0px;
}

.my-club {
    height: 80px !important;
}

.subc-top1 {
    font-size: 0.9rem;
    margin-left: 0px;
    padding-top: 4px;
    padding-left: 0px;
    padding-right: 0px;
}

.subc-top2 {
    font-size: 1rem;
    margin-left: -15px;
    /* margin-right: 2px; */
    padding-left: 0px;
    padding-right: 0px;
}

.subc-top3 {
    font-size: 1rem;
    margin-left: 0x;
    padding-left: 0px;
    padding-right: 0px;
}

.subc-top4 {
    font-size: 1rem;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.subc-mid1 {
    font-size: 0.9rem;
    font-weight: bold;
    margin-left: 0px;
    margin-right: -16px;
    padding-top: 9px;
    padding-left: 0px;
    padding-right: 0px;
    /* margin-bottom: -6px; */
    margin-top: 3px;

}

.subc-mid2 {
    font-size: 0.9rem;
    margin-left: 0px;
    padding-top: 12px;
    padding-left: 0px;
    padding-right: 0px;
}

.subc-mid3 {
    font-size: 0.9rem;
    margin-left: 0px;
    margin-right: 0px;
    padding-top: 12px;
    padding-left: 0px;
    padding-right: 0px;
}

.subc-mid4 {
    font-size: 1rem;
    margin-left: -4px;
    padding-right: 0px;
    padding-top: 9px;

}

.subc-low1 {
    font-size: 1rem;
    padding-top: 6px;
    margin-top: 2px;
    margin-left: 1px;
    margin-right: -15px;
    /*-16?*/
    padding-left: 0px;
    padding-right: 0px;
}

.subc-low2 {
    font-size: 1rem;
    margin-left: 0px;
    padding-top: 6px;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 2px;
}

.subc-low3 {
    font-size: 1rem;
    margin-left: 0px;
    padding-top: 6px;
    padding-left: 5px;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 2px;
}

.subc-low4 {
    font-size: 1rem;
    margin-left: 0px;
    padding-top: 6px;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 2px;
}

/* .subc-low4 {
    font-size: 0.9rem;
    margin-left: 3px;
    padding-top: 8px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 1px;
} */

.cell-neu {
    color: #dddddd;
}

.cell-disabled {
    color: #b2b2b9 !important;
    margin-top: 5px;
    margin-bottom: -5px;
}

/* ***** MODAL ****************************************************** */
.modal-area {
    background-color: #dbd7d4;
    background-color: #e4e4e4;
}

.modal-title2 {
    color: #4d4d4d;
}

.modal-body2 {
    color: #4d4d4d;
}

.top-row-modal {
    margin-top: 0px;
}

.modal-row {
    text-align: center;
}

.btn-secondary {
    margin-left: 20px !important;
}

.btn-secondary-modal {
    height: 38px;
    margin-top: -3px !important;
}

.modal-footer {
    display: flex;
    justify-content: center;
}

.modal-btn {
    /* width: 180px; */
    padding-left: 20px;
    padding-right: 20px;
    height: 38px;
    margin-right: 10px !important;
    margin-left: 0px !important;
    margin-top: -3px !important;
    background-color: #2F5C85 !important;
    color: white !important;
    border: none !important;
}

.comment-textarea {
    height: 140px !important;
    /* was 190 */
    background-color: #dbd7d4;
    color: #000;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 10px !important;
    font-size: 1rem;
}

/* *****END MODAL ************************** */

/* ***** NOT FOR FOLLOWED ****************************************************** */
.active-ranking {
    color: #dddddd;
    text-decoration: none;
    text-align: center;
    padding: 0 3px;
    font-weight: bold;
}

.inactive-ranking {
    text-decoration: underline;
    text-align: center;
    padding: 0 3px;
}

.links {
    color: #777777;
}

.links-criteria {
    /* color: #a2a2a9; */
    /* color: #777777; */
    text-decoration: underline;
}

.col-clubs {
    /* ONLY FOR topinvestors */
    margin-left: -10px;
    margin-right: 0px;
}

.col-inv {
    /* ONLY FOR topinvestors */
    margin-left: 0px;
    margin-right: 5px;
}

/* *****END NOT FOR FOLLOWED ****************************************************** */

/* *********FOR SEARCH******************** */

.src-btn {
    background-color: #2F5C85 !important;
    /* margin-left: 5px; */
    /* margin-right: 5px; */
    margin-top: 23px;
    /* width: 75px; */
    width: 100%;
    height: 37px;
    font-size: 1rem;
    padding-left: 5px;
    padding-right: 4px;
}

.wrapper :deep(.form-label) {
    color: #c2c2c9 !important;
}

.wrapper :deep(#input-src) {
    background-color: #413e3c !important;
    color: #d9d9d9 !important;
    /* font-weight: bold; */
    font-size: 1rem;
}

/* ******END FOR SEARCH ********************** */
.cell-followers {
    padding: 0px 0px;
    color: #babab9;
    font-size: 0.9rem;
    /* margin-left: 5px; */
    /* margin-right: -34px; */
    padding-top: 2px;
    text-align: end;
    padding-right: 10px;
}

.cell-followers2 {
    margin-left: -19px;
    padding: 0px 0px;
    color: #babab9;
    font-size: 0.9rem;
    padding-top: 2px;
    text-align: center;
}

/* ******************* BTN 3D ******************* */
.btn3d {
    border: 0;
    transition: all 40ms linear;
}

.btn3d.btn-primar {
    box-shadow: 0 0 0 1px #2f5b85a1 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

.btn3d.btn-primar:hover {
    box-shadow: 0 0 0 1px #4a95db34 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

/* ******************* END BTN 3D ******************* */
</style>
