import axios from 'axios'
import store from '@/store'
import router from '@/router'
const yfapiClient = axios.create({
    baseURL: 'https://yfapi.net',
    timeout:5000,
    // timeout: 1  // Here in case that I want to test generating error with API
    // params: {modules: 'defaultKeyStatistics,assetProfile'},
    withCredentials: false,
    headers: {
        'x-api-key': 'N62ROumvEjl6mSoOpC9C8XErITjWU4l4V6q0W23a',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }
})


const rapidClient = axios.create({
    baseURL: 'https://yh-finance.p.rapidapi.com',
    timeout:5000,
    // timeout:1,  // Here in case that I want to test generating error with API
    // params: {modules: 'defaultKeyStatistics,assetProfile'},
    withCredentials: false,
    headers: {
        'X-RapidAPI-Host': 'yh-finance.p.rapidapi.com',
        'X-RapidAPI-Key': 'd8f0881e4emsh31377c7cf897a2dp1ea90fjsn2fe8c8b5b827',
        // 'Accept': 'application/json',
        // 'Content-Type': 'application/json',
    }
})



rapidClient.interceptors.request.use(
    async function(config) {
        // console.log('RRRRRRRRRRRRRRRRRR rapidCalls spinner start')
        await store.dispatch('setIsLoading', true)
        return config
    }
)

rapidClient.interceptors.response.use(
    async function (response) {    // Any status code that lie within the range of 2xx cause this function to trigger. Do something with response data (here just returning the response unchanged)
        // console.log('RRRRRRRRRRRRRRRRRRY rapidCallsResponse spinner stop')
        await store.dispatch('setIsLoading', false)
        return response
    },
    async function (error) {    // Any status codes that falls outside the range of 2xx cause this function to trigger    // Do something with response error
        try {
            // console.log('RRRRRRRRRRRRRRRRRRY rapidCallsError spinner stop')
            await store.dispatch('setIsLoading', false)
            console.log('errRapid1: ',error)
     
            if (store.state.page != '/valuation') {
                await store.dispatch('setAlertMsg', `Not available.${'\n\n'}Please try again.`)
            }
            return
        } catch (error) {
            if (store.state.alertMessage == 1) {
                alert('errRapid: ', error)
            } else {
                console.log('errRapid2: ',error)
            }
        }
    }
)

yfapiClient.interceptors.request.use(
    async function(config) {
        // console.log('RRRRRRRRRRRRRRRRRRYFapiCalls spinner start')
        await store.dispatch('setIsLoading', true)
        return config
    }
)

yfapiClient.interceptors.response.use(
    async function (response) {    // Any status code that lie within the range of 2xx cause this function to trigger. Do something with response data (here just returning the response unchanged)
        // console.log('RRRRRRRRRRRRRRRRRRYFapiCallsResponse spinner stop')
        await store.dispatch('setIsLoading', false)
        return response
    },
    async function (error) {    // Any status codes that falls outside the range of 2xx cause this function to trigger    // Do something with response error
        try {
            // console.log('RRRRRRRRRRRRRRRRRRYFapiCallsError spinner stop')
            await store.dispatch('setIsLoading', false)
            if (error.response && error.response.status === 429 && store.state.apiUrl == 'yf' && !store.state.authentications.tryingRefresh) {
                console.log('originalRequest 01: ', error.config)
                await store.dispatch('authentications/setTryingRefresh', true)
                console.log('tryingRefresh in yfapiCalls set to "true"')
                localStorage.setItem('aur', 'rp')
                let myDate = new Date()
                localStorage.setItem('aut', `${('0'+(myDate.getMonth()+1)).slice(-2)}${('0'+myDate.getDate()).slice(-2)}`)
                await store.dispatch('setApiUrl', 'rp')

                // console.log('error1: ', error)
                // console.log('error2: ', error.response)
                // console.log('error3: ', error.response.data)

                const originalRequest = error.config //error.config has all the elements of the original request, it basically is the original request
                console.log('originalRequest: ', originalRequest)
                // console.log('originalRequest: ', originalRequest.params)
                // console.log('originalRequest: ', originalRequest.params.query)
                
                if (originalRequest.url == '/v6/finance/autocomplete/') {
                    let myQuery = originalRequest.params.query
                    delete (originalRequest.params.query)
                    console.log('originalRequest AFTER1: ', originalRequest)
                    const sendSearchRequestToRapid = new Promise(resolve => {
                        originalRequest.baseURL = 'https://yh-finance.p.rapidapi.com'
                        originalRequest.url = '/auto-complete/'
                        originalRequest.headers['X-RapidAPI-Host'] = 'yh-finance.p.rapidapi.com'
                        originalRequest.headers['X-RapidAPI-Key'] = 'd8f0881e4emsh31377c7cf897a2dp1ea90fjsn2fe8c8b5b827'
                        originalRequest.headers['Accept'] = 'application/json'                    
                        originalRequest.headers['Content-Type'] = 'application/json'
                        originalRequest.params['q'] = myQuery
                        resolve (axios(originalRequest))
                    })
                    console.log('originalRequest AFTER2: ', originalRequest)
                    await store.dispatch('authentications/setTryingRefresh', false)    
                    console.log('tryingRefresh set to "false"in yfapiCalls . Now resending original request which gave error: ', originalRequest)                
                    return await sendSearchRequestToRapid

                } else if (originalRequest.url == '/v6/finance/quote/') {
                    console.log('originalRequest AFTER1: ', originalRequest)
                    const sendGetRequestToRapid = new Promise(resolve => {
                        originalRequest.baseURL = 'https://yh-finance.p.rapidapi.com'
                        originalRequest.url = '/market/v2/get-quotes/'
                        originalRequest.headers['X-RapidAPI-Host'] = 'yh-finance.p.rapidapi.com'
                        originalRequest.headers['X-RapidAPI-Key'] = 'd8f0881e4emsh31377c7cf897a2dp1ea90fjsn2fe8c8b5b827'
                        originalRequest.headers['Accept'] = 'application/json'                    
                        originalRequest.headers['Content-Type'] = 'application/json'
                        resolve (axios(originalRequest))
                    })
                    console.log('originalRequest AFTER2: ', originalRequest)
                    await store.dispatch('authentications/setTryingRefresh', false)    
                    console.log('tryingRefresh set to "false"in yfapiCalls . Now resending original request which gave error: ', originalRequest)                
                    return await sendGetRequestToRapid
                }
                // return router.go(0)
            } else {
                // await store.dispatch('messages/setShowGeneralMsg', 'Network error - pls try again')
                router.push('/network-error')
                if (store.state.alertMessage == 1) {
                    alert(error)
                } else {
                    console.log('errorAPy2: ', error)
                }
            }
            return
        } catch (_error) {
            if (store.state.alertMessage == 1) {
                alert('errAPy1: ', _error)
            } else {
                console.log(_error)
            }
                // return Promise.reject(_error)
        }
    }
)
export default {
    getYF(params) {
        let endpoint = '/v6/finance/quote/'
        return yfapiClient.get(endpoint, {
            params: params,
        })
    },
    getRapid(params) {
        let endpoint = '/market/v2/get-quotes/'
        return rapidClient.get(endpoint, {
            params: params,
        })
    },
    searchYF(params) {
        let endpoint = '/v6/finance/autocomplete/'
        return yfapiClient.get(endpoint, {
            params: params,
        })
    },
    searchRapid(params) {
        let endpoint = '/auto-complete/'
        return rapidClient.get(endpoint, {
            params: params,
        })
    },
    // getSummary(params) {
    //     let endpoint = '/stock/v2/get-summary/'
    //     return rapidClient.get(endpoint, {
    //         params: params,
    //     })
    // },
    getFinancials(params) {
        let endpoint = '/stock/v2/get-financials/'
        return rapidClient.get(endpoint, {
            params: params,
        })
    },
    getTimeSeries(params) {
        let endpoint = '/stock/v2/get-timeseries/'
        return rapidClient.get(endpoint, {
            params: params,
        })
    },
    // getQuotes(params) {
    //     let endpoint = '/market/v2/get-quotes'
    //     return rapidClient.get(endpoint, {
    //         params: params,
    //     })
    // },
}