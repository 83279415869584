<template>
<div class="container my-5">

    <div class="modal fade" id="removeModal" tabindex="-1" aria-labelledby="removeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content modal-area">
                <div class="modal-header">
                    <h5 class="modal-title modal-title2" id="removeModalLabel">Delete selected {{delCategory}}</h5>
                    <button type="button" class="btn close-butt" data-bs-dismiss="modal" aria-label="Remove">
                        <div class="btn-tex">
                            x
                        </div>
                    </button>
                </div>

                <div class="modal-body modal-body2">
                    <div class="row top-row-modal">
                        <div class="col">
                            <p>Sure you want to delete <b>{{ deleteString}}</b>?</p>
                        </div>
                    </div>
                </div>

                <div class="modal-footer modal-body2 px-0">
                    <div class="row modal-row">
                        <div class="col">
                            <button type="button" @click.prevent="deleteCategory(delCategory)" class="btn btn-primary modal-btn">Delete {{delCategory}}</button>
                            <button type="button" class="btn btn-secondary btn-secondary-modal" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="modal fade" id="splitModal" tabindex="-1" aria-labelledby="splitModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content modal-area">
                <div class="modal-header">
                    <h5 class="modal-title modal-title2" id="splitModalLabel">Implement split for {{modalEntry.symbol}}</h5>
                    <button type="button" class="btn close-butt" data-bs-dismiss="modal" aria-label="Remove">
                        <div class="btn-tex">
                            x
                        </div>
                    </button>
                </div>

                <div class="modal-body modal-body2">
                    <div class="row top-row-modal">
                        <div class="col">
                            <p>Sure you want to implement a <b>{{modalEntry.factor}}</b> split for <b>{{modalEntry.symbol}}</b>?</p>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-3">
                            Symbol:
                        </div>
                        <div class="col">
                            <div class="forms-inputs modal-input">
                                <input v-model="modalEntry.symbol" class="reduce-qty" type="text" @drop.prevent="false" @paste.prevent="false" @focus="this.errorMsg=''" disabled>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-3">
                            Ratio:
                        </div>
                        <div class="col">
                            <div class="forms-inputs modal-input">
                                <input v-model="modalEntry.ratio" class="reduce-qty" type="number" step=1 @drop.prevent="false" @paste.prevent="false" @focus="this.errorMsg=''" disabled>
                            </div>
                        </div>
                        <div class="col ps-0">
                            :1
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-3">
                            Factor:
                        </div>
                        <div class="col">
                            <div class="forms-inputs modal-input">
                                <input v-model="modalEntry.factor" class="reduce-qty" type="text" @drop.prevent="false" @paste.prevent="false" @focus="this.errorMsg=''" disabled>
                            </div>
                        </div>
                    </div>


                </div>

                <div class="modal-footer modal-body2 px-0">
                        <div class="row modal-row">
                            <div class="col">
                                <button type="button" @click.prevent="implementSplit(this.modalEntry.symbol, this.modalEntry.ratio, this.modalEntry.factor, this.modalEntry.id)" class="btn btn-primary modal-btn">Implement split</button>
                                <button type="button" class="btn btn-secondary btn-secondary-modal" data-bs-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="newSplitModal" tabindex="-1" aria-labelledby="newSplitModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content modal-area">
                <div class="modal-header">
                    <h5 class="modal-title modal-title2" id="newSplitModalLabel">Enter new Split</h5>
                    <button type="button" class="btn close-butt" data-bs-dismiss="modal" aria-label="Remove">
                        <div class="btn-tex">
                            x
                        </div>
                    </button>
                </div>

                <div class="modal-body modal-body2">
                    <div class="row top-row-modal">
                        <div class="col">
                            <p>Enter Factor as x:y (e.g. 10:1), Date as yyyy-mm-dd (e.g. 2024-03-14)</p>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-3">
                            Ticker:
                        </div>
                        <div class="col">
                            <div class="forms-inputs modal-input">
                                <input v-model="modalEntry.symbol" class="reduce-qty" type="text" @drop.prevent="false" @paste.prevent="false" @focus="this.errorMsg=''">
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-3">
                            Ex Date:
                        </div>
                        <div class="col">
                            <div class="forms-inputs modal-input">
                                <input v-model="modalEntry.date" class="reduce-qty" type="text" @drop.prevent="false" @paste.prevent="false" @focus="this.errorMsg=''">
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-3">
                            Factor:
                        </div>
                        <div class="col">
                            <div class="forms-inputs modal-input">
                                <input v-model="modalEntry.factor" class="reduce-qty" type="text" @drop.prevent="false" @paste.prevent="false" @focus="this.errorMsg=''">
                            </div>
                        </div>
                    </div>


                </div>

                <div class="modal-footer modal-body2 px-0">
                        <div class="row modal-row">
                            <div class="col">
                                <button type="button" @click.prevent="enterNewSplit(this.modalEntry.symbol, this.modalEntry.date, this.modalEntry.factor)" class="btn btn-primary modal-btn">Enter split</button>
                                <button type="button" class="btn btn-secondary btn-secondary-modal" data-bs-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="!allowedIn" class="row mt-5 pt-5">
        <!-- <div class="row mt-5 pt-5"> -->
        <div class="col d-flex justify-content-center">

            <BaseInputEnter label="" v-model="utilityPwd" type="password" @drop.prevent="false" @paste.prevent="false" @blur="enterPwd()" class="enter-pwd-btn" />
        </div>
    </div>

    <div v-else>

        <button role="button" @click.prevent="getDividends()" class="btn get-btn btn-primar">Get Dividends</button><button role="button" @click.prevent="dividends=[]" class="btn btn-primar cancel-btn">Cancel</button>
        <div v-if="dividends">

            <div v-if="dividends.length > 0" class="card card-select">
                <div class="row">
                    <div class="col-9 cell-headers head-1">
                    </div>
                    <div class="col-3 cell-headers head-2 delete-selected">
                        <a href="" class="links-delete" @click.prevent="delCategory='dividends'; showRemoveModal(delCategory)">
                            Delete
                        </a>
                    </div>
                </div>
            </div>



            <div v-for="dividend, index in this.dividends" :key="dividend.id" class="card entry-card">
                <div class="row">
                    <div class="col-3 cell-targ">
                        {{ dividend.exdate}}
                    </div>
                    <div class="col-3 cell-tick">
                        {{ dividend.symbol}}
                    </div>
                    <div class="col-4 cell-targ">
                        {{ dividend.status}}
                    </div>
                    <div class="col-2">
                        <div class="form-check">
                            <BaseInputCheckbox v-model="this.dividends[index]['delete']" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <button role="button" @click.prevent="getSplits()" class="btn get-btn btn-primar">Get Splits</button><button role="button" @click.prevent="splits=[]" class="btn btn-primar cancel-btn">Cancel</button><button role="button" @click.prevent="showNewSplitModal()" class="btn get-btn btn-primar ms-3">New Split</button>
        
        
        <div v-if="splits">
            <div v-if="splits.length > 0" class="card card-select">
                <div class="row">
                    <div class="col-9 cell-headers head-1">
                    </div>
                    <div class="col-3 cell-headers head-2 delete-selected">
                        <a href="" class="links-delete" @click.prevent="delCategory='splits'; showRemoveModal(delCategory)">
                            Delete
                        </a>
                    </div>
                </div>
            </div>
            
            <div v-for="split, index in this.splits" :key="split.id" class="card entry-card">
                <div class="row">
                    <div class="col-4 cell-targ">
                        {{ split.exdate}}
                    </div>
                    <div class="col-3 cell-tick">
                        <!-- {{ split.symbol}} -->
                        <a href="" class="cell-tick" @click.prevent="showSplitModal(split.symbol, split.factor, split.id)">
                            {{ split.symbol}}
                        </a>
                    </div>
                    <div class="col-3 cell-targ">
                        {{ split.factor}}
                    </div>
                    <div class="col-2">
                        <div class="form-check">
                            <BaseInputCheckbox v-model="this.splits[index]['delete']" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    <!-- <div v-for="entry, index in this.notifications" :key="entry.id">
        <div class="card card-notification" :id="'notificationcard-'+index">
            <div class="row">
                <div class="col-6 cell-member">
                    <a :class="(topicBold[index]==true) ? 'cell-faded cell-bold' : 'cell-faded'" href="" @click.prevent="toggleNotification(entry, index)">{{ entry.notifieralias}}</a>
                </div>
                <div class="col-4 subject-col cell-faded">
                    {{ entry.title }}
                </div>
                <div class="col-2 d-flex justify-content-center checkbox-col">
                    <div class="form-check">
                        <BaseInputCheckbox v-model="this.notifications[index]['delete']" />
                    </div>
                </div>
            </div>
        </div> -->







        <button role="button" @click.prevent="getParticipants()" class="btn get-btn btn-primar">Get Participants</button><button role="button" @click.prevent="participants=[]" class="btn btn-primar cancel-btn">Cancel</button>
        <div v-if="participants && participants!= null && participants.length >0">
            &nbsp;&nbsp;&nbsp;&nbsp; N = {{participants.length}}
            <div v-for="participant in this.participants" :key="participant.id" class="card entry-card">
                <div class="row">
                    <div class="col-4 cell-tick">
                        {{ participant.created.slice(0, 10)}}
                    </div>
                    <div class="col-4 cell-targ">
                        {{ participant.useralias}}
                    </div>
                    <div class="col-4 cell-targ">
                        {{ participant.useralias}}
                    </div>
                </div>
            </div>
        </div>

        <!-- <div>
            <button @click.prevent="editComment()" class="btn get-btn btn-primar">Edit Comment</button>
        </div> -->

        <!-- <div>
            <button @click.prevent="showSmtg()" class="btn get-btn btn-primar">Show Smtg</button>
        </div> -->

    </div>

</div>
</template>

<script>
import apiCalls from '@/services/apiCalls.js'
import {
    Modal
} from 'bootstrap'
export default {
    name: 'UtilityPage',
    data() {
        return {
            dividends: [],
            splits: [],
            participants: [],
            allowedIn: false,
            utilityPwd: '',
            deleteString: '',
            delCategory: '',
            modalEntry: {
                symbol: 'XXX',
                ratio: 1,
                factor: 'X:Y',
                date: '',
            },
        }
    },
    methods: {
        async editComment() {
            try {

                // let initialComment = `Long term play on commodity price boom, market leading position of Glencore in some key products(e.g. Zinc) and appreciation of Pound sterling vs. CHF. Sell timeframe Q4 2024`
                let initialComment = ''
                // NOTICE: comment out the patch protection in PositionDetail view (unless the position belongs to the logged-on user)

                let asset = `MSTR - NasdaqGS - Microstrategy Incorporated`
                let firstAction = `B: 100; pri 272; fx 0.8943; 2023-06-15`
                let secondAction = `S: 11; pri 1183.43; fx 0.8831; 2024-03-05; chf 80,183.74`
                let participantName = `marc01`

                let comment = `${asset}${'\n'}${firstAction}${'\n'}${secondAction}${'\n\n'}${participantName} - INITIAL COMMENT:${'\n'}${initialComment}`

                let endpoint = '/positions/'
                let id = 301

                endpoint = endpoint + id + '/'
                let params = {
                    'comment': comment,
                }
                const updatedPos = await apiCalls.patchEntry(endpoint, params)

                if (updatedPos.data['error'] && updatedPos.data['error'] == 'User not logged-in') {
                    console.log('This User is not logged-in')
                }

                if (updatedPos) {
                    console.log('Updated position: ', updatedPos)
                }
            } catch (e) {
                console.log('e: ', e)
            }
        },

        showSmtg() {

            alert(window.innerWidth)
            alert(window.innerHeight)
            //  alert(store.state.page == '/conversation') 
            //     let participantData = {}
            //     if (Object.keys(participantData).length != 0) {
            //         alert('Not empty')
            //     } else {
            //         alert('It is empty')
            //     }
            //     if (participantData) {
            //         alert('However it does exist')
            //     } else {
            //         alert('Does not exist')
            //     }
            //     console.log('store.state.investorData: ', store.state.investorData)
            //     // console.log('Smtg: ', store.state.showModal1)
            //     // alert(store.state.myPid + '    ' + store.state.particId + '   ' + store.state.particIcon)
            //     const regex2 = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

            //     // var regex2 = /^.{3,5}$/ // 3 to 5 characters long
            //     // var regex2 = /^[a-zA-Z]\w{2,4}]$/ // 3 to 5, starting with a letter
            //     // var regex2 = /^[a-zA-Z0-9_]+$/ // only letters numbers and underscore
            //     // var regex2 = /^[a-zA-Z]/ // must start with a letter
            //     // var regex3 = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/  // At least 1 lower, one upper and one number

            //     var result = regex2.test('g.dhRjhd@soUeth.sjTksj.co')
            //     alert(result)
            // alert(store.state.myIconM)
            // console.log(store.state.rfrsh)
        },

        async getDividends() {
            try {
                let endPoint = '/dividends/'
                const Entries = await apiCalls.getEntries(endPoint, {})
                this.dividends = Entries.data

                this.dividends.sort((a, b) => (a.exdate > b.exdate) ? 1 : ((b.exdate > a.exdate) ? -1 : 0))

            } catch (error) {
                return console.log('error status: ', error)
            }
        },
        async getSplits() {
            try {
                let endPoint = '/splits/'
                let params = {
                    'status': 'pending',
                }
                const Entries = await apiCalls.getEntries(endPoint, params)
                this.splits = Entries.data
                this.splits.sort((a, b) => (a.exdate > b.exdate) ? 1 : ((b.exdate > a.exdate) ? -1 : 0))
            } catch (error) {
                return console.log('error status: ', error)
            }
        },
        async getParticipants() {
            try {
                let endPoint = '/participants/'
                const Entries = await apiCalls.getEntries(endPoint, {})
                this.participants = Entries.data
                this.participants.sort((a, b) => (a.created > b.created) ? 1 : ((b.created > a.created) ? -1 : 0))
            } catch (error) {
                return console.log('error status: ', error)
            }
        },
        async enterPwd() {
            if (this.utilityPwd != '') {
                const aI = await apiCalls.getEntries('/utility/' + this.utilityPwd + '/')
                // 'utAc': this.utilityPwd
                // })
                if (aI && aI.data && aI.data.aI) {
                    console.log('Passed: ', aI)
                    this.allowedIn = true
                } else {
                    console.log('Not passed: ', aI)
                    this.allowedIn = false
                }
            }
        },
        async deleteCategory(delCategory) {
            try {
                let deleteList = []
                if (delCategory == 'splits') {
                    deleteList = this.splits.map(a => {
                        if (a.delete == true) {
                            return a.id
                        } else {
                            return null
                        }
                    }).filter(Boolean).join(',')
                
                    console.log('deleteList: ', deleteList)

                    const removed = await apiCalls.deleteEntry('/delsplits/', {
                        'deletelist': deleteList
                    })

                    if (removed) {
                        console.log('removed splits: ', removed)
                        this.hideRemoveModal()
                        await this.getSplits()
                    }
                } else if (delCategory == 'dividends') {
                    deleteList = this.dividends.map(a => {
                        if (a.delete == true) {
                            return a.id
                        } else {
                            return null
                        }
                    }).filter(Boolean).join(',')
                
                    console.log('deleteList: ', deleteList)

                    const removed = await apiCalls.deleteEntry('/deldividends/', {
                        'deletelist': deleteList
                    })

                    if (removed) {
                        console.log('removed dividends: ', removed)
                        this.hideRemoveModal()
                        await this.getDividends()
                    }
                }
                // return router.go(0)
                return
            } catch (err) {
                console.log(err)
            }
        },
        async enterNewSplit(symbol, date, factor) {
             try {
                // console.log(symbol, date, factor)                
                let params = {
                    'symbol': symbol.toUpperCase(),
                    'factor': factor,
                    'exdate': date,
                    'status': 'pending',
                }
                const splitCreated = await apiCalls.postEntry('/splits/', params)
                if (splitCreated && splitCreated.data) {
                    console.log('split created: ', splitCreated.data)
                    this.hideNewSplitModal()
                    await this.getSplits()
                } else {
                    this.hideNewSplitModal()
                }
            } catch (err) {
                console.log(err)
            }
        },
        async implementSplit(symbol, ratio, factor, id) {
             try {

                let params = {
                    'symbol': symbol.toUpperCase(),
                    'ratio': ratio,
                    'factor': factor,
                    'id': id,
                }

                const implemented = await apiCalls.patchEntry('/updatesplit/', params)

                if (implemented && implemented.data) {

                    console.log('implemented split: ', implemented)
                    this.hideSplitModal()

                    await this.getSplits()
                } else {
                    this.hideSplitModal()
                }


            } catch (err) {
                console.log(err)
            }
        },
        showRemoveModal(delCategory) {
            if (delCategory=='splits' && this.splits.length < 1) {
                return
            } else if (delCategory=='dividends' && this.dividends.length < 1) {
                return
            }
            this.deleteString = ''
            let deleteCount = 0
            if (delCategory=='splits') {
                this.splits.forEach(a => {
                    if (a.delete == true) {
                        deleteCount += 1
                    }
                })
            } else if (delCategory=='dividends') {
                this.dividends.forEach(a => {
                    if (a.delete == true) {
                        deleteCount += 1
                    }
                })
            }
            if (deleteCount < 1) {
                return
            } else if (deleteCount == 1) {
                this.deleteString = `1 ${delCategory}`
            } else {
                this.deleteString = `${deleteCount} ${delCategory}`
            }

            return this.bsRemoveModal.show()
        },
        hideRemoveModal() {
            this.bsRemoveModal.hide()
        },
        showSplitModal(symbol, factor, id) {
            if (symbol==null || factor==null || factor==0) {
                return
            }
            this.modalEntry['symbol'] = symbol
            const factorNum = Number(factor.substr(0, factor.indexOf(':')))
            const factorDenom = Number(factor.substr(factor.indexOf(':') + 1))
            let ratio = 1
            if (factorDenom != 1) {
                ratio = factorNum / factorDenom
            } else {
                ratio = factorNum
            }
            this.modalEntry['ratio'] = ratio
            this.modalEntry['factor'] = factor
            this.modalEntry['id'] = id
            return this.bsSplitModal.show()
        },
        hideSplitModal() {
            this.bsSplitModal.hide()
        },
        showNewSplitModal() {
            this.modalEntry['symbol'] = ''
            this.modalEntry['ratio'] = ''
            this.modalEntry['factor'] = ''
            return this.bsNewSplitModal.show()
        },
        hideNewSplitModal() {
            this.bsNewSplitModal.hide()
        },
    },
    mounted() {
        this.myRemoveModal = document.getElementById('removeModal')
        this.bsRemoveModal = new Modal(this.myRemoveModal)
        this.mySplitModal = document.getElementById('splitModal')
        this.bsSplitModal = new Modal(this.mySplitModal)
        this.myNewSplitModal = document.getElementById('newSplitModal')
        this.bsNewSplitModal = new Modal(this.myNewSplitModal)
    },
}
</script>

<style scoped>
.entry-card {
    background-color: #413e3c !important;
    height: 50px;
    width: 100%;
    margin: 3px auto;
    padding-top: 10px;
    text-align: center;
}

.cell-tick {
    /* left text*/
    position: relative;
    color: #fff !important;
    text-decoration-line: underline;
    font-size: 0.8rem;
    text-align: center;
    left: 8px !important;
}

.cell-targ {
    /* right text*/
    color: #babab9 !important;
    font-size: 0.8rem;
}

.get-btn {
    height: 30px;
    font-size: 1rem;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0px;
    padding-bottom: 3px;
    margin-bottom: 15px;
    background-color: #2F5C85 !important;
    color: white !important;
}

.cancel-btn {
    height: 30px;
    font-size: 1rem;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0px;
    padding-bottom: 3px;
    margin-bottom: 15px;
    margin-left: 15px;
    background-color: #525259 !important;
    color: white !important;
}
</style><style>
.enter-pwd-btn {
    width: 270px !important;
}

.card-select {
    position: sticky;
    top: 30;
    height: 30px;
    background-color: #272522 !important;
    margin-bottom: 10px;
    margin-top: -7px;
    text-align: center;
    border: none;
    font-size: 0.8rem;
}

.cell-headers {
    /* color: #6c6c6c !important; */
    color: #9c9c9c !important;
    font-size: 1rem;
    padding-left: 0px;
    padding-right: 0px;
}

.head-1 {
    text-align: start;
    position: relative;
    left: 32px !important;
    margin-right: -10px;
    font-size: 1.1rem;
}

.head-2 {
    text-align: center;
    font-size: 1.1rem;
}

.links-delete {
    /* color: #6c6c6c; */
    color: #9c9c9c !important;
    text-decoration: underline;
}

/* ***** MODAL ****************************************************** */
.modal-area {
    /* background-color: #dbd7d4; */
    background-color: #e4e4e4;
}

.modal-title2 {
    color: #4d4d4d;
}

.modal-body2 {
    color: #4d4d4d;
}

.top-row-modal {
    margin-top: 0px;
}

.modal-row {
    text-align: center;
}

.btn-secondary {
    margin-left: 20px !important;
    margin-top: -3px !important;
}

.btn-secondary-modal {
    height: 38px;
    margin-top: -3px !important;
}

.modal-footer {
    display: flex;
    justify-content: center;
}

.modal-btn {
    /* width: 180px; */
    padding-left: 20px;
    padding-right: 20px;
    height: 38px;
    margin-right: 10px !important;
    /* margin-left: -60px !important; */
    margin-top: -3px !important;
    background-color: #2F5C85 !important;
    color: white !important;
    border: none !important;
}

.modal-input {
    margin-right: 11px !important;
    margin-left: -10px !important;
    margin-top: -5px !important;
    font-weight: bold;
}

.last-row-modal1 {
    text-align: start;
    padding: 0 0;
    font-weight: bold;
    margin-top: 3px;
}

/* ********************************************* */
</style>
