<template>
<!-- <div class="container main-cont-clubsadmin"> -->
<div class="container my-5">

    <BaseModalPicture v-if="picUrl" :picUrl="picUrl" :topPos="topPos" :leftPos="leftPos" element='investor' @clicked="onPicClosed" />

    <div v-if="iconModal">
        <ClubIconModal :clubId="this.clubId" :clubImage="this.clubicon" @clicked="onModalClosed" />
        <!-- <ClubIconModal :clubId="this.clubId" @clicked="iconModal=false"/> -->
    </div>

    <div>
        <form>
            <div class="row mb-3">
                <div class="col col-sm-12">
                    <BaseInputEnter label="Create a new club. Name:" type="text" v-model="newclubentry.clubname" id="clubnameBox" />
                    <div v-if="this.clubnameError">
                        <p class="warn-clubname">{{ this.clubnameError }}</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <button type="submit" role="button" class="btn custom-btn-clubsadmin btn3d btn-primar" @click.prevent="createClub()">Create club</button>
                </div>
            </div>
        </form>
    </div>

    <div class="divider sep-line-clubsadmin"></div>

    <div class="row top-row-clubsadmin"></div>
    <div v-if="this.showEmptyClubs" class="card empty-card-clubsadmin">
        <div class="row">
            <div class="col-12">
                {{ this.emptyMsgClubs }}
            </div>
        </div>
    </div>

    <!-- <div class="row" v-if="!this.showEmptyClubs">
        <div class="col-10 pt-3 mb-0 pe-0">
            <a v-if="clubAd==myPid && this.clubicon && this.clubicon != 'I-11'" href="" class="links" @click.prevent="iconModal=true">Change club image</a>
            <a v-else-if="clubAd==myPid" href="" class="links" @click.prevent="iconModal=true">Upload club image</a>
        </div>
        </div> -->

    <!-- <div class="club-wrapper"> -->
    <!-- <div class="row justify-content-start gx-2 gy-0 mt-0"> -->
    <div class="row justify-content-start mt-4 pt-1 ps-3" v-if="!this.showEmptyClubs">
        <!-- <div class="col-sm-2 col-xs-1 px-0"> -->
        <div class="col-2 col-md-1 px-0">
            <span v-if="this.clubicon && this.clubicon != 'I-11'">
                <div class="preview-icon" :style="{ 'background-image': `url(${this.clubicon})` }" @click.prevent="enlargePic(this.clubicon)" id="clubPicture"></div>
            </span>
            <span v-else><img class="user-sticker-round" :src="require('@/assets/images/'+ 'I-11' + '.png')" path="@/assets/images/" alt="user1"></span>
            <!-- <div class="preview-icon" :style="{ 'background-image': `url(${this.mySrc})` }"></div> -->
        </div>
        <div class="col-10 col-md-11 px-0 club-wrapper">
            <BaseInputSelect label="" v-model="this.clubname" @change="toggleClub($event.target.selectedIndex)" :options="this.clubnames" />
        </div>
        <!-- <div class="col-xs-9 col-sm-9">
                <BaseInputSelect label="" v-model="this.clubname" @change="toggleClub($event.target.selectedIndex)" :options="this.clubnames" />
            </div> -->
    </div>

    <div class="row mb-4" v-if="!this.showEmptyClubs">
        <div class="col-10 pt-3 mb-0 pe-0">
            <!-- {{iconModal}} -->
            <a v-if="clubAd==myPid && this.clubicon && this.clubicon != 'I-11'" href="" class="links" @click.prevent="iconModal=true">Change club image</a>
            <a v-else-if="clubAd==myPid" href="" class="links" @click.prevent="iconModal=true">Upload club image</a>
            <!-- <router-link :to="{ name: 'clubicons', params: { clubId: this.clubId } }" class="links">
                        Club icon
                    </router-link> -->
        </div>
    </div>

    <!-- </div> -->
    <div v-for="entry, index in this.myClubsData" :key="entry.id">
        <div v-if="showClub[index]">
            <transition name="fade" mode="out-in">
                <ClubAdmin />
            </transition>

        </div>
    </div>

</div>
</template>

<script>
import apiCalls from '@/services/apiCalls.js'
import store from '@/store'
import router from '@/router'
import ClubAdmin from '@/components/ClubAdmin.vue'
import ClubIconModal from '@/components/ClubIconModal.vue'
import {
    mapActions
} from 'vuex'
export default {
    name: 'ClubsAdmin',
    components: {
        ClubAdmin,
        ClubIconModal,
    },
    data() {
        return {
            myClubsData: [],
            clubnames: [],
            showEmptyClubs: false,
            emptyMsgClubs: 'No club membership',
            clubname: null,
            clubicon: null,
            clubnameError: '',
            newclubentry: {
                clubname: null,
            },
            showClub: [],
            valid: false,
            iconModal: false,
            picUrl: false,
            topPos: null,
            leftPos: null,
        }
    },
    computed: {
        myPid() {
            return store.state.myPid
        },
        clubId() {
            return store.state.caid
        },
        clubAd() {
            return store.state.cad
        },
        // isLoading() {
        //     return store.state.isLoading
        // },
    },
    methods: {
        ...mapActions(['setCad', 'setCain', 'setCaid', 'setMyMem', 'setAlertMsg', 'setRfrsh']),
        onModalClosed(value) {
            console.log(value)
            this.iconModal = false
        },
        enlargePic(src) {
            if (this.picUrl) {
                this.picUrl = false
            } else {
                let elem = document.getElementById('clubPicture')
                let rect = elem.getBoundingClientRect()
                // this.topPos = document.querySelector('pic-' + index).getBoundingClientRect().top
                // this.leftPos = document.querySelector('pic-' + index).getBoundingClientRect().left
                this.topPos = rect.top - 20
                this.leftPos = rect.left
                this.picUrl = src
                // this.showModalPic = true
            }
        },
        onPicClosed(value) {
            console.log('onPicClosed: ', value)
            this.picUrl = false
            // this.showModalPic = false
        },
        async getMyClubsData() {
            try {
                let params1 = {
                    'participant': store.state.myPid,
                    'status': 'active'
                }
                const participantMemberships = await apiCalls.getEntries('/memberships/', params1)
                if (participantMemberships && participantMemberships.data.length > 0) {
                    console.log('participantMemberships: ', participantMemberships.data)
                    let clubslist = participantMemberships.data.map(a => a.club)
                    let params2 = {
                        'clubslist': clubslist.join() // creates comma separated list from array
                    }
                    const myClubsData = await apiCalls.getEntries('/clubs/', params2)
                    if (myClubsData && myClubsData.data.length > 0) {
                        this.myClubsData = myClubsData.data
                        this.clubnames = myClubsData.data.map(a => a.clubname)
                        // this.clubicons = myClubsData.data.map(a => a.clubicon)

                        return console.log('myClubsData: ', this.myClubsData)
                        // if (store.state.cin && store.state.cid && store.state.cin <= (this.myClubsData.length - 1)) {
                        //     console.log('store.state.cin etc present in getMyClubsData: ', store.state.cin + '-' + store.state.cid + '-' + this.myClubsData.length)
                        //     this.clubname = this.clubnames[store.state.cin]
                        // } else {
                        //     console.log('store.state.cin etc absent in getMyClubsData: ', store.state.cin + '-' + store.state.cid)
                        //     await this.setCid(this.myClubsData[0].id)
                        //     localStorage.setItem('cid', this.myClubsData[0].id)
                        //     console.log('just set club id: ', store.state.cid)
                        //     this.clubname = this.clubnames[0]
                        // }
                    }
                } else {
                    return console.log('no clubs for this investor')
                    // this.showEmptyClubs = true
                }
            } catch (error) {
                console.log('error in getMyClubsData: ', error)
            }
        },
        async createClub() { // Create new Club
            try {
                console.log('started createClub: ', this.newclubentry.clubname)
                await this.validateClubname()
                if (!this.valid) {
                    console.log('stopping execution')
                    return
                }
                console.log('execution continues')
                let myCurrentMemberships = Number(store.state.mymem)
                const participantMemberships = await apiCalls.getEntries('/memberships/', {
                    'participant': store.state.myPid,
                    'status': 'active',
                })
                if (participantMemberships && participantMemberships.data.length > 0) { // checking if current memberships are different than the ones in store (e.g. if user is connected with 2 different devices)
                    if (participantMemberships.data.length != myCurrentMemberships) {
                        await this.setMyMem(participantMemberships.data.length)
                        localStorage.setItem('mem', participantMemberships.data.length)
                        myCurrentMemberships = participantMemberships.data.length
                        console.log('Memberships in db different than store: ', myCurrentMemberships + ', now' + participantMemberships.data.length)
                    }
                    if (myCurrentMemberships >= store.state.maxMemberships) { // maxMemberships
                        return await this.setAlertMsg('Sorry, you have already reached your limit of 10 active club memberships. Please leave at least one club before creating a new one.')
                    }
                }
                // let myCurrentMemberships = Number(store.state.mymem)
                // if (myCurrentMemberships >= store.state.maxMemberships) { // maxMemberships
                //     return await this.setAlertMsg('Sorry, you have already reached your limit of 10 active club memberships. Please leave at least one club before creating a new one.')
                // }
                let params = {
                    'clubname': this.newclubentry.clubname,
                    'admin': store.state.myPid
                }
                const createdClub = await apiCalls.postEntry('/clubs/', params)
                if (createdClub) {
                    const createdMembership = await apiCalls.postEntry('/memberships/', {
                        'participant': store.state.myPid,
                        'club': createdClub.data.id,
                        'status': 'active',
                    })
                    console.log('createdMembership: ', createdMembership)
                    if (createdMembership) {

                        await apiCalls.patchEntry('/participants/' + store.state.myPid + '/', {
                            'memberships': myCurrentMemberships + 1
                        })
                        console.log('myCurrentMemberships updated: ', myCurrentMemberships + 1)
                        await this.setMyMem(myCurrentMemberships + 1)
                        localStorage.setItem('mem', myCurrentMemberships + 1)

                        if (createdClub.data.clubname == this.newclubentry.clubname) {
                            await this.setCad(store.state.myPid)
                            localStorage.setItem('cad', store.state.myPid)
                            await this.setCain(this.myClubsData.length)
                            localStorage.setItem('cain', this.myClubsData.length)
                            await this.setCaid(createdClub.data.id)
                            localStorage.setItem('caid', createdClub.data.id)

                            await this.setRfrsh(true)
                            await this.setAlertMsg(`"${this.newclubentry.clubname}" club created successfully. You are subscribed as administrator.`)
                        }
                    }
                }
            } catch (error) {
                console.log('error in creatClub: ', error.response) // was errore.response.status
            }
        },
        validClubname() {
            // console.log('here')
            // const regex = /^.{3,5}$/ // 3 to 5 characters long
            // const regex = /^[a-zA-Z]\w{2,4}]$/ // 3 to 5, starting with a letter
            const regex1 = /^[a-zA-Z0-9 _-]+$/ // only letters numbers,space, underscore, dash - minimum 1 character (otherwise * instead of +)
            const regex2 = /^[a-zA-Z0-9]/ // must start with a letter or a number
            // const regex3 = /\x20{2,}/g // finds one or more spaces at any locaiton in the string (x20 is a different regex definition of spaces)
            // const regex3 = /^\s+|\s+$/g // finds one or more spaces at the beginning or at the end of the string
            const regex3 = /^\s+/ // finds one or more spaces at the beginning of the string
            const regex4 = /\s+$/ // finds one or more spaces at the end of the string
            const regex5 = /\s{2,}/g //finds 2 or more consecutive spaces anywhere in a string

            this.clubnameError = ''
            // const regex = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/  // At least 1 lower, one upper and one number
            if (!this.newclubentry.clubname || this.newclubentry.clubname.length < 1) {
                this.clubnameError = 'Clubname is required.'
                return this.valid = false
            }

            if (regex3.test(this.newclubentry.clubname) || regex4.test(this.newclubentry.clubname) || regex5.test(this.newclubentry.clubname)) {
                if (regex3.test(this.newclubentry.clubname)) {
                    console.log('Removed spaces at the beginning')
                    this.newclubentry.clubname = this.newclubentry.clubname.replace(regex3, "")
                }
                if (regex4.test(this.newclubentry.clubname)) {
                    console.log('Removed spaces at the end')
                    this.newclubentry.clubname = this.newclubentry.clubname.replace(regex4, "")
                }
                if (regex5.test(this.newclubentry.clubname)) {
                    console.log('Removed multiple spaces')
                    this.newclubentry.clubname = this.newclubentry.clubname.replaceAll(regex5, " ")
                }
                console.log('returning false: ', this.newclubentry.clubname)
                this.clubnameError = `Removed extra spaces. Please press the button again to create this club.`
                return this.valid = false
            }

            if (this.newclubentry.clubname.length < store.state.minClubname) {
                this.clubnameError = `Minimum ${store.state.minClubname} characters.`
                // this.usernameError = 'Invalid username'
                return this.valid = false
            } else if (this.newclubentry.clubname.length > store.state.maxClubname) {
                this.clubnameError = `Maximum ${store.state.maxClubname} characters.`
                // this.usernameError = 'Invalid username'
                return this.valid = false
            } else if (!regex1.test(this.newclubentry.clubname)) {
                this.clubnameError = 'Only latin letters, numbers, underscore, dash, single spaces.'
                // this.usernameError = 'Invalid username'
                return this.valid = false
            } else if (!regex2.test(this.newclubentry.clubname)) {
                this.clubnameError = 'Club name must start with a letter or a number.'
                // this.usernameError = 'Invalid username'
                return this.valid = false
            }
            return this.valid = true
        },
        async checkClubname() {
            try {
                console.log('Checking if clubname unique')
                const clubnameExists = await apiCalls.getEntries('/clubs/', {
                    'clubname': this.newclubentry.clubname
                })
                if (clubnameExists && clubnameExists.data.length > 0) {
                    console.log('Clubname already exists: ', clubnameExists)
                    await this.setAlertMsg(`The clubname ${this.newclubentry.clubname} is already in use, please choose a different one.`)
                    return this.valid = false
                    //document.getElementById("username-box").focus() // This was getting into an infinite loop!!!
                } else {
                    console.log('Clubname does not exist: ', clubnameExists)
                    return this.valid = true
                }
            } catch (err) {
                console.log('error in checkClubname: ', err)
            }
        },
        async validateClubname() {
            try {
                this.validClubname()
                if (this.valid) {
                    await this.checkClubname()
                    return
                } else {
                    return
                }
            } catch (error) {
                console.log('error in validateClubname: ', error)
            }
        },
        async toggleClub(index) {
            try {
                console.log('selected: ', index)
                await this.setCad(this.myClubsData[index].admin)
                localStorage.setItem('cad', this.myClubsData[index].admin)
                // console.log('set club admin (from toggle) to: ', localStorage.getItem('cad'))
                await this.setCain(index)
                localStorage.setItem('cain', index)
                await this.setCaid(this.myClubsData[index].id)
                localStorage.setItem('caid', this.myClubsData[index].id)
                console.log('set cain: ', store.state.cain)
                if (this.myClubsData[index].clubicon == 'I-11') {
                    this.clubicon = this.myClubsData[store.state.cain].clubicon
                } else {
                    this.clubicon = store.state.serverUrl + '/getimage/' + this.myClubsData[index].clubicon + '/'
                }
                this.showClub = []
                return this.showClub[index] = true
            } catch (error) {
                console.log('error in toggleClub: ', error)
            }
        },
        // onResize() {
        //     console.log('document.activeElement in ClubsAdmin: ', document.activeElement.id)
        //     if (document.activeElement.id == 'clubnameBox') {
        //         // window.scrollTo(0, document.body.scrollHeight)
        //         window.scrollTo({ top: 0, behavior: 'smooth' })
        //     }
        // }
    },
    async created() {
        // if (navigator.setAppBadge) {
        //     alert("supported")
        //     navigator.setAppBadge(3)
        //     alert('done')
        // }

        try {
            console.log('creating ClubsAdmin')
            // console.log('store.state.myClub: ', store.state.myClub)  //TOPCLUB-RELATED LINES
            console.log("localStorage.getItem('mcl'): ", localStorage.getItem('mcl'))
            console.log('store.state.caid: ', store.state.caid)
            console.log("localStorage.getItem('caid'): ", localStorage.getItem('caid'))
            console.log('store.state.cain: ', store.state.cain)
            console.log("localStorage.getItem('cain'): ", localStorage.getItem('cain'))
            // this.getParticipant()
            await this.getMyClubsData()
            // return console.log('pausing here!!!!: ')
            if (this.myClubsData && this.myClubsData.length > 0) {
                if (store.state.cain && store.state.caid) { // && store.state.cain <= (this.myClubsData.length - 1)
                    console.log('store.state.cain .caid present: ', store.state.cain + '-' + store.state.caid)

                    console.log('this.myClubsData.length: ', this.myClubsData.length)
                    if (this.myClubsData[store.state.cain] && this.myClubsData[store.state.cain].id == store.state.caid) {
                        this.clubname = this.myClubsData[store.state.cain].clubname
                        if (this.myClubsData[store.state.cain].clubicon == 'I-11') {
                            this.clubicon = this.myClubsData[store.state.cain].clubicon
                        } else {
                            this.clubicon = store.state.serverUrl + '/getimage/' + this.myClubsData[store.state.cain].clubicon + '/'
                        }
                        return this.showClub[store.state.cain] = true
                    } else { // means that in the meantime have entered or left some clubs, therefore it may give error. remove from local storage and do a refresh
                        localStorage.removeItem('cain')
                        localStorage.removeItem('caid')
                        return router.go(0)
                    }
                } else {
                    console.log('store.state.cain and .caid still in ClubsAdmin created: ', store.state.cain + '-' + store.state.caid)
                    console.log('unsorted clubs: ', this.myClubsData)
                    let myIndex = 0
                    // if (store.state.myClub != null) { // means I have a top club   //TOPCLUB-RELATED LINES
                    //     // find the club object with id = store.state.myClub
                    //     // discover its index and set cid cin and cad accordingly
                    //     // at the end, show it
                    //     console.log('store.state.myClub: ', store.state.myClub)
                    //     let ind = 0
                    //     this.myClubsData.forEach(clb => {
                    //         // clb['sortIndex'] = 1
                    //         if (clb.id == store.state.myClub) {
                    //             myIndex = ind
                    //         }
                    //         ind += 1
                    //     })
                    //     // let matchedClub = this.myClubsData.find(clb => clb.id == store.state.myClub)
                    //     // console.log('matchedClub: ', matchedClub)
                    //     // matchedClub['sortIndex'] = 0
                    //     // this.myClubsData.sort(function (a, b) {
                    //     //     return a.sortIndex - b.sortIndex
                    //     // })
                    //     // console.log('sorted clubs: ', this.myClubsData)
                    // }
                    this.clubname = this.myClubsData[myIndex].clubname
                    console.log()
                    if (this.myClubsData[myIndex].clubicon == 'I-11') {
                        this.clubicon = this.myClubsData[myIndex].clubicon
                    } else {
                        this.clubicon = store.state.serverUrl + '/getimage/' + this.myClubsData[myIndex].clubicon + '/'
                    }
                    await this.setCaid(this.myClubsData[myIndex].id)
                    localStorage.setItem('caid', this.myClubsData[myIndex].id)
                    await this.setCain(myIndex)
                    localStorage.setItem('cain', myIndex)
                    await this.setCad(this.myClubsData[myIndex].admin)
                    localStorage.setItem('cad', this.myClubsData[myIndex].admin)
                    console.log('set club admin (from created) to: ', localStorage.getItem('cad'))
                    console.log('store.state.cain and .caid entered in created: ', store.state.cain + '-' + store.state.caid)
                    return this.showClub[myIndex] = true
                }
            } else {
                this.showEmptyClubs = true
            }
        } catch (error) {
            return console.log('error in ClubsAdmin created: ', error)
        }
    },
    // mounted() {
    //     window.addEventListener('resize', this.onResize)
    // },
    // unmounted() {
    //     window.removeEventListener('resize', this.onResize)
    // }

}
</script>

<style scoped>
/* .main-cont-clubsadmin {
    margin-top: 70px;
    margin-bottom: 45px;
} */

.custom-btn-clubsadmin {
    background-color: #2F5C85 !important;
    color: white !important;
    margin-top: 10px !important;
    margin-bottom: 30px !important;
}

/* ******************* BTN 3D ******************* */
.btn3d {
    border: 0;
    transition: all 40ms linear;
}

.btn3d.btn-primar {
    box-shadow: 0 0 0 1px #2f5b85a1 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

.btn3d.btn-primar:hover {
    box-shadow: 0 0 0 1px #4a95db34 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

/* ******************* END BTN 3D ******************* */

.empty-card-clubsadmin {
    background-color: #413e3c !important;
    width: 100%;
    height: 40px;
    margin: 3px auto;
    margin-top: 20px;
    padding-top: 6px;
    text-align: center;
}

.top-row-clubsadmin {
    margin-top: -10px;
}

.sep-line-clubsadmin {
    background-color: #d9d9d9 !important;
    margin-top: 0px;
    margin-bottom: 5px;
    margin-left: 0px;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 2px !important;
}

.club-wrapper :deep(.bg-medium) {
    background-color: #272522 !important;
    color: #ddd !important;
    font-weight: bold;
    font-size: 1.2rem;
    text-decoration: underline;
    margin-top: -4px;
    margin-bottom: -10px;
    margin-left: -7px;
    width: 100%;
    /* with the topClub checkbox was -20 */
    box-shadow: none !important;
}

.warn-clubname {
    /* The warning messages which appear in case of error */
    /* color: red; */
    color: #d2d2d9;
    font-size: 14px;
    margin-left: 5px;
    padding: 0px;
    width: 90%;
    margin-bottom: -21px;
}

.preview-icon {
    height: 37px;
    width: 37px;
    margin-top: -1px;
    margin-bottom: 0px;
    margin-left: 7px;
    margin-right: 0px;
    object-fit: cover;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
}

.user-sticker-round {
    width: 37px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 5px;
    margin-right: 0px;
    /* margin-top: -23px; */
    /* margin-bottom: 2px; */
    border-radius: 50%;
}

.links {
    color: #e2e2e9;
    /* margin-top: -2px; */
    font-size: 1rem;
    padding-left: 5px;
    padding-right: 0px;
}

/* ###################### TRANSITIONS ###################    */
.fade-enter-from {
    opacity: 0;
}

.fade-enter-active {
    transition: opacity 0.7s ease-out;
}

.fade-leave-to {
    opacity: 0;
}

.fade-leave-active {
    transition: opacity 0.1s ease-out;
}

/* ###################### END TRANSITIONS ###################    */
</style>
