<template>
<div>
    <BaseModalPicture v-if="showPic" :picUrl="picUrl" :topPos="topPos" :leftPos="leftPos" element='bar' @clicked="onModalClosed" />

    <nav class="navbar navbar-expand navbar-light fixed-top topbar-style" style="background-color: #272522;">
        <!-- <div class="container-fluid"> -->
        <!-- <div :class="this.width > 1007 ? 'container px-0 mb-1' : 'container px-0 mb-1'"> -->
        <!-- <div class="container px-0 mb-1" v-show="!this.toInvestorPage"> -->
        <div class="container px-0 mb-1">

            <ul v-if="this.width > 1007" class="navbar-nav me-auto nav-top-left">
                <li>
                    <!-- <button class="toggle-btn" role="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" @click="checkNotifications"> -->
                    <a href="" @click.prevent="refreshPage" role="button">
                        <img class="name-logo logo-top" src="@/assets/images/NameLogo01.png" alt="namelogo">
                    </a>
                    <!-- </button> -->
                </li>
            </ul>

            <ul :class="this.width > 1007 ? 'navbar-nav mx-auto nav-top-mid' : 'navbar-nav mx-auto'">
                <!-- mid-top icon -->
                <li class="navbar-titl">
                    <span v-if="memberPic && memberPic != null && memberPic.length == 4"><img class="user-sticker-topbar-round" :src="require('@/assets/images/'+ memberPic + '_.png')" path="@/assets/images/" alt="user-icon"></span>
                    <span v-else-if="memberPic && memberPic != null && memberPic.length > 4">
                        <div class="image-topbar" :style="{ 'background-image': `url(${memberPic})` }" @click.prevent="enlargePic(memberPic)" id="investorPic"></div>
                    </span>
                    <span v-else-if="memberPic && memberPic != null"><img class="user-sticker-topbar-round" :src="require('@/assets/images/'+ 'I-11' + '_.png')" path="@/assets/images/" alt="user-icon"></span>
                    <!-- <span v-else-if="memberPic && memberPic.length > 4"><img class="user-sticker-topbar-round" :src="memberPic" alt="user1"></span> -->
                    {{ pagetitle }}

                    <!-- {{artificialName}} - {{artificialName2}} - {{width}} -->
                    <!-- ABCDEFGHIJKLMNOP -->
                    <!-- <span v-if="!memberPic || width > 370 || pagetitle.length <= 16">{{ this.pagetitle }}</span>
                <span v-else>{{ this.pagetitle.slice(0,14) }}...</span> -->
                </li>
            </ul>

            <ul v-if="this.width > 1007" class="navbar-nav ms-auto nav-top-right">
                <!-- top-right icon (toggler) -->
                <li>
                    <!-- <img class="name-logo" src="@/assets/images/NameLogo3.png" alt="namelogo"> -->
                    <!-- <button class="togge-btn bg-dark navbar-dark" role="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas">
                    <span class="navbar-toggler-icon bg-dark"></span>
                </button> -->
                    <!-- top toggler -->
                    <button v-if="this.myIcon && this.myIcon != 'I-11'" class="toggle-btn toggle-top" role="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" @click="checkNotifications">
                        <span v-if="this.myIcon != null && this.myIcon.length == 4"><img class="sticker-top" :src="require('@/assets/images/'+ this.myIcon + '_.png')" path="@/assets/images/" alt="user1"></span>
                        <span v-else-if="this.myIcon != null && this.myIcon.length > 4">
                            <div class="image-toggler-top" :style="{ 'background-image': `url(${this.myIcon})` }"></div>
                        </span>
                        <!-- <span v-else-if="this.myIcon.length > 4"><img class="sticker-top-round" :src="this.myIcon" alt="user1"></span> -->
                        <span v-else><img class="sticker-top-round" :src="require('@/assets/images/'+ 'I-11' + '_.png')" path="@/assets/images/" alt="user1"></span>
                    </button>

                    <button v-else class="toggle-btn toggle-top" role="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" @click="checkNotifications">
                        <span class="line"></span>
                        <span class="line"></span>
                        <span class="line"></span>
                    </button>

                </li>
            </ul>
        </div>
    </nav>

    <div class="row spac-row"></div>
    <div v-if="this.width > 1007" class="row spac-row2"></div>

    <!-- <div class="offcanvas offcanvas-start w-50" tabindex="-1" id="offcanvas" data-bs-keyboard="true" data-bs-scroll="true" data-bs-backdrop="true"> -->
    <div v-if="this.width > 1684" class="offcanvas offcanvas-start w-50 show" tabindex="-1" id="offcanvas" data-bs-keyboard="false" data-bs-scroll="true" data-bs-backdrop="false">
        <div class="offcanvas-header top-offcanvas-header">
            <!-- <span class="text-title">
                <h2 class="links-title" id="offcanvasLabel">Menu</h2>
            </span> -->
        </div>

        <div class="offcanvas-body px-0 offcanvas-style pb-0">
            <ul class="list-group" id="menu">
                <li class="list-group-item">
                    <a href="" @click.prevent="displayItemData('portfolio')" class="links">Portfolio</a>
                    <!-- <router-link :to="{ name: 'itemdata', params: { category: 'portfolio' }}" class="links" @click="clearPartic">Portfolio</router-link> -->
                </li>
                <li class="list-group-item">
                    <a href="" @click.prevent="displayItemData('watchlist')" class="links">Watchlist</a>
                    <!-- <router-link :to="{ name: 'itemdata', params: { category: 'watchlist' }}" class="links" @click="clearPartic">Watchlist</router-link> -->
                </li>
                <li class="list-group-item">
                    <router-link :to="{ name: 'valuation' }" class="links" @click="clearPartic">Stock valuation
                    </router-link>
                </li>
                <li class="list-group-item">
                    <router-link :to="{ name: 'newassetdata' }" class="links" @click="clearPartic">Add assets
                    </router-link>
                </li>
                <li class="list-group-item">
                    <a href="" @click.prevent="displayItemData('clubs')" class="links">My Clubs</a>
                </li>
                <li class="list-group-item">
                    <a href="" @click.prevent="displayItemData('followed')" class="links">Following</a>
                </li>
                <li class="list-group-item">
                    <a href="" @click.prevent="displayItemData('searchinvestors')" class="links">Search</a>
                </li>
                <li class="list-group-item">
                    <a href="" @click.prevent="displayItemData('topinvestors')" class="links">Rankings</a>
                </li>
                <li class="list-group-item">
                    <a v-if="this.unreadm > 0" href="" @click.prevent="displayItemData('messages')" class="links">Messages<span class="notific-num"><sup>({{ this.unreadm }})</sup></span></a>
                    <a v-else href="" @click.prevent="displayItemData('messages')" class="links">Messages</a>
                </li>
                <li class="list-group-item">
                    <a v-if="this.unreadn > 0" href="" @click.prevent="displayItemData('notifications')" class="links">Notifications<span class="notific-num"><sup>({{ this.unreadn }})</sup></span></a>
                    <a v-else href="" @click.prevent="displayItemData('notifications')" class="links">Notifications</a>
                </li>
                <li class="list-group-item">
                    <a href="" @click.prevent="displayItemData('history')" class="links">History</a>
                    <!-- <router-link :to="{ name: 'itemdata', params: { category: 'history' }}" class="links" @click="clearPartic">History</router-link> -->
                </li>
                <!-- <li v-if="this.isClubAdmin > 0" class="list-group-item list-group-item-action menu-item" role="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas"> -->
                <li class="list-group-item">
                    <router-link :to="{ name: 'clubsadmin' }" class="links" @click="clearPartic">Clubs admin
                    </router-link>
                </li>
                <li class="list-group-item">
                    <router-link :to="{ name: 'profile' }" class="links" @click="clearPartic">Profile
                    </router-link>
                </li>
                <li class="list-group-item">
                    <a href="" @click.prevent="goHelp" class="links">Help</a>
                </li>
            </ul>
        </div>

        <div class="row bottom-row">
            <div class="col-2 col-help-bottom align-self-end pe-0">
                <a href="" @click.prevent="goHelp">
                    <img src="@/assets/icons/help-circle-outline.svg" class="bottom-img-help">
                </a>
            </div>

            <div class="col-8">
                <a href="" @click.prevent="goHelp">
                    <img src="@/assets/images/Inv_6.png" class="bottom-img">
                </a>
            </div>
        </div>

        <div class="offcanvas-header">
            <span class="text-title">
                <h2 class="logout-bottom" id="offcanvasLabel" @click="this.logOut">Logout</h2>
            </span>
        </div>
    </div>

    <div v-else :class="currentCheck - lastCheck > 200 ? 'offcanvas offcanvas-start w-50 show' : 'offcanvas offcanvas-start w-50'" tabindex="-1" id="offcanvas" data-bs-keyboard="true" data-bs-scroll="true" data-bs-backdrop="true">
    <!-- <div v-else class="offcanvas offcanvas-start w-50 show" tabindex="-1" id="offcanvas" data-bs-keyboard="true" data-bs-scroll="true" data-bs-backdrop="true"> -->

        <div class="row spacer-row-navbar">

            <div class="col-2 col-help align-self-end pe-0">
                <a href="" @click.prevent="goHelp" role="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas">
                    <img src="@/assets/icons/help-circle-outline.svg" class="custom-img-help">
                </a>
            </div>

            <div class="col-8">
                <a href="" @click.prevent="goHelp" role="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas">
                    <img src="@/assets/images/Inv_6.png" class="custom-img">
                </a>
            </div>
        </div>

        <div class="divider sep-line"></div>

        <div class="offcanvas-body px-0 offcanvas-style pb-0">
            <ul class="list-group" id="menu">
                <li class="list-group-item" role="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas">
                    <a href="" @click.prevent="displayItemData('portfolio')" class="links">Portfolio</a>
                    <!-- <router-link :to="{ name: 'itemdata', params: { category: 'portfolio' }}" class="links" @click="clearPartic">Portfolio</router-link> -->
                </li>
                <li class="list-group-item" role="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas">
                    <a href="" @click.prevent="displayItemData('watchlist')" class="links">Watchlist</a>
                    <!-- <router-link :to="{ name: 'itemdata', params: { category: 'watchlist' }}" class="links" @click="clearPartic">Watchlist</router-link> -->
                </li>
                <li class="list-group-item" role="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas">
                    <router-link :to="{ name: 'valuation' }" class="links" @click="clearPartic">Stock valuation
                    </router-link>
                </li>
                <li class="list-group-item" role="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas">
                    <router-link :to="{ name: 'newassetdata' }" class="links" @click="clearPartic">Add assets
                    </router-link>
                </li>
                <li class="list-group-item" role="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas">
                    <a href="" @click.prevent="displayItemData('clubs')" class="links">My Clubs</a>
                </li>
                <li class="list-group-item" role="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas">
                    <a href="" @click.prevent="displayItemData('followed')" class="links">Following</a>
                </li>
                <li class="list-group-item" role="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas">
                    <a href="" @click.prevent="displayItemData('searchinvestors')" class="links">Search</a>
                </li>
                <li class="list-group-item" role="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas">
                    <a href="" @click.prevent="displayItemData('topinvestors')" class="links">Rankings</a>
                </li>
                <li class="list-group-item" role="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas">
                    <a v-if="this.unreadm > 0" href="" @click.prevent="displayItemData('messages')" class="links">Messages<span class="notific-num"><sup>({{ this.unreadm }})</sup></span></a>
                    <a v-else href="" @click.prevent="displayItemData('messages')" class="links">Messages</a>
                </li>
                <li class="list-group-item" role="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas">
                    <a v-if="this.unreadn > 0" href="" @click.prevent="displayItemData('notifications')" class="links">Notifications<span class="notific-num"><sup>({{ this.unreadn }})</sup></span></a>
                    <a v-else href="" @click.prevent="displayItemData('notifications')" class="links">Notifications</a>
                </li>
                <li class="list-group-item" role="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas">
                    <a href="" @click.prevent="displayItemData('history')" class="links">History</a>
                    <!-- <router-link :to="{ name: 'itemdata', params: { category: 'history' }}" class="links" @click="clearPartic">History</router-link> -->
                </li>
                <!-- <li v-if="this.isClubAdmin > 0" class="list-group-item list-group-item-action menu-item" role="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas"> -->
                <li class="list-group-item" role="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas">
                    <router-link :to="{ name: 'clubsadmin' }" class="links" @click="clearPartic">Clubs admin
                    </router-link>
                </li>
                <li class="list-group-item" role="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas">
                    <router-link :to="{ name: 'profile' }" class="links" @click="clearPartic">Profile
                    </router-link>
                </li>
                <li class="list-group-item" role="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas">
                    <a href="" @click.prevent="goHelp" class="links">Help</a>
                </li>
            </ul>

            <!-- <div class="divider sep-line2"></div> -->
        </div>
        <div class="divider sep-line2"></div>

        <div class="offcanvas-header">
            <span class="text-title">
                <h2 class="logout-bottom" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" id="offcanvasLabel" @click="this.logOut">Logout</h2>
            </span>
            <!-- <button type="button" class="links-title close-btn" data-bs-dismiss="offcanvas" aria-label="Close"></button> -->
        </div>
    </div>

    <!-- <nav :class="this.width > 2000 ? 'navbar navbar-expand navbar-light fixed-top' : 'navbar navbar-expand navbar-light fixed-bottom'" style="background-color: #272522;"> -->
    <nav class="navbar navbar-expand navbar-light fixed-bottom nav-styl">
        <div class="container px-0">
            <!-- <div class="container-fluid pe-0"> -->
            <ul v-if="this.width <= 1007" class="navbar-nav me-auto">
                <li>
                    <!-- <button role="button" class="logo-btn" @click.prevent="refreshPage"> -->
                    <a href="" @click.prevent="refreshPage" role="button">
                        <img class="name-logo" src="@/assets/images/NameLogo01.png" alt="namelogo">
                    </a>
                    <!-- </button> -->
                </li>
            </ul>
            <ul v-if="this.width <= 1007" class="navbar-nav ms-auto">
                <!-- bottom-right icon (toggler) -->
                <li>
                    <!-- <img class="name-logo" src="@/assets/images/NameLogo3.png" alt="namelogo"> -->
                    <!-- <button class="togge-btn bg-dark navbar-dark" role="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas">
                        <span class="navbar-toggler-icon bg-dark"></span>
                        </button> -->
                    <button v-if="this.myIcon && this.myIcon != 'I-11'" id="myOffcanvasButton" class="toggle-btn" role="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" @click="checkNotifications">
                        <span v-if="this.myIcon != null && this.myIcon.length == 4"><img class="user-sticker-round" :src="require('@/assets/images/'+ this.myIcon + '_.png')" path="@/assets/images/" alt="user1"></span>
                        <span v-else-if="this.myIcon != null && this.myIcon.length > 4">
                            <div class="image-toggler-bottom" :style="{ 'background-image': `url(${this.myIcon})` }"></div>
                        </span>
                        <!-- <span v-else-if="this.myIcon.length > 4"><img class="user-sticker-round" :src="this.myIcon" alt="user1"></span> -->
                        <span v-else><img class="user-sticker-round" :src="require('@/assets/images/'+ 'I-11' + '_.png')" path="@/assets/images/" alt="user1"></span>
                    </button>

                    <button v-else id="myOffcanvasButton" class="toggle-btn" role="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" @click="checkNotifications">
                        <!-- <button class="toggle-btn " role="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" @click="checkNotifications"> -->
                        <span class="line"></span>
                        <span class="line"></span>
                        <span class="line"></span>
                    </button>

                </li>
            </ul>
            <!-- <ul v-else class="navbar-nav ms-auto">
                    <li>
                        <button v-if="this.myIcon && this.myIcon != 'I-11'" id="myOffcanvasButton" class="toggle-btn" role="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" @click="checkNotifications">
                            <span v-if="this.myIcon != null && this.myIcon.length == 4"><img class="user-sticker-round" :src="require('@/assets/images/'+ this.myIcon + '_.png')" path="@/assets/images/" alt="user1"></span>
                            <span v-else-if="this.myIcon != null && this.myIcon.length > 4">
                                <div class="image-toggler-bottom" :style="{ 'background-image': `url(${this.myIcon})` }"></div>
                            </span>
                            <span v-else><img class="user-sticker-round" :src="require('@/assets/images/'+ 'I-11' + '_.png')" path="@/assets/images/" alt="user1"></span>
                        </button>
                        <button v-else id="myOffcanvasButton" class="toggle-btn" role="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" @click="checkNotifications">
                            <span class="line"></span>
                            <span class="line"></span>
                            <span class="line"></span>
                        </button>
                    </li>
                </ul> -->
        </div>
    </nav>
</div>
</template>

<script>
import apiCalls from '@/services/apiCalls.js'
import store from '@/store'
import {
    mapActions
} from 'vuex'
import router from '@/router'
import {
    Offcanvas
} from 'bootstrap'
export default {
    name: 'NavBar',
    data() {
        return {
            width: 0,
            height: 0,
            touchstartX: null,
            touchstartY: null,
            touchendX: null,
            touchendY: null,
            // newNotifications: 0,
            pagename: {
                '/': 'Portfolio',
                '/watchlist': 'Watchlist',
                '/portfolio': 'Portfolio',
                '/history': 'History',
                '/topinvestors': 'Top 10 Rankings',
                '/topclubs': 'Top 10 Rankings',
                '/topinvestments': 'Top 10 Rankings',
                '/topfollowed': 'Top 10 Rankings',
                '/searchinvestors': 'Search',
                '/searchclubs': 'Search',
                '/searchinvestments': 'Search',
                '/followed': 'Following',
                '/newasset': 'Add assets',
                '/newcrypto': 'Add crypto assets',
                '/profile': 'Profile',
                '/icons': 'Choose icon',
                // '/clubadmin': 'Club admin page',
                '/admin': 'Admin page',
                '/network-error': 'Network Error',
                // '/clubinvestors': 'Club Investors',
                '/clubsadmin': 'Clubs Administration',
                '/clubs': 'My Clubs',
                '/notifications': 'Notifications',
                '/help': 'Help page',
                '/about': 'About InvestClub',
                '/manual': 'How it works',
                // '/privacy': 'Privacy Policy',
                // '/terms': 'Terms and Conditions',
                '/privacy': '',
                '/terms': '',
                // '/links': 'Useful Links',
                // '/remove': 'My Portfolio',
                // '/removew': 'My Watchlist',
                '/messages': 'Messages',
                '/conversation': 'Conversation',
                '/valuation': 'Stock valuation',
            },
            picUrl: false,
            topPos: null,
            leftPos: null,
            // showModalPic: false,
            resizeTimeout: null,
        }
    },
    computed: { // Notice: the store is reactive (forces computing), localStorage is not. In case that links to property that is not reactive, call a method instead of computed
        // artificialName() {
        //     return store.state.particAlias.slice(0, 12) + '...'
        // },
        // artificialName2() {
        //     return store.state.particAlias.length
        // },
        toInvestorPage() {
            return store.state.toInvestorPage
        },
        loggedIn() {
            return store.state.authentications.loggedIn
        },
        page() {
            return store.state.page
        },
        // isAdmin() {
        //     return store.state.authentications.isAdminUser
        // },
        // isClubAdmin() {
        //     return store.state.cad
        // },
        myIcon() {
            if (store.state.myIconM != null && store.state.myIconM.length > 4) {
                // return store.state.serverUrl + '/getimage/' + store.state.myPid + '/'
                return store.state.serverUrl + '/getimage/' + store.state.myIconM + '/'
            } else {
                return store.state.myIconM
            }
        },
        unreadn() {
            return store.state.unreadn
        },
        unreadm() {
            return store.state.unreadm
        },
        showPic() {
            return store.state.showPic
        },
        pagetitle() {
            // console.log('pagename in topbar1: ', store.state.page)
            if (store.state.page == '/portfolio' || store.state.page == '/' || store.state.page == '/watchlist' || store.state.page == '/history') {
                if (store.state.particId && store.state.particId != store.state.myPid) {
                    if (this.width >= 500) {
                        return store.state.particAlias + ' ' + this.pagename[store.state.page]
                    } else if (this.width >= 400 && store.state.particAlias != null && store.state.particAlias.length <= 14) {
                        return store.state.particAlias + ' ' + this.pagename[store.state.page]
                    } else if (this.width >= 400) {
                        return store.state.particAlias
                    } else if (this.width >= 360 && store.state.particAlias != null && store.state.particAlias.length <= 12) {
                        return store.state.particAlias + ' ' + this.pagename[store.state.page]
                    } else if (this.width >= 360 && store.state.particAlias != null && store.state.particAlias.length <= 18) {
                        return store.state.particAlias
                    } else if (this.width >= 360) {
                        return store.state.particAlias.slice(0, 16) + '...'
                    } else if (this.width >= 320 && store.state.particAlias != null && store.state.particAlias.length <= 16) {
                        return store.state.particAlias
                    } else if (this.width >= 320) {
                        return store.state.particAlias.slice(0, 14) + '...'
                    } else if (this.width < 320 && store.state.particAlias != null && store.state.particAlias.length <= 14) {
                        return store.state.particAlias
                    } else if (this.width < 320) {
                        return store.state.particAlias.slice(0, 12) + '...'
                    }
                } else {
                    // return 'My ' + this.pagename[store.state.page]
                    return this.pagename[store.state.page]

                }
                // if (store.state.page == '/portfolio' || store.state.page == '/') {
                //     if (store.state.particId && store.state.particId != store.state.myPid) {
                //         return store.state.particAlias + ' ' + this.pagename['/portfolio']
                //     } else {
                //         return 'My ' + this.pagename['/portfolio']
                //     }
                // } else if (store.state.page == '/watchlist') {
                //     if (store.state.particId && store.state.particId != store.state.myPid) {
                //         return store.state.particAlias + ' ' + this.pagename['/watchlist']
                //     } else {
                //         return 'My ' + this.pagename['/watchlist']
                //     }
                // } else if (store.state.page == '/history') {
                //     if (store.state.particId && store.state.particId != store.state.myPid) {
                //         return store.state.particAlias + ' ' + this.pagename['/history']
                //     } else {
                //         return 'My ' + this.pagename['/history']
                //     }
            } else if (store.state.page == '/investor' || store.state.page == '/conversation') {
                // console.log('myPid_TopBar4: ', store.state.myPid)
                console.log('store.state.particId in Navbars: ', store.state.particId)
                console.log('store.state.particAlias in Navbars: ', store.state.particAlias)
                let topbarName = ''
                if ((store.state.particId && store.state.particId != store.state.myPid) || localStorage.getItem('cna')) {

                    if (localStorage.getItem('cna')) {
                        topbarName = localStorage.getItem('cna')
                    } else if (store.state.particId != null && store.state.particId != store.state.myPid) {
                        topbarName = store.state.particAlias
                    }
                    if (this.width >= 360 && topbarName && topbarName != null && topbarName.length <= 18) {
                        return topbarName
                    } else if (this.width >= 360 && topbarName && topbarName != null) {
                        return topbarName.slice(0, 16) + '...'
                    } else if (this.width >= 320 && topbarName && topbarName != null && topbarName.length <= 16) {
                        return topbarName
                    } else if (this.width >= 320 && topbarName && topbarName != null) {
                        return topbarName.slice(0, 14) + '...'
                    } else if (this.width < 320 && topbarName && topbarName != null && topbarName.length <= 14) {
                        return topbarName
                    } else if (this.width < 320 && topbarName && topbarName != null) {
                        return topbarName.slice(0, 12) + '...'
                    }
                    // return store.state.particAlias
                } else {
                    return 'My Summary'
                }
            } else if (store.state.page == '/removep') {
                return 'Portfolio'
            } else if (store.state.page == '/removew') {
                return 'Watchlist'
            }

            // } else if (/^\/[0-9]{1,12}/.test(store.state.page)) {
            //     console.log('myPid_TopBar4: ', store.state.myPid)
            //     if (store.state.particId && store.state.particId != store.state.myPid) {
            //         return store.state.particIcon + ' investor page'
            //     } else {
            //         return 'My investor page'
            //     }
            // }

            // console.log('pagename in topbar2: ', store.state.page)
            return this.pagename[store.state.page] //extracts the pagetitle from the dictionary

        },
        memberPic() {
            if (store.state.page == '/conversation' && localStorage.getItem('cik')) {
                // if (localStorage.getItem('cik').length > 4) {
                //     // return store.state.serverUrl + '/getimage/' + store.state.particId + '/'
                //     return store.state.serverUrl + '/getimage/' + localStorage.getItem('cik') + '/'
                // } else {

                return localStorage.getItem('cik')
                // }
            } else if (store.state.particId && store.state.particId != store.state.myPid) {
                if (store.state.particIcon != null && store.state.particIcon.length > 4) {
                    // return store.state.serverUrl + '/getimage/' + store.state.particId + '/'
                    return store.state.serverUrl + '/getimage/' + store.state.particIcon + '/'
                } else {
                    return store.state.particIcon
                }
            } else {
                return false
            }
        },
        lastCheck() {
            return Number(localStorage.getItem('nlc'))
        },
        currentCheck() {
            let myDate = new Date()
            // alert(Number(`${(myDate.getFullYear())}${('0' + (myDate.getMonth() + 1)).slice(-2)}${('0' + myDate.getDate()).slice(-2)}${('0' + myDate.getHours()).slice(-2)}`))
            return Number(`${(myDate.getFullYear())}${('0' + (myDate.getMonth() + 1)).slice(-2)}${('0' + myDate.getDate()).slice(-2)}${('0' + myDate.getHours()).slice(-2)}`) // number of strictly 4 digits, made of the day of the month (1 to 31) + the hour (00 to 24)
        }
    },
    methods: {
        // ...mapActions('authentications', ['setLoggedIn', 'setIncorrectAuth', 'setAdminUser', 'setTryingRefresh']),
        ...mapActions(['setParticIcon', 'setParticAlias', 'setParticId', 'setApiUrl', 'setUnreadN', 'setUnreadM', 'setShowPic', 'setRfrsh']),
        async enlargePic(src) {
            if (this.showPic) {
                await this.setShowPic(false)
                // this.picUrl = false
            } else {
                let elem = document.getElementById('investorPic')
                let rect = elem.getBoundingClientRect()
                // this.topPos = document.querySelector('pic-' + index).getBoundingClientRect().top
                // this.leftPos = document.querySelector('pic-' + index).getBoundingClientRect().left
                this.topPos = rect.top + 50
                this.leftPos = rect.left - 20
                this.picUrl = src
                await this.setShowPic(true)
            }
        },
        async onModalClosed(value) {
            console.log('onmodalClosed: ', value)
            // alert('onmodalClosed')
            // this.picUrl = false
            await this.setShowPic(false)
        },
        goHelp() {
            return router.push('/help')
        },

        async logOut() {
            await this.setRfrsh('/login') //New 221231
            return router.push('/login')
        },
        async displayItemData(category) {
            try {
                // await this.clearPartic()
                if (store.state.particId && store.state.page == '/' + category) {
                    await this.clearPartic()
                    console.log('forcing refresh')
                    return this.$router.go()
                }
                await this.clearPartic()
                return router.push({
                    name: 'itemdata',
                    params: {
                        category: category,
                    }
                })
            } catch (err) {
                console.log(err)
            }
        },
        async clearPartic() {
            try {
                await this.setParticId(null)
                await this.setParticIcon(null)
                await this.setParticAlias(null)
                localStorage.removeItem('paic')
                localStorage.removeItem('paal')
                return localStorage.removeItem('paid')
            } catch (err) {
                console.log(err)
            }
        },
        // async onResize(event) {
        onResize() {
            this.width = window.innerWidth
            // console.log('this.resizeTimeout 1:', this.resizeTimeout)
            // this.resizeTimeout = null
            // this.resizeTimeout = setTimeout(() => {
            // console.log('RESIZING')
            // // document.getElementById('myOffcanvasButton').click()
            // this.myOffcanvas = document.getElementById('offcanvas')
            // this.bsOffcanvas = new Offcanvas(this.myOffcanvas)                    
            // }, 300)

            // console.log('EEEEEVENT1:', this.width)
            // console.log('EEEEEVENT2:', event.srcElement.innerWidth)
            // console.log('EEEEEVENT3:', window.innerWidth)

            // alert(this.bsOffcanvas._isShown)
            // console.log('QQQQQQQQQ1:', this.bsOffcanvas._isShown)
            // // console.log('TTTTTTTTT:', document.getElementById('offcanvas'))
            // if (this.width > 1369 && this.bsOffcanvas._isShown == false) {
            //     // alert(this.bsOffcanvas._isShown)
            //         // document.getElementById('myOffcanvasButton').click()
            //         // document.getElementById('offcanvas').show
            //     // clearTimeout(this.resizeTimeout)
            //     // this.resizeTimeout = null
            //     console.log('this.resizeTimeout 2:', this.resizeTimeout)
            //     this.resizeTimeout = setTimeout(() => {
            //         // a +=1
            //         // alert(this.bsOffcanvas._isShown)
            //         // alert(a)
            //         console.log('CLICKING')
            //         document.getElementById('myOffcanvasButton').click()
            //     }, 300)
            // }
        },
        async showOffcanvas() {
            console.log('Showing offcanvas')
            if (this.touchendX > this.touchstartX && (this.touchendX - this.touchstartX) > 50) {
                this.bsOffcanvas.show()
                try {
                    await this.checkNotifications()
                } catch (err) {
                    console.log('error in showOffcanvas: ', err)
                }
            }
        },
        // async alwaysShowOffcanvas() {
        //     try {
        //         if (this.width > 1369) {
        //             alert('AAA')
        //             // this.bsOffcanvas.show()
        //             setTimeout(() => {
        //                 document.getElementById('myOffcanvasButton').click()
        //             }, 300)
        //             await this.checkNotifications()
        //         }
        //     } catch (err) {
        //         console.log('error in alwaysShowOffcanvas: ', err)
        //     }
        // },
        async checkNotifications() {
            // let myDate = new Date()
            // let currentCheck = `${('0' + myDate.getDate()).slice(-2)}${('0' + myDate.getHours()).slice(-2)}`
            // console.log('currentCheck: ', currentCheck)
            // let lastCheck = Number(localStorage.getItem('nlc')) // nlc = notifications last check
            // console.log('lastCheck: ', lastCheck)
            // alert (this.currentCheck - this.lastCheck)
            // alert(this.currentCheck)
            if (this.currentCheck - this.lastCheck > 4) { // if more than 4 hours have passed, check notifications and reset the lastcheck in localstorage
                localStorage.setItem('nlc', this.currentCheck)
                try {
                    await this.getNotifications()
                } catch (err) {
                    console.log('error in checkNotifications: ', err)
                }
            }
        },
        hideOffcanvas() {
            // console.log('_isShown_infunction == true')
            if (this.touchendX < this.touchstartX && (this.touchstartX - this.touchendX) > 35) {

                this.bsOffcanvas.hide()
                // console.log('Swiped Left')
                // if (this.currentCheck - this.lastCheck > 400) { // if more than 7 days have passed (e.g. just logged in) and you are closing offcanvas, then adjust lastcheck in localstorage so that it doesn't keep showing
                localStorage.setItem('nlc', this.currentCheck)
                // }
            }
        },
        async getNotifications() {
            try {
                let params = {}
                console.log('in Navbar/getNotifications')

                if (store.state.page == '/conversation' && localStorage.getItem('cna') && localStorage.getItem('cik') && localStorage.getItem('cid')) {
                    params = {
                        'notified': store.state.myPid,
                        'club': localStorage.getItem('cid'),
                        'status': 'navbar',
                    }
                } else if (store.state.page == '/conversation' && store.state.particId != null) {
                    params = {
                        'notified': store.state.myPid,
                        'notifier': store.state.particId,
                        'status': 'navbar',
                    }
                } else {
                    params = {
                        'notified': store.state.myPid,
                        'status': 'navbar',
                    }
                }
                const notifications = await apiCalls.getEntries('/notifications/', params)

                if (notifications && notifications.data.length > 0) {
                    console.log('notificationsInNavbar: ', notifications.data)
                    // let myMessageNotifications = []
                    // myMessageNotifications = notifications.data.filter(obj => { return obj.topic == 'message' })
                    let myMessageNotifications = notifications.data.filter(obj => obj.topic == 'message')

                    const messageCount = myMessageNotifications.length
                    console.log('messageCount: ', messageCount)
                    // const myParticNotifiers= notifications.data.filter(obj => { return obj.topic == 'message' }).map(a => a.notifier)
                    // const myClubNotifiers= notifications.data.filter(obj => { return (obj.topic == 'message' && obj.club) }).map(a => a.club)
                    // const myMessagesId= myMessageNotifications.map(a => a.id)
                    // console.log('Notifiers list: ', myParticNotifiers)
                    // console.log('myMessagesId: ', myMessagesId)
                    const notificationCount = notifications.data.length - messageCount

                    if (notificationCount > 0) {
                        await this.setUnreadN(notificationCount)
                        localStorage.setItem('unrn', notificationCount)
                    } else {
                        await this.setUnreadN(0)
                        localStorage.setItem('unrn', 0)
                    }

                    // console.log('messageCount > 0: ', messageCount > 0)
                    if (messageCount > 0) { // If I have personal messages still not with status=read (could be unread or dlvd) or any club message (read or unread)
                        let unreadMessageCount = 0
                        let readList = []
                        // console.log('myMessageNotifications: ', myMessageNotifications)
                        myMessageNotifications.forEach(msg => {
                            // console.log('msg: ', msg)
                            // console.log('msg.club: ', msg.club)
                            if (msg.status == 'unread' || msg.status == 'dlvd') {
                                unreadMessageCount += 1
                            } else if (msg.club) {
                                readList = msg.status.split(',')
                                // console.log('typeof store.state.myPid: ', typeof store.state.myPid)
                                // console.log('typeof readList[0]: ', typeof store.state.myPid)
                                // console.log('readList in if messagecount: ', readList)
                                // console.log('store.state.myPid: ', store.state.myPid)
                                // console.log('store.state.myPid == readList[0]: ', store.state.myPid == readList[0])
                                // console.log('readList.indexOf(store.state.myPid): ', readList.indexOf(store.state.myPid))
                                if (readList.indexOf(store.state.myPid.toString()) == -1) {
                                    console.log('Found unread club message: ', readList)
                                    console.log('My ID: ', store.state.myPid)
                                    console.log('readList.indexOf(store.state.myPid) == -1: ', readList.indexOf(store.state.myPid) == -1)
                                    console.log('readList.indexOf(store.state.myPid.toString()) == -1: ', readList.indexOf(store.state.myPid.toString()) == -1)
                                    unreadMessageCount += 1
                                }
                            }
                        })
                        await this.setUnreadM(unreadMessageCount)
                        localStorage.setItem('unrm', unreadMessageCount)

                        // if (store.state.page == '/conversation' && (myParticNotifiers.indexOf(store.state.ParticId) != -1 || myClubNotifiers.indexOf(localStorage.getItem('cid') != -1))) {
                        //     return
                        // } else {
                        // // if (store.state.page != '/conversation' || myParticNotifiers.indexOf(store.state.ParticId) != -1) {
                        //     await this.setUnreadM(messageCount)
                        //     localStorage.setItem('unrm', messageCount)
                        // } // else {
                        //     await apiCalls.patchEntry('/notifications/' + myMessagesId[0] + '/', {
                        //         'status': 'read',
                        //         // 'unreadlist': myMessagesId.join(),
                        //     })
                        // }

                    } else {
                        await this.setUnreadM(0)
                        localStorage.setItem('unrm', 0)
                    }
                } else {
                    console.log('NO MESSAGE')
                    await this.setUnreadM(0)
                    localStorage.setItem('unrm', 0)
                    await this.setUnreadN(0)
                    localStorage.setItem('unrn', 0)
                }
            } catch (error) {
                return console.log('error in Navbar/getNotifications: ', error)
            }
        },
        refreshPage() {
            return router.go(0)
        },
    },
    async created() {

            if (this.currentCheck - this.lastCheck > 700) { // if more than 7 days have passed (e.g. just logged in) and you are closing offcanvas, then adjust lastcheck in localstorage so that it doesn't keep showing
                localStorage.setItem('nlc', this.currentCheck - 500) // upon login it is set at currentCheck -700. Reaching here it sets in localstorage at -500 and triggers a refresh to show the offcanvas upon login. 
                return router.go(0)
            }
        console.log('STARTING Navbars created')
        try {
            await this.getNotifications()
            // console.log('not getting notifications in Navbar to avoid error on refresh')
            console.log('ENDING Navbars created')
            // console.log('this.currentCheck: ', this.currentCheck)
            // console.log('this.lastCheck: ', this.lastCheck)
        } catch (err) {
            console.log('error in Navbar/created: ', err)
        }
    },
    async mounted() {
        console.log('STARTING Navbars mounted')
        // alert (window.innerWidth)
        // window.addEventListener('resize', event => this.onResize(event))
        window.addEventListener('resize', this.onResize)
        this.width = window.innerWidth
        this.height = window.innerHeight

        // this.isClubAdmin = store.state.cad
        this.myOffcanvas = document.getElementById('offcanvas')
        this.bsOffcanvas = new Offcanvas(this.myOffcanvas)
        
        // if (this.width > 1369) {
        // this.alwaysShowOffcanvas()
        // } else {
        document.addEventListener('touchstart', event => {
            this.touchstartX = event.changedTouches[0].screenX
            this.touchstartY = event.changedTouches[0].screenY
        }, false)
        document.addEventListener('touchend', event => {
            this.touchendX = event.changedTouches[0].screenX
            this.touchendY = event.changedTouches[0].screenY
            let horizontalDifference = this.touchstartX - this.touchendX
            let verticalDifference = this.touchstartY - this.touchendY
            if (Math.abs(horizontalDifference) > Math.abs(verticalDifference)) {
                if (this.bsOffcanvas._isShown == false) {
                    // console.log('_isShown == false')
                    this.showOffcanvas()
                } else if (this.bsOffcanvas._isShown == true) {
                    // console.log('_isShown == true')
                    this.hideOffcanvas()
                }
            }
        }, false)
        // }
        console.log('ENDING Navbars mounted')
    },
    unmounted() {
        // Unregister the event listener before destroying this Vue instance
        window.removeEventListener('resize', this.onResize)
    }
}
</script>

<style scoped>

@media only screen and (min-width: 1008px) {
    /* offcanvas button at the top */

    .sticker-top {
        height: 40px;
        width: 40px;
        margin-top: -6px;
        margin-right: 0px;
        padding-right: 0px;
        outline: none !important;
        border: none !important;
        z-index: 1;
        margin-left: -15px;
    }

    .sticker-top-round {
        height: 37px;
        width: 37px;
        margin-top: -6px;
        margin-right: 0px;
        padding-right: 0px;
        outline: none !important;
        border: none !important;
        z-index: 1;
        margin-left: -15px;
        border-radius: 50%
    }
}

@media only screen {

    /* all sizes */

    .offcanvas {
        /* not fixed offcanvas */
        width: 200px !important;
    }

    .list-group-item {
        background-color: #312e2b !important;
        border-color: #312e2b;
        height: 43px;
    }

    .links {
        color: #ddd;
        font-size: 1.2rem;
        font-weight: bold;
        text-decoration: none;
        padding-left: 15px;
    }

    .logout-bottom {
        margin-bottom: 8px;
        margin-top: -3px;
        cursor: pointer;
        color: #ddd;
        font-size: 1.3rem;
        font-weight: bold;
        /* margin-left: -1px; */
    }
}


@media only screen and (min-width: 1685px) {
    /* fixed offcanvas with image at the bottom */

    .offcanvas { /* smaller fixed offcanvas between 1685 and 1799 */
        width: 183px !important;
    }

    .bottom-img {
        max-width: 100px;
        max-height: 100px;
        margin-top: 30px;
        margin-left: -15px;
        margin-bottom: -65px;
    }

    .bottom-img-help {
        width: 22px;
        margin-top: 50px;
        margin-bottom: -75px;
        margin-left: 28px;
        margin-right: -35px;
        padding-bottom: 0px;
    }
}

@media only screen and (min-width: 1800px) {
    /*24 inch screen = 1920;  min Desktop screen = 1366; tablets = 768; 13.3 in = 1232; 16 in = 1707 */

    .offcanvas { /* larger offcanvas above 1800 */
        width: 250px !important;
    }
}

@media only screen and (max-width: 1684px) { /* non fixed offcanvas with image at the top and slightly smaller links characters*/

    .custom-img {
        max-width: 70px;
        max-height: 70px;
        margin-top: 15px;
        margin-bottom: 10px;
        margin-left: 25px;
    }

    .custom-img-help {
        width: 22px;
        margin-bottom: 10px;
        margin-left: 40px;
        margin-right: -21px;
    }

}

@media only screen and (min-width: 1008px) and (max-width: 1684px) { /* slightly smaller links characters for small laptops*/
    
    .links {
        font-size: 1.1rem;
    }

    .logout-bottom {
        font-size: 1.2rem;
        margin-left: -1px;
    }
}

.top-offcanvas-header {
    margin-bottom: 25px;
}

.toggle-btn {
    height: 35px;
    width: 35px;
    padding-right: 0px;
    /* margin-top: 26px; */
    /* margin-right: 10px; */
    padding-bottom: 2px;
    margin-right: 15px;
    margin-top: 15px;
    background-color: #272522;
    border: none !important;
    outline: none !important;
}

.toggle-top {
    margin-right: 20px;
}

.logo-btn {
    height: 35px;
    margin-top: 15px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 15px;
    background-color: #272522;
    border: none !important;
    outline: none !important;
}

.toggle-btn .line {
    width: 80%;
    float: right;
    height: 1px;
    background-color: #fff;
    margin-bottom: 5px;
    /* this is the margin of each line */
}

.nav-styl {
    background-color: #272522;
    height: 40px;
    padding-bottom: 28px;
    padding-right: 0px;
    /* padding moves up the content, margin moves up the navbar itself */
}

.offcanvas {
    background-color: #312e2b !important;
}

.links-title {
    color: #ddd;
    font-size: 1.4rem;
    font-weight: bold;
}

/* .logout-bottom {
    font-size: 1.2rem;
    margin-bottom: 8px;
    margin-top: -3px;
    cursor: pointer;
} */

/* .custom-img {
    max-width: 100px;
    margin-top: 15px;
    margin-bottom: 10px;
    margin-left: 50px;
} */

/* .custom-img {
    max-width: 100px;
    margin-top: 15px;
    margin-bottom: 10px;
    margin-left: 7px;
}
.custom-img-help {
    width: 18px;
    margin-bottom: 10px;
    margin-left: 42px;
    margin-right: -25px;
} */

/* .bottom-img {
    max-width: 150px;
    max-height: 150px;
    margin-top: 30px;
    margin-left: -35px;
    margin-bottom: -65px;
}
.bottom-img-help {
    width: 25px;
    margin-top: 50px;
    margin-bottom: -75px;
    margin-left: 32px;
    margin-right: -35px;
    padding-bottom: 0px;
} */
.col-help-bottom {
    z-index: 1;
}

/* .links {
    color: #ddd;
    font-size: 1.2rem;
    font-weight: bold;
    text-decoration: none;
    padding-left: 15px;
} */

.text-title {
    padding-left: 20px;
}

/* .list-group-item {
    background-color: #312e2b !important;
    border-color: #312e2b;
    height: 43px;
} */

/* .user-sticker {
    height: 35px;
    width: 35px;
    margin-top: 0px;
    margin-left: -15px;
    margin-right: 0px;
    padding-right: 0px;
    outline: none !important;
    border: none !important;
    z-index: 1;
} */
.user-sticker-round {
    height: 35px;
    width: 35px;
    margin-top: 0px;
    margin-left: -15px;
    margin-right: 0px;
    padding-right: 0px;
    outline: none !important;
    border: none !important;
    z-index: 1;
    border-radius: 50%;
}

/* .sticker-top {
    height: 45px;
    width: 45px;
    margin-top: 0px;
    margin-right: 0px;
    padding-right: 0px;
    outline: none !important;
    border: none !important;
    z-index: 1;
    margin-left: -50px;
} */

.name-logo {
    height: 35px;
    padding-top: 19px;
    padding-bottom: 0px;
    margin-top: 0px;
    margin-left: 15px;
    /* margin-left: 13%; */
    outline: none !important;
    border: none !important;
    z-index: 1;
}

.logo-top {
    height: 40px;
    margin-left: 27px;
}

/* .name-logo-bottom {
    height: 35px;
    padding-top: 12px;
    padding-bottom: 7px;
    margin-top: 0px;
    margin-left: 10px;
    outline: none !important;
    border: none !important;
    z-index: 1;
} */

.nav-top-left {
    width: 15%;
    /* display: flex;
    justify-content: center; */
}

.nav-top-right {
    width: 15%;
    display: flex;
    justify-content: flex-end;
    /* z-index: 10; */
}

.nav-top-mid {
    width: 70%;
    display: flex;
    justify-content: center;
}

.sep-line {
    /* background-color: #dddddd !important; */
    background-color: #b2b0aE !important;
    padding-top: 1px !important;
    margin-top: 2px;
    width: 140px;
    margin-left: 28px;
    /* margin-bottom: -7px; */ /* enable to make it disappear when scrolling */
}

.sep-line2 {
    /* background-color: #dddddd !important; */
    background-color: #b2b0aE !important;
    padding-top: 1px !important;
    width: 140px;
    margin-left: 28px;
    /* margin-top: 10px; */ /* recently removed */
}

.offcanvas-style {
    padding-top: 10px;

}

.bottom-row {
    margin-bottom: 80px;
}

.notific-num {
    font-weight: bold;
}

/* .custom-img-help {
    width: 18px;
    margin-bottom: 12px;
    margin-left: -5px;
} */

/* .custom-img-help {
    width: 18px;
    margin-bottom: 10px;
    margin-left: 42px;
    margin-right: -25px;
} */

.col-help {
    z-index: 1;
}

/* .col-help {
    text-align: end;
    justify-content: end;
    margin-top: 15px;
    margin-left: 5px;

} */

/* ************************** TOB BAR **************************** */

.navbar-titl {
    color: #ddd;
    Font-weight: bold;
    font-size: 1.5rem;
    padding-top: 8px;
}

.spac-row {
    height: 3px;
}

.spac-row2 {
    height: 20px;
}

.user-sticker-topbar {
    width: 35px;
    height: 35px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: -10px;
}

.user-sticker-topbar-round {
    width: 35px;
    height: 35px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: -10px;
    margin-right: 5px;
    border-radius: 50%;
}

.topbar-style {
    height: 47px !important;
    padding-right: 0px!important; /* RECENT-NAVBAR- prevents top navbar from shifting when vertical scrollbar is visible!!! */

}

.image-topbar {
    height: 35px;
    width: 35px;
    margin-top: 0px;
    margin-bottom: -38px;
    margin-left: -45px;
    margin-right: 0px;
    object-fit: cover;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
}

.image-toggler-top {
    height: 35px;
    width: 35px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: -7px;
    /* WAS -23 */
    margin-right: 0px;
    object-fit: cover;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
}

.image-toggler-bottom {
    height: 34px;
    width: 34px;
    margin-top: -4px;
    margin-bottom: -7px;
    margin-left: -15px;
    margin-right: 0px;
    /* margin-right: 13%; */
    object-fit: cover;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
}
</style>
