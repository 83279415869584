import { createStore } from "vuex"
import authentications from './modules/authentications.js'
// import messages from './modules/messages.js'

export default createStore({
  state: {
    page: null,
    myClub: null,
    myUid: null,
    myPid: null,
    myIcon: null,
    myIconM: null,
    myAlias: null,
    cad: false,
    cin: false,  // These are for myclubs
    cid: false, // These are for myclubs
    cain: false, // These are for clubsadmin  : club administered index
    caid: false,  // These are for clubsadmin  : club administered id
    myCur: 'CHF',
    particId: null,
    particIcon: null,
    particAlias: null,
    mymem: null, // my memberships count
    unreadn: 0,
    unreadm: 0,
    alertMsg: '',
    rfrsh: false,
    atk: '',
    rtk: '',
    isLoading: false,
    sessionExpired: false,
    toInvestorPage: false,
    fromInvestorPage: false,
    investorData: null,
    joinedDate: null,
    profilesList: [],
    showPic: false,
    commentsVisible: null,
    commentsAllowed: null,

    maxMemberships: 10,
    maxMembers: 10,
    maxInvitations: 20,
    maxFollowed: 30,
    maxPositions: 20,
    maxWatchassets: 20,
    minUsername: 6,
    maxUsername: 20,
    minClubname: 6,
    maxClubname: 22,
    tradingFee: 0.01, // represents a transaction fee of 1%
    alertMessage: 0,  // with 0 just console.logs, with 1 gives alerts (which block the flow until handled)
    // commNum: 0, // number of comments in toggled Comments in Portfolio

    // errorMsg: null,
    // showErrorMsg: false,

    serverUrl: 'https://api.investclub.net',
    // serverUrl: 'http://localhost:8000',

    // serverUrl: 'https://api.investclub.app',
    // serverUrl: 'https://investmentclub-api.herokuapp.com',    
    // serverUrl: 'https://marqsin.pythonanywhere.com',

    apiUrl: 'yf',  //CHANGE BACK, enable this line and disable below
    // apiUrl: 'rp',

  },
  mutations: {
    SET_PAGE(state, page) {
      state.page = page
    },
    SET_TOINV_PAGE(state, toinvpage) {
      state.toInvestorPage = toinvpage
    },
    SET_FROMINV_PAGE(state, frominvpage) {
      state.fromInvestorPage = frominvpage
    },
    // SET_COMM_NUM (state, commNum) {
    //     state.commNum = commNum
    // },
    SET_MY_PID (state, mypid) {
      state.myPid = mypid
    },
    SET_MY_MEM (state, mymem) {
      state.mymem = mymem
    },
    SET_MY_CLUB (state, myclub) {
      state.myClub = myclub
    },
    SET_MY_UID (state, myUid) {
      state.myUid = myUid
    },
    SET_CAD (state, cad) {
      state.cad = cad
    },
    SET_CIN (state, cin) {
      state.cin = cin
    },
    SET_CID (state, cid) {
      state.cid = cid
    },
    SET_CAIN (state, cain) {
      state.cain = cain
    },
    SET_CAID (state, caid) {
      state.caid = caid
    },
    SET_MY_ICON (state, myicon) {
      state.myIcon = myicon
    },
    SET_MY_ICON_M (state, myiconM) {
      state.myIconM = myiconM
    },
    SET_MY_ALIAS (state, myalias) {
      state.myAlias = myalias},
    SET_PARTIC_ID (state, particId) {
      state.particId = particId
    },
    SET_PARTIC_ICON (state, particIcon) {
      state.particIcon = particIcon
    },
    SET_PARTIC_ALIAS (state, particAlias) {
      state.particAlias = particAlias
    },
    // SET_ERROR_MSG (state, errorMsg) {
    //   state.errorMsg = errorMsg
    // },
    // SET_SHOW_ERROR_MSG (state, showErrorMsg) {
    //   state.showErrorMsg = showErrorMsg
    // },
    SET_API_URL (state, apiUrl) {
      state.apiUrl = apiUrl
    },
    SET_MY_CUR (state, myCur) {
      state.myCur = myCur
    },
    SET_SESSION_EXPIRED (state, sessionExpired) {
      state.sessionExpired = sessionExpired
    },
    SET_UNREAD_N (state, unreadn) {
      state.unreadn = unreadn
    },
    SET_UNREAD_M (state, unreadm) {
      state.unreadm = unreadm
    },
    SET_ALERT_MSG (state, alertMsg) {
      state.alertMsg = alertMsg
    },
    SET_RFRSH (state, rfrsh) {
      state.rfrsh = rfrsh
    },
    SET_ATK (state, atk) {
      state.atk = atk
    },
    SET_RTK (state, rtk) {
      state.rtk = rtk
    },
    SET_INVESTOR_DATA (state, investorData) {
      state.investorData = investorData
    },
    SET_COMMENTS_VISIBLE (state, commentsVisible) {
      state.commentsVisible = commentsVisible
    },
    SET_COMMENTS_ALLOWED (state, commentsAllowed) {
      state.commentsAllowed = commentsAllowed
    },
    SET_IS_LOADING (state, isLoading) {
      state.isLoading = isLoading
    },
    SET_JOINED_DATE (state, joinedDate) {
      state.joinedDate = joinedDate
    },
    SET_SHOW_PIC (state, showPic) {
      state.showPic = showPic
    },
    SET_PROFILES_LIST (state, profilesList) {
      state.profilesList = profilesList
    },
  },
  actions: {
    setPage({ commit }, page) {
      // console.log('inside setPage')
      return commit('SET_PAGE', page) // This was returned
    },
    //   setCommNum({ commit }, commNum) {
    //     commit('SET_COMM_NUM', commNum)
    // },
    setToinvPage({ commit }, toinvpage) {
      commit('SET_TOINV_PAGE', toinvpage)
    },
    setFrominvPage({ commit }, frominvpage) {
      commit('SET_FROMINV_PAGE', frominvpage)
    },
    setMyPid({ commit }, mypid) {
      commit('SET_MY_PID', mypid)
    },
    setMyMem({ commit }, mymem) {
      commit('SET_MY_MEM', mymem)
    },
    setMyClub({ commit }, myclub) {
      commit('SET_MY_CLUB', myclub)
    },
    setMyUid({ commit }, myUid) {
      commit('SET_MY_UID', myUid)
    },
    setCad({ commit }, cad) {
      commit('SET_CAD', cad)
    },
    setCin({ commit }, cin) {
      commit('SET_CIN', cin)
    },
    setCid({ commit }, cid) {
      commit('SET_CID', cid)
    },
    setCain({ commit }, cain) {
      commit('SET_CAIN', cain)
    },
    setCaid({ commit }, caid) {
      commit('SET_CAID', caid)
    },
    setMyIcon({ commit }, myicon) {
      commit('SET_MY_ICON', myicon)
    },
    setMyIconM({ commit }, myiconM) {
      commit('SET_MY_ICON_M', myiconM)
    },
    setMyAlias({ commit }, myalias) {
      commit('SET_MY_ALIAS', myalias)
    },
    setParticId({ commit }, particId) {
      commit('SET_PARTIC_ID', particId)
    },
    setParticIcon({ commit }, particIcon) {
      commit('SET_PARTIC_ICON', particIcon)
    },
    setParticAlias({ commit }, particAlias) {
      commit('SET_PARTIC_ALIAS', particAlias)
    },
    // setErrorMsg({ commit }, errorMsg) {
    //   commit('SET_ERROR_MSG', errorMsg)
    // },
    // setShowErrorMsg({ commit }, showErrorMsg) {
    //   commit('SET_SHOW_ERROR_MSG', showErrorMsg)
    // },
    setApiUrl({ commit }, apiUrl) {
      commit('SET_API_URL', apiUrl)
    },
    setMyCur({ commit }, myCur) {
      commit('SET_MY_CUR', myCur)
    },
    setSessionExpired({ commit }, sessionExpired) {
      commit('SET_SESSION_EXPIRED', sessionExpired)
    },
    setUnreadN({ commit }, unreadn) {
      commit('SET_UNREAD_N', unreadn)
    },
    setUnreadM({ commit }, unreadm) {
      commit('SET_UNREAD_M', unreadm)
    },
    setAlertMsg({ commit }, alertMsg) {
      commit('SET_ALERT_MSG', alertMsg)
    },
    setRfrsh({ commit }, rfrsh) {
      commit('SET_RFRSH', rfrsh)
    },
    setRtk({ commit }, rtk) {
      // console.log('setting rtk in store')
      commit('SET_RTK', rtk)
    },
    setAtk({ commit }, atk) {
      // console.log('setting atk in store')
      commit('SET_ATK', atk)
    },
    setInvestorData({ commit }, investorData) {
      // console.log('setting atk in store')
      commit('SET_INVESTOR_DATA', investorData)
    },
    setCommentsVisible({ commit }, commentsVisible) {
      commit('SET_COMMENTS_VISIBLE', commentsVisible)
    },
    setCommentsAllowed({ commit }, commentsAllowed) {
      commit('SET_COMMENTS_ALLOWED', commentsAllowed)
    },
    setIsLoading({ commit }, isLoading) {
      commit('SET_IS_LOADING', isLoading)
    },
    setJoinedDate({ commit }, joinedDate) {
      commit('SET_JOINED_DATE', joinedDate)
    },
    setShowPic({ commit }, showPic) {
      commit('SET_SHOW_PIC', showPic)
    },
    setProfilesList({ commit }, profilesList) {
      commit('SET_PROFILES_LIST', profilesList)
    },
  },
  modules: {
    authentications,
    // messages,
  }
})
