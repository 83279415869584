<template>
<div class="container my-5">
    <div class="row spacer-row"></div>
    <div class="row d-flex justify-content-center">
        
        <div class="card px-5 py-0">

            <div class="row first-row">
                <div class="col">
                    <img class="user-icon" src="@/assets/images/I-00.png" alt="Account">
                </div>
                <div class="col col-right">
                    <span v-if="use=='m'">
                        <button class="toggle-btn" @click.prevent="">
                            <span class="line"></span>
                            <span class="line"></span>
                            <span class="line"></span>
                        </button>
                    </span>
                    <span v-else><img class="user-icon neutral-icon" src="@/assets/images/I-11.png" alt="Account"></span>
                </div>
            </div>
            <div class="row">
                <div class="col d-flex">
                    <span class="icon-rad">
                        <BaseRadio v-model="participant.usericon" :value="13" label="" name="icons" id="I-00" />
                    </span>
                </div>
                <div class="col  d-flex">
                    <span class="icon-rad">
                        <BaseRadio v-model="participant.usericon" :value="11" label="" name="icons" id="I-11" /></span>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <img class="user-icon" src="@/assets/images/I-01.png" alt="Account">
                </div>
                <div class="col col-right">
                    <span><img class="user-icon" src="@/assets/images/I-02.png" alt="Account"></span>
                </div>
            </div>
            <div class="row">
                <div class="col  d-flex">
                    <span class="icon-rad">
                        <BaseRadio v-model="participant.usericon" :value="1" label="" name="icons" id="I-01" />
                    </span>
                </div>
                <div class="col  d-flex">
                    <span class="icon-rad">
                        <BaseRadio v-model="participant.usericon" :value="2" label="" name="icons" id="I-02" /></span>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <img class="user-icon" src="@/assets/images/I-03.png" alt="Account">
                </div>
                <div class="col col-right">
                    <span><img class="user-icon" src="@/assets/images/I-04.png" alt="Account"></span>
                </div>
            </div>
            <div class="row">
                <div class="col  d-flex">
                    <span class="icon-rad">
                        <BaseRadio v-model="participant.usericon" :value="3" label="" name="icons" id="I-03" /></span>
                </div>
                <div class="col  d-flex">
                    <span class="icon-rad">
                        <BaseRadio v-model="participant.usericon" :value="4" label="" name="icons" id="I-04" /></span>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <img class="user-icon" src="@/assets/images/I-05.png" alt="Account">
                </div>
                <div class="col col-right">
                    <span><img class="user-icon" src="@/assets/images/I-06.png" alt="Account"></span>
                </div>
            </div>
            <div class="row">
                <div class="col  d-flex">
                    <span class="icon-rad">
                        <BaseRadio v-model="participant.usericon" :value="5" label="" name="icons" id="I-05" /></span>
                </div>
                <div class="col  d-flex">
                    <span class="icon-rad">
                        <BaseRadio v-model="participant.usericon" :value="6" label="" name="icons" id="I-06" /></span>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <img class="user-icon" src="@/assets/images/I-07.png" alt="Account">
                </div>
                <div class="col col-right">
                    <span><img class="user-icon" src="@/assets/images/I-08.png" alt="Account"></span>
                </div>
            </div>
            <div class="row">
                <div class="col  d-flex">
                    <span class="icon-rad">
                        <BaseRadio v-model="participant.usericon" :value="7" label="" name="icons" id="I-07" /></span>
                </div>
                <div class="col  d-flex">
                    <span class="icon-rad">
                        <BaseRadio v-model="participant.usericon" :value="8" label="" name="icons" id="I-08" /></span>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <img class="user-icon" src="@/assets/images/I-09.png" alt="Account">
                </div>
                <div class="col col-right">
                    <span><img class="user-icon" src="@/assets/images/I-10.png" alt="Account"></span>
                </div>
            </div>
            <div class="row">
                <div class="col  d-flex">
                    <span class="icon-rad">
                        <BaseRadio v-model="participant.usericon" :value="9" label="" name="icons" id="I-09" /></span>
                </div>
                <div class="col  d-flex">
                    <span class="icon-rad">
                        <BaseRadio v-model="participant.usericon" :value="10" label="" name="icons" id="I-10" /></span>
                </div>
            </div>

            <!-- <div class="row">
                <div class="col">
                    <BaseRadioGroup v-model="participant.usericon" name="icons" :options="iconOptions"/>
                </div>
            </div> -->

            <div class="row mt-4">
                <div class="col-6 col-last-rad">
                    <span class="icon-rad-last">
                        <BaseRadio v-model="participant.usericon" :value="12" label="" name="icons" id="I-12" /></span>
                    <!-- <img class="preview-icon" :src="image" alt=""> -->
                </div>
                <div class="col-3 col-browse">
                    <div class="mb-1 btn-pos">
                        <input style="display: none" type="file" @change="onFileSelected" ref="fileInput" accept="image/png, image/jpeg">
                        <button @click.prevent="$refs.fileInput.click()" class="btn browse-btn">Browse</button>
                    </div>

                </div>
                <div class="col-3 col-preview">
                    <!-- <div v-if="participant.usericon != 11" class="empty-image ms-3 me-0"></div> -->

                    <!-- <div v-if="participant.usericon != 12"><img class="empty-image2" src="@/assets/images/I-11.png" alt="Account"></div> -->
                    <div v-if="this.image" class="preview-icon ms-3 me-0" :style="{ 'background-image': `url(${this.image})` }"></div>
                    
                    <div v-else><img class="empty-image2" src="@/assets/images/I-11.png" alt="Account"></div>

                    <!-- <div v-if="participant.usericon != 11" class="preview-icon ms-3 me-0" src="@/assets/images/I-09.png"></div> -->
                    <!-- <div v-else class="preview-icon ms-3 me-0" :style="{ 'background-image': `url(${this.image})` }"></div> -->
                    <!-- <div v-else><img class="empty-image2" :src="this.image" alt="user1"></div> -->
                </div>
                <!-- <div class="col-3 p-0">
                    <div class="preview-icon ms-3 me-0" :style="{ 'background-image': `url(${this.image})` }"></div>
                </div> -->
            </div>

            <div class="row mt-3">
                <div class="col-7">
                    <div class="mb-3 btn-pos"> <button @click.prevent="pickIcon" class="btn custom-btn btn3d btn-primar">Confirm change</button> </div>
                </div>
                <div class="col-5">
                    <div class="mb-3 "> <button @click.prevent="closeIcon" class="btn close-btn">Cancel</button> </div>
                </div>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import store from '@/store'
import {
    mapActions
} from 'vuex'
import router from '@/router'
import apiCalls from '@/services/apiCalls.js'
export default {
    name: 'UserIcon',
    props: [
        'use',
        'partImage',
        // 'fromPage',
    ],
    data() {
        return {
            // takenAliases: [],
            // pid: null,
            participant: {
                // usericon: '',
                usericon: '',
            },
            selectedFile: false,
            image: null,
        }
    },
    methods: {
        ...mapActions(['setMyIcon', 'setMyIconM', 'setAlertMsg', 'setRfrsh']),
        // async getAliases() {
        //     try {
        //         let endPoint = '/participants/' // RECENT
        //         let params = {
        //             club: store.state.myClub
        //         }
        //         const Entries = await apiCalls.getEntries(endPoint, params)
        //         this.takenAliases = Entries.data.map(a=> a.usericon)
        //         this.takenAliases.forEach(alias => {
        //             if (alias) {
        //                  document.getElementById(alias).disabled = true
        //             }
        //         })
        //     } catch (error) {
        //         return console.log('error status: ', error)
        //     }
        // },
        randomString(len) {
            let output = ''
            for (let i = 0; i < len; ++i) {
                output += (Math.floor(Math.random() * 16)).toString(16).toUpperCase()
            }
            console.log('output: ', output)
            return output
        },
        async pickIcon() {
            try {
                console.log('this.participant.usericon: ', this.participant.usericon)
                // this.pid = localStorage.getItem('pid')
                // this.pid = store.state.myPid  // RECENT

                if (!this.participant.usericon || this.participant.usericon.length < 1 || this.participant.usericon.length > 1) {
                    return await this.setAlertMsg(`Please select one of the options or press cancel to go back to Profile page`)
                }
                let params = {
                    // usericon: 'I-0' + this.participant.usericon,
                    usericon: 'I-0' + this.participant.usericon
                }
                console.log('params.usericon: ', params.usericon)
                //if (params.usericon == 'I-011') { return this.closeIcon() }  //****************** */ COMMENT OUT THIS LINE !!!!!!!!!!!!!!!!!*******************
                let filename = ''
                let extension = ''
                if (params.usericon == 'I-012') {
                    if (!this.selectedFile) {
                        return router.push('/profile')
                    }
                    filename = 'I-' + this.randomString(16)
                    extension = this.selectedFile.name.split(".").pop().toLowerCase()
                    if (extension != 'png' && extension != 'jpg' && extension != 'jpeg') {
                        return await this.setAlertMsg(`Only .png, .jpg or .jpeg image files allowed.${'\n\n'}Please select an image in one of those formats and try again.`)
                    }
                    let full_filename = `${filename}.${extension}`
                    params = {
                        usericon: filename
                    }
                    await this.uploadImage(full_filename)
                }
                if (params.usericon == 'I-010') {
                    params = {
                        usericon: 'I-10'
                    }
                }
                if (params.usericon == 'I-013') {
                    params = {
                        usericon: 'I-00'
                    }
                }
                if (params.usericon == 'I-011') {
                    params = {
                        usericon: 'I-11'
                    }
                }
                let deleted = null
                if (this.use == 'p' && store.state.myIcon && store.state.myIcon.length > 4 && params.usericon.length == 4) { //TODO make safe in backend to post, patch or delete image only if request comes from User
                    deleted = await apiCalls.postEntry('/delimage/', { //TODO find way in backend to check authentication when getting images
                        participant: store.state.myPid,
                        use: this.use,
                    })
                    if (deleted != null) {
                        console.log('deleted: ', deleted)
                    }
                } else if (this.use == 'm' && store.state.myIconM && store.state.myIconM.length > 4 && params.usericon.length == 4) {
                    deleted = await apiCalls.postEntry('/delimage/', { //TODO find way in backend to check authentication when getting images
                        participant: store.state.myPid,
                        use: this.use,
                    })
                    if (deleted != null) {
                        console.log('deleted: ', deleted)
                    }
                }
                console.log('myPid: ', store.state.myPid)
                if (this.use == 'p') {
                    await this.setMyIcon(params.usericon)
                    localStorage.setItem('pik', params.usericon)
                } else if (this.use == 'm') {
                    await this.setMyIconM(params.usericon)
                    localStorage.setItem('pikm', params.usericon)
                } else {
                    return console.log('AAAAATTTTTT cannot change icon - use uncertain!!!')
                }
                if (this.use == 'm') { // Must change the assignment of the selected icon to the right field in participant
                    params = {
                        menuicon: params.usericon
                    }
                }
                await apiCalls.patchEntry('/participants/' + store.state.myPid + '/', params)
                return router.push('/profile')
                // if (this.fromPage == 'profile') {
                //     router.push('/profile')
                // } else {
                //     return router.push('/')
                // }
            } catch (error) {
                console.log('error in pickIcon: ', error)
            }
        },
        closeIcon() {
            return router.push('/profile')
            // if (this.fromPage == 'profile') {
            //     return router.push('/profile')
            // } else {
            //     return router.push('/')
            // }
        },
        async onFileSelected(event) {
            console.log(event)

            if (event.target.files[0].type.indexOf("image") == -1) {
                return await this.setAlertMsg(`File type not supported.${'\n\n'}Please select another image and try again.`)
            }
            if (event.target.files[0].size > 3200000) {
                let size = Math.round(Number(event.target.files[0].size) / 10000) / 100
                return await this.setAlertMsg(`Image too big (${size} MB)  -  max size 3.2 MB.${'\n\n'}Please select another image and try again.`)
            }

            this.selectedFile = event.target.files[0]

            // let reader = new FileReader()
            // reader.readAsDataURL(event.target.files[0])
            // reader.onload = (event) => {
            //     this.image = event.target.result
            //     console.log('this.image1: ', this.image)
            // }

            this.image = URL.createObjectURL(this.selectedFile)
            this.participant.usericon = 12
            console.log('this.image2: ', this.image)
        },
        async uploadImage(filename) {
            try {

                console.log('this.selectedFile: ', this.selectedFile)
                console.log('this.selectedFile.name: ', this.selectedFile.name)
                const fd = new FormData()
                // fd.append('participant', store.state.myPid)

                //if (store.state.myIcon != 'I-11') {
                fd.append('use', this.use)
                fd.append('participant', store.state.myPid)
                // fd.append('image', this.selectedFile, this.selectedFile.name)
                fd.append('image', this.selectedFile, filename)
                // fd.append('image', this.selectedFile)
                console.log('fd: ', fd)
                const uploaded = await apiCalls.postEntry('/postimage/', fd)
                // const uploaded = await apiCalls.patchEntry('/postimage/' + store.state.myPid + '/', fd)
                // ,{
                // onUploadProgress: uploadEvent => {
                //     console.log('Upload Progress: ', +Math.round(uploadEvent.loaded / uploadEvent.total * 100) + '%')
                // }
                // })
                console.log('uploaded: ', uploaded)
                //} else {
                //    fd.append('image', this.selectedFile, filename)
                // const updated = await apiCalls.patchEntry('/patchimage/54/', fd)

                //}

            } catch (error) {
                console.log('error in uploadImage: ', error)
            }
        },
    },    
    created() {
        // alert('inside')
        this.image = this.partImage
        console.log('SSSSSH this.image: ', this.image)
    },
    // async created() {
    //     try {
    //         await this.getAliases()
    //     } catch (err) {
    //         console.log(err)
    //     }
    // }
}
</script>

<style scoped>
.spacer-row {
    height: 10px;
}

.card {
    margin-top: -15px;
    border-radius: 5px;
    border: none;
    width: 330px;
    height: 890px;
    background-color: #413e3c;
}

.first-row {
    margin-top: -5px;
}

.user-icon {
    width: 45%;
    /* was 60% */
    margin-left: 24px;
    margin-top: 35px;
    margin-bottom: 10px;
    border-radius: 50%;
}

.neutral-icon {
    width: 38px;
    height: 38px;
    margin-left: 29px;
    margin-top: 45px;
}

.icon-rad {
    margin-left: 40px;
    margin-top: 0px;
}

.icon-rad-last {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
}

.custom-btn {
    position: relative;
    height: 37px;
    width: 135px;
    margin-top: 23px;
    margin-bottom: 0px;
    margin-left: -5px;
    background-color: #2F5C85;
    color: white;
    padding: 0 0;
}

.close-btn {
    position: relative;
    height: 37px;
    width: 70px;
    margin-top: 23px;
    margin-bottom: 0px;
    padding: 0 0;
    margin-left: 5px;
    background-color: #6c6c6c;
    color: white;
}

/* ******************* BTN 3D ******************* */
.btn3d {
    border: 0;
    transition: all 40ms linear;
}

.btn3d.btn-primar {
    box-shadow: 0 0 0 1px #2f5b85a1 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

.btn3d.btn-primar:hover {
    box-shadow: 0 0 0 1px #4a95db34 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

/* ******************* END BTN 3D ******************* */

/* .btn-pos {
    position: relative;
    left: -12px;
} */

.col-right {
    margin-left: -4px;
}

.browse-btn {
    position: relative;
    height: 34px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 3px !important;
    padding-bottom: 5px !important;
    margin-top: 26px;
    margin-bottom: 0px;
    margin-left: -5px;
    background-color: #828289;
    color: white;
}

.preview-icon {
    height: 35px;
    width: 35px;
    margin-top: 24px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    object-fit: cover;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
}

/* .empty-image {
    height: 35px;
    width: 35px;
    margin-top: 25px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 50%;
    background-color: #e2e2e9;
    border: solid 1px #00b3dba6 !important;
} */
.empty-image2 {
    height: 35px;
    width: 35px;
    margin-top: 24px;
    margin-bottom: 0px;
    margin-left: 15px;
    margin-right: 0px;
    border-radius: 50%;
    background-color: #e2e2e9;
    border: solid 1px #00b3dba6 !important;
}

.col-last-rad {
    padding-top: 30px;
    margin-right: -35px !important;
    margin-left: 40px;
}

.col-browse {
    margin-left: -42px !important;
    /* margin-right: 0px; */
}

.col-preview {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 4px;
}

.toggle-btn {
    height: 35px;
    width: 35px;
    padding-right: 0px;
    /* margin-top: 26px; */
    /* margin-right: 10px; */
    margin-left: 25px;
    margin-right: 15px;
    margin-top: 40px;
    padding-bottom: 2px;
    background-color: #413e3c;
    border: none !important;
    outline: none !important;
}

.toggle-btn .line {
    width: 80%;
    float: right;
    height: 1px;
    background-color: #fff;
    margin-bottom: 5px;
    /* this is the margin of each line */
}
</style>
