<template>
<!-- <div class="main-cont-notifications"> -->
<div>

    <div class="modal fade" id="removeModal" tabindex="-1" aria-labelledby="removeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content modal-area">
                <div class="modal-header">
                    <h5 class="modal-title modal-title2" id="removeModalLabel">Delete notifications</h5>
                    <button type="button" class="btn close-butt" data-bs-dismiss="modal" aria-label="Remove">
                        <div class="btn-tex">
                            x
                        </div>
                    </button>
                </div>

                <div class="modal-body modal-body2">
                    <div class="row top-row-modal">
                        <div class="col">
                            <p>Sure you want to delete <b>{{ deleteString}}</b>?</p>
                        </div>
                    </div>
                </div>

                <div class="modal-footer modal-body2 px-0">
                    <div class="row modal-row">
                        <div class="col">
                            <button type="button" @click.prevent="deleteNotifications()" class="btn btn-primary modal-btn">Delete notifications</button>
                            <button type="button" class="btn btn-secondary btn-secondary-modal" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="modal fade" id="blockModal" tabindex="-1" aria-labelledby="blockModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content modal-area">
                <div class="modal-header">
                    <h5 class="modal-title modal-title2" id="blockModalLabel">Block <b>{{ blockModalEntry.notifieralias }}</b></h5>
                    <button type="button" class="btn close-butt" data-bs-dismiss="modal" aria-label="Block">
                        <div class="btn-tex">
                            x
                        </div>
                    </button>
                </div>

                <div class="modal-body modal-body2">
                    <div class="row top-row-modal">
                        <div class="col">
                            <p>Sure you want to block <b>{{ blockModalEntry.notifieralias }}</b>?</p>
                            <p>If blocked, {{ blockModalEntry.notifieralias }} will not be able to send you messages or invitations, nor to view comments in your portfolio or watchlist</p>
                        </div>
                    </div>

                    <div class="row mt-3 mb-2">
                        <!-- <div class="col pt-0 ps-4 checkbox-col"> -->
                        <div class="col pt-0 ps-4">
                            <div class="form-check">
                                <BaseInputCheckbox label="Report message as inappropriate" v-model="this.reportComment" />
                            </div>
                        </div>
                    </div>

                </div>

                <div class="modal-footer modal-body2 px-0">
                    <div class="row modal-row">
                        <div class="col">
                            <button type="button" @click.prevent="blockInvestor(blockModalEntry)" class="btn btn-primary modal-btn">Block {{ blockModalEntry.notifieralias }}</button>
                            <button type="button" class="btn btn-secondary btn-secondary-modal" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="row top-row-notifications"></div>

    <div v-if="this.showEmptyNotifications" class="card entry-card-empty">
        <div class="row">
            <!-- <div class="col-12 cell-empty"> -->
            <div class="col-12">
                {{ this.emptyMsgNotifications }}
            </div>
        </div>
    </div>

    <div v-else-if="this.notifications.length > 0">
        <div class="card card-select">
            <div class="row">
                <div class="col-7 cell-headers head-1">

                </div>
                <div class="col-5 cell-headers head-2 delete-selected">
                    <a href="" class="links-delete" @click.prevent="showRemoveModal();">
                        Delete selected
                    </a>
                </div>
                <!-- <div class="col-2 select-box-col">
                <a href="" class="links-delete" @click.prevent="deleteNotifications();">
                    delete
                </a> -->
                <!-- </div> -->
            </div>
        </div>

        <div class="card card-headers">
            <div class="row">
                <div class="col-6 cell-headers head-1">
                    from
                </div>
                <div class="col-4 cell-headers head-2">
                    subject
                </div>
                <div class="col-2 cell-headers head-3 d-flex justify-content-center select-all-box">
                    <div class="form-check">
                        <BaseInputCheckbox v-model="this.allNotifications" @update:modelValue="toggleAllNotifications(this.allNotifications)" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-for="entry, index in this.notifications" :key="entry.id">
        <div class="card card-notification" :id="'notificationcard-'+index">
            <div class="row">
                <div class="col-6 cell-member">
                    <!-- <a :class="(topicBold[index]==true) ? 'cell-neu cell-bold' : 'cell-neu'" href="" @click.prevent="toggleNotification(entry, index)">{{ entry.topic}}</a> -->
                    <a :class="(topicBold[index]==true) ? 'cell-faded cell-bold' : 'cell-faded'" href="" @click.prevent="toggleNotification(entry, index)">{{ entry.notifieralias}}</a>
                </div>
                <div class="col-4 subject-col cell-faded">
                    <!-- <a class="cell-neu" href="" @click.prevent="removeNotification(entry)">remove</a> -->
                    {{ entry.title }}
                </div>
                <div class="col-2 d-flex justify-content-center checkbox-col">
                    <div class="form-check">
                        <BaseInputCheckbox v-model="this.notifications[index]['delete']" />
                    </div>

                    <!-- <button class="btn close-butt" role="button" @click.prevent="removeNotification(entry)">
                    <div class="btn-tex">
                        x
                    </div>
                </button> -->

                </div>
            </div>
        </div>

        <!-- <div v-show="this.showNotification[index]" class="container-fluid px-0"> -->
        <div v-show="this.showNotification[index]">
            <div class="card comments-card">
                <div class="row">
                    <div class="col line-break-and-tab" :id="'notification-'+ index">
                        <!-- <div class="col"> -->
                        <p> {{ entry.description }} </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <!-- <button v-if="entry.topic=='message'" role="button" @click.prevent="goConversation(entry)" class="btn-notif btn-conv btn3d">View</button> -->
                        <button v-if="entry.topic=='application'" role="button" @click.prevent="acceptMember(entry)" class="btn-notif btn-ok btn3d">Accept</button>
                        <button v-else-if="entry.topic=='invitation'" role="button" @click.prevent="joinClub(entry)" class="btn-notif btn-ok btn3d">Join</button>
                        <!-- <button v-else-if="entry.topic=='message'" type="button" class="btn-notif btn-decline btn3d" @click.prevent="this.showNotification[index] = false; removeNotification(entry)">Delete</button> -->
                        <button v-else-if="entry.topic=='dividend'" role="button" @click.prevent="goVisit(entry)" class="btn-notif btn-ok btn3d">View</button>
                        <button v-else-if="entry.topic.substr(0,5)=='split'" role="button" @click.prevent="goVisit(entry)" class="btn-notif btn-ok btn3d">View</button>
                        <button v-else-if="entry.title=='Alert'" role="button" @click.prevent="goVisit(entry)" class="btn-notif btn-ok btn3d">View</button>
                        <button v-else-if="entry.title=='Comment'" role="button" @click.prevent="goVisit(entry)" class="btn-notif btn-ok btn3d">View</button>
                        <button v-else-if="entry.activity" role="button" @click.prevent="goVisit(entry)" class="btn-notif btn-ok btn3d">View</button>
                        <!-- <button v-if="entry.topic=='message'" type="button" class="btn-decline" @click.prevent="this.showNotification[index] = false; reportMessage(entry)">Report as inappropriate</button>                     -->
                        <!-- <button v-else-if="entry.topic=='message'" type="button" class="btn-cancel" @click.prevent="this.showNotification[index] = false;">Close</button> -->
                        <!-- <button v-else-if="entry.topic=='message'" type="button" class="btn-decline" @click.prevent="this.showNotification[index] = false; removeNotification(entry)">Delete</button> -->
                        <!-- </div>
                <div class="col"> -->
                        <!-- <button v-if="entry.topic=='message'" type="button" class="btn-notif btn-cancel1 btn3d" @click.prevent="this.showNotification[index] = false;">Close</button> -->
                        <button type="button" class="btn-notif btn-cancel2 btn3d" @click.prevent="this.showNotification[index] = false;">Close</button>
                        <!-- <button v-if="!entry.activity && entry.topic!='message'" type="button" class="btn-decline" @click.prevent="this.showNotification[index] = false; removeNotification(entry)">Decline</button> -->
                        <!-- <button v-else-if="entry.topic=='message'" type="button" class="btn-cancel" @click.prevent="this.showNotification[index] = false;">Close</button> -->
                        <!-- <button v-else-if="entry.topic=='message'" type="button" class="btn-report" @click.prevent="this.showNotification[index] = false; reportMessage(entry)">Report message</button>                     -->
                        <!-- <button v-else-if="entry.activity" type="button" class="btn-decline" @click.prevent="this.showNotification[index] = false; removeNotification(entry)">Delete</button> -->

                        <!-- </div>
                <div class="col"> -->
                        <button v-if="entry.topic=='application' || entry.topic=='invitation'" type="button" class="btn-notif btn-decline btn3d" @click.prevent="this.showNotification[index] = false; removeNotification(entry)">Decline</button>
                        <!-- <button v-else-if="entry.topic=='message' && (!entry.clubname || entry.clubname!='reported')" type="button" class="btn-notif btn-report btn3d" @click.prevent="this.showNotification[index] = false; reportMessage(entry)">Report message</button> -->
                        <!-- <button v-else-if="entry.topic=='message' && entry.clubname=='reported'" type="button" class="btn-notif btn-report btn3d" disabled>Message reported</button> -->
                        <!-- <button v-else-if="entry.topic=='message' && (!entry.clubname)" type="button" class="btn-notif btn-report btn3d" @click.prevent="this.showNotification[index] = false; showBlockModal(entry)">Block/report</button> -->
                        <!-- <button v-else-if="entry.topic=='message' && entry.clubname=='blocked'" type="button" class="btn-notif btn-report " disabled>Blocked</button> -->
                        <!-- <button v-else-if="entry.topic=='message' && entry.clubname=='reported'" type="button" class="btn-notif btn-report " disabled>Blocked/reported</button> -->
                        <button v-else-if="entry.title=='Alert'" type="button" class="btn-notif btn-decline btn3d" @click.prevent="this.showNotification[index] = false; removeNotification(entry)">Remove alert</button>
                        <button v-else-if="entry.title=='Comment'" type="button" class="btn-notif btn-decline btn3d" @click.prevent="this.showNotification[index] = false; removeNotification(entry)">Delete</button>
                        <button v-else-if="entry.topic=='dividend'" type="button" class="btn-notif btn-decline btn3d" @click.prevent="this.showNotification[index] = false; removeNotification(entry)">Delete</button>
                        <button v-else-if="entry.topic.substr(0,5)=='split'" type="button" class="btn-notif btn-decline btn3d" @click.prevent="this.showNotification[index] = false; removeNotification(entry)">Delete</button>
                        <button v-else-if="entry.activity" type="button" class="btn-notif btn-decline btn3d" @click.prevent="this.showNotification[index] = false; removeNotification(entry)">Delete</button>
                        <!-- <button v-if="entry.activity || entry.topic!='message'" type="button" class="btn-cancel" @click.prevent="this.showNotification[index] = false;">Close</button> -->
                        <!-- <button v-else-if="entry.topic=='message'" type="button" class="btn-decline" @click.prevent="this.showNotification[index] = false; reportMessage(entry)">Report inappropriate</button> -->
                    </div>
                </div>
                <!-- <button v-else-if="entry.topic=='Investor'" role="button">View</button> -->
            </div>
        </div>

    </div>

    <div class="card entry-card-oldMsg">
        <div class="row">
            <div class="col-12">
                {{ this.oldMessages }}
            </div>
        </div>
    </div>

    <!-- </div> -->
</div>
</template>

<script>
import apiCalls from '@/services/apiCalls.js'
import store from '@/store'
import router from '@/router'
import {
    Modal
} from 'bootstrap'
import {
    mapActions
} from 'vuex'
// import router from '@/router'
export default {
    name: 'TableNotifications',
    data() {
        return {
            notifications: [],
            showNotification: [],
            showEmptyNotifications: false,
            emptyMsgNotifications: 'No notification',
            oldMessages: 'All notifications are deleted after 15 days',
            topicBold: [],
            delNot: [],
            allNotifications: false,
            deleteString: '',
            blockModalEntry: {},
            reportComment: false,
        }
    },
    props: [
        'category',
    ],
    computed: {},
    methods: {
        ...mapActions(['setCad', 'setCid', 'setCin', 'setUnreadN', 'setMyMem', 'setAlertMsg', 'setRfrsh', 'setParticId', 'setParticAlias', 'setParticIcon']),
        async getNotifications() {
            this.notifications = []
            try {
                console.log('in getNotifications')
                let participant = store.state.myPid // cid is the 'prop' to the component although comes through the store
                let params = {
                    'notified': participant,
                    // 'status': 'unread',
                }
                const notifications = await apiCalls.getEntries('/notifications/', params)
                if (notifications && notifications.data.length > 0) {
                    this.notifications = notifications.data
                    console.log('notifications: ', notifications.data)
                    let boldIndex = 0
                    this.notifications.forEach(obj => {
                        if (obj.topic == 'application') {
                            obj.title = 'Application'
                            // obj.description = `${obj.notifieralias} has requested to join ${obj.clubname} Club of which you are administrator. Do you want to accept ${obj.notifieralias} as new member?`
                            obj.description = `<b><a href="/${obj.notifier}" style="color: #fff;">${obj.notifieralias}</a></b> has asked to join "${obj.clubname}" Club of which you are administrator. Do you want to accept ${obj.notifieralias} as new member?<br><br>`
                        } else if (obj.topic == 'invitation') {
                            obj.title = 'Invitation'
                            // obj.description = `You have been invited you to join ${obj.clubname} Club of which ${obj.notifieralias} is administrator. Do you want to join?`
                            obj.description = `You have been invited by <b><a href="/${obj.notifier}" style="color: #fff;">${obj.notifieralias}</a></b> to join "${obj.clubname}" Club. Do you want to join?<br><br>`
                            if (obj.invitemessage) {
                                obj.description = `${obj.description}<b>Personal message from ${obj.notifieralias}:</b><br>${obj.invitemessage}"<br><br>`
                            }
                        } else if (obj.topic == 'dividend') {
                            obj.title = 'Dividend'
                            obj.description = `<b>${obj.notifieralias}</b> has paid dividends.<br>The amounts have been added to your "cash" total and to your Portfolio Summary. The details have been reported in the comments to <b>${obj.notifieralias}</b> position in your Portfolio (comment line starting with "D").<br><br>`
                        } else if (obj.topic.substr(0,5) == 'split') {
                            obj.title = 'Split'
                            obj.description = `<b>${obj.notifieralias}</b> stock has undergone a ${obj.topic.substr(5)} split.<br>The details have been reported in the comments to <b>${obj.notifieralias}</b> position in your Portfolio.<br><br>`
                        } else if (obj.topic == 'message') {
                            obj.title = 'MESSAGE'
                            if (obj.invitemessage) {
                                // obj.description = `Personal message from <b><a href="/${obj.notifier}" style="color: #fff;">${obj.notifieralias}</a></b>:<br><br>"${obj.invitemessage}"<br><br>`
                                obj.description = `Personal message from <b><a href="/${obj.notifier}" style="color: #fff;">${obj.notifieralias}</a></b>:<br><br><br>`

                            } else {
                                obj.description = `Empty message.`
                            }
                        } else if (obj.topic[0] == 'p') {
                            obj['activity'] = true
                            let actionP = ''
                            let lsP = ''
                            let adj = ''
                            let symbolP = obj.topic.slice(5)
                            if (obj.topic[2] == 'i') {
                                actionP = 'opened'
                                adj = ' new'
                            } else if (obj.topic[2] == 'o') {
                                actionP = 'operated on'
                            } else if (obj.topic[2] == 'c') {
                                actionP = 'closed'
                            }
                            if (obj.topic[3] == 'l') {
                                lsP = 'long'
                            } else if (obj.topic[3] == 's') {
                                lsP = 'short'
                            }
                            obj.title = 'Portfolio'
                            // obj.description = `${obj.notifieralias} has ${actionP} a ${lsP} investment in ${symbolP}`
                            obj.description = `<b><a href="/${obj.notifier}" style="color: #fff;">${obj.notifieralias}</a></b> has ${actionP} a${adj} ${lsP} position of ${symbolP}<br><br>`
                        } else if (obj.topic[0] == 'w') {
                            obj['activity'] = true
                            let actionW = ''
                            let conj = ''
                            let symbolW = obj.topic.slice(5)
                            if (obj.topic[2] == 'i') {
                                actionW = 'added'
                                conj = 'to'
                            } else if (obj.topic[2] == 'c') {
                                actionW = 'removed'
                                conj = 'from'
                            }
                            obj.title = 'Watchlist'
                            // obj.description = `${obj.notifieralias} has ${actionW} ${symbolW} ${conj} the watchlist`
                            obj.description = `<b><a href="/${obj.notifier}" style="color: #fff;">${obj.notifieralias}</a></b> has ${actionW} ${symbolW} ${conj} watchlist<br><br>`

                        } else if (obj.topic[0] == 'a' || obj.topic[0] == 'b') {
                            if (obj.topic[0] == 'a') {
                                obj['direction'] = 'above'
                            } else if (obj.topic[0] == 'b') {
                                obj['direction'] = 'below'
                            }
                            if (obj.topic[2] == 'p') {
                                obj['type'] = 'portfolio'
                            } else if (obj.topic[2] == 'w') {
                                obj['type'] = 'watchlist'
                            }
                            let currency = obj.topic.substr(4, 3)
                            console.log('currency: ', currency)

                            let lengthId = obj.topic.indexOf("__") - 8
                            let lengthLastprice = obj.topic.indexOf("___") - lengthId - 10
                            let lengthAlertprice = obj.topic.length - lengthId - lengthLastprice - 13

                            obj['assetId'] = obj.topic.substr(8, lengthId)
                            console.log('obj.assetId: ', obj.assetId)
                            console.log('typeof obj.assetId: ', typeof obj.assetId)

                            let lastPrice = obj.topic.substr(lengthId + 10, lengthLastprice)
                            console.log('lastPrice: ', lastPrice)
                            console.log('typeof lastPrice: ', typeof lastPrice)
                            lastPrice = Number(lastPrice)
                            console.log('lastPrice: ', lastPrice)
                            console.log('typeof lastPrice: ', typeof lastPrice)

                            let alertPrice = obj.topic.substr(lengthId + lengthLastprice + 13, lengthAlertprice)
                            console.log('alertPrice: ', alertPrice)
                            console.log('typeof alertPrice: ', typeof alertPrice)
                            alertPrice = Number(alertPrice)
                            console.log('alertPrice: ', alertPrice)
                            console.log('typeof alertPrice: ', typeof alertPrice)
                            obj.title = 'Alert'
                            obj.description = `<b>${obj.notifieralias}</b> closed (or was seen overnight) at a price of <b>${lastPrice}</b> ${currency}, which is <u>${obj.direction}</u> your alert at ${alertPrice} ${currency}.${'\n'}To view or modify this Alert please press View.${'\n'}In order not to receive additional notifications for this Alert, press "Remove alert".<br><br>`

                        } else if (obj.topic[0] == 'c') {
                            if (obj.topic[2] == 'p') {
                                obj['type'] = 'portfolio'
                                obj['location'] = 'portfolio'
                            } else if (obj.topic[2] == 'w') {
                                obj['type'] = 'watchlist'
                                obj['location'] = 'watchlist'
                            } else if (obj.topic[2] == 'q') {
                                obj['type'] = 'history'
                                obj['location'] = 'portfolio history'
                            } else if (obj.topic[2] == 'x') {
                                obj['type'] = 'history'
                                obj['location'] = 'watchlist history'
                            }
                            let currency = obj.topic.substr(4, 3)
                            console.log('currency: ', currency)

                            let lengthSymbol = obj.topic.indexOf("__") - 4
                            let lengthOwnerId = obj.topic.indexOf("___") - lengthSymbol - 6
                            let lengthOwnerAlias = obj.topic.length - lengthSymbol - lengthOwnerId - 9

                            obj['symbol'] = obj.topic.substr(4, lengthSymbol)
                            console.log('obj.symbol: ', obj.symbol)

                            obj['ownerId'] = obj.topic.substr(lengthSymbol + 6, lengthOwnerId)
                            console.log('obj.ownerId: ', obj.ownerId)
                            console.log('typeof obj.ownerId: ', typeof obj.ownerId)

                            obj['ownerAlias'] = obj.topic.substr(lengthSymbol + lengthOwnerId + 9, lengthOwnerAlias)
                            console.log('obj.ownerAlias: ', obj.ownerAlias)
                            obj.title = 'Comment'
                            if (obj.ownerId == store.state.myPid) {
                                obj.description = `<b><a href="/${obj.notifier}" style="color: #fff;">${obj.notifieralias}</a></b> has left a new comment on <b>${obj.symbol}</b> in your ${obj.location}:<br><br>"${obj.invitemessage}"<br><br>`
                            } else {
                                obj.description = `<b><a href="/${obj.notifier}" style="color: #fff;">${obj.notifieralias}</a></b> has left a new comment on <b>${obj.symbol}</b> in the ${obj.location} of <b><a href="/${obj.ownerId}" style="color: #fff;">${obj.ownerAlias}</a></b>:<br><br>"${obj.invitemessage}"<br><br>`
                            }
                        }
                        // document.getElementById('notification-' + boldIndex).innerHTML = obj.description

                        if (obj.status == 'unread') {
                            this.topicBold[boldIndex] = true
                        }
                        boldIndex = boldIndex += 1
                    })

                    // let notificationsList = NotificationsData.data.map(a => a.topic)
                    // let params2 = {
                    //     'memberslist': memberslist.join() //creates a comma separated list of club members (participant ID) from the array
                    // }
                    // const membersData = await apiCalls.getEntries('/participants/', params2)
                    // this.membersData = membersData.data
                } else {
                    this.showEmptyNotifications = true
                }
            } catch (error) {
                return console.log('error in getNotifications: ', error)
            }
        },
        async toggleNotification(entry, index) {
            try {
                if (this.topicBold[index] == true) {
                    const read = await apiCalls.patchEntry('/notifications/' + entry.id + '/', {
                        'status': 'read'
                    })
                    if (read) {
                        this.topicBold[index] = false
                        let currUnread = store.state.unreadn
                        await this.setUnreadN(currUnread - 1)
                        localStorage.setItem('unrn', currUnread - 1)
                        console.log('Notification marked as read: ', read.data)
                    }
                }
                if (!this.showNotification[index]) {
                    document.getElementById('notification-' + index).innerHTML = this.notifications[index].description
                    this.showNotification[index] = !this.showNotification[index]
                    setTimeout(() => { // NEW 20230308  
                        return document.getElementById('notificationcard-' + index).scrollIntoView({
                            behavior: 'smooth',
                            block: 'center'
                        })
                    }, 100)
                } else {
                    return this.showNotification[index] = !this.showNotification[index]
                }

            } catch (err) {
                console.log(err)
            }
        },
        async goVisit(entry) {
            try {
                if (entry.title == 'Alert') {
                    // localStorage.removeItem('paid')
                    // localStorage.removeItem('paal')
                    // localStorage.removeItem('paic')
                    // await this.setParticId(null)
                    // await this.setParticAlias(null)
                    // await this.setParticIcon(null)
                    return router.push('/' + entry.type)
                }
                if (entry.topic == 'dividend') {
                    return router.push('/portfolio')
                }
                if (entry.topic.substr(0,5) == 'split') {
                    return router.push('/portfolio')
                }

                if (entry.title != 'Comment' && entry.notifier != store.state.myPid) {
                    localStorage.setItem('paid', entry.notifier)
                    localStorage.setItem('paal', entry.notifieralias)
                    localStorage.removeItem('paic')
                    await this.setParticId(entry.notifier)
                    await this.setParticAlias(entry.notifieralias)
                    await this.setParticIcon(null)

                } else if (entry.title == 'Comment' && entry.ownerId != store.state.myPid) {
                    // console.log('entry.ownerId: ', entry.ownerId)
                    // console.log('store.state.myPid: ', store.state.myPid)
                    // console.log('entry.ownerId != store.state.myPid: ', entry.ownerId != store.state.myPid)
                    localStorage.setItem('paid', entry.ownerId)
                    localStorage.setItem('paal', entry.ownerAlias)
                    localStorage.removeItem('paic')
                    await this.setParticId(entry.ownerId)
                    await this.setParticAlias(entry.ownerAlias)
                    await this.setParticIcon(null)

                } else if (entry.title == 'Comment' && entry.ownerId == store.state.myPid) {
                    localStorage.removeItem('paid')
                    localStorage.removeItem('paal')
                    localStorage.removeItem('paic')
                    await this.setParticId(null)
                    await this.setParticAlias(null)
                    await this.setParticIcon(null)

                } else {
                    localStorage.removeItem('paid')
                    localStorage.removeItem('paal')
                    localStorage.removeItem('paic')
                    await this.setParticId(null)
                    await this.setParticAlias(null)
                    await this.setParticIcon(null)
                }
                console.log('entry.topic[2]: ', entry.topic[2])
                console.log('entry.title: ', entry.title)
                if ((entry.title == 'Portfolio' && entry.topic[2] != 'c') || (entry.title == 'Comment' && entry.topic[2] == 'p')) {
                    console.log('routing to portfolio')
                    return router.push('/portfolio')
                } else if ((entry.title == 'Watchlist' && entry.topic[2] != 'c') || (entry.title == 'Comment' && entry.topic[2] == 'w')) {
                    console.log('routing to watchlist')
                    return router.push('/watchlist')
                } else if (entry.title == 'Comment' && (entry.topic[2] == 'q' || entry.topic[2] == 'x')) {
                    console.log('routing to history')
                    return router.push('/history')
                } else if (entry.topic[2] == 'c') {
                    console.log('routing to history')
                    return router.push('/history')
                } else {
                    return
                }

            } catch (error) {
                return console.log('error status: ', error)
            }
            // router.push('/' + entry.notifier) // this was the only line of the function when sending to the entire investor page
        },
        async goConversation(entry) {
            try {
                if (entry.notifier != store.state.myPid) {
                    localStorage.setItem('paid', entry.notifier)
                    localStorage.setItem('paal', entry.notifieralias)
                    localStorage.removeItem('paic')
                    await this.setParticId(entry.notifier)
                    await this.setParticAlias(entry.notifieralias)
                    await this.setParticIcon(null)
                }

                return router.push({
                    name: 'itemdata',
                    params: {
                        category: 'conversation',
                    }
                })

            } catch (error) {
                return console.log('error status: ', error)
            }
            // router.push('/' + entry.notifier) // this was the only line of the function when sending to the entire investor page
        },
        async acceptMember(entry) {
            try {
                const membersCount = await apiCalls.getEntries('/memberships/', {
                    'club': entry.club,
                    'status': 'active'
                })
                if (membersCount && membersCount.data.length > (store.state.maxMembers - 1)) { // maxMembers
                    return await this.setAlertMsg(`Sorry, this club already has 10 members. You would have to remove one member before accepting one more.`)
                }
                const applicant = await apiCalls.getEntries('/participants/' + entry.notifier + '/', {})
                if (applicant) {
                    if (applicant.data.memberships > (store.state.maxMemberships - 1)) { // maxMemberships
                        const removed = await apiCalls.deleteEntry('/notifications/' + entry.id + '/')
                        if (removed) {
                            console.log('removed notification: ', removed.data)
                        }
                        await this.setRfrsh(true)
                        await this.setAlertMsg(`The Investor ${entry.notifieralias} has already reached his limit of 10 active club memberships.`)
                        // return router.go(0)
                    }
                }
                const confirmedMembership = await apiCalls.patchEntry('/memberships/' + entry.membership + '/', {
                    'status': 'active',
                })
                if (confirmedMembership) {
                    console.log('confirmedMembership: ', confirmedMembership)
                    let newMems = Number(applicant.data.memberships) + 1
                    const amendedPartic = await apiCalls.patchEntry('/participants/' + applicant.data.id + '/', {
                        'memberships': newMems,
                    })
                    if (amendedPartic) {
                        const removed = await apiCalls.deleteEntry('/notifications/' + entry.id + '/')
                        if (removed) {
                            console.log('removed notification: ', removed.data)
                        }
                        await this.setRfrsh(true)
                        await this.setAlertMsg(`The Investor ${entry.notifieralias} in now member of the club ${entry.clubname}.`)
                        // return router.go(0)
                    } else {
                        await this.setRfrsh(true)
                        await this.setAlertMsg(`Sorry, we have not been able to properly add ${entry.notifieralias} to the club ${entry.clubname}.${'\n\n'}Please try again or report the problem to the site administrators.`)
                        // return router.go(0)
                    }
                } else {
                    await this.setRfrsh(true)
                    await this.setAlertMsg(`Sorry, we have not been able to add ${entry.notifieralias} to the club ${entry.clubname}.${'\n\n'}Please try again or report the problem to the site administrators.`)
                    // return router.go(0)
                }
            } catch (err) {
                console.log('error in acceptMember: ', err)
                await this.setRfrsh(true)
                await this.setAlertMsg(`Sorry, we have not been able to add ${entry.notifieralias} to the club ${entry.clubname}.${'\n\n'}Please try again or report the problem to the site administrators.`)
                // return router.go(0)
            }
        },
        async joinClub(entry) {
            try {
                const applicant = await apiCalls.getEntries('/participants/' + store.state.myPid + '/', {})
                if (applicant) {
                    console.log(applicant)
                    if (applicant.data.memberships > (store.state.maxMemberships - 1)) { // maxMemberships
                        localStorage.setItem('mem', applicant.data.memberships)
                        await this.setMyMem(applicant.data.memberships)
                        await this.setRfrsh(true)
                        await this.setAlertMsg(`Sorry, you have already reached your limit of 10 active club memberships.${'\n\n'}Please leave at least one other clubs before joining a new one.`)
                        // return router.go(0)
                    }
                } else {
                    await this.setRfrsh(true)
                    await this.setAlertMsg(`Looks like there has been an error.${'\n\n'}Please try again or inform of the problem the site administrators.`)
                    // return router.go(0)
                }
                const confirmedMembership = await apiCalls.patchEntry('/memberships/' + entry.membership + '/', {
                    'status': 'active',
                })

                if (confirmedMembership) {
                    console.log('confirmedMembership: ', confirmedMembership)
                    let newMems = Number(applicant.data.memberships) + 1
                    const amendedPartic = await apiCalls.patchEntry('/participants/' + applicant.data.id + '/', {
                        'memberships': newMems,
                    })
                    if (amendedPartic) {
                        localStorage.setItem('mem', newMems)
                        await this.setMyMem(newMems)
                        const removed = await apiCalls.deleteEntry('/notifications/' + entry.id + '/')
                        if (removed) {
                            console.log('removed notification: ', removed.data)
                        }
                        await this.setRfrsh(true)
                        await this.setAlertMsg(`You are now member of the club ${entry.clubname}.`)
                        // return router.go(0)                  
                    } else {
                        await this.setRfrsh(true)
                        await this.setAlertMsg(`Sorry, we have not been able to properly add you to club ${entry.clubname}.${'\n\n'}Please try again or report the problem to the site administrators.`)
                        // return router.go(0)
                    }
                } else {
                    await this.setRfrsh(true)
                    await this.setAlertMsg(`Sorry, we have not been able to add you to club ${entry.clubname}.${'\n\n'}Please try again or report the problem to the site administrators.`)
                    // return router.go(0)
                }

                // update localStorage and store with mem

            } catch (err) {
                console.log('error in joinClub: ', err)
                await this.setRfrsh(true)
                await this.setAlertMsg(`Sorry, we have not been able to add you to the club ${entry.clubname}.${'\n\n'}Please try again or report the problem to the site administrators.`)
                // return router.go(0)
            }
        },
        async removeNotification(entry) {
            try {
                if (entry.title == 'Alert') {
                    let patchedAsset = null
                    let endpoint = null
                    if (entry.type == 'portfolio') {
                        endpoint = '/positions/'
                    } else if (entry.type == 'watchlist') {
                        endpoint = '/watchassets/'
                    }
                    if (entry.direction == 'above') {
                        patchedAsset = await apiCalls.patchEntry(endpoint + entry.assetId + '/', {
                            'alertabove': 0
                        })
                    } else if (entry.direction == 'below') {
                        patchedAsset = await apiCalls.patchEntry(endpoint + entry.assetId + '/', {
                            'alertbelow': 0
                        })
                    }
                    if (patchedAsset && patchedAsset != null) {
                        console.log('patchedAsset: ', patchedAsset.data)
                    }

                }
                const removed = await apiCalls.deleteEntry('/notifications/' + entry.id + '/')
                if (removed) {
                    console.log('removed notification: ', removed.data)
                    await this.getNotifications()
                }
                return
                // router.go(0)
            } catch (err) {
                console.log(err)
            }
        },
        async deleteNotifications() {
            try {
                let deleteList = []
                let deleteUnread = 0
                let currUnread = store.state.unreadn
                deleteList = this.notifications.map(a => {
                    if (a.delete == true) {
                        if (a.status == 'unread') {
                            deleteUnread += 1
                        }
                        return a.id
                    } else {
                        return null
                    }
                }).filter(Boolean).join(',')
                console.log('deleteList: ', deleteList)

                // const removed = await apiCalls.deleteEntry('/notifications/' + deleteList + '/')
                const removed = await apiCalls.deleteEntry('/delnotifications/', {
                    'deletelist': deleteList
                })

                if (removed) {
                    console.log('removed notification: ', removed)
                    this.hideRemoveModal()
                    this.allNotifications = false
                    if (deleteUnread > 0) {
                        await this.setUnreadN(currUnread - deleteUnread)
                        localStorage.setItem('unrn', currUnread - deleteUnread)
                    }
                    await this.getNotifications()
                }
                // return router.go(0)
                return
            } catch (err) {
                console.log(err)
            }
        },
        // async reportMessage(entry) {
        //     try {
        //         let reportType = 'message'
        //         let description = ''
        //         // let endpoint = '/' + reportType + 's/' + this.comment + '/'
        //         // const commentDetails = await apiCalls.getEntries(endpoint, {})
        //         // if (commentDetails) {

        //         description = `Message from ${entry.notifieralias} to ${store.state.myAlias} of ${entry.created}: ${entry.invitemessage}`

        //         const reported = await apiCalls.postEntry('/reports/', {
        //             'type': reportType,
        //             'reporter': store.state.myPid,
        //             'reported': entry.notifier,
        //             'description': description,
        //         })
        //         if (reported) {
        //             console.log('reported: ', reported)
        //             const patched = await apiCalls.patchEntry('/notifications/' + entry.id + '/', {
        //                 // 'notified': null,  // may add an administrator participant and book it to her id
        //                 'clubname': 'reported',
        //             })
        //             if (patched) {
        //                 console.log('patched notification: ', patched.data)
        //                 // await this.getNotifications()
        //             }
        //             await this.setRfrsh(true)
        //             await this.setAlertMsg(`The message has been reported to the administrators.`)
        //         } else {
        //             console.log('some error in reporting comment')
        //             await this.setRfrsh(true)
        //             await this.setAlertMsg(`Sorry, we have not been able to report the message correctly.${'\n\n'}Please try again or report the problem to the site administrators.`)
        //         }
        //     } catch (err) {
        //         console.log('error in reporting comment: ', err)
        //         await this.setRfrsh(true)
        //         await this.setAlertMsg(`Sorry, we have not been able to report the message correctly.${'\n\n'}Please try again or report the problem to the site administrators.`)
        //     }
        // },
        toggleAllNotifications(value) {
            this.notifications.forEach(notification => {
                notification['delete'] = value
            })
        },
        async blockInvestor(entry) {
            try {
                this.hideBlockModal()
                const blocked = await apiCalls.postEntry('/blocks/', {
                    'blocker': store.state.myPid,
                    'blocked': entry.notifier,
                    'blockedalias': entry.notifieralias,
                })
                if (!blocked) {
                    console.log('some error in reporting comment')
                    await this.setRfrsh(true)
                    await this.setAlertMsg(`Sorry, we have not been able to block the investor correctly.${'\n\n'}Please try again or report the problem to the site administrators.`)
                }
                if (this.reportComment == true) {
                    let reportType = 'message'
                    let description = `Message from ${entry.notifieralias} to ${store.state.myAlias} of ${entry.created}: ${entry.invitemessage}`

                    const reported = await apiCalls.postEntry('/reports/', {
                        'type': reportType,
                        'reporter': store.state.myPid,
                        'reported': entry.notifier,
                        'description': description,
                    })
                    if (reported) {
                        console.log('reported: ', reported)
                        const patched1 = await apiCalls.patchEntry('/notifications/' + entry.id + '/', {
                            // 'notified': null,  // may add an administrator participant and book it to her id
                            'clubname': 'reported',
                        })
                        if (patched1) {
                            console.log('patched notification: ', patched1.data)
                            // await this.getNotifications()
                        }
                        await this.setRfrsh(true)
                        await this.setAlertMsg(`The investor has been blocked and the message reported to the administrators.`)
                    } else {
                        console.log('some error in reporting comment')
                        await this.setRfrsh(true)
                        await this.setAlertMsg(`Sorry, the investor has been blocked but we have not been able to report the message correctly.${'\n\n'}Please try again or report the problem to the site administrators.`)
                    }
                } else {
                    const patched2 = await apiCalls.patchEntry('/notifications/' + entry.id + '/', {
                        // 'notified': null,  // may add an administrator participant and book it to her id
                        'clubname': 'blocked',
                    })
                    if (patched2) {
                        console.log('patched notification: ', patched2.data)
                        // await this.getNotifications()
                    }
                    await this.setRfrsh(true)
                    await this.setAlertMsg(`The investor has been blocked successfully.`)
                }
            } catch (error) {
                console.log('Error in blockInvestor: ', error)
                await this.setRfrsh(true)
                await this.setAlertMsg(`Sorry, we have not been able to block the investor correctly.${'\n\n'}Please try again or report the problem to the site administrators.`)
            }
        },
        showRemoveModal() {
            if (this.notifications.length < 1) {
                return
            }
            this.deleteString = ''
            let deleteCount = 0
            this.notifications.forEach(a => {
                if (a.delete == true) {
                    deleteCount += 1
                }
            })
            if (deleteCount < 1) {
                return
            } else if (deleteCount == 1) {
                this.deleteString = '1 notification'
            } else {
                this.deleteString = `${deleteCount} notifications`
            }

            return this.bsRemoveModal.show()
        },
        hideRemoveModal() {
            this.bsRemoveModal.hide()
        },
        showBlockModal(entry) {
            this.reportComment = false
            this.blockModalEntry = {} // RECENT 0822
            this.blockModalEntry = {
                ...entry
            }
            return this.bsBlockModal.show()
        },
        hideBlockModal() {
            this.bsBlockModal.hide()
        },

        // async toggleNotification(index) {
        //     try {
        //         console.log('selected: ', index)

        //         await this.setCid(this.clubentries[index].id)
        //         localStorage.setItem('cid', this.clubentries[index].id)
        //         await this.setCin(index)
        //         localStorage.setItem('cin', index)
        //         this.showClub = []
        //         return this.showClub[index] = true
        //     } catch (err) {
        //         console.log(err)
        //     }
        // },
    },
    async created() {
        try {
            await this.getNotifications()
        } catch (err) {
            console.log(err)
        }
    },
    mounted() {
        this.myRemoveModal = document.getElementById('removeModal')
        this.bsRemoveModal = new Modal(this.myRemoveModal)
        this.myBlockModal = document.getElementById('blockModal')
        this.bsBlockModal = new Modal(this.myBlockModal)
    },

}
</script>

<style scoped>
/* .main-cont-notifications {
    margin-bottom: 50px;
} */

.entry-card-empty {
    background-color: #413e3c;
    height: 40px;
    margin: 3px auto;
    padding-top: 6px;
    text-align: center;
}

.card-notification {
    background-color: #413e3c !important;
    width: 100%;
    margin: 3px auto;
    padding-top: 12px;
    text-align: center;
    font-size: 0.8rem;
    height: 55px;
}

.entry-card-oldMsg {
    /* background-color: #413e3c; */
    background-color: #34312f;
    color: #878380;
    width: 100%;
    font-size: 0.9rem;
    margin: 30px auto 3px;
    padding: 8px 3px;
    text-align: center;
    border-radius: 0px;
}

.cell-member {
    position: relative;
    text-decoration-line: none;
    font-size: 1rem;
    text-align: start;
    left: 8px !important;
    margin-right: -10px;
    margin-top: 1px;
}

.cell-remove {
    text-align: center;
}

/* .cell-empty {
    font-size: 0.9rem;

} */

.subject-col {
    font-size: 1rem;
    text-align: center;
    margin-top: 3px;
}

.checkbox-col {
    padding-top: 0px;
    margin-top: 2px;
    font-size: 1.05rem;
    margin-left: 1px;
}

.cell-faded {
    /* color: #dbd7d4; */
    color: #aba7a4;
}

.top-row-notifications {
    margin-top: 60px;
}

.comments-card {
    margin: 3px 0;
    margin-bottom: 7px;
    color: #fff;
    background-color: #7C7B78;
    padding: 5px 5px;
    font-size: 0.9rem;
}

.cell-bold {
    font-weight: bold;
    font-size: 1.1rem;
    color: #fbf7f4;
}

.btn-notif {
    padding-left: 9px;
    padding-right: 9px;
    min-width: 60px;
    /* padding-left: 0px;
    padding-right: 0px; */
    color: #fff;
    font-weight: bold;
    height: 30px !important;
}

.btn-ok {
    background-color: #2F5C85 !important;
    margin-right: 20px;
}

.btn-conv {
    background-color: #2F5C85 !important;
    margin-right: 12px;
}

.btn-cancel1 {
    background-color: #6c6c6c !important;
    margin-right: 12px;
    margin-left: -8px;
    /* width: 55px !important; */
}

.btn-cancel2 {
    background-color: #6c6c6c !important;
    margin-left: 10px;
    margin-right: 30px;
    /* width: 55px !important; */
}

.btn-decline {
    background-color: rgba(214, 55, 55, 0.897) !important;
    margin-right: 20px;
    /* width: 60px !important; */
}

.btn-report {
    background-color: rgba(214, 55, 55, 0.897) !important;
    padding-left: 4px;
    padding-right: 4px;
    margin-right: 0px;
    /* color: #fff; */
    /* height: 30px !important; */
    /* width: 100px !important; */
}

/* ******************* BTN 3D ******************* */
.btn3d {
    border: 0;
    border-right: 1px solid #272522;
    border-bottom: 1px solid #272522;
    transition: all 40ms linear;
}

.btn3d.btn-primar {
    box-shadow: 0 0 0 1px #2f5b85a1 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

.btn3d.btn-primar:hover {
    box-shadow: 0 0 0 1px #00b3dba6 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

/* ******************* END BTN 3D ******************* */

.close-butt {
    height: 25px !important;
    width: 10px !important;
    text-align: start !important;
    background-color: #b33430;
    color: white;
    margin-top: 10px !important;
}

.btn-tex {
    position: relative;
    top: -8px;
    left: -4px;
}

/* .close-butt {
    height: 20px !important;
    width: 20px !important;
    text-align: center !important;
    background-color: #b33430;
    color: white;
    margin-top: 0px !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    font-weight: bold !important;
    padding: 0px !important;

}

.btn-tex {
    font-size: 1.1rem;
    margin-top: -6px;
} */

.card-headers {
    height: 30px;
    background-color: #272522 !important;
    margin-bottom: 8px;
    margin-top: 15px;
    text-align: center;
    border: none;
    font-size: 0.8rem;
}

.cell-headers {
    /* color: #6c6c6c !important; */
    color: #9c9c9c !important;
    font-size: 1rem;
    padding-left: 0px;
    padding-right: 0px;
}

.head-1 {
    text-align: start;
    position: relative;
    left: 32px !important;
    margin-right: -10px;
    font-size: 1.1rem;
}

.head-2 {
    text-align: center;
    font-size: 1.1rem;
}

.head-3 {
    text-align: center;
    /* margin-left: 0px; */
}

.card-select {
    position: sticky;
    top: 30;
    height: 30px;
    background-color: #272522 !important;
    margin-bottom: 10px;
    margin-top: -7px;
    text-align: center;
    border: none;
    font-size: 0.8rem;
}

.delete-selected {
    text-align: end;
    margin-left: -4%;
    margin-right: 0px;
    font-size: 1.1rem;
    /* color: #9c9c9c !important; */
}

.select-all-box {
    padding-top: 3px;
    font-size: 1.05rem;

}

.links-delete {
    /* color: #6c6c6c; */
    color: #9c9c9c !important;
    text-decoration: underline;
}

/* ***** MODAL ****************************************************** */
.modal-area {
    /* background-color: #dbd7d4; */
    background-color: #e4e4e4;
}

.modal-title2 {
    color: #4d4d4d;
}

.modal-body2 {
    color: #4d4d4d;
}

.top-row-modal {
    margin-top: 0px;
}

.modal-row {
    text-align: center;
}

.btn-secondary {
    margin-left: 20px !important;
    margin-top: -3px !important;
}

.btn-secondary-modal {
    height: 38px;
    margin-top: -3px !important;
}

.modal-footer {
    display: flex;
    justify-content: center;
}

.modal-btn {
    /* width: 180px; */
    padding-left: 20px;
    padding-right: 20px;
    height: 38px;
    margin-right: 10px !important;
    /* margin-left: -60px !important; */
    margin-top: -3px !important;
    background-color: #2F5C85 !important;
    color: white !important;
    border: none !important;
}

/* ********************************************* */
</style>
